import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {SolTextArea} from "../../../../components/SolStyledComponents/components";
import React from "react";
import PropTypes from 'prop-types';
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {Button, Modal, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";


const FormLabel =(props)=>{
    return <label className={`form-check-label d-block mb-1`} style={{color: '#8C8C9B'}}>
        {props.children}
    </label>
}

const TextFieldStyle = (props) =>{


    return <>
        <div>
            <SolTypography.Text style = {{color: props.secondary? '#8C8C9B':undefined}} >{props.children}</SolTypography.Text>
        </div>
    </>
}
const ResolvedBody = ({resolve,...props}) => {
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();


    const submit=(data)=>{
        console.log(data);
        const payload = {
            issue_id: props.resolvedBodyvalue?.issue_id,
            resolved_comment: data.remarks,
        }
        resolve(payload)

    }



    return (
        <div>
            <div className={`row list-filter-gap`}>
                <div className={`col-sm-3 mb-2`}>
                    <div className={`mb-2 no-padding`}>
                        <FormLabel>
                            Issue Related To
                        </FormLabel>
                        <div style={{
                            color :'#12100C, 100% !important'
                        }}>
                            {props.resolvedBodyvalue?.issueReplatedToForModal}
                        </div>

                    </div>
                </div>
                <div className={`col-sm-2 mb-2`}>
                    <div className={`mb-2 no-padding`}>
                        <FormLabel>
                            SOLbox
                        </FormLabel>
                        <div><b>{props.resolvedBodyvalue?.solbox}</b></div>
                    </div>
                </div>
                <div className={`col-sm-4 mb-2`}>
                    <div className={`mb-2 no-padding`}>
                        <FormLabel>
                            Issue
                        </FormLabel>
                        {props.resolvedBodyvalue?.issue}
                    </div>
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <div className={`mb-2 no-padding`}>
                        <FormLabel>
                            Date
                        </FormLabel>
                        {props.resolvedBodyvalue?.issueDate}
                    </div>
                </div>

            </div>
            <hr/>
            <div style={{
                margin: "1.5rem 0 1.5rem 0"
            }}>
                <form onSubmit={handleSubmit(submit)} >
                    <h3>Field Issue Remarks</h3>
                    <div className={`col-md-7 no-padding`}>
                        <SolTextArea
                            rows={5}
                            id={'remarks'}
                            name={'remarks'}
                            // onKeyPress={(event)=>{
                            //     if (event.key === 'Enter') {
                            //         onSearch(event.target.value);
                            //     }
                            // }}
                            ref = {register()}
                            autoComplete={'off'}
                            // onChange={(event)=>{
                            //     if (event.target.value === '') {
                            //         onSearch(event.target.value);
                            //     }
                            // }}
                            placeholder={'Please enter details about how the issue was resolved...'}
                        /></div>
                    <div style={{
                        fontSize: '0.85rem'
                    }}>Enter details that you think might be helpful to solve similar issues</div>
                    <>
                        <Modal.Footer>
                            <Button variant="primary" type="submit" disabled={props.disableButton} style={{
                                backgroundColor: '#F18D00', border: 'none',
                            }}>
                                {props.resolveLoading ? <Spinner animation="border"  size={'sm'} variant="primary"/> : null}
                                &nbsp;Mark Resolved
                            </Button>
                        </Modal.Footer>
                    </>
                </form>

            </div>
        </div>
    );
};

ResolvedBody.propTypes = {

};

export default ResolvedBody;

/*

<Button style={{
    backgroundColor: '#6c757d',
    border: 'none',
}} disabled={props.disableButton} >
    <i className="fa fa-window-close"/>
    &nbsp;Close
</Button>*/
