import React from "react";
import styles from './TextInput.module.scss';
import PropTypes from 'prop-types';

const SolTextInput = ({
    type,
    name,
    inputClassName,
    placeHolder,
    value,
    disable,
    readOnly,
    handleChange,
    handleKeyDown,
    handleOnClick
}) => {

    return (
        <React.Fragment>
            <input
                type={(type !== undefined && type !== null) ? type : `text`}
                name={name}
                className={`${styles.solTextInput} ${inputClassName}`}
                placeholder={placeHolder}
                value={value}
                onChange={(event) => handleChange !== undefined ? handleChange(event) : null}
                onKeyDown={(event) => handleKeyDown !== undefined ? handleKeyDown(event) : null}
                autoComplete="off"
                disabled={disable}
                readOnly={readOnly}
                onClick={(event) => handleOnClick !== undefined ? handleOnClick(event) : null}
                maxLength="256"
                min={(type !== undefined && type !== null && type === "number") ? `0` : null}
                pattern="^[A-Za-z0-9_ ]+$"
            />
        </React.Fragment>
    );
}

SolTextInput.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    placeHolder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleKeyDown: PropTypes.func,
    disable: PropTypes.bool,
    readOnly: PropTypes.bool,
    handleOnClick: PropTypes.func
};

export default SolTextInput;