import React, { useState, useEffect, useRef } from "react";
import SolTextInput from "./soltextinput/SolTextInput";
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

const SolDaterangePicker = (props) => {

    const { 
      startDate, 
      endDate, 
      dateRange,
      handleDateRange,
      dateRangeValidation,
      dateRangelabel,
      customLabel
    } = props;

    const [autoFocusEndDate, setAutoFocusEndDate] = useState(false);
    const [focusedInput, setFocusedInput] = useState(autoFocusEndDate ? 'endDate' : 'startDate');
    const [show, setShow] = useState(0);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleDatesChange = ({ startDate, endDate }) => {
      handleDateRange(startDate, endDate);
    }

    const handleFocusChange = (focusedInput) => {
        if (!focusedInput) {
            setFocusedInput('startDate');
        } else {
            setFocusedInput(focusedInput);
        }
    }

    const handleShowCalendar = (event) => {
        setShow(!show);
        setTarget(event.target);
    }

    const handleHide = (event) => {
        setShow(false);
    }

    const isOutsideRange = (day) => {
      return day.startOf('day').isAfter(moment());
    }

    return (
      <React.Fragment>
        <label className={`form-check-label mb-5 ${customLabel}`}>{dateRangelabel}</label>
        <div className="form-group d-block">
          <SolTextInput
            inputClassName={`custom-text-input-style`}
            name={`transactionDate`}
            placeHolder="Select Date Range"
            value={dateRange}
            handleOnClick={(event) => handleShowCalendar(event)}
            readOnly={true}
          />
        </div>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref.current}
        >
          <Popover id="popover-contained" placement={`bottom`} bsPrefix={`sol-daterange-picker`}>
            <Popover.Content>
              <DayPickerRangeController
                startDate={startDate} // momentPropTypes.momentObj or null
                endDate={endDate} // momentPropTypes.momentObj or null
                onDatesChange={({ startDate, endDate }) =>
                  handleDatesChange({ startDate, endDate })
                } // PropTypes.func.isRequired
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null
                onFocusChange={(focusedInput) =>
                  handleFocusChange(focusedInput)
                } // PropTypes.func.isRequired
                onOutsideClick={(event) => handleHide(event)}
                minimumNights={-1}
                isOutsideRange={dateRangeValidation ? dateRangeValidation : isOutsideRange}
              />
            </Popover.Content>
          </Popover>
        </Overlay>
      </React.Fragment>
    );
}

export default SolDaterangePicker;