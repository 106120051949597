import React from "react";
import {Badge} from "react-bootstrap";
import {SolTypography} from "../../../../../components/utilityComponents/SOlTypography";
import {
    DangerStatusRender,
    PendingRenderStatus,
    SuccessRenderStatus
} from "../../../../../components/legendComponents/legendComponents";
const replacementStatuses= {
    topUp: {
        success: <b><SuccessRenderStatus>Transfer Balance</SuccessRenderStatus></b>,
        processing: <b><PendingRenderStatus>Transfer Balance</PendingRenderStatus></b>,
        failed: <b><DangerStatusRender>Transfer Balance</DangerStatusRender></b>,
    },
    config: {
        success: <b><SuccessRenderStatus>Transfer Config</SuccessRenderStatus></b>,
        processing: <b><PendingRenderStatus>Transfer Config</PendingRenderStatus></b>,
        failed: <b><DangerStatusRender>Transfer Config</DangerStatusRender></b>,
    },

}
export const nodesReplacementListColumns=[

    {
        field: 'replacement_id',
        title: "ID",
        emptyValue: ()=>{
            return "N/A"
        },

        cellStyle: {
            // color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        // disableClick: true,
    },
    {
        field: 'old_solbox_serial_number',
        title: "Old",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },

        cellStyle: {
            // color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
    {
        field: 'new_solbox_serial_number',
        title: "New",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },

        cellStyle: {
            // color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
    {
        field: 'replacementDate',
        title: "Date",
        emptyValue: ()=>{
            return "N/A"
        },

        cellStyle: {
            // color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
    {
        field: 'status',
        title: "Status",

        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            // color: '#F18D00',
            // paddingLeft: '0',
            padding: '15px 0px 15px 0px',
            textAlign: 'left',
            display: 'inline-block',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
    {
        field: 'replaced_by',
        title: "Done By",

        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            // color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
]


export const dummyData= [
    {
        id: '136',
        old: '11001356',
        new: '11001445',
        date: 'Oct 12, 2020 3pm',
        status: <>
            {replacementStatuses.topUp['success']}
            <br/>
            <br/>
            {replacementStatuses.config['processing']}
        </>,
        doneBy: 'Sebastian',
    },
    {
        id: '136',
        old: '11001356',
        new: '11001445',
        date: 'Oct 12, 2020 3pm',
        status: <>
            {replacementStatuses.topUp['success']}
            <br/>
            <br/>
            {replacementStatuses.config['processing']}
        </>,
        doneBy: 'Sebastian',
    },
    {
        id: '136',
        old: '11001356',
        new: '11001445',
        date: 'Oct 12, 2020 3pm',
        status: <>
            {replacementStatuses.topUp['success']}
            <br/>
            <br/>
            {replacementStatuses.config['processing']}
        </>,
        doneBy: 'Sebastian',
    },
    {
        id: '136',
        old: '11001356',
        new: '11001445',
        date: 'Oct 12, 2020 3pm',
        status: <>
            {replacementStatuses.topUp['success']}
            <br/>
            <br/>
            {replacementStatuses.config['processing']}
        </>,
        doneBy: 'Sebastian',
    },
]