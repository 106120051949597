import {CALL_API} from "../../utils/actionTypes";
import {replacementActionTypes as actionTypes} from "./replacementActionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";

export const replacementActions = {

    FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO: (solboxSerialNumber)=>({
        [CALL_API]: {
            endpoint: `/solbox/get_solbox_replacement_related_info/${solboxSerialNumber}/`,
            method: 'GET',
            types: [
                actionTypes.FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_REQUEST,
                actionTypes.FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_SUCCESS,
                actionTypes.FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_FAILURE,
            ]
        }
    }),
    NODES_UPDATE_SOLBOX_CONFIGURATION: (payload)=>({
        [CALL_API]: {
            endpoint: `/config/process_config/`,
            method: 'POST',
            body: payload,
            types: [
                actionTypes.NODES_UPDATE_SOLBOX_CONFIGURATION_REQUEST,
                actionTypes.NODES_UPDATE_SOLBOX_CONFIGURATION_SUCCESS,
                actionTypes.NODES_UPDATE_SOLBOX_CONFIGURATION_FAILURE,
            ]
        }
    }),

    CHECK_SERIAL_NUMBER_FOR_REPLACEMENT: (payload)=>({
        [CALL_API]: {
            endpoint: `/solbox/validate_solbox_for_replacement?old_solbox_serial_number=${payload.old}&new_solbox_serial_number=${payload.new}&old_solbox_node_guid=${payload.old_solbox_node_guid}`,
            method: 'GET',
            types: [
                actionTypes.CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_REQUEST,
                actionTypes.CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_SUCCESS,
                actionTypes.CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_FAILURE,
            ]
        }
    }),
    REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT: (payload)=>({
        [CALL_API]: {
            endpoint: `/solbox/replace_solbox/`,
            method: 'POST',
            body: payload,
            types: [
                actionTypes.REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_REQUEST,
                actionTypes.REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_SUCCESS,
                actionTypes.REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_FAILURE,
            ]
        }
    }),
    SEND_CONFIG_FOR_REPLACEMENT: (payload)=>({
        [CALL_API]: {
                endpoint: `/solbox/send_config_to_replaced_solbox/`,
            method: 'POST',
            body: payload,
            types: [
                actionTypes.SEND_CONFIG_FOR_REPLACEMENT_REQUEST,
                actionTypes.SEND_CONFIG_FOR_REPLACEMENT_SUCCESS,
                actionTypes.SEND_CONFIG_FOR_REPLACEMENT_FAILURE,
            ]
        }
    }),
    SEND_REIMBURESEMENT_FOR_REPLACEMENT: (payload)=>({
        [CALL_API]: {
            endpoint: `/solbox/send_reimbursement_to_replaced_solbox/`,
            method: 'POST',
            body: payload,
            types: [
                actionTypes.SEND_REIMBURESEMENT_FOR_REPLACEMENT_REQUEST,
                actionTypes.SEND_REIMBURESEMENT_FOR_REPLACEMENT_SUCCESS,
                actionTypes.SEND_REIMBURESEMENT_FOR_REPLACEMENT_FAILURE,
            ]
        }
    }),
    // NODE_PROCESS_FAILED : ()=>({type: actionTypes.NODE_PROCESS_FAILED}),
    SET_REIMBURSEMENT_SUCCESSFUL_TO_UNDEFINED : ()=>({type: actionTypes.SET_REIMBURSEMENT_SUCCESSFUL_TO_UNDEFINED}),
    SET_REPLACEMENT_PHASES_TO_UNDEFINED : ()=>({type: actionTypes.SET_REPLACEMENT_PHASES_TO_UNDEFINED}),
    SET_CONFIRMATION_FLAG_TO_UNDEFINED : ()=>({type: actionTypes.SET_CONFIRMATION_FLAG_TO_UNDEFINED}),
    SET_BALANCE_DATA_FLAG_TO_UNDEFINED : ()=>({type: actionTypes.SET_BALANCE_DATA_FLAG_TO_UNDEFINED}),


    GET_GRID_OWNERS_LIST: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/organization/list/`, payload),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRID_OWNERS_LIST_REQUEST,
                actionTypes.GET_GRID_OWNERS_LIST_SUCCESS,
                actionTypes.GET_GRID_OWNERS_LIST_FAILURE,
            ]
        }
    }),

    GET_MODELS_LIST: (payload={}) => (
        {
        [CALL_API]: {
            endpoint: paramsHandler(`/solmodel-list/`, payload),
            method: 'GET',
            types: [
                actionTypes.GET_MODELS_LIST_REQUEST,
                actionTypes.GET_MODELS_LIST_SUCCESS,
                actionTypes.GET_MODELS_LIST_FAILURE,
            ]
        }
    }),

    GET_GRIDS_WITHOUT_WITHOUT_PAGINATION: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/get_list_details/`, payload),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_REQUEST,
                actionTypes.GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_SUCCESS,
                actionTypes.GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_FAILURE,
            ]
        }
    }),

    GET_NODE_TYPE_REQUEST: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/node/node_type_list/', payload),   /*?node_type_id=${}&grid_owner_guid=${}*/
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_NODE_TYPE_REQUEST,
                actionTypes.GET_NODE_TYPE_SUCCESS,
                actionTypes.GET_NODE_TYPE_FAILURE,
            ]
        }
    }),

    GET_REPLACEMENT_LIST: (payload={})=>{
        const  separateLoaderCheckArray= ['solbox_model_guid', 'organization_guid','grid_organization_guid', 'status',
            'grid_item_guid', 'node_type_id', 'page', 'solbox_serial_number' , 'replacement_date_from',
            'replacement_date_to'];
        let confirmationArray= [];
        Object.keys(payload).forEach((key)=>{
            if (separateLoaderCheckArray.indexOf(key)>-1){
                confirmationArray.push(key);
            }
        })
        return {
            [CALL_API]: {
            endpoint: paramsHandler('/node/node_replacement_details/', payload),   /*?node_type_id=${}&grid_owner_guid=${}*/
                method: 'GET',
                backend_slug: 'grid_backend',
                types: [
                confirmationArray.length>0? actionTypes.GET_REPLACEMENT_LIST_WITH_SEPARATE_LOADER_REQUEST: actionTypes.GET_REPLACEMENT_LIST_REQUEST,
                actionTypes.GET_REPLACEMENT_LIST_SUCCESS,
                actionTypes.GET_REPLACEMENT_LIST_FAILURE,
        ]}}
    },

    GET_REPLACEMENT_LIST_WITHOUT_PAGINATION: (payload={}) => {
        return {
            [CALL_API]: {
                endpoint: paramsHandler('/node/node_replacement_details/', payload),
                method: 'GET',
                backend_slug: 'grid_backend',
                types: [
                    actionTypes.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_REQUEST,
                    actionTypes.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_SUCCESS,
                    actionTypes.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_FAILURE,
                ]}}
    },

    REPLACEMENT_RETRY: (payload={})=>({
        [CALL_API]: {
            endpoint:'/solbox/retry_send_config_and_reimbursement_to_replaced_solbox/',   /*?node_type_id=${}&grid_owner_guid=${}*/
            method: 'POST',
            body: payload,
            types: [
                actionTypes.REPLACEMENT_RETRY_REQUEST,
                actionTypes.REPLACEMENT_RETRY_SUCCESS,
                actionTypes.REPLACEMENT_RETRY_FAILURE,
            ]
        }
    }),

    UPDATE_SOLBOX_BALANCE: (payload)=>({
        [CALL_API]: {
            endpoint: `/matrices/store_life_time_node_performance_data/`,
            method: 'POST',
            backend_slug: 'performance',
            body: payload,
            types: [
                actionTypes.UPDATE_SOLBOX_BALANCE_REQUEST,
                actionTypes.UPDATE_SOLBOX_BALANCE_SUCCESS,
                actionTypes.UPDATE_SOLBOX_BALANCE_FAILURE
            ]
        }
    })
}
