import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route, Switch} from "react-router-dom";
import ReplacementList from "./view/replacementList/ReplacementList";
import Replacement from "./view/replacement/Replacement";
import SecuredRoute from "../../router/SecuredRoute";

const ReplacementRouter = props => {
    return (
        <Switch>
            {/* Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/replacements"}
                to={"/replacements/list"}
            />
            {/* Routes to SOLbox List */}
            <SecuredRoute
                path={"/replacements/list"}
                component={ReplacementList}
            />

            <SecuredRoute
                path={"/replacements/replace/:grid_guid/:solbox_id/:customer_guid/:node_guid"}
                component={Replacement}
            />

        </Switch>
    )
};

ReplacementRouter.propTypes = {

};

export default ReplacementRouter;