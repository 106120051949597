import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../../../components/dataTable/DataTable";
import {commonActions} from "../../commonActions";
import {connect} from "react-redux";
import {Badge} from "react-bootstrap";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import Loader from "../../../../components/loader/Loader";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import ConfigData from "../configData/configData";

const ConfigurationHistory = (props) => {

    const [historyData,setHistoryData] = useState([]);
    const [modal,setModal] = useState(false);
    const [rowData,setRowData] = useState(false);
    const [historyColumn, setHistoryColumn] = useState([
        {
            title: 'Updated At',
            field: 'created_at',
        },
        // {
        //     title: 'SOLbox',
        //     field: 'serial_number',
        // },
        {
            title: 'Configuration Id',
            field: 'config_id',
        },
        {
            title: 'Recieved by SOLbox',
            field: 'received_by_solbox',
            lookup: {
                true: <Badge variant={'success'}>Yes</Badge>,
                false: <Badge variant={'danger'}>No</Badge>
            }
        },
        {
            title: 'Updated By',
            field: 'created_by_name',
        },
    ]);
    const {FETCH_SOLBOX_CONFIG_HISTORY,configurationHistory} = props;


    /*mapping history Data*/
    useEffect(()=>{

        if (configurationHistory && configurationHistory.length>=0)
        {
            setHistoryData( configurationHistory.map((item,index)=>{
                return {
                    ...item,
                    created_at: new DateTimeFormat(item.created_at).localDisplayTime,
                }
            }));
        }
    }, [configurationHistory]);



    useEffect(()=>{

        FETCH_SOLBOX_CONFIG_HISTORY({
            solbox_id: props.solboxSerialNumber,
        });
    },[]);



    const historyDetails=(rowData)=>{
        setModal(true);
        setRowData(rowData)
    }


    const closeModal=(rowData)=>{
        setModal(false);
    }



    const renderModal = () => {
        return <>
            {/*<Modal show={modal} onHide={closeModal} animation={true} >*/}
            {/*    <Modal.Header closeButton>*/}
            {/*        <Modal.Title>*/}
            {/*            Set Configuration for SOLbox <SolTypography.Text*/}
            {/*            primary>{solboxSerialNumber}</SolTypography.Text>*/}
            {/*        </Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}

            {/*        /!*Modal Body to render the intended component*!/*/}
            {/*        /!*Independent Live Data Component*!/*/}


            {/*    </Modal.Body>*/}

            {/*</Modal>*/}

            <GenericModal showModalHeader={true} size={'lg'} footer={true} footerActionButtonText={"Close"} noSubmitButton={true} hideModal={closeModal}  modal={modal} title={<>Configuration Details for SOLbox <SolTypography.Text
                primary>{props.solboxSerialNumber}</SolTypography.Text></>} >
                <ConfigData configurationId={rowData?.config_id} solboxGuid={rowData?.solbox_guid}/>
            </GenericModal>
        </>
    }





    return (
        props.historyLoading? <Loader text={"Fetching Configuration History...."} />:
        <div>
            <DataTable
                columns={historyColumn}
                data={historyData}
                actions={[{
                    icon: 'info' ,
                    tooltip: "Show Details",
                    onClick: (event, rowData) => historyDetails(rowData)
                }]}
                pagination={Array.isArray(historyData) && historyData.length>5}
            />
            {renderModal()}
        </div>
    );
};

ConfigurationHistory.propTypes = {

};
const mapStateToProps=({commonReducer})=>{
    return {
        historyLoading: commonReducer.historyLoading,
        configurationHistory: commonReducer.configurationHistory,
    }
}

export default connect(mapStateToProps, commonActions) (ConfigurationHistory);
