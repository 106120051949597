import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
};

/*Reducer for Grid's redux store*/
export const gridsReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_GRID_LIST_SUMMARY_REQUEST:
            return {
                ...state,
                summaryLoading: true,
                gridSummary: undefined
            };
        case actionTypes.GET_GRID_LIST_SUMMARY_SUCCESS:
            return {
                ...state,
                gridSummary: action.payload,
                summaryLoading: false
            };
        case actionTypes.GET_GRID_LIST_SUMMARY_FAILURE:
            return {
                ...state,
                summaryLoading: false
            };
        case actionTypes.GET_GRID_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                statusCode: undefined,

            };
        case actionTypes.GET_GRID_LIST_WITH_PAGE_REQUEST:
            return {
                ...state,
                tableLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_GRID_LIST_WITH_FILTER_REQUEST:
            return {
                ...state,
                filterTriggeredLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_GRID_LIST_SUCCESS:
            return {
                ...state,
                gridList: action.payload,
                isLoading: false,
                tableLoading: false,
                filterTriggeredLoading: false,
            };
        case actionTypes.GET_GRID_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                filterTriggeredLoading: false,
                statusCode: action.payload.status,
            };
        case actionTypes.GET_GRID_OWNERS_LIST_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_GRID_OWNERS_LIST_SUCCESS:
            return {
                ...state,
                filterDropdownLoading: false,
                gridOwnerList: action.payload,
            };
        case actionTypes.GET_GRID_OWNERS_LIST_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
                statusCode: action.payload.status,
            };
        case actionTypes.GET_GRID_LIST_FOR_EXPORTING_TO_CSV_REQUEST:
            return {
                ...state,
                iconLoading: true,
            };
        case actionTypes.GET_GRID_LIST_FOR_EXPORTING_TO_CSV_SUCCESS:
            exportGridListToCsv(action.payload)
            return {
                ...state,
                iconLoading: undefined,
            };
        case actionTypes.GET_GRID_LIST_FOR_EXPORTING_TO_CSV_FAILURE:
            return {
                ...state,
                iconLoading: undefined,
                statusCode: action.payload.status,
            };
        case actionTypes.GET_MODELS_LIST_REQUEST:
            return {
                ...state,
                modelSelectLoading: true,
                statusCode: undefined,
            };

        case actionTypes.GET_MODELS_LIST_SUCCESS:
            return {
                ...state,
                modelList: action.payload,
                modelSelectLoading: false,
                statusCode: undefined,
            };

        case actionTypes.GET_MODELS_LIST_FAILURE:
            return {
                ...state,
                modelSelectLoading: false,
                statusCode: action.payload.status,
            };
        case actionTypes.GET_CUSTOMERS_LIST_REQUEST:
            return {
                ...state,
                customerSelectLoading: true,
                statusCode: undefined,
            };

        case actionTypes.GET_CUSTOMERS_LIST_SUCCESS:
            return {
                ...state,
                customerList: action.payload,
                customerSelectLoading: false,
                statusCode: undefined,
            };

        case actionTypes.GET_CUSTOMERS_LIST_FAILURE:
            return {
                ...state,
                customerSelectLoading: false,
                statusCode: action.payload.status,
            };
        case actionTypes.GET_GRIDS_NODE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                gridDataRequest: requestCycle.pending,
                statusCode: undefined,
            };
        case actionTypes.GET_GRIDS_NODE_LIST_WITH_FILTER_REQUEST:
            return {
                ...state,
                filterTriggeredLoading: true,
                gridDataRequest: requestCycle.pending,
                statusCode: undefined,
            };

        case actionTypes.GET_GRIDS_NODE_LIST_SUCCESS:
            return {
                ...state,
                nodeList: Array.isArray(action.payload.node_info)?action.payload.node_info:[],
                gridInfo: action.payload.grid_info,
                filterTriggeredLoading: false,
                isLoading: false,
                gridDataRequest: requestCycle.success,
                statusCode: undefined,
            };

        case actionTypes.GET_GRIDS_NODE_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                filterTriggeredLoading: false,
                gridDataRequest: requestCycle.failed,
                statusCode: action.payload.status,
            };
        case actionTypes.PUBLISH_TOPIC_REQUEST:
            return {
                ...state,
                topicPublished: requestCycle.pending,
                statusCode: undefined,
                filterTriggeredLoading: true,
            };
        // case actionTypes.PUBLISH_TOPIC_FILTERED_REQUEST:
        //     return {
        //         ...state,
        //         filterTriggeredLoading: true,
        //         topicPublished: requestCycle.pending,
        //         statusCode: undefined,
        //     };

        case actionTypes.PUBLISH_TOPIC_SUCCESS:
            return {
                ...state,
                topicPublished: requestCycle.success,
                statusCode: undefined,
            };

        case actionTypes.PUBLISH_TOPIC_FAILURE:
            return {
                ...state,
                isLoading: false,
                filterTriggeredLoading: false,
                topicPublished: requestCycle.failed,
                statusCode: action.payload.status,
            };
        case actionTypes.GET_LIVE_DATA_REQUEST:
            return {
                ...state,
                filterTriggeredLoading: true,
                liveDataFetched: requestCycle.pending,
                statusCode: undefined,
            };

        case actionTypes.GET_LIVE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                filterTriggeredLoading: false,
                // liveOnlineBoxesWithData: [
                //     { "i32Balance": 105,
                //         "u32TotalBoughtWh": 4,
                //         "u32TotalSoldWh": 1,
                //         "i32IToGrid": 44,
                //         "update_at": "",
                //         "solbox_id": "4290",
                //         "u8UserMode": 0,
                //         "u32VBattery": '20000',
                //     },
                // ],
                // liveOnlineBoxes: [
                //     { "i32Balance": 105,
                //         "u32TotalBoughtWh": 4,
                //         "u32TotalSoldWh": 1,
                //         "i32IToGrid": 44,
                //         "update_at": "",
                //         "solbox_id": "4291",
                //         "u8UserMode": 2,
                //         "u32VBattery": '20000',
                //     },
                // ].map((box)=>{
                //     return box.solbox_id
                // }),
                liveOnlineBoxesWithData: Array.isArray(action.payload)?action.payload:[],
                liveOnlineBoxes: Array.isArray(action.payload)?action.payload.map((box)=>{
                    console.log(box.solbox_id);
                    return box.solbox_id
                }):[],
                liveDataFetched: requestCycle.success,
                statusCode: undefined,
            };

        case actionTypes.GET_LIVE_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                filterTriggeredLoading: false,
                liveDataFetched: requestCycle.failed,
                statusCode: action.payload.status,
            };

        case actionTypes.GET_LEGACY_LIVE_DATA_REQUEST:
            return {
                ...state,
                statusCode: undefined,
            };


            case actionTypes.GET_LEGACY_LIVE_DATA_PARAMS_REQUEST:
            return {
                ...state,
                statusCode: undefined,
                filterTriggeredLoading: true,
            };

        case actionTypes.GET_LEGACY_LIVE_DATA_SUCCESS:
            return {
                ...state,
                legacyOnlineBoxes: Array.isArray(action.payload)?action.payload.map((box)=>{
                    return box.solbox_guid
                }):[],
                legacyOnlineBoxesWithData: Array.isArray(action.payload)?action.payload:[],
                statusCode: undefined,
            };

        case actionTypes.GET_LEGACY_LIVE_DATA_PARAMS_SUCCESS:
            return {
                ...state,
                legacyOnlineBoxesParams: Array.isArray(action.payload)?action.payload.map((box)=>{
                    return box.solbox_guid
                }):[],
                legacyOnlineBoxesParamsWithData: Array.isArray(action.payload)?action.payload:[],
                statusCode: undefined,
                filterTriggeredLoading: false,

            };

        case actionTypes.GET_LEGACY_LIVE_DATA_FAILURE:
            return {
                ...state,
                legacyOnlineBoxes: [],
                legacyOnlineBoxesWithData: [],
                statusCode: action.payload.status,
            };
        case actionTypes.GET_LEGACY_LIVE_DATA_PARAMS_FAILURE:
            return {
                ...state,
                legacyOnlineBoxes: [],
                legacyOnlineBoxesWithData: [],
                statusCode: action.payload.status,
                filterTriggeredLoading: false,
            };
        case actionTypes.SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED:
            return {
                ...state,
                liveDataFetched: undefined,
                gridInfoSuccess: undefined,
                topicPublished: undefined,
                gridDataRequest: undefined,
                // nodeList: undefined,
            };
        case actionTypes.CREATE_GRID_REQUEST:
            return {
                ...state,
            };
        case actionTypes.CREATE_GRID_SUCCESS:
            return {
                ...state,
            };

        case actionTypes.CREATE_GRID_FAILURE:
            return {
                ...state,
            };

        //project

        case actionTypes.GET_PROJECT_LIST_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true
            };
        case actionTypes.GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projectList: Array.isArray(action.payload)? action.payload:[],
                filterDropdownLoading: false
            };

        case actionTypes.GET_PROJECT_LIST_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false
            };


        //sam

        case actionTypes.GET_SAM_LIST_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true
            };
        case actionTypes.GET_SAM_LIST_SUCCESS:
            return {
                ...state,
                samList: Array.isArray(action.payload)? action.payload:[],
                filterDropdownLoading: false
            };

        case actionTypes.GET_SAM_LIST_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false
            };


            //post Data
        case actionTypes.POST_GRID_INFO_REQUEST:
            return {
                ...state,
                gridInfoSuccess: undefined,
                gridInfoLoading: true,
            };
        case actionTypes.POST_GRID_INFO_SUCCESS:
            toast.success('Grid Created Successfully');
            return {
                ...state,
                gridInfoSuccess: requestCycle.success,
                gridInfoLoading: false,
            };
        case actionTypes.POST_GRID_INFO_FAILURE:
            toast.error('Grid Could Not be Created');
            return {
                ...state,
                gridInfoSuccess: requestCycle.failed,
                gridInfoLoading: false,
            };

        default:
            return state;
    }
};
