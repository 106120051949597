import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";
import CustomerAssigned from "./CustomerAssigned";
import CustomerUnassigned from "./CustomerUnassigned";
import { NavLink } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { withRoles } from "../../../router/SecuredRoute";

const Transaction = (props) => {

    const { isSolshare, b2bOrgGuid } = props;
    const subheader= useSubheader();

    useEffect(()=>{
        if (isSolshare) {
            subheader.setActionButtons(
            <>
                <NavLink className="btn btn-warning btn-sm" to="/transactions/create">
                    <i className="fas fa-plus mr-2"/>
                    {`Create Transaction`}
                </NavLink>
            </>
            );
        }

        return ()=>{
           subheader.setActionButtons(null);
        }
    }, [isSolshare]);

    const [key, setKey] = useState("customer_assigned");

    const toggleTransactionProfileTab = (key) => {
        setKey(key);
    }

    const redirectToAssignCustomer = (event, id) => {
        props.history.push(`/transactions/assign-customer/${id}`);
    }

    return (
        <ContentWrapper
            permissionDenied={false}
            pageTitle={""}
            showBackButton={false}
            backFunction={null}
            isLoading={false}
            showCardHeader={false}
            loadingSubText={""}
        >
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(key) => toggleTransactionProfileTab(key)}
            >
                <Tab
                eventKey="customer_assigned"
                title="Customer Assigned"
                tabClassName={key === "customer_assigned" ? "solbox-profile-tab" : ""}
                >
                <TabContent bsPrefix="solbox-tab-content">
                    <CustomerAssigned 
                        {...props} 
                        isSolshare={isSolshare}
                        b2bOrgGuid={b2bOrgGuid}
                    />
                </TabContent>
                </Tab>
                <Tab
                eventKey="customer_unassigned"
                title="Customer Unassigned"
                tabClassName={
                    key === "customer_unassigned" ? "solbox-profile-tab" : ""
                }
                >
                    <TabContent bsPrefix="solbox-tab-content">
                        <CustomerUnassigned 
                            {...props} 
                            isSolshare={isSolshare}
                            b2bOrgGuid={b2bOrgGuid}
                            redirectToAssignCustomer={redirectToAssignCustomer} 
                        />
                    </TabContent>
                </Tab>
            </Tabs>
    </ContentWrapper>
    );
}

export default withRoles(Transaction);
