import * as firebase from 'firebase/app';
import 'firebase/auth';
/*Firebase COnfiguration object*/
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID
};
var provider = new firebase.auth.GoogleAuthProvider();

class Firebase {
    constructor() {
        /*initalizing firebase object with the specified
        * firebase configuration file*/
        firebase.initializeApp(config);
        this.auth = firebase.auth();
    }

    // *** Auth API ***
    doSignInWithPhone = (phoneNumber, appVerifier) =>
        this.auth.signInWithPhoneNumber(phoneNumber, appVerifier);


    // *** Auth API ***
    /*funtion to attempt sign in with phone number. Sends OTP if the the user is valid*/
    signInWithPopup = (phoneNumber) =>
        this.auth.signInWithPopup(provider);
    /*Returns recatcha*/
    static reCaptcha () {
      return new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible'
      })
    }

    /*Firebase Funtion to sign out of firebase instance*/
    doSignOut = () => this.auth.signOut();

}
export default Firebase;
