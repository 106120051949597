import {commonActionTypes} from './commonActionTypes'
import {requestCycle} from "../grids/utils";
import {toast} from "react-toastify";
import {actionTypes} from "../grids/actionTypes";
import moment from "moment";
const initialState= {
    isLoading: false,
    liveData: {}
}

export const commonReducer = (state = initialState, action) => {

    switch (action.type) {
        case commonActionTypes.PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_REQUEST:
            return {
                ...state,
                loading: true,
                topicPublished: requestCycle.pending,
                liveDataFetched: undefined,
                liveDataLoadingMessage: 'Requesting Server for live Data',
            };

        case commonActionTypes.PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_SUCCESS:
            return {
                ...state,
                loading: true,
                topicPublished: requestCycle.success,
                liveDataFetched: undefined,
                liveDataLoadingMessage: 'Waiting for SOLbox\'s response',

            }

        case commonActionTypes.PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_FAILURE:
            return {
                ...state,
                loading: true,
                topicPublished: requestCycle.success,
                liveDataFetched: undefined,
                liveDataLoadingMessage: undefined,
                statusCode: action.payload.status,
            }

        case commonActionTypes.GET_LIVE_DATA_FOR_SINGLE_SOLBOX_REQUEST:
            return {
                ...state,
                loading: true,
                topicPublished: requestCycle.success,
                liveDataFetched: requestCycle.pending,
                liveDataLoadingMessage: 'Nearly there. Few more seconds',
            };

        case commonActionTypes.GET_LIVE_DATA_FOR_SINGLE_SOLBOX_SUCCESS:
            return {
                ...state,
                loading: false,
                refreshTime: moment(new Date()),
                topicPublished: requestCycle.success,
                liveDataLoadingMessage: undefined,
                liveData: action.payload,
                liveDataFetched: requestCycle.success,
            };

        case commonActionTypes.GET_LIVE_DATA_FOR_SINGLE_SOLBOX_FAILURE:
            return {
                ...state,
                loading: false,
                topicPublished: requestCycle.failed,
                liveDataLoadingMessage: undefined,
                liveData: action.payload,
                liveDataFetched: requestCycle.failed,
            };

        case commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_SUCCESS:
            return {
                ...state,
                isLoading: false,
                solBoxCurrentConfiguration: action.payload,

            };

        case commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_FAILURE:
            return {
                ...state,
                isLoading: false,

            };

        case commonActionTypes.UPDATE_SOLBOX_CONFIGURATION_REQUEST:
            return {
                ...state,
                disableButton: true,
                configurationUpdated: requestCycle.pending,
            };

        case commonActionTypes.UPDATE_SOLBOX_CONFIGURATION_SUCCESS:
            toast.success("Request Successful");
            return {
                ...state,
                disableButton: false,
                configurationUpdated: requestCycle.success,

            };

        case commonActionTypes.UPDATE_SOLBOX_CONFIGURATION_FAILURE:
            toast.error("Request Failed");
            return {
                ...state,
                configurationUpdated: requestCycle.failed,
                disableButton: false,

            };
        case commonActionTypes.FETCH_SOLBOX_CONFIG_HISTORY_REQUEST:
            return {
                ...state,
                historyLoading: true,
            };

        case commonActionTypes.FETCH_SOLBOX_CONFIG_HISTORY_SUCCESS:

            return {
                ...state,
                historyLoading: false,
                configurationHistory: action.payload,

            };

        case commonActionTypes.FETCH_SOLBOX_CONFIG_HISTORY_FAILURE:

            return {
                ...state,
                historyLoading: false,
                statusCode: action.statusCode
            };

        case commonActionTypes.GET_SOLBOX_PROFILE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case commonActionTypes.GET_SOLBOX_PROFILE_DATA_SUCCESS:

            return {
                ...state,
                loading: false,
                solboxDetails: action.payload,

            };

        case commonActionTypes.GET_SOLBOX_PROFILE_DATA_FAILURE:

            return {
                ...state,
                loading: false,
                // statusCode: action.statusCode
            };
        case commonActionTypes.SET_FLAGS_TO_UNDEFINED:
            return {
                ...state,
                loading: undefined,
                topicPublished: undefined,
                liveDataLoadingMessage: undefined,
                liveDataFetched: undefined,
                statusCode: undefined,
                solBoxCurrentConfiguration: undefined,
                configurationUpdated: undefined,
                isLoading: undefined,
                disableButton: undefined,
                configurationHistory: undefined,
                liveData: {},
                solboxDetails: undefined,
                legacyData: undefined,
            }
        case commonActionTypes.GET_LEGACY_LIVE_DATA_COMMON_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case commonActionTypes.GET_LEGACY_LIVE_DATA_COMMON_SUCCESS:
            return {
                ...state,
                refreshTime: moment(new Date()),
                // legacyOnlineBoxes: [
                //     {
                //         "i32Balance": 105,
                //         "solbox_guid": "f21b4794-9572-4329-8e61-548950e400fc",
                //         "u32TotalBoughtWh": 4,
                //         "u32TotalSoldWh": 1,
                //         "i32IToGrid": 44,
                //         "update_at": "",
                //         "solbox_id": "2110",
                //         "battery_voltage": '50'
                //     },
                // ].map((box)=>{
                //     return box.solbox_guid
                // }),
                // legacyOnlineBoxesWithData: [
                //     {
                //         "i32Balance": 105,
                //         "solbox_guid": "f21b4794-9572-4329-8e61-548950e400fc",
                //         "u32TotalBoughtWh": 4,
                //         "u32TotalSoldWh": 1,
                //         "i32IToGrid": 44,
                //         "update_at": "",
                //         "solbox_mode": 'Buy',
                //         "solbox_id": "2110",
                //         "battery_voltage": '50'
                //     },
                // ],
                legacyData: Array.isArray(action.payload) && action.payload.length>0?action.payload[0]:undefined,
                loading: false,
                statusCode: undefined,
            };

        case commonActionTypes.GET_LEGACY_LIVE_DATA_COMMON_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}