import React, { useState, useEffect } from "react";
import { transactionActions }   from "../transactionActions";
import { connect } from 'react-redux';
import SolTextInput from "../../../components/soltextinput/SolTextInput";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import { ExportToCsv } from 'export-to-csv';
import { Spinner } from "react-bootstrap";

const TransactionToolbar = (props) => {

    const { 
        tabKey,
        csvData,
        toggleFilterApplied,
        filterApplied,
        handleToggleFilter,
        transactionHistoryFilterParams,
        filterParamsExceptPagination,
        solboxId,
        handleChangeSolboxId,
        handleChangeSearchFlag,
        isTransactionHistoryLoading,
        isOrphanTransactionLoading,
        pageCount,
        transactionHistory,
        transactionWorth,
        orphanTxnWorth,
        orphanTransactions,
        csvDataLoading,
        transactionHistoryData,
        GET_TRANSACTION_HISTORY_LIST,
        GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION,
        CLEAN_GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION
    } = props;

    const [totalTransactionAmount, setTotalTransactionAmount] = useState(0);
    const [totalOrphanTransactionAmount, setOrphanTotalTransactionAmount] = useState(0);

    const searchSolbox = (event) => {
        const regex = /^[0-9]+$/g;
        if (event.key === 'Enter' &&
            transactionHistoryFilterParams &&
            solboxId &&
            solboxId.trim() &&
            regex.test(solboxId.trim())
        ) {
            GET_TRANSACTION_HISTORY_LIST(solboxId.trim(), transactionHistoryFilterParams);
            handleChangeSearchFlag(event);
            toggleFilterApplied(event, true);
        } else {
          if (event.key === 'Enter' && 
              solboxId &&
              solboxId.trim() &&
              regex.test(solboxId.trim())
            ) {
            GET_TRANSACTION_HISTORY_LIST(solboxId.trim(), {
              "pagination": "True",
              "is_in_last_two_days_transactions": "False" 
            });
            handleChangeSearchFlag(event);
            toggleFilterApplied(event, true);
          }
        }
    }

    useEffect(() => {
      if (transactionWorth) {
        setTotalTransactionAmount(transactionWorth);
      }
    }, [transactionWorth]);

    useEffect(() => {
      if (orphanTxnWorth) {
        setOrphanTotalTransactionAmount(orphanTxnWorth);
      }
    }, [orphanTransactions]);

    const [csvLoaderIcon, setCsvLoaderIcon] = useState(false);
    const [exportCsv, setExportCsv] = useState(false);

    const handleClickCsvLoaderIcon = (event) => {
        setExportCsv(true);
        CLEAN_GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION();
    }

    useEffect(() => {
        if (csvDataLoading !== undefined && csvDataLoading !== null && csvDataLoading === false) {
            setCsvLoaderIcon(false);
        }
        if (csvDataLoading !== undefined && csvDataLoading !== null && csvDataLoading === true) {
            setCsvLoaderIcon(true);
        }
    }, [csvDataLoading]);

    useEffect(() => {
      if (exportCsv === true && 
        filterParamsExceptPagination && 
        solboxId && 
        solboxId.trim()
      ) {
        GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION(
          solboxId.trim(), filterParamsExceptPagination
        );
      } else if (exportCsv === true && 
        !filterParamsExceptPagination && 
        solboxId && 
        solboxId.trim()
      ) {
        GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION(
          solboxId.trim(), {
            "pagination": "False",
            "is_in_last_two_days_transactions": "False" 
          }
        );
      } else if (exportCsv === true && filterParamsExceptPagination && !solboxId) {
        GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION(
          "", filterParamsExceptPagination
        );
      } else {
        if (exportCsv === true) {
          GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION(
            "", {
              "pagination": "False",
              "is_in_last_two_days_transactions": "False" 
            }
          );
        }
      }
    }, [exportCsv, solboxId, filterParamsExceptPagination]);

    useEffect(() => {
        if (exportCsv !== undefined &&
            exportCsv !== null &&
            exportCsv === true &&
            transactionHistoryData &&
            transactionHistoryData.length !== 0
        ) {    
            const options = { 
                fieldSeparator: ',',
                filename: `transactions_${moment().format("DD_MM_YYYY")}`,
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true, 
                showTitle: false,
                title: '',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };
            const csvExporter = new ExportToCsv(options);
            let data = [];
            transactionHistoryData.forEach((element, index) => {
              data.push({
                "ID": element.id ? element.id : `N/A`,
                "Guid": element.transaction_guid ? element.transaction_guid : `N/A`,
                "SOLbox": element.solbox_serial_number ? element.solbox_serial_number : `N/A`,
                "Status": element.status ? element.status : `N/A`,
                "Amount": element.amount ? element.amount : `N/A`,
                "Token": element.token ? element.token : `N/A`,
                "Retry Allowed": element.retry_allow ? `Yes` : `No`,
                "Attempted retry": element.retry_counter !== undefined && element.retry_counter !== null ? element.retry_counter : `N/A`,
                "Published at": element.last_published ? moment(element.last_published).format('lll') : `N/A`,
                "Transaction counter": element.txn_counter ? element.txn_counter : `N/A`
              });
            });
            csvExporter.generateCsv(data);
            setExportCsv(false);
        }
    }, [transactionHistoryData, exportCsv]);

    return (
      <React.Fragment>
        <div className={`row list-toolbar`}>
          <div className={"col-12 col-sm-7"}>
            <h3 className={`list-bold-text`}>
              {tabKey === "customer_assigned"
                ? pageCount &&
                  !isTransactionHistoryLoading
                  ? pageCount > 1
                    ? `${pageCount} Transactions Worth ${totalTransactionAmount} Tk`
                    : `${pageCount} Transaction Worth ${totalTransactionAmount} Tk`
                  : ``
                : orphanTransactions !== undefined &&
                  orphanTransactions !== null &&
                  orphanTransactions.length !== 0 &&
                  !isOrphanTransactionLoading &&
                  filterApplied
                ? orphanTransactions.length > 1
                  ? `${orphanTransactions.length} Transactions Worth ${totalOrphanTransactionAmount} Tk`
                  : `${orphanTransactions.length} Transaction Worth ${totalOrphanTransactionAmount} Tk`
                : ``}
            </h3>
          </div>
          <div className={`col-12 col-sm-5`}>
            <div className={`list-toolbar-actions-part float-right`}>
              {tabKey === "customer_assigned" && (
                <div className="form-group d-inline-block">
                  <SolTextInput
                    name={`idOfSolbox`}
                    placeHolder="Type SOLbox ID..."
                    value={solboxId}
                    handleChange={handleChangeSolboxId}
                    handleKeyDown={searchSolbox}
                  />
                </div>
              )}
              <div
                className={`list-download-icon d-inline-block ml-5`}
              >
                {filterApplied === true && (
                  csvLoaderIcon ?
                    <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>
                    :
                    <img 
                      className="inter-module-link"
                      src={require('../../../utils/asset/download-Button.svg')} 
                      alt="download icon"
                      onClick={(event) => handleClickCsvLoaderIcon(event)}
                    />
                )}
              </div>
              <div
                className={`list-toolbar-actions list-filter-icon d-inline-block ml-5`}
              >
                <FilterListIcon
                  color="disabled"
                  fontSize="large"
                  style={{ marginRight: "0.5rem" }}
                  onClick={(event) => handleToggleFilter(event)}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isTransactionHistoryLoading: state.transactionReducer.isTransactionHistoryLoading,
        isOrphanTransactionLoading: state.transactionReducer.isOrphanTransactionLoading,
        statusCode: state.transactionReducer.statusCode,
        pageCount: state.transactionReducer.pageCount,
        transactionHistory: state.transactionReducer.transactionHistory,
        transactionWorth: state.transactionReducer.transactionWorth,
        orphanTxnWorth: state.transactionReducer.orphanTxnWorth,
        orphanTransactions: state.transactionReducer.orphanTransactions,
        csvDataLoading: state.transactionReducer.csvDataLoading,
        transactionHistoryData: state.transactionReducer.transactionHistoryData
    }
};

export default connect(mapStateToProps, transactionActions)(TransactionToolbar);
