import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_OTA_UPDATE_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/firmware/ota_history/`, filter),
            method: 'GET',
            types: [
                actionTypes.GET_OTA_UPDATE_LIST_REQUEST,
                actionTypes.GET_OTA_UPDATE_LIST_SUCCESS,
                actionTypes.GET_OTA_UPDATE_LIST_FAILURE,
            ]
        }
    }),

    GET_OTA_UPDATE_LIST_WITH_FILTER: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/firmware/ota_history/`, filter),
            method: 'GET',
            types: [
                actionTypes.GET_OTA_UPDATE_LIST_FILTER_REQUEST,
                actionTypes.GET_OTA_UPDATE_LIST_SUCCESS,
                actionTypes.GET_OTA_UPDATE_LIST_FAILURE,
            ]
        }
    }),

    GET_OTA_UPDATE_LIST_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/firmware/ota_history/`, filter),
            method: 'GET',
            types: [
                actionTypes.GET_OTA_UPDATE_LIST_FOR_EXPORT_REQUEST,
                actionTypes.GET_OTA_UPDATE_LIST_FOR_EXPORT_SUCCESS,
                actionTypes.GET_OTA_UPDATE_LIST_FOR_EXPORT_FAILURE
            ]
        }
    }),

    GET_GRID_OWNERS_LIST: ()=>({
        [CALL_API]: {
            endpoint: `/grid/organization/list/`,
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRID_OWNERS_LIST_REQUEST,
                actionTypes.GET_GRID_OWNERS_LIST_SUCCESS,
                actionTypes.GET_GRID_OWNERS_LIST_FAILURE,
            ]
        }
    }),

    GET_MODELS_LIST: () => ({
        [CALL_API]: {
            endpoint: `/solmodel-list/`,
            method: 'GET',
            types: [
                actionTypes.GET_MODELS_LIST_REQUEST,
                actionTypes.GET_MODELS_LIST_SUCCESS,
                actionTypes.GET_MODELS_LIST_FAILURE,
            ]
        }
    }),

    GET_GRID_LIST: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/get_list_details/`, filter),
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                actionTypes.GET_GRID_LIST_REQUEST,
                actionTypes.GET_GRID_LIST_SUCCESS,
                actionTypes.GET_GRID_LIST_FAILURE
            ],
        },
    }),

    SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE: (payload) => ({
        [CALL_API]: {
            endpoint: `/firmware/create_ota/`,
            method: "POST",
            body: payload,
            types: [
                actionTypes.SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_REQUEST,
                actionTypes.SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_SUCCESS,
                actionTypes.SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_FAILURE
            ],
        },
    }),

    RETRY_OTA: (payload) => ({
        [CALL_API]: {
            endpoint: `/firmware/retry_ota/`,
            method: "POST",
            body: payload,
            types: [
                actionTypes.RETRY_OTA_REQUEST,
                actionTypes.RETRY_OTA_SUCCESS,
                actionTypes.RETRY_OTA_FAILURE
            ],
        },
    }),

    COLLECT_SOLBOX_OTA_DETAILS: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler(`/solbox/get_solbox_ota_details/`, filter),
            method: "GET",
            types: [
                actionTypes.COLLECT_SOLBOX_OTA_DETAILS_REQUEST,
                actionTypes.COLLECT_SOLBOX_OTA_DETAILS_SUCCESS,
                actionTypes.COLLECT_SOLBOX_OTA_DETAILS_FAILURE
            ],
        },
    })
};
