import React from 'react';
import {Spinner} from "react-bootstrap";
import {LoadingSpinner} from "../../features/grids/utils";
import {SolTypography} from "../utilityComponents/SOlTypography";

const Loader = ({text,loadingSubText,size}) => {
    return (
        <div className="align-content-center" align="center" style={{"marginTop": "1rem"}}>
            <h2 className={`mb-5`}>{text?text:`Fetching Data...`}</h2>
            <Spinner className={`mt-5`} animation="border" variant="warning" size={size || undefined}/>
            <br/>
            <SolTypography.SubTitle primary> {loadingSubText} </SolTypography.SubTitle>
        </div>
    );
};

export default Loader;