export const solboxActionTypes = {

    GET_SOLBOX_LIST_REQUEST: 'GET_SOLBOX_LIST_REQUEST',
    GET_SOLBOX_LIST_SUCCESS: 'GET_SOLBOX_LIST_SUCCESS',
    GET_SOLBOX_LIST_FAILURE: 'GET_SOLBOX_LIST_FAILURE',
    CLEAN_UP_SOLBOX_LIST: 'CLEAN_UP_SOLBOX_LIST',

    GET_PAGINATED_SOLBOX_LIST_REQUEST: 'GET_PAGINATED_SOLBOX_LIST_REQUEST',
    GET_PAGINATED_SOLBOX_LIST_SUCCESS: 'GET_PAGINATED_SOLBOX_LIST_SUCCESS',
    GET_PAGINATED_SOLBOX_LIST_FAILURE: 'GET_PAGINATED_SOLBOX_LIST_FAILURE',

    GET_NODE_TYPES_REQUEST: 'GET_NODE_TYPES_REQUEST',
    GET_NODE_TYPES_SUCCESS: 'GET_NODE_TYPES_SUCCESS',
    GET_NODE_TYPES_FAILURE: 'GET_NODE_TYPES_FAILURE',

    GET_FILTERED_SOLBOX_LIST_REQUEST: 'GET_FILTERED_SOLBOX_LIST_REQUEST',
    GET_FILTERED_SOLBOX_LIST_SUCCESS: 'GET_FILTERED_SOLBOX_LIST_SUCCESS',
    GET_FILTERED_SOLBOX_LIST_FAILURE: 'GET_FILTERED_SOLBOX_LIST_FAILURE',

    GET_SEARCHED_SOLBOX_LIST_REQUEST: 'GET_SEARCHED_SOLBOX_LIST_REQUEST',
    GET_SEARCHED_SOLBOX_LIST_SUCCESS: 'GET_SEARCHED_SOLBOX_LIST_SUCCESS',
    GET_SEARCHED_SOLBOX_LIST_FAILURE: 'GET_SEARCHED_SOLBOX_LIST_FAILURE',

    GET_GRID_LIST_WITHOUT_PAGINATION_REQUEST: 'GET_GRID_LIST_WITHOUT_PAGINATION_REQUEST',
    GET_GRID_LIST_WITHOUT_PAGINATION_SUCCESS: 'GET_GRID_LIST_WITHOUT_PAGINATION_SUCCESS',
    GET_GRID_LIST_WITHOUT_PAGINATION_FAILURE: 'GET_GRID_LIST_WITHOUT_PAGINATION_FAILURE',

    GET_MODELS_LIST_REQUEST: 'GET_MODELS_LIST_REQUEST',
    GET_MODELS_LIST_SUCCESS: 'GET_MODELS_LIST_SUCCESS',
    GET_MODELS_LIST_FAILURE: 'GET_MODELS_LIST_FAILURE',

    GET_GRID_OWNERS_LIST_REQUEST: 'GET_GRID_OWNERS_LIST_REQUEST',
    GET_GRID_OWNERS_LIST_SUCCESS: 'GET_GRID_OWNERS_LIST_SUCCESS',
    GET_GRID_OWNERS_LIST_FAILURE: 'GET_GRID_OWNERS_LIST_FAILURE',

    GET_SOLBOX_GENERAL_DATA_REQUEST: 'GET_SOLBOX_GENERAL_DATA_REQUEST',
    GET_SOLBOX_GENERAL_DATA_SUCCESS: 'GET_SOLBOX_GENERAL_DATA_SUCCESS',
    GET_SOLBOX_GENERAL_DATA_FAILURE: 'GET_SOLBOX_GENERAL_DATA_FAILURE',

    POST_SOLBOX_PUBLISH_TOPIC_REQUEST: 'POST_SOLBOX_PUBLISH_TOPIC_REQUEST',
    POST_SOLBOX_PUBLISH_TOPIC_SUCCESS: 'POST_SOLBOX_PUBLISH_TOPIC_SUCCESS',
    POST_SOLBOX_PUBLISH_TOPIC_FAILURE: 'POST_SOLBOX_PUBLISH_TOPIC_FAILURE',

    GET_SOLBOX_BULK_LIVE_DATA_REQUEST: 'GET_SOLBOX_BULK_LIVE_DATA_REQUEST',
    GET_SOLBOX_BULK_LIVE_DATA_SUCCESS: 'GET_SOLBOX_BULK_LIVE_DATA_SUCCESS',
    GET_SOLBOX_BULK_LIVE_DATA_FAILURE: 'GET_SOLBOX_BULK_LIVE_DATA_FAILURE',

    GET_SOLBOX_PERFORMANCE_METRICS_DATA_REQUEST: 'GET_SOLBOX_PERFORMANCE_METRICS_DATA_REQUEST',
    GET_SOLBOX_PERFORMANCE_METRICS_DATA_SUCCESS: 'GET_SOLBOX_PERFORMANCE_METRICS_DATA_SUCCESS',
    GET_SOLBOX_PERFORMANCE_METRICS_DATA_FAILURE: 'GET_SOLBOX_PERFORMANCE_METRICS_DATA_FAILURE',

    GET_SOLBOX_CONFIGURATION_HISTORY_REQUEST: 'GET_SOLBOX_CONFIGURATION_HISTORY_REQUEST',
    GET_SOLBOX_CONFIGURATION_HISTORY_SUCCESS: 'GET_SOLBOX_CONFIGURATION_HISTORY_SUCCESS',
    GET_SOLBOX_CONFIGURATION_HISTORY_FAILURE: 'GET_SOLBOX_CONFIGURATION_HISTORY_FAILURE',

    FETCH_TRANSACTION_TYPES_REQUEST: 'FETCH_TRANSACTION_TYPES_REQUEST',
    FETCH_TRANSACTION_TYPES_SUCCESS: 'FETCH_TRANSACTION_TYPES_SUCCESS',
    FETCH_TRANSACTION_TYPES_FAILURE: 'FETCH_TRANSACTION_TYPES_FAILURE',

    CREATE_SOLBOX_REQUEST: 'CREATE_SOLBOX_REQUEST',
    CREATE_SOLBOX_SUCCESS: 'CREATE_SOLBOX_SUCCESS',
    CREATE_SOLBOX_FAILURE: 'CREATE_SOLBOX_FAILURE',

    GET_TRANSACTION_HISTORY_LIST_REQUEST: 'GET_TRANSACTION_HISTORY_LIST_REQUEST',
    GET_TRANSACTION_HISTORY_LIST_SUCCESS: 'GET_TRANSACTION_HISTORY_LIST_SUCCESS',
    GET_TRANSACTION_HISTORY_LIST_FAILURE: 'GET_TRANSACTION_HISTORY_LIST_FAILURE',

    GET_SOLBOX_FIRMWARE_VERSION_REQUEST: 'GET_SOLBOX_FIRMWARE_VERSION_REQUEST',
    GET_SOLBOX_FIRMWARE_VERSION_SUCCESS: 'GET_SOLBOX_FIRMWARE_VERSION_SUCCESS',
    GET_SOLBOX_FIRMWARE_VERSION_FAILURE: 'GET_SOLBOX_FIRMWARE_VERSION_FAILURE',

    FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST: 'FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST',
    FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS: 'FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS',
    FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE: 'FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE',

    FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST: 'FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST',
    FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS: 'FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS',
    FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE: 'FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE',

    FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_REQUEST: 'FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_REQUEST',
    FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_SUCCESS: 'FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_SUCCESS',
    FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_FAILURE: 'FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_FAILURE',

    FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_REQUEST: 'FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_REQUEST',
    FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_SUCCESS: 'FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_SUCCESS',
    FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_FAILURE: 'FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_FAILURE',

    FETCH_SOLBOX_TRADING_CHART_DATA_REQUEST: 'FETCH_SOLBOX_TRADING_CHART_DATA_REQUEST',
    FETCH_SOLBOX_TRADING_CHART_DATA_SUCCESS: 'FETCH_SOLBOX_TRADING_CHART_DATA_SUCCESS',
    FETCH_SOLBOX_TRADING_CHART_DATA_FAILURE: 'FETCH_SOLBOX_TRADING_CHART_DATA_FAILURE',

    FETCH_SOLBOX_FIRMWARE_LIST_REQUEST: 'FETCH_SOLBOX_FIRMWARE_LIST_REQUEST',
    FETCH_SOLBOX_FIRMWARE_LIST_SUCCESS: 'FETCH_SOLBOX_FIRMWARE_LIST_SUCCESS',
    FETCH_SOLBOX_FIRMWARE_LIST_FAILURE: 'FETCH_SOLBOX_FIRMWARE_LIST_FAILURE',

    FETCH_SOLBOX_LIFE_CYCLE_STAGE_REQUEST: 'FETCH_SOLBOX_LIFE_CYCLE_STAGE_REQUEST',
    FETCH_SOLBOX_LIFE_CYCLE_STAGE_SUCCESS: 'FETCH_SOLBOX_LIFE_CYCLE_STAGE_SUCCESS',
    FETCH_SOLBOX_LIFE_CYCLE_STAGE_FAILURE: 'FETCH_SOLBOX_LIFE_CYCLE_STAGE_FAILURE',

    UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_REQUEST: 'UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_REQUEST',
    UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_SUCCESS: 'UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_SUCCESS',
    UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_FAILURE: 'UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_FAILURE',

    SEND_OTA_UPDATE_REQUEST: 'SEND_OTA_UPDATE_REQUEST',
    SEND_OTA_UPDATE_SUCCESS: 'SEND_OTA_UPDATE_SUCCESS',
    SEND_OTA_UPDATE_FAILURE: 'SEND_OTA_UPDATE_FAILURE'
}
