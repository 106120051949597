
export const actionTypes = {
     TOKEN_REFRESH_REQUEST : 'TOKEN_REFRESH_REQUEST',
     TOKEN_REFRESH_SUCCESS : 'TOKEN_REFRESH_SUCCESS',
     TOKEN_REFRESH_FAILURE : 'TOKEN_REFRESH_FAILURE',
     LOGIN : 'LOGIN',
     LOGOUT : "LOGOUT",
     REGISTER : "REGISTER",
     USERREQUESTED : "USER_REQUESTED",
     SET_TOKEN : "SET_TOKEN",
     SEND_OTP : "SEND_OTP",
     SET_CONFIRMATION : "SET_CONFIRMATION",
     CONFIRM_OTP : "VERIFY_OTP",
     VERIFY_AUTH : "VERIFY_AUTH",
     REFRESH_AUTH_TOKEN : "REFRESH_TOKEN",
     AUTH_ERROR : "AUTH_ERROR",
     VERIFY_PHONE : "VERIFY_PHONE",
     CHECK_GOOGLE_USER: 'CHECK_GOOGLE_USER',
     POPUP_SIGNIN: 'POPUP_SIGNIN',
     SET_ROLE: "SET_ROLE",
     START_LOADING_STATE: "START_LOADING_STATE",
     DISABLE_LOADING: "DISABLE_LOADING",
     DISABLE_LOADING_IN_OTP: "DISABLE_LOADING_IN_OTP",
     START_EMAIL_LOADING_STATE: "START_EMAIL_LOADING_STATE",
     CLEAR_CONFIRMATION_OBJECT: "CLEAR_CONFIRMATION_OBJECT",

};