import React, { useState, useEffect, useRef } from "react";
import SolTextInput from "./soltextinput/SolTextInput";
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

const SolSingleDatePicker = (props) => {

    const { 
      date,
      singleDate,
      handleDate,
      dateRangelabel,
      customLabel 
    } = props;

    const [focused, setFocused] = useState(true);
    const [show, setShow] = useState(0);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleDatesChange = (date) => {
        handleDate(date);
    }

    const handleFocusChange = () => {
        setFocused(true);
    }

    const handleShowCalendar = (event) => {
        setShow(!show);
        setTarget(event.target);
    }

    const handleHide = (event) => {
        setShow(false);
    }

    const isOutsideRange = (day) => {
      return day.startOf('day').isAfter(moment());
    }    

    return (
      <React.Fragment>
        <label className={`form-check-label mb-5 ${customLabel}`}>{dateRangelabel}</label>
        <div className="form-group d-block">
          <SolTextInput
            inputClassName={`custom-text-input-style`}
            name={`transactionDate`}
            placeHolder="Select Single Date"
            value={singleDate}
            handleOnClick={(event) => handleShowCalendar(event)}
            readOnly={true}
          />
        </div>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref.current}
        >
          <Popover id="popover-contained" placement={`bottom`} bsPrefix={`sol-daterange-picker`}>
            <Popover.Content>
              <DayPickerSingleDateController
                date={date} // momentPropTypes.momentObj or null
                onDateChange={handleDatesChange} // PropTypes.func.isRequired
                focused={focused} // PropTypes.bool
                onFocusChange={handleFocusChange} // PropTypes.func.isRequired
                onOutsideClick={(event) => handleHide(event)}
                isOutsideRange={isOutsideRange}
              />
            </Popover.Content>
          </Popover>
        </Overlay>
      </React.Fragment>
    );
}

export default SolSingleDatePicker;