import Select from "react-select";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {forEach} from "react-bootstrap/cjs/ElementChildren";
import {isNullorUndefined, JsonToUriSerialize, validateValue} from "../../../../utils/utilityFunctions";


const FormLabel =(props)=>{
    return <label className={`form-check-label d-block mb-5`} style={{color: '#8C8C9B'}}>
        {props.children}
    </label>
}

export const FilterForm = forwardRef(({models,modelSelectLoading,customers,customerSelectLoading,onFilter,...props}, ref)=>{
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const [type, setType]= useState(null);
    const [mode, setMode]= useState(null);
    const [model, setModel]= useState(null);
    const [customer, setCustomer]= useState(null);
    const [onlineStatus, setOnlineStatus]= useState(false);
    const [offlineStatus, setOfflineStatus]= useState(false);
    const [positive, setPositive]= useState(false);
    const [negative, setNegative]= useState(false);
    const [initial1filterParam, setInitial1filterParam]= useState(null);
    const boxTypes = [
        {label: "Prosumer", value: 'Prosumer'},
        {label: "Consumer", value: 'Consumer'},
        {label: "Producer", value: 'Producer'},
        {label: "Microutility", value: 'Microutility'},
    ];

    const solbox_status_valid_values = ['online', 'offline'];

    const iToGrid_valid_values = ['positive', 'negative'];

    const modes = [
        {label: "Buy", value: 'Buy'},
        {label: "Sell", value: 'Sell'},
        {label: "Auto", value: 'Auto'},
        {label: "Stop", value: 'Stop'},
    ];

    const validationKeyArray = [
        {
            parameter: 'mode',
            list: modes,
        },
        {
            parameter: 'iToGrid',
            list: iToGrid_valid_values,
        },
        {
            parameter: 'status',
            list: solbox_status_valid_values,
        },
        {
            parameter: 'solbox_model',
            list: models,
        },
        {
            parameter: 'customer_guid',
            list: customers
        },
        {
            parameter: 'node_type',
            list: boxTypes,
        }];

    useImperativeHandle(
        ref,
        () => ({
            onReset: onReset,
        }),
    )


    useEffect(()=>{
        let keys = null;
        const validParameters = {};

        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(props.projectedParams);
        }catch (e){
            console.log(e)
            return;
        }

        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length>0) {

            /* Looping through each of the value in the keys. (Essentially This loop will run for every keys in the projected params object) */

            keys.forEach((item)=>{
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const currentIndex = validationKeyArray.findIndex((element)=>{
                    return element.parameter === item;
                });

                if (currentIndex >-1 && !isNullorUndefined(validateValue(props.projectedParams[item], validationKeyArray[currentIndex]))) {
                    // console.log(validationKeyArray[currentIndex].list);
                    Object.assign(validParameters, {[`${item}`] : validateValue(props.projectedParams[item], validationKeyArray[currentIndex])})
                }
            });
        }

        setInitial1filterParam(Object.keys(validParameters?.length>0)? {...validParameters} : null)

        /*Now we validated the */

        /* Now if the valid Params object has at least one parameter we set the filter */

    }, [props.projectedParams, models, customers])

    useEffect(()=>{
        if (!isNullorUndefined(initial1filterParam)){

            const frontEndFilterPayload = {};

            if (initial1filterParam.mode) {setMode(initial1filterParam.mode); Object.assign(frontEndFilterPayload, {mode: initial1filterParam.mode.value,})}

            if (initial1filterParam.solbox_model) {
                setModel(initial1filterParam.solbox_model);
                Object.assign(frontEndFilterPayload, {solbox_model: initial1filterParam.solbox_model.value,})
            }
            if (initial1filterParam.customer) {
                setCustomer(initial1filterParam.customer_guid);
                Object.assign(frontEndFilterPayload, {customer_guid: initial1filterParam.customer_guid.value,})
            }
            if (initial1filterParam.node_type) {
                setType(initial1filterParam.node_type);
                Object.assign(frontEndFilterPayload, {node_type: initial1filterParam.node_type.value})
            }

            if (initial1filterParam.iToGrid) {
                if (initial1filterParam.iToGrid.toString().toLowerCase() === 'negative'){
                    setPositive(false);
                    setNegative(true);
                    Object.assign(frontEndFilterPayload, {iToGrid: initial1filterParam.iToGrid.toString().toLowerCase()})
                }else if ( initial1filterParam.iToGrid.toString().toLowerCase() === 'positive'){
                    setPositive(true);
                    setNegative(false);
                    Object.assign(frontEndFilterPayload, {iToGrid: initial1filterParam.iToGrid.toString().toLowerCase()})
                }
            }

            if (initial1filterParam.status) {
                if ( initial1filterParam.status.toString().toLowerCase()  === 'online'){
                    setOnlineStatus(true);
                    setOfflineStatus(false);
                    Object.assign(frontEndFilterPayload, {status: initial1filterParam.status.toString().toLowerCase() ,})
                } else if ( initial1filterParam.status.toString().toLowerCase()  === 'offline'){
                    setOnlineStatus(false);
                    setOfflineStatus(true);
                    Object.assign(frontEndFilterPayload, {status: initial1filterParam.status.toString().toLowerCase() })
                }
            }

            if ( Object.keys(frontEndFilterPayload).length>0 ) onFilter({backEndFilterpayload: {}, frontEndFilterPayload})

            setInitial1filterParam(null);
        }
    },[initial1filterParam])


    const validateForm=(data)=>{
        if (!mode && !type && !model && !customer && !data.online && !data.offline && !data.positive && !data.negative  && !data.phone)
        {
            setError("validationError", {
                type: "manual",
                message: "Dont Forget Your Username Should Be Cool!"
            });
            toast.error("Please select at least one filter")
            return false
        } else {
            return true
        }
    }

    const submit=(data)=>{
        if (validateForm(data)===true){

            const backEndFilterpayload = {
                // solbox_model: model?.label,
                // node_type: type?.value,
            }
            const frontEndFilterPayload = {
                mode: mode?.value,
                iToGrid: positive===negative? null: positive?'positive':'negative',
                status: onlineStatus===offlineStatus? null: onlineStatus?"online":"offline",
                solbox_model: model?.label,
                customer_guid: customer?.value,
                node_type: type?.value
            }
            onFilter({backEndFilterpayload, frontEndFilterPayload})
        }
    }

    const onReset=()=>{
        setPositive(false);
        setNegative(false);
        setOfflineStatus(false);
        setOnlineStatus(false);
        setModel(null);
        setCustomer(null);
        setMode(null);
        setType(null);
        props.onReset();
    }

    return <>
        <form onSubmit={handleSubmit(submit)}>
            <div style={{
                marginTop: "1.5rem"
            }}>
                <h5 className={`list-filter-gap`}>{`FILTER`}</h5>
                <div className={`row list-filter-gap`}>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            SOLbox Type
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Type`}
                            isClearable={true}
                            options={boxTypes}
                            value={type}
                            onChange={(selected) => {
                                setType(selected)
                                clearErrors()
                            }}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            SOLbox Mode
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Mode`}
                            isClearable={true}
                            options={modes}
                            value={mode}
                            noOptionsMessage={() => `No Mode Available`}
                            onChange={(selected) => {
                                setMode(selected)
                                clearErrors()

                            }}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            SOLbox Model
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Model`}
                            isLoading={modelSelectLoading}
                            options={models}
                            isClearable={true}
                            value={model}
                            noOptionsMessage={() => `No grid available`}
                            onChange={(selected) => {
                                setModel(selected)
                                clearErrors();
                            }}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Customer name
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Customer`}
                            isLoading={customerSelectLoading}
                            options={customers}
                            isClearable={true}
                            value={customer}
                            noOptionsMessage={() => `No customer available`}
                            onChange={(selected) => {
                                setCustomer(selected)
                                clearErrors();
                            }}
                        />
                    </div>
                </div>
                <div className={`row list-filter-gap`}>
                    <div className={`col-sm-3`}>
                        <FormLabel>
                            Current to Grid
                        </FormLabel>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="positive"
                                id="Online"
                                ref={register()}
                                checked={positive}
                                value={`positive`}
                                onClick={()=>{
                                    clearErrors();
                                    if (positive){
                                        setPositive(false)
                                    }else {
                                        setNegative(false);
                                        setPositive(true);
                                    }
                                }}
                            />
                            <label className="form-check-label" htmlFor="Online">Positive</label>
                        </div>
                        <div className="form-check form-check-inline customer-status-inactive">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="negative"
                                id="Offline"
                                ref={register()}
                                checked={negative}
                                value={`negative`}
                                onClick={()=>{
                                    clearErrors();
                                    if (negative){
                                        setNegative(false)
                                    }else {
                                        setNegative(true);
                                        setPositive(false);
                                    }
                                }}
                            />
                            <label className="form-check-label" htmlFor="Offline">Negative</label>
                        </div>
                    </div>
                    <div className={`col-sm-5 mb-2`}>
                        <FormLabel>
                            Online Status
                        </FormLabel>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="online"
                                ref={register}
                                id="Flashed"
                                checked={onlineStatus}
                                value={`online`}
                                onChange={()=>{
                                    clearErrors();
                                }}
                                onClick={()=>{
                                    clearErrors();
                                    if (onlineStatus){
                                        setOnlineStatus(false)
                                    }else {
                                        setOfflineStatus(false);
                                        setOnlineStatus(true);
                                    }}
                                }
                            />
                            <label className="form-check-label" htmlFor="Flashed">Online</label>
                        </div>
                        <div className="form-check form-check-inline customer-status-inactive">
                            <input
                                className="form-check-input"
                                type="radio"
                                ref={register}
                                name="offline"
                                id="ID Created"
                                checked={offlineStatus}
                                value={`offline`}
                                onClick={()=>{
                                    clearErrors();
                                    if (offlineStatus){
                                        setOfflineStatus(false)
                                    }else {
                                        setOfflineStatus(true);
                                        setOnlineStatus(false);
                                    }
                                }}
                                onChange={()=>{
                                    clearErrors();
                                }}
                            />
                            <label className="form-check-label" htmlFor="ID Created">Offline</label>
                        </div>
                    </div>
                </div>
                <div className={`row mb-5`}>
                    <div className={`col-12`}>
                        <button
                            type={`submit`}
                            className={'btn btn-outline-warning btn-sm mb-5'}
                        >
                            {`Apply Filter`}
                        </button>
                        <button
                            type={`button`}
                            className={'btn btn-outline-secondary btn-sm ml-4 mb-5 reset-button-style'}
                            style={{border: "1px solid #000000"}}
                            onClick={onReset}
                        >
                            {`Reset Filter`}
                        </button>
                    </div>
                </div>

            </div>
        </form>
    </>
})

