import React, {useEffect, useState} from "react";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {Controller, useForm} from "react-hook-form";
import './style.scss'
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {generatePdfWithTable} from "../../../../utils/exportPDF/exportPdfWithTable";
import {connect} from "react-redux";
import {solboxActions as actions} from "../../solboxActions";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import moment from "moment";
import {toast} from "react-toastify";


const NewSolBox=(props)=>{
    const {register, handleSubmit, errors, control, clearErrors,setError} = useForm();
    const [modelList, setModelList]= useState([]);
    const [newSolboxList, setNewSolboxList]= useState([]);
    const [model, setModel]= useState(null);
    const [showModal, setShowModal]= useState(false);
    const [numberOfSolboxes, setNumberOfSolboxes] = useState(null)


    /* Running On load*/
    useEffect(()=>{
        fetchModels()
    },[])

    const fetchModels=()=>{
        props.GET_MODELS_LIST()
    }


    /* Mapping the Model List data */
    useEffect(()=>{
        if (props.modelList && props.modelList.length)
        {

            let temp =props.modelList.map((model, index)=>{
                return {
                    ...model,
                    value: model.id,
                    label: model.model_alias,
                }
            })

            setModelList(temp.filter((model,index)=>{
                if (model.label !== 'inactive' && model.status !== false) {
                    let splittedLabel = model.label.split('.')
                    let modelNoFirstPart = splittedLabel[0].charAt(2)
                    let modelNoSecondPart = splittedLabel[1].slice(0,2)
                    let convertionMethod = Math.floor
                    let approved = false
                    if (convertionMethod(modelNoFirstPart) === 3 && convertionMethod(modelNoSecondPart) >= 5) {
                        approved = true
                    }
                    return approved === true
                }
            }));
        }
    },[props.modelList])

    /* Mapping the SOLbox List data */
    useEffect(()=>{
        if (props.newSolboxList && props.newSolboxList.length)
        {
            setNewSolboxList(props.newSolboxList.map((solbox, index)=>{
                return {
                    ...solbox,
                    created_at: new DateTimeFormat(solbox.created_at).localDisplayTime
                }
            }));
        }

    },[props.newSolboxList])


    useEffect(()=>{
        if (props.solboxCreated===true){
            setShowModal(true)
        }
        else {
            setShowModal(false)
        }
    },[props.solboxCreated])

    const columns=()=> {
        return [
            { created_at: 'Created at', solbox_serial_number: 'SOLbox Serial Number',  },
        ]
    }



    /*Validatoin */
    const validateForm=(data)=>{
        if (!model )
        {
            setError("model", {
                type: "manual",
                message: "Model is required"
            });
            return false
        }else{
            return true
        }
    }
    /* Render the main Body */
    const submit=(data)=>{
        if (validateForm())
        {
            setNumberOfSolboxes(data.numberOfSolBoxes);
            const payload = {
                solbox_model: model.id.toString(),
                qty: parseInt(data.numberOfSolBoxes),
                user_guid: props.user_guid,
            }
            props.CREATE_SOLBOX(payload);
        }

    }


    /*Cancle Button*/
    const cancelButton=()=>{
        props.history.push('/solboxes')
    }



    /*Generate PDF*/
    const generatePDF=()=>{
        generatePdfWithTable(
            columns(),
            newSolboxList,
            [{label: 'Model', data: `${newSolboxList.length>0?model.label:'N/A'}`},
            {label: 'Firmware Version', data: `${newSolboxList.length>0? newSolboxList[0].firmware_version:'N/A'}`},
            {label: 'Total SOLboxes Created', data: `${numberOfSolboxes? numberOfSolboxes:'N/A'}`},
            ],
            (doc)=>{
                const pageSize = doc.internal.pageSize
                const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
                return {
                    solbox_serial_number: {   halign: 'center',},
                    created_at: {  cellWidth: (pageWidth - 100)/2, halign: 'left' },
                }
            },
            {
                solbox_serial_number: 'center',
                created_at: 'left'
            },
            'New SOLbox List', `new_solbox_list_${moment().format('Do')}_${moment().format('MMMM')}_${moment().format('YYYY')}`)
        setShowModal(false);
    }

    const renderBody =()=>{
        return <>
            <div className={"col-md-12 no-padding"}>
                <Form  onSubmit={handleSubmit(submit)} >
                    <Form.Group>
                        <div className={'row'}>
                            <div className={'col-md-2 div-element-center'}>
                                <Form.Label>{`Select Model`}<span className={"text-danger"}>*</span></Form.Label>
                            </div>
                            <div className="col-md-10">
                                <Select
                                    name={`model`}
                                    placeholder={`SOLbox Model`}
                                    classNamePrefix="react-select-sol-style"
                                    isDisabled={false}
                                    isLoading={false}
                                    maxMenuHeight={200}
                                    isClearable={true}
                                    options={modelList}
                                    value={model}
                                    control={control}
                                    isSearchable={true}
                                    onChange={(selected) => {
                                        setModel(selected)
                                        clearErrors()
                                        return selected
                                    }}
                                />

                                {errors.model && <div className={"text-danger"}>
                                    <i className={"flaticon-warning kt-font-brand"}/>{errors.model.message}</div>}
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <div className={'row'}>
                            <div className={'col-md-2 div-element-center'}>
                                <Form.Label>{`Number Of SOLbox`}<span className={"text-danger"}>*</span></Form.Label>
                            </div>
                            <div className="col-md-10">
                                <Form.Control
                                    name={'numberOfSolBoxes'}
                                    placeholder="Enter the number of SOLboxes you want to create (Max: 50)"
                                    ref={register({
                                        required: "Required",
                                        pattern: {
                                            value: /^[1-9]$|^0[1-9]$|^1[0-9]$|^2[0-9]$|3[0-9]$|4[0-9]$|50$/,
                                            message: "Enter a valid Number. Must be between 1-50"
                                        }
                                    })}
                                />
                                {errors.numberOfSolBoxes && <div className={"text-danger"}>
                                    <i className={"flaticon-warning kt-font-brand"}/>{errors.numberOfSolBoxes.message}</div>}
                            </div>
                        </div>
                    </Form.Group>


                    <Form.Group>
                        <div className={'row'}>
                            <div className={'col-md-2 div-element-center'}>
                                <Form.Label className={'col-md-1'}/>
                            </div>
                            <div className="col-md-10">
                                {/*<Button variant="primary" type={'submit'} disabled={props.disableButton}>*/}
                                {/*    {props.disableButton ? <Spinner animation="border" variant="secondary" />:<i className="fa fa-paper-plane"/>}*/}
                                {/*    &nbsp;Submit*/}
                                {/*</Button>*/}
                                {/*/!*<Link to={"/solboxes/list"} className={"ml-4"}>cancel</Link>*!/*/}
                                {/*&nbsp;*/}
                                {/*&nbsp;*/}
                                {/*<Button style={{*/}
                                {/*    backgroundColor: '#6c757d',*/}
                                {/*    border: 'none',*/}
                                {/*}} disabled={props.disableButton} onClick={cancelButton} >*/}
                                {/*    <i className="fa fa-window-close"/>*/}
                                {/*    &nbsp;Cancel*/}
                                {/*</Button>*/}

                                <Button variant="warning" type="submit" disabled={props.disableButton}>
                                    {props.disableButton===true? <><Spinner animation={'border'} size={'sm'} variant={'light'}/> </>:null} Create SOLbox
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button variant="dark" disabled={props.disableButton} style={{
                                    backgroundColor: '#8C8C9B',
                                    outline: '#8C8C9B',
                                    border: '#8C8C9B',
                                }} onClick={()=>props.history.goBack()}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Form.Group>
                </Form>

            </div>
            <Modal  show={showModal} onHide={()=>setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>New SOLboxes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to export the list of new SOLboxes in PDF?
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button style={{*/}
                    {/*    backgroundColor: '#6c757d',*/}
                    {/*    border: 'none',*/}
                    {/*}} onClick={()=>setShowModal(false)}>*/}
                    {/*    <i className="fa fa-window-close"/>*/}
                    {/*    &nbsp;No*/}
                    {/*</Button>*/}
                    {/*<Button variant="primary" >*/}
                    {/*    <i className="fa fa-check"/>*/}
                    {/*    &nbsp;Yes*/}
                    {/*</Button>*/}


                    <Button variant="dark" type="submit" disabled={props.disableButton} style={{
                        backgroundColor: '#8C8C9B',
                        outline: '#8C8C9B',
                        border: '#8C8C9B',
                    }} onClick={()=>setShowModal(false)}>
                        <i className="fa fa-window-close"/>
                        No
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button variant="warning" type="submit" disabled={props.disableButton}  onClick={generatePDF}>
                        <i className="fa fa-check"/>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
              
            `}</style>
            </>
    }



    return <>
            <ContentWrapper showCardHeader={true} showBackButton={false} isLoading={props.isLoading} serverError={props.serverError} pageTitle={'Create New SOLbox'} reloadFunction={fetchModels}>
                {renderBody()}
            </ContentWrapper>;
        </>

}

/*Mapping Redux States to this Component's Props*/
const mapStateToProps = (state) => {
    return {
        isLoading: state.solboxReducer.isLoading,
        serverError: state.solboxReducer.serverError,
        disableButton: state.solboxReducer.disableButton,
        modelList: state.solboxReducer.modelList,
        newSolboxList: state.solboxReducer.newSolboxList,
        solboxCreated: state.solboxReducer.solboxCreated,
        user_guid: state.auth.user_guid,
    }
};
export default connect(mapStateToProps, actions)(NewSolBox);
