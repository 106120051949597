import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import moment from "moment";
import {DayPickerRangeController} from "react-dates";
import Select from "react-select";
import {makeStyles} from "@material-ui/core/styles";
import {divisionOptions} from "../../../../utils/countryData/solDivision";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {
    isNullorUndefined,
    selectArraysMatches,
    selectObjectMatches,
    validateValue
} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";


const FormLabel = (props) => {
    return <label className={`form-check-label d-block mb-5`} style={{color: '#8C8C9B'}}>
        {props.children}
    </label>
}

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});

const FilterFormContainer = ({
                                        gridList,
                                        gridOperatorList,
                                        solboxModelList,
                                        firmwareVersionList,
                                        issueTypeList,
                                        selectedIssueType,
                                        issueList,
                                        setListFilters,
                                        resetFilter,
                                        getFilterGrid,
                                        clearFilterData,
                                        ...props
                                    }) => {
    const {register, handleSubmit, errors, setError, clearErrors, control} = useForm();
    const [grid, setGrid] = useState(null);
    const [gridOperator, setGridOperator] = useState(null);
    const [solboxModel, setSolboxModel] = useState(null);
    const [firmwareVersion, setfirmwareVersion] = useState(null);
    const [issueType, setIssueType] = useState(null);
    const [issue, setIssue] = useState(null);
    const [customerType, setCustomerType] = useState(null);
    const [onlineStatus, setOnlineStatus] = useState(true);
    const [offlineStatus, setOfflineStatus] = useState(false);
    const [division, setDivision] = useState(null);
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [initial1filterParam, setInitial1filterParam] = useState(null);
    const classes = useStylesForDatePicker();


    const validateIfFuture = (keyword) => {
        const date = moment(keyword, 'YYYY-MM-DD');
        if (date.isValid()) {
            const todayDate = moment();
            if (todayDate.isAfter(date)) {
                return date;
            } else {
                return undefined
            }
        } else {

            return undefined;
        }
    }

    useEffect(() => {
        setIssueType(selectedIssueType);
        if (selectedIssueType) {
            submit(selectedIssueType, true)
        }
    }, [selectedIssueType]);

    useEffect(() => {
        getFilterGrid({
            grid_operator_guid: gridOperator?.value,
            division: division?.value
        })
    }, [gridOperator, division])

    const customerTypes = [
        {label: "Consumer", value: "Consumer"},
        {label: "Prosumer", value: "Prosumer"},
        {label: "Producer", value: "Producer"},
        {label: "Microutility", value: "Microutility"},
    ]

    const issue_status = ['pending', 'resolved'];

    const validationKeyArray = [
        {
            parameter: 'grid_guids',
            list: gridList,
            isMulti: true
        },
        {
            parameter: 'grid_operator_guid',
            list: gridOperatorList,
        },
        {
            parameter: 'division',
            list: divisionOptions,
        },
        {
            parameter: 'solbox_model',
            list: solboxModelList,
        },
        {
            parameter: 'firmware_version',
            list: firmwareVersionList,
        },
        {
            parameter: 'issue_type',
            list: issueTypeList,
        },
        {
            parameter: 'customer_type',
            list: customerTypes,
        },
        {
            parameter: 'issue_status',
            list: issue_status,
        },
        {
            parameter: 'start_date',
            validationFunction: validateIfFuture
        },
        {
            parameter: 'end_date',
            validationFunction: validateIfFuture

        }

        /*,
        {
            parameter: 'replacement_date_from',
            list: customerTypes,
        }*/];

    useEffect(() => {
        let keys = null;
        const validParameters = {};

        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(props.projectedParams);
        } catch (e) {
            // console.log(e)
            // console.log(props.projectedParams)

            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {

            /* Looping through each of the value in the keys. (Essentially This loop will run for every keys in the projected params object) */

            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const currentIndex = validationKeyArray.findIndex((element) => {
                    return element.parameter === item;
                });

                if (currentIndex > -1 && !isNullorUndefined(validateValue(props.projectedParams[item], validationKeyArray[currentIndex]))) {
                    // console.log(validationKeyArray[currentIndex].list);
                    Object.assign(validParameters, {[`${item}`]: validateValue(props.projectedParams[item], validationKeyArray[currentIndex])})
                }
            })
        }

        // alert(gridOperator);
        setInitial1filterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null);

        /*Now we validated the */


        /* Now if the valid Params object has at least one parameter we set the filter */

    }, [props.projectedParams, gridList, gridOperatorList, solboxModelList, firmwareVersionList, issueTypeList])

    useEffect(() => {
        if (!isNullorUndefined(initial1filterParam)) {

            const frontEndFilterPayload = {}
            if (initial1filterParam.grid_guids) {
                setGrid(initial1filterParam.grid_guids);
                Object.assign(frontEndFilterPayload, {grid_guids: getCommaSeparatedGrids(initial1filterParam.grid_guids) || undefined})
            }

            if (initial1filterParam.grid_operator_guid) {
                if (!selectObjectMatches(gridOperator, initial1filterParam.grid_operator_guid)) {
                    setGridOperator(initial1filterParam.grid_operator_guid);
                    // alert('Not Matched');
                }
                // setGridOperator(initial1filterParam.grid_operator_guid);
                Object.assign(frontEndFilterPayload, {grid_operator_guid: initial1filterParam.grid_operator_guid.value,})
            }

            if (initial1filterParam.division) {
                setDivision(initial1filterParam.division);
                Object.assign(frontEndFilterPayload, {division: initial1filterParam.division.value})
            }

            if (initial1filterParam.solbox_model) {
                setSolboxModel(initial1filterParam.solbox_model);
                Object.assign(frontEndFilterPayload, {solbox_model: initial1filterParam.solbox_model.value})
            }

            if (initial1filterParam.firmware_version) {
                setfirmwareVersion(initial1filterParam.firmware_version);
                Object.assign(frontEndFilterPayload, {firmware_version: initial1filterParam.firmware_version.value})
            }

            if (initial1filterParam.issue_type) {
                setIssueType(initial1filterParam.issue_type);
                Object.assign(frontEndFilterPayload, {issue_type: initial1filterParam.issue_type.value})
            }

            if (initial1filterParam.customer_type) {
                setCustomerType(initial1filterParam.customer_type);
                Object.assign(frontEndFilterPayload, {customer_type: initial1filterParam.customer_type.value})
            }
            if (initial1filterParam.issue_status) {
                if (initial1filterParam.issue_status.toString().toLowerCase() === 'pending') {
                    setOnlineStatus(true);
                    setOfflineStatus(false);
                    Object.assign(frontEndFilterPayload, {issue_status: initial1filterParam.issue_status.toString().toLowerCase(),})
                } else if (initial1filterParam.issue_status.toString().toLowerCase() === 'resolved') {
                    setOnlineStatus(false);
                    setOfflineStatus(true);
                    Object.assign(frontEndFilterPayload, {issue_status: initial1filterParam.issue_status.toString().toLowerCase()})
                }
            }
            if (initial1filterParam.start_date && initial1filterParam.end_date) {
                setStartDate(initial1filterParam.start_date);
                Object.assign(frontEndFilterPayload, {start_date: initial1filterParam.start_date.local().format('YYYY-MM-DD')});

                setEndDate(initial1filterParam.end_date);
                Object.assign(frontEndFilterPayload, {end_date: initial1filterParam.end_date.local().format('YYYY-MM-DD')})
            }


            setListFilters(frontEndFilterPayload, false);

            console.table(frontEndFilterPayload);
            setInitial1filterParam(null);
        }
    }, [initial1filterParam])

    const validateForm = () => {
        if (!start && !end && !grid && !gridOperator && !solboxModel &&
            !firmwareVersion && !issueType && !division && !customerType && !onlineStatus && !offlineStatus) {
            setError("validationError", {
                type: "manual",
                message: "Be cool and give us the data!"
            });
            toast.error("Please select at least one filter")
            clearFilterData()
            setOnlineStatus(true)
            return false
        } else return true;
    }


    const getCommaSeparatedGrids = (selecetGrids) => {
        let temp = null;
        if (selecetGrids) {
            temp = JSON.stringify(selecetGrids.map((item) => {
                return item.value
            }));
        } else {
            return undefined;
        }

        return temp?.length >= 0 ? temp : undefined;
    }

    const submit = (data, issueTypeFlag = false) => {
        if (validateForm() === true) {
            mapAndDispatch(issueTypeFlag)
        }
    }


    const mapForDispatch = () => {
        return {
            grid_guids: getCommaSeparatedGrids(grid) || undefined,
            grid_operator_guid: gridOperator?.value || undefined,
            division: division?.value || undefined,
            solbox_model: solboxModel?.label || undefined,
            firmware_version: firmwareVersion?.label || undefined,
            issue_type: issueType?.value || undefined,
            issue_status: onlineStatus ? 'pending' : offlineStatus ? 'resolved' : undefined,
            customer_type: customerType?.value || undefined,
            start_date: start?.local().format('YYYY-MM-DD') || null, /*2020-09-10*/
            end_date: end?.local().format('YYYY-MM-DD') || null, /*2020-09-10*/
        }
    }

    const mapAndDispatch = (issueTypeFlag = false) => {
        let payload = {};
        if (issueTypeFlag === true) {
            payload = {
                ...mapForDispatch(),
                issue_type: selectedIssueType.value
            }
        } else {
            payload = mapForDispatch();
        }
        setListFilters(payload);

    }


    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days');
        return current.isAfter(today);
    }


    const onDatesChange = ({startDate, endDate}) => {

        // let doesNotMeetMinNights = false;
        // if (startDate && endDate) {
        //     const dayDiff = endDate.diff(startDate.clone().startOf('day').hour(12), 'days');
        //     doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
        //     console.log('asdasd')
        // }

        // const { daysViolatingMinNightsCanBeClicked, minimumNights } = props;
        // let doesNotMeetMinNights = false;
        // if (daysViolatingMinNightsCanBeClicked && startDate && endDate) {
        //     const dayDiff = endDate.diff(startDate.clone().startOf('day').hour(12), 'days');
        //     doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
        // }

        setStartDate(startDate);
        setEndDate(endDate);

    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }


    useEffect(() => {
        setDateRange(start && end ? `${new DateTimeFormat(start).localDisplayWithoutTime} - ${new DateTimeFormat(end).localDisplayWithoutTime}` : '');
    }, [start, end])


    const handleDateChange = ({startDate, endDate}) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }


    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start} // momentPropTypes.momentObj or null,
                    endDate={end} // momentPropTypes.momentObj or null,
                    // isOutsideRange={() => null}
                    numberOfMonths={2}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)} // PropTypes.func.isRequired,
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    );

    const onReset = () => {
        setGrid(null)
        setGridOperator(null)
        setDivision(null)
        setSolboxModel(null)
        setfirmwareVersion(null)
        setIssueType(null)
        setCustomerType(null)
        setOnlineStatus(null)
        setOfflineStatus(null)
        setStartDate(null)
        setEndDate(null)
        resetFilter()
    }


    /*
        const resetForm=()=>{
            if (Object.keys(filter).length>0)
            {
                setGrid(null)
                setGridOperator(null)
                setSolboxModel(null)
                setfirmwareVersion(null)
                setIssueType(null)
                setIssue(null)
                setCustomerType(null)
                resetFilter()
            }
        }*/


    return <>
        <form onSubmit={handleSubmit(submit)}>

            <div style={{
                marginTop: "1.5rem"
            }}>
                {/*<h5 className={`list-filter-gap`}>{`FILTER`}</h5>*/}
                <div className={`row list-filter-gap`}>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Division
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            isDisabled={props.filterDropdownLoading}
                            isLoading={props.filterDropdownLoading}
                            placeholder={`Select Division`}
                            isClearable={true}
                            options={divisionOptions}
                            value={division}
                            onChange={(selected) => {
                                setGrid(null)
                                setDivision(selected)
                                clearErrors()
                                return selected
                            }}
                        />
                    </div>

                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Grid Operator
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Organization`}
                            isDisabled={props.filterDropdownLoading || !props.isSolshare}
                            isLoading={props.filterDropdownLoading}
                            options={gridOperatorList}
                            value={gridOperator}
                            isClearable={true}
                            noOptionsMessage={() => `Not available`}
                            onChange={(selected) => {
                                setGrid(null)
                                setGridOperator(selected)
                                clearErrors();
                                return selected
                            }}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Grid
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Grid`}
                            isDisabled={props.filterDropdownLoading}
                            isLoading={props.filterDropdownLoading}
                            isMulti={true}
                            options={gridList}
                            value={grid}
                            isClearable={true}
                            noOptionsMessage={() => `Not available`}
                            onChange={(selected) => {
                                setGrid(selected)
                                clearErrors();
                                return selected
                            }}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Customer Type
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Type`}
                            isDisabled={props.filterDropdownLoading}
                            isLoading={props.filterDropdownLoading}
                            options={customerTypes}
                            value={customerType}
                            isClearable={true}
                            noOptionsMessage={() => `Not available`}
                            onChange={(selected) => {
                                setCustomerType(selected)
                                clearErrors();
                                return selected
                            }}
                        />
                    </div>
                </div>

                <div className={`row list-filter-gap`} style={{
                    marginTop: "1.5rem"
                }}>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            SOLbox Model
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Model...`}
                            isDisabled={props.filterDropdownLoading}
                            isLoading={props.filterDropdownLoading}
                            isClearable={true}
                            options={solboxModelList}
                            value={solboxModel}
                            noOptionsMessage={() => `Not available`}
                            onChange={(selected) => {
                                setSolboxModel(selected)
                                clearErrors()
                                return selected
                            }}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Firmware Version
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Firmware`}
                            isDisabled={props.filterDropdownLoading}
                            isLoading={props.filterDropdownLoading}
                            isClearable={true}
                            options={firmwareVersionList}
                            value={firmwareVersion}
                            noOptionsMessage={() => `No Mode Available`}
                            onChange={(selected) => {
                                setfirmwareVersion(selected)
                                clearErrors()
                                return selected
                            }}
                        />
                    </div>
                </div>

                <div className={`row list-filter-gap`} style={{
                    marginTop: "1.5rem"
                }}>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Issue Type
                        </FormLabel>
                        <Select
                            classNamePrefix="react-select-sol-style"
                            placeholder={`Select Type`}
                            isDisabled={props.filterDropdownLoading}
                            isLoading={props.filterDropdownLoading}
                            isClearable={true}
                            value={issueType}
                            options={issueTypeList}
                            noOptionsMessage={() => `Not available`}
                            onChange={(selected) => {
                                setIssueType(selected)
                                clearErrors()
                                return selected
                            }}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <FormLabel>
                            Issue Date
                        </FormLabel>
                        <OverlayTrigger rootClose trigger="click" placement="bottom-start" overlay={popover}>
                            <SolInput
                                name={'dateRangePickerInput'}
                                readOnly={true}
                                value={dateRange}
                                placeholder={`Select Date Range`}
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={`col-sm-5 mb-2`}>
                        <FormLabel>
                            Issue Status
                        </FormLabel>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="pending"
                                ref={register}
                                id="Flashed"
                                checked={onlineStatus}
                                value={`Pending`}
                                onChange={() => {
                                    clearErrors();
                                }}
                                onClick={() => {
                                    clearErrors();
                                    if (onlineStatus) {
                                        setOnlineStatus(true)
                                    } else {
                                        setOfflineStatus(false);
                                        setOnlineStatus(true);
                                    }
                                }
                                }
                            />
                            <label className="form-check-label" htmlFor="Flashed">Pending</label>
                        </div>
                        <div className="form-check form-check-inline customer-status-inactive">
                            <input
                                className="form-check-input"
                                type="radio"
                                ref={register}
                                name="resolved"
                                id="ID Created"
                                checked={offlineStatus}
                                value={`Resolved`}
                                onClick={() => {
                                    clearErrors();
                                    if (offlineStatus) {
                                        setOfflineStatus(true)
                                    } else {
                                        setOfflineStatus(true);
                                        setOnlineStatus(false);
                                    }
                                }}
                                onChange={() => {
                                    clearErrors();
                                }}
                            />
                            <label className="form-check-label" htmlFor="ID Created">Resolved</label>
                        </div>
                    </div>
                </div>

                <div className={`row mb-5`} style={{
                    marginTop: "1.5rem"
                }}>
                    <div className={`col-12`}>
                        <button
                            type={`submit`}
                            className={'btn btn-outline-warning btn-sm mb-5'}
                        >
                            {`Apply Filter`}
                        </button>
                        <button
                            type={`button`}
                            className={'btn btn-outline-secondary btn-sm ml-4 mb-5 reset-button-style'}
                            style={{border: "1px solid #000000"}}
                            onClick={onReset}
                        >
                            {`Reset Filter`}
                        </button>
                    </div>
                </div>

            </div>
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active {
                border: 1px solid #F18D00;
                box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                outline: none;
              }
            `}</style>

        </form>
    </>

}


export default withRoles(FilterFormContainer)
