import React from "react";
import styled from 'styled-components';

const getTextColor =(props)=>{
    if (props.primary) return "#F18D00"
    else if (props.secondary) return "#8C8C9B"
    else if (props.info) return "#2E798A"
    else if (props.success) return "#0B6E4F"
    else if (props.danger) return "#CB152B"
    else if (props.warning) return "#E1E114"
    else if (props.primaryLight) return "#FFEED6"
    else if (props.secondaryLight) return "#f4f4f6"
    else if (props.successLight) return "#ECFDF8"
    else if (props.dangerLight) return "#FDEDEE"
    else if (props.infoLight) return "#F0F8FA"
    else if (props.warningLight) return "#FAFAC6"
    else return "#12100C"
}

export const SolTypography = styled.div`
  padding: 2rem 0 2rem 0;
`

SolTypography.Title = styled.h3`
  color: ${getTextColor};
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
`


SolTypography.SubTitle = styled.p`
  color: ${getTextColor};
  font-size: 1rem;
  margin: .2rem;
`


SolTypography.Text = styled.span`
  color: ${getTextColor};
  margin: 0;
`


export const OnlineStatus = styled.div`
  display: inline-block;
  align-items: center;
  background-color: ${props =>props.online?"#0B6E4F":"#CB152B"};
  padding: 4px;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(11, 110, 79, 0.4);
`

export const CustomerProfileContainer= styled.div`
  min-height: 15rem;
  height: auto;
`

export const CustomerType= ({children})=> <span style={
    {
        padding: '4px',
        backgroundColor:'#2E798A',
        color: "#fff",
        fontSize: ".95rem",
        borderRadius: "3px",
        }}
>
    <b>{children}</b>
</span>

export const CustomerType_SmartBoard= ({children})=> <span style={
    {
        padding: '2px 1px 2px 1px',
        backgroundColor:'#2E798A',
        color: "#fff",
        fontSize: ".75rem",
        borderRadius: "3px",
    }}
>
    {children}
</span>
