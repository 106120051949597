import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Badge, Button, Form, Modal, Spinner} from "react-bootstrap";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import {incompatibleFirmwareVersion, determineBuyPriceAndSellPrice} from "../../../../utils/firmwareVersionChecking/firmwareVersionChecking";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";


export const SolboxInfoUpdateForm =
    ({solboxGuid, firmwareList, lifeCycleStageList, modelList, currentFirmware, currentModel, currentStatus, hideModal, submit, solboxInfoUpdateRequestPending, ...props}) => {
    const {handleSubmit, register, errors, control} = useForm();
    const [selectedFirmware, setSelectedFirmware] = useState(currentFirmware);
    const [statusOptions, setStatusOptions] = useState();
    const [selectedModel, setSelectedModel] = useState(currentModel);
    const [selectedStatus, setSelectedStatus] = useState(currentStatus);
    const [disableUpdateButton, setDisableUpdateButton] = useState(false);

    const handleFirmwareSelection=(selected) => {
        if (!selected) {
            setDisableUpdateButton(true)
        } else {
            if (selectedModel && selectedStatus) {
                setDisableUpdateButton(false)
            }
        }
        setSelectedFirmware(selected)
        return selected;
    }

    const handleSolboxModelSelection=(selected)=>{
        if (!selected) {
            setDisableUpdateButton(true)
        } else {
            if (selectedFirmware && selectedStatus) {
                setDisableUpdateButton(false)
            }
        }
        setSelectedModel(selected)
        return selected;
    }

    const handleSolboxStatusSelection=(selected)=>{
        if (!selected) {
            setDisableUpdateButton(true)
        } else {
            if (selectedFirmware && selectedModel) {
                setDisableUpdateButton(false)
            }
        }
        setSelectedStatus(selected)
        return selected;
    }

    const generateSubmissionData = ()=> {
        const payload = {
            solbox_guid: solboxGuid,
            fw_id: selectedFirmware.value,
            solbox_model_id: selectedModel.value,
            life_cycle_stage_id: selectedStatus.value
        }
        submit(payload)
    }

    return <>
        <Form onSubmit={handleSubmit(generateSubmissionData)}>
            <Form.Group>
                <div className={'row'}>
                    <div className={'col-md-4 div-element-center'}>
                        <Form.Label>{`Firmware`}<span className={"text-danger"}>*</span></Form.Label>
                    </div>
                    <div className="col-md-8">
                        <Select
                            name={`firmware`}
                            placeholder={`Select a firmware`}
                            isDisabled={false}
                            isLoading={false}
                            maxMenuHeight={200}
                            isClearable={true}
                            control={control}
                            value={selectedFirmware}
                            rules={{
                                required: "Required",
                            }}
                            isSearchable={true}
                            options={firmwareList}
                            onChange={(selected) => handleFirmwareSelection(selected)}
                        />
                        {errors.mode && <div className={"text-danger"}>
                            <i className={"flaticon-warning kt-font-brand"}/>{errors.mode.message}</div>}
                    </div>
                </div>
            </Form.Group>

            <Form.Group>
                <div className={'row'}>
                    <div className={'col-md-4 div-element-center'}>
                        <Form.Label>{`SOLbox model`}<span className={"text-danger"}>*</span></Form.Label>
                    </div>
                    <div className="col-md-8">
                        <Select
                            name={`model`}
                            placeholder={`Select a SOLbox model`}
                            isDisabled={false}
                            isLoading={false}
                            maxMenuHeight={200}
                            isClearable={true}
                            control={control}
                            value={selectedModel}
                            rules={{
                                required: "Required",
                            }}
                            isSearchable={true}
                            options={modelList}
                            onChange={(selected) => handleSolboxModelSelection(selected)}
                        />
                        {errors.mode && <div className={"text-danger"}>
                            <i className={"flaticon-warning kt-font-brand"}/>{errors.mode.message}</div>}
                    </div>
                </div>
            </Form.Group>

            <Form.Group>
                <div className={'row'}>
                    <div className={'col-md-4 div-element-center'}>
                        <Form.Label>{`Status`}<span className={"text-danger"}>*</span></Form.Label>
                    </div>
                    <div className="col-md-8">
                        <Select
                            name={`status`}
                            placeholder={`Select a SOLbox status`}
                            isDisabled={false}
                            isLoading={false}
                            maxMenuHeight={200}
                            isClearable={true}
                            control={control}
                            value={selectedStatus}
                            rules={{
                                required: "Required",
                            }}
                            isSearchable={true}
                            options={lifeCycleStageList}
                            onChange={(selected) => handleSolboxStatusSelection(selected)}
                        />
                        {errors.mode && <div className={"text-danger"}>
                            <i className={"flaticon-warning kt-font-brand"}/>{errors.mode.message}</div>}
                    </div>
                </div>
            </Form.Group>

            <Modal.Footer>
                <Button style={{
                    backgroundColor: '#6c757d',
                    border: 'none',
                }} onClick={hideModal}>
                    <i className="fa fa-window-close"/>
                    &nbsp;Cancel
                </Button>
                <Button variant="warning" type="submit" disabled={(disableUpdateButton || solboxInfoUpdateRequestPending)}>
                    Update
                    &nbsp;{solboxInfoUpdateRequestPending ? <Spinner animation="border" variant="light"/> :
                    <i className="fa fa-paper-plane"/>}
                </Button>
            </Modal.Footer>
        </Form>
    </>
}
