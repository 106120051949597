import React, {useState} from "react";
import styled from "styled-components";
import {SolInput} from "../../../components/SolStyledComponents/components";
import { useForm} from "react-hook-form";
import Select from "react-select";
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import {
    DatePicker
} from '@material-ui/pickers/'
import {divisionOptions} from "../../../utils/countryData/solDivision";
import {Button, Popover} from "react-bootstrap";
import { ThemeProvider } from '@material-ui/styles';
import {toast} from "react-toastify"
import {DayPickerRangeController} from "react-dates";
import moment from "moment";






export const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});




export const Toolbar = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
`;


export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`



Toolbar.ToolbarContainer = styled.div`
  grid-column: 10/-1;
`;
Toolbar.Title = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1/9 ;
`
Toolbar.ToolbarContainer.Items = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
`;


export const Filter = styled.div`
  margin: 35px 0 0 0;
  padding: 0;
`;



const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});


