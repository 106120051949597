import React, { useState, useEffect } from "react";
import { customerActions }   from "../customerActions";
import { connect } from 'react-redux';
import SolSelect from "../../../components/SolSelect";
import SolRadioInput from "../../../components/solradioinput/SolRadioInput";
import SolTextInput from "../../../components/soltextinput/SolTextInput";
import { ToastContainer } from 'react-toastify';
import Message from "../../../utils/toastify/notification";
import { toast } from "react-toastify";

const CustomerListFilter = (props) => {

    const { 
        location,
        history,
        isSolshare,
        b2bOrgGuid,
        gridOptions,
        handleSetGridOptions,
        divisionOptions,
        division,
        handleChangeDivision,
        grid,
        handleChangeGrid,
        customerType,
        handleChangeCustomerType,
        customerStatus,
        handleChangeCustomerStatus,
        customerPhone,
        handleChangeSolTextInput,
        reset,
        getCustomers,
        setCustomerListParams,
        handleDisplayGridNames, 
        toggleGetCustomers, 
        filterApplied,
        toggleFilterApplied,
        toggleCustomerListFilter,
        isGridListLoading, 
        gridList,
        FETCH_ALL_CUSTOMERS,
        FETCH_FILTERED_CUSTOMERS,
        FETCH_GRID_LIST
    } = props;

    const getAllCustomers = (event, params) => {
        FETCH_ALL_CUSTOMERS();
        setCustomerListParams(event, params);
        toggleGetCustomers(event);
    }

    useEffect(() => {
        if (isSolshare) {
            FETCH_GRID_LIST({});
        } else {
            FETCH_GRID_LIST({});
        }
    }, [isSolshare]);

    useEffect(() => {
        if (division && Object.entries(division).length !== 0) {
            FETCH_GRID_LIST({"division": division.value});
        }
    }, [division]);

    useEffect(() => {
        if (gridList &&
            gridList.length !== 0) {
            let gridArr = [];
            gridList.forEach((element, index) => {
                if (element.grid_guid && element.name) {
                    gridArr.push({ 
                        value: element.grid_guid, 
                        label: element.name 
                    });
                }
            });
            handleSetGridOptions(gridArr);
        } else {
            handleSetGridOptions([]);
        }
    }, [gridList]);

    const getFilteredCustomers = (event) => {
        const regex = /^[\+a-z0-9\- ]+$/gi;
        var formData = {};

        if (division) {
            Object.assign(formData, {"division": division.value});
        }

        if (grid && grid.length !== 0) {
            let gridIdArr = [];
            let gridNameArr = [];
            grid.forEach((element, index) => {
                gridIdArr.push(element.value);
                gridNameArr.push(element.label);
            });
            Object.assign(formData, {"grid_guid": gridIdArr});
            handleDisplayGridNames(gridNameArr);
        }

        if(customerPhone && customerPhone.trim() && regex.test(customerPhone.trim())) {
            Object.assign(formData, {"customer_phone": customerPhone.trim()});
        }

        if (customerType) {
            Object.assign(formData, {"customer_type": customerType});
        }

        if (customerStatus) {
            Object.assign(formData, {"customer_status": customerStatus});
        }

        if (!(Object.entries(formData).length < 1)) {
            setCustomerListParams(event, formData);
            toggleGetCustomers(event);
            toggleFilterApplied(event, true);
            FETCH_FILTERED_CUSTOMERS(formData);
            history.push({
                pathname: '/customers/list',
                search: filterParamsHandler(formData)
            });
            //toggleCustomerListFilter(event);
        } else {
            if (getCustomers) {
                //const message = new Message();
                //message.ErrorMessage("Please provide at least one filter criterion!");
                toast.error("Please provide at least one filter criterion!");
            } else {
                setCustomerListParams(event, null);
                toggleGetCustomers(event);
                FETCH_ALL_CUSTOMERS();
                toggleCustomerListFilter(event);
            }
        }
    }

    const filterParamsHandler = (filterParams) => {
        let queryStringParams = "";
        if (!(Object.entries(filterParams).length < 1)) {
            for (const [key, value] of Object.entries(filterParams)) {
                if (!queryStringParams.includes("?")) {
                    queryStringParams += `?${key}=${value}`;
                } else {
                    queryStringParams += `&${key}=${value}`;
                }
            }
        }
        return queryStringParams;
    }

    const renderFilterForm = () => {
        return (
            <React.Fragment>
                <h5 className={`list-filter-control`}>{`FILTER`}</h5>
                <div className="row list-filter-control">
                    <div className={`col-sm-3 mb-2`}>
                        <label className={`form-check-label d-block normal-text mb-5`}>
                            Division
                        </label>
                        <SolSelect
                            placeHolder={`Select Division`}
                            value={division}
                            handleChangeSolSelect={handleChangeDivision}
                            options={divisionOptions}
                            selectMulti={false}
                            loading={false}
                            clearable={true}
                            noOptionsMessage={() => `No division available`}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <label className={`form-check-label d-block normal-text mb-5`}>
                            Grid
                        </label>
                        <SolSelect
                            placeHolder={`Select Grid`}
                            value={grid}
                            handleChangeSolSelect={handleChangeGrid}
                            options={gridOptions}
                            selectMulti={true}
                            loading={isGridListLoading}
                            clearable={true}
                            noOptionsMessage={() => `No grids available`}
                        />
                    </div>
                    <div className={`col-sm-3 mb-2`}>
                        <label className={`form-check-label normal-text mb-5`}>
                            {`Customer Phone Number`}
                        </label>
                        <div className="form-group d-block">
                            <SolTextInput 
                                inputClassName={`custom-text-input-style`}
                                name={`customerPhoneNumber`}
                                placeHolder="Type Phone Number..."
                                value={customerPhone}
                                handleChange={(event) => handleChangeSolTextInput(event)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row list-filter-control">
                    <div className={`col-sm-12 col-md-3 mb-5`}>
                        <label className={`form-check-label d-block normal-text mb-5`}>
                            Customer Type
                        </label>
                        <div className="form-check-inline">
                            <SolRadioInput
                                name="typeOfCustomer"
                                id="Consumer"
                                value={`Consumer`}
                                handleChange={handleChangeCustomerType}
                                checked={(customerType.trim().toLowerCase() === "consumer") ? true : false}
                                htmlFor="Consumer"
                                labelName="Consumer"
                            />
                        </div>
                        <div className="form-check-inline">
                            <SolRadioInput
                                name="typeOfCustomer"
                                id="Prosumer"
                                value={`Prosumer`}
                                handleChange={handleChangeCustomerType}
                                checked={(customerType.trim().toLowerCase() === "prosumer") ? true : false}
                                htmlFor="Prosumer"
                                labelName="Prosumer"
                            />
                        </div>
                        <div className="form-check-inline">
                            <SolRadioInput
                                name="typeOfCustomer"
                                id="Producer"
                                value={`Producer`}
                                handleChange={handleChangeCustomerType}
                                checked={(customerType.trim().toLowerCase() === "producer") ? true : false}
                                htmlFor="Producer"
                                labelName="Producer"
                            />
                        </div>
                    </div>
                    <div className={`col-sm-12 col-md-3 mb-2`}>
                    <label className={`form-check-label d-block normal-text mb-5`}>
                        Customer Status
                    </label>
                    <div className="d-flex justify-content-start align-items-start">
                        <div className="form-check-inline">
                            <SolRadioInput
                                name="statusOfCustomer"
                                id="Active"
                                value={`Active`}
                                handleChange={handleChangeCustomerStatus}
                                checked={(customerStatus.trim().toLowerCase() === "active") ? true : false}
                                htmlFor="Active"
                                labelName="Active"
                            />
                        </div>
                        <div className="form-check-inline customer-status-inactive">
                            <SolRadioInput
                                name="statusOfCustomer"
                                id="Inactive"
                                value={`Inactive`}
                                handleChange={handleChangeCustomerStatus}
                                checked={(customerStatus.trim().toLowerCase() === "inactive") ? true : false}
                                htmlFor="Inactive"
                                labelName="Inactive"
                            />
                        </div>
                    </div>
                </div>
            </div>
                <div className={`row mb-5`}>
                    <div className={`col-12`}>
                        <button
                            type={`button`}
                            className={'btn btn-outline-warning btn-sm mb-5'}
                            onClick={(event) => getFilteredCustomers(event)}
                        >
                            {getCustomers ? `Apply Filter` : `Get Customers`}
                        </button>
                        <button
                            type={`button`}
                            className={'btn btn-outline-secondary btn-sm ml-4 mb-5 list-filter-reset-button'}
                            onClick={(event) => reset(event)}
                        >
                            {`Reset Filter`}
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {renderFilterForm()}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.customerReducer.isLoading,
        isGridListLoading: state.customerReducer.isGridListLoading,
        tableLoading: state.customerReducer.tableLoading,
        statusCode: state.customerReducer.statusCode,
        gridList: state.customerReducer.gridList,
        customerList: state.customerReducer.customerList
    }
};

export default connect(mapStateToProps, customerActions)(CustomerListFilter);