import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {commonActions} from "../commonActions";
import {requestCycle} from "../../grids/utils";
import {OnlineStatus, SolTypography} from "../../../components/utilityComponents/SOlTypography";
import {Badge} from "react-bootstrap";
import moment from "moment";
import Loader from "../../../components/loader/Loader";
import {incompatibleFirmwareVersion} from "../../../utils/firmwareVersionChecking/firmwareVersionChecking";

const LiveDataComponent = ({solboxSerialNumber, firmware_version, ...props}) => {

    const {
        PUBLISH_TOPIC_FOR_SINGLE_SOLBOX,
        GET_LIVE_DATA_FOR_SINGLE_SOLBOX,
        SET_FLAGS_TO_UNDEFINED,
        loading,
        topicPublished,
        liveDataLoadingMessage,
        liveDataFetched,
        liveData
    } = props;
    const [onlineStatus, setOnlineStatus] = useState(false);


    console.log({
        fw: firmware_version,
        compatible: incompatibleFirmwareVersion(firmware_version),
    })
    /*Starting to fetch the Live Data for current SOLbox*/
    useEffect(()=>{

        if (!incompatibleFirmwareVersion(firmware_version)){
            PUBLISH_TOPIC_FOR_SINGLE_SOLBOX({
                solbox_id: solboxSerialNumber?solboxSerialNumber.toString().slice(4,8):null,
            });
        }


        return ()=>{
            SET_FLAGS_TO_UNDEFINED();
        }
    },[PUBLISH_TOPIC_FOR_SINGLE_SOLBOX, SET_FLAGS_TO_UNDEFINED]);



    /* Getting Live Data if publishing request is successful */
    useEffect(()=>{

        /*Checking if the publish request has been successful*/
        if (topicPublished===requestCycle.success)
        {
            setTimeout(()=>{
                GET_LIVE_DATA_FOR_SINGLE_SOLBOX({
                    solbox_id: solboxSerialNumber?solboxSerialNumber.toString().slice(4,8):null,
                });
            },8000)
        }

    },[topicPublished,GET_LIVE_DATA_FOR_SINGLE_SOLBOX]);



    /*Setting Live Data and Online Status*/
    useEffect(()=>{
        if (liveDataFetched=== requestCycle.success && liveData)
        {
            setOnlineStatus(true)
        }
    },[liveDataFetched,liveData])



    /*Getting Last online time for solbox*/
    const getLastDataRecieved=()=> {
        return liveData? liveData.updated_at? `${moment().diff(liveData.updated_at, 'seconds')} seconds ago (This data shown here can be generated maximum 3 minutes before by SOLbox, due to Hardware limitation)`:'N/A':'N/A';
    }


    const getNoDataFoundMessage=(message=null)=>{
        return <>
            <div className={'d-flex justify-content-center'}><h1><Badge variant="secondary">{message || `Could not connect to SOLbox ${solboxSerialNumber}`}</Badge></h1></div>
        </>
    }


    const getData=()=>{
        return <div className={'col-md-12 no-padding'}>
            <SolTypography.SubTitle><b>Online Status:</b> <OnlineStatus online={onlineStatus}/> </SolTypography.SubTitle>
            <SolTypography.SubTitle><b>Balance:</b> { liveData && liveData.i32Balance ? liveData.i32Balance/100 :0 } Tk. </SolTypography.SubTitle>
            <SolTypography.SubTitle><b>Total Bought:</b> { liveData && liveData.u32TotalBoughtWh!==null && liveData.u32TotalBoughtWh !==undefined ? `${liveData.u32TotalBoughtWh} Wh`: 'N/A' } </SolTypography.SubTitle>
            <SolTypography.SubTitle><b>Total Sold:</b> { liveData && liveData.u32TotalSoldWh!==null && liveData.u32TotalSoldWh!==undefined ? `${liveData.u32TotalSoldWh} Wh`: 'N/A' } </SolTypography.SubTitle>
            <SolTypography.SubTitle><b>Electricity to Grid:</b> { liveData && liveData.i32IToGrid!==null && liveData.i32IToGrid !==undefined ? `${liveData.i32IToGrid} mA`: 'N/A' } </SolTypography.SubTitle>
            <SolTypography.SubTitle><b>Last Data Received:</b> {getLastDataRecieved()} </SolTypography.SubTitle>
            </div>
    }


    const renderBody=()=>{
        if (incompatibleFirmwareVersion(firmware_version))
        {
            return getNoDataFoundMessage("This SOLbox is not Compatible with live data feature");
        }
        else if (solboxSerialNumber && liveDataFetched===requestCycle.success && Object.keys(liveData).length>0){
            return getData();
        }else if (liveDataFetched===requestCycle.failed || topicPublished===requestCycle.failed){
            return getNoDataFoundMessage();
        }
        else {
            return getNoDataFoundMessage();
        }
    }



    return loading===true?<Loader text={'Fetching Live Data...'} size={'sm'} loadingSubText={liveDataLoadingMessage}/>:renderBody()



};

LiveDataComponent.propTypes = {
    
};

export default  connect(({commonReducer})=>(commonReducer), commonActions)(LiveDataComponent);