import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {gridListColumns} from "./gridListColumns";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner, LoadingSpinner} from '../../utils'
import {
    DangerStatusRender,
    PendingRenderStatus,
    SuccessRenderStatus
} from "../../../../components/legendComponents/legendComponents";
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});


const GridList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [gridList, setGridList] = useState([]);
    const [numberOfSolboxes, setNumberOfSolboxes] = useState(0);
    const [numberOfConsumers, setNumberOfConsumers] = useState(0);
    const [numberOfProsumers, setNumberOfProsumers] = useState(0);
    const [numberOfProducers, setNumberOfProducers] = useState(0);
    const [numberOfMUs, setNumberOfMUs] = useState(0);
    const [numberOfCustomers, setNumberOfCustomers] = useState(0);
    const [page, setPage] = useState(null);
    const [gridOwnersList, setGridOwnersList] = useState([]);
    const [filterParameters, setFilterParameters] = useState(null);
    const subHeader = useSubheader();
    const [searchText, setSearchText] = useState(null);
    const [projectedParams, setProjectedParams] = useState(null);
    const { b2bOrgGuid , isSolshare } = props;

    const {
        GET_GRID_LIST,
        GET_GRID_LIST_SUMMARY,
        GET_GRID_OWNERS_LIST,
    } = props;
    const classes = useStylesIconButton();

    const getTotalNumber = (grid) => {
        let total = 0;
        const tempArray = ['consumer', 'prosumer', 'producer', 'microutility'];
        tempArray.forEach((item) => {
            if (grid && isNumber(grid[item])) {
                total = total + parseFloat(grid[item]);
            }
        });
        return total;
    }

    useEffect(() => {
        GET_GRID_LIST({});
        GET_GRID_LIST_SUMMARY({});
        GET_GRID_OWNERS_LIST();
        if (isSolshare){
            subHeader.setActionButtons(<>
                <Button variant="warning" type="submit" style={{
                    backgroundColor: '#F18D00 !important',
                }} onClick={() => {
                    props.history.push('/grids/create')
                }}>
                    <img src={require('../../../../utils/asset/plus-icon.svg')}/>&nbsp;Create Grid
                </Button>
            </>)
        }
        return () => {
            subHeader.setActionButtons(null);
        }
    }, [GET_GRID_LIST, GET_GRID_OWNERS_LIST, GET_GRID_LIST_SUMMARY]);

    useEffect(() => {
        const gridSummary = props.gridSummary;
        if (gridSummary) {
            setNumberOfSolboxes(gridSummary.solbox_total)
            setNumberOfProsumers(gridSummary.prosumer_total)
            setNumberOfProducers(gridSummary.producer_total)
            setNumberOfMUs(gridSummary.mu_total)
            setNumberOfConsumers(gridSummary.consumer_total)
            setNumberOfCustomers(gridSummary.prosumer_total + gridSummary.producer_total + gridSummary.consumer_total)
        }
    }, [props.gridSummary]);

    useEffect(() => {
        const gridList = props.gridList;
        if (gridList && gridList.results && gridList.results.length > -1) {
            setGridList(gridList.results.map((grid, index) => {
                let thisGridsTotalSolbox = getTotalNumber(grid)
                return {
                    ...grid,
                    name: getGridNameString(grid),
                    tariff: getTariffString(grid.current_tariff),
                    total: thisGridsTotalSolbox
                }
            }))
        }
    }, [props.gridList]);

    useEffect(() => {
        const ownerList = props.gridOwnerList;
        if (ownerList && ownerList.length > -1) {
            setGridOwnersList(ownerList.map((owner, index) => {
                return {
                    ...owner,
                    label: owner.organization_name,
                    value: owner.organization_guid,
                }
            }))
        }
    }, [props.gridOwnerList]);

    useEffect(()=>{
        if ( props.isSolshare === false && props.b2bOrgGuid ){
            setProjectedParams({
                ...projectedParams,
                organization_guid: props.b2bOrgGuid,
            })
        }
    },[props.b2bOrgGuid,props.isSolshare])

    useEffect(() => {
        let temp = null;

        try {
            temp = parseParams(props.history?.location?.search);

            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                if ( props.isSolshare === false && props.b2bOrgGuid ){
                    delete temp.organization_guid;
                    Object.assign(temp, {organization_guid: props.b2bOrgGuid })
                }
                setProjectedParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    }, [props.history.location.search])


    const getTariffString = (tariff) => {
        if (tariff) {
            return `Buy at ৳ ${tariff.buy_price || 'N/A'}, Sell at ৳ ${tariff.sell_price || 'N/A'} `
        }
    };


    // const getGridNameString=(grid)=>{
    //     if (grid && grid.address) {
    //         return `${grid.name || 'N/A'} - ${grid.address.sub_district || 'N/A'}- ${grid.address.district || 'N/A'}`
    //     }
    // };
    const getGridNameString = (grid) => {
        let temp = '';
        temp = grid?.name ? temp.concat(`${grid.name}`) : temp.concat(`N/A`);
        temp = grid?.address?.district ? temp.concat(`-${grid.address.district}`) : temp.concat(``);
        temp = grid?.address?.division ? temp.concat(`-${grid.address.division}`) : temp.concat(``);
        temp = grid?.iot_alias ? temp.concat(`-${grid?.iot_alias}`) : temp.concat(``);

        return <Link style={{color: "#F18D00",}}
                     target={'_blank'}
            // onClick={()=>onRowClick(grid)}
                     to={`/grids/details/${grid.grid_guid}`}
        >
            {temp}
        </Link>
        // return <Link target={'_blank'}> </Link>
    }


    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterParameters) {
            if (searchText) {
                props.GET_GRID_LIST_WITH_FILTER({
                    page: newPage,
                    name: searchText,
                    ...filterParameters
                });
            } else {
                props.GET_GRID_LIST_WITH_FILTER({...filterParameters, page: newPage})
            }
        } else {
            if (searchText) {
                props.GET_GRID_LIST({page: newPage, name: searchText});
            } else {
                props.GET_GRID_LIST({page: newPage});
            }
        }
    }


    const setListFilters = (filterData) => {
        setPage(null)
        setFilterParameters(filterData);
        if (Object.keys(filterData)?.length > 0) {
            props.history.push({
                search: JsonToUriSerialize(filterData),
            });
        }
        if (searchText) {
            props.GET_GRID_LIST_WITH_FILTER({...filterData, name: searchText});
            GET_GRID_LIST_SUMMARY({...filterData, name: searchText});
        } else {
            props.GET_GRID_LIST_WITH_FILTER(filterData)
            GET_GRID_LIST_SUMMARY(filterData)
        }
    }

    const resetFilter = () => {
        setFilterParameters(null)
        if ( props.isSolshare === false && props.b2bOrgGuid ) {
            setProjectedParams({
                organization_guid: props.b2bOrgGuid,
            })
        } else setProjectedParams(null);
        setSearchText('')
        props.history.push({
            search: undefined,
        });
        GET_GRID_LIST({page: 1});
        GET_GRID_LIST_SUMMARY({})
    }


    const onSearch = (text) => {
        if (text === '' && !searchText) {
            return;
        }
        setPage(null)

        if (filterParameters) {
            props.GET_GRID_LIST_WITH_FILTER({...filterParameters, name: text, searchRequest: true})
            GET_GRID_LIST_SUMMARY({...filterParameters, name: text, searchRequest: true})
        } else {
            props.GET_GRID_LIST({name: text, searchRequest: true})
            GET_GRID_LIST_SUMMARY({name: text, searchRequest: true})
        }
        setSearchText(text)
    }


    const filterToggle = () => {
        setShowFilter(!showFilter)
    }


    const onRowClick = (rowData) => {
        props.history.push(
            `/grids/details/${rowData.grid_guid}`,
            {
                gridName: rowData.name,
                district: rowData && rowData.address?.district,
                division: rowData && rowData.address?.division,
                owner: rowData.grid_owner,
                operator: rowData.grid_operator,
            }
        )
    }

    const exportToCsv = () => {
        let filterObject = {
            ...filterParameters,
            name: searchText
        }
        props.GET_GRID_LIST_FOR_EXPORTING_TO_CSV(filterObject)
    }

    const ToolBar = <>
        <Toolbar>
            <Toolbar.Title>
                <b>
                    <h1>
                        {(props.isLoading || props.filterTriggeredLoading)? <>
                            <Spinner animation={"grow"} variant={"warning"} size={"lg"}/>{filterParameters? 'Updating grid count...': 'Generating grid count...'}
                        </>:<>
                            {props.gridList && props.gridList.count && props.gridList.count > 0 ? `${props.gridList.count} ${props.gridList.count > 1 ? 'Grids' : 'Grid'}` : "No Grid Found"}
                        </>}
                    </h1>
                    <p>{props.summaryLoading? <>
                        <Spinner animation={"grow"} variant={"warning"} size={"sm"}/>{filterParameters? 'Updating summary...': 'Generating summary...'}
                    </>: <>
                    {numberOfSolboxes}&nbsp;{numberOfSolboxes > 1? 'SOLboxes': 'SOLbox'}&nbsp;and&nbsp;{numberOfCustomers}
                        &nbsp;{numberOfCustomers > 1? 'Customers':'Customer'}&nbsp;(
                        {numberOfConsumers}&nbsp;{numberOfConsumers > 1? 'Consumers': 'Consumer'},
                        &nbsp;{numberOfProsumers}&nbsp;{numberOfConsumers > 1? 'Prosumers': 'Prosumer'},
                        &nbsp;{numberOfProducers}&nbsp;{numberOfProducers > 1? 'Producers': 'Producer'},
                        &nbsp;{numberOfMUs}&nbsp;{numberOfMUs > 1? 'MUs': 'MU'})
                    </>}</p>
                </b>
            </Toolbar.Title>
            <Toolbar.ToolbarContainer>
                <Toolbar.ToolbarContainer.Items>
                    <IconButton onClick={filterToggle} classes={classes}>
                        <FilterListIcon color="disabled" fontSize="large"/>
                    </IconButton>
                    <IconButton classes={classes} onClick={exportToCsv}
                                disabled={props.filterTriggeredLoading || props.isLoading || props.tableLoading || props.iconLoading}>
                        {props.iconLoading === true ? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :
                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                 alt="Kiwi standing on oval"/>}
                    </IconButton>
                    <SolInput
                        id={'test'}
                        name={'search'}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                onSearch(event.target.value);
                            }
                        }}
                        autoComplete={'off'}
                        onChange={(event) => {
                            if (event.target.value === '') {
                                onSearch(event.target.value);
                            }
                        }}
                        placeholder={'Type Grid Name...'}
                    />
                </Toolbar.ToolbarContainer.Items>
            </Toolbar.ToolbarContainer>
        </Toolbar>
    </>


    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Grids"}
                            statusCode={props.statusCode}>

                {ToolBar}


                <div className={'filter-animation'} style={{
                    display: showFilter ? undefined : 'none',
                }}>
                    <Filter>
                        <h4 style={{paddingBottom: "1rem"}}>Filter</h4>
                        <FormContainer resetFilter={resetFilter} gridOwners={gridOwnersList}
                                       setListFilters={setListFilters} projectedParams={projectedParams}/>
                    </Filter></div>

                <div className={'col-md-12 no-padding mt-5'}>
                    <div className={'row'}>
                        <div className={'col-md-4 '}>

                        </div>
                        <div className={'col-md-4 '}>

                        </div>
                        {/*<div className={'col-md-3 '}>*/}

                        {/*</div>*/}

                        {/*<div className={'col-md-4 '} style={{*/}
                        {/*    display: "flex",*/}
                        {/*    gap: '15px',*/}

                        {/*    // justifyContent: 'right',*/}
                        {/*    justifyContent: "flex-end",*/}
                        {/*}}>*/}
                        {/*    <Button variant="warning" type="submit" style={{*/}
                        {/*        backgroundColor: '#F18D00',*/}
                        {/*    }} onClick={()=>{*/}
                        {/*        props.history.push('/grids/create')*/}
                        {/*    }}>*/}
                        {/*        <img src={require('../../../../utils/asset/plus-icon.svg')} />&nbsp;Create Grid*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>
                </div>


                <DataTableContainer>
                    {props.filterTriggeredLoading !== true ? <DataTable
                        columns={gridListColumns}
                        data={gridList}
                        showToolbar={false}
                        asyncPagination={true}
                        isLoading={props.tableLoading}
                        count={props.gridList?.count}
                        itemsPerPage={props.gridList?.page_size}
                        onChangePage={onChangePage}
                        page={page}
                        // onRowClick={(e,rowData)=>onRowClick(rowData)}
                    /> : <LoadingSpinner/>}
                </DataTableContainer>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

GridList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        isLoading: state.gridsReducer.isLoading,
        tableLoading: state.gridsReducer.tableLoading,
        statusCode: state.gridsReducer.statusCode,
        gridList: state.gridsReducer.gridList,
        gridOwnerList: state.gridsReducer.gridOwnerList,
        filterTriggeredLoading: state.gridsReducer.filterTriggeredLoading,
        iconLoading: state.gridsReducer.iconLoading,
        gridSummary: state.gridsReducer.gridSummary,
        summaryLoading: state.gridsReducer.summaryLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(GridList));
