import React, {useState} from "react";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";
import '../../style.scss';
import {SolInput} from "../../../../components/SolStyledComponents/components";
import { GenericModal } from "../../../../components/genericModal/genericModal";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import styles from "../../../../components/soltextinput/TextInput.module.scss"

const solboxSerialNumbervalidation = {
    value: /^[0-9]{8}$/,
    message: `Number with 8 digits (must start with "11". ex. 11002203)`
}

export const ReplacementForms = ({replacementInfo,phase=1,newSerial, oldSerial,title,checkList,completed,backToCustomers,
                                     oldSolboxBalance, balanceUpdateEvent, showUpdateBalanceButton, ...props})=>{

    const {handleSubmit, errors, register, control} = useForm();
    const [showUpdateBalanceModal, setShowUpdateBalanceModal] = useState(false)
    const [balanceFieldValueError, setBalanceFieldValueError] = useState(false)
    const [balanceError, setBalanceError] = useState(false)
    const [balance, setBalance] = useState('')
    const [balanceForShow, setBalanceForShow] = useState('')
    const [disableUpdateButton, setDisableUpdateButton] = useState(false)
    const successCase= {
        1: <li className={'ul-success-case'}>Validation Successful for SOLbox serial number {newSerial}</li>,
        2: <li className={'ul-success-case'}>SOLbox data has been replaced successfully.</li>,
        3: <li className={'ul-success-case'}>Configuration has been successfully sent to {newSerial}.</li>,
        4: <li className={'ul-success-case'}>Balance has been successfully sent to {newSerial}.</li>,
    }
    const errorCase= {
        1: <li className={'ul-error-case'}> SOLboxes are not it right status to be replaced. Please contact with admin</li>,
        2: <li className={'ul-error-case'}> SOLbox data could not be replaced. please contact admin</li>,
        3: <li className={'ul-error-case'}> Could not connect with {newSerial}. Please try sending configuration manually.</li>,
        4: <li className={'ul-error-case'}> Could not connect with {newSerial}. Please try sending reimbursement manually.</li>,
    }

    const getCheckLists = () => {
        let i=0
        let temp=[]

        for (i=1;i<=4; i++ )
        {
            if (phase>i && checkList.indexOf(i)>-1)
            {
                temp.push(successCase[`${i}`]);
            }
            else if (phase>i && checkList.indexOf(i)===-1){
                temp.push(errorCase[`${i}`]);
            }
        }

        return temp
    }

    const closeModal = () => {
        setBalanceFieldValueError(false)
        setShowUpdateBalanceModal(false)
    }

    const showModal = () => {
        if (!oldSolboxBalance) {
            setDisableUpdateButton(true)
        } else {
            setDisableUpdateButton(false)
            setBalance(oldSolboxBalance)
            setBalanceForShow(oldSolboxBalance)
        }
        setBalanceError(false)
        setShowUpdateBalanceModal(true)
    }

    const balanceInputChangeEvent = (event)=> {
        setBalanceError(false)
        setBalanceForShow(event.target.value)
        let testingForFloatingPointNumber = /^-?\d*[.,]?\d*$/.test(event.target.value)
        if (!testingForFloatingPointNumber) {
            setBalanceFieldValueError(true)
            setDisableUpdateButton(true)
        } else {
            setBalance(event.target.value)
            setBalanceFieldValueError(false)
            setDisableUpdateButton(false)
        }
    }

    const handleBalanceUpdateEvent = ()=> {
        if (!balance) {
            setBalanceError(true)
        } else {
            balanceUpdateEvent(parseFloat(balance).toFixed(2) * 100)
        }
    }

    const renderUpdateBalanceModal = () => {
        return <>
            <GenericModal size={'md'} footer={false} title={<>
                Updating balance of SOLbox <SolTypography.Text primary>{oldSerial}</SolTypography.Text>
            </>} showModalHeader={true} hideModal={closeModal} modal={showUpdateBalanceModal}>
                <Form>
                    <Form.Group>
                        <div className={'row'}>
                            <div className={'col-md-4 div-element-center'}>
                                <Form.Label>{`Balance (Tk)`}<span className={"text-danger"}>*</span></Form.Label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type={`text`}
                                    name={'solbox_balance'}
                                    className={`${styles.solTextInput} custom-text-input-style`}
                                    placeholder={'Balance in taka..'}
                                    value={balanceForShow ? balanceForShow: ''}
                                    onChange={(event) => balanceInputChangeEvent(event)}
                                    autoComplete="off"
                                    maxLength="10"
                                />
                            </div>
                        </div>
                        {balanceFieldValueError? (
                            <div className={`row`}>
                                <div className={'col-md-4'}></div>
                                <div className={`col-md-8`}>
                                    <span className="d-block mb-3"
                                          style={{ color: "rgba(203, 21, 43, 1)" }}>
                                        {`Please enter a valid balance!`}
                                    </span>
                                </div>
                            </div>
                        ) : ''}
                        {balanceError? (
                            <div className={`row`}>
                                <div className={'col-md-4'}></div>
                                <div className={`col-md-8`}>
                                    <span className="d-block mb-3"
                                          style={{ color: "rgba(203, 21, 43, 1)" }}>
                                        {`Please provide a balance!`}
                                    </span>
                                </div>
                            </div>
                        ) : ''}
                    </Form.Group>

                    <Modal.Footer>
                        <Button style={{
                            backgroundColor: '#6c757d',
                            border: 'none',
                        }} onClick={() => closeModal()}>
                            <i className="fa fa-window-close"/>
                            &nbsp;Cancel
                        </Button>
                        <Button variant="warning" type="button"
                                disabled={disableUpdateButton || props.disableBalanceUpdateButton}
                                onClick={() => handleBalanceUpdateEvent()}>
                            {props.disableBalanceUpdateButton ? <Spinner animation="border" variant="secondary"/> : ''}
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </GenericModal>
        </>
    }


    // SOLbox validation
    const firstStep =()=>{

        return <>
            <div className={'replacement-form-container'}>
                <div className={'shrink-column'}>
                    <label>New SOLbox Serial Number</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'new'}
                        placeholder="Enter New SOLbox Serial Number (ex. 11002203)"
                        type={'number'}
                        ref={register({
                            required: "Required",
                            pattern: solboxSerialNumbervalidation

                        })}
                    />
                    {errors.new && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.new.message}</div>}
                </div>
            </div>
            <div className={'replacement-form-container'}style={{
                alignItems: 'flex-start',
            }}>

                <div className={'expand-column'}>
                    <button type="submit" className={'btn btn-outline-warning btn-sm'} disabled={props.disableButton}>
                        {props.disableButton ? <Spinner animation="border" variant="secondary"/> :
                            <i className="fa fa-check"/>}
                        &nbsp;Validate
                    </button>
                </div>
            </div>
        </>
    }

    // SOLbox replacement
    const secondStep=()=>{
        return <>
            <div className={'replacement-form-container'}>
                <div className={'shrink-column'}>
                    <label>Old SOLbox Serial Number</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'old'}
                        value={oldSerial}
                        type={'number'}
                        disabled
                        ref={register({
                            required: "Required",
                        })}
                    />
                    {errors.old && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.old.message}</div>}

                </div>
            </div>
            <div className={'replacement-form-container'}>
                <div className={'shrink-column'}>
                    <label>New SOLbox Serial Number</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'new'}
                        placeholder="Enter New SOLbox Serial Number (ex. 11002203)"
                        type={'number'}
                        ref={register({
                            required: "Required",
                            pattern: solboxSerialNumbervalidation
                        })}
                        value={newSerial}
                        disabled
                    />
                    {errors.new && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.new.message}</div>}

                </div>
            </div>
            <div className={'replacement-form-container'}style={{
                alignItems: 'flex-start',
            }}>
                <div className={'shrink-column'}>
                    <label style={{
                        paddingTop: '10px'
                    }}>Remarks</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'remarks'}
                        as={'textarea'}
                        placeholder={"Enter Additional Remarks"}
                        ref={register()}
                        type={'number'}
                        rows={5}
                    />
                    {errors.remarks && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.remarks.message}</div>}

                </div>
            </div>
            <div className={'replacement-form-container'}style={{
                alignItems: 'flex-start',
            }}>

                <div className={'expand-column'}>
                    {/*<Button variant="primary" type="submit" disabled={props.disableButton}>*/}
                    {/*    {props.disableButton ? <Spinner animation="border" variant="secondary"/> :*/}
                    {/*        <i className="fa fa-arrow-left "/>}*/}
                    {/*    &nbsp;Back*/}
                    {/*</Button>&nbsp;&nbsp;&nbsp;*/}
                    <button className={'btn btn-outline-warning btn-sm mb-5'} type="submit" disabled={props.disableButton}>
                        {props.disableButton ? <Spinner animation="border" variant="secondary"/> :
                            <i className="fa fa-paper-plane"/>}
                        &nbsp;Replace
                    </button>
                </div>
            </div>
        </>
    }

    // Config transfer
    const thirdStep=()=>{
        return <>
            <div className={'replacement-form-container'}>
                <div className={'shrink-column'}>
                    <label>Old SOLbox Serial Number</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'old'}
                        value={oldSerial}
                        type={'number'}
                        disabled
                        ref={register({
                            required: "Required",
                        })}
                    />
                    {errors.old && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.old.message}</div>}
                </div>
            </div>
            <div className={'replacement-form-container'}>
                <div className={'shrink-column'}>
                    <label>New SOLbox Serial Number</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'new'}
                        placeholder="Enter New SOLbox Serial Number  (ex. 11002203)"
                        type={'number'}
                        ref={register({
                            required: "Required",
                            pattern: solboxSerialNumbervalidation
                        })}
                        value={newSerial}
                        disabled
                    />
                    {errors.new && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.new.message}</div>}
                </div>
            </div>

            <div className={'replacement-form-container'}style={{
                alignItems: 'flex-start',
            }}>

                <div className={'expand-column'}>
                    {/*<Button variant="primary" type="submit" disabled={props.disableButton}>*/}
                    {/*    {props.disableButton ? <Spinner animation="border" variant="secondary"/> :*/}
                    {/*        <i className="fa fa-arrow-left "/>}*/}
                    {/*    &nbsp;Back*/}
                    {/*</Button>&nbsp;&nbsp;&nbsp;*/}
                    <button className={'btn btn-outline-warning btn-sm mb-5'} type="submit" disabled={props.disableButton}>
                        {props.disableButton ? <Spinner animation="border" variant="secondary"/> :
                            <i className="fa fa-paper-plane"/>}
                        &nbsp;Send
                    </button>
                </div>
            </div>
        </>
    }

    // Balance transfer
    const fourthStep=()=>{
        return <>
            <div className={'replacement-form-container'}>
                <div className={'shrink-column'}>
                    <label>Old SOLbox Serial Number</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'old'}
                        value={oldSerial}
                        type={'number'}
                        disabled
                        ref={register({
                            required: "Required",
                        })}
                    />
                    {errors.old && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.old.message}</div>}
                </div>
            </div>
            <div className={'replacement-form-container'}>
                <div className={'shrink-column'}>
                    <label>New SOLbox Serial Number</label>
                </div>
                <div className={'expand-column'}>
                    <SolInput
                        name={'new'}
                        placeholder="Enter New SOLbox Serial Number (ex. 11002203)"
                        type={'number'}
                        ref={register({
                            required: "Required",
                            pattern: solboxSerialNumbervalidation
                        })}
                        value={newSerial}
                        disabled
                    />
                    {errors.new && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>{errors.new.message}</div>}

                </div>
            </div>

            <div className={'replacement-form-container'}style={{
                alignItems: 'flex-start',
            }}>

                <div className={'expand-column'}>
                    {/*<Button variant="primary" type="submit" disabled={props.disableButton}>*/}
                    {/*    {props.disableButton ? <Spinner animation="border" variant="secondary"/> :*/}
                    {/*        <i className="fa fa-arrow-left "/>}*/}
                    {/*    &nbsp;Back*/}
                    {/*</Button>&nbsp;&nbsp;&nbsp;*/}

                    <button className={'btn btn-outline-warning btn-sm mb-5'} type="submit" disabled={props.disableButton}>
                        {(props.disableButton || props.makeReimburseButtonDisabled) ? <Spinner animation="border" variant="secondary"/> :
                            <i className="fa fa-upload "/>}
                        &nbsp;Reimburse
                    </button>
                </div>
            </div>
        </>
    }

    const fifthStep =()=>{
        return <></>
    }


    const body=()=>{

        switch (phase)
        {
            case 1:
                return firstStep()
            case 2:
                return secondStep()
            case 3:
                return thirdStep();
            case 4:
                return fourthStep()
            case 5:
                return fifthStep()
        }
    }


    return <>
     <Form onSubmit={handleSubmit(props.submit)} autoComplete={'off'}>
        <div className={'row padding-to-bottom'}>

            <div className={'col-md-7 padding-to-right'}>

                <div className={'step-container'}>
                    <div className={'step-circle'}>
                        <div className={'step-circle__label'}>
                            {phase}
                        </div>
                    </div>
                    <div className={'step-title'}>
                        {title}
                    </div>
                </div>

                {body()}
                <ul style={{
                    paddingLeft:"0",
                    paddingTop: "20px",
                    paddingBottom: '20px',
                }}>
                    {getCheckLists()}
                </ul>
                {phase===5?<Button variant="primary" disabled={props.disableButton} onClick={backToCustomers}>
                    <i className="flaticon2-left-arrow-1"/>
                    &nbsp;Customers
                </Button>:null}
            </div>

            <div className={'col-md-5 border-to-left padding-to-left'}>
                <div className={'step-container'}>

                    <div className={'additional-title'}>
                        Current SOLbox Information
                    </div>
                </div>


                <div className={'replacement-form-container '}>
                    <div className={'shrink-column'}>
                        <label> Serial Number</label>
                    </div>
                    <div className={'expand-column'}>
                        <label className={'label-padding '}>{oldSerial}</label>
                    </div>
                </div>


                <div className={'replacement-form-container'}>
                    <div className={'shrink-column'}>
                        <label> Installation Date </label>
                    </div>
                    <div className={'expand-column'}>
                        <label className={'label-padding '}>{replacementInfo && replacementInfo.activation_date? new DateTimeFormat(replacementInfo.activation_date).localDisplayWithoutTime:'N/A'}</label>
                    </div>
                </div>


                <div className={'replacement-form-container'}>
                    <div className={'shrink-column'}>
                        <label> Last Balance</label>
                    </div>
                    <div className={'expand-column'}>
                        <label className={'label-padding '}>{oldSolboxBalance!==null && oldSolboxBalance!==undefined? `${oldSolboxBalance} BDT` :'N/A'}</label>
                    </div>
                </div>


                <div className={'replacement-form-container'}>
                    <div className={'shrink-column'}>
                        <label> Last FW version</label>
                    </div>
                    <div className={'expand-column'}>
                        <label className={'label-padding '}>{replacementInfo &&replacementInfo.last_fw_version? replacementInfo.last_fw_version:'N/A'}</label>
                    </div>
                </div>

                <div className={'replacement-form-container'}>
                    <div className={'shrink-column'}>
                        <label> Model Number</label>
                    </div>
                    <div className={'expand-column'}>
                        <label className={'label-padding '}>{replacementInfo &&replacementInfo.last_model_number? replacementInfo.last_model_number:'N/A'}</label>
                    </div>
                </div>

                {showUpdateBalanceButton ? <div className={'replacement-form-container'}>
                    <div className={'shrink-column'}>
                        <label> Last box communication time </label>
                    </div>
                    <div className={'expand-column'}>
                        <label className={'label-padding '}>{replacementInfo && replacementInfo.updated_at? new DateTimeFormat(replacementInfo.updated_at).localDisplayTime: 'Not communicated yet'}</label>
                    </div>
                </div> : ''}

                {showUpdateBalanceButton ? <div className={'replacement-form-container'}>
                    <button type="button"
                            className={'btn btn-outline-info btn-sm float-left'}
                            disabled={props.disableButton}
                            style={{
                                'white-space': 'nowrap',
                                'text-align': 'center'
                            }}
                            onClick={() => showModal()}>
                        {props.disableButton ? <Spinner animation="border" variant="secondary"/> :
                            <i className="fa fa-edit"/>}
                        &nbsp;Update SOLbox balance
                    </button>
                </div> : ''}
            </div>

        </div>
    </Form>
    {renderUpdateBalanceModal()}
    </>
}
