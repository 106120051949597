import React, { useState, useEffect } from "react";
import { solboxActions }   from "../../solboxActions";
import { connect } from 'react-redux';
import SolSelect from "../../../../components/SolSelect";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import SolTextInput from "../../../../components/soltextinput/SolTextInput";
import { toast } from "react-toastify";
import { withRoles } from "../../../../router/SecuredRoute";

const SolboxListFilter = (props) => {

    const { 
        location,
        history,
        isSolshare,
        b2bOrgGuid,
        division,
        divisionOptions,
        handleChangeDivision,
        gridOwner,
        gridOwnersOptions,
        handleSetGridOwnerOptions,
        handleChangeGridOwner,
        grid,
        gridListOptions,
        handleSetGridOptions,
        handleChangeGrid,
        solboxModel,
        boxType,
        solboxModelOptions,
        handleSetSolboxModelOptions,
        handleChangeSolboxModel,
        solBoxStatus,
        handleChangeSolboxStatus,
        solBoxSetupStatus,
        handleChangeSolboxSetupStatus,
        handleBoxTypeSelection,
        reset,
        solboxIdParam,
        handleChangeSolTextInput,
        pageNumber,
        solboxId,
        setSolboxListParams, 
        getSolboxes,
        toggleGetSolboxes,
        filterApplied,
        toggleSolboxListFilter,
        toggleFilterApplied,
        solboxList,
        paginatedSolboxList,
        gridOwnersList,
        gridList,
        isGridListLoading,
        modelList,
        GET_SOLBOX_LIST,
        GET_PAGINATED_SOLBOX_LIST,
        GET_GRID_OWNERS_LIST,
        GET_GRID_LIST_WITHOUT_PAGINATION,
        GET_MODELS_LIST,
        FILTER_SOLBOX_LIST
    } = props;

    const [gridListParams, setGridListParams] = useState(null);
    const [divisionParam, setDivisionParam] = useState(null);
    const [gridOwnerParam, setGridOwnerParam] = useState(null);
    const [nodeTypes, setNodeTypes] = useState([]);


    useEffect(() => {
        if (props.nodeTypes) {
            let types = props.nodeTypes;
            setNodeTypes(types.map((nodeType) => {
                return {
                    value: nodeType.id,
                    label: nodeType.name
                }
            }))
        }
    }, [props.nodeTypes]);

    useEffect(() => {
        if (isSolshare) {
            GET_GRID_OWNERS_LIST();
            GET_GRID_LIST_WITHOUT_PAGINATION({});
        } else {
            GET_GRID_OWNERS_LIST();
            GET_GRID_LIST_WITHOUT_PAGINATION({});
        }
    }, [isSolshare]);

    useEffect(() => {
        if (solboxModelOptions && solboxModelOptions.length === 0) {
            GET_MODELS_LIST();
        }
    }, [solboxModelOptions]);

    useEffect(() => {
        if (modelList && modelList.length !== 0) {
            let solboxModelArr = [];
            modelList.forEach((element, index) => {
                solboxModelArr.push({value: element.model_alias, label: element.model_alias});
            });
            handleSetSolboxModelOptions(solboxModelArr);
        }
    }, [modelList]);

    useEffect(() => {
        if (gridOwnersList && gridOwnersList.length !== 0) {
            let gridOwnersArr = [];
            gridOwnersList.forEach((element, index) => {
                if (element.organization_guid && element.organization_name) {
                    gridOwnersArr.push({
                        value: element?.organization_guid, 
                        label: element?.organization_name
                    });
                }
            });
            handleSetGridOwnerOptions(gridOwnersArr);
        }
    }, [gridOwnersList]);

    useEffect(() => {
        if (division) {
            setDivisionParam({"division": division.value});
        } else {
            setDivisionParam(null);
        }
    }, [division]);

    useEffect(() => {
        if (gridOwner && isSolshare) {
            setGridOwnerParam({"organization_guid": gridOwner.value});
        } else {
            setGridOwnerParam(null);
        }
    }, [gridOwner, isSolshare]);

    useEffect(() => {
        if (divisionParam) {
            if (gridOwnerParam &&
                isSolshare
            ) {
                let targetObject = {};
                Object.assign(targetObject, divisionParam);
                Object.assign(targetObject, gridOwnerParam);
                setGridListParams(targetObject);
            } else {
                setGridListParams(Object.assign({}, divisionParam));
            }
        } else {
            if (gridOwnerParam &&
                isSolshare
            ) {
                setGridListParams(Object.assign({}, gridOwnerParam));
            } else {
                setGridListParams(null);
            }
        }
    }, [divisionParam]);

    useEffect(() => {
        if (gridOwnerParam &&
            isSolshare
        ) {
            if (divisionParam) {
                let targetObject = {};
                Object.assign(targetObject, divisionParam);
                Object.assign(targetObject, gridOwnerParam);
                setGridListParams(targetObject);
            } else {
                setGridListParams(Object.assign({}, gridOwnerParam));
            }
        } else {
            if (divisionParam) {
                setGridListParams(Object.assign({}, divisionParam));
            } else {
                setGridListParams(null);
            }
        }
    }, [gridOwnerParam]);

    useEffect(() => {
        if (gridListParams) {
            GET_GRID_LIST_WITHOUT_PAGINATION(gridListParams);
        }
    }, [gridListParams]);

    useEffect(() => {
        if (gridList) {
            let gridListArr = [];
            gridList.forEach((element, index) => {
                if (element.grid_guid && element.name) {
                    gridListArr.push({
                        value: element.grid_guid, 
                        label: element.name
                    });
                }
            });
            handleSetGridOptions(gridListArr);
        } else {
            handleSetGridOptions([]);
        }
    }, [gridList]);

    const getFilteredSolboxes = (event) => {
        const regex = /^[0-9]+$/g;
        let formData = {};
        let displayData = {};

        if (gridOwner && isSolshare) {
            Object.assign(formData, {"organization_guid": gridOwner.value});
            Object.assign(displayData, {"organization_guid": gridOwner.label});
        }

        if (grid && 
            grid.length !== 0
        ) {
            let gridValArr = [];
            let gridLabelArr = [];
            grid.forEach((element, index) => {
                gridValArr.push(element.value);
                gridLabelArr.push(element.label);
            });
            Object.assign(formData, {"grid_guid": gridValArr});
            Object.assign(displayData, {"grid_guid": gridLabelArr});
        }

        if (division) {
            Object.assign(formData, {"division": division.value});
            Object.assign(displayData, {"division": division.value});
        }

        if (solboxIdParam && solboxIdParam.trim() && regex.test(solboxIdParam.trim())) {
            Object.assign(formData, {"solbox_id": solboxIdParam.trim()});
        }

        if (solBoxStatus) {
            Object.assign(formData, {"solbox_status": solBoxStatus});
            Object.assign(displayData, {"solbox_status": solBoxStatus});
        }

        if (solBoxSetupStatus) {
            Object.assign(formData, {"solbox_stage": solBoxSetupStatus});
            Object.assign(displayData, {"solbox_stage": solBoxSetupStatus});
        }

        if (solboxModel) {
            Object.assign(formData, {"solbox_model": solboxModel.value});
            Object.assign(displayData, {"solbox_model": solboxModel.label})
        }

        if (boxType) {
            Object.assign(formData, {"box_type": boxType.value});
            Object.assign(displayData, {"box_type": boxType.label})
        }

        if (!(Object.entries(formData).length < 1)) {
            setSolboxListParams(event, formData, displayData);
            toggleGetSolboxes(event);
            toggleFilterApplied(event, true);
            //GET_SOLBOX_LIST();
            FILTER_SOLBOX_LIST(null, solboxId, formData);
            history.push({
                pathname: '/solboxes/list',
                search: filterParamsHandler(formData)
            });
            //toggleSolboxListFilter(event);
        } else {
            if (getSolboxes) {
                toast.error("Please provide at least one filter criterion!");
            } else {
                //GET_SOLBOX_LIST();
                GET_PAGINATED_SOLBOX_LIST(pageNumber);
                toggleSolboxListFilter(event);
                toggleGetSolboxes(event);
            }
        }
    }

    const filterParamsHandler = (filterParams) => {
        let queryStringParams = "";
        if (!(Object.entries(filterParams).length < 1)) {
            for (const [key, value] of Object.entries(filterParams)) {
                if (!queryStringParams.includes("?")) {
                    queryStringParams += `?${key}=${value}`;
                } else {
                    queryStringParams += `&${key}=${value}`;
                }
            }
        }
        return queryStringParams;
    }

    return (
        <React.Fragment>
            <h5 className={`list-filter-control`}>{`FILTER`}</h5>
            <div className={`row list-filter-control`}>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label mb-5`}>
                        {`Division`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Division`}
                        value={division}
                        handleChangeSolSelect={handleChangeDivision}
                        options={divisionOptions}
                        selectMulti={false}
                        loading={false}
                        clearable={true}
                        noOptionsMessage={() => `No division available`}
                    />
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label mb-5`}>
                        {`Grid Operator`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Grid Operator`}
                        value={gridOwner}
                        handleChangeSolSelect={handleChangeGridOwner}
                        options={gridOwnersOptions}
                        selectMulti={false}
                        loading={false}
                        clearable={true}
                        disabled={isSolshare ? false : true}
                        noOptionsMessage={() => `No grid operator available`}
                    />
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label mb-5`}>
                        {`Grid`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Grid`}
                        value={grid}
                        handleChangeSolSelect={handleChangeGrid}
                        options={gridListOptions}
                        selectMulti={true}
                        loading={isGridListLoading}
                        clearable={true}
                        noOptionsMessage={() => `No grids available`}
                    />
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label mb-5`}>
                        {`SOLbox ID`}
                    </label>
                    <div className="form-group d-block">
                        <SolTextInput 
                            inputClassName={`custom-text-input-style`}
                            name={`solboxIdParam`}
                            placeHolder="Type SOLbox ID..."
                            value={solboxIdParam}
                            handleChange={(event) => handleChangeSolTextInput(event)}
                        />
                    </div>
                </div>
            </div>
            <div className={`row list-filter-control`}>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label mb-5`}>
                        {`SOLbox Model`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Model`}
                        value={solboxModel}
                        handleChangeSolSelect={handleChangeSolboxModel}
                        options={solboxModelOptions}
                        selectMulti={false}
                        loading={false}
                        clearable={true}
                        noOptionsMessage={() => `No model available`}
                    />
                </div>

                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label mb-5`}>
                        {`SOLbox Type`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Type`}
                        value={boxType}
                        handleChangeSolSelect={handleBoxTypeSelection}
                        options={nodeTypes}
                        selectMulti={false}
                        loading={props.nodeTypesFilterLoading}
                        clearable={true}
                        noOptionsMessage={() => `No model available`}
                    />
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label d-block mb-5`}>
                        {`Online Status`}
                    </label>
                    <div className="form-check form-check-inline mt-2">
                        <SolRadioInput
                            name="statusOfSolbox"
                            id="Online"
                            value={`online`}
                            handleChange={handleChangeSolboxStatus}
                            checked={(solBoxStatus === "online") ? true : false}
                            htmlFor="Online"
                            labelName="Online"
                        />
                    </div>
                    <div className="form-check form-check-inline mt-2 customer-status-inactive">
                        <SolRadioInput
                            name="statusOfSolbox"
                            id="Offline"
                            value={`offline`}
                            handleChange={handleChangeSolboxStatus}
                            checked={(solBoxStatus === "offline") ? true : false}
                            htmlFor="Offline"
                            labelName="Offline"
                        />
                    </div>
                </div>
                {isSolshare &&
                <div className={`col-sm-6`}>
                    <label className={`form-check-label d-block mb-5`}>
                        {`SOLbox Setup Status`}
                    </label>
                    <div className="form-check form-check-inline mt-2">
                        <SolRadioInput
                            name="statusOfSetup"
                            id="flashed"
                            value={`flashed`}
                            handleChange={handleChangeSolboxSetupStatus}
                            checked={(solBoxSetupStatus === "flashed") ? true : false}
                            htmlFor="flashed"
                            labelName="Flashed"
                        />
                    </div>
                    <div className="form-check form-check-inline mt-2 customer-status-inactive">
                        <SolRadioInput
                            name="statusOfSetup"
                            id="assemble"
                            value={`assemble`}
                            handleChange={handleChangeSolboxSetupStatus}
                            checked={(solBoxSetupStatus === "assemble") ? true : false}
                            htmlFor="assemble"
                            labelName="ID Created"
                        />
                    </div>
                    <div className="form-check form-check-inline mt-2 customer-status-inactive">
                        <SolRadioInput
                            name="statusOfSetup"
                            id="others"
                            value={`others`}
                            handleChange={handleChangeSolboxSetupStatus}
                            checked={(solBoxSetupStatus === "others") ? true : false}
                            htmlFor="others"
                            labelName="Others"
                        />
                    </div>
                </div>
                }
            </div>
            <div className={`row mb-5`}>
                <div className={`col-12`}>
                    <button
                        type={`button`}
                        className={'btn btn-outline-warning btn-sm mb-5'}
                        onClick={(event) => getFilteredSolboxes(event)}
                    >
                        {getSolboxes ? `Apply Filter` : `Get Solboxes`}
                    </button>
                    <button
                        type={`button`}
                        className={'btn btn-outline-secondary btn-sm ml-4 mb-5 list-filter-reset-button'}
                        onClick={(event) => reset(event)}
                    >
                        {`Reset Filter`}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.solboxReducer.isLoading,
        tableLoading: state.solboxReducer.tableLoading,
        statusCode: state.solboxReducer.statusCode,
        solboxList: state.solboxReducer.solboxList,
        paginatedSolboxList: state.solboxReducer.paginatedSolboxList,
        gridOwnersList: state.solboxReducer.gridOwnersList,
        gridList: state.solboxReducer.gridList,
        isGridListLoading: state.solboxReducer.isGridListLoading,
        modelList: state.solboxReducer.modelList,
        nodeTypes: state.solboxReducer.nodeTypes,
        nodeTypesFilterLoading: state.solboxReducer.nodeTypesFilterLoading
    }
};

export default connect(mapStateToProps, solboxActions)(withRoles(SolboxListFilter));
