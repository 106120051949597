import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import OtaUpdateList from "./views/otaUpdateList/otaUpdateList";
import OtaUpdateHistory from "./views/otaUpdateHistoryOfASolbox/otaUpdateHistoryOfASolbox";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";


const otaUpdatesRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/otaupdates"}
                to={"/otaupdates/list"}
            />

            {/* Routes to ota update List*/}
            <SecuredRoute
                path={"/otaupdates/list"}
                component={OtaUpdateList}
            />

            {/* Routes to ota update history of a SOLbox*/}
            <SecuredRoute
                path={"/otaupdates/individual-solbox/:solbox_guid/:solbox_serial_number"}
                component={OtaUpdateHistory}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};

export default otaUpdatesRouter;
