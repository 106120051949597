import React from "react";
import  {connect} from "react-redux";
import {CALL_API} from "../utils/actionTypes";
import ContentWrapper from "../components/contentWrapper/contentWrapper.js";
// import {
//   Dashboard
// } from "../../_metronic/_partials";

const actions = {
  DISPATCH_NEW_RECHARGE: (payload)=>({
    [CALL_API]: {
      endpoint: '/transactions/recharge/',
      method: 'POST',
      body: payload,
      backend_slug: 'financial',
      types: [
        'SHUVO',
        'IMRAN',
        'TANVIR_VAI',
      ]
    }
  }),
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.dashboard.loading,
  }
};

const DashboardPage = (props) => {

  // const {user} = useSelector(state => state.auth);

  // useEffect(()=>{
  //   props.DISPATCH_NEW_RECHARGE();
  // },[]);

  return (
      <React.Fragment>
        <ContentWrapper />
      </React.Fragment>
  );
}

export default connect(mapStateToProps, actions)(DashboardPage);

/*Initial State for grid*/
const initialState= {
  loading: true,
};

/*Reducer for Grid's redux store*/
export const dashboard = (state=initialState, action)=> {
  /*Check the documentation for each reducer's purpose in the actionTypes file*/
  console.log('DASHBOARD REDUCER')
  switch (action.type) {
    case 'asd':
      return {
        ...state,
      }

    default:
      return state;
  }
};