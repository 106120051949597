export const incompatibleFirmwareVersion = (firmwareVersion) => {
    let firmware_version = null;
    if (firmwareVersion)
    {
        const temp = firmwareVersion.split('_');
        firmware_version = parseFloat(temp[temp.length-1])

    }
    else if (!firmwareVersion) {
        return true;
    }
    return firmware_version < 5.214
}

export const determineBuyPriceAndSellPrice = (firmwareVersion, configParams) => {
    let firmware = parseFloat(firmwareVersion.split("_")[1])
    let buyPrice, sellPrice
    // "fBuyPrice_conf", "fSellPrice_conf" have been used for FW version less or equal to 5.212
    // "u16BuyPrice_conf", "u16SellPrice_conf" have been used for FW version 5.213 or higher
    if (firmware <= 5.212) {
        buyPrice = configParams.fBuyPrice_conf
        sellPrice = configParams.fSellPrice_conf
    } else {
        // These keys contains values in 'paisha'
        buyPrice = configParams.u16BuyPrice_conf / 100
        sellPrice = configParams.u16SellPrice_conf / 100
    }
    return [buyPrice, sellPrice]
}
