import React, {useEffect, useRef, useState} from "react";
import {solboxActions} from "../../solboxActions";
import {connect} from "react-redux";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";
import SolboxProfileDisplay from "./SolboxProfileDisplay";
import SolboxProfileTrading from "./SolboxProfileTrading";
import SolboxProfileTransactions from "./SolboxProfileTransactions";
import NodeReplacementList from "../../../customer/views/customerProfile/nodesReplacementModule/NodeReplacementList";
import {paisaToTaka} from "../../../../utils/utilityFunctions";
import styled from "styled-components";
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {useSubheader} from "../../../../../_metronic/layout";
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from "moment";
import {Spinner} from 'react-bootstrap';
import {GenericModal} from "../../../../components/genericModal/genericModal";
import ConfigurationHistory from "../../../commonFeatureModules/views/configurationHistory/configurationHistory";
import {withRoles} from "../../../../router/SecuredRoute";

const TabContainer = styled.div`
  padding: 2rem 2rem 0 0;
  color: #12100C !important;
`;


const SolboxProfile = (props) => {
    const {
        isLoading,
        isSolshare,
        b2bOrgGuid,
        solboxGenericData,
        topicPublished,
        bulkLiveData,
        performanceMetricsData,
        GET_SOLBOX_GENERAL_DATA,
        GET_SOLBOX_PERFORMANCE_METRICS_DATA,
        POST_SOLBOX_PUBLISH_TOPIC,
        GET_SOLBOX_BULK_LIVE_DATA,
        GET_SOLBOX_CONFIGURATION_HISTORY
    } = props;

    const [key, setKey] = useState("trading");
    const [solboxIdJson, setSolboxIdJson] = useState([]);
    const [solboxIdNormal, setSolboxIdNormal] = useState([]);
    const [parentSolboxData, setParentSolboxData] = useState(null);
    const [connectedSolboxData, setConnectedSolboxData] = useState([]);
    const [solboxGuid, setSolboxGuid] = useState(null);
    const [topicPublicationStatus, setTopicPublicationStatus] = useState(false);
    const [parentSolboxDisplayData, setParentSolboxDisplayData] = useState(null);
    const [parentSolboxCustomerData, setParentSolboxCustomerData] = useState(null);
    const [disableLatestDataBtn, setDisableLatestDataBtn] = useState(false);
    const [parentSolboxLiveData, setParentSolboxLiveData] = useState([]);
    const [connectedSolboxLiveData, setConnectedSolboxLiveData] = useState([]);
    const [parentSolboxPerformanceData, setParentSolboxPerformanceData] = useState([]);
    const [connectedSolboxPerformanceData, setConnectedSolboxPerformanceData] = useState([]);
    const [showLiveStatus, setShowLiveStatus] = useState(false);
    const [parentSolboxTimeTracker, setParentSolboxTimeTracker] = useState("");
    const [parentSolboxTimeReturnedFromBox, setParentSolboxTimeReturnedFromBox] = useState("");
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const subheader = useSubheader();



    useEffect(() => {
            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    {handleShowUtilityButton()}
                </div>
            )
            return () => {
                subheader.setActionButtons(null);
            }
        },
        [
            parentSolboxCustomerData,
            parentSolboxDisplayData,
            disableLatestDataBtn,
            parentSolboxTimeTracker,
            show,
            target,
            ref
        ]);

    useEffect(() => {
        if (parentSolboxLiveData &&
            parentSolboxLiveData.length !== 0 &&
            showLiveStatus === true
        ) {
            parentSolboxLiveData.forEach((element, index) => {
                if (element.solbox_id &&
                    props.match.params.id &&
                    element.solbox_id == props.match.params.id.substring(
                        props.match.params.id.length - 4, props.match.params.id.length) &&
                    element.updated_at
                ) {
                    setParentSolboxTimeTracker(moment(element.updated_at).format('lll'));
                    setParentSolboxTimeReturnedFromBox(element.last_solbox_time? moment(element.last_solbox_time).format('lll'):'N/A')
                }
            });
        }
    }, [parentSolboxLiveData, showLiveStatus]);

    useEffect(() => {
        if (parentSolboxPerformanceData &&
            parentSolboxPerformanceData.length !== 0 &&
            showLiveStatus === false
        ) {
            parentSolboxPerformanceData.forEach((element, index) => {
                if (element.solbox_guid &&
                    props.match.params.guid &&
                    element.solbox_guid === props.match.params.guid &&
                    element.updated_at
                ) {
                    setParentSolboxTimeTracker(moment(element.updated_at).format('lll'))
                    setParentSolboxTimeReturnedFromBox(element.last_solbox_time? moment(element.last_solbox_time).format('lll'):'N/A')
                }
            });
        }
    }, [parentSolboxPerformanceData, showLiveStatus]);

    useEffect(() => {
        if (props.match.params.guid) {
            GET_SOLBOX_GENERAL_DATA(props.match.params.guid);
        }
    }, [props.match.params.guid]);

    const toggleSolboxProfileTab = (key) => {
        setKey(key);
        if (key === "configuration_history") {
            GET_SOLBOX_CONFIGURATION_HISTORY(props.match.params.guid);
        }
    }

    // useEffect(() => {
    //     if (key && key === "configuration_history" && props.match.params.guid) {
    //         GET_SOLBOX_CONFIGURATION_HISTORY(props.match.params.guid);
    //     }
    // }, []);

    const redirectToSolboxList = () => {
        // props.history.push("/solboxes/list");
        props.history.goBack();
    };

    const solboxFirmwareVersionArr = [
        "SB312P30V12SG_5.214",
        "model_1_5.50",
        "pre_test_4.2",
        "5.214",
        "test_4.1",
    ];

    useEffect(() => {
        if (solboxGenericData !== undefined && solboxGenericData !== null) {
            let targetObj = {};
            Object.assign(targetObj, solboxGenericData);
            setParentSolboxData(targetObj);
        } else {
            setParentSolboxData(null);
        }
    }, [solboxGenericData]);

    const [solboxGuidList, setSolboxGuidList] = useState(null);

    useEffect(() => {
        if (
            solboxGenericData !== undefined &&
            solboxGenericData !== null &&
            solboxGenericData.connected_nodes &&
            solboxGenericData.connected_nodes.length !== 0
        ) {
            let solboxGuidArr = [];
            if (props.match.params.guid) {
                solboxGuidArr.push(props.match.params.guid);
            }
            solboxGenericData.connected_nodes.forEach((element, index) => {
                if (element.solbox_guid) {
                    solboxGuidArr.push(element.solbox_guid);
                }
            });
            setSolboxGuidList({solbox_guid_list: solboxGuidArr});
            setConnectedSolboxData(solboxGenericData.connected_nodes);
        } else {
            let solboxGuidArr = [];
            if (props.match.params.guid) {
                solboxGuidArr.push(props.match.params.guid);
            }
            setSolboxGuidList({solbox_guid_list: solboxGuidArr});
            setConnectedSolboxData([]);
        }
    }, [solboxGenericData]);

    useEffect(() => {
        if (solboxGuidList) {
            GET_SOLBOX_PERFORMANCE_METRICS_DATA(solboxGuidList);
        }
    }, [solboxGuidList]);

    useEffect(() => {
        if (parentSolboxData !== undefined && parentSolboxData !== null) {

            let parentSolboxDisplayObj = {};

            let ota_availability_text = 'Yes'
            if (parentSolboxData.ota_available === false) {
                ota_availability_text = 'No'
            } else if (parentSolboxData.last_ota_history) {
                let deviceEspOtaStatus = parentSolboxData.last_ota_history.device_esp_ota_status
                let deviceStmOtaStatus = parentSolboxData.last_ota_history.device_stm_ota_status
                let notInProgressStatuses = ['failed', 'succeeded', 'cancelled']
                if (parentSolboxData.ota_available === true && !notInProgressStatuses.includes(deviceEspOtaStatus) && !notInProgressStatuses.includes(deviceStmOtaStatus)) {
                    ota_availability_text = "OTA is in progress"
                }
            }

            Object.assign(parentSolboxDisplayObj, {
                node_type:
                    parentSolboxData.node_type !== undefined &&
                    parentSolboxData.node_type !== null
                        ? parentSolboxData.node_type
                        : `N/A`,
                nearby_node:
                    parentSolboxData.nearby_node
                        ? parentSolboxData.nearby_node
                        : "",
                firmware:
                    parentSolboxData.solbox_fw_version !== undefined &&
                    parentSolboxData.solbox_fw_version !== null
                        ? parentSolboxData.solbox_fw_version
                        : `N/A`,
                model:
                    parentSolboxData.solbox_model !== undefined &&
                    parentSolboxData.solbox_model !== null
                        ? parentSolboxData.solbox_model
                        : `N/A`,
                buy_price:
                    parentSolboxData.buy_price !== undefined &&
                    parentSolboxData.buy_price !== null
                        ? parentSolboxData.buy_price
                        : `N/A`,
                sell_price:
                    parentSolboxData.sell_price !== undefined &&
                    parentSolboxData.sell_price !== null
                        ? parentSolboxData.sell_price
                        : `N/A`,
                exchange_control:
                    parentSolboxData.exchange_control !== undefined &&
                    parentSolboxData.exchange_control !== null
                        ? parentSolboxData.exchange_control
                        : `N/A`,
                threshold_value:
                    parentSolboxData.threshold_value !== undefined &&
                    parentSolboxData.threshold_value !== null
                        ? parentSolboxData.threshold_value
                        : `N/A`,
                grid_name:
                    parentSolboxData.grid_name !== undefined &&
                    parentSolboxData.grid_name !== null
                        ? parentSolboxData.grid_name
                        : `N/A`,
                grid_address_info:
                    parentSolboxData.grid_address_info ?
                        parentSolboxData.grid_address_info
                        : `N/A`,
                grid_guid:
                    parentSolboxData.grid_guid !== undefined &&
                    parentSolboxData.grid_guid !== null
                        ? parentSolboxData.grid_guid
                        : `N/A`,
                customer_guid:
                    parentSolboxData.customer_guid !== undefined &&
                    parentSolboxData.customer_guid !== null
                        ? parentSolboxData.customer_guid
                        : `N/A`,
                customer_info:
                    parentSolboxData.customer_info !== undefined &&
                    parentSolboxData.customer_info !== null
                        ? parentSolboxData.customer_info
                        : `N/A`,
                node_guid:
                    parentSolboxData.node_guid !== undefined &&
                    parentSolboxData.node_guid !== null
                        ? parentSolboxData.node_guid
                        : "null",
                ota_availability: ota_availability_text,
            });

            setParentSolboxDisplayData(parentSolboxDisplayObj);

            if (
                parentSolboxData.customer_info !== undefined &&
                parentSolboxData.customer_info !== null
            ) {
                let parentSolboxCustomerObj = {};

                Object.assign(parentSolboxCustomerObj, {
                    name:
                        parentSolboxData.customer_info.name !== undefined &&
                        parentSolboxData.customer_info.name !== null
                            ? parentSolboxData.customer_info.name
                            : `N/A`,
                    primary_phone:
                        parentSolboxData.customer_info.primary_phone !== undefined &&
                        parentSolboxData.customer_info.primary_phone !== null
                            ? parentSolboxData.customer_info.primary_phone
                            : `N/A`,
                    occupation:
                        parentSolboxData.customer_info.occupation !== undefined &&
                        parentSolboxData.customer_info.occupation !== null
                            ? parentSolboxData.customer_info.occupation
                            : `N/A`,
                    income_range:
                        parentSolboxData.customer_info.income_range !== undefined &&
                        parentSolboxData.customer_info.income_range !== null
                            ? parentSolboxData.customer_info.income_range
                            : `N/A`,
                    appliances:
                        parentSolboxData.customer_info.appliances !== undefined &&
                        parentSolboxData.customer_info.appliances !== null &&
                        parentSolboxData.customer_info.appliances.length !== 0
                            ? parentSolboxData.customer_info.appliances
                            : `N/A`,
                    total_wattage:
                        parentSolboxData.customer_info.total_wattage !== undefined &&
                        parentSolboxData.customer_info.total_wattage !== null
                            ? parentSolboxData.customer_info.total_wattage
                            : `N/A`
                });

                setParentSolboxCustomerData(parentSolboxCustomerObj);
            }
        }
    }, [parentSolboxData]);

    useEffect(() => {
        if (parentSolboxData &&
            props.match.params.guid &&
            parentSolboxData.solbox_fw_version !== undefined &&
            parentSolboxData.solbox_fw_version !== null &&
            parentSolboxData.solbox_fw_version !== "" &&
            parentSolboxData.solbox_fw_version !== " "
        ) {
            let solboxGuidArr = [];
            let solboxGuidObj = {};
            if (parentSolboxData.solbox_fw_version.includes("_")) {
                if (
                    Number.parseFloat(
                        parentSolboxData.solbox_fw_version.substring(
                            parentSolboxData.solbox_fw_version.lastIndexOf("_") + 1,
                            parentSolboxData.solbox_fw_version.length
                        )
                    ) < 5.214
                ) {
                    solboxGuidArr.push(props.match.params.guid);
                }
            } else {
                if (Number.parseFloat(parentSolboxData.solbox_fw_version) < 5.214) {
                    solboxGuidArr.push(props.match.params.guid);
                }
            }

            if (
                parentSolboxData.connected_nodes &&
                parentSolboxData.connected_nodes.length !== 0
            ) {
                parentSolboxData.connected_nodes.forEach((element, index) => {
                    if (
                        element.solbox_guid !== undefined &&
                        element.solbox_guid !== null &&
                        element.solbox_guid !== "" &&
                        element.solbox_guid !== " " &&
                        element.solbox_fw_version !== undefined &&
                        element.solbox_fw_version !== null &&
                        element.solbox_fw_version !== "" &&
                        element.solbox_fw_version !== " "
                    ) {
                        if (element.solbox_fw_version.includes("_")) {
                            if (
                                Number.parseFloat(
                                    element.solbox_fw_version.substring(
                                        element.solbox_fw_version.lastIndexOf("_") + 1,
                                        element.solbox_fw_version.length
                                    )
                                ) < 5.214
                            ) {
                                if (!solboxGuidArr.includes(element.solbox_guid)) {
                                    solboxGuidArr.push(element.solbox_guid);
                                }
                            }
                        } else {
                            if (Number.parseFloat(element.solbox_fw_version) < 5.214) {
                                if (!solboxGuidArr.includes(element.solbox_guid)) {
                                    solboxGuidArr.push(element.solbox_guid);
                                }
                            }
                        }
                    }
                });
            }
            if (solboxGuidArr.length !== 0) {
                Object.assign(solboxGuidObj, {solbox_guid_list: solboxGuidArr});
                setSolboxGuid(solboxGuidObj);
            }
        }
    }, [parentSolboxData]);

    // useEffect(() => {
    //     if (solboxGuid !== undefined && solboxGuid !== null) {
    //         GET_SOLBOX_PERFORMANCE_METRICS_DATA(solboxGuid);
    //         setSolboxGuid(null);
    //     }
    // }, [solboxGuid]);

    useEffect(() => {
        if (parentSolboxData &&
            parentSolboxData.node_guid &&
            parentSolboxData.solbox_fw_version !== undefined &&
            parentSolboxData.solbox_fw_version !== null &&
            parentSolboxData.solbox_fw_version !== "" &&
            parentSolboxData.solbox_fw_version !== " "
        ) {
            let solboxIdJsonArr = [];
            let solboxIdNormalArr = [];
            if (parentSolboxData.solbox_fw_version.includes("_")) {
                if (
                    Number.parseFloat(
                        parentSolboxData.solbox_fw_version.substring(
                            parentSolboxData.solbox_fw_version.lastIndexOf("_") + 1,
                            parentSolboxData.solbox_fw_version.length
                        )
                    ) >= 5.214
                ) {
                    if (props.match.params.id) {
                        solboxIdJsonArr.push(props.match.params.id.substring(
                            props.match.params.id.length - 4,
                            props.match.params.id.length
                        ));
                        solboxIdNormalArr.push(
                            props.match.params.id.substring(
                                props.match.params.id.length - 4,
                                props.match.params.id.length
                            )
                        );
                        // setSolboxIdNormal(solboxIdNormalArr);
                    }
                }
            } else {
                // TODO: Is this checking is needed, we will always receive the value in 'xx_7.015' format - Reza, 3rd January, 2022
                if (Number.parseFloat(parentSolboxData.solbox_fw_version) >= 5.214) {
                    if (props.match.params.id) {
                        solboxIdJsonArr.push(props.match.params.id.substring(
                            props.match.params.id.length - 4,
                            props.match.params.id.length
                        ));
                        solboxIdNormalArr.push(
                            props.match.params.id.substring(
                                props.match.params.id.length - 4,
                                props.match.params.id.length
                            )
                        );
                        // setSolboxIdNormal(solboxIdNormalArr);
                    }
                }
            }

            if (parentSolboxData.connected_nodes &&
                parentSolboxData.connected_nodes.length !== 0
            ) {
                parentSolboxData.connected_nodes.forEach((element, index) => {
                    if (element.solbox_serial_number !== undefined &&
                        element.solbox_serial_number !== null &&
                        element.solbox_serial_number !== "" &&
                        element.solbox_serial_number !== " " &&
                        element.solbox_fw_version !== undefined &&
                        element.solbox_fw_version !== null &&
                        element.solbox_fw_version !== "" &&
                        element.solbox_fw_version !== " "
                    ) {
                        if (element.solbox_fw_version.includes("_")) {
                            if (
                                Number.parseFloat(
                                    element.solbox_fw_version.substring(
                                        element.solbox_fw_version.lastIndexOf("_") + 1,
                                        element.solbox_fw_version.length
                                    )
                                ) >= 5.214
                            ) {
                                if (solboxIdJsonArr.length !== 0 &&
                                    !solboxIdJsonArr.includes(element.solbox_serial_number.substring(
                                        element.solbox_serial_number.length - 4,
                                        element.solbox_serial_number.length
                                    ))
                                ) {
                                    solboxIdJsonArr.push(element.solbox_serial_number.substring(
                                        element.solbox_serial_number.length - 4,
                                        element.solbox_serial_number.length
                                    ));
                                } else {
                                    solboxIdJsonArr.push(element.solbox_serial_number.substring(
                                        element.solbox_serial_number.length - 4,
                                        element.solbox_serial_number.length
                                    ));
                                }

                                if (!solboxIdNormalArr.includes(
                                    Number.parseInt(
                                        element.solbox_serial_number.substring(
                                            element.solbox_serial_number.length - 4,
                                            element.solbox_serial_number.length
                                        ),
                                        10
                                    )
                                )
                                ) {
                                    solboxIdNormalArr.push(
                                        element.solbox_serial_number.substring(
                                            element.solbox_serial_number.length - 4,
                                            element.solbox_serial_number.length
                                        )
                                    );
                                    // setSolboxIdNormal(solboxIdNormalArr);
                                }
                            }
                        } else {
                            if (Number.parseFloat(element.solbox_fw_version) >= 5.214) {
                                // TODO: We are doing same thing in following if/else ? - Reza, 3rd January, 2022
                                if (solboxIdJsonArr.length !== 0 &&
                                    !solboxIdJsonArr.includes(element.solbox_serial_number.substring(
                                        element.solbox_serial_number.length - 4,
                                        element.solbox_serial_number.length
                                    ))
                                ) {
                                    solboxIdJsonArr.push(element.solbox_serial_number.substring(
                                        element.solbox_serial_number.length - 4,
                                        element.solbox_serial_number.length
                                    ));
                                } else {
                                    solboxIdJsonArr.push(element.solbox_serial_number.substring(
                                        element.solbox_serial_number.length - 4,
                                        element.solbox_serial_number.length
                                    ));
                                }

                                if (!solboxIdNormalArr.includes(
                                    Number.parseInt(
                                        element.solbox_serial_number.substring(
                                            element.solbox_serial_number.length - 4,
                                            element.solbox_serial_number.length
                                        ),
                                        10
                                    )
                                )
                                ) {
                                    solboxIdNormalArr.push(
                                        element.solbox_serial_number.substring(
                                            element.solbox_serial_number.length - 4,
                                            element.solbox_serial_number.length
                                        )
                                    );
                                    // setSolboxIdNormal(solboxIdNormalArr);
                                }
                            }
                        }
                    }
                });
            }
            if (solboxIdJsonArr.length !== 0) {
                let solboxIds = [];
                solboxIdJsonArr.forEach((element, index) => {
                    solboxIds.push({"solbox_id": element});
                });
                setSolboxIdJson(solboxIds);
            }

            if (solboxIdNormalArr.length !== 0) {
                setSolboxIdNormal(solboxIdNormalArr)
            }
        }
    }, [parentSolboxData]);

    const handleCheckLatestData = (event) => {
        setDisableLatestDataBtn(true);
        setShow(true);
        setTarget(event.target);
        if (solboxIdJson && solboxIdJson.length !== 0) {
            POST_SOLBOX_PUBLISH_TOPIC(solboxIdJson);
            setShowLiveStatus(true);
        }
        // 'solboxGuidList' is used so that peformance metrix API has always been called
        // 'solboxIdJson' is only available when fw is greater or equal than 5.214
        if (solboxGuidList) {
            GET_SOLBOX_PERFORMANCE_METRICS_DATA(solboxGuidList);
        }
    }

    useEffect(() => {
        if (
            performanceMetricsData &&
            performanceMetricsData.length !== 0 &&
            props.match.params.guid
        ) {
            let parentSolboxPerformanceDataArr = [];
            let connectedSolboxPerformanceDataArr = [];
            performanceMetricsData.forEach((element, index) => {
                if (element.solbox_guid &&
                    props.match.params.guid === element.solbox_guid
                ) {
                    parentSolboxPerformanceDataArr.push({
                        solbox_guid:
                            element.solbox_guid
                                ? element.solbox_guid
                                : "",
                        solbox_mode:
                            element.solbox_mode
                                ? element.solbox_mode
                                : "",
                        balance:
                            element.last_known_balance !== undefined &&
                            element.last_known_balance !== null
                                ? element.last_known_balance
                                : `N/A`,
                        status:
                            element.solbox_status
                                ? element.solbox_status
                                : `Offline`,
                        current_buy_price: paisaToTaka(element.current_buy_price),
                        current_sell_price: paisaToTaka(element.current_sell_price),
                        router_ssid:
                            element.solbox_ssid
                                ? element.solbox_ssid
                                : `N/A`,
                        updated_at:
                            element.updated_at
                                ? element.updated_at
                                : "",
                        last_solbox_time:
                            element.last_solbox_time
                                ? element.last_solbox_time
                                : ""
                    });
                }
            });

            setParentSolboxPerformanceData(parentSolboxPerformanceDataArr);

            performanceMetricsData.forEach((element, index) => {
                if (element.solbox_guid &&
                    props.match.params.guid !== element.solbox_guid
                ) {
                    connectedSolboxPerformanceDataArr.push({
                        solbox_guid:
                            element.solbox_guid
                                ? element.solbox_guid
                                : "",
                        solbox_mode:
                            element.solbox_mode
                                ? element.solbox_mode
                                : "",
                        i2Grid:
                            element.i2Grid
                                ? element.i2Grid
                                : "",
                        battery_voltage:
                            element.battery_voltage
                                ? element.battery_voltage
                                : "",
                        status:
                            element.solbox_status
                                ? element.solbox_status
                                : `offline`,
                        updated_at:
                            element.updated_at
                                ? element.updated_at
                                : ""
                    });
                }
            });
            setConnectedSolboxPerformanceData(connectedSolboxPerformanceDataArr);
        }
    }, [performanceMetricsData]);

    // useEffect(() => {
    //     if (solboxIdJson !== undefined &&
    //         solboxIdJson !== null &&
    //         solboxIdJson.length !== 0
    //     ) {
    //         POST_SOLBOX_PUBLISH_TOPIC(solboxIdJson);
    //     }
    // }, [solboxIdJson]);

    useEffect(() => {
        if (topicPublished !== undefined &&
            topicPublished !== null &&
            topicPublished !== "" &&
            topicPublished === "Success"
        ) {
            const timeout = setTimeout(() => {
                setTopicPublicationStatus(true);
            }, 7000);

            return () => {
                clearTimeout(timeout);
                // setSolboxIdJson([]);
            };
        }
    }, [topicPublished]);

    useEffect(() => {
        if (solboxIdNormal &&
            solboxIdNormal.length !== 0 &&
            topicPublicationStatus !== false
        ) {
            GET_SOLBOX_BULK_LIVE_DATA(solboxIdNormal);
        }
        return () => {
            // setSolboxIdNormal([]);
            setTopicPublicationStatus(false);
        }
    }, [topicPublicationStatus]);

    useEffect(() => {
        if (
            bulkLiveData &&
            bulkLiveData.length !== 0 &&
            props.match.params.id
        ) {
            let parentSolboxLiveDataArr = [];
            let connectedSolboxLiveDataArr = [];
            bulkLiveData.forEach((element, index) => {
                if (element.solbox_id &&
                    element.solbox_id == props.match.params.id.substring(props.match.params.id.length - 4, props.match.params.id.length)
                ) {
                    parentSolboxLiveDataArr.push({
                        solbox_id:
                            element.solbox_id
                                ? element.solbox_id
                                : "",
                        balance:
                            element.i32Balance !== undefined &&
                            element.i32Balance !== null
                                ? element.i32Balance
                                : `N/A`,
                        status: element.bIsOnline ? "Online" : "Offline",
                        current_buy_price: paisaToTaka(element.u16BuyPrice),
                        current_sell_price: paisaToTaka(element.u16SellPrice),
                        solbox_mode:
                            typeof element.u8UserMode === "number" &&
                            element.u8UserMode === 0
                                ? `Auto`
                                :  element.u8UserMode && element.u8UserMode == 1
                                ? `Sell`
                                :  element.u8UserMode && element.u8UserMode == 2
                                    ? `Buy`
                                    :  element.u8UserMode && element.u8UserMode == 3
                                        ? `Stop`
                                        : "",
                        router_ssid:
                            element.ssid
                                ? element.ssid
                                : `N/A`,
                        updated_at:
                            element.updated_at
                                ? element.updated_at
                                : "",
                        last_solbox_time:
                            element.last_solbox_time
                                ? element.last_solbox_time
                                : ""
                    });
                }
            });

            setParentSolboxLiveData(parentSolboxLiveDataArr);

            bulkLiveData.forEach((element, index) => {
                if (element.solbox_id &&
                    element.solbox_id != props.match.params.id.substring(props.match.params.id.length - 4, props.match.params.id.length)
                ) {
                    connectedSolboxLiveDataArr.push({
                        solbox_id: element.solbox_id ? element.solbox_id : "",
                        status: element.bIsOnline ? "online" : "offline",
                        solbox_mode:
                            typeof element.u8UserMode === "number" &&
                            element.u8UserMode === 0
                                ? `Auto`
                                :  element.u8UserMode && element.u8UserMode == 1
                                ? `Sell`
                                :  element.u8UserMode && element.u8UserMode == 2
                                    ? `Buy`
                                    :  element.u8UserMode && element.u8UserMode == 3
                                        ? `Stop`
                                        : "",
                        i2Grid:
                            element.i32IToGrid
                                ? element.i32IToGrid
                                : "",
                        battery_voltage:
                            element.u32VBattery &&
                            !isNaN(element.u32VBattery)
                                ? (element.u32VBattery / 1000).toFixed(2)
                                : "",
                        updated_at: element.updated_at ? element.updated_at : ""
                    });
                }
            })

            setConnectedSolboxLiveData(connectedSolboxLiveDataArr);
        }
    }, [bulkLiveData]);

    const [loadSolboxProfile, setLoadSolboxProfile] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoadSolboxProfile(false);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (disableLatestDataBtn === true) {
                setDisableLatestDataBtn(false);
            }
        }, 12000);

        return () => {
            clearTimeout(timeout);
        };
    }, [disableLatestDataBtn]);

    useEffect(() => {
        if (disableLatestDataBtn === false) {
            setShow(false);
        }
    }, [disableLatestDataBtn])

    const [loadingSubtext, setLoadingSubtext] = useState("");

    useEffect(() => {
        if (loadSolboxProfile !== false) {
            setLoadingSubtext(`Getting live data. Usually takes 10-15 seconds`);
        }
    }, [loadSolboxProfile]);

    const RightBackButton = <React.Fragment>
        <button
            type={`button`}
            className={'btn btn-outline-warning btn-sm'}
            onClick={(event) => redirectToSolboxList(event)}
        >
            <i className="fa fa-chevron-left"/>
            &nbsp;Go Back
        </button>
    </React.Fragment>;

    const redirectToCreateTransaction = (event) => {
        if (props.match.params.id &&
            parentSolboxCustomerData &&
            parentSolboxCustomerData.name &&
            parentSolboxDisplayData &&
            parentSolboxDisplayData.grid_name
        ) {
            props.history.push(`/transactions/create-for/${props.match.params.id}/${parentSolboxCustomerData.name}/${parentSolboxDisplayData.grid_name}/${parentSolboxDisplayData.node_type}`);
        }
    }

    const showReplacementModal = (data) => {
        if (props.match.params.id &&
            parentSolboxDisplayData &&
            parentSolboxDisplayData.grid_guid &&
            parentSolboxDisplayData.customer_guid &&
            parentSolboxDisplayData.node_guid
        ) {
            props.history.push(`/replacements/replace/${parentSolboxDisplayData.grid_guid.trim()}/${props.match.params.id.trim()}/${parentSolboxDisplayData.customer_guid.trim()}/${parentSolboxDisplayData.node_guid.trim()}`);
        }
    }

    const redirectTOConfiguration = (event) => {
        if (props.match.params.id &&
            props.match.params.guid
        ) {
            props.history.push(`/solboxes/configuration/${props.match.params.guid}/${props.match.params.id}`);
        }
    }

    const redirectToOtaUpdate = (event) => {
        if (props.match.params.id &&
            props.match.params.guid
        ) {
            props.history.push(`/otaupdates/individual-solbox/${props.match.params.guid}/${props.match.params.id}`);
        }
    }

    const handleShowUtilityButton = () => {
        return (
            <React.Fragment>
                {parentSolboxTimeTracker ?
                    <div className="d-flex align-items-start justify-content-end flex-column mb-1 mr-5">
                        <div className="list-bold-text">{`Last data received on `}</div>
                        <div className="list-bold-text">{parentSolboxTimeTracker}</div>
                        <div className="list-bold-text">SOLbox time: {parentSolboxTimeReturnedFromBox}</div>
                    </div>
                    :
                    <div className="d-flex align-item-center justify-content-end pt-2">
                        <span className="d-block list-bold-text mt-3">{`No Data Found`}</span>
                    </div>
                }
                <div className="d-flex align-item-center justify-content-end pt-2">
                    <button
                        className="btn btn-warning btn-sm ml-5 mb-2"
                        onClick={(event) => handleCheckLatestData(event)}
                        disabled={disableLatestDataBtn}
                        ref={ref}
                    >
                        <RefreshIcon className={`sidebar-menu-item`} style={{"paddingRight": "4px"}} />
                        Check Latest Data
                        <GenericModal
                            modal={show}
                            showModalHeader={false}
                            size={`sm`}
                            backdropClassName={`check-live-data-modal`}
                            centered={true}
                        >
                            <div className="text-center">
                                <Spinner animation="border" variant="warning"></Spinner>
                            </div>
                            <div className="text-center mt-5 ml-5 mr-5">{`CHECKING LATEST DATA...`}</div>
                            <div className="text-center mt-1 ml-5 mr-5">{`Usually it takes 10 to 15 seconds`}</div>
                        </GenericModal>
                    </button>
                </div>
                {isSolshare &&
                <React.Fragment>
                    <div className="d-flex align-item-center justify-content-end pt-2">
                        <button
                            className="btn btn-warning btn-sm ml-5 mb-2"
                            onClick={(event) => showReplacementModal(event)}
                        >
                            <SyncAltOutlinedIcon className={`sidebar-menu-item`} style={{"paddingRight": "4px"}} />
                            Replace
                        </button>
                    </div>
                    <div className="d-flex align-item-center justify-content-end flex-grow-1 pt-2">
                        <button
                            className="btn btn-warning btn-sm ml-5 mb-2"
                            onClick={(event) => redirectToCreateTransaction(event)}
                        >
                            <img src={require('../../../../utils/asset/taka.svg')} alt="transaction-icon" style={{"paddingRight": "4px"}}/>
                            Create Transaction
                        </button>
                    </div>
                    {/*<div className="d-flex align-item-center justify-content-end pt-2">*/}
                    {/*    <button*/}
                    {/*        className="btn btn-warning btn-sm ml-5 mb-2"*/}
                    {/*        onClick={(event) => redirectToOtaUpdate(event)}*/}
                    {/*    >*/}
                    {/*        <i className={`fa fa-wrench`} style={{"paddingRight": "4px"}} />*/}
                    {/*        Update FW version*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <div className="d-flex align-item-center justify-content-end pt-2">
                        <button
                            className="btn btn-warning btn-sm ml-5 mb-2"
                            onClick={(event) => redirectTOConfiguration(event)}
                        >
                            <EditOutlinedIcon className={`sidebar-menu-item`} style={{"paddingRight": "4px"}} />
                            Update Config
                        </button>
                    </div>
                </React.Fragment>
                }
            </React.Fragment>
        );
    }

    return (
        <ContentWrapper
            permissionDenied={false}
            pageTitle={""}
            showBackButton={false}
            backFunction={redirectToSolboxList}
            isLoading={loadSolboxProfile}
            showCardHeader={false}
            // showUtilityButton={handleShowUtilityButton}
            loadingSubText={null}
        >
            <SolboxProfileDisplay
                {...props}
                parentSolboxId={props.match.params.id}
                parentSolboxGuid={props.match.params.guid}
                solboxIdNormal={solboxIdNormal}
                solboxGuid={solboxGuid}
                parentSolboxDisplayData={parentSolboxDisplayData}
                parentSolboxCustomerData={parentSolboxCustomerData}
                connectedSolboxData={connectedSolboxData}
                showLiveStatus={showLiveStatus}
                parentSolboxLiveData={parentSolboxLiveData}
                connectedSolboxLiveData={connectedSolboxLiveData}
                parentSolboxPerformanceData={parentSolboxPerformanceData}
                connectedSolboxPerformanceData={connectedSolboxPerformanceData}
            />
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(key) => toggleSolboxProfileTab(key)}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <Tab
                    eventKey="trading"
                    title="Trading"
                    tabClassName={key === "trading" ? "solbox-profile-tab" : ""}
                >
                    <TabContent bsPrefix="solbox-tab-content">
                        <SolboxProfileTrading solboxId={props.match.params.id} />
                    </TabContent>
                </Tab>
                <Tab
                    eventKey="configuration_history"
                    title="Configuration History"
                    tabClassName={
                        key === "configuration_history" ? "solbox-profile-tab" : ""
                    }
                >
                    {/*<SolboxProfileConfigurationHistory/>*/}

                    <TabContainer>
                        <ConfigurationHistory solboxGuid={props.match.params?.guid} solboxSerialNumber={props.match.params?.id?.toString().slice(4)}/>
                    </TabContainer>
                </Tab>
                <Tab
                    eventKey="replacements"
                    title="Replacements"
                    tabClassName={key === "replacements" ? "solbox-profile-tab" : ""}
                >
                    <TabContainer>
                        <NodeReplacementList solboxGuid={props.match.params?.guid}/>
                    </TabContainer>

                </Tab>
                <Tab
                    eventKey="transactions"
                    title="Transactions"
                    tabClassName={key === "transactions" ? "solbox-profile-tab" : ""}
                >
                    <SolboxProfileTransactions solboxId={props.match.params.id} />
                </Tab>
            </Tabs>
        </ContentWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.solboxReducer.isLoading,
        tableLoading: state.solboxReducer.tableLoading,
        statusCode: state.solboxReducer.statusCode,
        solboxList: state.solboxReducer.solboxList,
        solboxGenericData: state.solboxReducer.solboxGenericData,
        topicPublished: state.solboxReducer.topicPublished,
        bulkLiveData: state.solboxReducer.bulkLiveData,
        performanceMetricsData: state.solboxReducer.performanceMetricsData,
    };
};

export default connect(mapStateToProps, solboxActions)(withRoles(SolboxProfile));
