import { smartboardActionTypes as actionTypes } from "./smartboardActionTypes";
import {toast} from "react-toastify";
import {requestCycle} from "../grids/utils";
import moment from "moment";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    transactionHistory: [],
};

export const smartboardReducer = (state = initialState, action) => {
    switch (action.type) {

        //GET_GRID_LIST
        case actionTypes.GET_GRID_LIST_SMARTBOARD_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_GRID_LIST_SMARTBOARD_SUCCESS:

            return {
                ...state,
                filterDropdownLoading: false,
                gridList: action.payload,
            };
        case actionTypes.GET_GRID_LIST_SMARTBOARD_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
                statusCode: action.payload.status,
            };

           //GET_GRID_OPERATOR_LIST
        case actionTypes.GET_GRID_OPERATOR_LIST_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_GRID_OPERATOR_LIST_SUCCESS:
            return {
                ...state,
                filterDropdownLoading: false,
                gridOperatorList: action.payload,
            };
        case actionTypes.GET_GRID_OPERATOR_LIST_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
                statusCode: action.payload.status,
            };

            //GET_SOLBOX_MODEL_LIST
        case actionTypes.GET_SOLBOX_MODEL_LIST_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_SOLBOX_MODEL_LIST_SUCCESS:
            return {
                ...state,
                filterDropdownLoading: false,
                solboxModelList: action.payload,
            };
        case actionTypes.GET_SOLBOX_MODEL_LIST_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
                statusCode: action.payload.status,
            };

            //GET_FIRMWARE_VERSION
        case actionTypes.GET_FIRMWARE_VERSION_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_FIRMWARE_VERSION_SUCCESS:
            return {
                ...state,
                filterDropdownLoading: false,
                firmwareVersionList: action.payload,
            };
        case actionTypes.GET_FIRMWARE_VERSION_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
                statusCode: action.payload.status,
            };

            //GET_ISUE_TYPE_LIST
        case actionTypes.GET_ISUE_TYPE_LIST_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_ISUE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                filterDropdownLoading: false,
                issueTypeList: action.payload,
            };
        case actionTypes.GET_ISUE_TYPE_LIST_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
                statusCode: action.payload.status,
            };


        case actionTypes.GET_ISUE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_ISSUE_LIST_FOR_CSV_EXPORT_REQUEST:
            return {
                ...state,
                iconLoading: true,
            };
        case actionTypes.GET_ISSUE_LIST_WITH_SEPARATE_LOADER_REQUEST:
            return {
                ...state,
                filterTriggeredLoading: true,
            };
        case actionTypes.GET_ISUE_LIST_SUCCESS:
            return {
                ...state,
                refreshTime: moment(new Date()),
                filterTriggeredLoading: false,
                isLoading: false,
                iconLoading: false,
                issueList: action.payload,
                trackerissueList: action.payload?.summary
            };
        case actionTypes.GET_ISUE_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                iconLoading: false,
                filterTriggeredLoading: false,
                statusCode: action.payload.status,
            };

        case actionTypes.POST_RESOLVE_ISSUE_REQUEST:
            return {
                ...state,
                resolveSuccess: undefined,
                resolveLoading: true,
            };
        case actionTypes.POST_RESOLVE_ISSUE_SUCCESS:
            toast.success('Resolve request successful');
            return {
                ...state,
                resolveSuccess: requestCycle.success,
                resolveLoading: false,
            };
        case actionTypes.POST_RESOLVE_ISSUE_FAILURE:
            toast.error('Resolve request failed');
            return {
                ...state,
                resolveSuccess: requestCycle.failed,
                resolveLoading: false,
            };


        default:
            return state;
    }
};
