import React from "react";
import {
    DangerStatusRender,
    PendingRenderStatus,
    SuccessRenderStatus,
    NotInitiatedRender
} from "../../components/legendComponents/legendComponents";
const replacementStatuses= {
    topUp: {
        success: <b><SuccessRenderStatus>Transfer Balance</SuccessRenderStatus></b>,
        processing: <b><PendingRenderStatus>Transfer Balance</PendingRenderStatus></b>,
        failed: <b><DangerStatusRender>Transfer Balance</DangerStatusRender></b>,
        not_init: <b><NotInitiatedRender>Transfer Balance</NotInitiatedRender></b>,
    },
    config: {
        success: <b><SuccessRenderStatus>Transfer Config</SuccessRenderStatus></b>,
        processing: <b><PendingRenderStatus>Transfer Config</PendingRenderStatus></b>,
        failed: <b><DangerStatusRender>Transfer Config</DangerStatusRender></b>,
        not_init: <b><NotInitiatedRender>Transfer Config</NotInitiatedRender></b>
    },
}
export const ReplacementLegends=(props)=>{
    return <div className={'col-md-12 no-padding mt-5'} >
        <div className={'row'}>
            <div className={'col-md-4 '}>

            </div>
            <div className={'col-md-4 '}>

            </div>
            {/*<div className={'col-md-3 '}>*/}

            {/*</div>*/}

            <div className={'col-md-4 '} style={{
                display: "flex",
                gap: '15px',

                // justifyContent: 'right',
                justifyContent: "flex-end",
            }}>
                <b><PendingRenderStatus>Processing</PendingRenderStatus></b>
                <b><SuccessRenderStatus>Success</SuccessRenderStatus></b>
                <b><DangerStatusRender>Failed</DangerStatusRender></b>
                <b><NotInitiatedRender>Not initiated</NotInitiatedRender></b>
            </div>
        </div>
    </div>
}

export const getStatus=(replacement)=> {
    if (!replacement)
    {
        return <DangerStatusRender>{"No Data Found"}</DangerStatusRender>
    }else if (replacement && replacement.balance_transfer_status==='success' && replacement.config_transfer_status==='success')
    {
        return <b><SuccessRenderStatus>Completed</SuccessRenderStatus></b>
    }
    else if (replacement && replacement.balance_transfer_status==='failed' && replacement.config_transfer_status==='failed')
    {
        return <b><DangerStatusRender>Failed</DangerStatusRender></b>
    }
    else if (replacement && !replacement.balance_transfer_status && !replacement.config_transfer_status)
    {
        return <DangerStatusRender>{"No Data Found"}</DangerStatusRender>
    }else {
        return <>
            {replacementStatuses.topUp[replacement.balance_transfer_status]}
            <br/>
            <br/>
            {replacementStatuses.config[replacement.config_transfer_status]}
        </>
    }
}



export const getStatusInString=(replacement)=>{
    if (!replacement || !replacement.config_transfer_status || !replacement.balance_transfer_status) {
        return {
            message: "No Data Found",
            retry: false,
        }
    }
    else if (replacement.config_transfer_status === 'not_init')
    {
        return {
            message: "Config transfer and balance transfer is not initiated",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'processing' && replacement.balance_transfer_status === "not_init")
    {
        return {
            message: "Config transfer is in progress, and balance transfer is not initiated",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'failed' && replacement.balance_transfer_status === "not_init")
    {
        return {
            message: "Config transfer has failed, and balance transfer is not initiated",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'success' && replacement.balance_transfer_status === "not_init")
    {
        return {
            message: "Config transfer is successful, but balance transfer is not initiated",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'processing' && replacement.balance_transfer_status === "processing")
    {
        return {
            message: "In progress",
            retry: false,
        }
    }
    else if (replacement.config_transfer_status === 'failed' && replacement.balance_transfer_status === "processing")
    {
        return {
            message: "Config transfer has failed, but balance transfer is in progress",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'success' && replacement.balance_transfer_status === "processing")
    {
        return {
            message: "Config transfer is successful, and balance transfer is in progress",
            retry: false,
        }
    }
    else if (replacement.config_transfer_status === 'processing' && replacement.balance_transfer_status === "failed")
    {
        return {
            message: "Config transfer is in progress, and balance transfer has failed",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'failed' && replacement.balance_transfer_status === "failed")
    {
        return {
            message: "Failed",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'success' && replacement.balance_transfer_status === "failed")
    {
        return {
            message: "Config transfer is successful, but balance transfer has failed",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'processing' && replacement.balance_transfer_status === "success")
    {
        return {
            message: "Config transfer is in progress, but balance transfer is successful",
            retry: false,
        }
    }
    else if (replacement.config_transfer_status === 'failed' && replacement.balance_transfer_status === "success")
    {
        return {
            message: "Config transfer has failed, but balance transfer is successful",
            retry: true,
        }
    }
    else if (replacement.config_transfer_status === 'success' && replacement.balance_transfer_status === "success")
    {
        return {
            message: "Completed",
            retry: false,
        }
    }
};