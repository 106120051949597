import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useFormik } from "formik";
// import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {actions} from "../actions";
import  { Form, InputGroup,Button, Spinner } from 'react-bootstrap';
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import Firebase from "../firebase";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

// const initialValues = {
//   email: "admin@demo.com",
//   password: "demo",
// };

// const CustomInput = <input
//     className={`form-control form-control-solid h-auto py-5 px-6`}
// />

function Login(props) {
  // const { intl } = props;
  // const [loading, setLoading] = useState(false);
  // const { intl } = props;
  // const [otp, setotp] = useState(false);
  const {register, control, errors, handleSubmit}= useForm();
  // const [phone, setPhone] = useState(false);
  // const [confirmation, setConfirmation] = useState(undefined);
  // const [selectedOption, setSelectedOption] = useState(null);
  // const [loadingButtonStyle, setLoadingButtonStyle] = useState({
  //   paddingRight: "2.5rem"
  // });
  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string()
  //     .email("Wrong email format")
  //     .min(3, "Minimum 3 symbols")
  //     .max(50, "Maximum 50 symbols")
  //     .required(
  //       intl.formatMessage({
  //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
  //       })
  //     ),
  //   password: Yup.string()
  //     .min(3, "Minimum 3 symbols")
  //     .max(50, "Maximum 50 symbols")
  //     .required(
  //       intl.formatMessage({
  //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
  //       })
  //     ),
  // });

  // const enableLoading = () => {
  //   setLoading(true);
  // };
  //
  // const disableLoading = () => {
  //   setLoading(false);
  // };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }
  //
  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }
  //
  //   return "";
  // };

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: LoginSchema,
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     enableLoading();
  //   },
  // });


  const recaptcha = () => {
    const recap = new Firebase.reCaptcha();
    return recap;
  }

  const googleSignIn = () => {
    props.POPUP_SIGNIN( props.firebase, recaptcha());
  }

  const backToPhoneStage=()=>{
    props.CLEAR_CONFIRMATION_OBJECT();
  }

  const phoneSubmit=(data)=>{
    let phone =null;
    if (data.phone.charAt(0)==='0')
    {
      phone=data.phone.substring(1);
    }else{
      phone= data.phone
    }
    const payload={
      phone: `+${data.countryCode.value}${phone}`,
    }
    props.VERIFY_PHONE(payload.phone, props.firebase, recaptcha());
  }
  const otpSubmit=(data)=>{
    props.CONFIRM_OTP(data.otp, props.confirmation);

  }



  const otpStage=()=>{
    return <>
      <Form
          onSubmit={handleSubmit(otpSubmit)}
          className="form fv-plugins-bootstrap fv-plugins-framework"
      >

        <div role="alert" className="alert alert-info">
          <div className="alert-text">
            A one time pin (OTP) has been sent to your phone number. Enter that pin to proceed further.
          </div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <Form.Control
              placeholder="Enter OTP Number"
              type="text"
              name="otp"
              ref={register({
                required: 'OTP pin required',
              })}
              autoComplete={"off"}
          />
          {errors.otp && <div className={"text-danger"}>
            <i className={"flaticon-warning kt-font-brand"}/>{errors.otp.message}</div>}
          {props.isError && props.errorMsg && <div className={"text-danger"}>
            <i className={"flaticon-warning kt-font-brand"}/>{props.errorMsg}</div>}
        </div>

        <Button
            onClick={backToPhoneStage}
            variant={'warning'}
            style={{
              backgroundColor: '#F18D00'
            }}
        >
          <i className={'fa fa-arrow-left'}/>
          &nbsp;
          Back
        </Button>
        <Button
            type="submit"
            variant={`primary`}
            className={'float-right'}
            disabled={props.buttonLoading}
        >
          Submit
          {props.buttonLoading && <>&nbsp; &nbsp;<Spinner animation="border"  size={'sm'} variant="light"/></>}
        </Button>


      </Form>
    </>
  }



  const phoneStage=()=>{
    return <>
      <Form
          onSubmit={handleSubmit(phoneSubmit)}
          className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div role="alert" className="alert alert-info">
          <div className="alert-text">
            Enter your Phone Number with country code
          </div>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <InputGroup className="row no-padding no-margin mb-3">
            <div className={'col-md-4 no-padding'}>
              <Controller
                  as={<Select/>}
                  name={`countryCode`}
                  placeholder={`Select a Type`}
                  isDisabled={false}
                  isLoading={false}
                  maxMenuHeight={200}
                  isClearable={true}
                  control={control}
                  rules={{
                    required: "Required",
                  }}
                  defaultValue={{value: '880', label: '+880'}}
                  isSearchable={true}
                  options={[{value: '880', label: '+880'}]}
                  onChange={([selected]) => {
                    // setSelectedOption(selected?selected:null)
                    return selected
                  }}
              />
            </div>
            <div className={'col-md-8 row no-margin no-padding'}>
              <div className={'col-md-12 no-padding'} style={{
                paddingLeft: '5px'
              }}>
                <Form.Control
                    placeholder="Enter Phone Number"
                    type="text"
                    name="phone"
                    ref={register({
                      required: "required",
                    })}
                />
                {errors.phone && <div className={"text-danger"}>
                  <i className={"flaticon-warning kt-font-brand"}/>{errors.phone.message}</div>}
                {errors.countryCode && <div className={"text-danger"}>
                  <i className={"flaticon-warning kt-font-brand"}/>{errors.countryCode.message}</div>}
                {props.isError && props.errorMsg && <div className={"text-danger"}>
                  <i className={"flaticon-warning kt-font-brand"}/>{props.errorMsg}</div>}
              </div>
            </div>

          </InputGroup>
        </div>

        <Button
            disabled={props.emailButtonLoading}
            onClick={googleSignIn}
            variant={'warning'}
            style={{
              backgroundColor: '#F18D00'
            }}
        >
          Sign In with SOLshare
        </Button>
        <Button
            disabled={props.buttonLoading || props.emailButtonLoading}
            type="submit"
            variant={'primary'}
            className={'float-right'}
        >
          Sign In
          {props.buttonLoading && <>&nbsp; &nbsp;<Spinner animation="border"  size={'sm'} variant="light"/></>}
        </Button>

      </Form>
    </>
  }

  return (
      <div className="login-form login-signin" id="kt_login_signin_form">
        <div id="recaptcha-container"/>
        {/* begin::Head */}
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            {/*<FormattedMessage id="AUTH.LOGIN.TITLE" />*/}
            Login to {process.env.REACT_APP_APP_NAME}
          </h3>
          {/*<p className="text-muted font-weight-bold">*/}

          {/*</p>*/}
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        {props.confirmation?otpStage():phoneStage()}
        {/*end::Form*/}
      </div>
  );
}
/*Mapping the store variable to props for this component*/
const mapStateToProps = state =>{
  return {
    firebase: state.auth.firebase,
    user: state.auth.user,
    confirmation: state.auth.confirmation,
    isError: state.auth.isError,
    errorMsg: state.auth.errorMsg,
    buttonLoading: state.auth.loading,
    emailButtonLoading: state.auth.emailButtonLoading,
  }
};

export default injectIntl(connect(mapStateToProps, actions)(Login));
