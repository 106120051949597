import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table';
import {Button, Spinner} from 'react-bootstrap';
// import { makeStyles } from '@material-ui/core/styles';
import {Badge} from 'react-bootstrap';
import Pagination from "@material-ui/lab/Pagination";
import createMuiTheme from "@material-ui/core/styles/createTheme";
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from 'prop-types'
import {dataTableStyle} from "./dataTableStyles";
import {makeStyles} from "@material-ui/core/styles";
import {isNumber} from "../../utils/utilityFunctions";
// import {dataTableStyle} from "../Datatable/DatatableStyle";

const theme= createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#F18D00',
        },
    },
});

const useStylesForPagination = makeStyles({
    root: {

        '& ul': {
            float: 'right',
        },

    },
});
// const useStyles = makeStyles({
//     root: {
//         backgroundColor: "blue",
//         color: "green"
//     },
//     toolbar: {
//         backgroundColor: "white"
//     },
//     caption: {
//         color: "red",
//         fontSize: "20px"
//     },
//     selectIcon: {
//         color: "green"
//     },
//     select: {
//         color: "green",
//         fontSize: "20px"
//     },
//     actions: {
//         color: "blue"
//     }
// });

const DataTable = props => {
    const [page, setPage] = useState(1);
    const [columns, setColumns] = useState([])
    const [pageSize, setPageSize] = useState( props.pageSize? props.pageSize: 10);
    const paginationClasses = useStylesForPagination()
    useEffect(()=>{
        if (props.columns.length){
            setColumns(props.columns.map((item,index)=>{
                return {
                    ...item,
                    // cellStyle: {
                    //     fontSize: '1.15rem',
                    //     fontWeight: 300,
                    // },
                }
            }))
        }

    },[props.columns])

    useEffect(()=>{
        setPage(props.page ? props.page : 1);
    },[props.page])

    const changePage=(event, page)=> {
        setPage(page);
        props.onChangePage(event, page);
    }

    const getPageCount=()=> {
        return Math.ceil(props.count/props.itemsPerPage);
    }
    useEffect(()=>{
        if ( props.itemsPerPage && isNumber(props.itemsPerPage) && props.itemsPerPage>0 && (props.itemsPerPage-props.data?.length )<=10 ) setPageSize(props.itemsPerPage)
        else if ( props.itemsPerPage && isNumber(props.itemsPerPage) && props.itemsPerPage>0 && (props.itemsPerPage-props.data?.length )>10 && props.data?.length>=5 ) setPageSize(props.data.length%5===0 ?props.data.length: props.data.length+(5-props.data.length%5) )
        else if ( props.itemsPerPage && isNumber(props.itemsPerPage) && props.itemsPerPage>0 && (props.itemsPerPage-props.data?.length )>10 && props.data?.length<5 ) setPageSize(10)
        else if (!props.pageSize && props.data?.length && props.data?.length>=5 ) setPageSize(props.data.length%5===0 ?props.data.length: props.data.length+(5-props.data.length%5))
        else if (!props.pageSize && props.data?.length && props.data?.length<5 ) setPageSize(5)
    }, [props.data || props.itemsPerPage])
    const mainTable=()=> {
        return <div >
            <ThemeProvider theme={theme}>
                <MaterialTable
                    columns={columns}
                    data={props.data}
                    title={props.title? typeof props.title === 'function'? props.title(): props.title : null}
                    actions={props.actions && props.actions.length>0 ?props.actions:undefined}
                    onSelectionChange={props.onSelectionChange}
                    style={dataTableStyle.dataTableCardStyle}
                    onRowClick={props.onRowClick}
                    options={{
                        selection: props.selection,
                        actionsColumnIndex: -1,
                        padding: "dense",
                        paging: props.pagination !== false,
                        pageSize: pageSize,
                        // pageSize: props.itemsPerPage?props.itemsPerPage:10,
                        // pageSizeOptions: props.pageSizeOptions? props.pageSizeOptions : props.data && props.data.length<10?[10]:[10,20,30],
                        paginationType: 'stepped',
                        search: props.search !== false,
                        debounceInterval: 1500,
                        fixedColumns: props.fixedColumns,
                        toolbar: props.showToolbar?props.showToolbar:undefined,
                        headerStyle: {
                            fontWeight: 500,
                            fontSize: '1.1rem',
                            color: '#8C8C9B',
                            // height: '50px',
                            // padding: "0em 1em",
                        },
                    }}
                    detailPanel={props.detailPanel}
                    localization={{
                        toolbar: {
                            nRowsSelected: props.selectionText,
                            searchPlaceholder: props.searchPlaceholder
                        },
                        header: {
                            actions: props?.actionText || ""
                        }
                    }}
                    components={props.asyncPagination===true && 
                        props.count && 
                        props.onChangePage && 
                        typeof props.onChangePage ==='function'? {
                        Pagination: ()=>(<Pagination
                            style={{
                                marginBottom:'15px',
                                marginTop: '15px',
                            }}
                            classes={paginationClasses}
                            count={getPageCount()} 
                            page={page} 
                            onChange={(event,page)=>changePage(event,page)} 
                            color={'#f5b938'}
                        />),
                        // Pagination: ()=>(<TablePagination count={props.count} variant="outlined" onChangePage={props.onChangePage} page={props.page} labelDisplayedRows={({ from, to, count }) =>{return `${to}`}} rowsPerPage={props.pageSize} rowsPerPageOptions={[ 20, 50, 100]}/>),
                    }: undefined}
                    onSearchChange={props.asyncSearch && typeof props.asyncSearch === 'function'? (text)=>props.asyncSearch(text): undefined}
                    isLoading={props.isLoading}
                />
            </ThemeProvider>
        </div>
    }

    const noDataAvailable=()=>{

        return <>
            {props.isLoading?
            <div className={'d-flex justify-content-center'}>
                <Spinner animation="border" variant="warning" />
            </div>
            :
            <div className={'d-flex justify-content-center'}>
                <h1>
                    <Badge variant="secondary">No Data Available</Badge>
                </h1>
            </div>}
            {props.reloadFunction && typeof props.reloadFunction === "function" && 
            <div className={'d-flex justify-content-center'}>
                <Button variant="secondary"  onClick={props.reloadFunction}>
                    {props.disableFlashButton ? <Spinner animation="border" variant="warning" />:<i className="flaticon2-refresh-button"/>}
                    &nbsp;Reload
                </Button>
            </div>}
        </>
        // <div className={'d-flex justify-content-center'}>
        //     <Button variant="secondary"  onClick={props.reloadFunction}>
        //         {props.disableFlashButton ? <Spinner animation="border" variant="secondary" />:<i className="flaticon2-refresh-button"/>}
        //         &nbsp;Reload
        //     </Button>
        // </div>
    }

    return <>
        {props.data && props.data.length===0?noDataAvailable(): mainTable() }
    </>

};

DataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    actions: PropTypes.array,
    selection: PropTypes.bool,
    pagination: PropTypes.bool,
    asyncPagination: PropTypes.bool,
    count: PropTypes.number,
    onChangePage: PropTypes.func,
    isLoading: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    asyncSearch: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    selectionText: PropTypes.string,
    disableFlashButton: PropTypes.bool,
    reloadFunction: PropTypes.func,
    search: PropTypes.bool,
    pageSize: PropTypes.number,
    showToolbar: PropTypes.bool,
    onRowClick: PropTypes.func,
    detailPanel: PropTypes.func,
    page: PropTypes.number,
    actionText: PropTypes.string,

}
export default DataTable;
