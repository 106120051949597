import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import DemoComponent from "./demoComponent";

export const DemoRouter =()=> {
    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/"}
                to={"/demo"}
            />

            {/* Routes to Transactions List*/}
            <Route
                path={"/demo"}
                component={DemoComponent}
            />



            <Redirect to="/error/error-v1" />
        </Switch>
    )
};