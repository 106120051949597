import {CALL_API} from "../../utils/actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
import {commonActionTypes} from "./commonActionTypes";
import {actionTypes} from "../grids/actionTypes";
import {solboxActionTypes} from "../solbox/solboxActionTypes";

export const commonActions = {
    PUBLISH_TOPIC_FOR_SINGLE_SOLBOX: (params={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler("/solbox/request_for_live_data/", params),
            method: 'GET',
            types: [
                commonActionTypes.PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_REQUEST,
                commonActionTypes.PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_SUCCESS,
                commonActionTypes.PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_FAILURE,
            ]
        }
    }),

    GET_LIVE_DATA_FOR_SINGLE_SOLBOX: (params={})=>({
        [CALL_API]: {
            endpoint: paramsHandler("/solbox/get_live_data/", params),
            method: 'GET',
            types: [
                commonActionTypes.GET_LIVE_DATA_FOR_SINGLE_SOLBOX_REQUEST,
                commonActionTypes.GET_LIVE_DATA_FOR_SINGLE_SOLBOX_SUCCESS,
                commonActionTypes.GET_LIVE_DATA_FOR_SINGLE_SOLBOX_FAILURE,
            ]
        }
    }),

    FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX: (params)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/config/solbox_current_config/', params),   /*solbox_id*/
            method: 'GET',
            types: [
                commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_REQUEST,
                commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_SUCCESS,
                commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_FAILURE,
            ]
        }
    }),

    FETCH_CONFIG_DETAILS: (params)=>({
        [CALL_API]: {
            endpoint:  paramsHandler('/config/config_history_details/', params),
            method: 'GET',
            types: [
                commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_REQUEST,
                commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_SUCCESS,
                commonActionTypes.FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_FAILURE,
            ]
        }
    }),

    UPDATE_SOLBOX_CONFIGURATION: (payload)=>({
        [CALL_API]: {
            endpoint: `/config/process_config/`,
            method: 'POST',
            body: payload,
            types: [
                commonActionTypes.UPDATE_SOLBOX_CONFIGURATION_REQUEST,
                commonActionTypes.UPDATE_SOLBOX_CONFIGURATION_SUCCESS,
                commonActionTypes.UPDATE_SOLBOX_CONFIGURATION_FAILURE,
            ]
        }
    }),

    FETCH_SOLBOX_CONFIG_HISTORY: (params)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/config/solbox_config_history/',params),
            method: 'GET',
            types: [
                commonActionTypes.FETCH_SOLBOX_CONFIG_HISTORY_REQUEST,
                commonActionTypes.FETCH_SOLBOX_CONFIG_HISTORY_SUCCESS,
                commonActionTypes.FETCH_SOLBOX_CONFIG_HISTORY_FAILURE,
            ]
        }
    }),

    GET_SOLBOX_PROFILE_DATA: (params) => ({
        [CALL_API]: {
            endpoint:  paramsHandler('/node/solbox_profile/', params),
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                commonActionTypes.GET_SOLBOX_PROFILE_DATA_REQUEST,
                commonActionTypes.GET_SOLBOX_PROFILE_DATA_SUCCESS,
                commonActionTypes.GET_SOLBOX_PROFILE_DATA_FAILURE,
            ],
        },
    }),

    GET_LEGACY_LIVE_DATA_COMMON: (payload)=>({
        [CALL_API]: {
            endpoint: `/matrices/get_latest_metrics_data/`,
            method: 'POST',
            body: payload,
            backend_slug: 'performance',
            types: [
                commonActionTypes.GET_LEGACY_LIVE_DATA_COMMON_REQUEST,
                commonActionTypes.GET_LEGACY_LIVE_DATA_COMMON_SUCCESS,
                commonActionTypes.GET_LEGACY_LIVE_DATA_COMMON_FAILURE,
            ]
        }
    }),

    SET_FLAGS_TO_UNDEFINED : ()=>({type: commonActionTypes.SET_FLAGS_TO_UNDEFINED}),
}