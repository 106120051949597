/*Content Wrapper
*
* version: 2.0.0
* Author: Shajedul Islam Shuvo
*
* If you update this component, then please update the version according to the company versioning protocol
*
* */
import React from "react";
import HttpErrorHandler from "../HttpErrorHandler";
import { Spinner, Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import {PermissionDenied} from "../permissionDenied";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../_metronic/_partials/controls";
import {SolTypography} from "../utilityComponents/SOlTypography";

const ContentWrapper = ({
                            children,
                            showBackButton,
                            backFunction,
                            isLoading,
                            statusCode,
                            permissionDenied,
                            pageTitle,
                            reloadFunction,
                            showUtilityButton,
                            showCardHeader,
                            loadingSubText, loadingText,
}) => {

    // render Children
    const renderBody = () => {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }

    const renderLogic = () => {
        if (statusCode !== undefined && statusCode !== null)
        {
           return (
               <div className="align-content-center" align="center">
                   <HttpErrorHandler
                       disableFlashButton={false}
                       statusCode={statusCode}
                       reloadFunction={reloadFunction}
                   />
               </div>
           );
        } else if (permissionDenied === true)
        {
            return (
                <div className="align-content-center" align="center">
                    <PermissionDenied disableFlashButton={false} reloadFunction={reloadFunction}/>
                </div>
            );
        } else if (isLoading === true)
        {
            return (
                <div className="align-content-center" align="center" style={{"marginTop": 100}}>
                    <h2 className={`mb-5`}>
                        <span>{loadingText?loadingText: "Fetching Data..."}</span>
                        {/*&nbsp;&nbsp;*/}
                        {/*<span className="loader-dot">{`.`}</span>*/}
                        {/*<span className="loader-dot">{`.`}</span>*/}
                        {/*<span className="loader-dot">{`.`}</span>*/}
                    </h2>
                    <Spinner className={`mt-5`} animation="border" variant="warning" />
                    <br/>
                    <SolTypography.SubTitle primary> {loadingSubText} </SolTypography.SubTitle>
                </div>
            );
        }
        else {
           return (
               <React.Fragment>
                   {renderBody()}
               </React.Fragment>
           );
        }
    }

    // Main Wrapper
    return (
      <React.Fragment>
        <Card>
          {showCardHeader === true && (
            <CardHeader title={pageTitle === undefined ? "N/A" : pageTitle}>
              <CardHeaderToolbar>
                {showBackButton === true &&
                backFunction &&
                typeof backFunction === "function" ? (
                  <button
                    type="button"
                    className="btn btn-outline-warning btn-sm"
                    onClick={() => backFunction()}
                  >
                    <ChevronLeftIcon />
                    &nbsp;Go Back
                  </button>
                ) : null}
                {(typeof showUtilityButton === "function") ? showUtilityButton() : null}
              </CardHeaderToolbar>
            </CardHeader>
          )}
          <CardBody>{renderLogic()}</CardBody>
        </Card>
      </React.Fragment>
    );
}

ContentWrapper.propTypes = {
    showBackButton: PropTypes.bool.isRequired,
    backFunction: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    statusCode: PropTypes.number,
    permissionDenied: PropTypes.bool,
    pageTitle: PropTypes.string.isRequired,
    reloadFunction: PropTypes.func,
    showUtilityButton: PropTypes.func,
    showCardHeader: PropTypes.bool.isRequired,
    loadingSubText: PropTypes.string,
    loadingText: PropTypes.string,
}

export default ContentWrapper;