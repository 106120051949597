import React from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import '../styles/typographyStyles.css'

export const GenericModal = ({
    modal, 
    hideModal, 
    title, 
    footer, 
    footerCloseButtonText,
    footerActionButtonText,
    size,
    noSubmitButton,
    submitAction,
    showModalHeader,
    centered,
    backdropClassName,
    ...props
})=> {

    return <>
        <Modal 
            show={modal} 
            onHide={hideModal} 
            animation={true} 
            size={size || undefined} 
            backdropClassName={backdropClassName} 
            centered={centered}
        >
            {(showModalHeader === true) &&
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title >
                    <button type="button" className="close" onClick={hideModal} aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close"/>
                    </button>
                </Modal.Header>
            }
            <Modal.Body>
                {props.children}
            </Modal.Body>
            {footer ?<>
                <Modal.Footer>
                    <Button style={{
                        backgroundColor: '#6c757d',
                        border: 'none',
                    }} disabled={props.disableButton} onClick={hideModal}>
                        <i className="fa fa-window-close"/>
                        &nbsp;{footerCloseButtonText? footerCloseButtonText:'Close'}
                    </Button>
                    {noSubmitButton? null:<>
                    {submitAction? <Button variant="primary" type="button" disabled={props.disableButton}
                                           onClick={()=>{submitAction()}}>
                        {props.disableButton ? <Spinner animation="border" variant="secondary"/> :
                            <i className="fa fa-paper-plane"/>}
                        &nbsp;{footerActionButtonText}
                    </Button>:<Button variant="primary" type="submit" disabled={props.disableButton}>
                        {props.disableButton ? <Spinner animation="border" variant="secondary"/> :
                            <i className="fa fa-paper-plane"/>}
                        &nbsp;{footerActionButtonText}
                    </Button>}
                    </>}
                </Modal.Footer>
            </>:null}
        </Modal>
    </>
}
