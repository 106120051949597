import {customerActionTypes} from "./customerActionTypes";
import {CALL_API} from "../../utils/actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";

export const customerActions = {

    FETCH_GRID_LIST: (queryParam) => ({
        [CALL_API]: {
            endpoint: filterParamsHandler(`/grid/get_list_details/`, "", queryParam),
            backend_slug: 'grid_backend',
            method: 'GET',
            types: [
                customerActionTypes.FETCH_GRID_LIST_REQUEST,
                customerActionTypes.FETCH_GRID_LIST_SUCCESS,
                customerActionTypes.FETCH_GRID_LIST_FAILURE
            ]
        }
    }),
    FETCH_ALL_CUSTOMERS: () => ({
        [CALL_API]: {
            endpoint: `/grid/customers/`,
            backend_slug: 'grid_backend',
            method: 'GET',
            types: [
                customerActionTypes.FETCH_ALL_CUSTOMER_REQUEST,
                customerActionTypes.FETCH_ALL_CUSTOMER_SUCCESS,
                customerActionTypes.FETCH_ALL_CUSTOMER_FAILURE
            ]
        }
    }),
    FETCH_FILTERED_CUSTOMERS: (filterParams) => ({
        [CALL_API]: {
            endpoint: filterParamsHandler(`/grid/customers/`, "", filterParams),
            backend_slug: 'grid_backend',
            method: 'GET',
            types: [
                customerActionTypes.FETCH_FILTERED_CUSTOMER_REQUEST,
                customerActionTypes.FETCH_FILTERED_CUSTOMER_SUCCESS,
                customerActionTypes.FETCH_FILTERED_CUSTOMER_FAILURE
            ]
        }
    }),
    FETCH_SEARCHED_CUSTOMER: (customerPhone, filterParams) => ({
        [CALL_API]: {
            endpoint: (filterParams === null && customerPhone !== "") ? `/grid/customers/?customer_phone=${customerPhone}` : filterParamsHandler(`/grid/customers/`, customerPhone, filterParams),
            backend_slug: 'grid_backend',
            method: 'GET',
            types: [
                customerActionTypes.FETCH_SEARCHED_CUSTOMER_REQUEST,
                customerActionTypes.FETCH_SEARCHED_CUSTOMER_SUCCESS,
                customerActionTypes.FETCH_SEARCHED_CUSTOMER_FAILURE
            ]
        }
    }),
    FETCH_CUSTOMER_DETAILS: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler("/customers/customer_profile/", filter),
            backend_slug: `customer_backend`,
            method: 'GET',
            types: [
                customerActionTypes.FETCH_CUSTOMER_DETAILS_REQUEST,
                customerActionTypes.FETCH_CUSTOMER_DETAILS_SUCCESS,
                customerActionTypes.FETCH_CUSTOMER_DETAILS_FAILURE,
            ]
        }
    }),
    MU_PROFILE_DETAILS: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler("/node/solbox_shs_profile/", filter),
            backend_slug: `grid_backend`,
            method: 'GET',
            types: [
                customerActionTypes.MU_PROFILE_DETAILS_REQUEST,
                customerActionTypes.MU_PROFILE_DETAILS_SUCCESS,
                customerActionTypes.MU_PROFILE_DETAILS_FAILURE,
            ]
        }
    }),

    REPLACEMENT_LIST_FOR_SPECIFIC_NODE: (payload={})=>{
        const  separateLoaderCheckArray= [ 'start_date', 'end_date', "page" ];
        let confirmationArray= [];
        Object.keys(payload).forEach((key)=>{
            if (separateLoaderCheckArray.indexOf(key)>-1 && payload[key]) {
                confirmationArray.push(key);
            }
        })
        return {
            [CALL_API]: {
                endpoint: paramsHandler('/node/replacement_history/', payload),   /*?node_type_id=${}&grid_owner_guid=${}*/
                method: 'GET',
                backend_slug: 'grid_backend',
                types: [
                    confirmationArray?.length>0?customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SEPARATE_LOADER_REQUEST: customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_REQUEST,
                    customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SUCCESS,
                    customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_FAILURE,
                ]}}
    },


    REPLACEMENT_RETRY_FROM_NODE: (payload={})=>({
        [CALL_API]: {
            endpoint: '/solbox/retry_send_config_and_reimbursement_to_replaced_solbox/',   /*?node_type_id=${}&grid_owner_guid=${}*/
            method: 'POST',
            body: payload,
            types: [
                customerActionTypes.REPLACEMENT_RETRY_FROM_NODE_REQUEST,
                customerActionTypes.REPLACEMENT_RETRY_FROM_NODE_SUCCESS,
                customerActionTypes.REPLACEMENT_RETRY_FROM_NODE_FAILURE,
            ]
        }
    }),
    FETCH_TRANSACTION_TYPES: () => ({
        [CALL_API]: {
            endpoint: `/transactions/types/`,
            method: 'GET',
            backend_slug: 'financial',
            types: [
                customerActionTypes.FETCH_TRANSACTION_TYPES_REQUEST,
                customerActionTypes.FETCH_TRANSACTION_TYPES_SUCCESS,
                customerActionTypes.FETCH_TRANSACTION_TYPES_FAILURE
            ]
        }
    }),
    GET_TRANSACTION_HISTORY_LIST: (filterParams) => ({
        [CALL_API]: {
            endpoint: (filterParams === null) ? `/solbox_topups/history/` : filterParamsHandler(`/solbox_topups/history/`, "", filterParams),
            method: `GET`,
            types: [
                customerActionTypes.GET_TRANSACTION_HISTORY_LIST_REQUEST,
                customerActionTypes.GET_TRANSACTION_HISTORY_LIST_SUCCESS,
                customerActionTypes.GET_TRANSACTION_HISTORY_LIST_FAILURE
            ]
        }
    })
};



const filterParamsHandler = (endpoint, customerPhone, filterParams) => {
    let endPointWithParams = endpoint;
    if (!(Object.entries(filterParams).length < 1)) {
        for (const [key, value] of Object.entries(filterParams)) {
            if (!endPointWithParams.includes("?")) {
                endPointWithParams += `?${key}=${value}`;
            } else {
                endPointWithParams += `&${key}=${value}`;
            }
        }
        if (customerPhone !== "") {
            endPointWithParams += `&customer_phone=${customerPhone}`;
        }
    }
    return endPointWithParams;
}