import React from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import ConfigurationModule from "../../../commonFeatureModules/views/configurationModule/configurationModule";
import {Button} from "react-bootstrap";

const Configurations = props => {

    const backFunction=()=>{
        // props.history.push('/grids/list');
        props.history.goBack();
    }
    const BackButton = <Button size={'sm'} variant={'secondary'} className={'btn-outline-warning'} onClick={backFunction}>
        <i className="fa fa-chevron-left"/> &nbsp;Go Back</Button>
    return (
        <div>
            <ContentWrapper showCardHeader={false} pageTitle={BackButton} showBackButton={false} isLoading={false}>
                <ConfigurationModule solboxGuid={props.match.params.solbox_guid}  solboxSerialNumber={props.match.params.solbox_serial_number}/>
            </ContentWrapper>
        </div>
    );
};

Configurations.propTypes = {

};

export default Configurations;