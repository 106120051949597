import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CustomerList from "./views/CustomerList";
import CustomerProfile from "./views/customerProfile/CustomerProfile";
import SecuredRoute from "../../router/SecuredRoute";

const CustomerRouter = ({}) => {

    return (
        <Switch>
            {/* Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/customers"}
                to={"/customers/list"}
            />
            {/* Route to Customer List */}
            <SecuredRoute
                path={"/customers/list"}
                component={CustomerList}
            />
            {/* Route to Customer Profile*/}
            <SecuredRoute
                path={"/customers/profile/:customer_guid/:solbox_guid/:solbox_serial_number/:customer_type"}
                component={CustomerProfile}
            />
        </Switch>
    )
};

export default CustomerRouter;