import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {commonActions} from "../../commonActions";
import {Tab, Tabs} from "react-bootstrap";
import {TabContainer} from "../../../../components/utilityComponents/utilityStyledComponents";
import ConfigData from "../configData/configData";
import ConfigurationHistory from "../configurationHistory/configurationHistory";


const ConfigurationModule = props => {
    return (
        <div>
            <div className={'col-md-12 no-padding'}>
                <Tabs
                    id="controlled-tab-example"
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    <Tab eventKey="current_configuration" title="Current Configuration" tabClassName={'sol-tab'}>
                        <TabContainer>
                            <ConfigData solboxGuid={props.solboxGuid} solboxSerialNumber={props.solboxSerialNumber?.toString().slice(4)} />
                        </TabContainer>
                    </Tab>
                    <Tab eventKey="history" title="Configuration History" tabClassName={'sol-tab'}>
                        <TabContainer>
                            <ConfigurationHistory solboxGuid={props.solboxGuid} solboxSerialNumber={props.solboxSerialNumber?.toString().slice(4)}/>
                        </TabContainer>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

ConfigurationModule.propTypes = {

    solboxGuid: PropTypes.string.isRequired,
    solboxSerialNumber: PropTypes.string.isRequired,
};

const mapStateToProps=({commonReducer})=>(commonReducer);

export default connect(mapStateToProps, commonActions)(ConfigurationModule);