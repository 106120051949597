export const commonActionTypes= {
    PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_REQUEST: 'PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_REQUEST',
    PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_SUCCESS: 'PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_SUCCESS',
    PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_FAILURE: 'PUBLISH_TOPIC_FOR_SINGLE_SOLBOX_FAILURE',


    GET_LIVE_DATA_FOR_SINGLE_SOLBOX_REQUEST: 'GET_LIVE_DATA_FOR_SINGLE_SOLBOX_REQUEST',
    GET_LIVE_DATA_FOR_SINGLE_SOLBOX_SUCCESS: 'GET_LIVE_DATA_FOR_SINGLE_SOLBOX_SUCCESS',
    GET_LIVE_DATA_FOR_SINGLE_SOLBOX_FAILURE: 'GET_LIVE_DATA_FOR_SINGLE_SOLBOX_FAILURE',


    FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_REQUEST: 'FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_REQUEST',
    FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_SUCCESS: 'FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_SUCCESS',
    FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_FAILURE: 'FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX_FAILURE',


    UPDATE_SOLBOX_CONFIGURATION_REQUEST: 'UPDATE_SOLBOX_CONFIGURATION_REQUEST',
    UPDATE_SOLBOX_CONFIGURATION_SUCCESS: 'UPDATE_SOLBOX_CONFIGURATION_SUCCESS',
    UPDATE_SOLBOX_CONFIGURATION_FAILURE: 'UPDATE_SOLBOX_CONFIGURATION_FAILURE',


    FETCH_SOLBOX_CONFIG_HISTORY_REQUEST: 'FETCH_SOLBOX_CONFIG_HISTORY_REQUEST',
    FETCH_SOLBOX_CONFIG_HISTORY_SUCCESS: 'FETCH_SOLBOX_CONFIG_HISTORY_SUCCESS',
    FETCH_SOLBOX_CONFIG_HISTORY_FAILURE: 'FETCH_SOLBOX_CONFIG_HISTORY_FAILURE',

    GET_SOLBOX_PROFILE_DATA_REQUEST: 'GET_SOLBOX_PROFILE_DATA_REQUEST',
    GET_SOLBOX_PROFILE_DATA_SUCCESS: 'GET_SOLBOX_PROFILE_DATA_SUCCESS',
    GET_SOLBOX_PROFILE_DATA_FAILURE: 'GET_SOLBOX_PROFILE_DATA_FAILURE',


    GET_LEGACY_LIVE_DATA_COMMON_REQUEST: 'GET_LEGACY_LIVE_DATA_COMMON_REQUEST',
    GET_LEGACY_LIVE_DATA_COMMON_SUCCESS: 'GET_LEGACY_LIVE_DATA_COMMON_SUCCESS',
    GET_LEGACY_LIVE_DATA_COMMON_FAILURE: 'GET_LEGACY_LIVE_DATA_COMMON_FAILURE',


    SET_FLAGS_TO_UNDEFINED: 'SET_FLAGS_TO_UNDEFINED',
}