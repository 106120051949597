import React, { useState, useEffect } from "react";
import { transactionActions }   from "../transactionActions";
import { connect } from 'react-redux';
import SolSelect from "../../../components/SolSelect";
import SolTextInput from "../../../components/soltextinput/SolTextInput";
import SolRadioInput from "../../../components/solradioinput/SolRadioInput";
import SolDaterangePicker from "../../../components/SolDaterangePicker";
import moment from "moment";
import { toast } from "react-toastify";
import {Spinner} from "react-bootstrap";

const AssignedTransactionFilter = (props) => {

    const {
        history,
        isSolshare,
        b2bOrgGuid,
        customerType,
        handleCustomerType,
        grid,
        handleGridSelect,
        customerPhone,
        sentFrom,
        transactionId,
        handleChangeSolTextInput,
        transactionType,
        handleTransactionTypeSelect,
        promotionTopupRef,
        handlePromotionTopupRefSelect,
        startDate,
        endDate,
        handleDateRange,
        dateRange,
        handleSelectedDateRange,
        transactionStatus,
        handleChangeTransactionStatus,
        resetFilterForm,
        isGridListLoading,
        isGridBenefitsLoading,
        isTransactionTypesLoading,
        gridList,
        transactionTypes,
        gridBenefits,
        statusCode,
        filterApplied,
        toggleFilterApplied,
        assignTransactionHistoryFilterParams,
        handleFilterParamsExceptPagination,
        isRetryingAllFailedTransaction,
        FETCH_GRID_LIST,
        FETCH_TRANSACTION_TYPES,
        FETCH_GRID_BENEFITS_LIST,
        GET_TRANSACTION_HISTORY_LIST,
        RETRY_ALL_FAILED_TRANSACTIONS
    } = props;

    const customerTypeOpt = [
        {value: 'Microutility', label: 'Microutility'},
        {value: 'Producer', label: 'Producer'},
        {value: 'Prosumer', label: 'Prosumer'},
        {value: 'Consumer', label: 'Consumer'}
    ];

    useEffect(() => {
        if (isSolshare) {
            FETCH_GRID_LIST("");
            FETCH_TRANSACTION_TYPES();
            FETCH_GRID_BENEFITS_LIST();
        } else {
            FETCH_GRID_LIST("");
            FETCH_TRANSACTION_TYPES();
            FETCH_GRID_BENEFITS_LIST();
        }
    }, [isSolshare]);

    const [gridListOpt, setGridListOpt] = useState([]);

    useEffect(() => {
        if (gridList &&
            gridList.length !== 0
        ) {
            let gridListArr = [];
            gridList.forEach((element, index) => {
                gridListArr.push({
                    value: element?.grid_guid, label: element?.name
                });
            });
            setGridListOpt(gridListArr);
        }
    }, [gridList]);

    const [transactionTypesOpt, setTransactionTypesOpt] = useState([]);

    useEffect(() => {
        if (transactionTypes &&
            transactionTypes.length !== 0
        ) {
            let transactionTypesArr = [];
            transactionTypes.forEach((element, index) => {
                transactionTypesArr.push({
                    value: element?.guid, label: element?.name
                });
            });
            setTransactionTypesOpt(transactionTypesArr);
        }
    }, [transactionTypes]);

    const [promotionTopupOpt, setPromotionTopupOpt] = useState([]);

    useEffect(() => {
        if (gridBenefits && gridBenefits.length !== 0) {
            let gridBenefitsArr = [];
            gridBenefits.forEach((element, index) => {
                gridBenefitsArr.push({value: element?.guid, label: element?.name});
            });
            setPromotionTopupOpt(gridBenefitsArr);
        }
    }, [gridBenefits]);

    useEffect(() => {
        if (startDate && endDate) {
            handleSelectedDateRange(
                            moment(startDate._d).format('LL')
                            .concat(" - ", moment(endDate._d).format('LL'))
                        );
        }
    }, [startDate, endDate]);

    const applyFilter = (event) => {
        const regex = /^[\+a-z0-9_\- ]+$/gi;
        let formData = {
            "pagination": "True",
            "is_in_last_two_days_transactions": "False" 
        };
        let paramsData = {
            "pagination": "False",
            "is_in_last_two_days_transactions": "False" 
        };
        if (grid) {
            Object.assign(formData, {"grid_guid": grid.value});
            Object.assign(paramsData, {"grid_guid": grid.value});
        }
        if (customerType) {
            Object.assign(formData, {"node_type": customerType.value});
            Object.assign(paramsData, {"node_type": customerType.value});
        }
        if (customerPhone && customerPhone.trim() && regex.test(customerPhone.trim())) {
            Object.assign(formData, {"customer_phone": customerPhone.trim()});
            Object.assign(paramsData, {"customer_phone": customerPhone.trim()});
        }
        if (transactionType) {
            Object.assign(formData, {"txn_type": transactionType.value});
            Object.assign(paramsData, {"txn_type": transactionType.value});
        }
        if (sentFrom && sentFrom.trim() && regex.test(sentFrom.trim())) {
            Object.assign(formData, {"sent_from": sentFrom.trim()});
            Object.assign(paramsData, {"sent_from": sentFrom.trim()});
        }
        if (promotionTopupRef) {
            Object.assign(formData, {"promotion_ref": promotionTopupRef.value});
            Object.assign(paramsData, {"promotion_ref": promotionTopupRef.value});
        }
        if (transactionId && transactionId.trim() && regex.test(transactionId.trim())) {
            Object.assign(formData, {"transaction_guid": transactionId.trim()});
            Object.assign(paramsData, {"transaction_guid": transactionId.trim()});
        }
        if (transactionStatus) {
            Object.assign(formData, {"status": transactionStatus});
            Object.assign(paramsData, {"status": transactionStatus});
        }
        if (startDate && 
            startDate._d &&
            endDate &&
            endDate._d
        ) {
            Object.assign(formData, {"start_date": moment(startDate._d).format("YYYY-MM-DD")});
            Object.assign(paramsData, {"start_date": moment(startDate._d).format("YYYY-MM-DD")});
            Object.assign(formData, {"end_date": moment(endDate._d).format("YYYY-MM-DD")});
            Object.assign(paramsData, {"end_date": moment(endDate._d).format("YYYY-MM-DD")});
        } else {
            if (Object.entries(formData).length > 2) {
                handleSelectedDateRange("");
            }
        }
        if ((Object.entries(formData).length > 2)) {
            assignTransactionHistoryFilterParams(formData);
            handleFilterParamsExceptPagination(paramsData);
            toggleFilterApplied(event, true);
            GET_TRANSACTION_HISTORY_LIST("", formData);
            history.push({
                pathname: '/transactions/list',
                search: filterParamsHandler(formData),
                hash: "transaction_history"
            });
        } else {
            toast.error("Please provide at least one filter criterion!");
        }
    }

    const retryAllFailedTransactions = () => {
        RETRY_ALL_FAILED_TRANSACTIONS()
    }

    const filterParamsHandler = (filterParams) => {
        let queryStringParams = "";
        if (!(Object.entries(filterParams).length < 1)) {
            for (const [key, value] of Object.entries(filterParams)) {
                if (!queryStringParams.includes("?")) {
                    queryStringParams += `?${key}=${value}`;
                } else {
                    queryStringParams += `&${key}=${value}`;
                }
            }
        }
        return queryStringParams;
    }

    return (
        <React.Fragment>
            <h5 className={`list-filter-control`}>{`FILTER`}</h5>
            <div className={`row list-filter-control`}>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Grid`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Grid`}
                        value={grid}
                        handleChangeSolSelect={handleGridSelect}
                        options={gridListOpt}
                        selectMulti={false}
                        loading={isGridListLoading}
                        clearable={true}
                        noOptionsMessage={() => `No grid available`}
                    />
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Customer Type`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Type`}
                        value={customerType}
                        handleChangeSolSelect={handleCustomerType}
                        options={customerTypeOpt}
                        selectMulti={false}
                        loading={false}
                        clearable={true}
                        noOptionsMessage={() => `No type available`}
                    />
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Customer Phone Number`}
                    </label>
                    <div className="form-group d-block">
                        <SolTextInput 
                            inputClassName={`custom-text-input-style`}
                            name={`customerPhoneNumber`}
                            placeHolder="Type Phone Number..."
                            value={customerPhone}
                            handleChange={(event) => handleChangeSolTextInput(event)}
                        />
                    </div>
                </div>
            </div>
            <div className={`row list-filter-control`}>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Transaction Type`} 
                    </label>
                    <SolSelect
                        placeHolder={`Select Type`}
                        value={transactionType}
                        handleChangeSolSelect={handleTransactionTypeSelect}
                        options={transactionTypesOpt}
                        selectMulti={false}
                        loading={false}
                        clearable={true}
                        noOptionsMessage={() => `No type available`}
                    />
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Sent From`}
                    </label>
                    <div className="form-group d-block">
                        <SolTextInput 
                            inputClassName={`custom-text-input-style`}
                            name={`sentPhoneNumber`}
                            placeHolder="Type Phone Number..."
                            value={sentFrom}
                            handleChange={(event) => handleChangeSolTextInput(event)}
                        />
                    </div>
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Benefit`}
                    </label>
                    <SolSelect
                        placeHolder={`Type Reference`}
                        value={promotionTopupRef}
                        handleChangeSolSelect={handlePromotionTopupRefSelect}
                        options={promotionTopupOpt}
                        selectMulti={false}
                        loading={false}
                        clearable={true}
                        noOptionsMessage={() => `No reference available`}
                    />
                </div>
            </div>
            <div className={`row list-filter-control`}>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Transaction ID`}
                    </label>
                    <div className="form-group d-block">
                        <SolTextInput 
                            inputClassName={`custom-text-input-style`}
                            name={`transactionId`}
                            placeHolder="Type Transaction ID..."
                            value={transactionId}
                            handleChange={(event) => handleChangeSolTextInput(event)}
                        />
                    </div>
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <SolDaterangePicker
                        dateRangelabel={`Transaction Date`}
                        customLabel={`normal-text`}
                        startDate={startDate}
                        endDate={endDate}
                        dateRange={dateRange}
                        handleDateRange={handleDateRange}
                    />
                </div>
                <div className={`col-sm-6`} style={{"marginTop": "2rem"}}>
                    <label className={`form-check-label normal-text d-block`}>
                        {`Transaction Status`}
                    </label>
                    <div className="form-check form-check-inline mt-2">
                        <SolRadioInput
                            name="statusOfTransaction"
                            id="received"
                            value={`received`}
                            handleChange={(event) => handleChangeTransactionStatus(event)}
                            checked={(transactionStatus === "received") ? true : false}
                            htmlFor="received"
                            labelName="Successful"
                        />
                    </div>
                    <div className="form-check form-check-inline mt-2 customer-status-inactive">
                        <SolRadioInput
                            name="statusOfTransaction"
                            id="failed"
                            value={`failed`}
                            handleChange={(event) => handleChangeTransactionStatus(event)}
                            checked={(transactionStatus === "failed") ? true : false}
                            htmlFor="failed"
                            labelName="Failed"
                        />
                    </div>
                    <div className="form-check form-check-inline mt-2 customer-status-inactive">
                        <SolRadioInput
                            name="statusOfTransaction"
                            id="processing"
                            value={`processing`}
                            handleChange={(event) => handleChangeTransactionStatus(event)}
                            checked={(transactionStatus === "processing") ? true : false}
                            htmlFor="processing"
                            labelName="In Progress"
                        />
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
            <div className={`row mb-5`}>
                <div className={`col-12`}>
                    <button
                        type={`button`}
                        className={'btn btn-outline-warning btn-sm mb-5'}
                        onClick={(event) => applyFilter(event)}
                    >
                        {`Apply Filter`}
                    </button>
                    <button
                        type={`button`}
                        className={'btn btn-outline-secondary btn-sm ml-4 mb-5 list-filter-reset-button'}
                        onClick={(event) => resetFilterForm(event)}
                    >
                        {`Reset Filter`}
                    </button>
                    <button
                        type={`button`}
                        disabled={isRetryingAllFailedTransaction}
                        className={'btn btn-warning btn-sm float-right'}
                        onClick={() => retryAllFailedTransactions()}
                    >
                        {`Retry all failed transactions`}
                        {isRetryingAllFailedTransaction?<><Spinner animation="border" size={'sm'} variant="light"/></>:''}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.transactionReducer.isLoading,
        tableLoading: state.transactionReducer.tableLoading,
        isGridListLoading: state.transactionReducer.isGridListLoading,
        isTransactionTypesLoading: state.transactionReducer.isTransactionTypesLoading,
        isGridBenefitsLoading: state.transactionReducer.isGridBenefitsLoading,
        statusCode: state.transactionReducer.statusCode,
        gridList: state.transactionReducer.gridList,
        transactionTypes: state.transactionReducer.transactionTypes,
        gridBenefits: state.transactionReducer.gridBenefits,
        isRetryingAllFailedTransaction: state.transactionReducer.isRetryingAllFailedTransaction
    }
};

export default connect(mapStateToProps, transactionActions)(AssignedTransactionFilter);
