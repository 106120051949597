import React, { useState, useEffect, useRef } from "react";
import { transactionActions } from "../transactionActions";
import { connect } from "react-redux";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../components/dataTable/DataTable";
import { GenericModal } from "../../../components/genericModal/genericModal";
import TransactionToolbar from "./TransactionToolbar";
import AssignedTransactionFilter from "./AssignedTransactionFilter";
import moment from "moment";
import { Link } from "react-router-dom";
import {Button, Modal, Spinner} from "react-bootstrap";

const CustomerAssigned = (props) => {
  const {
    location,
    history,
    isSolshare,
    b2bOrgGuid,
    isTransactionHistoryLoading,
    transactionHistoryTableLoading,
    pageCount,
    pageSize,
    transactionHistory,
    createTransaction,
    isRetryingAllFailedTransaction,
    isCancellingTransaction,
    allFailedTransactionIsSuccessfullyRetried,
    transactionIsCancelled,
    statusCode,
    GET_TRANSACTION_HISTORY_LIST,
    CREATE_TRANSACTION,
    REINITIATE_CREATE_TRANSACTION,
    CANCEL_TRANSACTION
  } = props;

  const [toggleFilter, setToggleFilter] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [toggleModal, setToggleModal] = useState([]);
  const [showLog, setShowLog] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [transactionShowLogId, setTransactionShowLogId] = useState(-1);
  const [solboxId, setSolboxId] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [customerType, setCustomerType] = useState("");
  const [grid, setGrid] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [sentFrom, setSentFrom] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [promotionTopupRef, setPromotionTopupRef] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [transactionInitializationDate, setTransactionInitializationDate] = useState(false);
  const [solbox, setSolbox] = useState('');
  const [amount, setTransactionAmount] = useState('');
  const [transactionTypeToShow, setTransactionTypeToShow] = useState('');
  const [transactionGuidToShow, setTransactionGuidToShow] = useState('');

  const handleSelectedDateRange = (range) => {
    setDateRange(range);
  }

  const handleChangeTransactionStatus = (event) => {
    if (event.target.id === "received") {
      setTransactionStatus(event.target.value);
    } else if (event.target.id === "failed") {
      setTransactionStatus(event.target.value);
    } else {
      setTransactionStatus(event.target.value);
    }
  }

  const handleDateRange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const handlePromotionTopupRefSelect = (promotionTopupRef) => {
    setPromotionTopupRef(promotionTopupRef);
  }

  const handleTransactionTypeSelect = (transactionType) => {
    setTransactionType(transactionType);
  }

  const handleChangeSolTextInput = (event) => {
    if (event.target.name === "customerPhoneNumber") {
      setCustomerPhone(event.target.value);
    }
    if (event.target.name === "sentPhoneNumber") {
      setSentFrom(event.target.value);
    }
    if (event.target.name === "transactionId") {
      setTransactionId(event.target.value);
    }
  }

  const handleGridSelect = (grid) => {
    setGrid(grid);
  }

  const handleCustomerType = (customerType) => {
    setCustomerType(customerType);
  }

  const handleChangeSolboxId = (event) => {
    if (event.target.name === "idOfSolbox") {
        setSolboxId(event.target.value);
        if (searchFlag === true && event.target.value === "") {
          GET_TRANSACTION_HISTORY_LIST("", {
            "pagination": "True",
            "is_in_last_two_days_transactions": "True",
            "page": pageNumber
          });
          setDateRange(
            moment().subtract(1, 'days').format('LL')
            .concat(" - ", moment().format('LL'))
          );
          setSearchFlag(!searchFlag);
          setFilterApplied(false);
        }
    }
  }

  const handleChangeSearchFlag = (event) => {
    setSearchFlag(!searchFlag);
  }

  const handleToggleFilter = (event) => {
    setToggleFilter(!toggleFilter);
  };

  const toggleFilterApplied = (event, value) => {
    setFilterApplied(value);
  };

  const handleToggleModal = (event, data) => {
    if (
      data !== undefined &&
      data !== null &&
      data.show_log !== undefined &&
      data.show_log !== null &&
      data.show_log.length !== 0 &&
      data.id !== undefined &&
      data.id !== null &&
      data !== -1
    ) {
      let modalId = [];
      modalId.push(data.id);
      setSelectedRowData(data);
      setShowLog(data.show_log);
      setTransactionShowLogId(data.id);
      setToggleModal(modalId);
    } else {
      setTransactionShowLogId(data);
      setToggleModal([]);
    }
  };

  const manualRetry = (event, transGuid) => {
    let data = { type: "retry" };

    if (transGuid !== undefined && transGuid !== null && transGuid !== `N/A`) {
      Object.assign(data, { txn_guid: transGuid });
    }

    if (
      Object.entries(data).length === 2 &&
      data.type === "retry" &&
      data.txn_guid !== undefined &&
      data.txn_guid !== null &&
      data.txn_guid !== "" &&
      data.txn_guid !== `N/A`
    ) {
      CREATE_TRANSACTION(data);
    }
  };

  const cancelTransaction = (transactionData) => {
    setSolbox(transactionData.solbox)
    setTransactionInitializationDate(transactionData.created_at)
    setTransactionAmount(transactionData.amount)
    setTransactionTypeToShow(transactionData.type)
    setTransactionGuidToShow(transactionData.id)
    setShowConfirmationModal(true)
  }

  const transactionCancelConfimation = () => {
    CANCEL_TRANSACTION({"txn_guid": transactionGuidToShow})
  }

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false)
  }

  useEffect(() => {
    if (allFailedTransactionIsSuccessfullyRetried === true || transactionIsCancelled === true) {
      setShowConfirmationModal(false)
      setTimeout(function() {
        window.location.reload();
      }, 3000);
    }
  }, [allFailedTransactionIsSuccessfullyRetried, transactionIsCancelled]);

  useEffect(() => {
    if (
      createTransaction !== undefined &&
      createTransaction !== null &&
      createTransaction !== "" &&
      createTransaction === "Success"
    ) {
      //message.SuccessMessage("Transaction has been created.");
      REINITIATE_CREATE_TRANSACTION();
    }
  }, [createTransaction]);

  // useEffect(() => {
  //   if (statusCode !== undefined && statusCode !== null && statusCode == 400) {
  //     message.ErrorMessage("Transaction creation failed!");
  //   }
  // }, [statusCode]);

  const handleCustomerNameClick = (event, data) => {
    props.history.push("/customers/profile", {
      solbox_guid: data.solbox_guid,
      customer_guid: data.customer_guid,
      solbox_serial_number: data.solbox,
    });
  };

  const handleGridNameClick = (event, data) => {
    props.history.push(`/grids/details/${data.grid_guid}`, {
      gridName: data.grid_name,
    });
  };

  const handleSolboxClick = (event, data) => {
    props.history.push(`/solboxes/profile/${data.solbox}/${data.solbox_guid}`);
  };

  const customerAssignedColumns = [
    {
      field: "id",
      title: "ID",
      width: "40px",
      headerStyle: { color: "#8C8C9B" },
      render: (rowData) => (
        <React.Fragment>
          {(rowData.id !== undefined && rowData.id !== null) &&
            <span className="clipboard-style" onClick={() => navigator.clipboard.writeText(rowData.id)}>
            {rowData.id !== `N/A`
              ? rowData.id.substring(0, rowData.id.indexOf("-")).concat("...")
              : `N/A`}
            </span>
          }
        </React.Fragment>
      ),
    },
    {
      field: "created_at",
      title: "Initialization date",
      width: "290px",
      headerStyle: { color: "#8C8C9B" },
      render: (rowData) => (
          <React.Fragment>
            <span className="d-block">{rowData.created_at}</span>
          </React.Fragment>
      ),
    },
    {
      field: "date",
      title: "Date",
      width: "290px",
      headerStyle: { color: "#8C8C9B" },
      render: (rowData) => (
        <React.Fragment>
          <span className="d-block">{rowData.date}</span>
        </React.Fragment>
      ),
    },
    {
      field: "customer",
      title: "Customer",
      headerStyle: { color: "#8C8C9B" },
      render: (rowData) => (
        <React.Fragment>
          {rowData !== undefined &&
          rowData !== null &&
          rowData.customer !== undefined &&
          rowData.customer !== null &&
          rowData.customer !== `N/A` ? (
            <React.Fragment>
              <span
                className="d-block"
                //onClick={(event, data) => handleCustomerNameClick(event, rowData)}
              >
                <Link
                  className="sol-text inter-module-link"
                  target="_blank"
                  to={{
                    pathname: `/customers/profile/${rowData.customer_guid}/${rowData.solbox_guid}/${rowData.solbox}/${rowData.node_type}`,
                    state: {
                      solbox_guid: rowData.solbox_guid,
                      customer_guid: rowData.customer_guid,
                      solbox_serial_number: rowData.solbox,
                      customer_type: rowData.node_type
                    }
                  }}
                >
                  {rowData.customer}
                </Link>
              </span>
            </React.Fragment>
          ) : (
            `N/A`
          )}
          {rowData.grid_name !== undefined &&
          rowData.grid_name !== null &&
          rowData.grid_name !== `N/A` ? (
            <React.Fragment>
              <span
                className="d-block"
                //onClick={(event, data) => handleGridNameClick(event, rowData)}
              >
                <Link
                  className="sol-text inter-module-link"
                  target="_blank"
                  to={{
                    pathname: `/grids/details/${rowData.grid_guid}`,
                    state: {
                      gridName: rowData.grid_name
                    }
                  }}
                >
                  {rowData.grid_name}
                </Link>
              </span>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ),
    },
    {
      field: "solbox",
      title: "SOLbox",
      width: "50px",
      headerStyle: { color: "#8C8C9B" },
      render: (rowData) => (
        <React.Fragment>
          {rowData !== undefined &&
          rowData !== null &&
          rowData.solbox !== undefined &&
          rowData.solbox !== null ? (
            <span
              className="d-block"
              //onClick={(event, data) => handleSolboxClick(event, rowData)}
            >
              <Link
                className="sol-text inter-module-link"
                target="_blank"
                to={{
                  pathname: `/solboxes/profile/${rowData.solbox}/${rowData.solbox_guid}`,
                }}
              >
                {rowData.solbox}
              </Link>
            </span>
          ) : (
            `N/A`
          )}
        </React.Fragment>
      ),
    },
    {
      field: "amount",
      title: "Amount (Tk)",
      width: "160px",
      headerStyle: { color: "#8C8C9B" },
      render: (rowData) => (
        <React.Fragment>
          {rowData !== undefined &&
          rowData !== null &&
          rowData.amount !== undefined &&
          rowData.amount !== null ? (
            <React.Fragment>
              <span className="d-block">{rowData.amount}</span>
            </React.Fragment>
          ) : (
            `N/A`
          )}
          {rowData !== undefined &&
          rowData !== null &&
          rowData.sent_from !== undefined &&
          rowData.sent_from !== null &&
          rowData.sent_from !== `N/A` ? (
            <React.Fragment>
              <span className="d-block normal-text">
                {`From ${rowData.sent_from}`}
              </span>
            </React.Fragment>
          ) : null}
          {rowData !== undefined &&
          rowData !== null &&
          rowData.initiated_by !== undefined &&
          rowData.initiated_by !== null &&
          rowData.initiated_by !== `N/A` ? (
            <React.Fragment>
              <span className="d-block normal-text">
                {`By ${rowData.initiated_by}`}
              </span>
            </React.Fragment>
          ) : null}
          {rowData !== undefined &&
          rowData !== null &&
          rowData.remarks !== undefined &&
          rowData.remarks !== null &&
          rowData.remarks !== `N/A` ? (
            <React.Fragment>
              <span className="d-block normal-text">{rowData.remarks}</span>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ),
    },
    {
      field: "type",
      title: "Type",
      width: "250px",
      headerStyle: { color: "#8C8C9B" },
      render: (rowData) => (
        <React.Fragment>
          {rowData !== undefined &&
          rowData !== null &&
          rowData.type !== undefined &&
          rowData.type !== null ? (
            <React.Fragment>
              <span className="d-block list-bold-text">{rowData.type}</span>
            </React.Fragment>
          ) : (
            `N/A`
          )}
          {rowData !== undefined &&
          rowData !== null &&
          rowData.bkash_ref !== undefined &&
          rowData.bkash_ref !== null &&
          rowData.bkash_ref !== `N/A` ? (
            <React.Fragment>
              <span className="d-block normal-text">
                {`REF: ${rowData.bkash_ref}`}
              </span>
            </React.Fragment>
          ) : null}
          {rowData !== undefined &&
          rowData !== null &&
          rowData.drop_datetime !== undefined &&
          rowData.drop_datetime !== null &&
          rowData.drop_datetime !== `N/A` ? (
            <React.Fragment>
              <span className="d-block normal-text">
                {`Dropped on ${moment(rowData.drop_datetime).format(
                  "DD/MM/YYYY, h:mm:ss a"
                )}`}
              </span>
            </React.Fragment>
          ) : null}
          {rowData !== undefined &&
          rowData !== null &&
          rowData.promotion_name !== undefined &&
          rowData.promotion_name !== null &&
          rowData.promotion_name !== `N/A` ? (
            <React.Fragment>
              <span className="d-block normal-text">
                {`Promotion Reference #${rowData.promotion_name}`}
              </span>
            </React.Fragment>
          ) : null}
          {rowData !== undefined &&
          rowData !== null &&
          rowData.old_solbox_serial_number !== undefined &&
          rowData.old_solbox_serial_number !== null &&
          rowData.old_solbox_serial_number !== `N/A` ? (
            <React.Fragment>
              <span className="d-block normal-text">
                {`OLD SOLbox: ${rowData.old_solbox_serial_number}`}
              </span>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ),
    },
    {
      field: "status",
      title: "Status",
      headerStyle: { color: "#8C8C9B" },
      //cellStyle: { color: "rgba(203, 21, 43, 1)" },
      render: (rowData) => (
        <React.Fragment>
          {rowData.status.toLowerCase() === "failed" ? (
            <span className="transaction-status-failed">{`Failed`}</span>
          ) : rowData.status.toLowerCase() === "processing" ? (
            <React.Fragment>
              <span className="d-block mb-2">{`In Progress`}</span>
              <span className="normal-text d-block">
                {`Attempt #${rowData.retry_counter}`}
              </span>
            </React.Fragment>
          ) : (
            <span className="success-text">
              {rowData.status.toLowerCase() === "received"
                ? `Successful`
                : `N/A`}
            </span>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "",
      title: "",
      width: "200px",
      headerStyle: { color: "#8C8C9B" },
      cellStyle: { padding: "0" },
      render: (rowData) => (
        <div className="d-flex justify-content-around align-items-center">
          {(rowData.status === "processing" || rowData.status === "failed") && isSolshare ?
              <>
                <button
                    type={`button`}
                    disabled={isCancellingTransaction || isRetryingAllFailedTransaction}
                    className={"btn btn-outline-danger btn-sm"}
                    onClick={() => cancelTransaction(rowData)}
                >
                  {`Cancel`}
                </button>
              </> : null}
          {rowData.status === "failed" && rowData.retry_allow === true && isSolshare ? (
            <button
              type={`button`}
              disabled={isRetryingAllFailedTransaction || isCancellingTransaction}
              className={"btn btn-outline-warning btn-sm"}
              onClick={(event, transGuid) => manualRetry(event, rowData.id)}
            >
              {`Retry`}
            </button>
          ) : null}
          {rowData.show_log !== `N/A` ? (
            <button
              type={`button`}
              className={
                rowData.status === "failed" && rowData.retry_allow === true
                  ? "btn btn-outline-warning btn-sm ml-2"
                  : "btn btn-outline-warning btn-sm"
              }
              onClick={(event) => handleToggleModal(event, rowData)}
            >
              {`Show log`}
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   if (transactionHistory !== undefined && 
  //     transactionHistory === null &&
  //     location &&
  //     !location.search
  //   ) {
  //     GET_TRANSACTION_HISTORY_LIST("", {
  //       "pagination": "True",
  //       "is_in_last_two_days_transactions": "True"
  //     });
  //     setDateRange(
  //       moment().subtract(1, 'days').format('LL')
  //       .concat(" - ", moment().format('LL'))
  //     );
  //   }
  // }, [transactionHistory]);

  const [transactionHistoryList, setTransactionHistoryList] = useState([]);
  const [transactionHistoryCSV, setTransactionHistoryCSV] = useState([]);

  useEffect(() => {
    if (
      transactionHistory !== undefined &&
      transactionHistory !== null &&
      transactionHistory.length !== 0
    ) {
      let transactionHistoryArr = [];
      let transactionHistoryCSVArr = [];
      transactionHistory.forEach((element, index) => {
        transactionHistoryArr.push({
          id:
            element.transaction_guid !== undefined &&
            element.transaction_guid !== null
              ? element.transaction_guid
              : `N/A`,
          created_at:
              element.created_at !== undefined &&
              element.created_at !== null
                  ? moment(element.created_at).format("MMMM DD YYYY, h:mm:ss a")
                  : `N/A`,
          date:
            element.updated_at !== undefined && element.updated_at !== null
              ? moment(element.updated_at).format("MMMM DD YYYY, h:mm:ss a")
              : `N/A`,
          customer:
            element.customer_name !== undefined &&
            element.customer_name !== null
              ? element.customer_name
              : `N/A`,
          grid_name:
            element.grid_name !== undefined && element.grid_name !== null
              ? element.grid_name
              : `N/A`,
          solbox:
            element.solbox_serial_number !== undefined &&
            element.solbox_serial_number !== null
              ? element.solbox_serial_number
              : `N/A`,
          amount:
            element.amount !== undefined && element.amount !== null
              ? element.amount.toString(10).includes("-")
                ? element.amount.toString(10)
                : `+`.concat("", element.amount.toString(10))
              : `N/A`,
          sent_from:
            element.sent_from !== undefined && element.sent_from !== null
              ? element.sent_from
              : `N/A`,
          initiated_by:
            element.initiated_by !== undefined && element.initiated_by !== null
              ? element.initiated_by
              : `N/A`,
          remarks:
            element.remarks !== undefined && element.remarks !== null
              ? element.remarks
              : `N/A`,
          type:
            element.txn_type !== undefined && element.txn_type !== null
              ? element.txn_type
              : `N/A`,
          bkash_ref:
            element.bkash_ref !== undefined && element.bkash_ref !== null
              ? element.bkash_ref
              : `N/A`,
          drop_datetime:
            element.drop_datetime !== undefined &&
            element.drop_datetime !== null
              ? element.drop_datetime
              : `N/A`,
          old_solbox_serial_number:
            element.old_solbox_serial_number !== undefined &&
            element.old_solbox_serial_number !== null
              ? element.old_solbox_serial_number
              : `N/A`,
          promotion_name:
            element.promotion_name !== undefined &&
            element.promotion_name !== null
              ? element.promotion_name
              : `N/A`,
          status:
            element.status !== undefined && element.status !== null
              ? element.status
              : `N/A`,
          retry_counter:
            element.retry_counter !== undefined &&
            element.retry_counter !== null
              ? element.retry_counter
              : `N/A`,
          retry_allow:
            element.retry_allow !== undefined && element.retry_allow !== null
              ? element.retry_allow
              : `N/A`,
          show_log:
            element.show_log !== undefined &&
            element.show_log !== null &&
            element.show_log.length !== 0
              ? element.show_log
              : `N/A`,
          customer_guid:
            element.customer_guid !== undefined &&
            element.customer_guid !== null
              ? element.customer_guid
              : "",
          grid_guid:
            element.grid_guid !== undefined && element.grid_guid !== null
              ? element.grid_guid
              : "",
          solbox_guid:
            element.solbox_guid !== undefined && element.solbox_guid !== null
              ? element.solbox_guid
              : "",
          node_type:
            element.node_type !== undefined && element.node_type !== null
            ? element.node_type
            : ""
        });
        transactionHistoryCSVArr.push({
          id:
            element.transaction_guid !== undefined &&
            element.transaction_guid !== null
              ? element.transaction_guid
              : `N/A`,
          date:
            element.updated_at !== undefined && element.updated_at !== null
              ? moment(element.updated_at).format("MMMM DD YYYY, h:mm:ss a")
              : `N/A`,
          customer:
            element.customer_name !== undefined &&
            element.customer_name !== null
              ? element.customer_name
              : `N/A`,
          grid_name:
            element.grid_name !== undefined && element.grid_name !== null
              ? element.grid_name
              : `N/A`,
          solbox:
            element.solbox_serial_number !== undefined &&
            element.solbox_serial_number !== null
              ? element.solbox_serial_number
              : `N/A`,
          amount:
            element.amount !== undefined && element.amount !== null
              ? element.amount.toString(10).includes("-")
                ? element.amount.toString(10)
                : `+`.concat("", element.amount.toString(10))
              : `N/A`,
          sent_from:
            element.sent_from !== undefined && element.sent_from !== null
              ? element.sent_from
              : `N/A`,
          initiated_by:
            element.initiated_by !== undefined && element.initiated_by !== null
              ? element.initiated_by
              : `N/A`,
          remarks:
            element.remarks !== undefined && element.remarks !== null
              ? element.remarks
              : `N/A`,
          type:
            element.txn_type !== undefined && element.txn_type !== null
              ? element.txn_type
              : `N/A`,
          bkash_ref:
            element.bkash_ref !== undefined && element.bkash_ref !== null
              ? element.bkash_ref
              : `N/A`,
          drop_datetime:
            element.drop_datetime !== undefined &&
            element.drop_datetime !== null
              ? element.drop_datetime
              : `N/A`,
          old_solbox_serial_number:
            element.old_solbox_serial_number !== undefined &&
            element.old_solbox_serial_number !== null
              ? element.old_solbox_serial_number
              : `N/A`,
          promotion_name:
            element.promotion_name !== undefined &&
            element.promotion_name !== null
              ? element.promotion_name
              : `N/A`,
          status:
            element.status !== undefined && element.status !== null
              ? element.status
              : `N/A`,
          retry_counter:
            element.retry_counter !== undefined &&
            element.retry_counter !== null
              ? element.retry_counter
              : `N/A`,
        });
      });
      setTransactionHistoryList(transactionHistoryArr);
      setTransactionHistoryCSV(transactionHistoryCSVArr);
    } else {
      setTransactionHistoryList([]);
      setTransactionHistoryCSV([]);
    }
  }, [transactionHistory]);

  const [
    transactionHistoryFilterParams,
    setTransactionHistoryFilterParams,
  ] = useState(null);

  const [
    filterParamsExceptPagination,
    setFilterParamsExceptPagination,
  ] = useState(null);

  const assignTransactionHistoryFilterParams = (filterParams) => {
    setTransactionHistoryFilterParams(filterParams);
  };

  const handleFilterParamsExceptPagination = (params) => {
    setFilterParamsExceptPagination(params);
  }

  useEffect((event) => {
    if (!filterApplied &&
        location && 
        location.search && 
        location.hash &&
        location.hash === "#transaction_history"
      ) {
        let filterParams = {};
        let csvDataParams = {};
        var searchParams = new URLSearchParams(location.search);
        if (searchParams.has("grid_guid") ||
            searchParams.has("node_type") ||
            searchParams.has("customer_phone") ||
            searchParams.has("txn_type") ||
            searchParams.has("sent_from") ||
            searchParams.has("promotion_ref") ||
            searchParams.has("transaction_guid") ||
            searchParams.has("start_date") ||
            searchParams.has("end_date") ||
            searchParams.has("status")
        ) {
          searchParams.forEach((value, key) => {
            if (value) {
              Object.assign(filterParams, { [key]: value.trim() });
              Object.assign(csvDataParams, { [key]: value.trim() });
            }
          });
        }
        if (!(Object.entries(filterParams).length < 1)) {
            Object.assign(csvDataParams, { 
              "pagination": "False",
              "is_in_last_two_days_transactions": "False" 
            });
            handleFilterParamsExceptPagination(csvDataParams);
            Object.assign(filterParams, { 
              "pagination": "True",
              "is_in_last_two_days_transactions": "False" 
            });
            assignTransactionHistoryFilterParams(filterParams);
            toggleFilterApplied(event, true);
            GET_TRANSACTION_HISTORY_LIST("", filterParams);
        }
    } else {
      if (!filterApplied) {
        GET_TRANSACTION_HISTORY_LIST("", { 
          "pagination": "True",
          "is_in_last_two_days_transactions": "True",
          "page": pageNumber
         });
        setDateRange(
          moment().subtract(1, 'days').format('LL')
          .concat(" - ", moment().format('LL'))
        );
      }
    }
  }, [location, filterApplied]);

  const resetFilterForm = (event) => {
    if (filterApplied !== false) {
        if (grid !== "" ||
            grid !== null ||
            customerType !== "" ||
            customerType !== null ||
            customerPhone !== "" ||
            transactionType !== "" ||
            transactionType !== null ||
            sentFrom !== "" ||
            promotionTopupRef !== "" ||
            promotionTopupRef !== null ||
            transactionId !== "" ||
            startDate !== null ||
            endDate !== null ||
            transactionStatus !== ""
        ) {
            // GET_TRANSACTION_HISTORY_LIST("", { 
            //   "pagination": "True",
            //   "is_in_last_two_days_transactions": "True" 
            // });
            // setDateRange(
            //   moment().subtract(1, 'days').format('LL')
            //   .concat(" - ", moment().format('LL'))
            // );
            toggleFilterApplied(event, false);
            history.push({
                pathname: '/transactions/list',
                search: ""
            });
        }
    }
    setGrid("");
    setCustomerType("");
    setCustomerPhone("");
    setTransactionType("");
    setSentFrom("");
    setPromotionTopupRef("");
    setTransactionId("");
    setStartDate(null);
    setEndDate(null);
    setTransactionStatus("");
    if (!filterApplied) {
      setDateRange(
        moment().subtract(1, 'days').format('LL')
          .concat(" - ", moment().format('LL'))
      );
    }
    assignTransactionHistoryFilterParams(null);
  }

  const showLogColumns = [
    {
      field: "attempt_number",
      title: "Attempt #",
      headerStyle: { color: "#8C8C9B" },
      cellStyle: { padding: "1.5rem" },
      emptyValue: () => `N/A`,
    },
    {
      field: "created_at",
      title: "Date",
      headerStyle: { color: "#8C8C9B" },
      cellStyle: { padding: "1.5rem" },
      render: (rowData) => (
        <React.Fragment>
          {rowData.created_at !== undefined && rowData.created_at !== null ? (
            <React.Fragment>
              {moment(rowData.created_at).format("MMMM DD YYYY, h:mm:ss a")}
            </React.Fragment>
          ) : (
            `N/A`
          )}
        </React.Fragment>
      ),
    },
    {
      field: "attempt_by",
      title: "By",
      headerStyle: { color: "#8C8C9B" },
      cellStyle: { padding: "1.5rem" },
      emptyValue: () => `N/A`,
    },
    {
      field: "status",
      title: "Status",
      headerStyle: { color: "#8C8C9B" },
      cellStyle: { padding: "1.5rem" },
      render: (rowData) => (
        <React.Fragment>
          {rowData.status.toLowerCase() === "failed" ? (
            <span className="transaction-status-failed">{`Failed`}</span>
          ) : rowData.status.toLowerCase() === "processing" ? (
            <span>{`In Progress`}</span>
          ) : (
            <span className="text-success">
              {rowData.status.toLowerCase() === "received"
                ? `Successful`
                : `N/A`}
            </span>
          )}
        </React.Fragment>
      ),
    },
  ];

  const [
    parentTransactionDisplayData,
    setParentTransactionDisplayData,
  ] = useState(null);

  useEffect(() => {
    if (selectedRowData !== undefined && selectedRowData !== null) {
      let transactionHistoryObj = {};
      Object.assign(transactionHistoryObj, {
        id:
          selectedRowData.id !== undefined && selectedRowData.id !== null
            ? selectedRowData.id
            : `N/A`,
        type:
          selectedRowData.type !== undefined && selectedRowData.type !== null
            ? selectedRowData.type
            : `N/A`,
        date:
          selectedRowData.date !== undefined && selectedRowData.date !== null
            ? moment(selectedRowData.date).format("MMMM DD YYYY, h:mm:ss a")
            : `N/A`,
        amount:
          selectedRowData.amount !== undefined &&
          selectedRowData.amount !== null
            ? selectedRowData.amount
            : `N/A`,
        reference:
          selectedRowData.bkash_ref !== undefined &&
          selectedRowData.bkash_ref !== null
            ? selectedRowData.bkash_ref
            : `N/A`,
        initiated_by:
          selectedRowData.initiated_by !== undefined &&
          selectedRowData.initiated_by !== null
            ? selectedRowData.initiated_by
            : `N/A`,
        sent_from:
          selectedRowData.sent_from !== undefined &&
          selectedRowData.sent_from !== null
            ? selectedRowData.sent_from
            : `N/A`,
        customer:
          selectedRowData.customer !== undefined &&
          selectedRowData.customer !== null
            ? selectedRowData.customer
            : `N/A`,
        solbox:
          selectedRowData.solbox !== undefined &&
          selectedRowData.solbox !== null
            ? selectedRowData.solbox
            : `N/A`,
      });
      setParentTransactionDisplayData(transactionHistoryObj);
    }
  }, [selectedRowData]);

  const [pageNumber, setPageNumber] = useState(1);

  const handleChangePage = (event, pageNumber) => {
      setPageNumber(pageNumber);
      if (pageNumber &&
        !solboxId &&
        transactionHistoryFilterParams &&
        !(Object.entries(transactionHistoryFilterParams).length < 1)
      ) {
        let paginatedFilterParams = {};
        Object.assign(paginatedFilterParams, {"page": pageNumber});
        Object.assign(paginatedFilterParams, transactionHistoryFilterParams);
        GET_TRANSACTION_HISTORY_LIST("", paginatedFilterParams);
      }
      const regex = /^[0-9]+$/g;
      if (pageNumber && solboxId && solboxId.trim() && regex.test(solboxId.trim())) {
        if (transactionHistoryFilterParams &&
          !(Object.entries(transactionHistoryFilterParams).length < 1)
        ) {
          let paginatedSearch = {};
          Object.assign(paginatedSearch, {"page": pageNumber});
          Object.assign(paginatedSearch, {"solbox_serial_number": solboxId.trim()});
          Object.assign(paginatedSearch, transactionHistoryFilterParams);
          GET_TRANSACTION_HISTORY_LIST("", paginatedSearch);
        } else {
          let paginatedSearch = {};
          Object.assign(paginatedSearch, {
            "pagination": "True",
            "is_in_last_two_days_transactions": "False" 
          });
          Object.assign(paginatedSearch, {"page": pageNumber});
          Object.assign(paginatedSearch, {"solbox_serial_number": solboxId.trim()});
          GET_TRANSACTION_HISTORY_LIST("", paginatedSearch);
        }
      }
      if (pageNumber && !solboxId && !transactionHistoryFilterParams) {
        GET_TRANSACTION_HISTORY_LIST("", {
          "pagination": "True",
          "is_in_last_two_days_transactions": "True",
          "page": pageNumber
        });
      }
  }

  return (
    <React.Fragment>
      <ContentWrapper
        permissionDenied={false}
        pageTitle={"Configuration History"}
        showBackButton={false}
        isLoading={false}
        showCardHeader={false}
      >
        <TransactionToolbar
          tabKey={`customer_assigned`}
          csvData={transactionHistoryCSV}
          toggleFilterApplied={toggleFilterApplied}
          filterApplied={filterApplied}
          handleToggleFilter={handleToggleFilter}
          transactionHistoryFilterParams={transactionHistoryFilterParams}
          filterParamsExceptPagination={filterParamsExceptPagination}
          solboxId={solboxId}
          handleChangeSolboxId={handleChangeSolboxId}
          handleChangeSearchFlag={handleChangeSearchFlag}
        />
        {toggleFilter === true && (
          <AssignedTransactionFilter
            {...props}
            isSolshare={isSolshare}
            b2bOrgGuid={b2bOrgGuid}
            customerType={customerType}
            handleCustomerType={handleCustomerType}
            grid={grid}
            handleGridSelect={handleGridSelect}
            customerPhone={customerPhone}
            sentFrom={sentFrom}
            transactionId={transactionId}
            handleChangeSolTextInput={handleChangeSolTextInput}
            transactionType={transactionType}
            handleTransactionTypeSelect={handleTransactionTypeSelect}
            promotionTopupRef={promotionTopupRef}
            handlePromotionTopupRefSelect={handlePromotionTopupRefSelect}
            startDate={startDate}
            endDate={endDate}
            handleDateRange={handleDateRange}
            dateRange={dateRange}
            handleSelectedDateRange={handleSelectedDateRange}
            transactionStatus={transactionStatus}
            handleChangeTransactionStatus={handleChangeTransactionStatus}
            resetFilterForm={resetFilterForm}
            filterApplied={filterApplied}
            toggleFilterApplied={toggleFilterApplied}
            assignTransactionHistoryFilterParams={
              assignTransactionHistoryFilterParams
            }
            handleFilterParamsExceptPagination={handleFilterParamsExceptPagination}
          />
        )}
        <GenericModal
          modal={toggleModal.includes(transactionShowLogId)}
          showModalHeader={true}
          title={`Transaction Log`}
          size={`xl`}
          hideModal={(event) => handleToggleModal(event, -1)}
        >
          {parentTransactionDisplayData !== undefined &&
          parentTransactionDisplayData !== null ? (
            <React.Fragment>
              <div className="container">
                <div className="row">
                  <div className="col-7">
                    <div className="row mb-2">
                      <div className="col-4">ID:</div>
                      <div 
                        className="col-8 inter-module-link"
                        onClick={() => navigator.clipboard.writeText(parentTransactionDisplayData.id)}
                      >
                        {parentTransactionDisplayData.id.substring(
                          0,
                          parentTransactionDisplayData.id.indexOf("-")
                        ).concat(" ...")}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Type:</div>
                      <div className="col-8">
                        {parentTransactionDisplayData.type}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Amount:</div>
                      <div className="col-8 list-bold-text">{`${parentTransactionDisplayData.amount} Taka`}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-12">
                        <span className="text-danger">{`Failed `}</span>
                        <span>{`on `}</span>
                        <span className="list-bold-text">
                          {parentTransactionDisplayData.date}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Payment Reference:</div>
                      <div className="col-8">
                        {parentTransactionDisplayData.reference}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Initiated By:</div>
                      <div className="col-8">
                        {parentTransactionDisplayData.initiated_by !== `N/A`
                          ? parentTransactionDisplayData.initiated_by
                          : parentTransactionDisplayData.sent_from}
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="row mb-2">
                      <div className="col-2">Customer:</div>
                      <div className="col-10 sol-text">
                        {parentTransactionDisplayData.customer}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-2">SOLbox:</div>
                      <div className="col-10 sol-text">
                        {parentTransactionDisplayData.solbox}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4 className="normal-text list-bold-text ml-4 mt-5 mb-5">
                {`RETRY ATTEMPTS`}
              </h4>
            </React.Fragment>
          ) : null}
          <DataTable
            columns={showLogColumns}
            data={showLog}
            isLoading={false}
            search={false}
            count={showLog.length}
            asyncPagination={true}
          />
        </GenericModal>
        {/* <ToastContainer /> */}
        <Modal
            show={showConfirmationModal}
            onHide={hideConfirmationModal}
            animation={true}
            size={'md'}
            centered={true}
        >
          <Modal.Header>
            <Modal.Title>Cancelling transaction</Modal.Title >
            <button type="button" className="close" onClick={hideConfirmationModal} aria-label="Close">
              <i aria-hidden="true" className="ki ki-close"/>
            </button>
          </Modal.Header>
          <Modal.Body>
            <table>
              <tr><th>SOLbox</th><td>&nbsp;{solbox}</td></tr>
              <tr><th>Date</th><td>&nbsp;{transactionInitializationDate}</td></tr>
              <tr><th>Amount</th><td>&nbsp;{amount}</td></tr>
              <tr><th>Transaction type</th><td>&nbsp;{transactionTypeToShow}</td></tr>
              <tr><th>Guid</th><td>&nbsp;{transactionGuidToShow}</td></tr>
            </table>
          </Modal.Body>
            <Modal.Footer>
              <Button style={{
                backgroundColor: '#6c757d',
                border: 'none',
              }} disabled={isCancellingTransaction} onClick={hideConfirmationModal}>
                <i className="fa fa-window-close"/>
                &nbsp;Close
              </Button>
              <Button variant="warning" type="submit" disabled={isCancellingTransaction}
                      onClick={() => transactionCancelConfimation()}>
                {isCancellingTransaction ? <Spinner animation="border" variant="secondary"/> :
                    <i className="fa fa-check"/>}
                &nbsp;Continue
              </Button>
            </Modal.Footer>
        </Modal>



        <DataTable
          columns={customerAssignedColumns}
          data={transactionHistoryList}
          isLoading={transactionHistoryTableLoading}
          search={false}
          count={pageCount}
          pagination={true}
          asyncPagination={true}
          itemsPerPage={pageSize}
          onChangePage={handleChangePage}
          page={pageNumber}
        />
      </ContentWrapper>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isTransactionHistoryLoading:
      state.transactionReducer.isTransactionHistoryLoading,
    transactionHistoryTableLoading:
      state.transactionReducer.transactionHistoryTableLoading,
    statusCode: state.transactionReducer.statusCode,
    pageCount: state.transactionReducer.pageCount,
    pageSize: state.transactionReducer.pageSize,
    transactionHistory: state.transactionReducer.transactionHistory,
    createTransaction: state.transactionReducer.createTransaction,
    isRetryingAllFailedTransaction: state.transactionReducer.isRetryingAllFailedTransaction,
    isCancellingTransaction: state.transactionReducer.isCancellingTransaction,
    allFailedTransactionIsSuccessfullyRetried: state.transactionReducer.allFailedTransactionIsSuccessfullyRetried,
    transactionIsCancelled: state.transactionReducer.transactionIsCancelled
  };
};

export default connect(mapStateToProps, transactionActions)(CustomerAssigned);
