/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import GridOnRoundedIcon from '@material-ui/icons/GridOnRounded';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import {toAbsoluteUrl, checkIsActive} from "../../_metronic/_helpers";

export function AsideMenuList({ layoutProps }) {

  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ?
            `${!hasSubmenu && "menu-item-active"} menu-item-open`
            :
            "";
  };

  return (
      <React.Fragment>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/* Customer Support */}
          {/* begin:: section */}
          <li className="menu-section" style={{"margin": 0}}>
            <h4 className="menu-text">Customer Support</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end: section */}
          {/* begin:: sub section */}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive("/smart-boards", false)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/smart-boards">
            <span className="svg-icon menu-icon">
              <img src={require('../utils/asset/smartboard.svg')} alt="smartboard-icon"/>
            </span>
              <span className="menu-text sidebar-menu-item">Smart Board</span>
            </NavLink>
          </li>
          {/* end:: sub section */}
          {/* Finance */}
          {/* begin:: section */}
          <li className="menu-section">
            <h4 className="menu-text">Finance</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}
          {/* begin:: sub section */}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/transactions", false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/transactions">
            <span className="svg-icon menu-icon">
              <img src={require('../utils/asset/taka.svg')} alt="transaction-icon"/>
            </span>
              <span className="menu-text sidebar-menu-item">Transactions</span>
            </NavLink>
          </li>
          {/* end:: sub section */}
          {/* begin:: sub section */}
          {/*<li*/}
          {/*    className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
          {/*        "/tradings", false*/}
          {/*    )}`}*/}
          {/*    aria-haspopup="true"*/}
          {/*    data-menu-toggle="hover"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link menu-toggle" to="/tradings">*/}
          {/*  <span className="svg-icon menu-icon">*/}
          {/*    <img src={require('../utils/asset/trading.svg')} alt="trading-icon"/>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text sidebar-menu-item">Trading Insights</span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/* end:: sub section */}
          {/* Operations */}
          {/* begin::section */}
          <li className="menu-section">
            <h4 className="menu-text">Operations</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}
          {/* begin:: sub section */}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/grids", false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/grids">
            <span className="svg-icon menu-icon">
              <img src={require('../utils/asset/grid.svg')} alt="grid-icon"/>
            </span>
              <span className="menu-text sidebar-menu-item">Grids</span>
            </NavLink>
          </li>
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/customers", false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/customers">
            <span className="svg-icon menu-icon">
              <SupervisorAccountOutlinedIcon className={`sidebar-menu-item`} />
            </span>
              <span className="menu-text sidebar-menu-item">Customers</span>
            </NavLink>
          </li>

          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/solboxes", false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/solboxes">
            <span className="svg-icon menu-icon">
              <AspectRatioOutlinedIcon className={`sidebar-menu-item`} />
            </span>
              <span className="menu-text sidebar-menu-item">SOLboxes</span>
            </NavLink>
          </li>

          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/replacements", false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/replacements">
            <span className="svg-icon menu-icon">
              <SyncAltOutlinedIcon className={`sidebar-menu-item`} />
            </span>
              <span className="menu-text sidebar-menu-item">Replacements</span>
            </NavLink>
          </li>

          {/*<li*/}
          {/*    className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
          {/*        "/otaupdates", false*/}
          {/*    )}`}*/}
          {/*    aria-haspopup="true"*/}
          {/*    data-menu-toggle="hover"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link menu-toggle" to="/otaupdates">*/}
          {/*  <span className="svg-icon menu-icon">*/}
          {/*    <i className={`fas fa-broadcast-tower`}></i>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text sidebar-menu-item">OTA updates</span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/* end:: sub section */}
        </ul>
        {/* end::Menu Nav */}
      </React.Fragment>
  );
}
