import React from "react";
import styles from './Textarea.module.scss';
import PropTypes from 'prop-types';

const SolTextArea = ({
    name,
    inputClassName,
    placeHolder,
    value,
    handleChange
}) => {

    return (
        <React.Fragment>
            <textarea 
                name={name}
                className={`${styles.solTextArea} ${inputClassName}`}
                placeholder={placeHolder}
                value={value}
                onChange={(event) => handleChange !== undefined ? handleChange(event) : null}
                autoComplete="off"
                maxLength="256"
                rows="7"
            >
            </textarea>
        </React.Fragment>
    );
}

SolTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    placeHolder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func
};

export default SolTextArea;