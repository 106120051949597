import React from "react";
import {Badge} from "react-bootstrap";
import {SolTypography} from '../../../../components/utilityComponents/SOlTypography'

const TextFieldStyle = (props) =>{
    return <>
            <div>
                <SolTypography.Text style = {{color: props.secondary? '#8C8C9B':undefined}} >{props.children}</SolTypography.Text>
            </div>
        </>
}
export const smartboardListColumns = [

    {
        field: 'id',
        title: "ID",
        emptyValue: ()=>{
            return "N/A"
        },
        width: "3%",
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',

            // textAlign: 'center',
        },
        // disableClick: true,
    },
    {
        field: 'issueDate',
        title: "Date",
        emptyValue: ()=>{
            return "N/A"
        },
        width: "10%",
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        // disableClick: true,
    },
    {
        field: 'issueReplatedTo',
        title: "Issue Related To",

        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        width: '15%',
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',

        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
    {
        field: 'solbox',
        title: "SOLbox",
        sorting: false,
        width: '10%',
        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
    {
        field: 'issue',
        title: "Issue",
        emptyValue: ()=>{
            return "N/A"
        },
        width: '18%',
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },
    {
        field: 'resolvedOn',
        title: "Resolved On",
        width: '10%',
        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0',
            textAlign: 'left',
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B',
            textAlign: 'left',
        },
        disableClick: true,
    },

]
