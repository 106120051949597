export const smartboardActionTypes = {
    GET_GRID_LIST_SMARTBOARD_REQUEST: 'GET_GRID_LIST_SMARTBOARD_REQUEST',
    GET_GRID_LIST_SMARTBOARD_SUCCESS: 'GET_GRID_LIST_SMARTBOARD_SUCCESS',
    GET_GRID_LIST_SMARTBOARD_FAILURE: 'GET_GRID_LIST_SMARTBOARD_FAILURE',

    GET_GRID_OPERATOR_LIST_REQUEST: 'GET_GRID_OPERATOR_LIST_REQUEST',
    GET_GRID_OPERATOR_LIST_SUCCESS: 'GET_GRID_OPERATOR_LIST_SUCCESS',
    GET_GRID_OPERATOR_LIST_FAILURE: 'GET_GRID_OPERATOR_LIST_FAILURE',

    GET_SOLBOX_MODEL_LIST_REQUEST: 'GET_SOLBOX_MODEL_LIST_REQUEST',
    GET_SOLBOX_MODEL_LIST_SUCCESS: 'GET_SOLBOX_MODEL_LIST_SUCCESS',
    GET_SOLBOX_MODEL_LIST_FAILURE: 'GET_SOLBOX_MODEL_LIST_FAILURE',

    GET_FIRMWARE_VERSION_REQUEST: 'GET_FIRMWARE_VERSION_REQUEST',
    GET_FIRMWARE_VERSION_SUCCESS: 'GET_FIRMWARE_VERSION_SUCCESS',
    GET_FIRMWARE_VERSION_FAILURE: 'GET_FIRMWARE_VERSION_FAILURE',

    GET_ISUE_TYPE_LIST_REQUEST: 'GET_ISUE_TYPE_LIST_REQUEST',
    GET_ISUE_TYPE_LIST_SUCCESS: 'GET_ISUE_TYPE_LIST_SUCCESS',
    GET_ISUE_TYPE_LIST_FAILURE: 'GET_ISUE_TYPE_LIST_FAILURE',

    POST_RESOLVE_ISSUE_REQUEST: 'POST_RESOLVE_ISSUE_REQUEST',
    POST_RESOLVE_ISSUE_SUCCESS: 'POST_RESOLVE_ISSUE_SUCCESS',
    POST_RESOLVE_ISSUE_FAILURE: 'POST_RESOLVE_ISSUE_FAILURE',

    GET_ISUE_LIST_REQUEST: 'GET_ISUE_LIST_REQUEST',
    GET_ISSUE_LIST_FOR_CSV_EXPORT_REQUEST: 'GET_ISSUE_LIST_FOR_CSV_EXPORT_REQUEST',
    GET_ISSUE_LIST_WITH_SEPARATE_LOADER_REQUEST: 'GET_ISSUE_LIST_WITH_SEPARATE_LOADER_REQUEST',
    GET_ISUE_LIST_SUCCESS: 'GET_ISUE_LIST_SUCCESS',
    GET_ISUE_LIST_FAILURE: 'GET_ISUE_LIST_FAILURE',

}
