import React from "react";
import styles from './Radio.module.scss';
import PropTypes from 'prop-types';

const SolRadioInput = ({
    inputClassName,
    name,
    id,
    value,
    handleChange,
    checked,
    labelClassName,
    htmlFor,
    labelName
}) => {

    return (
        <React.Fragment>
            <input
                className={`form-check-input ${styles.solRadioInput} ${inputClassName}`}
                type={`radio`}
                name={name}
                id={id}
                value={value}
                onChange={(event) => handleChange(event)}
                checked={checked}
            />
            <label 
                className={`form-check-label ${labelClassName}`} 
                htmlFor={htmlFor}
            >
                {labelName}
            </label>
        </React.Fragment>
    );
}

SolRadioInput.propTypes = {
    inputClassName: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    labelClassName: PropTypes.string,
    htmlFor: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired
};

export default SolRadioInput;
