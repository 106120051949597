import { tradingActionTypes } from "./tradingActionTypes";

const initialState = {
    isLoading: false,
    gridOwnerList: [],
    gridsList: null
};

export const tradingReducer = (state = initialState, action) => {

    switch(action.type) {

        case tradingActionTypes.GET_GRID_LIST_REQUEST:

        return {
            ...state,
            isGridListLoading: true,
            statusCode: undefined,
        };

        case tradingActionTypes.GET_GRID_LIST_SUCCESS:

        return {
            ...state,
            isGridListLoading: false,
            gridsList: Array.isArray(action.payload) ? action.payload : [],
        };

        case tradingActionTypes.GET_GRID_LIST_FAILURE:

        return {
            ...state,
            isGridListLoading: false,
            statusCode: action.payload.status,
        };

        case tradingActionTypes.GET_GRID_OWNERS_LIST_REQUEST:

        return {
            ...state,
            isLoading: true,
            statusCode: undefined,
        };

        case tradingActionTypes.GET_GRID_OWNERS_LIST_SUCCESS:

        return {
            ...state,
            isLoading: false,
            gridOwnerList: Array.isArray(action.payload) ? action.payload : []
        };

        case tradingActionTypes.GET_GRID_OWNERS_LIST_FAILURE:

        return {
            ...state,
            isLoading: false,
            statusCode: action.payload.status,
        };

        default:
            return state;
    }
}