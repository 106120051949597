import moment from "moment";

class DateTimeFormat {
    constructor(dateString) {
        if (moment.utc(dateString).isValid())
        {
            this.localDisplayTime = moment.utc(dateString).local().format('D MMM, YYYY LT');
            this.localDisplayTimeChart = moment.utc(dateString).local().format('D MMM');
            this.localDisplayTimeWithSeconds = moment.utc(dateString).local().format('D MMM, YYYY h:mm:ss a');
            this.localDisplayWithoutTime = moment.utc(dateString).local().format('D MMM, YYYY');
            this.utcDisplayTime = moment.utc(dateString).local().format('D MMM, YYYY LT');
        } else {
            this.localDisplayTime = false;
            this.utcDisplayTime = false;
        }
    }
}

export default DateTimeFormat;
