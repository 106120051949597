import React, { useEffect, useState } from "react";
import { transactionActions } from "../transactionActions";
import { connect } from "react-redux";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import SolSelect from "../../../components/SolSelect";
import SolTextInput from "../../../components/soltextinput/SolTextInput";
import SolTextArea from "../../../components/soltextarea/SolTextArea";
import SolRadioInput from "../../../components/solradioinput/SolRadioInput";
import SolDaterangePicker from "../../../components/SolDaterangePicker";
import SolSingleDatePicker from "../../../components/SolSingleDatePicker";
import moment from "moment";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import SolChart from '../../../components/SolChart';
import {LoadingSpinner} from "../../grids/utils";
import Loader from "../../../components/loader/Loader";


const CreateTransaction = (props) => {

  const {
    isLoading,
    isGridListLoading,
    isCustomerListLoading,
    statusCode,
    failureMessage,
    transactionTypes,
    gridList,
    customerList,
    gridBenefits,
    validatedTransaction,
    highestBalance,
    lastBalance,
    orphanTransactions,
    createTransaction,
    offlineTransactionId,
    solboxData,
    solChartTxnBalanceData,
    solChartTxnI2GridData,
    FETCH_TRANSACTION_TYPES,
    FETCH_GRID_LIST,
    FETCH_ALL_CUSTOMERS,
    FETCH_GRID_BENEFITS_LIST,
    VALIDATE_FAILED_TRANSACTION,
    GET_HIGHEST_BALANCE,
    GET_LAST_KNOWN_BALANCE,
    CREATE_TRANSACTION,
    REINITIATE_CREATE_TRANSACTION,
    GET_SOLBOX_FIRMWARE_VERSION,
    FETCH_SOLBOX_BALANCE_CHART_DATA,
    FETCH_SOLBOX_I2GRID_CHART_DATA
  } = props;

  const solboxSerailNumRegex = /^[0-9]{8}$/g;

  const [refreshTimerString, setRefreshTimerString] = useState(null);
  const [timer, setTimer] = useState(null);
  const [failedTxnChartDataSeries, setfailedTxnChartDataSeries] = useState(null);
  const [balanceDropChartDataSeries, setBalanceDropChartDataSeries] = useState(null);
  const [i2GridChartDataSeries, setI2GridChartDataSeries] = useState(null);

  const redirectToTransactionList = (event) => {
    props.history.goBack();
  };

  const [amount, setAmount] = useState(0);
  const [amountFieldErrorMessage, setAmountFieldErrorMessage] = useState(false);
  const [tokenNumberErrorMessage, setTokenNumberErrorMessage] = useState(false);
  const [transactionRef, setTransactionRef] = useState("");

  useEffect(() => {
    if (transactionTypes !== undefined && transactionTypes === null) {
      FETCH_TRANSACTION_TYPES();
    }
  }, [transactionTypes]);

  useEffect(() => {
    if (
      orphanTransactions &&
      orphanTransactions.length !== 0 &&
      props.match.params.id &&
      props.match.params.id !== `N/A`
    ) {
      orphanTransactions.forEach((element, index) => {
        if (
          element.id &&
          element.id == props.match.params.id
        ) {
          if (element.amount) {
            setAmount(element.amount);
          }
          if (
            element.tranaction_reference
          ) {
            setTransactionRef(element.tranaction_reference);
          }
        }
      });
    }
  }, [orphanTransactions]);

  useEffect(() => {
    if (gridList !== undefined && gridList === null) {
      FETCH_GRID_LIST();
    }
  }, [gridList]);

  const [gridListOpt, setGridListOpt] = useState([]);

  useEffect(() => {
    if (gridList && gridList.length !== 0) {
      let gridListArr = [];
      gridList.forEach((element, index) => {
        if (element.grid_guid && element.name) {
          gridListArr.push({
            value: element.grid_guid,
            label: element.name,
          });
        }
      });
      setGridListOpt(gridListArr);
    }
  }, [gridList]);

  const [grid, setGrid] = useState("");

  const handleGridSelect = (grid) => {
    setGrid(grid);
  };

  useEffect(() => {
    if (grid) {
      FETCH_ALL_CUSTOMERS({ grid_guid: grid.value });
    } else {
      setCustomerListOpt([]);
    }
  }, [grid]);

  const [customerListOpt, setCustomerListOpt] = useState([]);

  useEffect(() => {
    if (
      customerList &&
      customerList.length !== 0
    ) {
      let customerListArr = [];
      customerList.forEach((element, index) => {
        if (element.name &&
          element.solbox_serial_number &&
          element.node_type &&
          element.node_type.toLowerCase() === "consumer" ||
          element.node_type.toLowerCase() === "prosumer"
        ) {
          customerListArr.push({
            value:
              element.solbox_serial_number !== undefined &&
              element.solbox_serial_number !== null
                ? element.solbox_serial_number
                : `N/A`,
            label:
              element.name !== undefined && element.name !== null
                ? element.name
                : `N/A`,
          });
        }
      });
      setCustomerListOpt(customerListArr);
    } else {
      setCustomerListOpt([]);
    }
  }, [customerList]);

  const [customer, setCustomer] = useState("");

  const handleCustomerSelect = (customer) => {
    setCustomer(customer);
  };

  const [comment, setComment] = useState("");
  const [solboxSerialNum, setSolboxSerialNum] = useState("");
  const [failedTxnId, setFailedTxnId] = useState("");
  const [tokenNum, setTokenNum] = useState(null);

  const handleChangeSolTextInput = (event) => {
    if (event.target.name === "comments") {
      setComment(event.target.value);
    }
    if (event.target.name === "solboxSerialNumber") {
      setSolboxSerialNum(event.target.value);
    }
    if (event.target.name === "unfixedTransactionAmount") {
      if (isNaN(event.target.value)) {
        setAmountFieldErrorMessage(true)
      } else {
        setAmountFieldErrorMessage(false)
        setAmount(event.target.value);
      }
    }
    if (event.target.name === "failedTransId") {
      setFailedTxnId(event.target.value);
    }
    if (event.target.name === "tokenNumber") {
      if (isNaN(event.target.value)) {
        setTokenNumberErrorMessage(true)
      } else {
        setTokenNumberErrorMessage(false)
        setTokenNum(event.target.value);
      }
    }
  };

  const [transactionType, setTransactionType] = useState("");
  const [transactionPurpose, setTransactionPurpose] = useState("");
  const [reimbursementPurpose, setReimbursementPurpose] = useState("");

  const handleChangeTransactionType = (event) => {
    if (event.target.id === "addBalance") {
      setTransactionType(event.target.value);
    } else if (event.target.id === "deductBalance") {
      setTransactionType(event.target.value);
    } else {
      setTransactionType(event.target.value);
    }
  };

  const handleChangeTransactionPurpose = (event) => {
    if (event.target.id === "promotion") {
      setTransactionPurpose(event.target.value);
    } else if (event.target.id === "reimbursement") {
      setTransactionPurpose(event.target.value);
    } else {
      setTransactionPurpose(event.target.value);
    }
  };

  const handleChangeReimbursement = (event) => {
    if (event.target.id === "balance_drop") {
      setReimbursementPurpose(event.target.value);
    } else {
      setReimbursementPurpose(event.target.value);
    }
  };

  useEffect(() => {
    if (
      transactionType &&
      transactionType === "deductBalance"
    ) {
      setTransactionPurpose("custom");
    }
  }, [transactionType]);

  useEffect(() => {
    if (
      transactionPurpose &&
      transactionPurpose === "custom"
    ) {
      if (
        props.match.params.solbox &&
        props.match.params.solbox !== "null"
      ) {
        GET_LAST_KNOWN_BALANCE(props.match.params.solbox);
      }
      if (solboxSerialNum &&
        solboxSerialNum.trim() &&
        solboxSerailNumRegex.test(solboxSerialNum.trim())
      ) {
        GET_LAST_KNOWN_BALANCE(solboxSerialNum.trim());
      }
    }
  }, [transactionPurpose]);

  useEffect(() => {
    if (
      transactionPurpose &&
      transactionPurpose === "custom" &&
      solboxSerialNum &&
      solboxSerialNum.trim() &&
      solboxSerailNumRegex.test(solboxSerialNum.trim())
    ) {
      GET_LAST_KNOWN_BALANCE(solboxSerialNum.trim());
    }
  }, [solboxSerialNum]);

  useEffect(() => {
    if (
      transactionPurpose &&
      transactionPurpose === "reimbursement"
    ) {
      if (
        props.match.params.solbox &&
        props.match.params.solbox !== "null"
      ) {
        GET_SOLBOX_FIRMWARE_VERSION(props.match.params.solbox);
      }
      if (
        solboxSerialNum &&
        solboxSerialNum.trim() &&
        solboxSerailNumRegex.test(solboxSerialNum.trim())
      ) {
        GET_SOLBOX_FIRMWARE_VERSION(solboxSerialNum.trim());
      }
    }
  }, [transactionPurpose]);

  useEffect(() => {
    if (
      transactionPurpose &&
      transactionPurpose === "reimbursement" &&
      solboxSerialNum &&
      solboxSerialNum.trim() &&
      solboxSerailNumRegex.test(solboxSerialNum.trim())
    ) {
      GET_SOLBOX_FIRMWARE_VERSION(solboxSerialNum.trim());
    }
  }, [solboxSerialNum]);

  useEffect(() => {
    if (
      props.match.params.solbox &&
      props.match.params.solbox !== "null"
    ) {
      GET_LAST_KNOWN_BALANCE(props.match.params.solbox);
    }
  }, [props.match.params.solbox]);

  useEffect(() => {
    if (
      transactionPurpose &&
      transactionPurpose === "promotion"
    ) {
      FETCH_GRID_BENEFITS_LIST();
    }
  }, [transactionPurpose]);

  const [promotionRefOpt, setPromotionRefOpt] = useState([]);
  const [promotionLimitRange, setPromotionLimitRange] = useState([]);

  const timeChecker = () => {
    const temp = moment().diff(props.refreshTimer, "minutes");

    if (temp !== timer) {
      setTimer(temp);
    }
  };

  useEffect(() => {
    if (!timer) {
      setRefreshTimerString("Refreshed just now");
    } else if (Number.isInteger(timer) && timer < 60) {
      setRefreshTimerString(
        `Refreshed ${timer} ${timer > 1 ? "minutes" : "minute"} ago`
      );
    } else if (Number.isInteger(timer) && timer >= 60) {
      setRefreshTimerString(
        `Refreshed ${timer / 60} ${
          timer / 60 > 1 ? "hours" : "hour"
        } and ${timer % 60} minutes ago`
      );
    } else {
      setRefreshTimerString(null);
    }
  }, [timer]);

  /*Setting Refresh Timer CountDown*/
  useEffect(() => {
    setRefreshTimerString("Refreshed just now");
    const interval = setInterval(timeChecker, 10000);

    return () => clearInterval(interval);
  }, [props.refreshTimer]);

  useEffect(() => {
    if (
      gridBenefits &&
      gridBenefits.length !== 0
    ) {
      let gridBenefitsArr = [];
      gridBenefits.forEach((element, index) => {
        gridBenefitsArr.push({ value: element.guid, label: element.name });
        setPromotionLimitRange(element.limitation_range);
      });
      setPromotionRefOpt(gridBenefitsArr);
    }
  }, [gridBenefits]);

  const [promotionRef, setPromotionRef] = useState("");

  const handlePromotionRefSelect = (promotionRef) => {
    setPromotionRef(promotionRef);
  };

  useEffect(() => {
    if (
      transactionPurpose &&
      transactionPurpose === "reimbursement"
    ) {
      setPromotionLimitRange([]);
    }
  }, [transactionPurpose]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");

  const handleDateRange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const [dateRange, setDateRange] = useState("");

  useEffect(() => {
    if (
      startDate !== undefined &&
      endDate !== undefined &&
      startDate !== null &&
      endDate !== null
    ) {
      if (moment(endDate).diff(moment(startDate), 'days') + 1 <= 7) {
        setDateRange(
          moment(startDate._d)
            .format("LL")
            .concat(" - ", moment(endDate._d).format("LL"))
        );
        setStartingDate(moment(startDate._d).format("YYYY-MM-DD HH:mm:ss"));
        setEndingDate(moment(endDate._d).format("YYYY-MM-DD HH:mm:ss"));
      }else
      {
        toast.error('You can not select more that 7 days');
      }
    }

  }, [startDate, endDate]);

  const [customerRegDate, setCustomerRegDate] = useState(null);

  useEffect(() => {
    if (solboxData && solboxData.customer_reg_date) {
      setCustomerRegDate(solboxData.customer_reg_date);
    }
  }, [solboxData]);

  const handleDateRangeValidation = (day) => {
    if (customerRegDate) {
      return day.startOf('day').isAfter(moment()) || day.startOf('day').isBefore(moment(customerRegDate));
    }
    return day.startOf('day').isAfter(moment());
  }

  useEffect(() => {
    if (reimbursementPurpose &&
      reimbursementPurpose === "balance_drop" &&
      solboxSerialNum &&
      solboxSerialNum.trim() &&
      solboxSerailNumRegex.test(solboxSerialNum.trim()) &&
      startingDate &&
      endingDate
    ) {
      FETCH_SOLBOX_BALANCE_CHART_DATA(
        {
          //"api_name": `solbox_balances`,
          "solbox_serial_number": Number.parseInt(solboxSerialNum.trim(), 10),
          "date_start": startingDate,
          "date_end": endingDate
        }
      );
    }
  }, [startingDate, endingDate, solboxSerialNum, reimbursementPurpose]);

  useEffect(() => {
    if (reimbursementPurpose &&
      reimbursementPurpose === "balance_drop" &&
      props.match.params.solbox &&
      props.match.params.solbox !== "null" &&
      startingDate &&
      endingDate
    ) {
      FETCH_SOLBOX_BALANCE_CHART_DATA(
        {
          //"api_name": `solbox_balances`,
          "solbox_serial_number": Number.parseInt(props.match.params.solbox, 10),
          "date_start": startingDate,
          "date_end": endingDate
        }
      );
    }
  }, [startingDate, endingDate, props.match.params.solbox, reimbursementPurpose]);

  const compare=(a, b)=> {
    const firstElement = a.x;
    const secondElement = b.x;

    let comparison = 0;
    if (firstElement > secondElement) {
      comparison = 1;
    } else if (firstElement < secondElement) {
      comparison = -1;
    }
    return comparison;
  }

  const getGraph=()=>{
    if (balanceDropChartDataSeries && i2GridChartDataSeries && !isLoading){
      return <>
        <div className="row">
          <div className={`col-sm-8 mt-5 mb-2`} style={{ paddingLeft: "0" }}>
            <SolChart
                dataSeries={balanceDropChartDataSeries}
                xaxisDataType={'datetime'}
                yaxisLineColor={['#53B7E8']}
                mainTitle={`Balance`}
                sideTitle={`Tk`}
                min={0}
            />
          </div>
        </div>
        <div className="row">
          <div className={`col-sm-8 mt-5 mb-2`} style={{ paddingLeft: "0" }}>
            <SolChart
                dataSeries={i2GridChartDataSeries}
                xaxisDataType={'datetime'}
                yaxisLineColor={['#53B7E8']}
                mainTitle={`I2Grid (mA)`}
                sideTitle={`mA`}
            />
          </div>
        </div>
      </>
    } else if (isLoading){
      return <div className={'col-sm-4'}>
        <Loader loadingSubText={'for balance and I2grid'} text={'Generating graph'}/>
      </div>
    }
}

  useEffect(() => {
    if (reimbursementPurpose &&
        reimbursementPurpose === "balance_drop" &&
        solChartTxnBalanceData &&
        solChartTxnBalanceData.length !== 0
      ) {
        let balanceDropChartDataArr = [{
            name: "Balance",
            data: []
        }];
        solChartTxnBalanceData.forEach((element, index) => {
            if (element.solbox_time && element.balance !== undefined && element.created_at) {
                balanceDropChartDataArr[0].data.push({
                    x: Date.parse(element.solbox_time)+21600000,
                    y: element.balance,
                    z: moment(element.created_at).format("DD-MM-YYYY h:mm:ss a")
                });
            }
        });
        if (balanceDropChartDataArr[0].data.length !== 0) {
          const temp = [...balanceDropChartDataArr[0].data ].sort(compare);
          setBalanceDropChartDataSeries([{
            name: "Balance",
            data: temp
          }]);
          // setBalanceDropChartDataSeries(balanceDropChartDataArr);
        }
    }
  }, [solChartTxnBalanceData, reimbursementPurpose]);

  useEffect(() => {
    if (solboxSerialNum &&
      solboxSerialNum.trim() &&
      // solboxSerailNumRegex.test(solboxSerialNum.trim()) &&
      startingDate &&
      endingDate
    ) {
      FETCH_SOLBOX_I2GRID_CHART_DATA(
        {
          //"api_name": `solbox_i2grid_values`,
          "solbox_serial_number": Number.parseInt(solboxSerialNum.trim(), 10),
          "date_start": startingDate,
          "date_end": endingDate
        }
      );
    }
  }, [startingDate, endingDate, solboxSerialNum]);

  useEffect(() => {
    if (props.match.params.solbox &&
        props.match.params.solbox !== "null" &&
        startingDate &&
        endingDate
    ) {
      FETCH_SOLBOX_I2GRID_CHART_DATA(
        {
          //"api_name": `solbox_i2grid_values`,
          "solbox_serial_number": Number.parseInt(props.match.params.solbox, 10),
          "date_start": startingDate,
          "date_end": endingDate
        }
      );
    }
  }, [startingDate, endingDate, solboxSerialNum, props.match.params.solbox]);

  useEffect(() => {
    if (solChartTxnI2GridData && solChartTxnI2GridData.length !== 0) {
        let i2GridChartDataArr = [{
            name: "I2Grid",
            data: []
        }];
        solChartTxnI2GridData.forEach((element, index) => {
            if (element.solbox_time && element.i_to_grid && element.created_at) {
                i2GridChartDataArr[0].data.push({
                    x: Date.parse(element.solbox_time)+21600000,
                    y: element.i_to_grid,
                    z: moment(element.created_at).format("DD-MM-YYYY h:mm:ss a")
                });
            }
        });
        if (i2GridChartDataArr[0].data.length !== 0) {

          const temp = [...i2GridChartDataArr[0].data ].sort(compare);
          setI2GridChartDataSeries([{
            name: "I2Grid",
            data: temp
          }]);
          // setI2GridChartDataSeries(i2GridChartDataArr);
        }
    }
  }, [solChartTxnI2GridData]);

  const [transactionGuid, setTransactionGuid] = useState("");
  const [transactionTime, setTransactionTime] = useState("");
  const [failedTxnAmount, setFailedTxnAmount] = useState(null);

  const handelValidateTransaction = (event) => {
    if (
      failedTxnId
    ) {
      if (
        solboxSerialNum &&
        solboxSerialNum.trim() &&
        solboxSerailNumRegex.test(solboxSerialNum.trim())
      ) {
        VALIDATE_FAILED_TRANSACTION(failedTxnId.trim(), solboxSerialNum.trim());
      }
      if (
        props.match.params.solbox &&
        props.match.params.solbox !== "null"
      ) {
        VALIDATE_FAILED_TRANSACTION(failedTxnId.trim(), props.match.params.solbox);
      }
    }
  };

  useEffect(() => {
    if (validatedTransaction) {
      setTransactionGuid(validatedTransaction.transaction_guid);
      setTransactionTime(validatedTransaction.created_at);
      setFailedTxnAmount(validatedTransaction.amount);
    }
  }, [validatedTransaction]);

  useEffect(() => {
    if (reimbursementPurpose &&
        reimbursementPurpose === "transaction_failed" &&
        solboxSerialNum &&
        solboxSerialNum.trim() &&
        solboxSerailNumRegex.test(solboxSerialNum.trim()) &&
        transactionTime
    ) {
      FETCH_SOLBOX_BALANCE_CHART_DATA(
        {
          //"api_name": `solbox_balances`,
          "solbox_serial_number": Number.parseInt(solboxSerialNum.trim(), 10),
          "date_start": moment(transactionTime).subtract(12, 'hours').format("YYYY-MM-DD HH:mm:ss"),
          "date_end": moment(transactionTime).add(12, 'hours').format("YYYY-MM-DD HH:mm:ss")
        }
      );
    }
  }, [transactionTime, solboxSerialNum, reimbursementPurpose]);

  useEffect(() => {
    if (reimbursementPurpose &&
        reimbursementPurpose === "transaction_failed" &&
        props.match.params.solbox &&
        props.match.params.solbox !== "null" &&
        transactionTime
    ) {
      FETCH_SOLBOX_BALANCE_CHART_DATA(
        {
          //"api_name": `solbox_balances`,
          "solbox_serial_number": Number.parseInt(props.match.params.solbox, 10),
          "date_start": moment(transactionTime).subtract(12, 'hours').format("YYYY-MM-DD HH:mm:ss"),
          "date_end": moment(transactionTime).add(12, 'hours').format("YYYY-MM-DD HH:mm:ss")
        }
      );
    }
  }, [transactionTime, props.match.params.solbox, reimbursementPurpose]);

  useEffect(() => {
    if (reimbursementPurpose &&
        reimbursementPurpose === "transaction_failed" &&
        solChartTxnBalanceData &&
        solChartTxnBalanceData.length !== 0
      ) {
        let failedTxnChartDataArr = [{
            name: "Balance",
            data: []
        }];
        solChartTxnBalanceData.forEach((element, index) => {
            if (element.solbox_time && element.balance !== undefined && element.created_at) {
                failedTxnChartDataArr[0].data.push({
                    x: Date.parse(element.solbox_time)+21600000,
                    y: element.balance,
                    z: moment(element.created_at).format("DD-MM-YYYY h:mm:ss a")
                });
            }
        });
        if (failedTxnChartDataArr[0].data.length !== 0) {
          const temp = [...failedTxnChartDataArr[0].data ].sort(compare);
          setfailedTxnChartDataSeries([{
            name: "Balance",
            data: temp
          }]);
          // setfailedTxnChartDataSeries(failedTxnChartDataArr);
        }
    }
  }, [solChartTxnBalanceData, reimbursementPurpose]);

  const [date, setDate] = useState(null);

  const handleSingleDateChange = (date) => {
    setDate(date);
  };

  const [singleDate, setSingleDate] = useState("");
  const [dropDatetime, setDropDatetime] = useState("");

  useEffect(() => {
    if (date) {
      setSingleDate(moment(date._d).format("lll"));
      setDropDatetime(date._d);
    }
  }, [date]);

  useEffect(() => {
    if (solboxSerialNum &&
      solboxSerialNum.trim() &&
      solboxSerailNumRegex.test(solboxSerialNum.trim()) &&
      dropDatetime
    ) {
      GET_HIGHEST_BALANCE({
        "solbox_serial_number": Number.parseInt(solboxSerialNum.trim(), 10),
        "solbox_date": moment(dropDatetime).format("YYYY-MM-DD")
      });
    }
  }, [dropDatetime, solboxSerialNum]);

  useEffect(() => {
    if (props.match.params.solbox &&
        props.match.params.solbox !== "null" &&
        dropDatetime
    ) {
      GET_HIGHEST_BALANCE({
        "solbox_serial_number": Number.parseInt(props.match.params.solbox, 10),
        "solbox_date": moment(dropDatetime).format("YYYY-MM-DD")
      });
    }
  }, [dropDatetime, props.match.params.solbox]);

  // const [higherBalance, setHigherBalance] = useState(null);

  // useEffect(() => {
  //   if (highestBalance) {
  //     setHigherBalance(highestBalance.highest_balance);
  //   }
  // }, [highestBalance]);

  const handleCreateTransaction = (event) => {
    const regex = /^[\+a-z0-9_,\-\. ]+$/gi;
    let data = {};

    if (timer >= 40) {
      toast.success(`40 minutes have passed since last refreshed. Please Reload the page`);
      return;
    }

    if (
      transactionPurpose &&
      transactionPurpose !== "reimbursement"
    ) {
      Object.assign(data, { type: transactionPurpose });
    } else {
      if (
        transactionPurpose &&
        transactionPurpose === "reimbursement" &&
        reimbursementPurpose
      ) {
        Object.assign(data, { type: reimbursementPurpose });
      } else {
        Object.assign(data, { type: "orphan_topup" });
      }
    }

    if (
      props.match.params.solbox
    ) {
      Object.assign(data, { solbox_serial: props.match.params.solbox });
    }

    if (solboxSerialNum &&
      solboxSerialNum.trim() &&
      solboxSerailNumRegex.test(solboxSerialNum.trim())
    ) {
      Object.assign(data, { solbox_serial: solboxSerialNum.trim() });
    }

    if (customer) {
      Object.assign(data, { solbox_serial: customer.value });
    }

    if (
      promotionRef
    ) {
      Object.assign(data, {
        promotion_guid: promotionRef.value,
        promotion_name: promotionRef.label,
      });
    }

    if (
      transactionGuid
    ) {
      Object.assign(data, { txn_guid: transactionGuid });
    }

    if (
      dropDatetime
    ) {
      Object.assign(data, { drop_datetime: dropDatetime });
    }

    // if (
    //   higherBalance
    // ) {
    //   Object.assign(data, {
    //     limitation_range: [1, Number.parseFloat(higherBalance / 100)],
    //   });
    // }
    // Following has been set as "higher balance" related checking has been removed
    Object.assign(data, {
      limitation_range: [1, 9999],
    });

    if (tokenNum && tokenNum.trim() && regex.test(tokenNum.trim())) {
      Object.assign(data, { token: tokenNum.trim() });
    }

    if (
      transactionRef
    ) {
      Object.assign(data, { txn_reference: transactionRef });
    }

    if (comment && comment.trim() && regex.test(comment.trim())) {
      Object.assign(data, { remarks: comment.trim() });
    } else {
      Object.assign(data, { remarks: "N/A" }); // Added as sometimes we are facing problem in collecting user input
    }

    if (failedTxnAmount &&
      data.type &&
      data.type === "transaction_failed"
    ) {
      Object.assign(data, {amount: failedTxnAmount})
    }

    if (amount) {
      if (
        promotionLimitRange &&
        promotionLimitRange.length !== 0 &&
        data.type &&
        data.type === "promotion"
      ) {
        if (
          amount >= promotionLimitRange[0] &&
          amount <= promotionLimitRange[1]
        ) {
          Object.assign(data, {
            amount: Number.parseFloat(amount),
            limitation_range: promotionLimitRange,
          });
        } else {
          if (
            data.type &&
            data.type === "promotion"
          ) {
            toast.error(`The amount should be between ${promotionLimitRange[0]} to ${promotionLimitRange[1]} Tk.`);
            return;
          }
        }
      }
      // if (
      //   higherBalance
      // ) {
      //   if (amount <= higherBalance / 100) {
      //     Object.assign(data, { amount: Number.parseFloat(amount) });
      //   } else {
      //     if (
      //       data.type &&
      //       data.type === "balance_drop"
      //     ) {
      //       toast.error(`The amount should be between 1 to ${higherBalance / 100} Tk.`);
      //       return;
      //     }
      //   }
      // }
      // As "Higher balance" related checking has been removed, we are setting "amount" for "Balance drop" below
      if (data.type === "balance_drop" && amount >= data.limitation_range[0] && amount <= data.limitation_range[1]) {
        Object.assign(data, {
          amount: amount,
        });
      }

      if (data.type && data.type === "orphan_topup") {
        Object.assign(data, { amount: Number.parseFloat(amount) });
      }
      if (
        data.type &&
        data.type === "offline_recharge" &&
        transactionTypes &&
        transactionTypes.length !== 0
      ) {
        transactionTypes.forEach((element, index) => {
          if (
            element.slug &&
            element.slug === "offline_recharge" &&
            element.limitation_range &&
            element.limitation_range.length !== 0
          ) {
            if (
              amount >= element.limitation_range[0] &&
              amount <= element.limitation_range[1]
            ) {
              Object.assign(data, { amount: Number.parseFloat(amount) });
              Object.assign(data, { limitation_range: element.limitation_range });
            } else {
              if (
                data.type &&
                data.type === "offline_recharge"
              ) {
                toast.error(`The amount should be between ${element.limitation_range[0]} to ${element.limitation_range[1]} Tk.`);
              }
            }
          }
        });
        if (data.amount === undefined) {
          return;
        }
      }
      if (data.type && data.type === "custom") {
        if (
          transactionTypes &&
          transactionTypes.length !== 0
        ) {
          transactionTypes.forEach((element, index) => {
            if (
              element.slug &&
              element.slug === "custom"
            ) {
              if (
                Number.parseFloat(amount) >= 1 && Number.parseFloat(amount) <= 5000
              ) {
                Object.assign(data, {
                  amount: Number.parseFloat(amount),
                });
                Object.assign(data, {
                  limitation_range: [1, 5000],
                });
              } else {
                if (
                  data.type &&
                  data.type === "custom"
                ) {
                  toast.error(`The amount should be between 1 to 5000 Tk.`);
                }
              }
            }
          });
        }
        if (data.amount === undefined) {
          return;
        }
      }
    }

    if (
      Object.entries(data).length !== 0 &&
      data.type &&
      data.type === "promotion" &&
      data.solbox_serial &&
      data.amount &&
      data.amount !== 0 &&
      data.promotion_guid &&
      data.promotion_name &&
      data.limitation_range &&
      data.limitation_range.length !== 0
    ) {
      CREATE_TRANSACTION(data);
    } else if (
      Object.entries(data).length !== 0 &&
      data.type !== undefined &&
      data.type === "orphan_topup" &&
      data.solbox_serial &&
      data.amount &&
      data.amount !== 0 &&
      data.txn_reference
    ) {
      CREATE_TRANSACTION(data);
    } else if (
      Object.entries(data).length !== 0 &&
      data.type !== undefined &&
      data.type === "transaction_failed" &&
      data.solbox_serial &&
      data.amount &&
      data.amount !== 0 &&
      data.txn_guid
    ) {
      CREATE_TRANSACTION(data);
    } else if (
      Object.entries(data).length !== 0 &&
      data.type !== undefined &&
      data.type === "offline_recharge" &&
      data.solbox_serial &&
      data.amount &&
      data.amount !== 0 &&
      data.token
    ) {
      CREATE_TRANSACTION(data);
    } else if (
      Object.entries(data).length !== 0 &&
      data.type !== undefined &&
      data.type === "balance_drop" &&
      data.solbox_serial &&
      data.amount &&
      data.amount !== 0 &&
      data.drop_datetime &&
      data.limitation_range &&
      data.limitation_range.length !== 0
    ) {
      CREATE_TRANSACTION(data);
    } else if (
      Object.entries(data).length !== 0 &&
      data.type !== undefined &&
      data.type === "custom" &&
      data.solbox_serial &&
      data.amount &&
      data.amount !== 0
    ) {
      CREATE_TRANSACTION(data);
    } else {
      toast.error("All input fields should be entered correctly.");
    }
  };

  useEffect(() => {
    if (
      offlineTransactionId &&
      offlineTransactionId.transaction_id &&
      amount
    ) {
      if (
        props.match.params.solbox
      ) {
        toast.success(`
        Transaction has been created
        Transaction ID: ${offlineTransactionId.transaction_id}
        Amount: +${amount}
        Created for: (${props.match.params.solbox})
        `);
        props.history.goBack();
        REINITIATE_CREATE_TRANSACTION();
      }

      if (
        solboxSerialNum
      ) {
        toast.success(`
        Transaction has been created
        Transaction ID: ${offlineTransactionId.transaction_id}
        Amount: +${amount}
        Created for: (${solboxSerialNum})
        `);
        setSolboxSerialNum("");
        props.history.goBack();
        REINITIATE_CREATE_TRANSACTION();
      }
    }
  }, [offlineTransactionId]);

  useEffect(() => {
    if (
      createTransaction &&
      createTransaction === "Success"
    ) {
      setSolboxSerialNum("");
      setComment("");
      setPromotionRef("");
      setDropDatetime("");
      setPromotionLimitRange([]);
      // setHigherBalance(null);
      setTransactionGuid("");
      setTransactionRef("");
      setTokenNum(null);
      setFailedTxnAmount(null);
      props.history.goBack();
      REINITIATE_CREATE_TRANSACTION();
    }
  }, [createTransaction]);

  return (
    <React.Fragment>
      <ContentWrapper
        permissionDenied={false}
        pageTitle={"Configuration History"}
        showBackButton={false}
        isLoading={false}
        showCardHeader={false}
      >
        {props.match.params.id !== undefined &&
        props.match.params.id !== null ? (
          <React.Fragment>
            <h3 className="list-bold-text mb-5">{`Assign Customer for Transaction ${props.match.params.id}`}</h3>
            <div className="normal-text mb-3">{`Relevant Transaction`}</div>
            <div className="list-bold-text mb-1">{`Transaction #${props.match.params.id}`}</div>
            <div className="mb-5">
              {amount !== undefined && amount !== null && amount !== 0
                ? `${amount} Tk`
                : null}
            </div>
          </React.Fragment>
        ) : (
          <h3 className="list-bold-text mb-5">{`Create Transaction For`}</h3>
        )}
        {props.match.params.id !== undefined &&
        props.match.params.id !== null ? (
          <React.Fragment>
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-4`}>
                <label className={`form-check-label mb-5`}>{`Grid`}</label>
                <SolSelect
                  placeHolder={`Select Grid`}
                  value={grid}
                  handleChangeSolSelect={handleGridSelect}
                  options={gridListOpt}
                  selectMulti={false}
                  loading={isGridListLoading}
                  clearable={true}
                  noOptionsMessage={() => `No grid available`}
                />
              </div>
            </div>
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-4`}>
                <label className={`form-check-label mb-5`}>{`Customer`}</label>
                <SolSelect
                  placeHolder={`Select Customer`}
                  value={customer}
                  handleChangeSolSelect={handleCustomerSelect}
                  options={customerListOpt}
                  selectMulti={false}
                  loading={isCustomerListLoading}
                  clearable={true}
                  noOptionsMessage={() => `No customer available`}
                />
              </div>
            </div>
          </React.Fragment>
        ) : props.match.params.solbox !== undefined &&
          props.match.params.solbox !== null &&
          props.match.params.name !== undefined &&
          props.match.params.name !== null &&
          props.match.params.grid !== undefined &&
          props.match.params.grid !== null ? (
          <React.Fragment>
            <div className={`row mb-5`}>
              <div className="col-12 col-sm-6">
                <div className={`row mb-5`}>
                  <div className="col-6 normal-text">{`Customer`}</div>
                  <div className="col-6 normal-text">{`SOLbox`}</div>
                </div>
                <div className={`row mb-5`}>
                  <div className="col-6">
                    {props.match.params.name !== "null"
                      ? props.match.params.name
                      : `N/A`}
                  </div>
                  <div className="col-6">
                    {props.match.params.solbox !== "null"
                      ? props.match.params.solbox
                      : `N/A`}
                  </div>
                </div>
                <div className={`row mb-5`}>
                  <div className="col-6">
                    {props.match.params.grid !== "null"
                      ? props.match.params.grid
                      : `N/A`}
                  </div>
                  <div className="col-6">
                    {lastBalance !== undefined &&
                    lastBalance !== null &&
                    lastBalance.last_known_balance !== undefined &&
                    lastBalance.last_known_balance !== null
                      ? `Balance: ${lastBalance.last_known_balance / 100} Tk`
                      : `N/A`}
                  </div>
                </div>
              </div>
            </div>
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-6`}>
                <label className={`form-check-label mb-5 d-block`}>
                  {`I Want To`}
                </label>
                <div className="form-check form-check-inline mt-2">
                  <SolRadioInput
                    name="typeOfTransaction"
                    id="addBalance"
                    value={`addBalance`}
                    handleChange={(event) => handleChangeTransactionType(event)}
                    checked={transactionType === "addBalance" ? true : false}
                    htmlFor="addBalance"
                    labelName="Add Balance"
                  />
                </div>
                {props.match.params.type &&
                props.match.params.type === "Consumer" ||
                props.match.params.type === "Prosumer" ||
                props.match.params.type === "Producer" ||
                props.match.params.type === "Microutility" ?
                <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="typeOfTransaction"
                    id="deductBalance"
                    value={`deductBalance`}
                    handleChange={(event) => handleChangeTransactionType(event)}
                    checked={transactionType === "deductBalance" ? true : false}
                    htmlFor="deductBalance"
                    labelName="Deduct Balance"
                  />
                </div>
                :
                null
                }
                {/* <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                      name="typeOfTransaction"
                      id="generateTransaction"
                      value={`generateTransaction`}
                      handleChange={(event) => handleChangeTransactionType(event)}
                      checked={(transactionType === "generateTransaction") ? true : false}
                      htmlFor="generateTransaction"
                      labelName="Generate Transaction ID for Offline Recharge"
                  />
              </div> */}
              </div>
            </div>
            {transactionType === "addBalance" ? (
              <div className={`row mb-5`}>
                <div className={`col-12 col-sm-6`}>
                  <label className={`form-check-label mb-5 d-block`}>
                    {`Add Balance For`}
                  </label>
                  {props.match.params.type &&
                  props.match.params.type === "Consumer" ||
                  props.match.params.type === "Prosumer" ||
                  props.match.params.type === "Producer" ?
                  <div className="form-check form-check-inline mt-2">
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="promotion"
                      value={`promotion`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={
                        transactionPurpose === "promotion" ? true : false
                      }
                      htmlFor="promotion"
                      labelName="Promotion"
                    />
                  </div>
                  :
                  null
                  }
                  {/* <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="purposeOfTransaction"
                    id="donation"
                    value={`donation`}
                    handleChange={(event) => handleChangeTransactionPurpose(event)}
                    checked={(transactionPurpose === "donation") ? true : false}
                    htmlFor="donation"
                    labelName="Donation"
                  />
              </div> */}
                  <div className={
                    props.match.params.type && props.match.params.type !== "Microutility" ?
                    "form-check form-check-inline mt-2 customer-status-inactive"
                    :
                    "form-check form-check-inline mt-2"
                    }
                  >
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="reimbursement"
                      value={`reimbursement`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={
                        transactionPurpose === "reimbursement" ? true : false
                      }
                      htmlFor="reimbursement"
                      labelName="Reimbursement"
                    />
                  </div>
                  {props.match.params.type &&
                  props.match.params.type === "Consumer" ||
                  props.match.params.type === "Prosumer" ?
                  <div className="form-check form-check-inline mt-2 customer-status-inactive">
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="offline_recharge"
                      value={`offline_recharge`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={
                        transactionPurpose === "offline_recharge" ? true : false
                      }
                      htmlFor="offline_recharge"
                      labelName="Offline Recharge"
                    />
                  </div>
                  :
                  null
                  }
                </div>
              </div>
            ) : transactionType === "deductBalance" ? (
              <div className={`row mb-5`}>
                <div className={`col-12 col-sm-6`}>
                  <label className={`form-check-label mb-5 d-block`}>
                    {`Deduct Balance For`}
                  </label>
                  <div className="form-check form-check-inline mt-2">
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="custom"
                      value={`custom`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={transactionPurpose === "custom" ? true : false}
                      htmlFor="custom"
                      labelName="Custom"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-4`}>
                <label
                  className={`form-check-label mb-5`}
                >
                  {`SOLbox Number`}
                </label>
                <div className="form-group d-block">
                  <SolTextInput
                    inputClassName={`custom-text-input-style`}
                    name={`solboxSerialNumber`}
                    placeHolder="Type SOLbox Number..."
                    value={solboxSerialNum}
                    handleChange={(event) => handleChangeSolTextInput(event)}
                  />
                </div>
              </div>
            </div>
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-6`}>
                <label className={`form-check-label mb-5 d-block`}>
                  {`Type of Transaction`}
                </label>
                <div className="form-check form-check-inline mt-2">
                  <SolRadioInput
                    name="typeOfTransaction"
                    id="addBalance"
                    value={`addBalance`}
                    handleChange={(event) => handleChangeTransactionType(event)}
                    checked={transactionType === "addBalance" ? true : false}
                    htmlFor="addBalance"
                    labelName="Add Balance"
                  />
                </div>
                <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="typeOfTransaction"
                    id="deductBalance"
                    value={`deductBalance`}
                    handleChange={(event) => handleChangeTransactionType(event)}
                    checked={transactionType === "deductBalance" ? true : false}
                    htmlFor="deductBalance"
                    labelName="Deduct Balance"
                  />
                </div>
                {/* <div className="form-check form-check-inline mt-2 customer-status-inactive">
                    <SolRadioInput
                        name="typeOfTransaction"
                        id="generateTransaction"
                        value={`generateTransaction`}
                        handleChange={(event) => handleChangeTransactionType(event)}
                        checked={(transactionType === "generateTransaction") ? true : false}
                        htmlFor="generateTransaction"
                        labelName="Generate Transaction ID for Offline Recharge"
                    />
                </div> */}
              </div>
            </div>
            {transactionType === "addBalance" ? (
              <div className={`row mb-5`}>
                <div className={`col-12 col-sm-6`}>
                  <label className={`form-check-label mb-5 d-block`}>
                    {`Purpose of Transaction`}
                  </label>
                  <div className="form-check form-check-inline mt-2">
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="promotion"
                      value={`promotion`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={
                        transactionPurpose === "promotion" ? true : false
                      }
                      htmlFor="promotion"
                      labelName="Promotion"
                    />
                  </div>
                  {/* <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="purposeOfTransaction"
                    id="donation"
                    value={`donation`}
                    handleChange={(event) => handleChangeTransactionPurpose(event)}
                    checked={(transactionPurpose === "donation") ? true : false}
                    htmlFor="donation"
                    labelName="Donation"
                  />
              </div> */}
                  <div className="form-check form-check-inline mt-2 customer-status-inactive">
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="reimbursement"
                      value={`reimbursement`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={
                        transactionPurpose === "reimbursement" ? true : false
                      }
                      htmlFor="reimbursement"
                      labelName="Reimbursement"
                    />
                  </div>
                  <div className="form-check form-check-inline mt-2 customer-status-inactive">
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="offline_recharge"
                      value={`offline_recharge`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={
                        transactionPurpose === "offline_recharge" ? true : false
                      }
                      htmlFor="offline_recharge"
                      labelName="Offline Recharge"
                    />
                  </div>
                </div>
              </div>
            ) : transactionType === "deductBalance" ? (
              <div className={`row mb-5`}>
                <div className={`col-12 col-sm-6`}>
                  <label className={`form-check-label mb-5 d-block`}>
                    {`Deduct Balance For`}
                  </label>
                  <div className="form-check form-check-inline mt-2">
                    <SolRadioInput
                      name="purposeOfTransaction"
                      id="custom"
                      value={`custom`}
                      handleChange={(event) =>
                        handleChangeTransactionPurpose(event)
                      }
                      checked={transactionPurpose === "custom" ? true : false}
                      htmlFor="custom"
                      labelName="Custom"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        )}
        {transactionType === "addBalance" &&
          transactionPurpose === "promotion" && (
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-4`}>
                <label className={`form-check-label mb-5`}>
                  {`Promotion Reference`}
                </label>
                <SolSelect
                  placeHolder={`Select Promotion`}
                  value={promotionRef}
                  handleChangeSolSelect={handlePromotionRefSelect}
                  options={promotionRefOpt}
                  selectMulti={false}
                  loading={false}
                  clearable={true}
                  noOptionsMessage={() => `No reference available`}
                />
              </div>
            </div>
          )}
        {transactionType === "addBalance" &&
          transactionPurpose === "reimbursement" &&
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-4`}>
                <label className={`form-check-label mb-5 d-block`}>
                  {`Reimburse Due To`}
                </label>
                {props.match.params.type &&
                props.match.params.type === "Consumer" ||
                props.match.params.type === "Prosumer" ||
                props.match.params.type === "Producer" ||
                props.match.params.type === "Microutility" ?
                <div className="form-check form-check-inline mt-2">
                  <SolRadioInput
                    name="purposeOfReimbursement"
                    id="balance_drop"
                    value={`balance_drop`}
                    handleChange={(event) => handleChangeReimbursement(event)}
                    checked={
                      reimbursementPurpose === "balance_drop" ? true : false
                    }
                    htmlFor="balance_drop"
                    labelName="Balance Drop"
                  />
                </div>
                :
                null
                }
                {!props.match.params.type &&
                <div className="form-check form-check-inline mt-2">
                  <SolRadioInput
                    name="purposeOfReimbursement"
                    id="balance_drop"
                    value={`balance_drop`}
                    handleChange={(event) => handleChangeReimbursement(event)}
                    checked={
                      reimbursementPurpose === "balance_drop" ? true : false
                    }
                    htmlFor="balance_drop"
                    labelName="Balance Drop"
                  />
                </div>
                }
                {solboxData &&
                solboxData.fw_version &&
                solboxData.fw_version.includes("_") &&
                Number.parseFloat(solboxData.fw_version.substring(
                  solboxData.fw_version.lastIndexOf("_") + 1,
                  solboxData.fw_version.length)) < 5.209 ?
                props.match.params.type &&
                props.match.params.type === "Consumer" ||
                props.match.params.type === "Prosumer" ?
                <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="purposeOfReimbursement"
                    id="transaction_failed"
                    value={`transaction_failed`}
                    handleChange={(event) => handleChangeReimbursement(event)}
                    checked={
                      reimbursementPurpose === "transaction_failed"
                        ? true
                        : false
                    }
                    htmlFor="transaction_failed"
                    labelName="Transaction Actually Failed"
                  />
                </div>
                :
                null
                :
                null
                }
                {solboxData &&
                solboxData.fw_version &&
                !solboxData.fw_version.includes("_") &&
                Number.parseFloat(solboxData.fw_version) < 5.209 ?
                props.match.params.type &&
                props.match.params.type === "Consumer" ||
                props.match.params.type === "Prosumer" ?
                <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="purposeOfReimbursement"
                    id="transaction_failed"
                    value={`transaction_failed`}
                    handleChange={(event) => handleChangeReimbursement(event)}
                    checked={
                      reimbursementPurpose === "transaction_failed"
                        ? true
                        : false
                    }
                    htmlFor="transaction_failed"
                    labelName="Transaction Actually Failed"
                  />
                </div>
                :
                null
                :
                null
                }
                {!props.match.params.type &&
                solboxData &&
                solboxData.fw_version &&
                solboxData.fw_version.includes("_") &&
                Number.parseFloat(solboxData.fw_version.substring(
                  solboxData.fw_version.lastIndexOf("_") + 1,
                  solboxData.fw_version.length)) < 5.209 &&
                <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="purposeOfReimbursement"
                    id="transaction_failed"
                    value={`transaction_failed`}
                    handleChange={(event) => handleChangeReimbursement(event)}
                    checked={
                      reimbursementPurpose === "transaction_failed"
                        ? true
                        : false
                    }
                    htmlFor="transaction_failed"
                    labelName="Transaction Actually Failed"
                  />
                </div>
                }
                {!props.match.params.type &&
                solboxData &&
                solboxData.fw_version &&
                !solboxData.fw_version.includes("_") &&
                Number.parseFloat(solboxData.fw_version) < 5.209 &&
                <div className="form-check form-check-inline mt-2 customer-status-inactive">
                  <SolRadioInput
                    name="purposeOfReimbursement"
                    id="transaction_failed"
                    value={`transaction_failed`}
                    handleChange={(event) => handleChangeReimbursement(event)}
                    checked={
                      reimbursementPurpose === "transaction_failed"
                        ? true
                        : false
                    }
                    htmlFor="transaction_failed"
                    labelName="Transaction Actually Failed"
                  />
                </div>
                }
              </div>
            </div>
          }
        {transactionType === "addBalance" &&
          transactionPurpose === "reimbursement" &&
          reimbursementPurpose === "balance_drop" && (
            <React.Fragment>
              <div className={`col-sm-4 mb-2`} style={{ paddingLeft: "0" }}>
                <SolDaterangePicker
                  dateRangelabel={`See Balance Data Of`}
                  startDate={startDate}
                  endDate={endDate}
                  dateRange={dateRange}
                  handleDateRange={handleDateRange}
                  dateRangeValidation={handleDateRangeValidation}
                />
              </div>
              <div className={`col-sm-4 mb-5`} style={{ paddingLeft: "0" }}>
                <SolSingleDatePicker
                  dateRangelabel={`Expected Balance Drop On`}
                  date={date}
                  singleDate={singleDate}
                  handleDate={handleSingleDateChange}
                />
              </div>
              {getGraph()}
            </React.Fragment>
          )}
        {transactionType === "addBalance" &&
          transactionPurpose === "reimbursement" &&
          reimbursementPurpose === "transaction_failed" && (
            <React.Fragment>
              <div className={`row`}>
                <div className={`col-12 col-sm-4`}>
                  <label
                    className={`form-check-label mb-3`}
                  >{`Failed Transaction ID`}</label>
                  <div className="form-group d-block">
                    <SolTextInput
                      inputClassName={`custom-text-input-style`}
                      name={`failedTransId`}
                      placeHolder="Type Failed Transaction ID..."
                      value={failedTxnId}
                      handleChange={(event) => handleChangeSolTextInput(event)}
                    />
                  </div>
                </div>
              </div>
              {isLoading !== undefined &&
                isLoading !== null &&
                isLoading === true && (
                  <React.Fragment>
                    <span className="d-block mb-3">
                      <Spinner animation="border" variant="warning" />
                      <span className="normal-text ml-2">{`Validating transaction ID...`}</span>
                    </span>
                  </React.Fragment>
              )}
              {transactionTime ? (
                  <span
                    className="d-block normal-text mb-3"
                    style={{ color: "rgba(11, 110, 79, 1)" }}
                  >
                    {`Valid transaction ID. Transaction Date: ${moment(
                      transactionTime
                    ).format("LLL")}`}
                  </span>
                )
                :
                statusCode && (
                  <React.Fragment>
                    <span className="d-block normal-text mb-3">{`Could not validate transaction. Please try again.`}</span>
                  </React.Fragment>
                )
              }
              {failureMessage &&
                failureMessage === "Data not found" &&
                failedTxnId && (
                  <span
                    className="d-block mb-3"
                    style={{ color: "rgba(203, 21, 43, 1)" }}
                  >{`No transaction found with transaction ID ${failedTxnId}`}</span>
              )}
              <button
                type={`button`}
                className={"btn btn-outline-warning btn-sm mb-5"}
                onClick={(event) => handelValidateTransaction(event)}
              >
                {`Validate Transaction`}
              </button>
              {failedTxnChartDataSeries &&
                <div className="row">
                  <div className={`col-sm-12 mt-5 mb-5`} style={{ paddingLeft: "0" }}>
                    <SolChart
                      dataSeries={failedTxnChartDataSeries}
                      xaxisDataType={'datetime'}
                      yaxisLineColor={['#53B7E8']}
                      mainTitle={`Balance`}
                      sideTitle={`Tk`}
                      min={0}
                    />
                  </div>
                </div>
              }
            </React.Fragment>
          )}
        {transactionType === "addBalance" &&
          transactionPurpose === "offline_recharge" && (
            <div>
            <div className={tokenNumberErrorMessage? `row`:`row mb-5`}>
              <div className={`col-12 col-sm-4`}>
                <label
                  className={`form-check-label mb-5`}
                >{`Token Number`}</label>
                <div className="form-group d-block">
                  <SolTextInput
                    inputClassName={`custom-text-input-style`}
                    name={`tokenNumber`}
                    placeHolder="Type Token Number..."
                    value={tokenNum}
                    handleChange={(event) => handleChangeSolTextInput(event)}
                  />
                </div>
              </div>
            </div>
            {tokenNumberErrorMessage? (
                <div className={`row`}>
                  <div className={`col-12 col-sm-4`}>
                  <span
                      className="d-block mb-3"
                      style={{ color: "rgba(203, 21, 43, 1)" }}
                  >{`Please enter a number!`}</span>
                  </div>
                </div>
            ): ''}
            </div>
          )}
        <div className={`row mb-5`}>
          <div className={`col-12 col-sm-4`}>
            <label className={`form-check-label mb-5`}>{`Comments`}</label>
            <div className="form-group d-block">
              <SolTextArea
                inputClassName={`custom-text-input-style`}
                name={`comments`}
                placeHolder="More Specific Details..."
                value={comment}
                handleChange={(event) => handleChangeSolTextInput(event)}
              />
            </div>
          </div>
        </div>
        {props.match.params.id !== undefined &&
        props.match.params.id !== null ? (
          <React.Fragment>
            <div className={`row mb-5`}>
              <div className={`col-12 col-sm-4`}>
                <label
                  className={`form-check-label mb-5`}
                >{`Amount (Tk)`}</label>
                <div className="form-group d-block">
                  <SolTextInput
                    inputClassName={`custom-text-input-style disable-input`}
                    name={`fixedTransactionAmount`}
                    placeHolder="Amount (Tk)..."
                    value={amount}
                    handleChange={(event) => handleChangeSolTextInput(event)}
                    disable={true}
                  />
                </div>
              </div>
            </div>
            <div className="list-bold-text">
              {amount !== undefined &&
              amount !== null &&
              amount !== 0 &&
              customer !== undefined &&
              customer !== null &&
              customer !== ""
                ? `${amount}tk will be recharged to the SOLbox (${customer.value}) of ${customer.label}`
                : null}
            </div>
          </React.Fragment>
        ) : failedTxnAmount !== undefined && failedTxnAmount !== null ? (
          <div className={`row mb-5`}>
            <div className={`col-12 col-sm-4`}>
              <label className={`form-check-label mb-5`}>{`Amount (Tk)`}</label>
              <div className="form-group d-block">
                <SolTextInput
                  inputClassName={`custom-text-input-style disable-input`}
                  name={`fixedTransactionAmount`}
                  placeHolder="Amount (Tk)..."
                  value={failedTxnAmount}
                  handleChange={(event) => handleChangeSolTextInput(event)}
                  disable={true}
                />
              </div>
            </div>
          </div>
        ) : (
           <div>
          <div className={amountFieldErrorMessage? `row`: `row mb-5`}>
            <div className={`col-12 col-sm-4`}>
              <label className={`form-check-label mb-5`}>{`Amount (Tk)`}</label>
              <div className="form-group d-block">
                <SolTextInput
                  inputClassName={`custom-text-input-style`}
                  name={`unfixedTransactionAmount`}
                  placeHolder="Amount (Tk)..."
                  value={amount ? amount : null}
                  handleChange={(event) => handleChangeSolTextInput(event)}
                  disable={false}
                />
              </div>
            </div>
          </div>
            {amountFieldErrorMessage? (
              <div className={`row`}>
                <div className={`col-12 col-sm-4`}>
                  <span
                      className="d-block mb-3"
                      style={{ color: "rgba(203, 21, 43, 1)" }}
                  >{`Please enter a number!`}</span>
                </div>
              </div>
            ) : ''}
           </div>
        )}
        <hr />
        {/* <ToastContainer /> */}
        <div className={`row mb-5`}>
          <div className={`col-12`}>
            <button
              type={`button`}
              className={"btn btn-warning btn-lg mb-5"}
              onClick={(event) => handleCreateTransaction(event)}
              disabled={(tokenNumberErrorMessage || amountFieldErrorMessage)? true: false}
            >
              {props.match.params.id !== undefined &&
              props.match.params.id !== null
                ? `Assign Customer`
                : `Create Transaction`}
            </button>
            <button
              type={`button`}
              className={"btn btn-secondary btn-lg ml-4 mb-5 cancel-button"}
              onClick={(event) => redirectToTransactionList(event)}
            >
              {`Cancel`}
            </button>
          </div>
        </div>
      </ContentWrapper>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.transactionReducer.isLoading,
    tableLoading: state.transactionReducer.tableLoading,
    isGridListLoading: state.transactionReducer.isGridListLoading,
    isCustomerListLoading: state.transactionReducer.isCustomerListLoading,
    statusCode: state.transactionReducer.statusCode,
    transactionTypes: state.transactionReducer.transactionTypes,
    gridList: state.transactionReducer.gridList,
    orphanTransactions: state.transactionReducer.orphanTransactions,
    customerList: state.transactionReducer.customerList,
    gridBenefits: state.transactionReducer.gridBenefits,
    validatedTransaction: state.transactionReducer.validatedTransaction,
    failureMessage: state.transactionReducer.failureMessage,
    highestBalance: state.transactionReducer.highestBalance,
    lastBalance: state.transactionReducer.lastBalance,
    createTransaction: state.transactionReducer.createTransaction,
    offlineTransactionId: state.transactionReducer.offlineTransactionId,
    refreshTimer: state.transactionReducer.refreshTimer,
    solboxData: state.transactionReducer.solboxData,
    solChartTxnBalanceData: state.transactionReducer.solChartTxnBalanceData,
    solChartTxnI2GridData: state.transactionReducer.solChartTxnI2GridData
  };
};

export default connect(mapStateToProps, transactionActions)(CreateTransaction);
