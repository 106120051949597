import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import DashboardPage from "../pages/DashboardPage";
import { DemoRouter } from "../features/demoComponent/router";
import CustomerRouter from "../features/customer/CustomerRouter";
import SolboxRouter from "../features/solbox/SolboxRouter";
import TransactionRouter from "../features/transaction/TransactionRouter";
import TradingRouter from "../features/trading/TradingRouter";
import { GridsRouter } from "../features/grids/router";
import ReplacementRouter from "../features/replacement/ReplacementRouter";
import SmartboardRouter from "../features/smartBoard/SmartboardRouter";
import otaUpdatesRouter from "../features/otaUpdates/router";

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/smart-boards"/>
                }
                <ContentRoute path="/customers" component={CustomerRouter}/>
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/grids" component={GridsRouter}/>
                <ContentRoute path="/replacements" component={ReplacementRouter}/>
                <ContentRoute path="/smart-boards" component={SmartboardRouter}/>
                <ContentRoute path="/solboxes" component={SolboxRouter}/>
                <ContentRoute path="/tradings" component={TradingRouter}/>
                <ContentRoute path="/transactions" component={TransactionRouter}/>
                <ContentRoute path="/otaupdates" component={otaUpdatesRouter}/>
                <Route path="/demo" component={DemoRouter}/>
                <Route path="/common/solboxes/router" component={DemoRouter}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
