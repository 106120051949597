import React, { useState, useEffect } from "react";
import { transactionActions }   from "../transactionActions";
import { connect } from 'react-redux';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../components/dataTable/DataTable";
import TransactionToolbar from "./TransactionToolbar";
import UnassignedTransactionFilter from "./UnassignedTransactionFilter";
import CreateTransaction from "./CreateTransaction";
import moment from "moment";

const CustomerUnassigned = (props) => {

    const { 
        location,
        history,
        isSolshare,
        b2bOrgGuid,
        key,
        redirectToAssignCustomer,
        isOrphanTransactionLoading,
        orphanTransactiontableLoading,
        page_count,
        page_size,
        orphanTransactions,
        statusCode,
        GET_ORPHAN_TRANSACTION_LIST
     } = props;

    const [toggleFilter, setToggleFilter] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [sentFrom, setSentFrom] = useState("");
    const [mobPayRef, setMobPayRef] = useState("");
    const [mobTransRef, setMobTransRef] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState("");
    const [filterParams, setFilterParams] = useState(null);

    const handleSetFilterParams = (params) => {
        if (params) {
            setFilterParams(params);
        }
    }

    const handleSelectedDateRange = (range) => {
        setDateRange(range);
    }

    const handleDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const handleChangeSolTextInput = (event) => {
        if (event.target.name === "sentPhoneNumber") {
            setSentFrom(event.target.value);
        }
        if (event.target.name === "mobilePaymentRef") {
            setMobPayRef(event.target.value);
        }
        if (event.target.name === "mobileTransactionRef") {
            setMobTransRef(event.target.value);
        }
    }

    const handleToggleFilter = (event) => {
        setToggleFilter(!toggleFilter);
    }

    const toggleFilterApplied = (event, value) => {
        setFilterApplied(value);
    }

    useEffect((event) => {
        if (!filterApplied &&
            location && 
            location.search &&
            location.hash &&
            location.hash === "#orphan_transaction"
        ) {
            let filterParams = {};
            var searchParams = new URLSearchParams(location.search);
            if (searchParams.has("sent_from") ||
                searchParams.has("payment_ref") ||
                searchParams.has("transaction_ref") ||
                searchParams.has("start_date") ||
                searchParams.has("end_date")
            ) {
                searchParams.forEach((value, key) => {
                    if (value) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                });
            }
            if (!(Object.entries(filterParams).length < 1)) {
                toggleFilterApplied(event, true);
                setFilterParams(filterParams);
                GET_ORPHAN_TRANSACTION_LIST(filterParams);
            }
        } else {
            if (!filterApplied) {
                GET_ORPHAN_TRANSACTION_LIST(null);
            }
        }
    }, [location, filterApplied]);

    const resestFilter = (event) => {
        if (filterApplied !== false) {
            if (sentFrom !== "" ||
            mobPayRef !== "" ||
            mobTransRef !== "" ||
            startDate !== null ||
            endDate !== null
            ) {
                //GET_ORPHAN_TRANSACTION_LIST(null);
                toggleFilterApplied(event, false);
                history.push({
                    pathname: '/transactions/list',
                    search: ""
                });
            }
        }
        setSentFrom("");
        setMobPayRef("");
        setMobTransRef("");
        setStartDate(null);
        setEndDate(null);
        setDateRange("");
        setFilterParams(null);
    }

    const customerUnassignedColumns = [
        {
            field: "id",
            title: "ID",
            headerStyle: { color: "#8C8C9B" },
            emptyValue: () => `N/A`
        },
        {
            field: "date",
            title: "Date",
            headerStyle: { color: "#8C8C9B" },
            emptyValue: () => `N/A`
        },
        {
            field: "mobile_payment_ref",
            title: "Mobile Payment Ref.",
            headerStyle: { color: "#8C8C9B" },
            emptyValue: () => `N/A`
        },
        {
            field: "amount",
            title: "Amount (Tk)",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <React.Fragment>
                    {(rowData !== undefined &&
                        rowData !== null && 
                        rowData.amount !== undefined &&
                        rowData.amount !== null
                    ) ?
                    <React.Fragment>
                        <span className="d-block">
                        {rowData.amount}
                        </span>
                    </React.Fragment>
                    :
                    `N/A`}
                    {(rowData !== undefined &&
                        rowData !== null && 
                        rowData.sent_from !== undefined &&
                        rowData.sent_from !== null &&
                        rowData.sent_from !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                        {`From ${rowData.sent_from}`}
                        </span>
                    </React.Fragment>
                    :
                    null}
                </React.Fragment>
            )
        },
        {
            field: "type",
            title: "Type",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <React.Fragment>
                    {(rowData !== undefined &&
                        rowData !== null &&
                        rowData.type !== undefined &&
                        rowData.type !== null &&
                        rowData.type !== `N/A`
                        ) ?
                        <span className="d-block list-bold-text">
                            {rowData.type}
                        </span>
                        :
                        `N/A`
                    }
                    {(rowData !== undefined &&
                        rowData !== null &&
                        rowData.tranaction_reference !== undefined &&
                        rowData.tranaction_reference !== null &&
                        rowData.tranaction_reference !== `N/A`
                        ) ?
                        <span className="d-block">
                            {`REF: ${rowData.tranaction_reference}`}
                        </span>
                        :
                        null
                    }
                </React.Fragment>
            )
        },
        {
            field: "status",
            title: "Status",
            headerStyle: { color: "#8C8C9B" },
            //cellStyle: { color: "rgba(203, 21, 43, 1)" },
            render: (rowData) => (
                <React.Fragment>
                    {(rowData.status.toLowerCase() === "failed") ?
                        <span className="transaction-status-failed">
                            {`Failed`}
                        </span>
                        :
                        (rowData.status.toLowerCase() === "processing") ?
                        <span>
                            {`In Progress`}
                        </span>
                        :
                        <span className="text-success">
                            {(rowData.status.toLowerCase() === "received") ? `Successful` : `N/A`}
                        </span>
                    }
                </React.Fragment>
            )
        },
        {
            field: "",
            title: "",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <React.Fragment>
                    {isSolshare &&
                        <button
                        type={`button`}
                        className={'btn btn-outline-warning btn-sm'}
                        onClick={(event) => redirectToAssignCustomer(event, rowData.id)}
                        >
                            {`Select Customer`}
                        </button>
                    }
                </React.Fragment>
            ) 
        }
    ];

    const customerUnassignedData = [
        {"id": "57413fea", "date": "Nov 20, 2020 3:00 PM", "mobile_payment_ref": "1934", "amount": "+450 From +8801712345789", "type": "Topup REF: YT78X9089", "status": "failed"},
        {"id": "57413feb", "date": "Nov 20, 2020 3:00 PM", "mobile_payment_ref": "1934", "amount": "+450 From +8801712345789", "type": "Topup REF: YT78X9089", "status": "failed"},
        {"id": "57413fec", "date": "Nov 20, 2020 3:00 PM", "mobile_payment_ref": "1934", "amount": "+450 From +8801712345789", "type": "Topup REF: YT78X9089", "status": "failed"}
    ];

    // useEffect(() => {
    //     if (orphanTransactions !== undefined &&
    //         orphanTransactions === null &&
    //         location &&
    //         !location.search
    //     ) {
    //         GET_ORPHAN_TRANSACTION_LIST(null);
    //     }
    // }, [orphanTransactions]);

    const [orphanTransactionsList, setOrphanTransactionsList] = useState([]);

    useEffect(() => {
        setToggleFilter(true);
    }, []);

    useEffect(() => {
      if (
        orphanTransactions &&
        orphanTransactions.length !== 0
      ) {
        let orphanTransactionsArr = [];
        orphanTransactions.forEach((element, index) => {
          orphanTransactionsArr.push({
            id:
              element.id !== undefined &&
              element.id !== null
                ? element.id
                : `N/A`,
            date:
              element.created_at !== undefined && element.created_at !== null
                ? moment(element.created_at).format("MMMM DD YYYY, h:mm:ss a")
                : `N/A`,
            mobile_payment_ref:
              element.payment_reference !== undefined &&
              element.payment_reference !== null
                ? element.payment_reference
                : `N/A`,
            amount:
              element.amount !== undefined &&
              element.amount !== null
                ? element.amount.toString(10).includes("-")
                  ? element.amount.toString(10)
                  : `+`
                      .concat("", element.amount.toString(10))
                : `N/A`,
            sent_from:
            element.sent_from !== undefined && element.sent_from !== null
            ? element.sent_from 
            : `N/A`,
            type:
              element.txn_type !== undefined && element.txn_type !== null
                ? element.txn_type
                : `N/A`,
            tranaction_reference:
            element.tranaction_reference !== undefined && element.tranaction_reference !== null 
            ? element.tranaction_reference
            : `N/A`,
            status:
              element.status !== undefined && element.status !== null
                ? element.status
                : `N/A`,
          });
        });
        setOrphanTransactionsList(orphanTransactionsArr);
      } else {
        setOrphanTransactionsList([]);
      }
    }, [orphanTransactions]);

    const [pageNumber, setpageNumber] = useState(null);

    const handleChangePage = (event, pageNumber) => {
        setpageNumber(pageNumber);
        if (pageNumber && filterParams) {
            let paginatedFilterParams = {"page": pageNumber};
            Object.assign(paginatedFilterParams, filterParams);
            GET_ORPHAN_TRANSACTION_LIST(paginatedFilterParams);
        }
        if (pageNumber && !filterParams) {
            GET_ORPHAN_TRANSACTION_LIST({"page": pageNumber});
        }
    }

    return (
        <React.Fragment>
            <ContentWrapper 
                permissionDenied={false} 
                pageTitle={"Configuration History"} 
                showBackButton={false}
                isLoading={false} 
                showCardHeader={false}
            >
                <TransactionToolbar
                    csvData={orphanTransactionsList}
                    tabKey={`customer_unassigned`}
                    filterApplied={filterApplied}
                    handleToggleFilter={handleToggleFilter}
                />
                {(toggleFilter === true) &&
                    <UnassignedTransactionFilter
                        {...props}
                        sentFrom={sentFrom}
                        mobPayRef={mobPayRef}
                        mobTransRef={mobTransRef}
                        handleChangeSolTextInput={handleChangeSolTextInput}
                        startDate={startDate}
                        endDate={endDate}
                        handleDateRange={handleDateRange}
                        dateRange={dateRange}
                        handleSelectedDateRange={handleSelectedDateRange}
                        resestFilter={resestFilter}
                        filterApplied={filterApplied}
                        toggleFilterApplied={toggleFilterApplied}
                        handleSetFilterParams={handleSetFilterParams}
                    />
                }
                <DataTable
                    columns={customerUnassignedColumns}
                    data={orphanTransactionsList}
                    isLoading={orphanTransactiontableLoading}
                    search={false}
                    count={page_count}
                    asyncPagination={true}
                    itemsPerPage={page_size}
                    onChangePage={handleChangePage}
                    page={pageNumber}
                />
            </ContentWrapper>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isOrphanTransactionLoading: state.transactionReducer.isOrphanTransactionLoading,
        orphanTransactiontableLoading: state.transactionReducer.orphanTransactiontableLoading,
        statusCode: state.transactionReducer.statusCode,
        page_count:  state.transactionReducer.page_count,
        page_size: state.transactionReducer.page_size,
        orphanTransactions: state.transactionReducer.orphanTransactions
    }
};

export default connect(mapStateToProps, transactionActions)(CustomerUnassigned);