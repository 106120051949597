import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TradingInsights from "./views/TradingInsights";
import SecuredRoute from "../../router/SecuredRoute";

const TradingRouter = ({}) => {

    return (
        <Switch>
            {/* Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/tradings"}
                to={"/tradings/insights"}
            />
            {/* Route to Transaction List */}
            <SecuredRoute
                path={"/tradings/insights"}
                component={TradingInsights}
            />
        </Switch>
    )
};

export default TradingRouter;