import {SolTypography} from "../utilityComponents/SOlTypography";
import React from "react";

export const SuccessRenderStatus =({children})=>{
    return <>
        <div style={{
            display: "inline-block",
        }}>
            {/*<span className="material-icons text-danger" style={{verticalAlign: "middle"}}>error_outline</span>*/}
            <img src={require('../../utils/asset/okay.svg')} alt="Success logo"/>
            &nbsp;
            &nbsp;
            <span><SolTypography.Text style={{color: '#0B6E4F'}}>{children}</SolTypography.Text></span>
        </div>
    </>
}

export const PendingRenderStatus =({children})=>{
    return <>
        <div style={{
            display: "inline-block",
        }}>
            {/*<span className="material-icons text-danger" style={{verticalAlign: "middle"}}>error_outline</span>*/}
            <img src={require('../../utils/asset/in-progress.svg')} alt="Pending logo"/>
            &nbsp;
            &nbsp;
            <span><SolTypography.Text style={{color: '#12100C'}}>{children}</SolTypography.Text></span>
        </div>
    </>
}


export const DangerStatusRender =({children})=>{
    return <>
        <div style={{
            display: "inline-block",
        }}>
            {/*<span className="material-icons text-danger" style={{verticalAlign: "middle"}}>error_outline</span>*/}
            <img src={require('../../utils/asset/danger.svg')} alt="Failed logo"/>
            &nbsp;
            &nbsp;
            <span><SolTypography.Text style={{color: '#E71D36'}}>{children}</SolTypography.Text></span>
        </div>
    </>
}


export const NotInitiatedRender =({children})=>{
    return <>
        <div style={{
            display: "inline-block",
        }}>
            {/*<span className="material-icons text-danger" style={{verticalAlign: "middle"}}>error_outline</span>*/}
            <i className="fa fa-times" aria-hidden="true"></i>
            &nbsp;
            &nbsp;
            <span><SolTypography.Text style={{color: '#0275d8'}}>{children}</SolTypography.Text></span>
        </div>
    </>
}