import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_GRID_LIST_SUMMARY: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/list_details_summary/`, filter),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRID_LIST_SUMMARY_REQUEST,
                actionTypes.GET_GRID_LIST_SUMMARY_SUCCESS,
                actionTypes.GET_GRID_LIST_SUMMARY_FAILURE,
            ]
        }
    }),

    GET_GRID_LIST_FOR_EXPORTING_TO_CSV: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/get_list_details/`, filter),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRID_LIST_FOR_EXPORTING_TO_CSV_REQUEST,
                actionTypes.GET_GRID_LIST_FOR_EXPORTING_TO_CSV_SUCCESS,
                actionTypes.GET_GRID_LIST_FOR_EXPORTING_TO_CSV_FAILURE,
            ]
        }
    }),

    GET_GRID_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/list_details/`, filter),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                filter.page || filter.name || filter.searchRequest===true?actionTypes.GET_GRID_LIST_WITH_PAGE_REQUEST:actionTypes.GET_GRID_LIST_REQUEST,
                actionTypes.GET_GRID_LIST_SUCCESS,
                actionTypes.GET_GRID_LIST_FAILURE,
            ]
        }
    }),

    GET_GRID_LIST_WITH_FILTER: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/list_details/`, {
                ...filter,
                month: filter.month?parseInt(filter.month)+1:undefined,
            }),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                filter.page || filter.name || filter.searchRequest===true?actionTypes.GET_GRID_LIST_WITH_PAGE_REQUEST:actionTypes.GET_GRID_LIST_WITH_FILTER_REQUEST,
                actionTypes.GET_GRID_LIST_SUCCESS,
                actionTypes.GET_GRID_LIST_FAILURE,
            ]
        }
    }),
    GET_GRID_OWNERS_LIST: ()=>({
        [CALL_API]: {
            endpoint: `/grid/organization/list/`,
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRID_OWNERS_LIST_REQUEST,
                actionTypes.GET_GRID_OWNERS_LIST_SUCCESS,
                actionTypes.GET_GRID_OWNERS_LIST_FAILURE,
            ]
        }
    }),

    GET_GRIDS_NODE_LIST: (filter={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/profile/`, filter),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                filter.solbox_id || filter.solbox_model || filter.node_type || filter.customer_guid || filter.frontEndFilter?actionTypes.GET_GRIDS_NODE_LIST_WITH_FILTER_REQUEST:actionTypes.GET_GRIDS_NODE_LIST_REQUEST,
                actionTypes.GET_GRIDS_NODE_LIST_SUCCESS,
                actionTypes.GET_GRIDS_NODE_LIST_FAILURE,
            ]
        }
    }),

    PUBLISH_TOPIC: (payload)=>({
        [CALL_API]: {
            endpoint: `/solbox/publish_topic/`,
            method: 'POST',
            body: payload,
            // backend_slug: 'solbox_backend',
            types: [
                actionTypes.PUBLISH_TOPIC_REQUEST,
                actionTypes.PUBLISH_TOPIC_SUCCESS,
                actionTypes.PUBLISH_TOPIC_FAILURE,
            ]
        }
    }),

    GET_LIVE_DATA: (payload)=>({
        [CALL_API]: {
            endpoint: `/solbox/bulk_live_data/`,
            method: 'POST',
            body: payload,
            types: [
                actionTypes.GET_LIVE_DATA_REQUEST,
                actionTypes.GET_LIVE_DATA_SUCCESS,
                actionTypes.GET_LIVE_DATA_FAILURE,
            ]
        }
    }),

    GET_LEGACY_LIVE_DATA: (payload)=>({
        [CALL_API]: {
            endpoint: `/matrices/get_latest_metrics_data/`,
            method: 'POST',
            body: {solbox_guid_list: payload.solbox_guid_list},
            backend_slug: 'performance',
            types: [
                payload.firstCall? actionTypes.GET_LEGACY_LIVE_DATA_PARAMS_REQUEST: actionTypes.GET_LEGACY_LIVE_DATA_REQUEST,
                payload.firstCall? actionTypes.GET_LEGACY_LIVE_DATA_PARAMS_SUCCESS: actionTypes.GET_LEGACY_LIVE_DATA_SUCCESS,
                payload.firstCall? actionTypes.GET_LEGACY_LIVE_DATA_PARAMS_FAILURE: actionTypes.GET_LEGACY_LIVE_DATA_SUCCESS,
            ]
        }
    }),

    GET_MODELS_LIST: () => ({
        [CALL_API]: {
            endpoint: `/solmodel-list/`,
            method: 'GET',
            types: [
                actionTypes.GET_MODELS_LIST_REQUEST,
                actionTypes.GET_MODELS_LIST_SUCCESS,
                actionTypes.GET_MODELS_LIST_FAILURE,
            ]
        }
    }),

    GET_CUSTOMERS_LIST: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler(`/grid/customers/`, filter),
            backend_slug: 'grid_backend',
            method: 'GET',
            types: [
                actionTypes.GET_CUSTOMERS_LIST_REQUEST,
                actionTypes.GET_CUSTOMERS_LIST_SUCCESS,
                actionTypes.GET_CUSTOMERS_LIST_FAILURE,
            ]
        }
    }),

    GET_SAM_LIST: () => ({
        [CALL_API]: {
            endpoint: `/area_manager/`,
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_SAM_LIST_REQUEST,
                actionTypes.GET_SAM_LIST_SUCCESS,
                actionTypes.GET_SAM_LIST_FAILURE,
            ]
        }
    }),
    GET_PROJECT_LIST: () => ({
        [CALL_API]: {
            endpoint: `/grid/project/`,
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_PROJECT_LIST_REQUEST,
                actionTypes.GET_PROJECT_LIST_SUCCESS,
                actionTypes.GET_PROJECT_LIST_FAILURE,
            ]
        }
    }),

    POST_GRID_INFO: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/grid/',
            method: 'POST',
            body : payload,
            backend_slug: 'grid_backend',
            types: [
                actionTypes.POST_GRID_INFO_REQUEST,
                actionTypes.POST_GRID_INFO_SUCCESS,
                actionTypes.POST_GRID_INFO_FAILURE,
            ]
        }
    }),

    SET_CONFIRMATION_FLAG_TO_UNDEFINED : ()=>({type: actionTypes.SET_CONFIRMATION_FLAG_TO_UNDEFINED}),
    SET_BALANCE_DATA_FLAG_TO_UNDEFINED : ()=>({type: actionTypes.SET_BALANCE_DATA_FLAG_TO_UNDEFINED}),
    SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED : ()=>({type: actionTypes.SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED}),

};
