export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    GET_GRID_LIST_SUMMARY_REQUEST: 'GET_GRID_LIST_SUMMARY_REQUEST',
    GET_GRID_LIST_SUMMARY_SUCCESS: 'GET_GRID_LIST_SUMMARY_SUCCESS',
    GET_GRID_LIST_SUMMARY_FAILURE: 'GET_GRID_LIST_SUMMARY_FAILURE',

    /* Requesting For Live Data for a SOLbox*/
    GET_GRID_LIST_REQUEST: 'GET_GRID_LIST_REQUEST',
    GET_GRID_LIST_WITH_FILTER_REQUEST: 'GET_GRID_LIST_WITH_FILTER_REQUEST',
    GET_GRID_LIST_WITH_PAGE_REQUEST: 'GET_GRID_LIST_WITH_PAGE_REQUEST',
    GET_GRID_LIST_SUCCESS: 'GET_GRID_LIST_SUCCESS',
    GET_GRID_LIST_FAILURE: 'GET_GRID_LIST_FAILURE',


    /*Getting The grid owners list*/
    GET_GRID_OWNERS_LIST_REQUEST: 'GET_GRID_OWNERS_LIST_REQUEST',
    GET_GRID_OWNERS_LIST_SUCCESS: 'GET_GRID_OWNERS_LIST_SUCCESS',
    GET_GRID_OWNERS_LIST_FAILURE: 'GET_GRID_OWNERS_LIST_FAILURE',


    GET_GRID_LIST_FOR_EXPORTING_TO_CSV_REQUEST: 'GET_GRID_LIST_FOR_EXPORTING_TO_CSV_REQUEST',
    GET_GRID_LIST_FOR_EXPORTING_TO_CSV_SUCCESS: 'GET_GRID_LIST_FOR_EXPORTING_TO_CSV_SUCCESS',
    GET_GRID_LIST_FOR_EXPORTING_TO_CSV_FAILURE: 'GET_GRID_LIST_FOR_EXPORTING_TO_CSV_FAILURE',


    PUBLISH_TOPIC_REQUEST: 'PUBLISH_TOPIC_REQUEST',
    PUBLISH_TOPIC_FILTERED_REQUEST: 'PUBLISH_TOPIC_FILTERED_REQUEST',
    PUBLISH_TOPIC_SUCCESS: 'PUBLISH_TOPIC_SUCCESS',
    PUBLISH_TOPIC_FAILURE: 'PUBLISH_TOPIC_FAILURE',


    GET_LIVE_DATA_REQUEST: 'GET_LIVE_DATA_REQUEST',
    GET_LIVE_DATA_SUCCESS: 'GET_LIVE_DATA_SUCCESS',
    GET_LIVE_DATA_FAILURE: 'GET_LIVE_DATA_FAILURE',

    GET_LEGACY_LIVE_DATA_REQUEST: 'GET_LEGACY_LIVE_DATA_REQUEST',
    GET_LEGACY_LIVE_DATA_PARAMS_REQUEST: 'GET_LEGACY_LIVE_DATA_PARAMS_REQUEST',
    GET_LEGACY_LIVE_DATA_PARAMS_SUCCESS: 'GET_LEGACY_LIVE_DATA_PARAMS_SUCCESS',
    GET_LEGACY_LIVE_DATA_SUCCESS: 'GET_LEGACY_LIVE_DATA_SUCCESS',
    GET_LEGACY_LIVE_DATA_FAILURE: 'GET_LEGACY_LIVE_DATA_FAILURE',
    GET_LEGACY_LIVE_DATA_PARAMS_FAILURE: 'GET_LEGACY_LIVE_DATA_PARAMS_FAILURE',


    GET_GRIDS_NODE_LIST_REQUEST: 'GET_GRIDS_NODE_LIST_REQUEST',
    GET_GRIDS_NODE_LIST_WITH_FILTER_REQUEST: 'GET_GRIDS_NODE_LIST_WITH_FILTER_REQUEST',
    GET_GRIDS_NODE_LIST_WITH_PAGE_REQUEST: 'GET_GRIDS_NODE_LIST_WITH_PAGE_REQUEST',
    GET_GRIDS_NODE_LIST_SUCCESS: 'GET_GRIDS_NODE_LIST_SUCCESS',
    GET_GRIDS_NODE_LIST_FAILURE: 'GET_GRIDS_NODE_LIST_FAILURE',


    GET_MODELS_LIST_REQUEST: 'GET_MODELS_LIST_REQUEST',
    GET_MODELS_LIST_SUCCESS: 'GET_MODELS_LIST_SUCCESS',
    GET_MODELS_LIST_FAILURE: 'GET_MODELS_LIST_FAILURE',

    GET_CUSTOMERS_LIST_REQUEST: 'GET_CUSTOMERS_LIST_REQUEST',
    GET_CUSTOMERS_LIST_SUCCESS: 'GET_CUSTOMERS_LIST_SUCCESS',
    GET_CUSTOMERS_LIST_FAILURE: 'GET_CUSTOMERS_LIST_FAILURE',


    CREATE_GRID_REQUEST: 'CREATE_GRID_REQUEST',
    CREATE_GRID_SUCCESS: 'CREATE_GRID_SUCCESS',
    CREATE_GRID_FAILURE: 'CREATE_GRID_FAILURE',



    GET_PROJECT_LIST_REQUEST: 'GET_PROJECT_LIST_REQUEST',
    GET_PROJECT_LIST_SUCCESS: 'GET_PROJECT_LIST_SUCCESS',
    GET_PROJECT_LIST_FAILURE: 'GET_PROJECT_LIST_FAILURE',



    GET_SAM_LIST_REQUEST: 'GET_SAM_LIST_REQUEST',
    GET_SAM_LIST_SUCCESS: 'GET_SAM_LIST_SUCCESS',
    GET_SAM_LIST_FAILURE: 'GET_SAM_LIST_FAILURE',

    POST_GRID_INFO_REQUEST: 'POST_GRID_INFO_REQUEST',
    POST_GRID_INFO_SUCCESS: 'POST_GRID_INFO_SUCCESS',
    POST_GRID_INFO_FAILURE: 'POST_GRID_INFO_FAILURE',

    /*call to get the requested Live data */

    SET_CONFIRMATION_FLAG_TO_UNDEFINED: 'SET_CONFIRMATION_FLAG_TO_UNDEFINED',
    SET_BALANCE_DATA_FLAG_TO_UNDEFINED: 'SET_BALANCE_DATA_FLAG_TO_UNDEFINED',
    SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED: 'SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED',





};
