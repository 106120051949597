export const transactionActionTypes = {

    FETCH_GRID_LIST_REQUEST: 'FETCH_GRID_LIST_REQUEST',
    FETCH_GRID_LIST_SUCCESS: 'FETCH_GRID_LIST_SUCCESS',
    FETCH_GRID_LIST_FAILURE: 'FETCH_GRID_LIST_FAILURE',

    FETCH_TRANSACTION_TYPES_REQUEST: 'FETCH_TRANSACTION_TYPES_REQUEST',
    FETCH_TRANSACTION_TYPES_SUCCESS: 'FETCH_TRANSACTION_TYPES_SUCCESS',
    FETCH_TRANSACTION_TYPES_FAILURE: 'FETCH_TRANSACTION_TYPES_FAILURE',

    FETCH_GRID_BENEFITS_LIST_REQUEST: 'FETCH_GRID_BENEFITS_LIST_REQUEST',
    FETCH_GRID_BENEFITS_LIST_SUCCESS: 'FETCH_GRID_BENEFITS_LIST_SUCCESS',
    FETCH_GRID_BENEFITS_LIST_FAILURE: 'FETCH_GRID_BENEFITS_LIST_FAILURE',

    FETCH_ALL_CUSTOMER_REQUEST: 'FETCH_ALL_CUSTOMER_REQUEST',
    FETCH_ALL_CUSTOMER_SUCCESS: 'FETCH_ALL_CUSTOMER_SUCCESS',
    FETCH_ALL_CUSTOMER_FAILURE: 'FETCH_ALL_CUSTOMER_FAILURE',

    GET_TRANSACTION_HISTORY_LIST_REQUEST: 'GET_TRANSACTION_HISTORY_LIST_REQUEST',
    GET_TRANSACTION_HISTORY_LIST_SUCCESS: 'GET_TRANSACTION_HISTORY_LIST_SUCCESS',
    GET_TRANSACTION_HISTORY_LIST_FAILURE: 'GET_TRANSACTION_HISTORY_LIST_FAILURE',

    GET_ORPHAN_TRANSACTION_LIST_REQUEST: 'GET_ORPHAN_TRANSACTION_LIST_REQUEST',
    GET_ORPHAN_TRANSACTION_LIST_SUCCESS: 'GET_ORPHAN_TRANSACTION_LIST_SUCCESS',
    GET_ORPHAN_TRANSACTION_LIST_FAILURE: 'GET_ORPHAN_TRANSACTION_LIST_FAILURE',

    VALIDATE_FAILED_TRANSACTION_REQUEST: 'VALIDATE_FAILED_TRANSACTION_REQUEST',
    VALIDATE_FAILED_TRANSACTION_SUCCESS: 'VALIDATE_FAILED_TRANSACTION_SUCCESS',
    VALIDATE_FAILED_TRANSACTION_FAILURE: 'VALIDATE_FAILED_TRANSACTION_FAILURE',

    GET_HIGHEST_BALANCE_REQUEST: 'GET_HIGHEST_BALANCE_REQUEST',
    GET_HIGHEST_BALANCE_SUCCESS: 'GET_HIGHEST_BALANCE_SUCCESS',
    GET_HIGHEST_BALANCE_FAILURE: 'GET_HIGHEST_BALANCE_FAILURE',

    GET_LAST_KNOWN_BALANCE_REQUEST: 'GET_LAST_KNOWN_BALANCE_REQUEST',
    GET_LAST_KNOWN_BALANCE_SUCCESS: 'GET_LAST_KNOWN_BALANCE_SUCCESS',
    GET_LAST_KNOWN_BALANCE_FAILURE: 'GET_LAST_KNOWN_BALANCE_FAILURE',

    CREATE_TRANSACTION_INITIATE: 'CREATE_TRANSACTION_INITIATE',
    CREATE_TRANSACTION_REQUEST: 'CREATE_TRANSACTION_REQUEST',
    CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
    CREATE_TRANSACTION_FAILURE: 'CREATE_TRANSACTION_FAILURE',

    RETRY_ALL_FAILED_TRANSACTIONS_REQUEST: 'RETRY_ALL_FAILED_TRANSACTIONS_REQUEST',
    RETRY_ALL_FAILED_TRANSACTIONS_SUCCESS: 'RETRY_ALL_FAILED_TRANSACTIONS_SUCCESS',
    RETRY_ALL_FAILED_TRANSACTIONS_FAILURE: 'RETRY_ALL_FAILED_TRANSACTIONS_FAILURE',

    CANCEL_TRANSACTION_REQUEST: 'CANCEL_TRANSACTION_REQUEST',
    CANCEL_TRANSACTION_SUCCESS: 'CANCEL_TRANSACTION_SUCCESS',
    CANCEL_TRANSACTION_FAILURE: 'CANCEL_TRANSACTION_FAILURE',

    GET_SOLBOX_FIRMWARE_VERSION_REQUEST: 'GET_SOLBOX_FIRMWARE_VERSION_REQUEST',
    GET_SOLBOX_FIRMWARE_VERSION_SUCCESS: 'GET_SOLBOX_FIRMWARE_VERSION_SUCCESS',
    GET_SOLBOX_FIRMWARE_VERSION_FAILURE: 'GET_SOLBOX_FIRMWARE_VERSION_FAILURE',

    FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST: 'FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST',
    FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS: 'FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS',
    FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE: 'FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE',

    FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST: 'FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST',
    FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS: 'FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS',
    FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE: 'FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE',

    GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_REQUEST: 'GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_REQUEST',
    GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_SUCCESS: 'GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_SUCCESS',
    GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_FAILURE: 'GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_FAILURE',
    CLEAN_UP_GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION: 'CLEAN_UP_GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION'
}
