import {CALL_API} from "../../utils/actionTypes";
import {solboxActionTypes} from "./solboxActionTypes";

export const solboxActions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_SOLBOX_LIST: (solboxId, filterParams) => ({
        [CALL_API]: {
            endpoint: filterParamsHandler(`/solbox/get_solbox_list/`, null, solboxId, filterParams),
            method: "GET",
            types: [
                solboxActionTypes.GET_SOLBOX_LIST_REQUEST,
                solboxActionTypes.GET_SOLBOX_LIST_SUCCESS,
                solboxActionTypes.GET_SOLBOX_LIST_FAILURE,
            ],
        },
    }),

    CLEAN_SOLBOX_LIST: () => ({
        type: solboxActionTypes.CLEAN_UP_SOLBOX_LIST
    }),

    GET_PAGINATED_SOLBOX_LIST: (pageNumber) => ({
        [CALL_API]: {
            endpoint: (pageNumber !== undefined && pageNumber !== null) ? `/solbox/solbox_list/?page=${pageNumber}` : `/solbox/solbox_list/`,
            method: "GET",
            types: [
                solboxActionTypes.GET_PAGINATED_SOLBOX_LIST_REQUEST,
                solboxActionTypes.GET_PAGINATED_SOLBOX_LIST_SUCCESS,
                solboxActionTypes.GET_PAGINATED_SOLBOX_LIST_FAILURE,
            ],
        },
    }),

    SEARCH_SOLBOX_LIST: (solboxId, pageNumber, filterParams) => ({
        [CALL_API]: {
            endpoint:
                filterParams === null && solboxId !== "" && pageNumber === null
                    ? `/solbox/solbox_list/?solbox_id=${solboxId}`
                    : filterParamsHandler(`/solbox/solbox_list/`, pageNumber, solboxId, filterParams),
            method: "GET",
            types: [
                solboxActionTypes.GET_SEARCHED_SOLBOX_LIST_REQUEST,
                solboxActionTypes.GET_SEARCHED_SOLBOX_LIST_SUCCESS,
                solboxActionTypes.GET_SEARCHED_SOLBOX_LIST_FAILURE,
            ],
        },
    }),

    FILTER_SOLBOX_LIST: (pageNumber, solboxId, filterParams) => ({
        [CALL_API]: {
            endpoint: filterParamsHandler("/solbox/solbox_list/", pageNumber, solboxId, filterParams),
            method: "GET",
            types: [
                solboxActionTypes.GET_FILTERED_SOLBOX_LIST_REQUEST,
                solboxActionTypes.GET_FILTERED_SOLBOX_LIST_SUCCESS,
                solboxActionTypes.GET_FILTERED_SOLBOX_LIST_FAILURE,
            ],
        },
    }),

    GET_GRID_LIST_WITHOUT_PAGINATION: (filterParams) => ({
        [CALL_API]: {
            endpoint: filterParamsHandler(
                `/grid/get_list_details/`,
                null,
                "",
                filterParams
            ),
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                solboxActionTypes.GET_GRID_LIST_WITHOUT_PAGINATION_REQUEST,
                solboxActionTypes.GET_GRID_LIST_WITHOUT_PAGINATION_SUCCESS,
                solboxActionTypes.GET_GRID_LIST_WITHOUT_PAGINATION_FAILURE,
            ],
        },
    }),

    GET_GRID_OWNERS_LIST: () => ({
        [CALL_API]: {
            endpoint: `/grid/organization/list/`,
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                solboxActionTypes.GET_GRID_OWNERS_LIST_REQUEST,
                solboxActionTypes.GET_GRID_OWNERS_LIST_SUCCESS,
                solboxActionTypes.GET_GRID_OWNERS_LIST_FAILURE,
            ],
        },
    }),

    GET_MODELS_LIST: () => ({
        [CALL_API]: {
            endpoint: `/solmodel-list/`,
            method: "GET",
            types: [
                solboxActionTypes.GET_MODELS_LIST_REQUEST,
                solboxActionTypes.GET_MODELS_LIST_SUCCESS,
                solboxActionTypes.GET_MODELS_LIST_FAILURE,
            ],
        },
    }),

    GET_SOLBOX_GENERAL_DATA: (solboxGuid) => ({
        [CALL_API]: {
            endpoint: `/node/solbox_profile/?solbox_guid=${solboxGuid}`,
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                solboxActionTypes.GET_SOLBOX_GENERAL_DATA_REQUEST,
                solboxActionTypes.GET_SOLBOX_GENERAL_DATA_SUCCESS,
                solboxActionTypes.GET_SOLBOX_GENERAL_DATA_FAILURE,
            ],
        },
    }),

    GET_NODE_TYPES: () => ({
        [CALL_API]: {
            endpoint: `/node/node_type_list/`,
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                solboxActionTypes.GET_NODE_TYPES_REQUEST,
                solboxActionTypes.GET_NODE_TYPES_SUCCESS,
                solboxActionTypes.GET_NODE_TYPES_FAILURE,
            ],
        },
    }),

    POST_SOLBOX_PUBLISH_TOPIC: (payload) => ({
        [CALL_API]: {
            endpoint: `/solbox/publish_topic/`,
            method: "POST",
            body: payload,
            types: [
                solboxActionTypes.POST_SOLBOX_PUBLISH_TOPIC_REQUEST,
                solboxActionTypes.POST_SOLBOX_PUBLISH_TOPIC_SUCCESS,
                solboxActionTypes.POST_SOLBOX_PUBLISH_TOPIC_FAILURE,
            ],
        },
    }),

    GET_SOLBOX_BULK_LIVE_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/solbox/bulk_live_data/`,
            method: "POST",
            body: payload,
            types: [
                solboxActionTypes.GET_SOLBOX_BULK_LIVE_DATA_REQUEST,
                solboxActionTypes.GET_SOLBOX_BULK_LIVE_DATA_SUCCESS,
                solboxActionTypes.GET_SOLBOX_BULK_LIVE_DATA_FAILURE,
            ],
        },
    }),

    GET_SOLBOX_PERFORMANCE_METRICS_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/matrices/get_latest_metrics_data/`,
            method: "POST",
            body: payload,
            backend_slug: "performance",
            types: [
                solboxActionTypes.GET_SOLBOX_PERFORMANCE_METRICS_DATA_REQUEST,
                solboxActionTypes.GET_SOLBOX_PERFORMANCE_METRICS_DATA_SUCCESS,
                solboxActionTypes.GET_SOLBOX_PERFORMANCE_METRICS_DATA_FAILURE,
            ],
        },
    }),

    GET_SOLBOX_CONFIGURATION_HISTORY: (solboxGuid) => ({
        [CALL_API]: {
            endpoint: `/config/history/?solbox_guid=${solboxGuid}`,
            method: "GET",
            types: [
                solboxActionTypes.GET_SOLBOX_CONFIGURATION_HISTORY_REQUEST,
                solboxActionTypes.GET_SOLBOX_CONFIGURATION_HISTORY_SUCCESS,
                solboxActionTypes.GET_SOLBOX_CONFIGURATION_HISTORY_FAILURE,
            ],
        },
    }),

    FETCH_TRANSACTION_TYPES: () => ({
        [CALL_API]: {
            endpoint: `/transactions/types/`,
            method: 'GET',
            backend_slug: 'financial',
            types: [
                solboxActionTypes.FETCH_TRANSACTION_TYPES_REQUEST,
                solboxActionTypes.FETCH_TRANSACTION_TYPES_SUCCESS,
                solboxActionTypes.FETCH_TRANSACTION_TYPES_FAILURE
            ]
        }
    }),

    GET_TRANSACTION_HISTORY_LIST: (filterParams) => ({
        [CALL_API]: {
            endpoint: (filterParams === null) ? `/solbox_topups/history/` : filterParamsHandler(`/solbox_topups/history/`, null, "", filterParams),
            method: `GET`,
            types: [
                solboxActionTypes.GET_TRANSACTION_HISTORY_LIST_REQUEST,
                solboxActionTypes.GET_TRANSACTION_HISTORY_LIST_SUCCESS,
                solboxActionTypes.GET_TRANSACTION_HISTORY_LIST_FAILURE
            ]
        }
    }),

    CREATE_SOLBOX: (payload) => ({
        [CALL_API]: {
            endpoint: `/solbox/add_multi_solbox/`,
            method: 'POST',
            body: payload,
            types: [
              solboxActionTypes.CREATE_SOLBOX_REQUEST,
              solboxActionTypes.CREATE_SOLBOX_SUCCESS,
              solboxActionTypes.CREATE_SOLBOX_FAILURE,
            ]
        }
    }),

    GET_SOLBOX_FIRMWARE_VERSION: (solboxSerialNum) => ({
        [CALL_API]: {
            endpoint: `/solbox/firmware/${solboxSerialNum}`,
            method: `GET`,
            types: [
                solboxActionTypes.GET_SOLBOX_FIRMWARE_VERSION_REQUEST,
                solboxActionTypes.GET_SOLBOX_FIRMWARE_VERSION_SUCCESS,
                solboxActionTypes.GET_SOLBOX_FIRMWARE_VERSION_FAILURE
            ]
        }
    }),

    FETCH_SOLBOX_BALANCE_CHART_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_balance_history/`,
            method: `POST`,
            body: payload,
            types: [
                solboxActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST,
                solboxActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS,
                solboxActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE
            ]
        }
    }),

    FETCH_SOLBOX_I2GRID_CHART_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_i2grid_history/`,
            method: `POST`,
            body: payload,
            types: [
                solboxActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST,
                solboxActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS,
                solboxActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE
            ]
        }
    }),

    FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_data_avail_history/`,
            method: `POST`,
            body: payload,
            types: [
                solboxActionTypes.FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_REQUEST,
                solboxActionTypes.FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_SUCCESS,
                solboxActionTypes.FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_FAILURE
            ]
        }
    }),

    FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_battery_voltage_history/`,
            method: `POST`,
            body: payload,
            types: [
                solboxActionTypes.FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_REQUEST,
                solboxActionTypes.FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_SUCCESS,
                solboxActionTypes.FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_FAILURE
            ]
        }
    }),

    FETCH_SOLBOX_TRADING_CHART_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_bought_sold_history/`,
            method: `POST`,
            body: payload,
            types: [
                solboxActionTypes.FETCH_SOLBOX_TRADING_CHART_DATA_REQUEST,
                solboxActionTypes.FETCH_SOLBOX_TRADING_CHART_DATA_SUCCESS,
                solboxActionTypes.FETCH_SOLBOX_TRADING_CHART_DATA_FAILURE
            ]
        }
    }),

    FETCH_SOLBOX_FIRMWARE_LIST: (payload) => ({
        [CALL_API]: {
            endpoint: `/firmware/firmware_list/`,
            method: `GET`,
            types: [
                solboxActionTypes.FETCH_SOLBOX_FIRMWARE_LIST_REQUEST,
                solboxActionTypes.FETCH_SOLBOX_FIRMWARE_LIST_SUCCESS,
                solboxActionTypes.FETCH_SOLBOX_FIRMWARE_LIST_FAILURE
            ]
        }
    }),

    FETCH_SOLBOX_LIFE_CYCLE_STAGE: (payload) => ({
        [CALL_API]: {
            endpoint: `/solbox/life_cycle_stage_list/`,
            method: `GET`,
            types: [
                solboxActionTypes.FETCH_SOLBOX_LIFE_CYCLE_STAGE_REQUEST,
                solboxActionTypes.FETCH_SOLBOX_LIFE_CYCLE_STAGE_SUCCESS,
                solboxActionTypes.FETCH_SOLBOX_LIFE_CYCLE_STAGE_FAILURE
            ]
        }
    }),

    UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO: (payload) => ({
        [CALL_API]: {
            endpoint: `/solbox/update_solbox_data/`,
            method: `POST`,
            body: payload,
            types: [
                solboxActionTypes.UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_REQUEST,
                solboxActionTypes.UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_SUCCESS,
                solboxActionTypes.UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_FAILURE
            ]
        }
    }),

    SEND_OTA_UPDATE: (payload) => ({
        [CALL_API]: {
            endpoint: `/firmware/create_ota/`,
            method: "POST",
            body: payload,
            types: [
                solboxActionTypes.SEND_OTA_UPDATE_REQUEST,
                solboxActionTypes.SEND_OTA_UPDATE_SUCCESS,
                solboxActionTypes.SEND_OTA_UPDATE_FAILURE
            ],
        },
    })
};

const filterParamsHandler = (endpoint, pageNumber, solboxId, filterParams) => {
    let endPointWithParams = endpoint;
    if (filterParams && !(Object.entries(filterParams).length < 1)) {
        for (const [key, value] of Object.entries(filterParams)) {
            if (!endPointWithParams.includes("?")) {
                endPointWithParams += `?${key}=${value}`;
            } else {
                endPointWithParams += `&${key}=${value}`;
            }
        }
    }
    if (pageNumber && !Number.isNaN(pageNumber)) {
        if (!endPointWithParams.includes("?")) {
            endPointWithParams += `?page=${pageNumber}`;
        } else {
            endPointWithParams += `&page=${pageNumber}`;
        }
    }
    if (solboxId && solboxId.trim()) {
        if (!endPointWithParams.includes("?")) {
            endPointWithParams += `?solbox_id=${solboxId.trim()}`;
        } else {
            endPointWithParams += `&solbox_id=${solboxId.trim()}`;
        }
    }
    return endPointWithParams;
};
