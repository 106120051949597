export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    GET_OTA_UPDATE_LIST_REQUEST: 'GET_OTA_UPDATE_LIST_REQUEST',
    GET_OTA_UPDATE_LIST_FILTER_REQUEST: 'GET_OTA_UPDATE_LIST_FILTER_REQUEST',
    GET_OTA_UPDATE_LIST_SUCCESS: 'GET_OTA_UPDATE_LIST_SUCCESS',
    GET_OTA_UPDATE_LIST_FAILURE: 'GET_OTA_UPDATE_LIST_FAILURE',

    GET_OTA_UPDATE_LIST_FOR_EXPORT_REQUEST: 'GET_OTA_UPDATE_LIST_FOR_EXPORT_REQUEST',
    GET_OTA_UPDATE_LIST_FOR_EXPORT_SUCCESS: 'GET_OTA_UPDATE_LIST_FOR_EXPORT_SUCCESS',
    GET_OTA_UPDATE_LIST_FOR_EXPORT_FAILURE: 'GET_OTA_UPDATE_LIST_FOR_EXPORT_FAILURE',

    /*Getting The grid owners list*/
    GET_GRID_OWNERS_LIST_REQUEST: 'GET_GRID_OWNERS_LIST_REQUEST',
    GET_GRID_OWNERS_LIST_SUCCESS: 'GET_GRID_OWNERS_LIST_SUCCESS',
    GET_GRID_OWNERS_LIST_FAILURE: 'GET_GRID_OWNERS_LIST_FAILURE',

    GET_MODELS_LIST_REQUEST: 'GET_MODELS_LIST_REQUEST',
    GET_MODELS_LIST_SUCCESS: 'GET_MODELS_LIST_SUCCESS',
    GET_MODELS_LIST_FAILURE: 'GET_MODELS_LIST_FAILURE',

    GET_GRID_LIST_REQUEST: 'GET_GRID_LIST_REQUEST',
    GET_GRID_LIST_SUCCESS: 'GET_GRID_LIST_SUCCESS',
    GET_GRID_LIST_FAILURE: 'GET_GRID_LIST_FAILURE',

    SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_REQUEST: 'SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_REQUEST',
    SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_SUCCESS: 'SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_SUCCESS',
    SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_FAILURE: 'SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_FAILURE',

    RETRY_OTA_REQUEST: 'RETRY_OTA_REQUEST',
    RETRY_OTA_SUCCESS: 'RETRY_OTA_SUCCESS',
    RETRY_OTA_FAILURE: 'RETRY_OTA_FAILURE',

    COLLECT_SOLBOX_OTA_DETAILS_REQUEST: 'COLLECT_SOLBOX_OTA_DETAILS_REQUEST',
    COLLECT_SOLBOX_OTA_DETAILS_SUCCESS: 'COLLECT_SOLBOX_OTA_DETAILS_SUCCESS',
    COLLECT_SOLBOX_OTA_DETAILS_FAILURE: 'COLLECT_SOLBOX_OTA_DETAILS_FAILURE'
};
