/*Initial State for grid*/
import { replacementActionTypes as actionTypes } from "./replacementActionTypes";
import {toast} from "react-toastify";
import {exportGridListToCsv, requestCycle} from "../grids/utils";

/*Initial State for grid*/
const initialState= {
    // Both of the 'isLoading' and 'retryLoading' has been used at the "retry" process
    isLoading: false,
    retryLoading: false,
    dataIsBeingPreparedForCsv: false,
    replacementListForExport: undefined
};

export const replacementReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_REQUEST:
            return {
                ...state,
                modalLoading: true,
                serverError: undefined,
                additionalDataNotFound: undefined,
            }
        case actionTypes.FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_SUCCESS:
            return {
                ...state,
                modalLoading: false,
                replacementInfo: action.payload,
                additionalDataNotFound: false,
                serverError: false,
            }
        case actionTypes.FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_FAILURE:
            return {
                ...state,
                modalLoading: false,
                replacementInfo: undefined,
                additionalDataNotFound: true,
                serverError: false,
            }

        case actionTypes.NODES_UPDATE_SOLBOX_CONFIGURATION_REQUEST:
            return {
                ...state,
                disableButton: true,
                serverError: false,
                configurationCreated: undefined,
            }
        case actionTypes.NODES_UPDATE_SOLBOX_CONFIGURATION_SUCCESS:
            toast.success("Configuration has been successfully sent to SOLbox")
            return {
                ...state,
                disableButton: false,
                configurationCreated: true,
                serverError: false,
            }
        case actionTypes.NODES_UPDATE_SOLBOX_CONFIGURATION_FAILURE:
            toast.error("Configuration Could Not be Sent to SOLbox!")
            return {
                ...state,
                disableButton: false,
                configurationCreated: false,
                serverError: false,
            }

        case actionTypes.CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_REQUEST:
            return {
                ...state,
                serialNumberCheckSuccessful: undefined,
                serverError: false,
                disableButton: true,
            }
        case actionTypes.CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_SUCCESS:
            toast.success("Validation Successful. You can proceed to next step")
            return {
                ...state,
                serialNumberCheckSuccessful: true,
                serverError: false,
                disableButton: false,
            }
        case actionTypes.CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_FAILURE:
            toast.error("SOLboxes are not in right status to be replaced. Please contact with admin")
            return {
                ...state,
                serialNumberCheckSuccessful: false,
                serverError: false,
                disableButton: false,
            }

        case actionTypes.REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_REQUEST:
            return {
                ...state,
                serialNumberCheckSuccessful: undefined,
                replacementSuccessful: undefined,
                serverError: false,
                disableButton: true,
            }
        case actionTypes.REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_SUCCESS:
            toast.success("SOLbox data has been replaced successfully.")
            return {
                ...state,
                replacementSuccessful: true,
                serialNumberCheckSuccessful: undefined,
                replacementId: action.payload.replacement_id,
                serverError: false,
                disableButton: false,
            }
        case actionTypes.REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_FAILURE:
            toast.error("Could not replace SOLbox data.")
            return {
                ...state,
                serialNumberCheckSuccessful: undefined,
                replacementSuccessful: false,
                serverError: false,
                disableButton: false,
            }

        case actionTypes.SEND_CONFIG_FOR_REPLACEMENT_REQUEST:

            return {
                ...state,
                configUpdateSuccessful: undefined,
                replacementSuccessful: undefined,
                retryLoading: true,
                serverError: false,
                disableButton: true,
            }
        case actionTypes.SEND_CONFIG_FOR_REPLACEMENT_SUCCESS:
            toast.success("Configuration has been successfully sent to new SOLbox")
            return {
                ...state,
                configUpdateSuccessful: true,
                replacementSuccessful: undefined,
                retryLoading: true, // We are keeping this value "true" as normally at the time of retry, we send balance transfer just after the config transfer, in case of "Not initiated"
                serverError: false,
                disableButton: false,
            }
        case actionTypes.SEND_CONFIG_FOR_REPLACEMENT_FAILURE:
            toast.error("Could not connect with the SOLbox. Please try sending configuration manually")
            return {
                ...state,
                configUpdateSuccessful: false,
                replacementSuccessful: undefined,
                retryLoading: false,
                serverError: false,
                disableButton: false,
            }

        case actionTypes.SEND_REIMBURESEMENT_FOR_REPLACEMENT_REQUEST:

            return {
                ...state,
                reimbursementSuccessful: undefined,
                configUpdateSuccessful: undefined,
                retryLoading: true,
                serverError: false,
                disableButton: true,
            }
        case actionTypes.SEND_REIMBURESEMENT_FOR_REPLACEMENT_SUCCESS:
            toast.success("Balance has been successfully sent to the new SOLbox")
            return {
                ...state,
                reimbursementSuccessful: true,
                configUpdateSuccessful: undefined,
                retryLoading: false,
                serverError: false,
                disableButton: false,
            }
        case actionTypes.SEND_REIMBURESEMENT_FOR_REPLACEMENT_FAILURE:
            toast.error("Could not connect with the new SOLbox. Please try sending reimbursement manually")
            return {
                ...state,
                configUpdateSuccessful: undefined,
                reimbursementSuccessful: false,
                retryLoading: false,
                serverError: false,
                disableButton: false,
            }

        case actionTypes.SET_REIMBURSEMENT_SUCCESSFUL_TO_UNDEFINED:

            return {
                ...state,
                reimbursementSuccessful: undefined,

            }
        case actionTypes.SET_REPLACEMENT_PHASES_TO_UNDEFINED:

            return {
                ...state,
                retryLoading2: undefined,
                reimbursementSuccessful: undefined,
                replacementSuccessful: undefined,
                serialNumberCheckSuccessful: undefined,
                configUpdateSuccessful: undefined,
            };
        case actionTypes.SET_CONFIRMATION_FLAG_TO_UNDEFINED:

            return {
                ...state,
                promotionSuccess: undefined,
                cashOutSuccess: undefined,
                requestOngoing: undefined,
                balanceCheckSuccess: undefined,
            }
        case actionTypes.SET_BALANCE_DATA_FLAG_TO_UNDEFINED:

            return {
                ...state,
                balanceData: undefined,
            }

        case actionTypes.GET_GRID_OWNERS_LIST_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_GRID_OWNERS_LIST_SUCCESS:
            return {
                ...state,
                filterDropdownLoading: false,
                gridOwnerList: action.payload,
            };
        case actionTypes.GET_GRID_OWNERS_LIST_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
                statusCode: action.payload.status,
            };

        case actionTypes.GET_MODELS_LIST_REQUEST:
            return {
                ...state,
                modelSelectLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_MODELS_LIST_SUCCESS:
            return {
                ...state,
                modelList: action.payload,
                modelSelectLoading: false,
                statusCode: undefined,
            };
        case actionTypes.GET_MODELS_LIST_FAILURE:
            return {
                ...state,
                modelSelectLoading: false,
                statusCode: action.payload.status,
            };

        case actionTypes.GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
            };
        case actionTypes.GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_SUCCESS:
            return {
                ...state,
                gridList: Array.isArray(action.payload)?action.payload:[],
                filterDropdownLoading: undefined,
            };
        case actionTypes.GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_FAILURE:
            return {
                ...state,
                filterDropdownLoading: undefined,
                statusCode: action.payload.status,
            };

        case actionTypes.GET_REPLACEMENT_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.GET_REPLACEMENT_LIST_WITH_SEPARATE_LOADER_REQUEST:
            return {
                ...state,
                filterTriggeredLoading: true,
            };
        case actionTypes.GET_REPLACEMENT_LIST_SUCCESS:
            return {
                ...state,
                replacementList: action.payload,
                isLoading: false,
                filterTriggeredLoading: false,
            };
        case actionTypes.GET_REPLACEMENT_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                filterTriggeredLoading: false,
            };

        case actionTypes.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_REQUEST:
            return {
                ...state,
                dataIsBeingPreparedForCsv: true,
                replacementListForExport: undefined
            };
        case actionTypes.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_SUCCESS:
            return {
                ...state,
                replacementListForExport: action.payload,
                dataIsBeingPreparedForCsv: false
            };
        case actionTypes.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_FAILURE:
            toast.error('Failed to collect data for export!')
            return {
                ...state,
                dataIsBeingPreparedForCsv: false
            };

        case actionTypes.GET_NODE_TYPE_REQUEST:
            return {
                ...state,
                filterDropdownLoading: true,
            };
        case actionTypes.GET_NODE_TYPE_SUCCESS:
            return {
                ...state,
                nodeTypeList: action.payload,
                filterDropdownLoading: false,
            };
        case actionTypes.GET_NODE_TYPE_FAILURE:
            return {
                ...state,
                filterDropdownLoading: false,
            };

        case actionTypes.REPLACEMENT_RETRY_REQUEST:
            return {
                ...state,
                retrySuccess: undefined,
                retryLoading: true,
            };
        case actionTypes.REPLACEMENT_RETRY_SUCCESS:
            toast.success('Retry request successful!');
            return {
                ...state,
                retrySuccess: requestCycle.success,
                retryLoading: false,
            };
        case actionTypes.REPLACEMENT_RETRY_FAILURE:
            toast.error('Retry request failed!')
            return {
                ...state,
                retrySuccess: requestCycle.failed,
                retryLoading: false,
            };

        case actionTypes.UPDATE_SOLBOX_BALANCE_REQUEST:
            return {
                ...state,
                balanceUpdateRequestPending: true,
                balanceUpdateSuccessful: false
            }
        case actionTypes.UPDATE_SOLBOX_BALANCE_SUCCESS:
            toast.success("SOLbox balance has been updated!")
            return {
                ...state,
                balanceUpdateRequestPending: false,
                balanceUpdateSuccessful: true
            }
        case actionTypes.UPDATE_SOLBOX_BALANCE_FAILURE:
            toast.error("Balance update has failed!")
            return {
                ...state,
                balanceUpdateRequestPending: false,
                balanceUpdateSuccessful: false
            }

        default:
            return state;
    }
};
