import React from "react";
import {Badge} from "react-bootstrap";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {CustomerType, OnlineStatus} from "../../../customer/views/components";

export const customerListColumns=[


    {
        field: 'id',
        title: "",
        emptyValue: ()=>{
            return "N/A"
        },
        width: '2.5rem',
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0'
        },
        headerStyle: {
            paddingLeft: '0',
            color: '#8C8C9B'
        },
    },
    {
        field: 'solboxRender',
        title: "ID",
        // render: rowData =><>
        //       <SolTypography.SubTitle primary>{rowData.solbox_serial_number}&nbsp;&nbsp;<OnlineStatus online={rowData.status==='online'}/></SolTypography.SubTitle>
        //       <SolTypography.SubTitle >FW: {rowData?.solbox_firmware || "N/A"}</SolTypography.SubTitle>
        //       <SolTypography.SubTitle >{rowData?.solbox_model || "N/A"}</SolTypography.SubTitle>
        // </>,
        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0'
        },
        headerStyle: {
            color: '#8C8C9B',
            paddingLeft: '0',
        },
    },
    {
        field: 'nameRender',
        title: "Name",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        // render: rowData=> <>
        //         <SolTypography.SubTitle primary>Jamal Ahmed </SolTypography.SubTitle>
        //         <SolTypography.SubTitle >+88013456978</SolTypography.SubTitle>
        //     </>
        // ,
        headerStyle: {
            color: '#8C8C9B'
        },
    },
    {
        field: 'modeRender',
        title: "Mode",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        headerStyle: {
            color: '#8C8C9B',
            textAlign: 'center'
        },
        cellStyle: {
            textAlign: 'center'
        },
    },
    {
        field: 'batteryRender',
        title: "Batt (V)",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        headerStyle: {
            color: '#8C8C9B',
            textAlign: 'right'
        },
        cellStyle: {
            textAlign: 'right'
        },
    },
    {
        field: 'iToGridRender',
        title: <>I2Grid<br/>(mA)</>,
        emptyValue: ()=>{
            return "N/A"
        },
        sorting: false,
        headerStyle: {
            color: '#8C8C9B',
            textAlign: 'right'
        },
        cellStyle: {
            textAlign: 'right'
        },
    },
    {
        field: 'balanceRender',
        title: <>Balance<br/>(Tk)</>,
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        headerStyle: {
            color: '#8C8C9B',
            textAlign: 'right'
        },
        cellStyle: {
            textAlign: 'right'
        },
    },
    {
        field: 'lastDataRecievedRender',
        title: <>Last Data<br/>Received On</>,
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        headerStyle: {
            color: '#8C8C9B',
            textAlign: 'left'
        },
        cellStyle: {
            textAlign: 'left'
        },
    },
]
