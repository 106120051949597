import React, { useState, useEffect } from "react";
import {solboxActions} from "../../solboxActions";
import {connect} from "react-redux";
import SolDaterangePicker from "../../../../components/SolDaterangePicker";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SolChart from '../../../../components/SolChart';
import moment from "moment";
import { Spinner } from 'react-bootstrap';
import temp from '../../../../../temp.json';
import {toast} from "react-toastify";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const SolboxProfileTrading = ({ 
    solboxId,
    isLoading,
    statusCode,
    solboxData,
    solChartBalanceData, 
    solChartI2GridData,
    solChartAvailabilityData,
    solChartBatteryVoltageData,
    solChartTradingData,
    GET_SOLBOX_FIRMWARE_VERSION,
    FETCH_SOLBOX_BALANCE_CHART_DATA,
    FETCH_SOLBOX_I2GRID_CHART_DATA,
    FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA,
    FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA,
    FETCH_SOLBOX_TRADING_CHART_DATA,
    ...props
}) => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startingDate, setStartingDate] = useState(moment().subtract(6, 'days').format("YYYY-MM-DD").concat(' 00:00:00'));
    const [endingDate, setEndingDate] = useState(moment().format("YYYY-MM-DD").concat(' 23:59:59'));
    const [displayStartDate, setDisplayStartDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [dateRange, setDateRange] = useState("");
    const [customerRegDate, setCustomerRegDate] = useState(null);
    const [balanceChartDataSeries, setBalanceChartDataSeries] = useState(null);
    const [i2GridChartDataSeries, setI2GridChartDataSeries] = useState(null);
    const [dataAvailabilityChartDataSeries, setDataAvailabilityChartDataSeries] = useState(null);
    const [batteryVoltageChartDataSeries, setBatteryVoltageChartDataSeries] = useState(null);
    const [tradingChartDataSeries, setTradingChartDataSeries] = useState(null);
    const [performanceMode, setPerformanceMode] = useState(false);
    const [dataWithNullValue, setDataWithNullValue] = useState([]);
    const [dataWithNoNullValue, setDataWithNoNullValue] = useState([]);

    const handleDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        const temp=moment(startDate);
        if (endDate && endDate.diff(startDate, 'days')>7){
            setEndDate(temp.add(7, 'days'));
            toast.info(`Currently more than 7 days can not be selected. The filter has been set from ${new DateTimeFormat(startDate).localDisplayWithoutTime} to ${new DateTimeFormat(temp).localDisplayWithoutTime} `);
        }else {

            setEndDate(endDate);
        }
    };

    const handlePerformanceMode=(value)=>{
        setPerformanceMode((prev)=>{
            return !prev
        })
    }

    useEffect(() => {
        if (solboxId) {
            GET_SOLBOX_FIRMWARE_VERSION(solboxId);
        }
    }, [solboxId]);

    useEffect(() => {
        if (solboxId &&
            startingDate &&
            endingDate
        ) {
            FETCH_SOLBOX_I2GRID_CHART_DATA(
                {
                    //"api_name": `solbox_i2grid_values`,
                    "solbox_serial_number": Number.parseInt(solboxId, 10),
                    "date_start": startingDate,
                    "date_end": endingDate
                }
            );

            FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA(
                {
                    //"api_name": `solbox_i2grid_values`,
                    "solbox_serial_number": Number.parseInt(solboxId, 10),
                    "date_start": startingDate,
                    "date_end": endingDate
                }
            );

            FETCH_SOLBOX_BALANCE_CHART_DATA(
                {
                    //"api_name": `solbox_balances`,
                    "solbox_serial_number": Number.parseInt(solboxId, 10),
                    "date_start": startingDate,
                    "date_end": endingDate
                }
            );
        }
    }, [startingDate, endingDate, solboxId]);

    useEffect(() => {
        if (solboxData && solboxData.customer_reg_date) {
            setCustomerRegDate(solboxData.customer_reg_date);
        }
    }, [solboxData]);
    
    const handleDateRangeValidation = (day) => {
        if (customerRegDate) {
            return day.startOf('day').isAfter(moment()) || day.startOf('day').isBefore(moment(customerRegDate));
        }
        return day.startOf('day').isAfter(moment());
    }

    useEffect(() => {
        if (
        startDate !== undefined &&
        endDate !== undefined &&
        startDate !== null &&
        endDate !== null
        ) {
            setDateRange(
                moment(startDate._d)
                .format("LL")
                .concat(" - ", moment(endDate._d).format("LL"))
            );
            setStartingDate(moment(startDate._d).format("YYYY-MM-DD").concat(' 00:00:00'));
            setEndingDate(moment(endDate._d).format("YYYY-MM-DD").concat(' 23:59:59'));
            setDisplayStartDate(moment(startDate._d).format("DD/MM/YYYY"));
            setDisplayEndDate(moment(endDate._d).format("DD/MM/YYYY"));
        } else {
            setDisplayStartDate(moment().subtract(6, 'days').format("DD/MM/YYYY"));
            setDisplayEndDate(moment().format("DD/MM/YYYY"));
        }
    }, [startDate, endDate]);


    useEffect(() => {
        if (solChartBalanceData && solChartBalanceData.length >= 0) {
            let balanceChartDataArr = [{
                name: "Balance",
                data: []
            }];
            solChartBalanceData.forEach((element, index) => {
                if (element.solbox_time &&
                    element.balance !== undefined &&
                    element.created_at &&
                    element.solbox_serial_number
                ) {

                    if (Date.parse(element.solbox_time) ){

                        balanceChartDataArr[0].data.push({
                            x: Date.parse(element.solbox_time)+21600000,
                            y: element.balance,
                            z: moment(element.created_at).add(6, 'hours').format("DD-MM-YYYY h:mm:ss a")
                        });
                    }

                }
            });
            if (balanceChartDataArr[0].data.length >= 0) {
                const temp = [...balanceChartDataArr[0].data ].sort(compare);
                const timeDiff = props.isEsp?900000:120000;
                /*This is the for adding null value*/
                // const temp2=[];
                // setDataWithNoNullValue(temp)
                // temp.forEach((item, index)=>{
                //     if (index< temp.length-1 && Math.abs(item.x - temp[index+1].x)>900000){
                //         console.log(Math.abs(item.x - temp[index+1].x))
                //         temp2.push(item);
                //         temp2.push({
                //             x: item.x+900000,
                //             y: null,
                //             z: "Data Not Received"
                //         })
                //     }else{
                //         temp2.push(item);
                //     }
                // });
                /*This is the for adding null value*/

                // setDataWithNullValue(temp2);
                setBalanceChartDataSeries([{
                    name: "Balance",
                    data: temp
                }]);

            }
        }
    }, [solChartBalanceData]);

    // useEffect(()=>{
    //     if (!performanceMode){
    //         setBalanceChartDataSeries([{
    //             name: "Balance",
    //             data: dataWithNoNullValue
    //         }]);
    //     }else{
    //         setBalanceChartDataSeries([{
    //             name: "Balance",
    //             data: dataWithNullValue
    //         }]);
    //     }
    // },[performanceMode])

    const compare=(a, b)=> {
        const firstElement = a.x;
        const secondElement = b.x;

        let comparison = 0;
        if (firstElement > secondElement) {
            comparison = 1;
        } else if (firstElement < secondElement) {
            comparison = -1;
        }
        return comparison;
    }


    // useEffect(() => {
    //     if (solboxId &&
    //         startingDate &&
    //         endingDate
    //     ) {
    //         FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_i2grid_values`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": startingDate,
    //                 "date_end": endingDate
    //             }
    //         );
    //     } else {
    //         FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_i2grid_values`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": moment().subtract(6, 'days').format("YYYY-MM-DD"),
    //                 "date_end": moment().format("YYYY-MM-DD")
    //             }
    //         );
    //     }
    // }, [startingDate, endingDate, solboxId]);
    //
    //
    //
    // useEffect(() => {
    //     if (solboxId &&
    //         startingDate &&
    //         endingDate
    //     ) {
    //         FETCH_SOLBOX_BALANCE_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_balances`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": startingDate,
    //                 "date_end": endingDate
    //             }
    //         );
    //     } else {
    //         FETCH_SOLBOX_BALANCE_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_balances`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": moment().subtract(6, 'days').format("YYYY-MM-DD"),
    //                 "date_end": moment().format("YYYY-MM-DD")
    //             }
    //         );
    //     }
    // }, [startingDate, endingDate, solboxId]);

    useEffect(() => {
        if (solChartI2GridData && solChartI2GridData.length >= 0) {
            let i2GridChartDataArr = [{
                name: "I2Grid",
                data: []
            }];
            solChartI2GridData.forEach((element, index) => {
                if (element.solbox_time && 
                    element.i_to_grid &&
                    element.created_at &&
                    element.solbox_serial_number &&
                    solboxId &&
                    element.solbox_serial_number == solboxId
                ) {
                    i2GridChartDataArr[0].data.push({
                        // verboseTime: new DateTimeFormat(Date.parse(element.solbox_time)+21600000).localDisplayTime,
                        x: Date.parse(element.solbox_time)+21600000,
                        y: element.i_to_grid,
                        z: moment(element.created_at).add(6, 'hours').format("D MMM, YYYY h:mm:ss a")
                    });
                }
            });
            if (i2GridChartDataArr[0].data.length >= 0) {
                // setI2GridChartDataSeries(i2GridChartDataArr);
                const temp= [...i2GridChartDataArr[0].data].sort(compare);

                setI2GridChartDataSeries([{
                    name: "I2Grid",
                    data: temp
                }]);
            }
        }
    }, [solChartI2GridData]);



    useEffect(() => {
        if (solChartAvailabilityData && solChartAvailabilityData.length >= 0) {
            let dataAvailabilityChartDataArr = [{
                name: "Data Availability Per Hour",
                data: []
            }];
            solChartAvailabilityData.forEach((element, index) => {
                if (element.solbox_date && 
                    element.data_avail &&
                    element.solbox_serial_number &&
                    solboxId &&
                    element.solbox_serial_number == solboxId
                ) {
                    dataAvailabilityChartDataArr[0].data.push({
                        x: Date.parse(element.solbox_date)+21600000,
                        y: element.data_avail,
                        z: moment(new DateTimeFormat(element.solbox_date)).format("DD MMM, YYYY"),
                    });
                }
            });
            if (dataAvailabilityChartDataArr[0].data.length >= 0) {
                // setDataAvailabilityChartDataSeries(dataAvailabilityChartDataArr);
                const temp= [...dataAvailabilityChartDataArr[0].data].sort(compare);
                setDataAvailabilityChartDataSeries([{
                    name: "Data Availability Per Hour",
                    data: temp
                }]);
            }
        }
    }, [solChartAvailabilityData]);

    // useEffect(() => {
    //     if (solboxId &&
    //         startingDate &&
    //         endingDate
    //     ) {
    //         FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_i2grid_values`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": startingDate,
    //                 "date_end": endingDate
    //             }
    //         );
    //     } else {
    //         FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_i2grid_values`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": moment().subtract(6, 'days').format("YYYY-MM-DD"),
    //                 "date_end": moment().format("YYYY-MM-DD")
    //             }
    //         );
    //     }
    // }, [startingDate, endingDate, solboxId]);

    // useEffect(() => {
    //     if (solChartBatteryVoltageData && solChartBatteryVoltageData.length !== 0) {
    //         let batteryVoltageChartDataArr = [{
    //             name: "Battery Voltage",
    //             data: []
    //         }];
    //         solChartBatteryVoltageData.forEach((element, index) => {
    //             if (element.solbox_time &&
    //                 element.v_battery &&
    //                 element.created_at &&
    //                 element.solbox_serial_number &&
    //                 solboxId &&
    //                 element.solbox_serial_number == solboxId
    //             ) {
    //                 batteryVoltageChartDataArr[0].data.push({
    //                     x: Date.parse(element.solbox_time),
    //                     y: element.v_battery,
    //                     z: moment(element.created_at).format("DD-MM-YYYY h:mm:ss a")
    //                 });
    //             }
    //         });
    //         if (batteryVoltageChartDataArr[0].data.length !== 0) {
    //             setBatteryVoltageChartDataSeries(batteryVoltageChartDataArr);
    //         }
    //     }
    // }, [solChartBatteryVoltageData]);

    // useEffect(() => {
    //     if (solboxId &&
    //         startingDate &&
    //         endingDate
    //     ) {
    //         FETCH_SOLBOX_TRADING_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_i2grid_values`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": startingDate,
    //                 "date_end": endingDate
    //             }
    //         );
    //     } else {
    //         FETCH_SOLBOX_TRADING_CHART_DATA(
    //             {
    //                 //"api_name": `solbox_i2grid_values`,
    //                 "solbox_serial_number": Number.parseInt(solboxId, 10),
    //                 "date_start": moment().subtract(6, 'days').format("YYYY-MM-DD"),
    //                 "date_end": moment().format("YYYY-MM-DD")
    //             }
    //         );
    //     }
    // }, [startingDate, endingDate, solboxId]);







    // useEffect(()=>{
    //     let arr= [];
    //     let dups=[];
    //     temp.forEach((item)=>{
    //         if (arr.indexOf(item)>=0) {
    //             alert('asdasd')
    //             dups.push(item);
    //             return
    //         }
    //         arr.push(item)
    //     })
    //     console.log(dups)
    // },[])

    // console.log(startingDate);
    // console.log(endingDate);
    // console.log(i2GridChartDataSeries);
    // console.log(dataAvailabilityChartDataSeries);
    // console.log(batteryVoltageChartDataSeries);
    // console.log(tradingChartDataSeries);

    const [loadContent, setLoadContent] = useState(true);

    useEffect(() => {
        if (isLoading === true) {
            setLoadContent(true);
        }
    }, [isLoading]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isLoading === false) {
                setLoadContent(false);
            }
        }, 3000);
        
        return () => { 
            clearTimeout(timeout);
        };
    }, [isLoading]);

    return (
        <React.Fragment>
            {loadContent &&
                <div className="row">
                    <div className={`col-12 mt-5 mb-5`}>
                        <div className="text-center">
                            <Spinner animation="border" variant="warning"></Spinner>
                        </div>
                        <div className="text-center list-bold-text mt-5 ml-5 mr-5">{`FETCHING DATA...`}</div>
                    </div>
                </div>
            }
            {!loadContent &&
            <div className={`row`}>
                <div className={`col-12`}>
                    <div className={`row`}>
                        <div className={`col-sm-3 mb-2`}>
                            <SolDaterangePicker
                                dateRangelabel={`Select Duration`}
                                startDate={startDate}
                                endDate={endDate}
                                dateRange={dateRange}
                                handleDateRange={handleDateRange}
                                dateRangeValidation={handleDateRangeValidation}
                            />
                        </div>
                    </div>
                </div>
                {/*<div className={`col-12`}>*/}
                {/*    <div className={`row`}>*/}
                {/*        <div className={`col-sm-3 mb-2`}>*/}
                {/*            <FormControlLabel*/}
                {/*                control={*/}
                {/*                    <Checkbox*/}
                {/*                        checked={performanceMode}*/}
                {/*                        onChange={handlePerformanceMode}*/}
                {/*                        name="checkedB"*/}
                {/*                        color="secondary"*/}
                {/*                    />*/}
                {/*                }*/}
                {/*                label="Performance Mode"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            }
            {!loadContent && balanceChartDataSeries &&
                <div className="row">
                    <div className={`col-sm-10 mt-5 mb-5`}>
                        <SolChart
                            dataSeries={balanceChartDataSeries}
                            xaxisDataType={'datetime'}
                            yaxisLineColor={['#53B7E8']}
                            mainTitle={`Balance`}
                            displayStartDate={displayStartDate}
                            displayEndDate={displayEndDate}
                            sideTitle={`Tk`}
                            showStroke={false}
                            min={0}
                        />
                    </div>
                </div>
            }
            {!loadContent && i2GridChartDataSeries &&
                <div className="row">
                    <div className={`col-sm-10 mt-5 mb-5`}>
                        <SolChart 
                            dataSeries={i2GridChartDataSeries} 
                            xaxisDataType={'datetime'}
                            yaxisLineColor={['#53B7E8']}
                            mainTitle={`I2Grid`}
                            displayStartDate={displayStartDate}
                            displayEndDate={displayEndDate}
                            sideTitle={`Current (mA)`}
                            showStroke={false}
                        />
                    </div>
                </div>
            }
            {!loadContent && dataAvailabilityChartDataSeries &&
                <div className="row">
                    <div className={`col-sm-10 mt-5 mb-5`} >
                        <SolChart 
                            dataSeries={dataAvailabilityChartDataSeries} 
                            xaxisDataType={'datetime'}
                            yaxisLineColor={['#53B7E8']}
                            mainTitle={`Data Availability Per Hour`}  
                            displayStartDate={displayStartDate}
                            displayEndDate={displayEndDate}
                            sideTitle={`Number of Datapoints`}
                            dateTimeFormatter={(val)=>(new DateTimeFormat(val).localDisplayWithoutTime)}
                            showStroke={false}
                        />
                    </div>
                </div>
            }
            {/*{!loadContent && batteryVoltageChartDataSeries &&*/}
            {/*    <div className="row">*/}
            {/*        <div className={`col-sm-10 mt-5 mb-5`} style={{ paddingLeft: "4rem" }}>*/}
            {/*            <SolChart */}
            {/*                dataSeries={batteryVoltageChartDataSeries} */}
            {/*                xaxisDataType={'datetime'}*/}
            {/*                yaxisLineColor={['#53B7E8']}*/}
            {/*                mainTitle={`Battery Voltage`}  */}
            {/*                displayStartDate={displayStartDate}*/}
            {/*                displayEndDate={displayEndDate}*/}
            {/*                sideTitle={`Voltage (Volt)`} */}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{!loadContent && tradingChartDataSeries &&*/}
            {/*    <div className="row">*/}
            {/*        <div className={`col-sm-10 mt-5 mb-5`} style={{ paddingLeft: "4rem" }}>*/}
            {/*            <SolChart */}
            {/*                dataSeries={tradingChartDataSeries} */}
            {/*                xaxisDataType={'datetime'}*/}
            {/*                yaxisLineColor={['#53B7E8', '#6E838E']}*/}
            {/*                mainTitle={`Trading`}  */}
            {/*                displayStartDate={displayStartDate}*/}
            {/*                displayEndDate={displayEndDate}*/}
            {/*                sideTitle={`wH`} */}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.solboxReducer.isLoading,
        statusCode: state.solboxReducer.statusCode,
        solboxData: state.solboxReducer.solboxData,
        solChartBalanceData: state.solboxReducer.solChartBalanceData,
        solChartI2GridData: state.solboxReducer.solChartI2GridData,
        solChartAvailabilityData: state.solboxReducer.solChartAvailabilityData,
        solChartBatteryVoltageData: state.solboxReducer.solChartBatteryVoltageData,
        solChartTradingData: state.solboxReducer.solChartTradingData
    };
};

export default connect(mapStateToProps, solboxActions)(SolboxProfileTrading);
