import { CALL_API } from "../../utils/actionTypes";
import { transactionActionTypes } from "./transactionActionTypes";

export const transactionActions = {

    FETCH_GRID_LIST: (queryParam) => ({
        [CALL_API]: {
            endpoint: queryParam ? `/grid/get_list_details/?organization_guid=${queryParam}` : `/grid/get_list_details/`,
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                transactionActionTypes.FETCH_GRID_LIST_REQUEST,
                transactionActionTypes.FETCH_GRID_LIST_SUCCESS,
                transactionActionTypes.FETCH_GRID_LIST_FAILURE
            ]
        }
    }),
    FETCH_TRANSACTION_TYPES: () => ({
        [CALL_API]: {
            endpoint: `/transactions/types/`,
            method: 'GET',
            backend_slug: 'financial',
            types: [
                transactionActionTypes.FETCH_TRANSACTION_TYPES_REQUEST,
                transactionActionTypes.FETCH_TRANSACTION_TYPES_SUCCESS,
                transactionActionTypes.FETCH_TRANSACTION_TYPES_FAILURE
            ]
        }
    }),
    FETCH_GRID_BENEFITS_LIST: () => ({
        [CALL_API]: {
            endpoint: `/benefit/`,
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                transactionActionTypes.FETCH_GRID_BENEFITS_LIST_REQUEST,
                transactionActionTypes.FETCH_GRID_BENEFITS_LIST_SUCCESS,
                transactionActionTypes.FETCH_GRID_BENEFITS_LIST_FAILURE
            ]
        }
    }),
    FETCH_ALL_CUSTOMERS: (filterParams) => ({
        [CALL_API]: {
            endpoint: (filterParams === null) ? `/grid/customers/` : filterParamsHandler(`/grid/customers/`, "", filterParams),
            backend_slug: 'grid_backend',
            method: 'GET',
            types: [
                transactionActionTypes.FETCH_ALL_CUSTOMER_REQUEST,
                transactionActionTypes.FETCH_ALL_CUSTOMER_SUCCESS,
                transactionActionTypes.FETCH_ALL_CUSTOMER_FAILURE
            ]
        }
    }),
    GET_TRANSACTION_HISTORY_LIST: (solboxId, filterParams) => ({
        [CALL_API]: {
            endpoint: (solboxId === "" && filterParams === null) ? `/solbox_topups/history/` : filterParamsHandler(`/solbox_topups/history/`, solboxId, filterParams),
            method: `GET`,
            types: [
                transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_REQUEST,
                transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_SUCCESS,
                transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_FAILURE
            ]
        }
    }),
    GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION: (solboxId, filterParams) => ({
        [CALL_API]: {
            endpoint: (solboxId === "" && filterParams === null) ? `/solbox_topups/history/` : filterParamsHandler(`/solbox_topups/history/`, solboxId, filterParams),
            method: `GET`,
            types: [
                transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_REQUEST,
                transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_SUCCESS,
                transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_FAILURE
            ]
        }
    }),
    GET_ORPHAN_TRANSACTION_LIST: (filterParams) => ({
        [CALL_API]: {
            endpoint: (filterParams === null) ? `/transactions/orphan/` : filterParamsHandler(`/transactions/orphan/`, "", filterParams),
            method: `GET`,
            backend_slug: 'financial',
            types: [
                transactionActionTypes.GET_ORPHAN_TRANSACTION_LIST_REQUEST,
                transactionActionTypes.GET_ORPHAN_TRANSACTION_LIST_SUCCESS,
                transactionActionTypes.GET_ORPHAN_TRANSACTION_LIST_FAILURE
            ]
        }
    }),
    VALIDATE_FAILED_TRANSACTION: (reference, solboxSerial) => ({
        [CALL_API]: {
            endpoint: `/solbox_topups/failure_check/?reference=${reference}&solbox_serial=${solboxSerial}`,
            method: `GET`,
            types: [
                transactionActionTypes.VALIDATE_FAILED_TRANSACTION_REQUEST,
                transactionActionTypes.VALIDATE_FAILED_TRANSACTION_SUCCESS,
                transactionActionTypes.VALIDATE_FAILED_TRANSACTION_FAILURE
            ]
        }
    }),
    GET_HIGHEST_BALANCE: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_highest_balance/`,
            method: `POST`,
            body: payload,
            types: [
                transactionActionTypes.GET_HIGHEST_BALANCE_REQUEST,
                transactionActionTypes.GET_HIGHEST_BALANCE_SUCCESS,
                transactionActionTypes.GET_HIGHEST_BALANCE_FAILURE
            ]
        }
    }),
    GET_LAST_KNOWN_BALANCE: (solboxSerial) => ({
        [CALL_API]: {
            endpoint: `/transactions/get_last_known_balance/?solbox_serial=${solboxSerial}`,
            method: `GET`,
            backend_slug: 'financial',
            types: [
                transactionActionTypes.GET_LAST_KNOWN_BALANCE_REQUEST,
                transactionActionTypes.GET_LAST_KNOWN_BALANCE_SUCCESS,
                transactionActionTypes.GET_LAST_KNOWN_BALANCE_FAILURE
            ]
        }
    }),
    CREATE_TRANSACTION: (payload) => ({
        [CALL_API]: {
            endpoint: `/transactions/create/`,
            method: `POST`,
            backend_slug: 'financial',
            body: payload,
            types: [
                transactionActionTypes.CREATE_TRANSACTION_REQUEST,
                transactionActionTypes.CREATE_TRANSACTION_SUCCESS,
                transactionActionTypes.CREATE_TRANSACTION_FAILURE
            ]
        }
    }),
    RETRY_ALL_FAILED_TRANSACTIONS: () => ({
        [CALL_API]: {
            endpoint: `/solbox_topups/retry_all_failed_transactions/`,
            method: `POST`,
            types: [
                transactionActionTypes.RETRY_ALL_FAILED_TRANSACTIONS_REQUEST,
                transactionActionTypes.RETRY_ALL_FAILED_TRANSACTIONS_SUCCESS,
                transactionActionTypes.RETRY_ALL_FAILED_TRANSACTIONS_FAILURE
            ]
        }
    }),
    CANCEL_TRANSACTION: (payload) => ({
        [CALL_API]: {
            endpoint: `/solbox_topups/cancel_transaction/`,
            body: payload,
            method: `POST`,
            types: [
                transactionActionTypes.CANCEL_TRANSACTION_REQUEST,
                transactionActionTypes.CANCEL_TRANSACTION_SUCCESS,
                transactionActionTypes.CANCEL_TRANSACTION_FAILURE
            ]
        }
    }),
    GET_SOLBOX_FIRMWARE_VERSION: (solboxSerialNum) => ({
        [CALL_API]: {
            endpoint: `/solbox/firmware/${solboxSerialNum}`,
            method: `GET`,
            types: [
                transactionActionTypes.GET_SOLBOX_FIRMWARE_VERSION_REQUEST,
                transactionActionTypes.GET_SOLBOX_FIRMWARE_VERSION_SUCCESS,
                transactionActionTypes.GET_SOLBOX_FIRMWARE_VERSION_FAILURE
            ]
        }
    }),
    FETCH_SOLBOX_BALANCE_CHART_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_balance_history/`,
            method: `POST`,
            body: payload,
            types: [
                transactionActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST,
                transactionActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS,
                transactionActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE
            ]
        }
    }),
    FETCH_SOLBOX_I2GRID_CHART_DATA: (payload) => ({
        [CALL_API]: {
            endpoint: `/trading/get_solbox_params_i2grid_history/`,
            method: `POST`,
            body: payload,
            types: [
                transactionActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST,
                transactionActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS,
                transactionActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE
            ]
        }
    }),
    REINITIATE_CREATE_TRANSACTION: () => ({
        type: transactionActionTypes.CREATE_TRANSACTION_INITIATE
    }),
    CLEAR_CHART_DATA_ON_UNMOUNT: () => ({
        type: transactionActionTypes.CLEAR_CHART_DATA_ON_UNMOUNT
    })
}

const filterParamsHandler = (endpoint, solboxId, filterParams) => {
    let endPointWithParams = endpoint;
    if (!(Object.entries(filterParams).length < 1)) {
      for (const [key, value] of Object.entries(filterParams)) {
        if (!endPointWithParams.includes("?")) {
          endPointWithParams += `?${key}=${value}`;
        } else {
          endPointWithParams += `&${key}=${value}`;
        }
      }
    }
    if (solboxId !== "") {
        if (!endPointWithParams.includes("?")) {
            endPointWithParams += `?solbox_serial_number=${solboxId}`;
        } else {
            endPointWithParams += `&solbox_serial_number=${solboxId}`;
        }
    }
    return endPointWithParams;
  };
