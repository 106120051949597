import React, {useEffect, useState} from "react";
import {ReplacementForms} from "./replacementForms";
import {connect} from "react-redux";
import moment from "moment";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Button} from "react-bootstrap";
import {replacementActions} from "../../replacementActions";


const Replacement =(props)=> {

    const [phase, setPhase] = useState(1);
    const [newSerialNumber, setnewSerialNumber] = useState(null)
    const [oldSerialNumber, setOldSerialNumber] = useState(parseInt(props.match.params.solbox_id.toString()))
    const [checkList, setCheckList] = useState([]);
    const [remarksString, setRemarksString] = useState(null);
    const [replacementId, setReplacementId] = useState(null);
    const [oldSolBoxBalance, setOldSolBoxBalance] = useState(null);
    const [makeReimburseButtonDisabled, setMakeReimburseButtonDisabled] = useState(false);
    const [nodeGuid, setNodeGuid] = useState('');
    const [showUpdateBalanceButton, setShowUpdateBalanceButton] = useState(true);

    const phases = {
        1: {
            title: "Validating new SOLbox Serial Number",
        },
        2: {
            title: "Replacing SOlbox data"
        },
        3: {
            title: "Sending Configuration Update",
        },
        4: {
            title: 'Sending Reimbursement'
        },
        5: {
            title: 'Process Summary'
        }
    }

    const checkLists = (data)=> {
        const temp = checkList.map((item, index)=>{return item})
        if (temp.indexOf(data)===-1)
        {
            temp.push(data)
        }
        setCheckList(temp)
    }

    /*useeffect to fetch additional SOLbox info*/
    useEffect(()=> {
        props.FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO(parseInt(props.match.params.solbox_id.toString()));
    },[])

    useEffect(()=> {
        if (props.balanceUpdateSuccessful) {
            window.location.reload()
        }
    },[props.balanceUpdateSuccessful])

    useEffect(()=> {
        if (props.replacementInfo !== undefined) {
            setNodeGuid(props.replacementInfo.node_guid)
            if (props.replacementInfo.last_known_balance || props.replacementInfo.last_known_balance === 0) {
                setOldSolBoxBalance(parseFloat(props.replacementInfo.last_known_balance)/100)
            }
            let updatedAt = ''
            let presentTime = moment()
            if (props.replacementInfo.hasOwnProperty('updated_at')) {
                updatedAt = moment(props.replacementInfo.updated_at)
            }
            let hourDifference = presentTime.diff(updatedAt, 'hours')
            if (hourDifference == 0) {
                setShowUpdateBalanceButton(false)
            }
        }
    },[props.replacementInfo])

    /*UseEffect to run to phase into step 2*/
    useEffect(()=> {
        if (props.serialNumberCheckSuccessful===true)
        {
            checkLists(1)
            setPhase(2)
        }
        else if(props.replacementSuccessful===true){
            checkLists(2)
            setPhase(3)
        }
        else if(props.configUpdateSuccessful===true){
            setMakeReimburseButtonDisabled(true)
            checkLists(3)
            setPhase(4)
            setTimeout(()=>{
                setMakeReimburseButtonDisabled(false)
            },15000)
        }
        else if(props.reimbursementSuccessful===true)
        {
            checkLists(4)
            setPhase(5)
            props.SET_REIMBURSEMENT_SUCCESSFUL_TO_UNDEFINED()
        }
        else if (props.serialNumberCheckSuccessful===false)
        {
            // setPhase(2)
        }
        else if(props.replacementSuccessful===false){
            // setPhase(3)
        }
        else if(props.configUpdateSuccessful===false){
            setPhase(4)
        }
        else if(props.reimbursementSuccessful===false)
        {
            setPhase(5)
            props.SET_REIMBURSEMENT_SUCCESSFUL_TO_UNDEFINED()
        }

        return ()=>{
            props.SET_REPLACEMENT_PHASES_TO_UNDEFINED()
        }
    },[props.serialNumberCheckSuccessful, props.replacementSuccessful, props.configUpdateSuccessful, props.reimbursementSuccessful])

    /*Submit Check SOlbox serial number*/
    const checkSerialNumber =(data)=> {
        setnewSerialNumber(parseInt(data.new.toString()))
        props.CHECK_SERIAL_NUMBER_FOR_REPLACEMENT({
            new: data.new,
            old: oldSerialNumber,
            old_solbox_node_guid: props.match.params.node_guid,
        });
    }

    /*Back to Customers List*/
    const backToCustomers=()=> {
        props.history.push(`/grids/details/${props.match.params.grid_guid}`)
    }


    /* dispatch action to replace SOLbox*/
    const replaceSOlbox =(data)=> {
        setRemarksString(data.remarks)
        const payload= {
            old_solbox_serial_number: oldSerialNumber,
            new_solbox_serial_number: newSerialNumber,
            remarks: data.remarks,
            user_guid: props.user_guid
        }
        props.REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT(payload);
    }

    /*Send configuration Update*/
    const sendConfig = (data) => {
        const payload= {
            old_solbox_serial_number: oldSerialNumber,
            new_solbox_serial_number: newSerialNumber,
            customer_guid: props.match.params.customer_guid,
            grid_guid: props.match.params.grid_guid,
            user_guid: props.user_guid,
            replacement_id: props.replacementId,
            remarks: remarksString || null,
        }
        props.SEND_CONFIG_FOR_REPLACEMENT(payload);
    }

    /*Send Reimbursement*/
    const sendReimbursement=(data)=> {
        const payload= {
            old_solbox_serial_number: oldSerialNumber,
            new_solbox_serial_number: newSerialNumber,
            old_solbox_node_guid: props.match.params.node_guid,
            user_guid: props.user_guid,
            replacement_id: props.replacementId,
            remarks: remarksString || null,
        }
        props.SEND_REIMBURESEMENT_FOR_REPLACEMENT(payload);
    }

    /*Function To get Sumbit Function*/
    const getSubmitFunction=()=>{
        switch (phase)
        {
            case 1:
                return checkSerialNumber;
            case 2:
                return replaceSOlbox;
            case 3:
                return sendConfig;
            case 4:
                return sendReimbursement;
            default:
                return checkSerialNumber;
        }
    }

    const updateBalanceOfSolbox = (balance) => {
        const payload = {
            "solbox_serial_number": oldSerialNumber.toString(),
            "node_guid": nodeGuid,
            "last_known_balance": balance
        }
        props.UPDATE_SOLBOX_BALANCE(payload)
    }

    return <ContentWrapper showBackButton={false}
                           showCardHeader={false}
                           isLoading={props.modalLoading || props.isLoading}
                           serverError={props.serverError}
                           statusCode={props.statusCode}
                           pageTitle={`Replace SOlbox ${oldSerialNumber}`}
                           showUtilityButton={<Button variant="primary" disabled={props.disableButton} onClick={backToCustomers}>
                               <i className="flaticon2-left-arrow-1"/>
                               &nbsp;Customers
                           </Button>}>
        <ReplacementForms
            phase={phase}
            submit={getSubmitFunction()}
            balanceUpdateEvent={updateBalanceOfSolbox}
            title={phases[`${phase}`].title}
            oldSerial={oldSerialNumber}
            newSerial={phase!==1 && newSerialNumber?newSerialNumber:null}
            disableButton={props.disableButton}
            disableBalanceUpdateButton={props.balanceUpdateRequestPending}
            makeReimburseButtonDisabled={makeReimburseButtonDisabled}
            checkList={checkList}
            backToCustomers={backToCustomers}
            oldSolboxBalance={oldSolBoxBalance}
            replacementInfo={props.replacementInfo}
            showUpdateBalanceButton={showUpdateBalanceButton}
        />
    </ContentWrapper>
}

/*Mapping Redux States to this Component's Props*/
const mapStateToProps = (state) => {
    return {
        isLoading: state.replacementReducer.isLoading,
        serverError: state.replacementReducer.serverError,
        disableButton: state.replacementReducer.disableButton,
        serialNumberCheckSuccessful: state.replacementReducer.serialNumberCheckSuccessful,
        replacementSuccessful: state.replacementReducer.replacementSuccessful,
        configUpdateSuccessful: state.replacementReducer.configUpdateSuccessful,
        reimbursementSuccessful: state.replacementReducer.reimbursementSuccessful,
        replacementInfo: state.replacementReducer.replacementInfo,
        modalLoading: state.replacementReducer.modalLoading,
        user_guid: state.auth.user_guid,
        replacementId: state.replacementReducer.replacementId,
        balanceUpdateRequestPending: state.replacementReducer.balanceUpdateRequestPending,
        balanceUpdateSuccessful: state.replacementReducer.balanceUpdateSuccessful
    }
};
export default connect(mapStateToProps, replacementActions)(Replacement);