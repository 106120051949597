import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route, Switch} from "react-router-dom";
import SmartboardList from "./views/smartboardList/SmartboardList"
import SecuredRoute from "../../router/SecuredRoute";

const SmartboardRouter = props =>{

        return (
           <Switch>
              <Redirect
                  exact={"true"}
                  from={"/smart-boards"}
                  to={"/smart-boards/list"}
              />
               <SecuredRoute
                   path={"/smart-boards/list"}
                   component={SmartboardList}
               />
           </Switch>
        )

};

SmartboardRouter.propTypes = {

};

export default SmartboardRouter;