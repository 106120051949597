import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SolboxList from "./views/solboxlist/SolboxList";
import SolboxProfile from "./views/solboxprofile/SolboxProfile";
import NewSolBox from "./views/createNewSolbox/newSolbox";
import Configurations from "../commonFeatureModules/views/configurations/Configurations";
import SecuredRoute from "../../router/SecuredRoute";

const SolboxRouter = ({}) => {

    return (
        <Switch>
            {/* Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/solboxes"}
                to={"/solboxes/list"}
            />
            {/* Routes to SOLbox List */}
            <SecuredRoute
                path={"/solboxes/list"}
                component={SolboxList}
            />
            {/* Routes to SOLbox Profile */}
            <SecuredRoute
                path={"/solboxes/profile/:id/:guid"}
                component={SolboxProfile}
            />
            {/* Routes to Create SOLbox Page */}
            <SecuredRoute
                path={"/solboxes/create"}
                component={NewSolBox}
                externalUserPermitted={false}
            />
            {/* Routes to SOLbox Configurations */}
            <SecuredRoute
                path={"/solboxes/configuration/:solbox_guid/:solbox_serial_number"}
                component={Configurations}
            />
        </Switch>
    )
};

export default SolboxRouter;