import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import styled from "styled-components";
import moment from "moment";
import {Button} from "react-bootstrap";

const Text= styled.div`
    margin: 0 0 16px 0;
`

const DocumentText= styled.div`
    margin: 0 0 5px 0;
`


const GridProfile = ({profileData}) => {

    const [collapsed, setCollapsed] = useState(true);



    const getGridNameString=()=>{
        let temp = '';
        temp = profileData?.grid_name? temp.concat(`${profileData.grid_name}`):temp.concat(`N/A`);
        temp = profileData?.address?.district? temp.concat(`-${profileData.address.district}`):temp.concat(``);
        temp = profileData?.address?.division? temp.concat(`-${profileData.address.division}`):temp.concat(``);
        temp = profileData?.iot_alias? temp.concat(`-${profileData?.iot_alias}`):temp.concat(``);

        return temp
    }


    const getAddressString=()=>{
        let temp = '';
        temp = profileData?.address?.union? temp.concat(`${profileData.address.union} Union`):temp.concat(``);
        temp = profileData?.address.sub_district?   temp?  temp.concat(`, ${profileData.address.sub_district}`) : temp.concat(`${profileData.address.sub_district}`)  :temp.concat(``);
        temp = profileData?.address?.district? temp?   temp.concat(`, ${profileData.address.district}`) : temp.concat(`${profileData.address.district}`)    :temp.concat(``);
        temp = temp.concat(  temp? ", Bangladesh":"Bangladesh")

        return temp
    }


    return (
        <>

            <div className={'row '}>
                <div className={'col-md-8  '}>
                    <Text> <SolTypography.Heading> {getGridNameString()}</SolTypography.Heading></Text>
                    <Text> <SolTypography.Text secondary >{/*{profileData?.operator_name || "N/A"}*/} Operated by {profileData?.grid_operator || "N/A"}</SolTypography.Text></Text>
                    <Text>
                        <SolTypography.Text secondary> {getAddressString()} (<SolTypography.Text primary>
                            <a href={profileData?.grid_map_link || undefined}  target={'_blank'} style={{color: "#F18D00",}}>Open in Google Maps</a>
                        </SolTypography.Text>)</SolTypography.Text>
                    </Text>
                    { collapsed? <button onClick={()=>setCollapsed(false)} className="btn btn-light" style={{
                            border: '1px solid #8C8C9B',
                        }} > <img src={require('../../../../utils/asset/collapse-arrow-down.svg')} alt={'down'}/> &nbsp;See More Details</button>:
                        <>
                            <Text> <SolTypography.Text secondary> Installed on {profileData?.installation_date?moment(profileData.installation_date).format('DD, MMMM YYYY '):"N/A"}</SolTypography.Text> </Text>
                            <Text> <SolTypography.Text secondary>Sharing {profileData?.profit_share_percentage || parseFloat(profileData?.profit_share_percentage)===0? `${profileData?.profit_share_percentage}%`:"N/A" } trading profit with {profileData?.grid_operator || "N/A"}</SolTypography.Text></Text>
                            <Text> <SolTypography.Text secondary>Default Tariff: Buy at {profileData?.default_tariff?.buy_price || parseFloat(profileData?.default_tariff?.buy_price)===0?profileData?.default_tariff?.buy_price:"N/A"} Tk / Wh and Sell at {profileData?.default_tariff?.sell_price || parseFloat(profileData?.default_tariff?.sell_price)===0?profileData?.default_tariff?.sell_price:"N/A"} Tk / Wh</SolTypography.Text></Text>
                            <Text> <SolTypography.Text secondary>Belongs to project {profileData?.project || "N/A"}</SolTypography.Text></Text>
                            <button onClick={()=>setCollapsed(true)} className="btn btn-light" style={{
                                border: '1px solid #8C8C9B',
                                marginTop: '20px'
                            }} > <img src={require('../../../../utils/asset/collapse-arrow-up.svg')} alt={'down'}/> &nbsp;Hide Details</button>
                        </> }
                </div>
                <div className={'col-md-4'}>
                    <Text> <SolTypography.Heading>&nbsp; </SolTypography.Heading></Text>
                    { collapsed? null :
                        <>
                            <b><DocumentText> <SolTypography.Text secondary >DOCUMENTS</SolTypography.Text></DocumentText></b>

                            <DocumentText><img src={require('../../../../utils/asset/document.svg')} alt={'doc'}/>&nbsp;&nbsp;<SolTypography.Text primary><a href={profileData?.grid_map_link || undefined} target={'_blank'} style={{
                                color: "#F18D00",
                            }}>Grid Map</a></SolTypography.Text></DocumentText>

                            <DocumentText><img src={require('../../../../utils/asset/document.svg')} alt={'doc'}/>&nbsp;&nbsp;<SolTypography.Text primary><a href={profileData?.survey_data_link || undefined} target={'_blank'} style={{
                                color: "#F18D00",
                            }}>Survey</a></SolTypography.Text></DocumentText>

                            {/*<DocumentText><img src={require('../../../../utils/asset/document.svg')} alt={'doc'}/>&nbsp;&nbsp;<SolTypography.Text primary><a href={profileData?.agreement_doc_link || undefined} target={'_blank'} style={{*/}
                            {/*    color: "#F18D00",*/}
                            {/*}}>Service Agreement</a></SolTypography.Text></DocumentText>*/}
                        </> }


                    <div style={{
                        marginTop: collapsed?null:'25px',
                    }}> <b><DocumentText><SolTypography.Text secondary>SAM</SolTypography.Text></DocumentText></b> </div>
                    <DocumentText><SolTypography.Text secondary>{profileData?.area_manager?.name || "SAM name not Found"}</SolTypography.Text></DocumentText>
                    <DocumentText><SolTypography.Text secondary>{profileData?.area_manager?.phone || "SAM phone number not Found"}</SolTypography.Text></DocumentText>


                </div>

            </div>
            <br/>
            <hr/>
            <br/>
        </>

    );
};

GridProfile.propTypes = {

};

export default GridProfile;