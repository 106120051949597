import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Transaction from "./views/Transaction";
import CreateTransaction from "./views/CreateTransaction";
import SecuredRoute from "../../router/SecuredRoute";

const TransactionRouter = ({}) => {

    return (
        <Switch>
            {/* Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/transactions"}
                to={"/transactions/list"}
            />
            {/* Route to Transaction List */}
            <SecuredRoute
                path={"/transactions/list"}
                component={Transaction}
            />
            {/* Route to Assign Customer to Orphan Transaction */}
            <SecuredRoute
                path={"/transactions/assign-customer/:id"}
                component={CreateTransaction}
                externalUserPermitted={false}
            />
            {/* Route to Create Transaction from Same Module */}
            <SecuredRoute
                path={"/transactions/create"}
                component={CreateTransaction}
                externalUserPermitted={false}
            />
            {/* Route to Create Transaction from Other Modules */}
            <SecuredRoute
                path={"/transactions/create-for/:solbox/:name/:grid/:type"}
                component={CreateTransaction}
                externalUserPermitted={false}
            />
        </Switch>
    )
};

export default TransactionRouter;
