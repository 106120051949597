import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import GridList from "./views/gridList/gridList";
import GridDetails from "./views/gridDetails/GridDetails";
import CreateGrid from "./views/newGrid/CreateGrid";
import Configurations from "../commonFeatureModules/views/configurations/Configurations";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";


export const GridsRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/grids"}
                to={"/grids/list"}
            />

            {/* Routes to Grid List*/}
            <SecuredRoute
                path={"/grids/list"}
                component={GridList}
            />

            {/* Routes to Grid Details*/}
            <SecuredRoute
                path={"/grids/details/:grid_guid"}
                component={GridDetails}
            />

            <SecuredRoute
                path={"/grids/create"}
                component={CreateGrid}
                externalUserPermitted={false}
            />

            <SecuredRoute
                path={"/grids/nodes/configuration/:solbox_guid/:solbox_serial_number"}
                component={Configurations}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};