import {forEach} from "react-bootstrap/cjs/ElementChildren";
import {SolTypography} from "../components/utilityComponents/SOlTypography";
import React from "react";

export const isNumber = (value) => {

    if (!isNaN(value) && value !== null && value !== '' && isFinite(value)) {
        return typeof parseFloat(value) === 'number'
    } else {
        return false
    }
}


export const getGridNameString = (grid) => {
    let temp = '';
    temp = grid?.name ? temp.concat(`${grid.name}`) : temp.concat(`N/A`);
    temp = grid?.address?.district ? temp.concat(`-${grid.address.district}`) : temp.concat(``);
    temp = grid?.address?.division ? temp.concat(`-${grid.address.division}`) : temp.concat(``);
    temp = grid?.iot_alias ? temp.concat(`-${grid?.iot_alias}`) : temp.concat(``);

    return temp
}


export const getApplianceString = (appliances) => {
    // let appliances = [
    //     {
    //         "quantity": 0,
    //         "appliance_guid": "74da9a5d-0ba7-4dac-b9eb-71ec21a70a38",
    //         "name": "Electric Fan",
    //         "operating_voltage": 0.0,
    //         "watt": 0.0,
    //         "power_consumption": 0.0,
    //         "appliance_type_name": "Home",
    //         "is_industrial_appliance": false
    //     },
    //     {
    //         "quantity": 2,
    //         "appliance_guid": "74da9a5d-0ba7-4dac-b9eb-71ec21a70a38",
    //         "name": "Electric Fan",
    //         "operating_voltage": 0.0,
    //         "watt": 0.0,
    //         "power_consumption": 0.0,
    //         "appliance_type_name": "Home",
    //         "is_industrial_appliance": false
    //     },
    //
    // ];
    if (!Array.isArray(appliances) || appliances?.length === 0) {
        return "No appliances found"
    }
    let temp = '';
    {/*Uses 5W 1 Light, 10W 1 Table Fan*/
    }
    appliances.forEach((item, index) => {
        if (!temp && isNumber(item.quantity) && parseInt(item.quantity)>0) {
            temp = temp.concat(`Uses ${item.watt? `${item.watt} watt `: ''}${item.quantity} ${item.name}`)
        } else if (parseInt(item.quantity)>0){
            temp = temp.concat(`, ${item.watt? `${item.watt} watt `: ''}${item.quantity} ${item.name}`)
        }

    });

    return temp
}


export const pluralizer = (numb, singular, plural) => {
    if (numb > 1) {
        return `${numb} ${plural}`;
    } else if (numb === 0) {
        return `No ${plural}`;
    } else if (numb === 1) {
        return `1 ${singular}`;
    } else {
        return `N/A ${singular}`;
    }
}


/*Bangladeshi Phone Number Validation*/
export const displayBangladeshiMobileNo = (phone) => {
    // const arr = ['01791032540', '179103240', '8801791032540', '+8801791032540'];
    if (phone?.length >= 10) {
        // return <SolTypography.Text>{phone}&nbsp;<img src={require('./asset/danger.svg')} alt="Kiwi standing on oval"/></SolTypography.Text>
        return `+880${phone.slice(-10)}`;
    } else if (phone?.length < 10) {
        return phone
    } else {
        return "N/A";
    }

}


/*Serializing an object from */
export const JsonToUriSerialize = function (obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    // return str.toString();
    return str.join("&");
}


/*Query Param To Object*/
export const parseParams = (querystring) => {

    // parse query string
    const params = new URLSearchParams(querystring);

    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {


        if (params.getAll(key).length > 1) {
            console.log(params.getAll(key));
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    // console.log(obj);

    return obj;
};


/* Checking if a keyword exists in a array.*/
export const validateValue = (keyword, validationArr) => {

    const isMulti = validationArr?.isMulti;
    const arr = validationArr?.list;
    const validationFunction = validationArr?.validationFunction

    if (validationFunction && typeof validationFunction=== 'function'){
        return validationFunction(keyword);
    }



    if (Array.isArray(arr) && arr.length > 0) {
        let currentIndex = null;
        /*Loop through the array*/
        if (!isMulti) {
            currentIndex = arr.findIndex((item) => {

                /*Check if it is an object or a single value*/
                if (typeof item !== 'object') {
                    /*return false if it does not match and vice-varca*/
                    return keyword.toString().toLowerCase() === item.toString().toLowerCase();
                }
                /*Check if the item is an object and see if it has a key "value"*/
                else if (typeof item === 'object' && !isNullorUndefined(item.value)) {
                    /*return false if it does not match with the value and vice-varca*/
                    return item.value.toString().toLowerCase() === keyword.toString().toLowerCase();
                }
            });
        } else {
            const keywordArr = keyword.replace(/[\[\]\"\"']+/g, '');
            const convertedKeywordArr = keywordArr.split(',');

            const temp = arr.filter((item)=>{
                return convertedKeywordArr.indexOf(item.value)>-1
            });

            return Array.isArray(temp) && temp.length>0 ? temp: undefined;
        }
        return arr[currentIndex];
    }
    /* If the array is empty return false*/
    else {
        return undefined
    }
};


/*Check if a value is null or undefined*/
export const isNullorUndefined = (value) => {
    return value === null || value === undefined;
};



/*Check if two Select Arrays are exactly same*/
export const selectArraysMatches=(source, target )=>{
    if (Array.isArray(source) && Array.isArray(target) && source.length===target.length){
        const filtered = source.filter((item)=>{

            if (item?.label && item?.value){

                const tempIndex =  target.findIndex((targetItem)=>{

                    return targetItem?.label && targetItem?.value && targetItem?.label===item?.label && targetItem?.value===item?.value;
                });

                return !tempIndex >-1;

            }
            else{
                return true;
            }
        })

        return  filtered.length>0
    }else {
        return false
    }
}


/*Check if two React Select object is same*/
export const selectObjectMatches=(source, target)=>{
    return source?.label && source?.value && target?.label && target?.value && source?.label===target?.label && source?.value===target?.value

}


export const processFilterParams=(payload)=>{
    const filterParams={}
    Object.keys(payload).forEach((item)=>{
        if (payload[item]){
            filterParams[item]=payload[item];
        }
    });
    return filterParams;
}


/*Logic to construct the SHS construct String*/
export const getShsSetupString= (data)=> {
    let str= '';
    if (Array.isArray(data.customer_shs_info?.battery_info) && data.customer_shs_info?.battery_info.length>0)
    {
        str = str.concat(`${data.customer_shs_info?.battery_info.length} Battery `);
        const temp=data.customer_shs_info?.battery_info.forEach((item, index)=>{
            if (index===0 && index===data.customer_shs_info.battery_info.length-1){

                str= str.concat(`(${item.batt_size} v)`)

            }else if (index===0){

                str= str.concat(`(${item.batt_size} v`)

            }
            else if (index === data.customer_shs_info.battery_info.length-1)
            {
                str= str.concat(`, ${item.batt_size} v)`)
            }
            else
            {
                str= str.concat(`, ${item.batt_size} v)`)
            }
        })
    }

    if (Array.isArray(data.customer_shs_info?.panel_info) && data.customer_shs_info?.panel_info.length>0)
    {
        str = str.concat(`, ${data.customer_shs_info?.panel_info.length} Panel `);
        const temp=data.customer_shs_info?.panel_info.forEach((item, index)=>{

            if (index===0 && index===data.customer_shs_info.panel_info.length-1){

                str= str.concat(`(${item.panel_size} Wp)`)

            }else if (index===0){

                str= str.concat(`(${item.panel_size} Wp`)

            }else if (index === data.customer_shs_info.panel_info.length-1)
            {
                str= str.concat(`, ${item.panel_size} Wp)`)
            }
            else
            {
                str= str.concat(`, ${item.panel_size} Wp)`)
            }
        })
    }

    return str ===''? "No Data Found":str;

}


/*Convert Paisa to Taka*/
export const paisaToTaka=(paisa)=>{
    return isNumber(paisa)? (parseFloat(paisa)/100).toFixed(2): "N/A"
}


export const capitalizeFirstLetter=(string)=> {

    return string.charAt(0).toUpperCase() + string.slice(1);
}
