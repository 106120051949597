import React, { useState, useEffect } from "react";
import { customerActions } from "../customerActions";
import { connect } from 'react-redux';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../components/dataTable/DataTable";
import CustomerListToolbar from "./CustomerListToolbar";
import CustomerListFilter from "./CustomerListFilter";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from "react-router-dom";
import { withRoles } from "../../../router/SecuredRoute";

const CustomerList = (props) => {

    const {
        location,
        history,
        isSolshare,
        b2bOrgGuid,
        isLoading,
        tableLoading,
        statusCode,
        customerList,
        FETCH_SEARCHED_CUSTOMER,
        FETCH_GRID_LIST,
        FETCH_FILTERED_CUSTOMERS
    } = props;

    console.log(isSolshare);
    console.log(b2bOrgGuid);

    const [customerParams, setCustomerParams] = useState(null);
    const [displayGridNames, setDisplayGridNames] = useState(null);
    const [toggleFilter, setToggleFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);
    const [getCustomers, setGetCustomers] = useState(false);
    //const [customerPhone, setCustomerPhone] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [searchFlag, setSearchFlag] = useState(false);
    const [division, setDivision] = useState("");
    const [grid, setGrid] = useState([]);
    const [customerType, setCustomerType] = useState("");
    const [customerStatus, setCustomerStatus] = useState("");
    const [gridOptions, setGridOptions] = useState([]);

    const divisionOptions = [
        { value: 'Chattogram', label: 'Chattogram' },
        { value: 'Rajshahi', label: 'Rajshahi' },
        { value: 'Khulna', label: 'Khulna' },
        { value: 'Barisal', label: 'Barisal' },
        { value: 'Sylhet', label: 'Sylhet' },
        { value: 'Dhaka', label: 'Dhaka' },
        { value: 'Rangpur', label: 'Rangpur' },
        { value: 'Mymensingh', label: 'Mymensingh' },
    ];

    const handleSetGridOptions = (gridList) => {
        setGridOptions(gridList);
    }

    const handleChangeDivision = (division) => {
        setDivision(division);
        if (!division) {
            FETCH_GRID_LIST({});
        }
    }

    const handleChangeGrid = (grid) => {
        setGrid(grid);
    }

    const [customerPhone, setCustomerPhone] = useState("");

    const handleChangeSolTextInput = (event) => {
        if (event.target.name === "customerPhoneNumber") {
            setCustomerPhone(event.target.value);
        }
    }

    const handleChangeCustomerType = (event) => {
        if (event.target.id === "Prosumer") {
            setCustomerType(event.target.value);
        } else if (event.target.id === "Producer") {
            setCustomerType(event.target.value);
        } else {
            setCustomerType(event.target.value);
        }
    }

    const handleChangeCustomerStatus = (event) => {
        if (event.target.id === "Inactive") {
            setCustomerStatus(event.target.value);
        } else {
            setCustomerStatus(event.target.value);
        }
    }

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedRowData(data);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const setCustomerListParams = (event, params) => {
        setCustomerParams(params);
    }

    const handleDisplayGridNames = (gridNames) => {
        setDisplayGridNames(gridNames);
    }

    const toggleCustomerListFilter = (event) => {
        setToggleFilter(!toggleFilter);
    }

    const toggleFilterApplied = (event, value) => {
        setFilterApplied(value);
    }

    const toggleGetCustomers = (event) => {
        setGetCustomers(true);
    }

    const handleSetFilterParams = (searchParams) => {
        const regex = /^[\+a-z0-9\- ]+$/gi;
        if (searchParams.has("division") && 
            searchParams.get("division") &&
            searchParams.get("division").trim()
        ) {
            divisionOptions.forEach((element, index) => {
                if (element.value.toLowerCase() === searchParams.get("division").trim().toLowerCase()) {
                    setDivision({
                        value: element.value,
                        label: element.label,
                    });
                }
            });
        }
        if (searchParams.has("customer_phone") && 
            searchParams.get("customer_phone") &&
            searchParams.get("customer_phone").trim() &&
            regex.test(searchParams.get("customer_phone").trim())
        ) {
            setCustomerPhone(searchParams.get("customer_phone").trim());
        }
        if (searchParams.has("customer_type")) {
            let customerType = searchParams.get("customer_type");
            if (customerType &&
                customerType.trim() &&
                customerType.trim().toLowerCase() === "consumer" ||
                customerType.trim().toLowerCase() === "prosumer" ||
                customerType.trim().toLowerCase() === "producer"
            ) {
                setCustomerType(customerType.trim().toLowerCase());
            }
        }
        if (searchParams.has("customer_status")) {
            let customerStatus = searchParams.get("customer_status");
            if (customerStatus &&
                customerStatus.trim() &&
                customerStatus.trim().toLowerCase() === "active" ||
                customerStatus.trim().toLowerCase() === "inactive" 
            ) {
                setCustomerStatus(customerStatus.trim().toLowerCase());
            }
        }
    }

    useEffect((event) => {
        if (!filterApplied && location && location.search) {
            let filterParams = {};
            var searchParams = new URLSearchParams(location.search);
            if (searchParams.has("division") ||
                searchParams.has("grid_guid") ||
                searchParams.has("customer_phone") ||
                searchParams.has("customer_type") ||
                searchParams.has("customer_status")
            ) {
                handleSetFilterParams(searchParams);
                const divisions = [
                    'chattogram',
                    'rajshahi',
                    'khulna',
                    'barisal',
                    'sylhet',
                    'dhaka',
                    'rangpur',
                    'mymensingh'
                ];
                searchParams.forEach((value, key) => {
                    if (key === "division" &&
                        value &&
                        value.trim() &&
                        divisions.includes(value.trim().toLowerCase())
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                    if (key === "customer_type" &&
                        value &&
                        value.trim() &&
                        value.trim().toLowerCase() === "consumer" ||
                        value.trim().toLowerCase() === "prosumer" ||
                        value.trim().toLowerCase() === "producer"
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                    if (key === "customer_status" &&
                        value &&
                        value.trim() &&
                        value.trim().toLowerCase() === "active" ||
                        value.trim().toLowerCase() === "inactive" 
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                    const regex = /^[\+a-z0-9_,\- ]+$/gi;
                    if (key === "grid_guid" || 
                        key === "customer_phone" && 
                        value && 
                        value.trim() &&
                        regex.test(value.trim())
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                });
            }
            if (!(Object.entries(filterParams).length < 1)) {
                // setCustomerListParams(event, formData);
                toggleGetCustomers(event);
                toggleFilterApplied(event, true);
                FETCH_FILTERED_CUSTOMERS(filterParams);
                //toggleCustomerListFilter(event);
            }
        }
    }, [location, filterApplied]);

    useEffect(() => {
        if (location && location.search && gridOptions && gridOptions.length !== 0) {
            var searchParams = new URLSearchParams(location.search);
            if (searchParams.has("grid_guid") && 
                searchParams.get("grid_guid") &&
                searchParams.get("grid_guid").trim()
            ) {
                let gridGuidArr = searchParams.get("grid_guid").trim().split(",");
                let gridArr = [];
                gridOptions.forEach((element, index) => {
                    if (gridGuidArr.includes(element?.value)) {
                        gridArr.push({
                            value: element?.value,
                            label: element?.label
                        });
                    }
                });
                setGrid(gridArr);
            }
        }
    }, [location, gridOptions]);

    const reset = (event) => {
        if (filterApplied !== false) {
            if (division !== "" ||
                division !== null ||
                grid !== null ||
                grid.length !== 0 ||
                customerPhone.trim() !== "" ||
                customerType !== "" ||
                customerStatus !== ""
            ) {
                toggleFilterApplied(event, false);
                FETCH_FILTERED_CUSTOMERS({});
                //toggleCustomerListFilter(event);
                history.push({
                    pathname: '/customers/list',
                    search: ""
                });
            }
        }
        setDivision("");
        setGrid([]);
        setCustomerPhone("");
        setCustomerType("");
        setCustomerStatus("");
    }

    const handleChangeSearchKeyword = (event) => {
        if (event.target.name === "phoneOfCustomer") {
            setCustomerPhone(event.target.value);
            if (searchFlag === true && event.target.value === "") {
                FETCH_SEARCHED_CUSTOMER("", {});
                setSearchFlag(!searchFlag);
            }
        }
    }

    const handleSearchFlag = (event) => {
        setSearchFlag(!searchFlag);
    }

    const handleCustomerNameClick = (event, data) => {

        props.history.push(
            '/customers/profile',
            {
                solbox_guid: data.solbox_guid,
                customer_guid: data.customer_guid,
                solbox_serial_number: data.solbox_serial_number,
                customer_type: data.type
            }
        )
    }

    const handleGridNameClick = (event, data) => {
        if (data !== undefined && data !== null) {
            props.history.push(`/grids/details/${data.grid_guid}`,
                {gridName: data.grid}
            );
        }
    }

    const handleSolboxClick = (event, data) => {
        if (data !== undefined && data !== null) {
            props.history.push(`/solboxes/profile/${data.solbox_serial_number}/${data.solbox_guid}`);
        }
    }

    const redirectToCreateTransaction = (data) => {
        if (data !== undefined && data !== null) {
            handleClose();
            props.history.push(`/transactions/create-for/${data.solbox_serial_number}/${data.name}/${data.grid_name}/${data.type}`);
        }
    }

    const showReplacementModal = (data) => {
        if (data !== undefined && data !== null) {
            handleClose();
            props.history.push(`/replacements/replace/${data.grid_guid}/${data.solbox_serial_number}/${data.customer_guid}/${data.node_guid}`);
        }
    }

    const customerListColumns = [
        {
            field: "",
            title: "",
            width: "80px",
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            render: (rowData) => (
                <React.Fragment>
                    {(rowData.type !== undefined &&
                        rowData !== undefined &&
                        rowData.type !== null &&
                        rowData.type !== "") ?
                        <div className={`node-type-cell-container`}>
                    <span className={`node-type-cell`}>
                      {rowData.type !== undefined &&
                      rowData.type !== null &&
                      rowData.type !== "" &&
                      rowData !== undefined ? (
                              rowData.type === "Consumer" ? (
                                  rowData.type.substring(0, 2).toUpperCase()
                              ) : rowData.type === "Microutility" ? (
                                  `MU`
                              ) : rowData.type === "Producer" ? (
                                  `PRD`
                              ) : (
                                  rowData.type.substring(0, 3).toUpperCase()
                              )
                          ) :
                          null
                      }
                    </span>
                        </div>
                        :
                        <span className="ml-3">
                  <AspectRatioOutlinedIcon fontSize="large"/>
              </span>
                    }
                </React.Fragment>
            ),
        },
        {
            field: "name",
            title: "Name",
            // cellStyle: { color: "#F18D00" },
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            render: (rowData) => (
                <React.Fragment>
                    {rowData.name !== undefined &&
                    rowData.name !== null &&
                    rowData.name !== "null" &&
                    rowData !== undefined ? (
                        <React.Fragment>
                            <span
                                className="d-block sol-text inter-module-link">
                                <Link style={{ color: "#F18D00",}}
                                    target={'_blank'}
                                    to={{
                                        pathname:  `/customers/profile/${rowData.customer_guid}/${rowData.solbox_guid}/${rowData.solbox_serial_number}/${rowData.type}`,
                                        state:  {
                                            solbox_guid: rowData.solbox_guid,
                                            customer_guid: rowData.customer_guid,
                                            solbox_serial_number: rowData.solbox_serial_number,
                                            customer_type: rowData.type,
                                        },
                                    }}>
                                    {rowData.name}
                                </Link>
                            </span>
                        </React.Fragment>
                        ) :
                        `N/A`
                    }
                    {rowData.phone !== undefined &&
                    rowData.phone !== null &&
                    rowData.phone !== `N/A` &&
                    rowData !== undefined && (
                        <React.Fragment>
                            <span
                                className="d-block sol-text inter-module-link">
                                <Link style={{ color: "#F18D00",}}
                                    target={'_blank'}
                                    to={{
                                        pathname:  `/customers/profile/${rowData.customer_guid}/${rowData.solbox_guid}/${rowData.solbox_serial_number}/${rowData.type}`,
                                        state:  {
                                            solbox_guid: rowData.solbox_guid,
                                            customer_guid: rowData.customer_guid,
                                            solbox_serial_number: rowData.solbox_serial_number,
                                            customer_type: rowData.type,
                                        },
                                    }}>
                                {rowData.phone}
                                </Link>
                            </span>
                        </React.Fragment>
                    )}
                </React.Fragment>
            ),
            align: "left",
        },
        {
            field: "grid",
            title: "Grid",
            cellStyle: {color: "#F18D00"},
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            render: (rowData) => (
                <React.Fragment>
                    {rowData.grid !== undefined &&
                    rowData.grid !== null &&
                    rowData.grid !== "null" &&
                    rowData !== undefined ? (
                            <React.Fragment>
                                <span className="inter-module-link">
                                    <Link to={`/grids/details/${rowData.grid_guid}`}  target={'_blank'} style={{color: "#F18D00",}}>
                                        {rowData.grid}
                                    </Link>
                                </span>
                            </React.Fragment>
                        )
                        :
                        `N/A`
                    }
                </React.Fragment>
            ),
        },
        {
            field: "balance",
            title: "Balance (Tk)",
            cellStyle: {paddingLeft: "5%"},
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            align: "right",
        },
        {
            field: "solbox_serial_number",
            title: "SOLbox",
            cellStyle: {color: "#F18D00"},
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            render: (rowData) => (
                <React.Fragment>
                    {rowData.solbox_serial_number !== undefined &&
                    rowData.solbox_serial_number !== null &&
                    rowData.solbox_serial_number !== "null" &&
                    rowData !== undefined ? (
                        <React.Fragment>
                            <span className="inter-module-link">
                                <Link to={`/solboxes/profile/${rowData.solbox_serial_number}/${rowData.solbox_guid}`} target={'_blank'} style={{color: "#F18D00",}}>
                                    {rowData.solbox_serial_number}
                                </Link>
                            </span>
                        </React.Fragment>
                    ) : `N/A`}
                </React.Fragment>
            ),
            align: isSolshare ? "left" : "right",
        },
        {
            field: "",
            title: "",
            render: (rowData) => (
                <React.Fragment>
                    {isSolshare &&
                    <React.Fragment>
                        <IconButton
                        aria-label="more"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event, data) => handleClick(event, rowData)}
                        >
                            <MoreVertIcon style={{color: "#F18D00"}}/>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted={false}
                            open={Boolean(anchorEl)}
                            variant={"menu"}
                            onClose={(event) => handleClose(event)}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            style={{top: "3rem", left: "1.75rem"}}
                            PaperProps={{
                                style: {
                                    boxShadow: "0px 0px 3px 2px #f2f2f2",
                                },
                            }}
                        >
                            <MenuItem
                                onClick={() => redirectToCreateTransaction(selectedRowData)}
                            >{`Create Transaction`}</MenuItem>
                            <MenuItem
                                onClick={() => showReplacementModal(selectedRowData)}
                            >{`Replace SOLbox`}</MenuItem>
                        </Menu>
                    </React.Fragment>
                    }
                </React.Fragment>
            ),
            align: "right",
            disableClick: true
        },
    ];

    const [customerListData, setCustomerListData] = useState([]);

    const hideCustomerList = (event) => {
        setToggleFilter(!toggleFilter);
    }

    useEffect(() => {
        if (customerList !== undefined && customerList.length !== 0) {
            let data = [];
            customerList.forEach((element, index) => {
                data.push({
                    ...element,
                    type: element.node_type !== undefined && element.node_type !== null
                        ? element.node_type
                        : "",
                    name:
                        element.name !== undefined && element.name !== null
                            ? element.name
                            : "null",
                    phone:
                        element.phone !== undefined && element.phone !== null
                            ? element.phone
                            : `N/A`,
                    grid:
                        element.grid_name !== undefined && element.grid_name !== null
                            ? element.grid_name
                            : "null",
                    balance:
                        element.last_known_balance !== null &&
                        element.last_known_balance !== undefined
                            ? typeof element.last_known_balance === "number"
                            ? (element.last_known_balance / 100).toFixed(2)
                            : (Number.parseInt(element.last_known_balance) / 100).toFixed(2)
                            : `N/A`,
                    solbox_serial_number:
                        element.solbox_serial_number !== undefined && element.solbox_serial_number !== null
                            ? element.solbox_serial_number
                            : "null",
                    solbox_guid:
                        element.solbox_guid !== undefined && element.solbox_guid !== null
                            ? element.solbox_guid
                            : "null"
                });
            });
            setCustomerListData(data);
        } else {
            if (customerList !== undefined && customerList.length === 0) {
                setCustomerListData(customerList);
            }
        }
    }, [customerList]);

    useEffect(() => {
        if (getCustomers !== undefined && getCustomers !== null && getCustomers === false) {
            setCustomerListData([]);
        }
    }, [getCustomers]);

    const [importCustomerData, setImportCustomerData] = useState([]);

    useEffect(() => {
        if (customerList !== undefined && customerList !== null && customerList.length !== 0) {
            let data = [];
            customerList.forEach((element, index) => {
                data.push({
                    "Customer": element.name !== undefined && element.name !== null ? element.name : `N/A`,
                    "Customer phone": element.phone !== undefined && element.phone !== null ? element.phone : `N/A`,
                    "Grid": element.grid_name !== undefined && element.grid_name !== null ? element.grid_name : `N/A`,
                    "Balance": element.last_known_balance !== undefined && element.last_known_balance !== null ? 
                        typeof element.last_known_balance === "number"
                        ? (element.last_known_balance / 100)
                        : (Number.parseInt(element.last_known_balance) / 100)
                        : `N/A`,
                    "SOLbox": element.solbox_serial_number !== undefined && element.solbox_serial_number !== null ? element.solbox_serial_number : `N/A`
                });
            });
            setImportCustomerData(data);
        } else {
            if (customerList !== undefined && customerList !== null && customerList.length === 0) {
                setImportCustomerData(customerList);
            } else {
                setImportCustomerData([]);
            }
        }
    }, [customerList]);

    const redirectToCustomerList = () => {
        props.history.goBack();
    }

    return (
        <React.Fragment>
            <ContentWrapper
                showBackButton={false}
                permissionDenied={false}
                pageTitle={`Customers`}
                isLoading={isLoading}
                //statusCode={statusCode}
                showCardHeader={(statusCode !== undefined &&
                    statusCode !== null &&
                    !Number.isNaN(statusCode)) ?
                    true
                    :
                    false
                }
            >
                <CustomerListToolbar
                    customerParams={customerParams}
                    customerPhone={customerPhone}
                    getCustomers={getCustomers}
                    hideCustomerList={hideCustomerList}
                    searchFlag={handleSearchFlag}
                    handleSearchFlag={handleSearchFlag}
                    customerListData={customerListData}
                    importCustomerData={importCustomerData}
                    handleChangeSearchKeyword={handleChangeSearchKeyword}
                />
                {(toggleFilter === true) &&
                <CustomerListFilter
                    {...props}
                    isSolshare={isSolshare}
                    b2bOrgGuid={b2bOrgGuid}
                    gridOptions={gridOptions}
                    handleSetGridOptions={handleSetGridOptions}
                    divisionOptions={divisionOptions}
                    division={division}
                    handleChangeDivision={handleChangeDivision}
                    grid={grid}
                    handleChangeGrid={handleChangeGrid}
                    customerType={customerType}
                    handleChangeCustomerType={handleChangeCustomerType}
                    customerStatus={customerStatus}
                    handleChangeCustomerStatus={handleChangeCustomerStatus}
                    customerPhone={customerPhone}
                    handleChangeSolTextInput={handleChangeSolTextInput}
                    reset={reset}
                    getCustomers={getCustomers}
                    setCustomerListParams={setCustomerListParams}
                    handleDisplayGridNames={handleDisplayGridNames}
                    toggleGetCustomers={toggleGetCustomers}
                    filterApplied={filterApplied}
                    toggleFilterApplied={toggleFilterApplied}
                    toggleCustomerListFilter={toggleCustomerListFilter}
                />
                }
                {(getCustomers === true) &&
                <DataTable
                    columns={customerListColumns}
                    data={customerListData}
                    isLoading={tableLoading}
                    search={false}
                    count={customerListData.length}
                    asyncPagination={true}
                    // onRowClick={onRowClick}
                />
                }
            </ContentWrapper>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.customerReducer.isLoading,
        tableLoading: state.customerReducer.tableLoading,
        statusCode: state.customerReducer.statusCode,
        customerList: state.customerReducer.customerList,
    }
};

export default connect(mapStateToProps, customerActions)(withRoles(CustomerList));