import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../_metronic/_partials/controls";
import ContentWrapper from "../../components/contentWrapper/contentWrapper";

const DemoComponent = props => {
    return (
        <div>
            {/*<Card>*/}
            {/*    <CardHeader title="Customers list">*/}
            {/*        <CardHeaderToolbar>*/}
            {/*            <button*/}
            {/*                type="button"*/}
            {/*                className="btn btn-primary"*/}
            {/*            >*/}
            {/*                Dummy Button*/}
            {/*            </button>*/}
            {/*        </CardHeaderToolbar>*/}
            {/*    </CardHeader>*/}
            {/*    <CardBody>*/}
            {/*        <h1 style={{*/}
            {/*            margin: '0 auto'*/}
            {/*        }}>Hello World</h1>*/}
            {/*    </CardBody>*/}
            {/*</Card>*/}
            <ContentWrapper showBackButton={false} isLoading={false} serverError={false} permissionDenied={false} pageTitle={"Dummy Component"} showUtilityButton={<button
                type="button"
                className="btn btn-primary"
            >
                Dummy Button
            </button>}>
                <h1>Hello World</h1>
            </ContentWrapper>
        </div>
    );
};

DemoComponent.propTypes = {

};

export default DemoComponent;