import {solboxActionTypes} from "./solboxActionTypes";
import {toast} from "react-toastify";
import {requestCycle} from "./solboxUtils";

const initialState = {
    isLoading: false,
    solboxList: [],
    paginatedSolboxList: [],
    gridOwnersList: [],
    gridList: null,
    modelList: [],
    solboxGenericData: null,
    topicPublished: "",
    bulkLiveData: [],
    performanceMetricsData: [],
    configurationHistory: [],
    transactionTypes: null,
    transactionWorth: 0,
    transactionHistory: null,
    updatingSolboxInfo: false,
    nodeTypes: undefined,
    otaUpdateStatus: undefined
};

export const solboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case solboxActionTypes.GET_NODE_TYPES_REQUEST:
            return {
                ...state,
                nodeTypes: undefined,
                nodeTypesFilterLoading: true
            }

        case solboxActionTypes.GET_NODE_TYPES_SUCCESS:
            return {
                ...state,
                nodeTypes: action.payload,
                nodeTypesFilterLoading: false
            }

        case solboxActionTypes.GET_NODE_TYPES_FAILURE:
            return {
                ...state,
                nodeTypes: undefined,
                nodeTypesFilterLoading: false
            }

        case solboxActionTypes.UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_REQUEST:
            return {
                ...state,
                updatingSolboxInfo: true,
                solboxInfoUpdateStatus: undefined
            }

        case solboxActionTypes.UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_SUCCESS:
            toast.success('SOLbox info has been Updated');
            return {
                ...state,
                updatingSolboxInfo: false,
                solboxInfoUpdateStatus: requestCycle.success
            }

        case solboxActionTypes.UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO_FAILURE:
            toast.error('SOLbox info could not be updated');
            return {
                ...state,
                updatingSolboxInfo: false,
                solboxInfoUpdateStatus: requestCycle.failed
            }

        case solboxActionTypes.FETCH_SOLBOX_LIFE_CYCLE_STAGE_REQUEST:
            return {
                ...state,
                lifeCycleStageListLoading: true,
                lifeCycleStageList: []
            }

        case solboxActionTypes.FETCH_SOLBOX_LIFE_CYCLE_STAGE_SUCCESS:
            return {
                ...state,
                lifeCycleStageListLoading: false,
                lifeCycleStageList: action.payload
            }

        case solboxActionTypes.FETCH_SOLBOX_LIFE_CYCLE_STAGE_FAILURE:
            return {
                ...state,
                lifeCycleStageListLoading: false,
                lifeCycleStageList: action.payload.status
            }

        case solboxActionTypes.FETCH_SOLBOX_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
                firmwareListLoading: true,
                solboxfirmwarelist: []
            }

        case solboxActionTypes.FETCH_SOLBOX_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                firmwareListLoading: false,
                solboxfirmwarelist: action.payload
            }

        case solboxActionTypes.FETCH_SOLBOX_FIRMWARE_LIST_FAILURE:
            return {
                ...state,
                firmwareListLoading: false,
                solboxfirmwarelist: action.payload.status
            }

        case solboxActionTypes.GET_SOLBOX_LIST_REQUEST:
            return {
                ...state,
                csvDataLoading: true,
            };

        case solboxActionTypes.GET_SOLBOX_LIST_SUCCESS:
            return {
                ...state,
                csvDataLoading: false,
                solboxList: Array.isArray(action.payload) ? action.payload : [],
            };

        case solboxActionTypes.GET_SOLBOX_LIST_FAILURE:
            return {
                ...state,
                csvDataLoading: false,
                statusCode: action.payload.status
            };

        case solboxActionTypes.CLEAN_UP_SOLBOX_LIST:
            return {
                ...state,
                solboxList: [],
                solboxInfoUpdateStatus: undefined,
            };

        case solboxActionTypes.GET_PAGINATED_SOLBOX_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                tableLoading: true,
            };

        case solboxActionTypes.GET_PAGINATED_SOLBOX_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                pageCount: action.payload.count,
                pageSize: action.payload.page_size,
                paginatedSolboxList: Array.isArray(action.payload.results) ? action.payload.results : [],
            };

        case solboxActionTypes.GET_PAGINATED_SOLBOX_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status
            };

        case solboxActionTypes.GET_FILTERED_SOLBOX_LIST_REQUEST:
            return {
                ...state,
                isLoading: false,
                tableLoading: true,
            };

        case solboxActionTypes.GET_FILTERED_SOLBOX_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                pageCount: action.payload.count,
                pageSize: action.payload.page_size,
                paginatedSolboxList: Array.isArray(action.payload.results) ? action.payload.results : [],
            };

        case solboxActionTypes.GET_FILTERED_SOLBOX_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_SEARCHED_SOLBOX_LIST_REQUEST:
            return {
                ...state,
                isLoading: false,
                tableLoading: true,
            };

        case solboxActionTypes.GET_SEARCHED_SOLBOX_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                pageCount: action.payload.count,
                pageSize: action.payload.page_size,
                paginatedSolboxList: Array.isArray(action.payload.results) ? action.payload.results : [],
            };

        case solboxActionTypes.GET_SEARCHED_SOLBOX_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_MODELS_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                statusCode: undefined,
            };

        case solboxActionTypes.GET_MODELS_LIST_SUCCESS:
            return {
                ...state,
                modelList: Array.isArray(action.payload) ? action.payload : [],
                isLoading: false,
                statusCode: undefined,
            };

        case solboxActionTypes.GET_MODELS_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_GRID_LIST_WITHOUT_PAGINATION_REQUEST:
            return {
                ...state,
                isGridListLoading: true,
                statusCode: undefined,
            };

        case solboxActionTypes.GET_GRID_LIST_WITHOUT_PAGINATION_SUCCESS:
            return {
                ...state,
                isGridListLoading: false,
                gridList: Array.isArray(action.payload) ? action.payload : [],
            };

        case solboxActionTypes.GET_GRID_LIST_WITHOUT_PAGINATION_FAILURE:
            return {
                ...state,
                isGridListLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_GRID_OWNERS_LIST_REQUEST:
            return {
                ...state,
                statusCode: undefined,
            };

        case solboxActionTypes.GET_GRID_OWNERS_LIST_SUCCESS:
            return {
                ...state,
                gridOwnersList: Array.isArray(action.payload) ? action.payload : [],
                statusCode: undefined,
            };

        case solboxActionTypes.GET_GRID_OWNERS_LIST_FAILURE:
            return {
                ...state,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_SOLBOX_GENERAL_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                solboxGenericData: null,
            };

        case solboxActionTypes.GET_SOLBOX_GENERAL_DATA_SUCCESS:
            return {
                ...state,
                isLoading: true,
                solboxGenericData: action.payload,
            };

        case solboxActionTypes.GET_SOLBOX_GENERAL_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.POST_SOLBOX_PUBLISH_TOPIC_REQUEST:
            return {
                ...state,
                isLoading: true,
                topicPublished: "",
            };

        case solboxActionTypes.POST_SOLBOX_PUBLISH_TOPIC_SUCCESS:
            return {
                ...state,
                isLoading: true,
                topicPublished: action.payload.message ? action.payload.message : "",
            };

        case solboxActionTypes.POST_SOLBOX_PUBLISH_TOPIC_FAILURE:
            return {
                ...state,
                isLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_SOLBOX_BULK_LIVE_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                bulkLiveData: [],
            };

        case solboxActionTypes.GET_SOLBOX_BULK_LIVE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                topicPublished: "",
                bulkLiveData: Array.isArray(action.payload) ? action.payload : [],
            };

        case solboxActionTypes.GET_SOLBOX_BULK_LIVE_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_SOLBOX_PERFORMANCE_METRICS_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                performanceMetricsData: [],
            };

        case solboxActionTypes.GET_SOLBOX_PERFORMANCE_METRICS_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                performanceMetricsData: Array.isArray(action.payload)
                    ? action.payload
                    : [],
            };

        case solboxActionTypes.GET_SOLBOX_PERFORMANCE_METRICS_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_SOLBOX_CONFIGURATION_HISTORY_REQUEST:
            return {
                ...state,
                isLoading: false,
                tableLoading: true,
            };

        case solboxActionTypes.GET_SOLBOX_CONFIGURATION_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                configurationHistory: Array.isArray(action.payload.results) ? action.payload.results : [],
            };

        case solboxActionTypes.GET_SOLBOX_CONFIGURATION_HISTORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.FETCH_TRANSACTION_TYPES_REQUEST:
            return {
                ...state,
                isTransactionTypesLoading: true
            };

        case solboxActionTypes.FETCH_TRANSACTION_TYPES_SUCCESS:

            return {
                ...state,
                isTransactionTypesLoading: false,
                transactionTypes: Array.isArray(action.payload) ? action.payload : []
            };

        case solboxActionTypes.FETCH_TRANSACTION_TYPES_FAILURE:
            return {
                ...state,
                isTransactionTypesLoading: false,
                statusCode: action.payload.status,
            };

        case solboxActionTypes.GET_TRANSACTION_HISTORY_LIST_REQUEST:
            return {
                ...state,
                isLoading: false,
                tableLoading: true,
                //transactionHistory: null
            };

        case solboxActionTypes.GET_TRANSACTION_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                pageCount: action.payload.count,
                pageSize: action.payload.page_size,
                transactionWorth: action.payload.total_worth,
                transactionHistory: Array.isArray(action.payload.results) ? action.payload.results : []
            };

        case solboxActionTypes.GET_TRANSACTION_HISTORY_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status
            };

        case solboxActionTypes.CREATE_SOLBOX_REQUEST:
            return {
                ...state,
                disableButton: true,
                serverError: undefined,
                newSolboxList: undefined,
                solboxCreated: undefined,
            }
            
        case solboxActionTypes.CREATE_SOLBOX_SUCCESS:
            toast.success("Request successful");
            return {
                ...state,
                disableButton: false,
                serverError: false,
                newSolboxList: action.payload,
                solboxCreated: true,
            }

        case solboxActionTypes.CREATE_SOLBOX_FAILURE:
            toast.error("Request Failed");
            return {
                ...state,
                disableButton: false,
                serverError: true,
                solboxCreated: false,
            }

        case solboxActionTypes.GET_SOLBOX_FIRMWARE_VERSION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
    
        case solboxActionTypes.GET_SOLBOX_FIRMWARE_VERSION_SUCCESS:
        return {
            ...state,
            isLoading: false,
            solboxData: action.payload
        };
    
        case solboxActionTypes.GET_SOLBOX_FIRMWARE_VERSION_FAILURE:
        return {
            ...state,
            isLoading: false,
            statusCode: action.payload.status
        };

        case solboxActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
          
        case solboxActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                solChartBalanceData: action.payload ? Array.isArray(action.payload.data) ? action.payload.data : [] : [],
                isEsp: action.payload.is_esp32
            };
    
        case solboxActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                solChartBalanceData: [],
                statusCode: action.payload.status
            };
    
        case solboxActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
    
        case solboxActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                solChartI2GridData: action.payload ? Array.isArray(action.payload.data) ? action.payload.data : [] : []
            };
    
        case solboxActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                solChartI2GridData: [],
                statusCode: action.payload.status
            };

        case solboxActionTypes.FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
    
        case solboxActionTypes.FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                solChartAvailabilityData: action.payload ? Array.isArray(action.payload.data) ? action.payload.data : [] : []
            };
    
        case solboxActionTypes.FETCH_SOLBOX_DATA_AVAILABILITY_CHART_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                solChartAvailabilityData: [],
                statusCode: action.payload.status
            };

        case solboxActionTypes.FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
    
        case solboxActionTypes.FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                solChartBatteryVoltageData: action.payload ? Array.isArray(action.payload.data) ? action.payload.data : [] : []
            };
    
        case solboxActionTypes.FETCH_SOLBOX_BATTERY_VOLTAGE_CHART_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                statusCode: action.payload.status
            };

        case solboxActionTypes.FETCH_SOLBOX_TRADING_CHART_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case solboxActionTypes.FETCH_SOLBOX_TRADING_CHART_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                solChartTradingData: action.payload ? Array.isArray(action.payload.data) ? action.payload.data : [] : []
            };

        case solboxActionTypes.FETCH_SOLBOX_TRADING_CHART_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                statusCode: action.payload.status
            };

        case solboxActionTypes.SEND_OTA_UPDATE_REQUEST:
            return {
                ...state,
                otaUpdateProcessing: true,
                otaUpdateStatus: undefined
            };
        case solboxActionTypes.SEND_OTA_UPDATE_SUCCESS:
            toast.success('OTA is in queue!');
            return {
                ...state,
                otaUpdateProcessing: false,
                otaUpdateStatus: requestCycle.success
            };
        case solboxActionTypes.SEND_OTA_UPDATE_FAILURE:
            toast.error('Failed to initiate the OTA!');
            return {
                ...state,
                otaUpdateProcessing: false,
                otaUpdateStatus: requestCycle.failed
            };

        default:
            return state;
    }
};
