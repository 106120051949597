import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar, useStylesIconButton} from "../components";
import FilterFormContainer from "./SmartBoardFilterForm";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {Button, Spinner} from "react-bootstrap";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import DataTable from "../../../../components/dataTable/DataTable";
import {smartboardListColumns} from "./smartboardColumns";
import {DataTableContainer, requestCycle} from "../../../grids/utils";
import TrackerBoard from "./TrackerBoard"
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {connect} from "react-redux";
import {smartboardActions as actions} from "../../smartboardActions"
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat"
import ResolvedBody from "./ResolvedBody";
import {CustomerType_SmartBoard} from "../../../customer/views/components";
import moment from "moment";
import {Link} from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import {useSubheader} from "../../../../../_metronic/layout";
import { ExportToCsv } from 'export-to-csv';
import {
    displayBangladeshiMobileNo,
    getShsSetupString,
    isNullorUndefined,
    JsonToUriSerialize,
    parseParams, pluralizer
} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";


const FormLabel = (props) => {
    return <label className={`form-check-label d-block mb-1`} style={{color: '#8C8C9B'}}>
        {props.children}
    </label>
}

const TextFieldStyle = (props) => {
    return <>
        <div>
            <SolTypography.Text
                style={{color: props.secondary ? '#8C8C9B' : undefined}}>{props.children}</SolTypography.Text>
        </div>
    </>
}

const SmartboardList = props => {
    const classes = useStylesIconButton();
    const [filter, setFilter] = useState(false);
    const [gridList, setGridList] = useState([]);
    const [gridOperatorList, setGridOperatorList] = useState([]);
    const [solboxModelList, setSolboxModelList] = useState([]);
    const [firmwareVersionList, setfirmwareVersionList] = useState([]);
    const [issueTypeList, setIssueTypeList] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [trackerissueList, setTrackerIssueList] = useState([]);
    const [exportInCsv, setExportInCsv] = useState(false);
    const [iconLoading, setIconLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [issueLength, setissueLength] = useState();
    const [selectedIssueType, setSelectedIssueType] = useState(null);
    const [filterData, setFilterData] = useState({});
    const [page, setPage] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [refreshTimerString, setRefreshTimerString] = useState('');
    const [timer, setTimer] = useState(null);
    const [projectedParams, setProjectedParams] = useState(null);
    const subHeader = useSubheader();


    useEffect(() => {
        subHeader.setActionButtons(<>
            <div>
                <b><SolTypography.Text secondary>{refreshTimerString}</SolTypography.Text></b> &nbsp;&nbsp;&nbsp;
                <Button className={'btn btn-outline-warning'} size={'sm'} onClick={refreshWithCurrentFilter}
                        disabled={props.filterTriggeredLoading}>

                    <div style={{
                        display: "inline-block",
                    }}><img src={require('../../../../utils/asset/refresh.svg')} alt="refresh button"/>
                        &nbsp;
                        <span className="justify-content-md-center no-padding"> Refresh Issues</span>

                    </div>
                </Button>
            </div>

        </>)

        return () => {
            subHeader.setActionButtons(null)
        }
    }, [refreshTimerString, props.filterTriggeredLoading])

    useEffect(() => {

        const trackerissues = props.trackerissueList;

        if (trackerissues && trackerissues.length >= 0) {
            setTrackerIssueList(trackerissues.map((trackerissue) => {
                return {
                    ...trackerissue,
                    label: trackerissue.issue_name,
                    count: trackerissue.issue_count,
                    value: trackerissue.anomaly_trigger__trigger_slug,
                }
            }))
        }
    }, [props.trackerissueList])

    useEffect(() => {
        setFilterData({issue_status: 'pending'});
        props.GET_GRID_LIST_SMARTBOARD();
        props.GET_GRID_OPERATOR_LIST();
        props.GET_SOLBOX_MODEL_LIST();
        props.GET_FIRMWARE_VERSION();
        props.GET_ISUE_TYPE_LIST();
        props.GET_ISUE_LIST({issue_status: 'pending'}, true);
    }, [])

    useEffect(() => {
        setIconLoading(props.iconLoading)
    }, [props.iconLoading])


    useEffect(() => {

        setRefreshTimerString('Refreshed just now');
        const interval = setInterval(timeChecker, 10000);

        return () => clearInterval(interval);
    }, [props.refreshTime])


    useEffect(() => {

        if (!timer) {
            setRefreshTimerString('Refreshed just now');
        } else if (Number.isInteger(timer) && timer < 60) {
            setRefreshTimerString(`Refreshed ${timer} ${timer > 1 ? 'minutes' : 'minute'} ago`);
        } else if (Number.isInteger(timer) && timer >= 60) {
            setRefreshTimerString(`Refreshed ${Math.floor(timer / 60)} ${Math.floor(timer / 60) > 1 ? 'hours' : 'hour'} and ${Math.floor(timer % 60)} minutes ago`)
        } else {
            setRefreshTimerString(null)
        }

    }, [timer])


    const timeChecker = () => {
        const temp = moment().diff(props.refreshTime, 'minutes')

        if (temp !== timer) {
            setTimer(temp)
        }

    }

    const getFilterGrid = (payload) => {
        if (projectedParams && Object.keys(projectedParams).length>0 && Object.keys(payload)?.length>0 ){

            const temp = {
                ...(projectedParams),
            }
            Object.keys(payload).forEach((item)=>{
                if (!isNullorUndefined(payload[item])){
                    Object.assign(temp,  {[`${item}`] : payload[item] })
                }else {
                    delete temp[item]
                }

            })
            props.history.push({
                search: JsonToUriSerialize(processFilterParams(temp)),
            });
        }

        props.GET_GRID_LIST_SMARTBOARD({
            organization_guid : payload.grid_operator_guid,
            division : payload.division
        });

    }


    useEffect(() => {
        if (props.resolveSuccess === requestCycle.success) {
            setModal(false);
            refreshWithCurrentFilter();
        }
    }, [props.resolveSuccess])


    //Mapping For Dropdown

    useEffect(() => {
        const grids = props.gridList;
        const operators = props.gridOperatorList;
        const solboxModels = props.solboxModelList;
        const firmwares = props.firmwareVersionList;
        const issueTypes = props.issueTypeList;

        if (grids && grids.length >= 0) {

            setGridList(grids.map((grid) => {
                return {
                    label: grid.name,
                    value: grid.grid_guid,
                }
            }));
        }
        if (operators && operators.length >= 0) {
            setGridOperatorList(operators.map((operator) => {
                return {
                    label: operator.organization_name,
                    value: operator.organization_guid,
                }
            }));
        }
        if (solboxModels && solboxModels.length >= 0) {
            setSolboxModelList(solboxModels.map((solboxModel) => {
                return {
                    label: solboxModel.model_alias,
                    value: solboxModel.model_alias,
                }
            }));
        }
        if (firmwares && firmwares.length >= 0) {
            setfirmwareVersionList(firmwares.map((firmware) => {
                return {
                    label: firmware.firmware_version,
                    value: firmware.firmware_version,
                }
            }));
        }
        if (issueTypes && issueTypes.length >= 0) {
            setIssueTypeList(issueTypes.map((issueType) => {
                return {
                    label: issueType.trigger_name,
                    value: issueType.trigger_slug,
                }
            }));
        }


    }, [props.gridList, props.gridOperatorList, props.solboxModelList, props.firmwareVersionList, props.issueTypeList])


    useEffect(() => {
        const issues = props.issueList
        if (exportInCsv) {
            setExportInCsv(false)
            let currentDate = moment().format("DD_MMM_YYYY")
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: `` ,
                filename: `Issue_list_` + currentDate,
                useTextFile: false,
                useBom: true,
                headers: ['ID', 'Date', 'Grid', 'Issue', 'Status', 'SOLbox', 'Type', 'Customer',
                    'Customer phone number', 'SAM', 'SAM phone number', 'Router', 'Resolved on',
                    'Resolved by', 'Details of the resolve process'],
            };
            const csvExporter = new ExportToCsv(options);
            if (issues && issues.results && issues.results.length >= 0) {
                const issuesForCsvExport = issues.results.map((issue, index)=>{
                    return {
                        id: issue.issue_id,
                        date: new DateTimeFormat(issue.issue_date).localDisplayTime,
                        grid: issue.grid? (issue.grid.name? issue.grid.name: 'N/A'): 'N/A',
                        issue: issue.issues,
                        status: issue.is_resolved? 'Resolved': 'Pending',
                        solbox: issue.solbox_serial_number? issue.solbox_serial_number: 'N/A',
                        type: issue.customer_type? issue.customer_type: 'N/A',
                        customer: issue.customer? (issue.customer.customer_name? issue.customer.customer_name: 'N/A'): 'N/A',
                        customerPhoneNumber: issue.customer? (issue.customer.customer_phone? issue.customer.customer_phone: 'N/A'): 'N/A',
                        sam: issue.grid? (issue.grid.am_name? issue.grid.am_name: 'N/A'): 'N/A',
                        samPhoneNumber: issue.grid? (issue.grid.am_phone? issue.grid.am_phone: 'N/A'): 'N/A',
                        router: issue.router_name? issue.router_name: 'N/A',
                        resolvedOn: issue.resolved_date? new DateTimeFormat(issue.resolved_date).localDisplayTime: 'N/A',
                        resolvedBy: issue.resolved_by_name? issue.resolved_by_name: 'N/A',
                        resolveProcessDetails: issue.resolved_comment? issue.resolved_comment: 'N/A'
                    }
                });
                csvExporter.generateCsv(issuesForCsvExport);
            } else {
                csvExporter.generateCsv([{}]);
            }
        } else {
            if (issues && issues.results && issues.results.length >= 0) {
                setIssueList(issues.results.map((issue) => {
                    return {
                        ...issue,
                        id: <>
                            <div><SolTypography.Text>{issue.issue_id}</SolTypography.Text></div>
                        </>,
                        issueReplatedTo: <>
                            <div className={'col-md-12 no-padding'}>
                                <div className={'row'} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0',
                                    marginLeft: '0px'

                                }}>
                                    <div className={'col-md-2 no-padding'}>
                                        {issue.customer_type === "Prosumer" ?
                                            <CustomerType_SmartBoard>PRO</CustomerType_SmartBoard> : null}
                                        {issue.customer_type === "Producer" ?
                                            <CustomerType_SmartBoard>PRD</CustomerType_SmartBoard> : null}
                                        {issue.customer_type === "Consumer" ?
                                            <CustomerType_SmartBoard>CO</CustomerType_SmartBoard> : null}
                                        {issue.customer_type === "Microutility" ?
                                            <CustomerType_SmartBoard>MU</CustomerType_SmartBoard> : null}
                                    </div>
                                    <div className={'col-md-10 no-padding'}>
                                        {issue.entity_type === 'node' ? renderIssueRelatedForNode(issue) : renderIssueRelatedForGrid(issue)}
                                    </div>
                                </div>
                            </div>
                        </>,
                        issueReplatedToForModal: <>
                            {issue.entity_type === 'node' ? renderIssueRelatedForNode(issue, true) : renderIssueRelatedForGrid(issue, true)}
                        </>,
                        issueDate: <>
                            <div>
                                <SolTypography.Text>{new DateTimeFormat(issue.issue_date).localDisplayTime}</SolTypography.Text>
                            </div>
                        </>,

                        solbox: <>
                            {issue.entity_type === 'node' ?
                                <SolTypography.Text primary>
                                    <Link to={`/solboxes/profile/${issue.solbox_serial_number}/${issue.solbox_guid}`}
                                          target={'_blank'} style={{color: "#F18D00",}}>
                                        {issue.solbox_serial_number}
                                    </Link>
                                </SolTypography.Text> :
                                <TextFieldStyle secondary>N/A</TextFieldStyle>}
                        </>,

                        issue: <>
                            <SolTypography.Text>{issue.issues}</SolTypography.Text>
                        </>,

                        resolvedOn: <>
                            <SolTypography.Text>{issue.is_resolved === false ?
                                <TextFieldStyle secondary>N/A</TextFieldStyle>
                                : renderResolvedOnData(issue)}</SolTypography.Text>
                        </>

                    }
                }));
            }
        }
    }, [props.issueList])

    useEffect(()=>{
        if ( props.isSolshare === false && props.b2bOrgGuid ){
            setProjectedParams({
                ...(projectedParams),
                grid_operator_guid: props.b2bOrgGuid,
            })
        }
    },[props.b2bOrgGuid,props.isSolshare])


    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);

            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                // alert(temp);
                if ( props.isSolshare === false && props.b2bOrgGuid ){
                    Object.assign(temp, {grid_operator_guid: props.b2bOrgGuid})
                }
                setProjectedParams({...temp});
            }
        } catch (e) {
            console.debug(e);
        }
    }, [props.history.location.search]);


    const renderResolvedOnData = (resolvedInfo) => {
        return <>
            {resolvedInfo.resolved_date ?
                <SolTypography.Text><b>{new DateTimeFormat(resolvedInfo.resolved_date).localDisplayTime}</b></SolTypography.Text> :
                <TextFieldStyle secondary>N/A</TextFieldStyle>}
            <div>
                {resolvedInfo.resolved_by_name ?
                    <SolTypography.Text>by {resolvedInfo.resolved_by_name}</SolTypography.Text> :
                    <TextFieldStyle secondary>N/A</TextFieldStyle>}
            </div>
            <div>
                {resolvedInfo.resolved_comment ?
                    <SolTypography.Text>{resolvedInfo.resolved_comment}</SolTypography.Text> :
                    <TextFieldStyle secondary>N/A</TextFieldStyle>}
            </div>

        </>
    }


    const processFilterParams = (payload) => {
        const filterParams = {}
        Object.keys(payload).forEach((item) => {
            if (payload[item]) {
                filterParams[item] = payload[item];
            }
        });
        return filterParams;
    }

    const onFilter = (data, changeURL = true) => {
        const getFilterData = processFilterParams(data);
        setFilterData(getFilterData)

        if (changeURL) {
            props.history.push({
                search: JsonToUriSerialize(processFilterParams(data)),
            });
        }
        if (Object.keys(processFilterParams(data))?.length > 0) {
            if (searchText) {
                props.GET_ISUE_LIST({...getFilterData, solbox_serial_number: searchText});
            } else {
                props.GET_ISUE_LIST(getFilterData)
            }
        }


    }

    const trackerBoardFilter = (data) => {

        if (searchText) {
            props.GET_ISUE_LIST({...filterData, solbox_serial_number: searchText});
        } else {
            props.GET_ISUE_LIST({...filterData, issue_type: data.label})
        }

    }

    const clearFilterData = () => {
        setFilterData({issue_status: 'pending'});
    }

    const filterController = () => {
        setFilter(!filter);
    }

    const resetFilter = () => {
        if (Object.keys(filterData).length > 0) {
            setPage(null)
            setSearchText(null)
            if ( props.isSolshare === false && props.b2bOrgGuid ) {
                setProjectedParams({
                    grid_operator_guid: props.b2bOrgGuid,
                });
            }else {
                setProjectedParams(null);
            }
            setFilterData({});
            setSelectedIssueType(null);
            props.history.push({
                search: undefined,
            });
            props.GET_ISUE_LIST({issue_status: 'pending'}, true)
        }
    }

    const refreshWithCurrentFilter = () => {

        if (filterData) {
            if (searchText) {
                props.GET_ISUE_LIST({...filterData, solbox_serial_number: searchText, page: page})
            } else {
                props.GET_ISUE_LIST({...filterData, page: page})
            }
        } else {
            if (searchText) {
                props.GET_ISUE_LIST({page: page, solbox_serial_number: searchText});
                props.GET_ISUE_LIST({solbox_serial_number: searchText, page: page})
            } else {
                props.GET_ISUE_LIST({page: page});
                props.GET_ISUE_LIST({page: page})
            }
        }

    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterData) {
            if (searchText) {
                props.GET_ISUE_LIST({...filterData, page: newPage, solbox_serial_number: searchText})
            } else {
                props.GET_ISUE_LIST({...filterData, page: newPage})
            }
        } else {
            if (searchText) {
                props.GET_ISUE_LIST({page: newPage, solbox_serial_number: searchText})
            } else {
                props.GET_ISUE_LIST({page: newPage})
            }
        }
    }

    const onSearch = (text) => {
        if (text === '' && !searchText) {
            return;
        }
        setPage(null);
        if (filterData) {
            props.GET_ISUE_LIST({...filterData, solbox_serial_number: text})
        } else {
            props.GET_ISUE_LIST({solbox_serial_number: text})
        }
        setSearchText(text)
    }

    const resolveIssue = (data) => {
        props.RESOLVE_ISSUE(data)
    }

    const render_selected_issue = (item) => {
        setFilter(true);
        setSelectedIssueType(item);
    }

    const returnPlus = (phone_number) => {
        if (phone_number?.includes("+")) {
            return phone_number
        } else if (!phone_number) {
            return 'N/A'
        } else {
            return `+${phone_number}`
        }
    }

    const getCustomerName = (data) => {
        if (data.customer_type === 'Microutility') {
            return `Microutility-${data.solbox_serial_number.toString().slice(4)}`
        } else {
            return data?.customer?.customer_name || "N/A"
        }
    }

    const renderIssueRelatedForNode = (issue, modalFlag = false) => {
        return <>
            {issue && issue.customer ?
                <SolTypography.Text primary={!modalFlag}>
                    <Link
                        // to={'/customers/profile'}
                        style={{color: "#F18D00",}}
                        target={'_blank'}
                        to={{
                            pathname: `/customers/profile/${issue?.customer?.customer_guid}/${issue.solbox_guid}/${issue.solbox_serial_number}/${issue.customer_type}`,
                            state: {
                                solbox_guid: issue.solbox_guid,
                                customer_guid: issue.customer.customer_guid,
                                solbox_serial_number: issue.solbox_serial_number,
                                customer_type: issue.customer_type,
                            },
                        }}

                    >
                        {getCustomerName(issue)}
                    </Link>
                </SolTypography.Text> :
                <TextFieldStyle secondary>N/A</TextFieldStyle>}

            {issue && issue.customer ?
                <TextFieldStyle
                    secondary={!modalFlag}>{issue.customer.customer_phone ? displayBangladeshiMobileNo(issue.customer.customer_phone) : 'N/A'}</TextFieldStyle> :
                <TextFieldStyle secondary>N/A</TextFieldStyle>}

            {issue && issue.customer ?
               /* <TextFieldStyle secondary={!modalFlag}>Bought: {issue.total_bought} Tk,
                    Sold: {issue.total_sold} Tk </TextFieldStyle> : null*/
                <div>
                    { issue.customer_type === "Microutility" || issue.customer_type === "Producer"?
                        <TextFieldStyle secondary={!modalFlag}>Sold: {issue.total_sold} Tk </TextFieldStyle>
                        : issue.customer_type === "Consumer" ?
                            <TextFieldStyle secondary={!modalFlag}>Bought: {issue.total_bought} Tk</TextFieldStyle> :
                            issue.customer_type === "Prosumer" ?<TextFieldStyle secondary={!modalFlag}>Bought: {issue.total_bought} Tk,
                                Sold: {issue.total_sold} Tk </TextFieldStyle> : null
                    }
                </div> : <TextFieldStyle secondary>N/A</TextFieldStyle>}

            {issue && issue.grid ?
                <div><SolTypography.Text primary={!modalFlag}>
                    <Link to={`/grids/details/${issue.grid.grid_guid}`} target={'_blank'} style={{color: "#F18D00",}}>
                        {issue.grid.name}
                    </Link>
                </SolTypography.Text>
                </div> : <TextFieldStyle secondary>N/A</TextFieldStyle>}

            {issue && issue.customer && issue.customer_type !== 'Consumer' ? <div>
                <TextFieldStyle
                    secondary={!modalFlag}>SHS: {getShsSetupString({
                    customer_shs_info: {
                        ...issue.customer
                    }
                })} </TextFieldStyle>
            </div> : null   //<TextFieldStyle secondary>N/A</TextFieldStyle>
            }
        </>

    }
    const renderIssueRelatedForGrid = (issue, modalFlag = false) => {
        return <>
            {issue && issue.grid ?
                <div><SolTypography.Text primary={!modalFlag}>
                    <Link to={`/grids/details/${issue.grid.grid_guid}`} target={'_blank'} style={{color: "#F18D00",}}>
                        {issue.grid.name}
                    </Link>
                </SolTypography.Text>
                </div> : <TextFieldStyle secondary>N/A</TextFieldStyle>}

            {issue ? <div>
                <TextFieldStyle
                    secondary={!modalFlag}>Router: {issue.router_name || "N/A"} </TextFieldStyle>
            </div> : <TextFieldStyle secondary>N/A</TextFieldStyle>
            }

            {issue && issue.grid ?
                <TextFieldStyle
                    secondary={!modalFlag}>SAM: {issue.grid?.am_name || 'N/A'} ({issue.grid ? displayBangladeshiMobileNo(issue.grid.am_phone) : 'N/A'})
                </TextFieldStyle> : <TextFieldStyle secondary>N/A</TextFieldStyle>}
        </>

    }

    const exportToCsv = () => {
        setExportInCsv(true)
        if (filterData) {
            if (searchText) {
                props.GET_ISUE_LIST({...filterData, paginated: 'false', solbox_serial_number: searchText})
            } else {
                props.GET_ISUE_LIST({...filterData, paginated: 'false'})
            }
        } else {
            if (searchText) {
                props.GET_ISUE_LIST({paginated: 'false', solbox_serial_number: searchText})
            } else {
                props.GET_ISUE_LIST({paginated: 'false'})
            }
        }
    }

    const ToolBar = <>
        <Toolbar>
            <Toolbar.Title>
                <SolTypography.Title style={{fontWeight: '600'}}>
                    <SolTypography.Text> {pluralizer(props.issueList?.count,"Issue","Issues")} </SolTypography.Text>
                </SolTypography.Title>
            </Toolbar.Title>
            <Toolbar.ToolbarContainer>
                <Toolbar.ToolbarContainer.Items>
                    <IconButton classes={classes} onClick={filterController}>
                        <FilterListIcon color="disabled" fontSize="large"/>
                    </IconButton>
                    {/*<IconButton classes={classes} >
                        {props.iconLoading===true? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>:<CloudDownloadOutlined color="disabled" fontSize="large"/>}
                    </IconButton>*/}
                    <IconButton classes={classes} onClick={exportToCsv}
                                disabled={props.filterTriggeredLoading || props.isLoading || props.tableLoading || iconLoading}>
                        {iconLoading === true ? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :
                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                 alt="Kiwi standing on oval"/>}
                    </IconButton>
                    <SolInput
                        id={'test'}
                        name={'search'}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                onSearch(event.target.value);
                            }
                        }}
                        autoComplete={'off'}
                        onChange={(event) => {
                            if (event.target.value === '') {
                                onSearch(event.target.value);
                            }
                        }}
                        placeholder={'Type SOLbox ID...'}
                    />
                </Toolbar.ToolbarContainer.Items>
            </Toolbar.ToolbarContainer>
        </Toolbar>
    </>


    return (
        <ContentWrapper showCardHeader={false} pageTitle={null} showBackButton={false} isLoading={props.isLoading}
                        loadingText={'Fetching Issues...'}>
            {ToolBar}

            <div className={'col-md-12'}>
                {!filterData?.issue_type && <div className={'row'}>
                    {
                        trackerissueList.map((item) => {
                            return <div className={'col-md-2 no-padding'} onClick={() => render_selected_issue(item)}>
                                <TrackerBoard item={item}/>
                            </div>
                        })

                    }
                </div>}
            </div>

            <div className={'filter-animation'} style={{
                display: filter ? undefined : 'none',
            }}><h4 style={{paddingBottom: "1rem"}}>Filter</h4>
                <FilterFormContainer gridList={gridList} gridOperatorList={gridOperatorList}
                                     solboxModelList={solboxModelList} firmwareVersionList={firmwareVersionList}
                                     issueTypeList={issueTypeList} issueList={issueList}
                                     resetFilter={resetFilter} setListFilters={onFilter}
                                     clearFilterData={clearFilterData} selectedIssueType={selectedIssueType}
                                     getFilterGrid={getFilterGrid} filterDropdownLoading={props.filterDropdownLoading}
                                     projectedParams={projectedParams}/>
            </div>

            {/*<div style={{float: 'right'}}>*/}
            {/*    <b><SolTypography.Text secondary>{refreshTimerString}</SolTypography.Text></b> &nbsp;&nbsp;&nbsp;*/}
            {/*    <Button className={'btn btn-outline-warning'} size={'sm'} onClick={refreshWithCurrentFilter}*/}
            {/*            disabled={props.filterTriggeredLoading}>*/}

            {/*        <div style={{*/}
            {/*            display: "inline-block",*/}
            {/*        }}><img src={require('../../../../utils/asset/refresh.svg')} alt="refresh button"/>*/}
            {/*            &nbsp;*/}
            {/*            <span className="justify-content-md-center no-padding"> Refresh Issues</span>*/}

            {/*        </div>*/}
            {/*    </Button>*/}
            {/*</div>*/}

            <DataTableContainer>
                {props.filterTriggeredLoading !== true ?
                    <DataTable
                        columns={smartboardListColumns}
                        data={issueList}
                        actions={props.isSolshare? [
                            (row_Data) => ({
                                icon: ({hidden}) => (
                                    <button className={'btn btn-outline-warning'}>
                                        Mark Resolved
                                    </button>),
                                onClick: (event, rowData) => {
                                    setSelectedRowData(rowData)
                                    setModal(true);
                                },
                                hidden: row_Data.is_resolved
                            })
                        ]:undefined
                        }
                        asyncPagination={true}
                        itemsPerPage={10}
                        onChangePage={onChangePage}
                        page={page}
                        count={props.issueList?.count}
                        actionText={'Actions'}
                    /> : <Loader size={'sm'} text={"Fetching issues..."}/>}
            </DataTableContainer>


            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }
              //}


            `}</style>
            <GenericModal showModalHeader={true} hideModal={() => setModal(false)}
                          title={`Steps Taken To Resolve The Issue ${selectedRowData?.issue_id || 'N/A'}?`}
                          modal={modal} footer={false} size={"lg"}>
                <ResolvedBody resolvedBodyvalue={selectedRowData} resolve={resolveIssue}
                              resolveLoading={props.resolveLoading}/>
            </GenericModal>

        </ContentWrapper>
    );
}

SmartboardList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        filterDropdownLoading: state.smartboardReducer.filterDropdownLoading,
        gridList: state.smartboardReducer.gridList,
        gridOperatorList: state.smartboardReducer.gridOperatorList,
        solboxModelList: state.smartboardReducer.solboxModelList,
        firmwareVersionList: state.smartboardReducer.firmwareVersionList,
        issueTypeList: state.smartboardReducer.issueTypeList,
        issueList: state.smartboardReducer.issueList,
        trackerissueList: state.smartboardReducer.trackerissueList,
        isLoading: state.smartboardReducer.isLoading,
        iconLoading: state.smartboardReducer.iconLoading,
        filterTriggeredLoading: state.smartboardReducer.filterTriggeredLoading,
        resolveLoading: state.smartboardReducer.resolveLoading,
        resolveSuccess: state.smartboardReducer.resolveSuccess,
        refreshTime: state.smartboardReducer.refreshTime,
    }
}


export default connect(mapStateToProps, actions)(withRoles(SmartboardList));
