import React, { useEffect, useState } from "react";
import { solboxActions }   from "../../solboxActions";
import { connect } from 'react-redux';
import SolTextInput from "../../../../components/soltextinput/SolTextInput";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import { ExportToCsv } from 'export-to-csv';
import { Spinner } from "react-bootstrap";

const SolboxListToolbar = (props) => {

    const { 
        getSolboxes,
        pageNumber,
        pageCount,
        solboxFilterParams,
        solboxId,
        toggleFilter,
        searchFlag,
        handleSearchFlag,
        hideSolboxList,
        solboxList,
        solboxListData,
        csvDataLoading,
        handleChangeSearchKeyword,
        GET_SOLBOX_LIST,
        SEARCH_SOLBOX_LIST,
        CLEAN_SOLBOX_LIST
    } = props;

    const searchSolbox = (event) => {
        if (event.key === 'Enter' &&
            solboxFilterParams !== undefined &&
            solboxId !== undefined &&
            solboxId !== ""
        ) {
            SEARCH_SOLBOX_LIST(solboxId.trim(), null, solboxFilterParams);
            handleSearchFlag(event);
        } else {
            if (event.key === 'Enter' && searchFlag === true  && solboxId === "") {
                SEARCH_SOLBOX_LIST(solboxId.trim(), null, {});
            }
        }
    }

    const [csvLoaderIcon, setCsvLoaderIcon] = useState(false);
    const [exportCsv, setExportCsv] = useState(false);

    const handleClickCsvLoaderIcon = (event) => {
        setExportCsv(true);
        CLEAN_SOLBOX_LIST();
    }

    useEffect(() => {
        if (csvDataLoading !== undefined && csvDataLoading !== null && csvDataLoading === false) {
            setCsvLoaderIcon(false);
        }
        if (csvDataLoading !== undefined && csvDataLoading !== null && csvDataLoading === true) {
            setCsvLoaderIcon(true);
        }
    }, [csvDataLoading]);

    useEffect(() => {
        if (exportCsv === true && solboxId && solboxId.trim() && solboxFilterParams) {
            GET_SOLBOX_LIST(solboxId.trim(), solboxFilterParams);
        } else if (exportCsv === true && !solboxId && solboxFilterParams) {
            GET_SOLBOX_LIST("", solboxFilterParams);
        } else if (exportCsv === true && solboxId && solboxId.trim() && !solboxFilterParams) {
            GET_SOLBOX_LIST(solboxId.trim(), {});
        } else {
            if (exportCsv === true) {
                GET_SOLBOX_LIST("", {});
            }
        }
    }, [exportCsv, solboxId, solboxFilterParams]);

    useEffect(() => {
        if (solboxList &&
            solboxList.length !== 0 &&
            exportCsv !== undefined &&
            exportCsv !== null &&
            exportCsv === true
        ) {    
            const options = { 
                fieldSeparator: ',',
                filename: `solboxes_${moment().format("DD_MM_YYYY")}`,
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true, 
                showTitle: false,
                title: '',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };
            const csvExporter = new ExportToCsv(options);
            let data = [];
            solboxList.forEach((element, index) => {
                data.push({
                    "SOLbox": element.solbox_serial_number ? element.solbox_serial_number : `N/A`,
                    "Firmware": element.solbox_firmware ? element.solbox_firmware : `N/A`,
                    "SOLbox model": element.solbox_model ? element.solbox_model : `N/A`,
                    "Life cycle stage": element.solbox_stage ? element.solbox_stage : `N/A`,
                    "Customer": element.name ? element.name : `N/A`,
                    "Customer phone": element.phone ? element.phone : `N/A`,
                    "Grid": element.grid_namel ? element.grid_name : `N/A`,
                    "Grid owner": element.grid_owner ? element.grid_owner : `N/A`,
                    "Balance": element.last_known_balance !== undefined && element.last_known_balance !== null 
                        ? typeof element.last_known_balance === "number"
                        ? (element.last_known_balance / 100)
                        : (Number.parseInt(element.last_known_balance) / 100)
                        : `N/A`
                });
            });
            csvExporter.generateCsv(data);
            setExportCsv(false);
        }
    }, [solboxList, exportCsv]);

    return (
        <React.Fragment>
            <div className={`row list-toolbar`}>
                <div className={'col-12 col-sm-7'}>
                    <h3 className={`list-bold-text`}>
                        {(pageCount !== undefined && pageCount !== null && pageCount !== 0 && getSolboxes === true) ? 
                            (pageCount < 2) ?
                                `${pageCount} SOLbox` 
                                :
                                `${pageCount} SOLboxes`
                            : 
                            `SOLbox`
                        }
                        </h3>
                </div>
                {(getSolboxes === true) && 
                    <div className={`col-12 col-sm-5`}>
                        <div className={`list-toolbar-actions-part float-right`}>
                            {/* <div className="form-group d-inline-block">
                                <SolTextInput 
                                    name={`idOfSolbox`}
                                    placeHolder="Type SOLbox ID..."
                                    value={solboxId}
                                    handleChange={handleChangeSearchKeyword}
                                    handleKeyDown={searchSolbox}
                                />
                            </div> */}
                            <div className={`list-download-icon d-inline-block ml-5`}>
                                {csvLoaderIcon ?
                                    <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>
                                    :
                                    <img 
                                        className="inter-module-link"
                                        src={require('../../../../utils/asset/download-Button.svg')} 
                                        alt="download icon"
                                        onClick={(event) => handleClickCsvLoaderIcon(event)}
                                    />
                                }
                            </div>
                            <div className={`list-toolbar-actions list-filter-icon d-inline-block ml-5`}>
                                <FilterListIcon 
                                    color="disabled"   
                                    fontSize="large" 
                                    style={{"marginRight": "0.5rem"}} 
                                    onClick={(event) => hideSolboxList(event)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.solboxReducer.isLoading,
        tableLoading: state.solboxReducer.tableLoading,
        csvDataLoading: state.solboxReducer.csvDataLoading,
        statusCode: state.solboxReducer.statusCode,
        solboxList: state.solboxReducer.solboxList,
        pageCount: state.solboxReducer.pageCount
    }
};

export default connect(mapStateToProps, solboxActions)(SolboxListToolbar);