const slugurlPair = {
    grid_backend : process.env.REACT_APP_BACKEND_URL_GRID,
    customer_backend : process.env.REACT_APP_BACKEND_URL_CUSTOMER,
    financial : process.env.REACT_APP_BACKEND_URL_FINANCIAL,
    performance : process.env.REACT_APP_BACKEND_URL_PERFORMANCE,
    default_url : process.env.REACT_APP_BACKEND_BASE_URL
};


export const getBaseUrl = (slug) => {
    return slug ? slugurlPair[slug] : slugurlPair.default_url;
};


