import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";

const FormLabel =(props)=>{
    return <label className={`form-check-label d-block mb-1`} style={{color: '#8C8C9B'}}>
        {props.children}
    </label>
}

const TrackerBoard = props => {
    return (

        <div className={'col-md-12 no-padding'} style={{
            margin: "1.5rem 0 1.5rem 0"

        }}>
            <div className={`mb-2 no-padding`}>
               <FormLabel>
                   {props.item.label}
               </FormLabel>
                <div><b> <SolTypography.Title primary style={{
                    cursor: 'pointer'
                }}>{props.item.count}</SolTypography.Title></b></div>
           </div>
        </div>
    );
};

TrackerBoard.propTypes = {

};

export default TrackerBoard;