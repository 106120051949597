import {actionTypes} from "./actionTypes";
import {exportOtaUpdateListToCsv} from "./utils";
import {toast} from "react-toastify";
import {requestCycle} from "./utils";

/*Initial State for OTA update*/
const initialState= {
    isLoading: false
};

/*Reducer for OTA update's redux store*/
export const otaUpdatesReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_OTA_UPDATE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                tableLoading: true,
                filterTriggeredLoading: true,
                otaUpdateList: undefined,
                otaUpdateStatus: undefined,
                otaUpdateRetryStatus: undefined
            };
        case actionTypes.GET_OTA_UPDATE_LIST_FILTER_REQUEST:
            return {
                ...state,
                filterTriggeredLoading: true,
                tableLoading: true,
                otaUpdateList: undefined
            };
        case actionTypes.GET_OTA_UPDATE_LIST_SUCCESS:
            return {
                ...state,
                otaUpdateList: action.payload,
                isLoading: false,
                tableLoading: false,
                filterTriggeredLoading: false
            };
        case actionTypes.GET_OTA_UPDATE_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                filterTriggeredLoading: false,
                otaUpdateList: undefined
            };

        case actionTypes.GET_OTA_UPDATE_LIST_FOR_EXPORT_REQUEST:
            return {
                ...state,
                iconLoading: true
            };
        case actionTypes.GET_OTA_UPDATE_LIST_FOR_EXPORT_SUCCESS:
            exportOtaUpdateListToCsv(action.payload)
            return {
                ...state,
                iconLoading: false
            };
        case actionTypes.GET_OTA_UPDATE_LIST_FOR_EXPORT_FAILURE:
            return {
                ...state,
                iconLoading: false
            };

        case actionTypes.GET_GRID_OWNERS_LIST_REQUEST:
            return {
                ...state,
                isGridOwnerListLoading: true,
                gridOwnerList: undefined
            };
        case actionTypes.GET_GRID_OWNERS_LIST_SUCCESS:
            return {
                ...state,
                isGridOwnerListLoading: false,
                gridOwnerList: action.payload
            };
        case actionTypes.GET_GRID_OWNERS_LIST_FAILURE:
            return {
                ...state,
                isGridOwnerListLoading: false
            };

        case actionTypes.GET_MODELS_LIST_REQUEST:
            return {
                ...state,
                modelList: undefined,
                isModelListLoading: true
            };
        case actionTypes.GET_MODELS_LIST_SUCCESS:
            return {
                ...state,
                modelList: action.payload,
                isModelListLoading: false
            };
        case actionTypes.GET_MODELS_LIST_FAILURE:
            return {
                ...state,
                isModelListLoading: false
            };

        case actionTypes.GET_GRID_LIST_REQUEST:
            return {
                ...state,
                gridList: undefined,
                isGridListLoading: true
            };
        case actionTypes.GET_GRID_LIST_SUCCESS:
            return {
                ...state,
                gridList: action.payload,
                isGridListLoading: false
            };
        case actionTypes.GET_GRID_LIST_FAILURE:
            return {
                ...state,
                isGridListLoading: false
            };

        case actionTypes.SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_REQUEST:
            return {
                ...state,
                otaUpdateProcessing: true,
                otaUpdateStatus: undefined
            };
        case actionTypes.SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_SUCCESS:
            toast.success('OTA is in queue!');
            return {
                ...state,
                otaUpdateProcessing: false,
                otaUpdateStatus: requestCycle.success
            };
        case actionTypes.SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE_FAILURE:
            toast.error('Failed to initiate the OTA!');
            return {
                ...state,
                otaUpdateProcessing: false,
                otaUpdateStatus: requestCycle.failed
            };

        case actionTypes.RETRY_OTA_REQUEST:
            return {
                ...state,
                otaUpdateRetrying: true,
                otaUpdateRetryStatus: undefined
            };
        case actionTypes.RETRY_OTA_SUCCESS:
            return {
                ...state,
                otaUpdateRetrying: false,
                otaUpdateRetryStatus: requestCycle.success
            };
        case actionTypes.RETRY_OTA_REQUEST_FAILURE:
            return {
                ...state,
                otaUpdateRetrying: false,
                otaUpdateRetryStatus: requestCycle.failed
            };

        case actionTypes.COLLECT_SOLBOX_OTA_DETAILS_REQUEST:
            return {
                ...state,
                collectingOtaDetails: true,
                otaDetails: undefined
            };
        case actionTypes.COLLECT_SOLBOX_OTA_DETAILS_SUCCESS:
            return {
                ...state,
                collectingOtaDetails: false,
                otaDetails: action.payload
            };
        case actionTypes.COLLECT_SOLBOX_OTA_DETAILS_FAILURE:
            return {
                ...state,
                collectingOtaDetails: false
            };

        default:
            return state;
    }
};
