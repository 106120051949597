import React, { useState, useEffect } from 'react';
import { solboxActions } from "../../solboxActions";
import { connect } from 'react-redux';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import SolSelect from "../../../../components/SolSelect";
import {SolboxInfoUpdateForm} from "./solboxInfoUpdateForm"
import SolboxListToolbar from "./SolboxListToolbar";
import SolboxListFilter from "./SolboxListFilter";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import {Button, Form, Modal} from "react-bootstrap";
import { SolTypography } from "../../../../components/utilityComponents/SOlTypography";
import LiveDataComponent from "../../../commonFeatureModules/views/LiveDataComponent";
import { GenericModal } from "../../../../components/genericModal/genericModal";
import { Link, NavLink } from "react-router-dom";
import { useSubheader } from "../../../../../_metronic/layout";
import { withRoles } from "../../../../router/SecuredRoute";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {requestCycle} from "../../solboxUtils";

const SolboxList = (props) => {

    const {
        location,
        history,
        isSolshare,
        b2bOrgGuid,
        isLoading,
        tableLoading,
        solboxList,
        pageCount,
        pageSize,
        paginatedSolboxList,
        statusCode,
        GET_GRID_LIST_WITHOUT_PAGINATION,
        GET_PAGINATED_SOLBOX_LIST,
        FILTER_SOLBOX_LIST,
        SEARCH_SOLBOX_LIST,
        SEND_OTA_UPDATE
    } = props;

    const [solboxFilterParams, setSolboxFilterParams] = useState(null);
    const [solboxDisplayParams, setSolboxDisplayParams] = useState(null);
    const [toggleFilter, setToggleFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);
    const [getSolboxes, setGetSolboxes] = useState(false);
    const [solboxId, setSolboxId] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [modal, setModal] = useState(false);
    const [showUpdateInfoModal, setShowUpdateInfoModal] = useState(false);
    const [showOtaUpdateModal, setShowOtaUpdateModal] = useState(false);
    const [selectedSolboxModel, setSelectedSolboxModel] = useState('');
    const [selectedSolboxStatus, setSelectedSolboxStatus] = useState('');
    const [searchFlag, setSearchFlag] = useState(false);
    const subheader= useSubheader();
    const [division, setDivision] = useState("");
    const [gridOwner, setGridOwner] = useState("");
    const [grid, setGrid] = useState([]);
    const [solboxModel, setSolboxModel] = useState("");
    const [solBoxStatus, setSolBoxStatus] = useState("");
    const [solBoxSetupStatus, setSolBoxSetupStatus] = useState("");
    const [gridOwnersOptions, setGridOwnersOptions] = useState([]);
    const [gridListOptions, setGridListOptions] = useState([]);
    const [solboxModelOptions, setSolboxModelOptions] = useState([]);
    const [firmwareOptions, setFirmwareOptions] = useState([]);
    const [lifeCycleStageOptions, setLifeCycleStageOptions] = useState([]);
    const [modelListOptions, setModelListOptions] = useState([]);
    const [currentModel, setCurrentModel] = useState([]);
    const [currentFirmware, setCurrentFirmware] = useState([]);
    const [currentStatus, setCurrentStatus] = useState([]);
    const [solboxGuid, setSolboxGuid] = useState([]);
    const [boxType, setBoxType] = useState(null);
    const [solboxListData, setSolboxListData] = useState([]);
    const [solboxInfoUpdateRequestPending, setSolboxInfoUpdateRequestPending] = useState();

    const divisionOptions = [
        { value: 'Chattogram', label: 'Chattogram' },
        { value: 'Rajshahi', label: 'Rajshahi' },
        { value: 'Khulna', label: 'Khulna' },
        { value: 'Barisal', label: 'Barisal' },
        { value: 'Sylhet', label: 'Sylhet' },
        { value: 'Dhaka', label: 'Dhaka' },
        { value: 'Rangpur', label: 'Rangpur' },
        { value: 'Mymensingh', label: 'Mymensingh' },
    ];

    useEffect(()=>{
        props.GET_NODE_TYPES()
    },[])

    const handleSetSolboxModelOptions = (solboxModels) => {
        setSolboxModelOptions(solboxModels);
    }

    const handleSetGridOptions = (grids) => {
        setGridListOptions(grids);
    }

    const handleSetGridOwnerOptions = (gridOwners) => {
        setGridOwnersOptions(gridOwners);
    }

    const handleChangeDivision = (division) => {
        setDivision(division);
        if (!division && isSolshare && !gridOwner) {
            GET_GRID_LIST_WITHOUT_PAGINATION({});
        }
        if (!division && !isSolshare && gridOwner) {
            GET_GRID_LIST_WITHOUT_PAGINATION({});
        }
    }

    const handleChangeGridOwner = (gridOwner) => {
        setGridOwner(gridOwner);
        if (!division && !gridOwner) {
            GET_GRID_LIST_WITHOUT_PAGINATION({});
        }
    }

    const handleBoxTypeSelection = (selected) => {
        setBoxType(selected);
    }

    const handleChangeGrid = (grid) => {
        setGrid(grid);
    }

    const [solboxIdParam, setSolboxIdParam] = useState("");

    const handleChangeSolTextInput = (event) => {
        if (event.target.name === "solboxIdParam") {
            setSolboxIdParam(event.target.value);
        }
    }

    const handleChangeSolboxModel = (grid) => {
        setSolboxModel(grid);
    }

    const handleChangeSolboxStatus = (event) => {
        if (event.target.id === "Online") {
            setSolBoxStatus(event.target.value);
        } else {
            setSolBoxStatus(event.target.value);
        }
    }

    const handleChangeSolboxSetupStatus = (event) => {
        if (event.target.id === "flashed") {
            setSolBoxSetupStatus(event.target.value);
        } else if (event.target.id === "assemble") {
            setSolBoxSetupStatus(event.target.value);
        } else {
            setSolBoxSetupStatus(event.target.value);
        }
    }

    const handleClick = (event, data) => {
        setRowData(data);
        setAnchorEl(event.currentTarget);
        setSelectedRowData(data);
    }

    useEffect(()=>{
        if (isSolshare) {
            subheader.setActionButtons(<>
                <NavLink className="btn btn-warning btn-sm" to="/solboxes/create" style={{
                    backgroundColor: '#F18D00 !important',
                }}>
                    <img src={require('../../../../utils/asset/plus-icon.svg')} />&nbsp;
                    {`Create SOLbox`}
                </NavLink>
            </>)
        }

        return ()=>{
           subheader.setActionButtons(null);
        }
    }, [isSolshare]);

    const handleClose = (event) => {
        setAnchorEl(null)
        setSelectedRowData(null)
    }

    const setSolboxListParams = (event, filterParams, displayParams) => {
        setSolboxFilterParams(filterParams);
        setSolboxDisplayParams(displayParams);
    }

    const toggleSolboxListFilter = (event) => {
        setToggleFilter(!toggleFilter);
    }

    const toggleFilterApplied = (event, value) => {
        setFilterApplied(value);
    }

    const toggleGetSolboxes = (event) => {
        setGetSolboxes(true);
    }

    const handleChangeSearchKeyword = (event) => {
        if (event.target.name === "idOfSolbox") {
            setSolboxId(event.target.value);
            if (searchFlag === true && event.target.value === "") {
                SEARCH_SOLBOX_LIST("", null, {});
                setSearchFlag(!searchFlag);
            }
        }
    }

    const handleSearchFlag = (event) => {
        setSearchFlag(!searchFlag);
    }

    const hideSolboxList = (event) => {
        setSolboxId("");
        setSolboxFilterParams(null);
        setSolboxDisplayParams(null);
        setToggleFilter(!toggleFilter);
    }

    const handleSetFilterParams = (searchParams) => {
        const regex = /^[0-9]+$/g;
        if (searchParams.has("division") && 
            searchParams.get("division") &&
            searchParams.get("division").trim()
        ) {
            divisionOptions.forEach((element, index) => {
                if (element.value.toLowerCase() === searchParams.get("division").trim().toLowerCase()) {
                    setDivision({
                        value: element.value,
                        label: element.label,
                    });
                }
            });
        }
        if (searchParams.has("solbox_id") && 
            searchParams.get("solbox_id") &&
            searchParams.get("solbox_id").trim() &&
            regex.test(searchParams.get("solbox_id").trim())
        ) {
            setSolboxIdParam(searchParams.get("solbox_id").trim());
        }

        if (searchParams.has("solbox_status")) {
            let solboxStatus = searchParams.get("solbox_status");
            if (solboxStatus &&
                solboxStatus.trim() &&
                solboxStatus.trim().toLowerCase() === "online" ||
                solboxStatus.trim().toLowerCase() === "offline"
            ) {
                setSolBoxStatus(solboxStatus.trim().toLowerCase());
            }
        }
        if (searchParams.has("solbox_stage")) {
            let solboxStage = searchParams.get("solbox_stage");
            if (solboxStage &&
                solboxStage.trim() &&
                solboxStage.trim().toLowerCase() === "flashed" ||
                solboxStage.trim().toLowerCase() === "assemble" ||
                solboxStage.trim().toLowerCase() === "others"
            ) {
                setSolBoxSetupStatus(solboxStage.trim().toLowerCase());
            }
        }
    }

    useEffect((event) => {
        if (!filterApplied && location && location.search) {
            let filterParams = {};
            var searchParams = new URLSearchParams(location.search);
            if (searchParams.has("organization_guid") ||
                searchParams.has("grid_guid") ||
                searchParams.has("division") ||
                searchParams.has("solbox_id") ||
                searchParams.has("solbox_status") ||
                searchParams.has("solbox_stage") ||
                searchParams.has("solbox_model") 
            ) {
                handleSetFilterParams(searchParams);
                const divisions = [
                    'chattogram',
                    'rajshahi',
                    'khulna',
                    'barisal',
                    'sylhet',
                    'dhaka',
                    'rangpur',
                    'mymensingh'
                ];
                searchParams.forEach((value, key) => {
                    if (key === "division" &&
                        value &&
                        value.trim() &&
                        divisions.includes(value.trim().toLowerCase())
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                    if (key === "solbox_stage" &&
                        isSolshare &&
                        value &&
                        value.trim() &&
                        value.trim().toLowerCase() === "flashed" ||
                        value.trim().toLowerCase() === "assemble" ||
                        value.trim().toLowerCase() === "others"
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                    if (key === "solbox_status" &&
                        value &&
                        value.trim() &&
                        value.trim().toLowerCase() === "online" ||
                        value.trim().toLowerCase() === "offline" 
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                    const regex = /^[a-z0-9_,\-\. ]+$/gi;
                    if (key === "grid_guid" ||
                        key === "solbox_model" ||
                        key === "solbox_id" && 
                        value && 
                        value.trim() &&
                        regex.test(value.trim())
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                    if (key === "organization_guid" && 
                        isSolshare &&
                        value && 
                        value.trim() &&
                        regex.test(value.trim())
                    ) {
                        Object.assign(filterParams, { [key]: value.trim() });
                    }
                });
            }
            if (!(Object.entries(filterParams).length < 1)) {
                toggleGetSolboxes(event);
                setSolboxListParams(event, filterParams, filterParams);
                toggleFilterApplied(event, true);
                FILTER_SOLBOX_LIST(null, solboxId, filterParams);
            }
        }
    }, [location, filterApplied]);

    useEffect(() => {
        if (location && location.search && gridOwnersOptions && gridOwnersOptions.length !== 0) {
            var searchParams = new URLSearchParams(location.search);
            if (searchParams.has("organization_guid") && 
                searchParams.get("organization_guid") &&
                searchParams.get("organization_guid").trim() &&
                isSolshare
            ) {
                let orgGuid = searchParams.get("organization_guid").trim();
                gridOwnersOptions.forEach((element, index) => {
                    if (element?.value === orgGuid) {
                        setGridOwner(element);
                    }
                });
            }
        }
    }, [location, gridOwnersOptions]);

    useEffect(() => {
        if (!isSolshare && b2bOrgGuid && gridOwnersOptions && gridOwnersOptions.length !== 0) {
            gridOwnersOptions.forEach((element, index) => {
                if (element?.value === b2bOrgGuid) {
                    setGridOwner(element);
                }
            });
        }
    }, [gridOwnersOptions]);

    useEffect(() => {
        if (location && location.search && gridListOptions && gridListOptions.length !== 0) {
            var searchParams = new URLSearchParams(location.search);
            if (searchParams.has("grid_guid") && 
                searchParams.get("grid_guid") &&
                searchParams.get("grid_guid").trim()
            ) {
                let gridGuidArr = searchParams.get("grid_guid").trim().split(",");
                let gridArr = [];
                gridListOptions.forEach((element, index) => {
                    if (gridGuidArr.includes(element?.value)) {
                        gridArr.push({
                            value: element?.value,
                            label: element?.label
                        });
                    }
                });
                setGrid(gridArr);
            }
        }
    }, [location, gridListOptions]);

    useEffect(() => {
        if (location && location.search && solboxModelOptions && solboxModelOptions.length !== 0) {
            var searchParams = new URLSearchParams(location.search);
            if (searchParams.has("solbox_model") && 
                searchParams.get("solbox_model") &&
                searchParams.get("solbox_model").trim()
            ) {
                let solboxModel = searchParams.get("solbox_model").trim();
                solboxModelOptions.forEach((element, index) => {
                    if (element?.value === solboxModel) {
                        setSolboxModel(element);
                    }
                });
            }
        }
    }, [location, solboxModelOptions]);

    useEffect(() => {
        props.FETCH_SOLBOX_FIRMWARE_LIST()
        props.FETCH_SOLBOX_LIFE_CYCLE_STAGE()

        return ()=>{
            props.CLEAN_SOLBOX_LIST();
        }

    }, []);

    useEffect(() => {
        if (props.solboxInfoUpdateStatus ===requestCycle.success) {
            setShowUpdateInfoModal(false)
            FILTER_SOLBOX_LIST(pageNumber, null, solboxFilterParams);
        }
    }, [props.solboxInfoUpdateStatus]);

    useEffect(() => {
        setSolboxInfoUpdateRequestPending(props.updatingSolboxInfo)
    }, [props.updatingSolboxInfo]);

    useEffect(() => {
        let firmwares = props.firmwareList
        let editedFirmwares = []
        if (firmwares) {
            for (let i = 0; i < firmwares.length; i++) {
                editedFirmwares.push({"label": firmwares[i]["firmware_version"], "value": firmwares[i]["id"]})
            }
        }
        setFirmwareOptions(editedFirmwares)
    }, [props.firmwareList]);

    useEffect(() => {
        let solboxModels = props.modelList
        let editedSOLboxmodels = []
        if (solboxModels) {
            for (let i = 0; i < solboxModels.length; i++) {
                editedSOLboxmodels.push({"label": solboxModels[i]["model_alias"], "value": solboxModels[i]["id"]})
            }
        }
        setModelListOptions(editedSOLboxmodels)
    }, [props.modelList]);

    useEffect(() => {
        let lifeCycleStages = props.lifeCycleStageList
        let editedLifeCycleStages = []
        if (lifeCycleStages) {
            for (let i = 0; i < lifeCycleStages.length; i++) {
                editedLifeCycleStages.push({"label": lifeCycleStages[i]["name"], "value": lifeCycleStages[i]["id"]})
            }
        }
        setLifeCycleStageOptions(editedLifeCycleStages)
    }, [props.lifeCycleStageList]);

    useEffect(() => {
        if (props.otaUpdateStatus === requestCycle.success){
            setShowOtaUpdateModal(false)
            FILTER_SOLBOX_LIST(pageNumber, null, solboxFilterParams);
        }
    }, [props.otaUpdateStatus]);

    const reset = (event) => {
        if (filterApplied !== false) {
            if (division !== "" ||
                division !== null ||
                gridOwner !== "" ||
                gridOwner !== null ||
                grid !== null ||
                grid.length !== 0 ||
                solboxIdParam.trim() !== "" ||
                solboxModel !== "" ||
                solboxModel !== null ||
                solBoxStatus !== "" ||
                solBoxSetupStatus !== "" ||
                boxType !== null ||
                boxType !== ""
            ) {
                setSolboxListParams(event, null, null);
                toggleFilterApplied(event, false);
                FILTER_SOLBOX_LIST(null, "", {});
                history.push({
                    pathname: '/solboxes/list',
                    search: ""
                });
                //GET_PAGINATED_SOLBOX_LIST(null);
                //toggleSolboxListFilter(event);
            }
        }
        setDivision("");
        if (isSolshare) {
            setGridOwner("");
        }
        setGrid([]);
        setSolboxIdParam("");
        setSolboxModel("");
        setSolBoxStatus("");
        setSolBoxSetupStatus("");
        setBoxType(null)
    }

    const redirectToSolboxProfile = (event, solboxGuid, solbox_serial_number) => {
        //GET_SOLBOX_GENERAL_DATA(solboxGuid);
        props.history.push(`profile/${solbox_serial_number}/${solboxGuid}`);
    }

    const triggerLiveDataModal = () => {
        handleClose();
        setModal(true);
    }

    const triggerUpdateInfoModal = (rowData) => {
        let model = rowData.solbox_model
        let firmware = rowData.solbox_firmware
        let status = rowData.solbox_stage
        setSolboxGuid(rowData.solbox_guid)
        // Extracting Ids
        for (let i=0; i<firmwareOptions.length; i++) {
            if (firmwareOptions[i]["label"] === firmware) {
                setCurrentFirmware(firmwareOptions[i])
            }
        }
        for (let i=0; i<modelListOptions.length; i++) {
            if (modelListOptions[i]["label"] === model) {
                setCurrentModel(modelListOptions[i])
            }
        }
        for (let i=0; i<lifeCycleStageOptions.length; i++) {
            if (lifeCycleStageOptions[i]["label"] === status) {
                setCurrentStatus(lifeCycleStageOptions[i])
            }
        }
        handleClose();
        setShowUpdateInfoModal(true);
    }

    const handleCustomerNameClick = (event, data) => {
        props.history.push(
            '/customers/profile',
            {
                solbox_guid: data.guid,
                customer_guid: data.customer_guid,
                solbox_serial_number: data.solbox_serial_number,
                customer_type: data.node_type,
            }
        );
    }

    const handleGridNameClick = (event, data) => {
        props.history.push(`/grids/details/${data.grid_guid}`, {
            gridName: data.grid_name,
            owner: data.grid_owner
        });
    }

    const redirectToCreateTransaction = (data) => {
        if (data !== undefined && data !== null) {
            handleClose();
            props.history.push(`/transactions/create-for/${data.solbox_serial_number}/${data.name}/${data.grid_name}/${data.type}`);
        }
    }

    const redirectTOConfiguration = () => {
        handleClose();
        props.history.push(`/solboxes/configuration/${rowData?.guid}/${rowData?.solbox_serial_number}`);
    }

    const showReplacementModal = (data) => {
        if (data !== undefined && data !== null) {
            handleClose();
            props.history.push(`/replacements/replace/${data.grid_guid}/${data.solbox_serial_number}/${data.customer_guid}/${data.node_guid}`);
        }
    }

    const handleOtaUpdateRequest = (data) => {
        setShowOtaUpdateModal(true)
    }

    const sendOtaUpdate = () => {
        let otaPayload = {
            "solboxes": [rowData.solbox_serial_number.substring(4, 8)],
            "firmware_version": rowData.ota_firmware_version
        }
        SEND_OTA_UPDATE(otaPayload)
    }

    const closeModal = () => {
        setModal(false)
        setShowUpdateInfoModal(false)
        setShowOtaUpdateModal(false)
    }

    const solboxListColumns = [
        {
            field: "",
            title: "",
            width: "80px",
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            render: (rowData) => (
                <React.Fragment>
                    {(rowData.type !== undefined &&
                        rowData !== undefined &&
                        rowData.type !== null &&
                        rowData.type !== "") ? (
                        <div className={`node-type-cell-container`}>
                  <span className={`node-type-cell`}>
                    {rowData.type !== undefined &&
                    rowData.type !== null &&
                    rowData.type !== "" &&
                    rowData !== undefined
                        ? rowData.type === "Consumer"
                            ? rowData.type.substring(0, 2).toUpperCase()
                            : rowData.type === "Microutility"
                                ? `MU`
                                : rowData.type === "Producer"
                                    ? `PRD`
                                    : rowData.type.substring(0, 3).toUpperCase()
                        :
                        null
                    }
                  </span>
                        </div>
                    ) : (
                        <span className="ml-3">
                  <AspectRatioOutlinedIcon fontSize="large"/>
                </span>
                    )}
                </React.Fragment>
            ),
        },
        {
            field: "id",
            title: "ID",
            //cellStyle: {color: "#F18D00"},
            headerStyle: {color: "#8C8C9B"},
            disableClick: false,
            render: (rowData) => (
                <React.Fragment>
                    {rowData.id !== undefined &&
                    rowData.id !== null &&
                    rowData !== undefined ? (
                        <React.Fragment>
                            <Link 
                                target="_blank" 
                                to={`profile/${rowData.solbox_serial_number}/${rowData.guid}`} 
                                style={{color: "#F18D00"}}
                            >
                                <span className="d-block sol-text solbox-profile-details">
                                {rowData.id.substring(0, rowData.id.indexOf(","))}
                                        {rowData.status.toLowerCase() === "online" ? (
                                            <FiberManualRecordIcon
                                                style={{color: "#0B6E4F", paddingLeft: "0.25rem"}}
                                            />
                                        ) : (
                                            <FiberManualRecordIcon
                                                style={{color: "#CB152B", paddingLeft: "0.25rem"}}
                                            />
                                        )}
                                </span>
                            </Link>
                            <span className="d-block mt-1">
                            {rowData.id.substring(
                                rowData.id.indexOf(",") + 1,
                                rowData.id.indexOf(";")
                            )} </span>
                            <span className="d-block mt-1">
                                {rowData.id.substring(rowData.id.indexOf(";") + 1, rowData.id.indexOf("|"))}
                            </span>
                            <span className="d-block mt-1">
                                {rowData.id.substring(
                                    rowData.id.indexOf("|") + 1,
                                    rowData.id.length
                                )}
                            </span>
                        </React.Fragment>
                    ) : (
                        `N/A`
                    )}
                </React.Fragment>
            ),
        },
        {
            field: "name",
            title: "Customer",
            //cellStyle: {color: "#F18D00"},
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            render: (rowData) => (
                <React.Fragment>
                    {rowData.name !== null &&
                    rowData.name !== undefined &&
                    rowData.name !== "null" &&
                    rowData !== undefined ? (
                        <React.Fragment>
                            <Link 
                                target="_blank" 
                                to={`/customers/profile/${rowData.customer_guid}/${rowData.guid}/${rowData.solbox_serial_number}/${rowData.node_type}`} 
                                style={{color: "#F18D00"}}
                            >
                                <span className="d-block sol-text inter-module-link">
                                    {rowData.name}
                                </span>
                            </Link>
                        </React.Fragment>
                    ) : 
                    `N/A`
                    }
                    {rowData.phone !== null &&
                    rowData.phone !== undefined &&
                    rowData.phone !== `N/A` &&
                    rowData !== undefined && (
                        <React.Fragment>
                            <Link 
                                target="_blank" 
                                to={`/customers/profile/${rowData.customer_guid}/${rowData.guid}/${rowData.solbox_serial_number}/${rowData.node_type}`} 
                                style={{color: "#F18D00"}}
                            >
                                <span className="d-block sol-text inter-module-link">{rowData.phone}</span>
                            </Link>
                        </React.Fragment>
                    )}
                </React.Fragment>
            ),
        },
        {
            field: "grid",
            title: "Grid",
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            render: (rowData) => (
                <React.Fragment>
                    {rowData.grid_name !== undefined &&
                    rowData.grid_name !== null &&
                    rowData.grid_name !== "null" &&
                    rowData !== undefined ? (
                        <React.Fragment>
                            <Link
                                target="_blank"
                                to={{
                                    pathname: `/grids/details/${rowData.grid_guid}`,
                                    state: {
                                      gridName: rowData.grid_name,
                                    }
                                }}
                                style={{color: "#F18D00"}}
                            >
                                <span className="d-block sol-text inter-module-link">{rowData.grid_name}</span>
                            </Link>
                        </React.Fragment>
                    ) : `N/A`}
                    {rowData.grid_owner !== undefined &&
                    rowData.grid_owner !== null &&
                    rowData.grid_owner !== `N/A` &&
                    rowData !== undefined && (
                    <React.Fragment>
                        <span className="d-block mt-1">
                        {rowData.grid_owner}
                        </span>
                    </React.Fragment>
                    )}
                </React.Fragment>
            ),
        },
        {
            field: "mode",
            title: "Mode",
            cellStyle: {paddingRight: "2.5rem"},
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            align: "right",
        },
        {
            field: "balance",
            title: "Balance (Tk)",
            cellStyle: {textAlign: "right"},
            headerStyle: {color: "#8C8C9B"},
            disableClick: true,
            align: "right",
        },
        // {
        //     field: "otaAvailable",
        //     title: "OTA Available",
        //     cellStyle: {textAlign: "center"},
        //     headerStyle: {color: "#8C8C9B"},
        //     disableClick: true,
        //     align: "right",
        //     // emptyValue: ()=>{
        //     //     return "N/A"
        //     // },
        //     render: (rowData) => {
        //         if (rowData.otaAvailable === true) {
        //             return (
        //                 <>Yes</>
        //             )
        //         } else {
        //             return (
        //                 <>No</>
        //             )
        //         }
        //     }
        // },
        {
            field: "",
            title: "",
            render: (rowData) => {
                let menus = <></>
                const otaAvailable = rowData.otaAvailable
                if (isSolshare) {
                    menus = <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted={false}
                        open={Boolean(anchorEl)}
                        variant={"menu"}
                        onClose={(event) => handleClose(event)}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        style={{top: "3rem", left: "1.75rem"}}
                        PaperProps={{
                            style: {
                                boxShadow: "0px 0px 3px 2px #f2f2f2",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={(event) => triggerLiveDataModal()}
                        >
                            {`See Live Data`}
                        </MenuItem>
                        <MenuItem
                            onClick={(event) => redirectTOConfiguration(event)}
                        >
                            {`Configuration`}
                        </MenuItem>
                        <MenuItem
                            onClick={(event) => triggerUpdateInfoModal(selectedRowData)}
                        >
                            {`Update info`}
                        </MenuItem>
                        <MenuItem
                            onClick={() => redirectToCreateTransaction(selectedRowData)}
                        >
                            {`Create Transaction`}
                        </MenuItem>
                        <MenuItem
                            onClick={() => showReplacementModal(selectedRowData)}
                        >
                            {`Replace`}
                        </MenuItem>
                        {/*<MenuItem*/}
                        {/*    onClick={() => handleOtaUpdateRequest(selectedRowData)}*/}
                        {/*>*/}
                        {/*    {`OTA update`}*/}
                        {/*</MenuItem>*/}
                    </Menu>
                } else {
                    menus =  <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted={false}
                        open={Boolean(anchorEl)}
                        variant={"menu"}
                        onClose={(event) => handleClose(event)}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        style={{top: "3rem", left: "1.75rem"}}
                        PaperProps={{
                            style: {
                                boxShadow: "0px 0px 3px 2px #f2f2f2",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={(event) => triggerLiveDataModal()}
                        >
                            {`See Live Data`}
                        </MenuItem>
                        <MenuItem
                            onClick={(event) => redirectTOConfiguration(event)}
                        >
                            {`Configuration`}
                        </MenuItem>
                    </Menu>
                }
                return (
                    <>
                        <IconButton
                            aria-label="more"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event, data) => handleClick(event, rowData)}
                        >
                            <MoreVertIcon style={{color: "#F18D00"}}/>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted={false}
                            open={Boolean(anchorEl)}
                            variant={"menu"}
                            onClose={(event) => handleClose(event)}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            style={{top: "3rem", left: "1.75rem"}}
                            PaperProps={{
                                style: {
                                    boxShadow: "0px 0px 3px 2px #f2f2f2",
                                },
                            }}
                        >
                            {menus}
                        </Menu>
                    </>
                )
            },
            align: "right",
            disableClick: true
        },
    ];

    useEffect(() => {
        if (paginatedSolboxList !== undefined && paginatedSolboxList.length !== 0) {
            let data = [];
            paginatedSolboxList.forEach((element, index) => {
                let otaAvailability = element.ota_available
                if (element?.last_ota_history && Object.keys(element.last_ota_history).length > 0) {
                    let deviceEspOtaStatus = element.last_ota_history.device_esp_ota_status
                    let deviceStmOtaStatus = element.last_ota_history.device_stm_ota_status
                    let statuses = ['succeeded', 'failed', 'cancelled']
                    if (!statuses.includes(deviceEspOtaStatus) || !statuses.includes(deviceStmOtaStatus)) {
                        // OTA is in progress
                        otaAvailability = false
                    }
                }
                data.push({
                    ...element,
                    id:
                        element.solbox_serial_number !== undefined &&
                        element.solbox_firmware !== undefined &&
                        element.solbox_stage !== undefined &&
                        element.solbox_serial_number !== null &&
                        element.solbox_firmware !== null &&
                        element.solbox_model !== null &&
                        element.solbox_stage !== null
                            ? element.solbox_serial_number
                                .concat(",FW: ", element.solbox_firmware)
                                .concat(";", element.solbox_model)
                                .concat("|", element.solbox_stage)
                            : element.solbox_serial_number !== undefined &&
                            element.solbox_serial_number === null
                            ? `N/A`
                                .concat(",FW: ", element.solbox_firmware)
                                .concat(";", element.solbox_model)
                                .concat("|", element.solbox_stage)
                            : element.solbox_firmware !== undefined &&
                            element.solbox_firmware === null
                                ? element.solbox_serial_number
                                    .concat(",FW: ", `N/A`)
                                    .concat(";", element.solbox_model)
                                    .concat("|", element.solbox_stage)
                                : element.solbox_model !== undefined && element.solbox_model === null
                                    ? element.solbox_serial_number
                                        .concat(",FW: ", element.solbox_firmware)
                                        .concat(";", `N/A`)
                                        .concat("|", element.solbox_stage)
                                    : element.solbox_stage !== undefined && element.solbox_stage === null
                                        ? element.solbox_serial_number
                                            .concat(",FW: ", element.solbox_firmware)
                                            .concat(";", element.solbox_model)
                                            .concat("|", 'N/A') : `N/A`,
                    name:
                        element.name !== undefined && element.name !== null
                            ? element.name
                            : `null`,
                    phone:
                        element.phone !== undefined && element.phone !== null
                            ? element.phone
                            : `N/A`,
                    grid_name:
                        element.grid_name !== undefined && element.grid_name !== null
                            ? element.grid_name
                            : `null`,
                    grid_owner:
                        element.grid_owner !== undefined && element.grid_owner !== null ?
                            element.grid_owner
                            : `N/A`,
                    balance:
                        element.last_known_balance !== null &&
                        element.last_known_balance !== undefined
                            ? typeof element.last_known_balance === "number"
                            ? (element.last_known_balance / 100).toFixed(2)
                            : (Number.parseInt(element.last_known_balance) / 100).toFixed(2)
                            : `N/A`,
                    otaAvailable: otaAvailability,
                    mode:
                        element.solbox_mode !== null &&
                        element.solbox_mode !== undefined
                            ? element.solbox_mode
                            : `N/A`,
                    type: element.node_type !== undefined && element.node_type !== null ? element.node_type : "",
                    status: element.solbox_status !== undefined && element.solbox_status !== null ? element.solbox_status : "",
                    guid: element.solbox_guid !== undefined && element.solbox_guid !== null ? element.solbox_guid : "",
                    solbox_serial_number: element.solbox_serial_number !== undefined && element.solbox_serial_number !== null ? element.solbox_serial_number : "null",
                    customer_guid: element.customer_guid !== undefined && element.customer_guid !== null ? element.customer_guid : "null",
                    grid_guid: element.grid_guid !== undefined && element.grid_guid !== null ? element.grid_guid : "null",
                    node_guid: element.node_guid !== undefined && element.node_guid !== null ? element.node_guid : "null"
                });
            });
            setSolboxListData(data);
        } else {
            if (paginatedSolboxList !== undefined && paginatedSolboxList.length === 0) {
                setSolboxListData(paginatedSolboxList);
            }
        }
    }, [paginatedSolboxList]);

    useEffect(() => {
        if (getSolboxes !== undefined && getSolboxes === false) {
            setSolboxListData([]);
        }
    }, [getSolboxes]);

    const redirectToSolboxList = () => {
        props.history.goBack();
    }

    const [pageNumber, setpageNumber] = useState(null);

    const handleChangePage = (event, pageNumber) => {
        setpageNumber(pageNumber);
        if (pageNumber !== undefined &&
            pageNumber !== null &&
            solboxFilterParams !== undefined &&
            solboxFilterParams === null &&
            solboxId !== undefined &&
            solboxId === ""
        ) {
            GET_PAGINATED_SOLBOX_LIST(pageNumber);
        }
        if (pageNumber !== undefined &&
            pageNumber !== null &&
            solboxFilterParams !== undefined &&
            solboxFilterParams !== null &&
            solboxId !== undefined &&
            solboxId === ""
        ) {
            FILTER_SOLBOX_LIST(pageNumber, solboxId.trim(), solboxFilterParams);
        }
        if (pageNumber !== undefined &&
            pageNumber !== null &&
            solboxFilterParams !== undefined &&
            solboxId !== undefined &&
            solboxId !== ""
        ) {
            if (solboxFilterParams !== null) {
                SEARCH_SOLBOX_LIST(solboxId.trim(), pageNumber, solboxFilterParams);
            } else {
                SEARCH_SOLBOX_LIST(solboxId.trim(), pageNumber, {});
            }
        }
    }

    const handleSolboxInfoUpdateSubmit = (data) => {
        props.UPDATE_SOLBOX_FW_HW_LIFE_CYCLE_INFO({
            ...data,
        });
    }

    const otaUpdateModal = () => {
        return <>
            {rowData?.otaAvailable?
                <GenericModal size={'md'} footer={false} title={<>
                    Sending OTA update to: <SolTypography.Text primary>{rowData?.solbox_serial_number}</SolTypography.Text>
                </>} showModalHeader={true} footer={true} footerCloseButtonText={'No'} footerActionButtonText={'Yes'}
                              modal={showOtaUpdateModal} hideModal={closeModal}
                              submitAction={sendOtaUpdate} disableButton={props.otaUpdateProcessing}>
                    <span className={"text-dark"}>Are you sure?</span>
                </GenericModal>:<GenericModal size={'md'} footer={false} title={<>
                    OTA update to: <SolTypography.Text primary>{rowData?.solbox_serial_number}</SolTypography.Text>
                </>} showModalHeader={true} modal={showOtaUpdateModal} hideModal={closeModal}>
                    <span className={"text-danger"}>OTA update is not available for this SOLbox</span>
                </GenericModal>}
        </>
    }

    const renderUpdateInfoModal = () => {
        return <>
            <GenericModal size={'md'} footer={false} title={<>
                Updating info of SOLbox <SolTypography.Text primary>{rowData?.solbox_serial_number}</SolTypography.Text>
            </>} showModalHeader={true} modal={showUpdateInfoModal} hideModal={closeModal}>
                <SolboxInfoUpdateForm solboxGuid={solboxGuid}
                                      submit={handleSolboxInfoUpdateSubmit}
                                      firmwareList={firmwareOptions}
                                      lifeCycleStageList={lifeCycleStageOptions}
                                      modelList={modelListOptions}
                                      currentFirmware={currentFirmware}
                                      currentModel={currentModel}
                                      currentStatus={currentStatus}
                                      solboxInfoUpdateRequestPending={solboxInfoUpdateRequestPending}
                                      hideModal={closeModal}/>
            </GenericModal>
        </>
    }

    const renderModal = () => {
        return <>
            <GenericModal size={'lg'} footer={false} title={<>
                Live Data from <SolTypography.Text primary>{rowData?.solbox_serial_number}</SolTypography.Text>
            </>} showModalHeader={true} modal={modal} hideModal={closeModal}>
                <LiveDataComponent solboxSerialNumber={rowData?.solbox_serial_number}
                                   firmware_version={rowData?.solbox_firmware}/>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        type="submit"
                        style={{
                            backgroundColor: '#8C8C9B',
                            outline: '#8C8C9B',
                            border: '#8C8C9B',
                        }}
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </GenericModal>
            {/*<Modal show={modal} onHide={closeModal} animation={false} size={'lg'}>*/}
            {/*    <Modal.Header closeButton>*/}
            {/*        <Modal.Title>*/}
            {/*            Live Data from <SolTypography.Text primary>{rowData?.solbox_serial_number}</SolTypography.Text>*/}
            {/*        </Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}

            {/*        /!*Modal Body to render the intended component*!/*/}
            {/*        /!*Independent Live Data Component*!/*/}
            {/*        <LiveDataComponent solboxSerialNumber={rowData?.solbox_serial_number}/>*/}

            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer>*/}
            {/*        <Button*/}
            {/*            variant="dark"*/}
            {/*            type="submit"*/}
            {/*            style={{*/}
            {/*                backgroundColor: '#8C8C9B',*/}
            {/*                outline: '#8C8C9B',*/}
            {/*                border: '#8C8C9B',*/}
            {/*            }}*/}
            {/*            onClick={closeModal}*/}
            {/*        >*/}
            {/*            Close*/}
            {/*        </Button>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}
        </>
    }

    return (
        <ContentWrapper
            showBackButton={false}
            permissionDenied={false}
            pageTitle={"SOLboxs"}
            //statusCode={statusCode} 
            isLoading={isLoading}
            showCardHeader={(statusCode !== undefined &&
                statusCode !== null &&
                !Number.isNaN(statusCode)) ?
                true
                :
                false
            }
        >
            <SolboxListToolbar
                getSolboxes={getSolboxes}
                pageNumber={pageNumber}
                solboxFilterParams={solboxFilterParams}
                solboxId={solboxId}
                toggleFilter={toggleFilter}
                hideSolboxList={hideSolboxList}
                searchFlag={handleSearchFlag}
                handleSearchFlag={handleSearchFlag}
                solboxListData={solboxListData}
                handleChangeSearchKeyword={handleChangeSearchKeyword}
            />
            {(toggleFilter === true) &&
            <SolboxListFilter
                {...props}
                isSolshare={isSolshare}
                b2bOrgGuid={b2bOrgGuid}
                division={division}
                divisionOptions={divisionOptions}
                handleChangeDivision={handleChangeDivision}
                handleBoxTypeSelection={handleBoxTypeSelection}
                gridOwner={gridOwner}
                gridOwnersOptions={gridOwnersOptions}
                handleSetGridOwnerOptions={handleSetGridOwnerOptions}
                handleChangeGridOwner={handleChangeGridOwner}
                grid={grid}
                gridListOptions={gridListOptions}
                handleSetGridOptions={handleSetGridOptions}
                handleChangeGrid={handleChangeGrid}
                solboxModel={solboxModel}
                boxType={boxType}
                solboxModelOptions={solboxModelOptions}
                handleSetSolboxModelOptions={handleSetSolboxModelOptions}
                handleChangeSolboxModel={handleChangeSolboxModel}
                solBoxStatus={solBoxStatus}
                handleChangeSolboxStatus={handleChangeSolboxStatus}
                solBoxSetupStatus={solBoxSetupStatus}
                handleChangeSolboxSetupStatus={handleChangeSolboxSetupStatus}
                reset={reset}
                pageNumber={pageNumber}
                solboxId={solboxId}
                setSolboxListParams={setSolboxListParams}
                solboxIdParam={solboxIdParam}
                handleChangeSolTextInput={handleChangeSolTextInput}
                getSolboxes={getSolboxes}
                toggleGetSolboxes={toggleGetSolboxes}
                filterApplied={filterApplied}
                toggleSolboxListFilter={toggleSolboxListFilter}
                toggleFilterApplied={toggleFilterApplied}
            />
            }
            {
                getSolboxes && <div className={'col-md-12 no-padding mt-5 mb-6'}>
                    <div className={'row'}>
                        <div className={'col-md-4 '}>

                        </div>
                        <div className={'col-md-4 '}>

                        </div>
                        {/*<div className={'col-md-3 '}>*/}

                        {/*</div>*/}

                        <div className={'col-md-4 '} style={{
                            display: "flex",
                            gap: '15px',

                            // justifyContent: 'right',
                            justifyContent: "flex-end",
                        }}>
                            {/*<Button variant="warning" type="submit" style={{*/}
                            {/*    backgroundColor: '#F18D00',*/}
                            {/*}} onClick={() => {*/}
                            {/*    props.history.push('/solboxes/create')*/}
                            {/*}}>*/}
                            {/*    <img src={require('../../../../utils/asset/plus-icon.svg')}*/}
                            {/*         alt={'create SOlbox'}/>&nbsp;Create SOLbox*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                </div>
            }
            {(getSolboxes === true) &&
            <DataTable
                columns={solboxListColumns}
                data={solboxListData}
                isLoading={tableLoading}
                search={false}
                count={pageCount}
                asyncPagination={true}
                itemsPerPage={pageSize}
                onChangePage={handleChangePage}
                page={pageNumber}
            />
            }

            {renderModal()}
            {renderUpdateInfoModal()}
            {otaUpdateModal()}
        </ContentWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.solboxReducer.isLoading,
        tableLoading: state.solboxReducer.tableLoading,
        statusCode: state.solboxReducer.statusCode,
        solboxList: state.solboxReducer.solboxList,
        pageCount: state.solboxReducer.pageCount,
        pageSize: state.solboxReducer.pageSize,
        paginatedSolboxList: state.solboxReducer.paginatedSolboxList,
        solboxGenericData: state.solboxReducer.solboxGenericData,
        firmwareList: state.solboxReducer.solboxfirmwarelist,
        lifeCycleStageList: state.solboxReducer.lifeCycleStageList,
        modelList: state.solboxReducer.modelList,
        solboxInfoUpdateStatus: state.solboxReducer.solboxInfoUpdateStatus,
        updatingSolboxInfo: state.solboxReducer.updatingSolboxInfo,
        otaUpdateProcessing: state.solboxReducer.otaUpdateProcessing,
        otaUpdateStatus: state.solboxReducer.otaUpdateStatus
    }
};

export default connect(mapStateToProps, solboxActions)(withRoles(SolboxList));
