import { CALL_API } from "../../utils/actionTypes";
import { tradingActionTypes } from "./tradingActionTypes";

export const tradingActions = {

    GET_GRID_OWNERS_LIST: () => ({
        [CALL_API]: {
            endpoint: `/grid/organization/list/`,
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                tradingActionTypes.GET_GRID_OWNERS_LIST_REQUEST,
                tradingActionTypes.GET_GRID_OWNERS_LIST_SUCCESS,
                tradingActionTypes.GET_GRID_OWNERS_LIST_FAILURE,
            ],
        },
    }),
    GET_GRID_LIST: (gridOwner) => ({
        [CALL_API]: {
            endpoint: `/grid/get_list_details/?organization_guid=${gridOwner}`,
            method: "GET",
            backend_slug: "grid_backend",
            types: [
                tradingActionTypes.GET_GRID_LIST_REQUEST,
                tradingActionTypes.GET_GRID_LIST_SUCCESS,
                tradingActionTypes.GET_GRID_LIST_FAILURE,
            ],
        },
    })
};