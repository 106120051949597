import {CALL_API} from "../../utils/actionTypes";
import {smartboardActionTypes as actionTypes} from "./smartboardActionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";

export const smartboardActions = {

    GET_GRID_LIST_SMARTBOARD: (payload = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/grid/get_list_details/', payload),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRID_LIST_SMARTBOARD_REQUEST,
                actionTypes.GET_GRID_LIST_SMARTBOARD_SUCCESS,
                actionTypes.GET_GRID_LIST_SMARTBOARD_FAILURE,
            ]
        }
    }),
    GET_GRID_OPERATOR_LIST: (payload = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/grid/organization/list/', payload),
            method: 'GET',
            backend_slug: 'grid_backend',
            types: [
                actionTypes.GET_GRID_OPERATOR_LIST_REQUEST,
                actionTypes.GET_GRID_OPERATOR_LIST_SUCCESS,
                actionTypes.GET_GRID_OPERATOR_LIST_FAILURE,
            ]
        }
    }),
    GET_SOLBOX_MODEL_LIST: (payload = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/solmodel-list/', payload),
            method: 'GET',
            backend_slug: 'default_url',
            types: [
                actionTypes.GET_SOLBOX_MODEL_LIST_REQUEST,
                actionTypes.GET_SOLBOX_MODEL_LIST_SUCCESS,
                actionTypes.GET_SOLBOX_MODEL_LIST_FAILURE,
            ]
        }
    }),
    GET_FIRMWARE_VERSION: (payload = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/firmware/firmware_list/', payload),
            method: 'GET',
            backend_slug: 'default_url',
            types: [
                actionTypes.GET_FIRMWARE_VERSION_REQUEST,
                actionTypes.GET_FIRMWARE_VERSION_SUCCESS,
                actionTypes.GET_FIRMWARE_VERSION_FAILURE,
            ]
        }
    }),
    GET_ISUE_TYPE_LIST: (payload = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/matrices/anomaly/anomaly_types/', payload),
            method: 'GET',
            backend_slug: 'performance',
            types: [
                actionTypes.GET_ISUE_TYPE_LIST_REQUEST,
                actionTypes.GET_ISUE_TYPE_LIST_SUCCESS,
                actionTypes.GET_ISUE_TYPE_LIST_FAILURE,
            ]
        }
    }),
    RESOLVE_ISSUE: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/matrices/anomaly/resolve/',
            method: 'POST',
            body : payload,
            backend_slug: 'performance',
            types: [
                actionTypes.POST_RESOLVE_ISSUE_REQUEST,
                actionTypes.POST_RESOLVE_ISSUE_SUCCESS,
                actionTypes.POST_RESOLVE_ISSUE_FAILURE,
            ]
        }
    }),
    GET_ISUE_LIST: (payload = {}, resetLoader=false)=>{
        const  separateLoaderCheckArray= ['grid_guids', 'grid_operator_guid', 'solbox_model',
            'firmware_version', 'issue_type', 'page', 'issue_status' , 'customer_type',
            'start_date','end_date' , 'solbox_serial_number', 'paginated'];
        let confirmationArray= [];
        Object.keys(payload).forEach((key)=>{
            if (separateLoaderCheckArray.indexOf(key)>-1){
                confirmationArray.push(key);
            }
        })

        return {
            [CALL_API]: {
                endpoint: paramsHandler('/matrices/anomaly/', payload),
                method: 'GET',
                backend_slug: 'performance',
                types: [
                    confirmationArray.length>0 && resetLoader===false? (confirmationArray.includes("paginated")? actionTypes.GET_ISSUE_LIST_FOR_CSV_EXPORT_REQUEST: actionTypes.GET_ISSUE_LIST_WITH_SEPARATE_LOADER_REQUEST): actionTypes.GET_ISUE_LIST_REQUEST,
                    actionTypes.GET_ISUE_LIST_SUCCESS,
                    actionTypes.GET_ISUE_LIST_FAILURE,
                ]
            }
        }
    },

    GET_REPLACEMENT_LIST: (payload={})=>{
        const  separateLoaderCheckArray= ['solbox_model_guid', 'organization_guid', 'status', 'grid_item_guid', 'node_type_id', 'page', 'solbox_serial_number' , 'replacement_date_from', 'replacement_date_to' ];
        let confirmationArray= [];
        Object.keys(payload).forEach((key)=>{
            if (separateLoaderCheckArray.indexOf(key)>-1){
                confirmationArray.push(key);
            }
        })
        return {
            [CALL_API]: {
                endpoint: paramsHandler('/node/node_replacement_details/', payload),   /*?node_type_id=${}&grid_owner_guid=${}*/
                method: 'GET',
                backend_slug: 'grid_backend',
                types: [
                    confirmationArray.length>0? actionTypes.GET_REPLACEMENT_LIST_WITH_SEPARATE_LOADER_REQUEST: actionTypes.GET_REPLACEMENT_LIST_REQUEST,
                    actionTypes.GET_REPLACEMENT_LIST_SUCCESS,
                    actionTypes.GET_REPLACEMENT_LIST_FAILURE,
                ]}}
    },




}
