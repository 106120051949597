import React, { useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import PropTypes from 'prop-types';
import DateTimeFormat from "../utils/dateFormat/DateTimeFormat";

const SolChart = ({ 
  xaxisDataType,
  yaxisLineColor,
  dataSeries, 
  mainTitle, 
  displayStartDate,
  displayEndDate,
  sideTitle, showStroke=true, dateTimeFormatter, min,

}) => {

  const [options, setOptions] = useState(
      {
        chart: {
          id: 'area-datetime',
            height: 190,
            width: "100%",
            type: "area",
            stacked: false,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true
              },
              export: {
                csv: {
                  filename: mainTitle,
                  columnDelimiter: ',',
                  headerCategory: 'category',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return moment(new Date(timestamp)).format("DD-MM-YYYY h:mm:ss a")
                  }
                },
                svg: {
                  filename: mainTitle,
                },
                png: {
                  filename: mainTitle,
                }
              },
              autoSelected: 'zoom' 
            }
        },
        colors: yaxisLineColor,
        stroke: {
          curve: 'smooth',
          show: showStroke,
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 1,
          colors: '#ffffff',
          // shape: "circle",
          strokeColors: '#111111',
        },
        title: {
          text: displayStartDate && displayEndDate ? `${mainTitle} (${displayStartDate} - ${displayEndDate})` : mainTitle,
          align: 'left',
          style: {
            fontSize:  '16px',
            color: 'black'
          }
        },
        animations: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          // gradient: {
          //   shadeIntensity: 1,
          //   inverseColors: false,
          //   opacityFrom: 0.5,
          //   opacityTo: 0,
          //   stops: [0, 90, 100]
          // },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return (val).toFixed(0);
            },
          },
          min: min,
          tickAmount: 15,
          title: {
            text: sideTitle,
            style: {
              fontSize: "16px"
            }
          },
        },
        xaxis: {
          type: xaxisDataType,
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
            }
          }
        },
        tooltip: {
          enabled: true,
          shared: false,
          x: {
            formatter:  (val) =>{
              return dateTimeFormatter? dateTimeFormatter(val): new DateTimeFormat(val).localDisplayTimeWithSeconds
            },
          },
          y: {
            formatter: function (val) {
              return (val).toFixed(2)
            }
          },
          z: {
            formatter: undefined,
            title: 'Data Received on: '
          }
        }
      }
  );

  const [series, setSeries] = useState(
      [
          {
              name: "Balance",
              data: [
                {
                  x: "02:00",
                  y: 3400,
                  z: "04:00"
                },
                {
                  x: "02:15",
                  y: 4300,
                  z: "04:15"
                },
                {
                  x: "02:30",
                  y: 3100,
                  z: "04:30"
                },
                {
                  x: "02:45",
                  y: 4300,
                  z: "04:45"
                },
                {
                  x: "03:00",
                  y: 3300,
                  z: "05:00"
                },
                {
                  x: "03:15",
                  y: 5200,
                  z: "05:15"
                },
                {
                  x: "03:30",
                  y: 3200,
                  z: "05:30"
                }
              ]
            }
      ]
  );

  return (
      <React.Fragment>
          <Chart
            options={options}
            series={dataSeries ? dataSeries : series}
            type="area"
          />
      </React.Fragment>
    );
}

SolChart.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  sideTitle: PropTypes.string.isRequired,
  xaxisDataType: PropTypes.string.isRequired,
  yaxisLineColor: PropTypes.array.isRequired,
  dataSeries: PropTypes.array.isRequired,
  displayStartDate: PropTypes.string,
  displayEndDate: PropTypes.string
};


SolChart.defaultProps ={
  min: undefined,
  max: undefined,
}

export default SolChart;
