import React, {useEffect, useState} from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import {Link} from "react-router-dom";
import moment from "moment";

const SolboxProfileDisplay = (props) => {

  const {
    parentSolboxId,
    parentSolboxGuid,
    solboxIdNormal,
    solboxGuid,
    parentSolboxDisplayData,
    parentSolboxCustomerData,
    showLiveStatus,
    connectedSolboxData,
    parentSolboxLiveData,
    connectedSolboxLiveData,
    parentSolboxPerformanceData,
    connectedSolboxPerformanceData
  } = props;

  const [totalWatt, setTotalWatt] = useState(0);

  useEffect(() => {
    if (parentSolboxCustomerData !== undefined &&
        parentSolboxCustomerData !== null &&
        parentSolboxCustomerData.total_wattage !== undefined &&
        parentSolboxCustomerData.total_wattage !== null &&
        parentSolboxCustomerData.total_wattage !== `N/A`
    ) {
      setTotalWatt(parentSolboxCustomerData.total_wattage);
    }
  }, [parentSolboxCustomerData]);

  const handleCustomerNameClick = (event, solboxGuid, customerGuid, solboxId, nodeType) => {

    props.history.push(
        `/customers/profile/${customerGuid}/${solboxGuid}/${solboxId}/${nodeType}`,
        {
          solbox_guid: solboxGuid,
          customer_guid: customerGuid,
          solbox_serial_number: solboxId
        }
    )
  }

  const handleGridNameClick = (event, gridGuid, gridName) => {
    props.history.push(`/grids/details/${gridGuid}`,
        {gridName: gridName}
    );
  }

  const handleSolboxClick = (event, solboxId, solboxGuid) => {
    props.history.push(`/solboxes/profile/${solboxId}/${solboxGuid}`);
  }

  const [housePorts, sethousePorts] = useState([]);

  useEffect(() => {
    if (connectedSolboxData && connectedSolboxData.length !== 0) {
      let housePortArr = [];
      connectedSolboxData.forEach((element, index) => {
        if (element.port) {
          housePortArr.push(element.port);
        }
      });
      sethousePorts(housePortArr);
    }
  }, [connectedSolboxData]);

  const renderOnlineLiveStatus = (solboxId) => {
    if (solboxId && connectedSolboxLiveData && connectedSolboxLiveData.length !== 0) {
      let onlineStatus = null;
      for (let i = 0; i < connectedSolboxLiveData.length; i++) {
        if (solboxId.substring(
                solboxId.length - 4,
                solboxId.length)
            === connectedSolboxLiveData[i].solbox_id.toString(10)
        ) {
          onlineStatus = <React.Fragment>
            <FiberManualRecordIcon
                style={{color: "#0B6E4F"}}
            />
          </React.Fragment>;
          break;
        }
      }
      return onlineStatus;
    }
  }

  const renderOfflineLiveStatus = (solboxId) => {
    if (solboxId && connectedSolboxLiveData && connectedSolboxLiveData.length !== 0) {
      let offlineStatus = null;
      for (let i = 0; i < connectedSolboxLiveData.length; i++) {
        if (solboxId.substring(
                solboxId.length - 4,
                solboxId.length)
            !== connectedSolboxLiveData[i].solbox_id.toString(10)
        ) {
          offlineStatus = <React.Fragment>
            <FiberManualRecordIcon
                style={{color: "#CB152B"}}
            />
          </React.Fragment>;
          break;
        }
      }
      return offlineStatus;
    }
  }

  const renderLiveStatus = (solboxId) => {
    if (solboxId) {
      let onlineStatus = renderOnlineLiveStatus(solboxId);
      let offlineStatus = renderOfflineLiveStatus(solboxId);
      if (onlineStatus) {
        return onlineStatus;
      }
      if (offlineStatus) {
        return offlineStatus;
      }
    }
  }

  const renderOnlinePerformanceStatus = (solboxGuid) => {
    if (solboxGuid && connectedSolboxPerformanceData && connectedSolboxPerformanceData.length !== 0) {
      let onlineStatus = null;
      for (let i = 0; i < connectedSolboxPerformanceData.length; i++) {
        if (connectedSolboxPerformanceData[i].solbox_guid &&
            solboxGuid === connectedSolboxPerformanceData[i].solbox_guid &&
            connectedSolboxPerformanceData[i].status &&
            connectedSolboxPerformanceData[i].status.toLowerCase() === "online"
        ) {
          onlineStatus = <React.Fragment>
            <FiberManualRecordIcon
                style={{color: "#0B6E4F"}}
            />
          </React.Fragment>;
          break;
        }
      }
      return onlineStatus;
    }
  }

  const renderOfflinePerformanceStatus = (solboxGuid) => {
    if (solboxGuid && connectedSolboxPerformanceData && connectedSolboxPerformanceData.length !== 0) {
      let offlineStatus = null;
      for (let i = 0; i < connectedSolboxPerformanceData.length; i++) {
        if (connectedSolboxPerformanceData[i].solbox_guid &&
            solboxGuid === connectedSolboxPerformanceData[i].solbox_guid &&
            connectedSolboxPerformanceData[i].status &&
            connectedSolboxPerformanceData[i].status.toLowerCase() === "offline"
        ) {
          offlineStatus = <React.Fragment>
            <FiberManualRecordIcon
                style={{color: "#CB152B"}}
            />
          </React.Fragment>;
          break;
        }
        if (connectedSolboxPerformanceData[i].solbox_guid &&
            solboxGuid !== connectedSolboxPerformanceData[i].solbox_guid
        ) {
          offlineStatus = <React.Fragment>
            <FiberManualRecordIcon
                style={{color: "#CB152B"}}
            />
          </React.Fragment>;
          break;
        }
      }
      return offlineStatus;
    }
  }

  const renderPerformanceStatus = (solboxGuid) => {
    if (solboxGuid) {
      let onlineStatus = renderOnlinePerformanceStatus(solboxGuid);
      let offlineStatus = renderOfflinePerformanceStatus(solboxGuid);
      if (onlineStatus) {
        return onlineStatus;
      }
      if (offlineStatus) {
        return offlineStatus;
      }
    }
  }

  const renderLiveData = (solboxId) => {
    if (solboxId && connectedSolboxLiveData && connectedSolboxLiveData.length !== 0) {
      let liveDataObj = <div className="d-block mt-1">{`No Data Found`}</div>;
      connectedSolboxLiveData.forEach((element, index) => {
        if (
            element.solbox_id &&
            solboxId.substring(
                solboxId.length - 4,
                solboxId.length)
            === element.solbox_id.toString(10)
        ) {
          liveDataObj = <div className="d-block mt-1" key={index}>
            <span className="d-inline-block"
                  style={{"textTransform": "capitalize"}}>{element.solbox_mode ? `${element.solbox_mode} mode,` : null}</span>
            <span className="d-inline-block ml-2">{element.i2Grid ? `i2Grid: ${element.i2Grid} mA,` : null}</span>
            <span
                className="d-inline-block ml-2">{element.battery_voltage ? `Battery: ${element.battery_voltage}V` : null}</span>
            <span
                className="d-block mt-1">{element.updated_at ? `Last data received on ${moment(element.updated_at).format('lll')}` : null}</span>
          </div>
        }
      });
      return liveDataObj;
    }
  }

  const renderPerformanceData = (solboxGuid) => {
    if (solboxGuid && connectedSolboxPerformanceData && connectedSolboxPerformanceData.length !== 0) {
      let performanceDataObj = <div className="d-block mt-1">{`No Data Found`}</div>;
      connectedSolboxPerformanceData.forEach((element, index) => {
        if (element.solbox_guid &&
            element.solbox_guid === solboxGuid
        ) {
          performanceDataObj = <div className="d-block mt-1" key={index}>
            <span className="d-inline-block"
                  style={{"textTransform": "capitalize"}}>{element.solbox_mode ? `${element.solbox_mode} mode,` : null}</span>
            <span className="d-inline-block ml-2">{element.i2Grid ? `i2Grid: ${element.i2Grid} mA,` : null}</span>
            <span
                className="d-inline-block ml-2">{element.battery_voltage ? `Battery: ${element.battery_voltage}V` : null}</span>
            <span
                className="d-block mt-1">{element.updated_at ? `Last data received on ${moment(element.updated_at).format('lll')}` : null}</span>
          </div>
        }
      });
      return performanceDataObj;
    }
  }

  const handleRenderAppliances = () => {
    if (parentSolboxCustomerData &&
        parentSolboxCustomerData.appliances &&
        parentSolboxCustomerData.appliances.length !== 0 &&
        parentSolboxCustomerData.appliances !== `N/A`
    ) {
      let appliances = "";
      parentSolboxCustomerData.appliances.forEach((element, index) => {
        if (element.quantity && element.name) {
          appliances += `${element.quantity} (${element.name.replaceAll('_', ' ')}), `
        }
      });
      return appliances.substring(0, appliances.length - 2);
    }
  }

  return (
      <React.Fragment>
        <div
            className={`row mb-5`}
            style={{borderBottom: "1px solid rgba(0, 0, 0, 0.1)"}}
        >
          <div className={`col-12 col-sm-6 mb-5`}>
            <h3 className={`list-bold-text mb-4`}>
              {parentSolboxDisplayData &&
              parentSolboxId &&
              parentSolboxGuid &&
              parentSolboxDisplayData.customer_guid &&
              parentSolboxDisplayData.node_type &&
              parentSolboxDisplayData.node_type.toLowerCase() === "microutility"
                  ?
                  <Link
                      target="_blank"
                      to={`/customers/profile/${parentSolboxDisplayData?.customer_guid}/${parentSolboxGuid}/${parentSolboxId}/${parentSolboxDisplayData?.node_type}`}
                      style={{color: "#F18D00"}}
                  >
                    {`Microutility - ${parentSolboxId.substring(parentSolboxId.length - 4, parentSolboxId.length)}`}
                  </Link>
                  : parentSolboxId
                      ? `SOLbox ${parentSolboxId}`
                      : `SOLbox`
              }
            </h3>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Firmware:`}</div>
              <div className={`col-6 col-sm-9 list-filtered-params`}>
                {parentSolboxDisplayData
                    ? parentSolboxDisplayData.firmware
                    : `N/A`}
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Model:`}</div>
              <div className={`col-6 col-sm-9 list-filtered-params`}>
                {parentSolboxDisplayData
                    ? parentSolboxDisplayData.model
                    : `N/A`}
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-12`}>
                {parentSolboxLiveData &&
                parentSolboxLiveData.length !== 0 ? (
                        parentSolboxLiveData.map((element, index) => {
                          if (element.solbox_id &&
                              parentSolboxId &&
                              element.solbox_id == parentSolboxId.substring(parentSolboxId.length - 4, parentSolboxId.length) &&
                              element.status &&
                              element.status.toLowerCase() === "online"
                          ) {
                            return (
                                <React.Fragment>
                        <span className={`mr-1`}>
                          {`${element.status} at ${element.solbox_mode ? element.solbox_mode : null} Mode`}
                        </span>
                                  <FiberManualRecordIcon style={{color: "#0B6E4F"}}/>
                                </React.Fragment>
                            );
                          }
                        })
                    )
                    :
                    //showLiveStatus === false &&
                    parentSolboxPerformanceData &&
                    parentSolboxPerformanceData.length !== 0 ? (
                        parentSolboxPerformanceData.map((element, index) => {
                          if (
                              element.solbox_guid &&
                              parentSolboxGuid &&
                              element.solbox_guid === parentSolboxGuid &&
                              element.status
                          ) {
                            return (
                                <React.Fragment>
                                  {element.status.toLowerCase() === "online" ?
                                      <React.Fragment>
                            <span className={`mr-1`} style={{"textTransform": "capitalize"}}>
                            {`${element.status} at ${element.solbox_mode ? element.solbox_mode : null} Mode`}
                            </span>
                                        <FiberManualRecordIcon style={{color: "#0B6E4F"}}/>
                                      </React.Fragment>
                                      :
                                      <React.Fragment>
                            <span className={`mr-1`} style={{"textTransform": "capitalize"}}>
                            {`${element.status} at ${element.solbox_mode ? element.solbox_mode : null} Mode`}
                            </span>
                                        <FiberManualRecordIcon style={{color: "#CB152B"}}/>
                                      </React.Fragment>
                                  }
                                </React.Fragment>
                            );
                          }
                        })
                    ) : (
                        <React.Fragment>
                          <span className={`mr-1`}>{`Offline`}</span>
                          <FiberManualRecordIcon style={{color: "#CB152B"}}/>
                        </React.Fragment>
                    )}
              </div>
            </div>
            {/*TODO: In the following SOLbox balance is shown from live data or performance metrix data*/}
            {/*TODO: So we need to check if there is any chance balance is getting updated at the time of replacement ?*/}
            {/*TODO: Or live data API/Performance metrix API is showing balance data from wrong point ?*/}
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Balance:`}</div>
              <div
                  className={`col-6 col-sm-9 list-bold-text list-filtered-params`}
              >
                {parentSolboxLiveData &&
                parentSolboxLiveData.length !== 0
                    ? parentSolboxLiveData.map((element, index) => {
                      if (
                          element.solbox_id &&
                          parentSolboxId &&
                          element.solbox_id == parentSolboxId.substring(parentSolboxId.length - 4, parentSolboxId.length) &&
                          element.balance !== undefined &&
                          element.balance !== null
                      ) {
                        return <React.Fragment>
                          {(typeof element.balance === "number" && element.balance !== 0) ?
                              `${(element.balance / 100).toFixed(2)} Tk`
                              :
                              element.balance === 0 ?
                                  `${element.balance} Tk`
                                  :
                                  `${element.balance !== "N/A" ? (Number.parseInt(element.balance) / 100).toFixed(2) : element.balance} Tk`
                          }
                        </React.Fragment>;
                      }
                    })
                    : parentSolboxPerformanceData &&
                    parentSolboxPerformanceData.length !== 0
                        ? parentSolboxPerformanceData.map((element, index) => {
                          if (
                              element.solbox_guid &&
                              parentSolboxGuid &&
                              element.solbox_guid === parentSolboxGuid &&
                              element.balance !== undefined &&
                              element.balance !== null
                          ) {
                            return <React.Fragment>
                              {(typeof element.balance === "number" && element.balance !== 0) ?
                                  `${(element.balance / 100).toFixed(2)} Tk`
                                  :
                                  element.balance === 0 ?
                                      `${element.balance} Tk`
                                      :
                                      `${element.balance !== "N/A" ? (Number.parseInt(element.balance) / 100).toFixed(2) : element.balance} Tk`
                              }
                            </React.Fragment>;
                          }
                        })
                        : `N/A`}
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Tariff:`}</div>
              {parentSolboxLiveData &&
              parentSolboxLiveData.length !== 0
                  ? parentSolboxLiveData.map((element, index) => {
                    if (element.solbox_id &&
                        parentSolboxId &&
                        element.solbox_id == parentSolboxId.substring(
                            parentSolboxId.length - 4, parentSolboxId.length) &&
                        element.u16BuyPrice && element.u16SellPrice
                    ) {
                      return (
                          <div className={`col-6 col-sm-9 list-filtered-params`}>
                            {`Buy ${element.current_buy_price} Tk/Wh, Sell ${element.current_sell_price} Tk/Wh`}
                          </div>
                      );
                    }
                  })
                  : parentSolboxPerformanceData &&
                  parentSolboxPerformanceData.length !== 0
                      ? parentSolboxPerformanceData.map((element, index) => {
                        if (element.solbox_guid &&
                            parentSolboxGuid &&
                            element.solbox_guid === parentSolboxGuid &&
                            element.current_buy_price && element.current_sell_price
                        ) {
                          return (
                              <div className={`col-6 col-sm-9 list-filtered-params`}>
                                {`Buy ${element.current_buy_price} Tk/Wh, Sell ${element.current_sell_price} Tk/Wh`}
                              </div>
                          );
                        }
                      })
                      : `N/A`}
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Exchange Control:`}</div>
              <div className={`col-6 col-sm-9 text-danger list-filtered-params`}
                   style={{"textTransform": "capitalize"}}>
                {parentSolboxDisplayData
                    ? parentSolboxDisplayData.exchange_control
                    : `N/A`}
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Threshold Value:`}</div>
              <div className={`col-6 col-sm-9 list-filtered-params`}>
                {parentSolboxDisplayData &&
                parentSolboxDisplayData.threshold_value !== "N/A"
                    ? `${parentSolboxDisplayData.threshold_value} mA`
                    : `N/A`}
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Router SSID:`}</div>
              <div className={`col-6 col-sm-9 list-filtered-params`}>
                {parentSolboxLiveData &&
                parentSolboxLiveData.length !== 0
                    ? parentSolboxLiveData.map((element, index) => {
                      if (element.solbox_id &&
                          parentSolboxId &&
                          element.solbox_id == parentSolboxId.substring(
                              parentSolboxId.length - 4, parentSolboxId.length) &&
                          element.router_ssid
                      ) {
                        return (
                            <React.Fragment>{element.router_ssid}</React.Fragment>
                        );
                      }
                    })
                    : parentSolboxPerformanceData &&
                    parentSolboxPerformanceData.length !== 0
                        ? parentSolboxPerformanceData.map((element, index) => {
                          if (element.solbox_guid &&
                              parentSolboxGuid &&
                              element.solbox_guid === parentSolboxGuid &&
                              element.router_ssid
                          ) {
                            return (
                                <React.Fragment>{element.router_ssid}</React.Fragment>
                            );
                          }
                        })
                        : `N/A`}
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-3`}>{`Registration Date:`}</div>
              <div className={`col-6 col-sm-9 list-filtered-params`}>
                {parentSolboxDisplayData?.customer_info? moment(parentSolboxDisplayData.customer_info.registration_date).format("MMMM DD, YYYY") : "N/A"}
              </div>
            </div>
            {/*<div className={`row mb-2`}>*/}
            {/*  <div className={`col-6 col-sm-3`}>{`OTA availability:`}</div>*/}
            {/*  <div className={`col-6 col-sm-9 list-filtered-params`}>*/}
            {/*    {parentSolboxDisplayData? <>{parentSolboxDisplayData.ota_availability === 'Yes'? <span style={{color: "#0B6E4F"}}>Yes</span>:*/}
            {/*        <span style={{color: "#CB152B"}}>{parentSolboxDisplayData.ota_availability}</span>}</>:<>N/A</>}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className={`col-12 col-sm-6 mt-5`}>
            {(parentSolboxDisplayData) ?
                <h4 className={`list-bold-text mb-4`}>
                  {parentSolboxId &&
                  parentSolboxDisplayData.node_type !== "Microutility"
                      ? `Customer of SOLbox ${parentSolboxId}`
                      : parentSolboxDisplayData.node_type === "Microutility"
                          ? `Nearby customer of this MU`
                          : `Customer`}
                </h4>
                :
                <h4 className={`list-bold-text mb-4`}>{`N/A`}</h4>
            }
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-12`}>
                {parentSolboxCustomerData && parentSolboxCustomerData.name ?
                    <span
                        className={`sol-text d-inline inter-module-link`}
                    >
                  {parentSolboxDisplayData &&
                  parentSolboxDisplayData.node_type &&
                  parentSolboxDisplayData.node_type.toLowerCase() === "microutility" &&
                  parentSolboxDisplayData.nearby_node &&
                  parentSolboxDisplayData.nearby_node.customer_guid &&
                  parentSolboxDisplayData.nearby_node.solbox_guid &&
                  parentSolboxDisplayData.nearby_node.solbox_serial_number &&
                  parentSolboxDisplayData.nearby_node.node_type
                      ?
                      <Link
                          target="_blank"
                          to={`/customers/profile/${parentSolboxDisplayData.nearby_node.customer_guid}/${parentSolboxDisplayData.nearby_node.solbox_guid}/${parentSolboxDisplayData.nearby_node.solbox_serial_number}/${parentSolboxDisplayData.nearby_node.node_type}`}
                          style={{color: "#F18D00"}}
                      >
                        {parentSolboxCustomerData.name}
                      </Link>
                      :
                      parentSolboxDisplayData &&
                      parentSolboxGuid &&
                      parentSolboxId &&
                      parentSolboxDisplayData.customer_guid &&
                      parentSolboxDisplayData.node_type &&
                      parentSolboxDisplayData.node_type.toLowerCase() !== "microutility"
                          ?
                          <Link
                              target="_blank"
                              to={`/customers/profile/${parentSolboxDisplayData?.customer_guid}/${parentSolboxGuid}/${parentSolboxId}/${parentSolboxDisplayData?.node_type}`}
                              style={{color: "#F18D00"}}
                          >
                            {parentSolboxCustomerData.name}
                          </Link>
                          :
                          `${parentSolboxCustomerData.name}`
                  }
                </span>
                    :
                    <span className={`normal-text d-inline`}>{`N/A`}</span>
                }
                {parentSolboxDisplayData ? (
                    <div className={`node-type-cell-container d-inline-block ml-3`}>
                  <span className={`node-type-cell`}>
                    {parentSolboxDisplayData.node_type
                        ? parentSolboxDisplayData.node_type.toLowerCase() === "consumer"
                            ? parentSolboxDisplayData.node_type
                                .substring(0, 2)
                                .toUpperCase()
                            : parentSolboxDisplayData.node_type.toLowerCase() === "microutility" &&
                            parentSolboxDisplayData.nearby_node &&
                            parentSolboxDisplayData.nearby_node.node_type &&
                            parentSolboxDisplayData.nearby_node.node_type.toLowerCase() === "consumer"
                                ? parentSolboxDisplayData.nearby_node.node_type
                                    .substring(0, 2)
                                    .toUpperCase()
                                : parentSolboxDisplayData.node_type.toLowerCase() === "microutility" &&
                                parentSolboxDisplayData.nearby_node &&
                                parentSolboxDisplayData.nearby_node.node_type &&
                                parentSolboxDisplayData.nearby_node.node_type.toLowerCase() === "prosumer"
                                    ? parentSolboxDisplayData.nearby_node.node_type
                                        .substring(0, 3)
                                        .toUpperCase()
                                    : parentSolboxDisplayData.node_type.toLowerCase() === "microutility" &&
                                    parentSolboxDisplayData.nearby_node &&
                                    parentSolboxDisplayData.nearby_node.node_type &&
                                    parentSolboxDisplayData.nearby_node.node_type.toLowerCase() === "producer"
                                        ? `PRD`
                                        : parentSolboxDisplayData.node_type.toLowerCase() === "producer"
                                            ? `PRD`
                                            : parentSolboxDisplayData.node_type.toLowerCase() === "prosumer"
                                                ? parentSolboxDisplayData.node_type
                                                    .substring(0, 3)
                                                    .toUpperCase()
                                                : null
                        : null}
                  </span>
                    </div>
                ) : (
                    <span className="ml-3">
                  <AspectRatioOutlinedIcon fontSize="large"/>
                </span>
                )}
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-12`}>
              <span className={`normal-text`}>
                {parentSolboxCustomerData
                    ? parentSolboxCustomerData.primary_phone
                    : `N/A`}
              </span>
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-12`}>
              <span className={`normal-text d-block`}>
                {parentSolboxCustomerData &&
                parentSolboxCustomerData.occupation !== `N/A` &&
                parentSolboxCustomerData.income_range !== `N/A`
                    ? parentSolboxCustomerData.occupation.concat(
                        "|",
                        parentSolboxCustomerData.income_range,
                        ` Taka Monthly Income`
                    )
                    : `N/A`}
              </span>
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-12`}>
              <span className={`normal-text`}>
                {(parentSolboxCustomerData &&
                    parentSolboxCustomerData.appliances &&
                    parentSolboxCustomerData.appliances.length !== 0 &&
                    parentSolboxCustomerData.appliances !== `N/A`) &&
                `Uses `}
              </span>
                <span className={`normal-text`}>
                {parentSolboxCustomerData &&
                parentSolboxCustomerData.appliances &&
                parentSolboxCustomerData.appliances.length !== 0 &&
                parentSolboxCustomerData.appliances !== `N/A`
                    ? handleRenderAppliances()
                    : `No Appliances Found`}
              </span>
                <span className="normal-text">
                {parentSolboxCustomerData &&
                parentSolboxCustomerData.appliances &&
                parentSolboxCustomerData.appliances.length !== 0 &&
                parentSolboxCustomerData.appliances !== `N/A` &&
                totalWatt !== undefined &&
                totalWatt !== null ?
                    ` | Total ${totalWatt} Watts`
                    :
                    null
                }
              </span>
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-12`}>
                {parentSolboxDisplayData &&
                parentSolboxDisplayData.grid_name &&
                parentSolboxDisplayData.grid_address_info ?
                    <span
                        className={`sol-text inter-module-link`}
                    >
                <Link
                    target="_blank"
                    to={{
                      pathname: `/grids/details/${parentSolboxDisplayData?.grid_guid}`,
                      state: {
                        gridName: parentSolboxDisplayData?.grid_name,
                      }
                    }}
                    style={{color: "#F18D00"}}
                >
                {`${parentSolboxDisplayData.grid_name}-
                  ${parentSolboxDisplayData.grid_address_info.division ?
                    parentSolboxDisplayData.grid_address_info.division : "N/A"}-
                  ${parentSolboxDisplayData.grid_address_info.district ?
                    parentSolboxDisplayData.grid_address_info.district : "N/A"}`
                }
                </Link>
              </span>
                    :
                    <span className="normal-text">{`N/A`}</span>
                }
              </div>
            </div>
          </div>
        </div>
        <div className={`row mb-5`}>
          <div className={`col-12 mb-5`}>
            <h4 className={`list-bold-text mt-4 mb-4`}>{`Connections`}</h4>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-2`}>
                {`Houseport 1:`}
              </div>
              {connectedSolboxData &&
              connectedSolboxData.length !== 0
              && connectedSolboxData.map((connectedSolbox, connectedSolboxIndex) => {
                if (connectedSolbox.port !== undefined &&
                    connectedSolbox.port !== null &&
                    connectedSolbox.port == "1"
                ) {
                  return (
                      <React.Fragment>
                        <div className={`col-6 col-sm-10 list-filtered-params`}>
                          {connectedSolboxLiveData &&
                          connectedSolbox.solbox_serial_number &&
                          connectedSolboxLiveData.length !== 0 ?
                              renderLiveStatus(connectedSolbox.solbox_serial_number)
                              :
                              connectedSolboxPerformanceData &&
                              connectedSolbox.solbox_guid &&
                              //showLiveStatus === false &&
                              connectedSolboxPerformanceData.length !== 0 ?
                                  renderPerformanceStatus(connectedSolbox.solbox_guid)
                                  : (
                                      <React.Fragment>
                                        <FiberManualRecordIcon
                                            style={{color: "#CB152B"}}
                                        />
                                      </React.Fragment>
                                  )}
                          <span
                              className={`sol-text inter-module-link`}
                          >
                            <Link
                                target="_blank"
                                to={`/solboxes/profile/${connectedSolbox?.solbox_serial_number}/${connectedSolbox?.solbox_guid}`}
                                style={{color: "#F18D00"}}
                            >
                            {`${connectedSolbox.solbox_serial_number} `}
                            </Link>
                          </span>
                          <span
                              className={`sol-text inter-module-link`}
                          >
                            <Link
                                target="_blank"
                                to={`/customers/profile/${connectedSolbox?.customer_guid}/${connectedSolbox?.solbox_guid}/${connectedSolbox?.solbox_serial_number}/${connectedSolbox?.node_type}`}
                                style={{color: "#F18D00"}}
                            >
                            {` (${connectedSolbox.customer_name})`}
                            </Link>
                          </span>
                          {connectedSolbox.node_type ? (
                              <div
                                  className={`node-type-cell-container d-inline-block ml-3`}
                              >
                              <span className={`node-type-cell`}>
                                {connectedSolbox.node_type
                                    ? connectedSolbox.node_type === "Consumer"
                                        ? connectedSolbox.node_type
                                            .substring(0, 2)
                                            .toUpperCase()
                                        : connectedSolbox.node_type === "Microutility"
                                            ? `MU`
                                            : connectedSolbox.node_type === "Producer"
                                                ? `PRD`
                                                : connectedSolbox.node_type
                                                    .substring(0, 3)
                                                    .toUpperCase()
                                    : null}
                              </span>
                              </div>
                          ) : (
                              <span className="ml-3">
                              <AspectRatioOutlinedIcon fontSize="large"/>
                            </span>
                          )}
                          {connectedSolboxLiveData &&
                          connectedSolbox.solbox_serial_number &&
                          connectedSolboxLiveData.length !== 0 ?
                              renderLiveData(connectedSolbox.solbox_serial_number)
                              :
                              connectedSolboxPerformanceData &&
                              connectedSolbox.solbox_guid &&
                              //showLiveStatus === false &&
                              connectedSolboxPerformanceData.length !== 0 ?
                                  renderPerformanceData(connectedSolbox.solbox_guid)
                                  :
                                  <div className="d-block mt-1">{`No Data Found`}</div>
                          }
                        </div>
                      </React.Fragment>
                  );
                }
              })
              }
              {housePorts && !housePorts.includes("1") &&
              `No Connection`
              }
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-2`}>
                {`Houseport 2:`}
              </div>
              {connectedSolboxData !== undefined &&
              connectedSolboxData !== 0 &&
              connectedSolboxData.length !== 0
              && connectedSolboxData.map((connectedSolbox, connectedSolboxIndex) => {
                if (connectedSolbox.port !== undefined &&
                    connectedSolbox.port !== null &&
                    connectedSolbox.port == "2"
                ) {
                  return (
                      <React.Fragment>
                        <div className={`col-6 col-sm-10 list-filtered-params`}>
                          {connectedSolboxLiveData &&
                          connectedSolbox.solbox_serial_number &&
                          connectedSolboxLiveData.length !== 0 ?
                              renderLiveStatus(connectedSolbox.solbox_serial_number)
                              :
                              connectedSolboxPerformanceData &&
                              connectedSolbox.solbox_guid &&
                              //showLiveStatus === false &&
                              connectedSolboxPerformanceData.length !== 0 ?
                                  renderPerformanceStatus(connectedSolbox.solbox_guid)
                                  : (
                                      <React.Fragment>
                                        <FiberManualRecordIcon
                                            style={{color: "#CB152B"}}
                                        />
                                      </React.Fragment>
                                  )}
                          <span
                              className={`sol-text inter-module-link`}
                          >
                            <Link
                                target="_blank"
                                to={`/solboxes/profile/${connectedSolbox?.solbox_serial_number}/${connectedSolbox?.solbox_guid}`}
                                style={{color: "#F18D00"}}
                            >
                            {`${connectedSolbox.solbox_serial_number} `}
                            </Link>
                          </span>
                          <span
                              className={`sol-text inter-module-link`}
                          >
                            <Link
                                target="_blank"
                                to={`/customers/profile/${connectedSolbox?.customer_guid}/${connectedSolbox?.solbox_guid}/${connectedSolbox?.solbox_serial_number}/${connectedSolbox?.node_type}`}
                                style={{color: "#F18D00"}}
                            >
                            {` (${connectedSolbox.customer_name})`}
                            </Link>
                          </span>
                          {connectedSolbox.node_type !== undefined &&
                          connectedSolbox.node_type !== null ? (
                              <div
                                  className={`node-type-cell-container d-inline-block ml-3`}
                              >
                              <span className={`node-type-cell`}>
                                {connectedSolbox.node_type !== undefined &&
                                connectedSolbox.node_type !== null
                                    ? connectedSolbox.node_type === "Consumer"
                                        ? connectedSolbox.node_type
                                            .substring(0, 2)
                                            .toUpperCase()
                                        : connectedSolbox.node_type === "Microutility"
                                            ? `MU`
                                            : connectedSolbox.node_type === "Producer"
                                                ? `PRD`
                                                : connectedSolbox.node_type
                                                    .substring(0, 3)
                                                    .toUpperCase()
                                    : null}
                              </span>
                              </div>
                          ) : (
                              <span className="ml-3">
                              <AspectRatioOutlinedIcon fontSize="large"/>
                            </span>
                          )}
                          {connectedSolboxLiveData &&
                          connectedSolbox.solbox_serial_number &&
                          connectedSolboxLiveData.length !== 0 ?
                              renderLiveData(connectedSolbox.solbox_serial_number)
                              :
                              connectedSolboxPerformanceData &&
                              connectedSolbox.solbox_guid &&
                              //showLiveStatus === false &&
                              connectedSolboxPerformanceData.length !== 0 ?
                                  renderPerformanceData(connectedSolbox.solbox_guid)
                                  :
                                  <div className="d-block mt-1">{`No Data Found`}</div>
                          }
                        </div>
                      </React.Fragment>
                  );
                }
              })
              }
              {housePorts && !housePorts.includes("2") &&
              `No Connection`
              }
            </div>
            <div className={`row mb-2`}>
              <div className={`col-6 col-sm-2`}>
                {`Houseport 3:`}
              </div>
              {connectedSolboxData !== undefined &&
              connectedSolboxData !== 0 &&
              connectedSolboxData.length !== 0
              && connectedSolboxData.map((connectedSolbox, connectedSolboxIndex) => {
                if (connectedSolbox.port !== undefined &&
                    connectedSolbox.port !== null &&
                    connectedSolbox.port == "3"
                ) {
                  return (
                      <React.Fragment>
                        <div className={`col-6 col-sm-10 list-filtered-params`}>
                          {connectedSolboxLiveData &&
                          connectedSolbox.solbox_serial_number &&
                          connectedSolboxLiveData.length !== 0 ?
                              renderLiveStatus(connectedSolbox.solbox_serial_number)
                              :
                              connectedSolboxPerformanceData &&
                              connectedSolbox.solbox_guid &&
                              //showLiveStatus === false &&
                              connectedSolboxPerformanceData.length !== 0 ?
                                  renderPerformanceStatus(connectedSolbox.solbox_guid)
                                  : (
                                      <React.Fragment>
                                        <FiberManualRecordIcon
                                            style={{color: "#CB152B"}}
                                        />
                                      </React.Fragment>
                                  )}
                          <span
                              className={`sol-text inter-module-link`}
                          >
                            <Link
                                target="_blank"
                                to={`/solboxes/profile/${connectedSolbox?.solbox_serial_number}/${connectedSolbox?.solbox_guid}`}
                                style={{color: "#F18D00"}}
                            >
                            {`${connectedSolbox.solbox_serial_number} `}
                            </Link>
                          </span>
                          <span
                              className={`sol-text inter-module-link`}
                          >
                            <Link
                                target="_blank"
                                to={`/customers/profile/${connectedSolbox?.customer_guid}/${connectedSolbox?.solbox_guid}/${connectedSolbox?.solbox_serial_number}/${connectedSolbox?.node_type}`}
                                style={{color: "#F18D00"}}
                            >
                            {` (${connectedSolbox.customer_name})`}
                            </Link>
                          </span>
                          {connectedSolbox.node_type !== undefined &&
                          connectedSolbox.node_type !== null ? (
                              <div
                                  className={`node-type-cell-container d-inline-block ml-3`}
                              >
                              <span className={`node-type-cell`}>
                                {connectedSolbox.node_type !== undefined &&
                                connectedSolbox.node_type !== null
                                    ? connectedSolbox.node_type === "Consumer"
                                        ? connectedSolbox.node_type
                                            .substring(0, 2)
                                            .toUpperCase()
                                        : connectedSolbox.node_type === "Microutility"
                                            ? `MU`
                                            : connectedSolbox.node_type === "Producer"
                                                ? `PRD`
                                                : connectedSolbox.node_type
                                                    .substring(0, 3)
                                                    .toUpperCase()
                                    : null}
                              </span>
                              </div>
                          ) : (
                              <span className="ml-3">
                              <AspectRatioOutlinedIcon fontSize="large"/>
                            </span>
                          )}
                          {connectedSolboxLiveData &&
                          connectedSolbox.solbox_serial_number &&
                          connectedSolboxLiveData.length !== 0 ?
                              renderLiveData(connectedSolbox.solbox_serial_number)
                              :
                              connectedSolboxPerformanceData &&
                              connectedSolbox.solbox_guid &&
                              //showLiveStatus === false &&
                              connectedSolboxPerformanceData.length !== 0 ?
                                  renderPerformanceData(connectedSolbox.solbox_guid)
                                  :
                                  <div className="d-block mt-1">{`No Data Found`}</div>
                          }
                        </div>
                      </React.Fragment>
                  );
                }
              })
              }
              {housePorts && !housePorts.includes("3") &&
              `No Connection`
              }
            </div>
          </div>
        </div>
      </React.Fragment>
  );
};

export default SolboxProfileDisplay;
