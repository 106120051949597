import React, {useEffect, useRef, useState} from 'react';
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {Badge, Breadcrumb, Button, Modal, Spinner} from "react-bootstrap";
import {ActionMenu, SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {ToastContainer} from "react-toastify";
import DataTable from "../../../../components/dataTable/DataTable";
import {customerListColumns} from "./customerListColumns";
import {DataTableContainer, LoadingSpinner, requestCycle} from '../../utils';
import {FilterForm} from './CustomerListFilter'
import {actions} from "../../actions";
import {connect} from "react-redux";
import {filter as searchFilter} from "smart-array-filter";
import {incompatibleFirmwareVersion} from "../../../../utils/firmwareVersionChecking/firmwareVersionChecking";
import {CustomerType, OnlineStatus} from "../../../../components/utilityComponents/SOlTypography";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {ExportToCsv} from "export-to-csv";
import {Menu, MenuItem} from "@material-ui/core";
import LiveDataComponent from "../../../commonFeatureModules/views/LiveDataComponent";
import GridProfile from "./GridProfile";
import {Link} from "react-router-dom";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {pluralizer} from '../../../../utils/utilityFunctions';
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import moment from "moment";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});


const GridDetails = (props) => {

    const [gridList, setGridList] = useState([]);
    const [filterTriggeredLoading, setFilterTriggeredLoading] = useState(false);
    const [page, setPage] = useState(null);
    const [filter, setFilter] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [models, setModels] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [dataNodes, setDataNodes] = useState([]);
    const [boxArray, setBoxArray] = useState([]);
    const [liveBoxArray, setLiveBoxArray] = useState([]);
    const [legacyBoxArray, setLegacyBoxArray] = useState([]);
    const [legacyBoxGuidArray, setLegacyBoxGuidArray] = useState([]);
    const [loadingSubtext, setLoadingSubtext] = useState(null);
    const [backEndFilterParams, setBackEndFilterParams] = useState({});
    const [frontEndFilterParams, setFrontEndFilterParams] = useState({});
    const [projectedParams, setProjectedParams] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [firstCall, setFirstCall] = useState(true);
    const [isShowingParamsData, setIsShowingParamsData] = useState(true);
    const filterFormRef = useRef(null);
    const [boxCount, setBoxCount] = useState({
        consumer: {
            total: 0,
            offline: 0,
        },
        prosumer: {
            total: 0,
            offline: 0,
        },
        producer: {
            total: 0,
            offline: 0,
        },
        mu: {
            total: 0,
            offline: 0,
        },
    });
    const classes = useStylesIconButton();
    const {
        GET_MODELS_LIST,
        GET_CUSTOMERS_LIST,
        GET_LIVE_DATA,
        PUBLISH_TOPIC,
        GET_GRIDS_NODE_LIST,
        GET_LEGACY_LIVE_DATA,
        SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED,
    } = props;
    const [currentRowData, setCurrentRowData] = useState(null);
    const [[anchorEl, selectedRow], setAnchorEl] = useState([null, undefined]);
    const subHeader = useSubheader();

    useEffect(() => {
        if (props.nodeList && props.nodeList.length > 0) {
            props.nodeList.sort(function(a, b){
                return a.solbox_serial_number - b.solbox_serial_number;
            });
        }
    }, [props.nodeList]);

    const moreIconClick = (e, rowData) => {
        setCurrentRowData(rowData);
        setAnchorEl([e.currentTarget, rowData]);
    }

    const handleClose = () => {
        setAnchorEl([null, undefined]);
    };
    const getGridNameString = (profileData) => {
        let temp = '';
        temp = profileData?.grid_name ? temp.concat(`${profileData.grid_name}`) : temp.concat(`N/A`);
        temp = profileData?.address?.district ? temp.concat(`-${profileData.address.district}`) : temp.concat(``);
        temp = profileData?.address?.division ? temp.concat(`-${profileData.address.division}`) : temp.concat(``);
        temp = profileData?.iot_alias ? temp.concat(`-${profileData?.iot_alias}`) : temp.concat(``);

        return temp
    }

    useEffect(() => {
        GET_MODELS_LIST();
        GET_CUSTOMERS_LIST({
            grid_guid: props.match.params.grid_guid
        });
        GET_GRIDS_NODE_LIST({
            grid_guid: props.match.params.grid_guid,
            ...backEndFilterParams,
            ...frontEndFilterParams,
            solbox_id: searchText,
        });
        subHeader.setTitle(null);
    }, [GET_MODELS_LIST, GET_CUSTOMERS_LIST, GET_GRIDS_NODE_LIST])


    useEffect(() => {
        subHeader.setBreadCrumbComponent(<Breadcrumb>
            <Breadcrumb.Item href={'/grids/list'} target={'_blank'}>Grids</Breadcrumb.Item>
            <Breadcrumb.Item active>
                {getGridNameString(props.gridInfo) === 'N/A' ? 'Current grid' : getGridNameString(props.gridInfo)}
            </Breadcrumb.Item>
        </Breadcrumb>)


        subHeader.setActionButtons(<>
            <Button variant="warning" size={'sm'} type="submit" style={{
                backgroundColor: '#F18D00 !important',
            }} onClick={() => checkForLatestData()}
                    disabled={filterTriggeredLoading || props.filterTriggeredLoading || props.isLoading}>
                <img src={require('../../../../utils/asset/refresh-live-data.svg')}/>&nbsp;&nbsp;Check Latest Data
            </Button>
        </>)

        return () => {
            subHeader.setActionButtons(null);
            subHeader.setBreadCrumbComponent(null);
        }
    }, [props.gridInfo, filterTriggeredLoading, props.filterTriggeredLoading, props.isLoading])
    /**/

    useEffect(() => {
        if (Object.keys(frontEndFilterParams)?.length === 0 && filter) {
            setFilter(false);
            setTimeout(() => {
                setFilter(true);
            }, 300);
            filterList({
                frontendFilerList: frontEndFilterParams
            })
        }
    }, [frontEndFilterParams]);

    useEffect(() => {
        if (props.modelList && props.modelList.length >= 0) {
            setModels(props.modelList.map((model) => {
                return {
                    ...model,
                    label: model.model_alias,
                    value: model.model_alias,
                }
            }))
        }
    }, [props.modelList]);

    useEffect(() => {
        if (props.customerList && props.customerList.length >= 0) {
            setCustomers(props.customerList.filter(function(customer) {
                return customer.node_type !== "Microutility";
            }).map(function(customer) {
                return {
                    ...customer,
                    label: customer.name,
                    value: customer.customer_guid
                }
            }))
        }
    }, [props.customerList]);

    useEffect(() => {
        if (!props.topicPublished && !props.liveDataFetched) {
            setLoadingSubtext(null);
        } else if (props.topicPublished === requestCycle.pending && !props.liveDataFetched) {
            setLoadingSubtext('Getting live data. Usually takes 10-15 seconds');
            setTimeout(() => {
                setLoadingSubtext('Nearly there. Few more seconds');
            }, 6500)
        } else if (props.topicPublished === requestCycle.success && !props.liveDataFetched) {
            setLoadingSubtext('Trying to Connect to the SOLboxes');
        }
        if (props.liveDataFetched === requestCycle.success) setIsShowingParamsData(false);
    }, [props.liveDataFetched, props.topicPublished])

    const checkForLatestData = () => {
        onReset();
        if (filterFormRef && typeof filterFormRef?.current?.onReset === 'function'){
            filterFormRef.current.onReset();
        }
        liveDataFunction();
    }

    const liveDataFunction = () => {

        let temp = [];
        let temp2 = [];
        let tempGuid = [];
        let live = [];
        let legacy = [];
        let legacyGuid = []
        setNodes(props.nodeList?.map((node) => {
            temp.push(node?.solbox_serial_number.slice(4, 8));
            temp2.push(node.solbox_guid);
            tempGuid.push(node?.solbox_guid);
            if (node && node.solbox_firmware && incompatibleFirmwareVersion(node.solbox_firmware)) {
                legacy.push(node?.solbox_serial_number.slice(4, 8));
                legacyGuid.push(node?.solbox_guid);
            } else if (node && node.solbox_firmware && !incompatibleFirmwareVersion(node.solbox_firmware)) {
                live.push(node?.solbox_serial_number.slice(4, 8))
            }

            return {
                ...node,
                // id: node.solbox_firmware || node.solbox_model || node.solbox_serial_number,
                iToGrid: null,
                battery: null,
                balance: null,
            }
        }) ?? []);

        setBoxArray(temp);
        setLegacyBoxArray(legacy);
        setLiveBoxArray(live);
        setLegacyBoxGuidArray(legacyGuid);
        if (!firstCall && Array.isArray(liveBoxArray) && liveBoxArray.length > 0) {
            PUBLISH_TOPIC(liveBoxArray.map((solbox) => {
                return {
                    solbox_id: solbox,
                }
            }));

            GET_LEGACY_LIVE_DATA({
                solbox_guid_list: Array.isArray(temp2) ? temp2 : []
            });
        } else if (!firstCall && Array.isArray(liveBoxArray) && !liveBoxArray.length > 0) {
            GET_LEGACY_LIVE_DATA({
                solbox_guid_list: Array.isArray(temp2) ? temp2 : [],
                firstCall: true,
            });
        } else {
            GET_LEGACY_LIVE_DATA({
                solbox_guid_list: Array.isArray(temp2) ? temp2 : [],
                firstCall: firstCall,
            });
        }
    }

    /*Function to trigger modal to repalce SOLbox*/
    const showReplacementModal = (data) => {
        handleClose();
        props.history.push(`/replacements/replace/${data.grid_guid}/${data.solbox_serial_number}/${data.customer_guid}/${data.node_guid}`)
    }

    const redirectTOConfiguration = () => {
        handleClose();
        props.history.push(`/solboxes/configuration/${currentRowData?.solbox_guid}/${currentRowData?.solbox_serial_number}`);
    }

    useEffect(() => {
        if (props.nodeList && props.nodeList.length >= 0 && firstCall) {
            liveDataFunction();
            setFirstCall(false);
        } else if (props.nodeList && props.nodeList.length >= 0) {
            liveDataFunction();
        }
    }, [props.nodeList])

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);

            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setProjectedParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    }, [props.history.location.search])

    useEffect(() => {
        return () => {
            SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED();
        }
    }, [SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED])

    const getStatus = (node, params = false) => {
        const live = props.liveOnlineBoxes;
        const legacy = props.legacyOnlineBoxes;
        const legacyBoxesWithData = props.legacyOnlineBoxesWithData;
        const legacyBoxesParamsWithData = props.legacyOnlineBoxesParamsWithData;

        if (params === true) {
            const temp = Array.isArray(legacyBoxesParamsWithData) ? legacyBoxesParamsWithData.find((item) => {
                return item.solbox_guid === node.solbox_guid
            }) : null;

            return temp?.solbox_status === 'online' ? 'online' : 'offline';
        }

        const currentLegacyBox = Array.isArray(legacyBoxesWithData) ? legacyBoxesWithData.find((item) => {
            return item.solbox_guid === node.solbox_guid
        }) : null;


        if (Array.isArray(live) && Array.isArray(legacy)) {
            if (live.indexOf(parseInt(node.solbox_serial_number.slice(4, 8))) > -1) {
                return "online";
            } else if (currentLegacyBox?.solbox_status === 'online') {

                return "online"
            } else {
                return "offline";
            }
        } else {
            return "offline";
        }
    }

    const getLiveDataObjectFromArray = (rowData, params = false) => {
        const id = rowData.solbox_serial_number?.slice(4, 8);
        const guid = rowData?.solbox_guid;
        if (!id && !guid) return null;

        if (params === true) {

            return {
                boxType: 'legacy',
                ...props.legacyOnlineBoxesParamsWithData?.find((item) => {
                    return item.solbox_guid?.toString() === guid?.toString();
                }),
            };
        }

        const liveDatacondition = (item) => {
            return parseInt(item.solbox_id) === parseInt(id);
        }

        const legacyDatacondition = (item) => {
            return item.solbox_guid?.toString() === guid?.toString();
        }

        const legacy = props.legacyOnlineBoxesWithData;
        const live = props.liveOnlineBoxesWithData;


        if (live && Array.isArray(live) && live.find((item) => liveDatacondition(item))) {
            return {
                boxType: 'live',
                ...live.find((item) => liveDatacondition(item)),
            };
        } else if (legacy && Array.isArray(legacy) && legacy.find((item) => legacyDatacondition(item))) {
            return {
                boxType: 'legacy',
                ...legacy.find((item) => legacyDatacondition(item)),
            };
        } else {
            return null
        }
    };

    const getBatteryInfo = (rowData, params = false) => {
        const data = getLiveDataObjectFromArray(rowData, params);
        if (data?.boxType === 'legacy') {
            return isNumber(data?.battery_voltage) ? parseFloat(data.battery_voltage) : null;
        } else if (data?.boxType === 'live') {
            return isNumber(data?.u32VBattery) ? parseFloat(data.u32VBattery) / 1000 : null;
        } else {
            return null;
        }
    }

    const getModeInfo = (rowData, params = false) => {
        const data = getLiveDataObjectFromArray(rowData, params)
        if (data?.boxType === 'legacy') {
            return data?.solbox_mode;
        } else if (data?.boxType === 'live') {

            const mode = {
                0: "Auto",
                1: "Sell",
                2: "Buy",
                3: "Stop",
            };

            return data?.u8UserMode === 0 || data?.u8UserMode ? mode[`${data.u8UserMode}`] : null;
        } else {
            return null;
        }
    };

    const getIToGridInfo = (rowData, params) => {
        const data = getLiveDataObjectFromArray(rowData, params);
        if (data?.boxType === 'legacy') {
            return isNumber(data?.i2Grid) ? data.i2Grid : null;
        } else if (data?.boxType === 'live') {
            return isNumber(data?.i32IToGrid) ? data.i32IToGrid : null;
        } else {
            return null;
        }
    };

    const getBalanceInfo = (rowData, params) => {
        const data = getLiveDataObjectFromArray(rowData, params)

        if (data?.boxType === 'legacy') {
            return isNumber(data?.last_known_balance) ? parseFloat(data.last_known_balance) / 100 : null;
        } else if (data?.boxType === 'live') {
            return isNumber(data?.i32Balance) ? parseFloat(data.i32Balance) / 100 : null;
        } else {
            return null;
        }
    };

    const getLastSeenString = (date) => {

        const timeDiffInMinutes =  moment().diff(moment(date), 'minutes')

        if (!timeDiffInMinutes && !Number.isInteger(timeDiffInMinutes)) return ''
        else if (timeDiffInMinutes<1) return  'Last online just now' ;
        else if ( timeDiffInMinutes < 60) return `Last online ${timeDiffInMinutes} ${timeDiffInMinutes > 1 ? 'minutes' : 'minute'} ago`
        else if ( timeDiffInMinutes >= 60 && timeDiffInMinutes<1440) return `Last online ${Math.floor(timeDiffInMinutes / 60)} ${Math.floor(timeDiffInMinutes / 60) > 1 ? 'hours' : 'hour'} and ${pluralizer(Math.floor(timeDiffInMinutes % 60), 'minute', 'minutes')} ago`
        else if ( timeDiffInMinutes >= 1440) return `Last online ${Math.floor(timeDiffInMinutes / 1440)} ${Math.floor(timeDiffInMinutes / 1440) > 1 ? 'days' : 'day'} and ${pluralizer(Math.floor((timeDiffInMinutes % 1440)/60)  , 'hour', 'hours')} ago`
        else return ''

    };

    const getLastDataReceivedTime = (rowData, params) => {
        const data = getLiveDataObjectFromArray(rowData, params);

        let convertedTimeDifference = getLastSeenString(data?.updated_at)
        let convertedDataReceivedTime = data?.updated_at ? new DateTimeFormat(data.updated_at).localDisplayTime : null
        let solboxTime = data?.last_solbox_time? new DateTimeFormat(data.last_solbox_time).localDisplayTime: 'N/A'

        return convertedDataReceivedTime ? <>
            <SolTypography.SubTitle>{convertedDataReceivedTime}</SolTypography.SubTitle>
            <SolTypography.Text secondary>{convertedTimeDifference}</SolTypography.Text>
            <p><SolTypography.Text secondary><strong>SOLbox time:</strong>&nbsp;{solboxTime}</SolTypography.Text></p>
        </>:null;

    };

    const filterList = ({
                            frontendFilerList, params = false
                        }) => {
        const list = nodes.reduce((filtered, rowData) => {

            if (constructFilteringCondiion({rowData, frontendFilerList})) {
                filtered.push(rowData);
            }
            return filtered;
        }, []);

        if (Object.keys(frontendFilerList)?.length > 0) {
            let temp = {};
            Object.keys(frontendFilerList).forEach((item) => {

                if (frontendFilerList[item] !== undefined && frontendFilerList[item] !== null) {

                    temp = Object.assign(temp, {[`${item}`]: frontendFilerList[item]});
                }
            })
            if (Object.keys(temp)?.length > 0) {
                props.history.push({
                    search: JsonToUriSerialize(temp),
                });
            }
        }

        setTimeout(() => {
            setDataNodes(list);
            setFilterTriggeredLoading(false);
        }, 200)
    }

    /*Constructing tables during latest data fetching*/
    useEffect(() => {

        if (nodes && nodes.length && nodes.length >= 0) {
            setNodes(nodes.map((rowData) => {
                return constructTableData(rowData);
            }));
        }

    }, [props.liveOnlineBoxes, props.legacyOnlineBoxes])

    /*Constructing table when it is params data*/
    useEffect(() => {

        if (nodes && nodes.length && nodes.length >= 0 && !firstCall) {
            setNodes(nodes.map((rowData) => {
                return constructTableData(rowData, true);
            }));
        }

    }, [props.legacyOnlineBoxesParams, props.legacyOnlineBoxesParamsWithData])

    useEffect(() => {
        const boxes = {
            consumer: {
                total: 0,
                offline: 0,
            },
            prosumer: {
                total: 0,
                offline: 0,
            },
            producer: {
                total: 0,
                offline: 0,
            },
            mu: {
                total: 0,
                offline: 0,
            },
        }
        dataNodes.forEach((rowData) => {
            if (rowData.node_type === 'Consumer') {

                boxes.consumer.total += 1
                if (rowData.onlineStatus === 'offline') {
                    boxes.consumer.offline += 1
                }

            } else if (rowData.node_type === 'Prosumer') {

                boxes.prosumer.total += 1
                if (rowData.onlineStatus === 'offline') {
                    boxes.prosumer.offline += 1
                }

            } else if (rowData.node_type === 'Microutility') {
                boxes.mu.total += 1
                if (rowData.onlineStatus === 'offline') {
                    boxes.mu.offline += 1
                }
            } else if (rowData.node_type === 'Producer') {
                boxes.producer.total += 1
                if (rowData.onlineStatus === 'offline') {
                    boxes.producer.offline += 1
                }
            }

        })
        setBoxCount(boxes);
    }, [dataNodes]);

    useEffect(() => {

        if (Object.keys(frontEndFilterParams).length > 0) {
            filterList({
                frontendFilerList: frontEndFilterParams,
                params: !isShowingParamsData,
            });
        } else {
            setDataNodes(nodes)
        }

    }, [nodes]);

    /*Construct DataNodes according to the incoming params or live data*/
    const constructFilteringCondiion = ({rowData, frontendFilerList}) => {

        /*Checking for mode filter*/
        const mode = frontendFilerList?.mode ? rowData.modeRender?.toLowerCase() === frontendFilerList.mode?.toLowerCase() : true;

        /*Checking for iToGrid Filter */
        const iToGrid = frontendFilerList?.iToGrid ?
            frontendFilerList.iToGrid === 'positive' ? parseFloat(rowData.iToGridRender) >= 0 : frontendFilerList.iToGrid === 'negative' ? parseFloat(rowData.iToGridRender) < 0 : true
            : true;

        /*Checking For Status Filtering*/
        const status = frontendFilerList?.status ?
            frontendFilerList.status === rowData.onlineStatus
            : true;

        /*Checking for active solbox filter*/
        const solbox_search = searchText ?
            rowData.solbox_serial_number.includes(searchText)
            : true;

        /*Checking for SOLbox model filter*/
        const model = frontendFilerList?.solbox_model ? rowData.solbox_model === frontendFilerList.solbox_model : true;

        /*Checking for Customer name filter*/
        const customer = frontendFilerList?.customer_guid ? rowData.customer_guid === frontendFilerList.customer_guid : true;

        /*Checking for SOLbox Customer Type Filter*/
        const node_type = frontendFilerList?.node_type ? rowData.node_type === frontendFilerList.node_type : true;

        return mode && iToGrid && status && model && customer && node_type && solbox_search;

    }

    const getName = (data) => {
        if (data.node_type === 'Microutility') {
            return `Microutility-${data.solbox_serial_number.toString().slice(4)}`
        } else {
            return data.name || "N/A"
        }

    }

    const constructTableData = (rowData, params = false) => {
        return {
            ...rowData,
            id: <>
                {rowData.node_type === "Prosumer" ? <CustomerType>PRO</CustomerType> : null}
                {rowData.node_type === "Producer" ? <CustomerType>PRD</CustomerType> : null}
                {rowData.node_type === "Consumer" ? <CustomerType>CO</CustomerType> : null}
                {rowData.node_type === "Microutility" ? <CustomerType>MU</CustomerType> : null}
            </>,
            solboxRender: <>
                <SolTypography.SubTitle primary>
                    <Link to={`/solboxes/profile/${rowData.solbox_serial_number}/${rowData.solbox_guid}`}
                          style={{color: "#F18D00",}} target={`blank`}>
                        {rowData.solbox_serial_number}&nbsp;&nbsp;
                    </Link>
                    <OnlineStatus online={getStatus(rowData, params) === 'online'}/></SolTypography.SubTitle>
                <SolTypography.SubTitle>FW: {rowData?.solbox_firmware || "N/A"}</SolTypography.SubTitle>
                <SolTypography.SubTitle>{rowData?.solbox_model || "N/A"}</SolTypography.SubTitle>
            </>,
            nameRender: <>
                <SolTypography.SubTitle primary>
                    <Link style={{color: "#F18D00",}}
                          target={'_blank'}
                          to={{
                              pathname: `/customers/profile/${rowData.customer_guid}/${rowData.solbox_guid}/${rowData.solbox_serial_number}/${rowData.node_type}`,
                              state: {
                                  solbox_guid: rowData.solbox_guid,
                                  customer_guid: rowData.customer_guid,
                                  solbox_serial_number: rowData.solbox_serial_number,
                                  customer_type: rowData.node_type,
                              },
                          }}
                    >
                        {getName(rowData)}
                    </Link>
                </SolTypography.SubTitle>
                <SolTypography.SubTitle>{(() => {
                    if (rowData.node_type === "Microutility") return rowData.name
                })()}</SolTypography.SubTitle>
                <SolTypography.SubTitle>{(() => {
                    if (rowData.phone?.toString().includes('+880')) return rowData.phone
                    else return `+880${rowData.phone}`
                })()}</SolTypography.SubTitle>
            </>,
            batteryRender: getBatteryInfo(rowData, params),
            modeRender: getModeInfo(rowData, params),
            iToGridRender: getIToGridInfo(rowData, params),
            balanceRender: getBalanceInfo(rowData, params),
            onlineStatus: getStatus(rowData, params),
            lastDataRecievedRender: getLastDataReceivedTime(rowData, params)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (props.topicPublished === requestCycle.success) {
                GET_LIVE_DATA(boxArray);
                SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED();
            } else if (props.topicPublished === requestCycle.failed) {
                SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED();
            }
        }, 10000);

    }, [props.topicPublished]);

    useEffect(() => {
        setFilterTriggeredLoading(true);
        setTimeout(() => setFilterTriggeredLoading(false), 300)
        filterList({
            frontendFilerList: {
                ...frontEndFilterParams,
                searchText: searchText,
            },
        })
    }, [searchText]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const filterToggle = () => {
        setFilter(!filter)
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const exportToCsv = () => {
        const data = dataNodes.map((node, index) => {
            return {
                name: node.name || "N/A",
                phone: node.phone || "N/A",
                solbox_serial_number: node.solbox_serial_number,
                node_type: node.node_type || "N/A",
                model: node.solbox_model || "N/A",
                fw: node.solbox_firmware || "N/A",
                status: node.onlineStatus || "N/A",
                mode: node.modeRender || "N/A",
                balance: node.balanceRender || "N/A",
                iToGrid: node.onlineStatus || "N/A",
                battery: node.batteryRender || "N/A",
                lastDataRecivedOn: node.lastDataRecievedRender || "N/A",
            }
        });

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: ``,
            filename: `Customers List ${new DateTimeFormat(new Date()).localDisplayWithoutTime}`,
            useTextFile: false,
            useBom: true,
            headers: ['Name', 'Phone', 'SOLbox Serial Number', 'Type', 'SOLboxModel', 'Firmware Version', 'Status', "Mode", "Balance", "IToGrid", "Battery", "Last Data Received on"],
        };

        const csvExporter = new ExportToCsv(options);


        csvExporter.generateCsv(data.length > 0 ? data : [{}]);
    }

    const onSearch = (text) => {
        setSearchText(text);
    };

    const processFilterParams = (payload) => {
        const filterParams = {}
        Object.keys(payload).forEach((item) => {
            if (payload[item]) {
                filterParams[item] = payload[item];
            }
        })
        return filterParams;
    }

    const onReset = () => {
        if (Object.keys(backEndFilterParams).length > 0 || Object.keys(frontEndFilterParams).length > 0) {
            props.history.push({
                search: undefined,
            });
            SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED();
            setLoadingSubtext('Getting live data. Usually takes 10-15 seconds');
            setSearchText(null);
            setBackEndFilterParams({});
            setFrontEndFilterParams({});

            setBoxCount({
                consumer: {
                    total: 0,
                    offline: 0,
                },
                prosumer: {
                    total: 0,
                    offline: 0,
                },
                producer: {
                    total: 0,
                    offline: 0,
                },
                mu: {
                    total: 0,
                    offline: 0,
                },
            });

            // GET_GRIDS_NODE_LIST({
            //     grid_guid: props.match.params.grid_guid,
            // });
        }

    }

    /*Trigger Live Data*/
    const liveDataTrigger = () => {
        handleClose();
        setShowModal(true);
    };

    const onFilter = (payload) => {

        setFilterTriggeredLoading(true);
        setBackEndFilterParams(processFilterParams(payload.backEndFilterpayload));
        setFrontEndFilterParams(processFilterParams(payload.frontEndFilterPayload));
        SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED();
        setLoadingSubtext('Getting live data. Usually takes 10-15 seconds');
        filterList({
            frontendFilerList: payload.frontEndFilterPayload,
            params: !isShowingParamsData,
        });
    };

    const backFunction = () => {
        // props.history.push('/grids/list');
        props.history.goBack();
    }


    const renderModal = () => {
        return <>
            <Modal show={showModal} onHide={closeModal} animation={false} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Live Data from <SolTypography.Text
                        primary>{currentRowData?.solbox_serial_number}</SolTypography.Text>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*Modal Body to render the intended component*/}
                    {/*Independent Live Data Component*/}
                    <LiveDataComponent solboxSerialNumber={currentRowData?.solbox_serial_number}
                                       firmware_version={currentRowData?.solbox_firmware}/>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        type="submit"
                        style={{
                            backgroundColor: '#8C8C9B',
                            outline: '#8C8C9B',
                            border: '#8C8C9B',
                        }}
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }


    const getNodesTitle = () => {
        let tempString = ``;
        if (Array.isArray(nodes) && nodes.length && nodes.length > 0) {

            return <>
                {`${pluralizer(dataNodes.length, 'SOLbox', 'SOLboxes')}`} {
                boxCount.consumer.total ?
                    <SolTypography.Text>{`, ${pluralizer(boxCount.consumer.total, 'Consumer', 'Consumers')}`} (<SolTypography.Text
                        primary> {boxCount.consumer.offline} Offline </SolTypography.Text>) </SolTypography.Text> : null
            }{
                boxCount.prosumer.total ?
                    <SolTypography.Text>{`, ${pluralizer(boxCount.prosumer.total, 'Prosumer', 'Prosumers')}`} (<SolTypography.Text
                        primary> {boxCount.prosumer.offline} Offline </SolTypography.Text>) </SolTypography.Text> : null
            }{
                boxCount.mu.total ?
                    <SolTypography.Text>{`, ${pluralizer(boxCount.mu.total, 'MU', 'MUs')}`} (<SolTypography.Text
                        primary> {boxCount.mu.offline} Offline </SolTypography.Text>) </SolTypography.Text> : null
            }{
                boxCount.producer.total ?
                    <SolTypography.Text>{`, ${pluralizer(boxCount.producer.total, 'Producer', 'Producers')}`} (<SolTypography.Text
                        primary> {boxCount.producer.offline} Offline </SolTypography.Text>) </SolTypography.Text> : null
            }

            </>
        } else {
            return "No SOLboxes";
        }
    }


    const ToolBar = <>
        <Toolbar style={{marginTop: "1rem"}}>
            <Toolbar.Title>
                <SolTypography.Title style={{fontWeight: '600'}}>
                    {getNodesTitle()}
                    {/*35 SOLboxs | 20 Consumers ( <SolTypography.Text primary>3 Offline</SolTypography.Text> ) | 7 Prosumers ( <SolTypography.Text primary>2 Offline</SolTypography.Text> ) | 8 MU ( <SolTypography.Text primary>1 Offline</SolTypography.Text> )*/}
                </SolTypography.Title>
            </Toolbar.Title>

            <Toolbar.ToolbarContainer>
                <Toolbar.ToolbarContainer.Items>
                    <IconButton onClick={filterToggle} classes={classes}>
                        <FilterListIcon color="disabled" fontSize="large"/>
                    </IconButton>
                    <IconButton classes={classes} onClick={exportToCsv}
                                disabled={props.filterTriggeredLoading || props.isLoading || props.tableLoading || props.iconLoading}>
                        {props.iconLoading === true ? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :
                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                 alt="Kiwi standing on oval"/>}
                    </IconButton>
                    <SolInput
                        name={'customerSearch'}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                onSearch(event.target.value);
                            }
                        }}
                        autoComplete={'off'}
                        onChange={(event) => {
                            if (event.target.value === '') {
                                onSearch(event.target.value);
                            }
                        }}
                        placeholder={'Type SOLbox ID...'}
                    />
                </Toolbar.ToolbarContainer.Items>
            </Toolbar.ToolbarContainer>
        </Toolbar>
    </>

    const BackButton = <Button size={'sm'} variant={'secondary'} className={'btn-outline-warning'}
                               onClick={backFunction}>
        <i className="fa fa-chevron-left"/> &nbsp;Go Back</Button>

    const redirectToCreateTransaction = (data) => {
        if (data !== undefined && data !== null && props.gridInfo) {
            props.history.push(`/transactions/create-for/${data.solbox_serial_number}/${data.name}/${props.gridInfo?.grid_name}/${data.node_type}`);
        }
    }

    const noDataAvailable = () => {

        return <>

            {props.isLoading ?
                <div className={'d-flex justify-content-center'}><Spinner animation="border" variant="primary"/></div> :
                <div className={'d-flex justify-content-center'}><h1><Badge variant="secondary">No Data
                    Available</Badge></h1></div>}
        </>
    }


    return (
        <ContentWrapper showCardHeader={false} pageTitle={BackButton} showBackButton={false} isLoading={props.isLoading}
                        loadingSubText={loadingSubtext}>
            {props.gridInfo ? <GridProfile profileData={props.gridInfo}/> : noDataAvailable()}

            <SolTypography.Title
                style={{fontWeight: '600', marginTop: "1rem", fontSize: '1.80rem'}}>Nodes</SolTypography.Title>
            {ToolBar}

            <div className={'filter-animation'} style={{
                display: filter ? undefined : 'none',
            }}>
            <FilterForm ref={filterFormRef} models={models} modelSelectLoading={props.modelSelectLoading}
                        customers={customers} customerSelectLoading={props.customerSelectLoading}
                        onFilter={onFilter} onReset={onReset} projectedParams={projectedParams}/>
            </div>

            <DataTableContainer>
                {props.filterTriggeredLoading !== true && filterTriggeredLoading !== true ? <DataTable
                    columns={customerListColumns}
                    // data={[{id: 'asdas',name:'asd', mode: "Auto",battery: "10.5",iToGrid:'300.12', balance: '230.36'},{id: 'asdas',name:'asd', mode: "Auto",battery: "10.5",iToGrid:'300.12', balance: '230.36'}]}
                    data={dataNodes}
                    showToolbar={false}
                    asyncPagination={true}
                    isLoading={props.tableLoading}
                    count={props.gridList?.count}
                    // itemsPerPage={props.gridList?.page_size}
                    onChangePage={onChangePage}
                    page={page}
                    pageSize={30}
                    actions={[
                        {
                            icon: () => <ActionMenu/>,
                            tooltip: 'Save User',
                            onClick: (event, rowData) => moreIconClick(event, rowData)
                        }
                    ]}
                /> : <LoadingSpinner loadingSubText={loadingSubtext}/>}
            </DataTableContainer>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active {
                border: 1px solid #F18D00;
                box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                outline: none;
              }

              .list-filter-gap {
                margin-bottom: 1.4rem;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }
            `}</style>
            <ToastContainer/>

            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted={false}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                variant={'menu'}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => liveDataTrigger()}>See Live Data</MenuItem>
                <MenuItem onClick={() => redirectTOConfiguration()}>Configuration</MenuItem>
                {props.isSolshare?<><MenuItem onClick={() => redirectToCreateTransaction(currentRowData)}>Create Transaction</MenuItem>
                    <MenuItem onClick={() => showReplacementModal(currentRowData)}>Replace</MenuItem></>:null}
            </Menu>
            {renderModal()}
        </ContentWrapper>
    );
};


const mapStateToProps = (state) => {
    return {
        customerSelectLoading: state.gridsReducer.customerSelectLoading,
        modelSelectLoading: state.gridsReducer.modelSelectLoading,
        isLoading: state.gridsReducer.isLoading,
        filterTriggeredLoading: state.gridsReducer.filterTriggeredLoading,
        customerList: state.gridsReducer.customerList,
        modelList: state.gridsReducer.modelList,
        nodeList: state.gridsReducer.nodeList,
        tableLoading: state.gridsReducer.tableLoading,
        liveDataFetched: state.gridsReducer.liveDataFetched,
        topicPublished: state.gridsReducer.topicPublished,
        liveOnlineBoxes: state.gridsReducer.liveOnlineBoxes,
        legacyOnlineBoxes: state.gridsReducer.legacyOnlineBoxes,
        liveOnlineBoxesWithData: state.gridsReducer.liveOnlineBoxesWithData,
        legacyOnlineBoxesWithData: state.gridsReducer.legacyOnlineBoxesWithData,
        legacyOnlineBoxesParams: state.gridsReducer.legacyOnlineBoxesParams,
        legacyOnlineBoxesParamsWithData: state.gridsReducer.legacyOnlineBoxesParamsWithData,
        gridInfo: state.gridsReducer.gridInfo,
    }
}

export default connect(mapStateToProps, actions)(withRoles(GridDetails));
