import styled from "styled-components";

export const Toolbar = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
`;


export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`

Toolbar.ToolbarContainer = styled.div`
  grid-column: 10/-1;
`;
Toolbar.Title = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1/9 ;
`
Toolbar.ToolbarContainer.Items = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
`;

export const Filter = styled.div`
  margin: 35px 0 0 0;
  padding: 0;
`;
