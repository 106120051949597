import {all} from "redux-saga/effects";
import {combineReducers} from "redux";
import * as auth from "../features/auth/reducer";
import { dashboard } from "../pages/DashboardPage";
import { reducer as refreshReducer } from 'redux-refresh-token';
import { commonReducer } from "../features/commonFeatureModules/commonReducer";
import { customerReducer } from "../features/customer/customerReducer";
import { gridsReducer } from "../features/grids/reducer";
import { replacementReducer } from "../features/replacement/replacementReducer";
import { smartboardReducer } from "../features/smartBoard/SmartboardReducer";
import { solboxReducer } from "../features/solbox/solboxReducer";
import { tradingReducer } from "../features/trading/tradingReducer";
import { transactionReducer } from "../features/transaction/transactionReducer";
import { otaUpdatesReducer } from "../features/otaUpdates/reducer";

export const rootReducer = combineReducers({
  tokenRefresh: refreshReducer,
  auth: auth.reducer,
  dashboard: dashboard,
  commonReducer: commonReducer,
  customerReducer: customerReducer,
  gridsReducer: gridsReducer,
  replacementReducer: replacementReducer,
  smartboardReducer: smartboardReducer,
  solboxReducer: solboxReducer,
  tradingReducer: tradingReducer,
  transactionReducer: transactionReducer,
  otaUpdatesReducer: otaUpdatesReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
