import { transactionActionTypes } from "./transactionActionTypes";
import moment from "moment";
import {toast} from "react-toastify";

const initialState = {
  isLoading: false,
  isRetryingAllFailedTransaction: false,
  isCancellingTransaction: false,
  allFailedTransactionIsSuccessfullyRetried: false,
  transactionIsCancelled: false,
  transactionHistory: null,
  transactionWorth: 0,
  orphanTransactions: null,
  gridList: null,
  transactionTypes: null,
  gridBenefits: null,
  customerList: null,
};

export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case transactionActionTypes.FETCH_GRID_LIST_REQUEST:
      return {
        ...state,
        isGridListLoading: true,
      };

    case transactionActionTypes.FETCH_GRID_LIST_SUCCESS:
      return {
        ...state,
        isGridListLoading: false,
        gridList: Array.isArray(action.payload) ? action.payload : [],
      };

    case transactionActionTypes.FETCH_GRID_LIST_FAILURE:
      return {
        ...state,
        isGridListLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.FETCH_TRANSACTION_TYPES_REQUEST:
      return {
        ...state,
        isTransactionTypesLoading: true,
      };

    case transactionActionTypes.FETCH_TRANSACTION_TYPES_SUCCESS:
      return {
        ...state,
        isTransactionTypesLoading: false,
        transactionTypes: Array.isArray(action.payload) ? action.payload : [],
      };

    case transactionActionTypes.FETCH_TRANSACTION_TYPES_FAILURE:
      return {
        ...state,
        isTransactionTypesLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.FETCH_GRID_BENEFITS_LIST_REQUEST:
      return {
        ...state,
        isGridBenefitsLoading: true,
      };

    case transactionActionTypes.FETCH_GRID_BENEFITS_LIST_SUCCESS:
      return {
        ...state,
        isGridBenefitsLoading: false,
        gridBenefits: Array.isArray(action.payload) ? action.payload : [],
      };

    case transactionActionTypes.FETCH_GRID_BENEFITS_LIST_FAILURE:
      return {
        ...state,
        isGridBenefitsLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.FETCH_ALL_CUSTOMER_REQUEST:
      return {
        ...state,
        isCustomerListLoading: true,
      };

    case transactionActionTypes.FETCH_ALL_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCustomerListLoading: false,
        customerList: Array.isArray(action.payload) ? action.payload : [],
      };

    case transactionActionTypes.FETCH_ALL_CUSTOMER_FAILURE:
      return {
        ...state,
        isCustomerListLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_REQUEST:
      return {
        ...state,
        isTransactionHistoryLoading: true,
        transactionHistoryTableLoading: true,
        //transactionHistory: null
      };

    case transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        isTransactionHistoryLoading: false,
        transactionHistoryTableLoading: false,
        pageCount: action.payload.count,
        pageSize: action.payload.page_size,
        transactionWorth: action.payload.total_worth,
        transactionHistory: Array.isArray(action.payload.results)
          ? action.payload.results
          : [],
      };

    case transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_FAILURE:
      return {
        ...state,
        isTransactionHistoryLoading: false,
        transactionHistoryTableLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_REQUEST:
      return {
        ...state,
        csvDataLoading: true
        //transactionHistory: null
      };

    case transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_SUCCESS:
      return {
        ...state,
        csvDataLoading: false,
        transactionHistoryData: Array.isArray(action.payload)
          ? action.payload
          : [],
      };

    case transactionActionTypes.GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION_FAILURE:
      return {
        ...state,
        csvDataLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.CLEAN_UP_GET_TRANSACTION_HISTORY_LIST_EXCEPT_PAGINATION:
      return {
        ...state,
        transactionHistoryData: []
      };

    case transactionActionTypes.GET_ORPHAN_TRANSACTION_LIST_REQUEST:
      return {
        ...state,
        isOrphanTransactionLoading: true,
        orphanTransactiontableLoading: true,
        //orphanTransactions: null
      };

    case transactionActionTypes.GET_ORPHAN_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        isOrphanTransactionLoading: false,
        orphanTransactiontableLoading: false,
        page_count: action.payload.count,
        page_size: action.payload.page_size,
        orphanTxnWorth: action.payload.total_worth,
        orphanTransactions: Array.isArray(action.payload.results)
          ? action.payload.results
          : [],
      };

    case transactionActionTypes.GET_ORPHAN_TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        isOrphanTransactionLoading: false,
        orphanTransactiontableLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.VALIDATE_FAILED_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        statusCode: null,
        failureMessage: null
      };

    case transactionActionTypes.VALIDATE_FAILED_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        validatedTransaction: action.payload,
      };

    case transactionActionTypes.VALIDATE_FAILED_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        statusCode: action.payload.status,
        failureMessage: action.payload.message
      };

    case transactionActionTypes.GET_HIGHEST_BALANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case transactionActionTypes.GET_HIGHEST_BALANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        highestBalance: action.payload,
      };

    case transactionActionTypes.GET_HIGHEST_BALANCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.GET_LAST_KNOWN_BALANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
  
    case transactionActionTypes.GET_LAST_KNOWN_BALANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        refreshTimer: moment(new Date()),
        lastBalance: action.payload,
      };
  
    case transactionActionTypes.GET_LAST_KNOWN_BALANCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        statusCode: action.payload.status,
      };

    case transactionActionTypes.CREATE_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        createTransaction: null,
        offlineTransactionId: null,
        statusCode: null
      };

    case transactionActionTypes.CREATE_TRANSACTION_SUCCESS:
      toast.success("Transaction has been created.");
      return {
        ...state,
        isLoading: false,
        createTransaction: action.payload.status,
        offlineTransactionId: action.payload
      };

    case transactionActionTypes.CREATE_TRANSACTION_FAILURE:
      toast.error("Transaction creation failed!");
      return {
        ...state,
        isLoading: false,
        statusCode: action.payload.status
      };

    case transactionActionTypes.CREATE_TRANSACTION_INITIATE:
      return {
        ...state,
        isLoading: false,
        createTransaction: null,
      };

    case transactionActionTypes.RETRY_ALL_FAILED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isRetryingAllFailedTransaction: true
      };

    case transactionActionTypes.RETRY_ALL_FAILED_TRANSACTIONS_SUCCESS:
      toast.success("All failed transactions have been retried!");
      return {
        ...state,
        isRetryingAllFailedTransaction: false,
        allFailedTransactionIsSuccessfullyRetried: true
      };

    case transactionActionTypes.RETRY_ALL_FAILED_TRANSACTIONS_FAILURE:
      toast.error("Can't perform this action! Please try again later.");
      return {
        ...state,
        isRetryingAllFailedTransaction: false
      };

    case transactionActionTypes.CANCEL_TRANSACTION_REQUEST:
      return {
        ...state,
        isCancellingTransaction: true
      };

    case transactionActionTypes.CANCEL_TRANSACTION_SUCCESS:
      toast.success("Transaction is cancelled!");
      return {
        ...state,
        isCancellingTransaction: false,
        transactionIsCancelled: true
      };

    case transactionActionTypes.CANCEL_TRANSACTION_FAILURE:
      toast.error("Can't cancel this transaction. Please try again later!");
      return {
        ...state,
        isCancellingTransaction: false
      };

    case transactionActionTypes.GET_SOLBOX_FIRMWARE_VERSION_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case transactionActionTypes.GET_SOLBOX_FIRMWARE_VERSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        solboxData: action.payload
      };

    case transactionActionTypes.GET_SOLBOX_FIRMWARE_VERSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        statusCode: action.payload.status
      };

    case transactionActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case transactionActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        solChartTxnBalanceData: action.payload ? Array.isArray(action.payload.data) ? action.payload.data : [] : []
      };

    case transactionActionTypes.FETCH_SOLBOX_BALANCE_CHART_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        statusCode: action.payload.status
      };

    case transactionActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case transactionActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        solChartTxnI2GridData: action.payload ? Array.isArray(action.payload.data) ? action.payload.data : [] : []
      };

    case transactionActionTypes.FETCH_SOLBOX_I2GRID_CHART_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        statusCode: action.payload.status
      };

    default:
      return { ...state };
  }
};
