import React, { useState, useEffect } from "react";
import { transactionActions }   from "../transactionActions";
import { connect } from 'react-redux';
import SolTextInput from "../../../components/soltextinput/SolTextInput";
import SolDaterangePicker from "../../../components/SolDaterangePicker";
import moment from "moment";
import { ToastContainer } from 'react-toastify';
import Message from "../../../utils/toastify/notification";
import { toast } from "react-toastify";

const UnassignedTransactionFilter = (props) => {

    const { 
        history, 
        sentFrom,
        mobPayRef,
        mobTransRef,
        handleChangeSolTextInput,
        startDate,
        endDate,
        handleDateRange,
        dateRange,
        handleSelectedDateRange,
        resestFilter,
        filterApplied, 
        toggleFilterApplied, 
        handleSetFilterParams,
        GET_ORPHAN_TRANSACTION_LIST 
    } = props;

    useEffect(() => {
        if (startDate && endDate) {
            handleSelectedDateRange(
                            moment(startDate._d).format('LL')
                            .concat(" - ", moment(endDate._d).format('LL'))
                        );
        }
    }, [startDate, endDate]);

    const applyFilter = (event) => {
        const regex = /^[\+a-z0-9_\- ]+$/gi;
        let formData = {};
        if (sentFrom && sentFrom.trim() && regex.test(sentFrom.trim())) {
            Object.assign(formData, {"sent_from": sentFrom.trim()});
        }
        if (mobPayRef && mobPayRef.trim() && regex.test(mobPayRef.trim())) {
            Object.assign(formData, {"payment_ref": mobPayRef.trim()});
        }
        if (mobTransRef && mobTransRef.trim() && regex.test(mobTransRef.trim())) {
            Object.assign(formData, {"tranaction_reference": mobTransRef.trim()});
        }
        if (startDate && 
            startDate._d &&
            endDate && 
            endDate._d
        ) {
            Object.assign(formData, {"start_date": moment(startDate._d).format("YYYY-MM-DD")});
            Object.assign(formData, {"end_date": moment(endDate._d).format("YYYY-MM-DD")});
        }
        if (!(Object.entries(formData).length < 1)) {
            toggleFilterApplied(event, true);
            handleSetFilterParams(formData);
            GET_ORPHAN_TRANSACTION_LIST(formData);
            history.push({
                pathname: '/transactions/list',
                search: filterParamsHandler(formData),
                hash: "orphan_transaction"
            });
        } else {
            // const message = new Message();
            // message.ErrorMessage("Please provide at least one filter criterion!");
            toast.error("Please provide at least one filter criterion!");
        }
    }

    const filterParamsHandler = (filterParams) => {
        let queryStringParams = "";
        if (!(Object.entries(filterParams).length < 1)) {
            for (const [key, value] of Object.entries(filterParams)) {
                if (!queryStringParams.includes("?")) {
                    queryStringParams += `?${key}=${value}`;
                } else {
                    queryStringParams += `&${key}=${value}`;
                }
            }
        }
        return queryStringParams;
    }

    return (
        <React.Fragment>
            <h5 className={`list-filter-control`}>{`FILTER`}</h5>
            <div className={`row list-filter-control`}>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Sent From`}
                    </label>
                    <div className="form-group d-block">
                        <SolTextInput 
                            inputClassName={`custom-text-input-style`}
                            name={`sentPhoneNumber`}
                            placeHolder="Type Phone Number..."
                            value={sentFrom}
                            handleChange={(event) => handleChangeSolTextInput(event)}
                        />
                    </div>
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Mobile Payment Ref.`}
                    </label>
                    <div className="form-group d-block">
                        <SolTextInput 
                                inputClassName={`custom-text-input-style`}
                                name={`mobilePaymentRef`}
                                placeHolder="Type Mobile Payment Ref..."
                                value={mobPayRef}
                                handleChange={(event) => handleChangeSolTextInput(event)}
                        />
                    </div>
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Mobile Transaction Ref.`}
                    </label>
                    <div className="form-group d-block">
                        <SolTextInput 
                            inputClassName={`custom-text-input-style`}
                            name={`mobileTransactionRef`}
                            placeHolder="Trx Reference..."
                            value={mobTransRef}
                            handleChange={(event) => handleChangeSolTextInput(event)}
                        />
                    </div>
                </div>
                <div className={`col-sm-3 mb-2`}>
                    <SolDaterangePicker
                        dateRangelabel={`Transaction Date`}
                        customLabel={`normal-text`}
                        startDate={startDate}
                        endDate={endDate}
                        dateRange={dateRange}
                        handleDateRange={handleDateRange}
                    />
                </div>
            </div>
            {/* <ToastContainer /> */}
            <div className={`row mb-5`}>
                <div className={`col-12`}>
                    <button
                        type={`button`}
                        className={'btn btn-outline-warning btn-sm mb-5'}
                        onClick={(event) => applyFilter(event)}
                    >
                        {`Apply Filter`}
                    </button>
                    <button
                        type={`button`}
                        className={'btn btn-outline-secondary btn-sm ml-4 mb-5 list-filter-reset-button'}
                        onClick={(event) => resestFilter(event)}
                    >
                        {`Reset Filter`}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.transactionReducer.isLoading,
        tableLoading: state.transactionReducer.tableLoading,
        statusCode: state.transactionReducer.statusCode,
        orphanTransactions: state.transactionReducer.orphanTransactions
    }
};

export default connect(mapStateToProps, transactionActions)(UnassignedTransactionFilter);
