import {actionTypes} from "./actionTypes"
import {CALL_API} from "../../utils/actionTypes"
export const actions = {
    /*verfify phone number with backend*/
    VERIFY_PHONE: (phone, firebase, recap) => ({ type: actionTypes.VERIFY_PHONE, payload: { phone, firebase, recap } }),
    /*Action to call firebase funtion to check the user and send OTP if authenticated*/
    SEND_OTP: (phone, firebase , recap) => ({ type: actionTypes.SEND_OTP, payload: { phone, firebase, recap } }),
    SET_CONFIRMATION: (confirmation) => ({ type: actionTypes.SET_CONFIRMATION, payload: { confirmation } }),
    REGISTER: authToken => ({ type: actionTypes.REGISTER, payload: { authToken } }),
    LOGOUT: () => ({ type: actionTypes.LOGOUT }),
    POPUP_SIGNIN: (firebase, recap) => ({ type: actionTypes.POPUP_SIGNIN, payload: {firebase, recap} }),
    SET_TOKEN: (user, access_token, refresh_token) => ({ type: actionTypes.SET_TOKEN, payload: { user, access_token, refresh_token } }),
    /*Action to authenticate and confirm the OTP provided by the user and set the user to the current */
    CONFIRM_OTP: (code, confirmation)  => ({ type: actionTypes.CONFIRM_OTP, payload: { code, confirmation}}),
    VERIFY_AUTH: ({ type: actionTypes.VERIFY_AUTH }),
    /*Action to Refresh the auth token*/
    REFRESH_AUTH_TOKEN: ({ type: actionTypes.REFRESH_AUTH_TOKEN }),
    /*Set the is_error flag to true and sends a messahge*/
    AUTH_ERROR: (msg) => ({ type: actionTypes.AUTH_ERROR, payload: { msg } }),
    /*Action to authenticate google user and check if it is of "@me-solshare.com" domain*/
    CHECK_GOOGLE_USER: (user, token, refresh_token)=> ({type: actionTypes.CHECK_GOOGLE_USER, payload: {user, token, refresh_token}}),
    /*Action to call the api to refresh the current access token.*/
    REFRESH_TOKEN: () =>  ({
        [CALL_API]: {
            endpoint: '/refresh',
            method: 'POST',
            types: [
                actionTypes.TOKEN_REFRESH_REQUEST,
                actionTypes.TOKEN_REFRESH_SUCCESS,
                actionTypes.TOKEN_REFRESH_FAILURE
            ]
        }
    }),
    /*Action to call api to log user out*/
    ATTEMPT_LOGOUT: () => ({
        [CALL_API]: {
            endpoint: '/logout',
            method: 'POST',
            types: []
        }
    }),
    /*Action to fetch all solboxes*/
    FETCH_SOLBOX: () =>  ({
        [CALL_API]: {
            endpoint: '/solboxes',
            method: 'GET',
            types: [
                actionTypes.SOLBOX_REQUEST,
                actionTypes.SOLBOX_SUCCESS,
                actionTypes.SOLBOX_FAILURE
            ]
        }
    }),
    SET_ROLE: (payload)=> ({
        type: "SET_ROLE",
        payload: payload,
    }),
    START_LOADING_STATE: ()=> ({type: actionTypes.START_LOADING_STATE}),
    START_EMAIL_LOADING_STATE: ()=> ({type: actionTypes.START_EMAIL_LOADING_STATE}),
    DISABLE_LOADING: ()=> ({type: actionTypes.DISABLE_LOADING}),
    DISABLE_LOADING_IN_OTP: ()=> ({type: actionTypes.DISABLE_LOADING_IN_OTP}),
    CLEAR_CONFIRMATION_OBJECT: ()=> ({type: actionTypes.CLEAR_CONFIRMATION_OBJECT}),
};