import React from "react";
import Select from 'react-select';
import PropTypes from 'prop-types';

const SolSelect = ({
    placeHolder,
    value,
    handleChangeSolSelect,
    options,
    selectMulti,
    loading,
    clearable,
    disabled,
    noOptionsMessage
}) => {

    const customStyles = {
        control: (provided, state) =>
            state.isFocused ?
                ({
                    border: '1px solid #F18D00',
                    boxShadow: '0 0 3px 1px #F18D00',
                    borderRadius: '3px',
                    display: 'flex',
                    width: '100%'
                })
                :
                ({
                    border: '1px solid rgb(204, 204, 204)',
                    borderRadius: '3px',
                    display: 'flex',
                    width: '100%'
                })
    }

    return (
        <React.Fragment>
            <Select
                styles={customStyles}
                placeholder={placeHolder}
                value={value}
                onChange={handleChangeSolSelect}
                options={options}
                isMulti={selectMulti}
                isLoading={loading}
                isClearable={clearable}
                isDisabled={disabled !== undefined && disabled !== null ? disabled : false}
                noOptionsMessage={noOptionsMessage}
            />
        </React.Fragment>
    );
}

SolSelect.propTypes = {
    placeHolder: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    handleChangeSolSelect: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    selectMulti: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    clearable: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    noOptionsMessage: PropTypes.func.isRequired
};

export default SolSelect;