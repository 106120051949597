import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {replacementActions as actions} from "../../replacementActions";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Filter, Toolbar} from "../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {Button, Spinner} from "react-bootstrap";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {ActionMenu, SolInput} from "../../../../components/SolStyledComponents/components";
import {useStylesIconButton} from "../components";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import DataTable from "../../../../components/dataTable/DataTable";
import {dummyData, replacementListColumns} from "./replacementColumns";
import {DataTableContainer, LoadingSpinner, requestCycle} from "../../../grids/utils";
import DetailsPanel from "./DetailsPanel";
import FilterForm from "./FilterForm";
import {replace} from "formik";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {ExportToCsv} from "export-to-csv";
import {DangerStatusRender,PendingRenderStatus,SuccessRenderStatus} from '../../../../components/legendComponents/legendComponents';
import {getStatus, getStatusInString, ReplacementLegends} from "../../../../utils/replacementutils/utilityFunctions";
import {JsonToUriSerialize, parseParams, processFilterParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import moment from "moment";


const ReplacementList = props => {
    const classes = useStylesIconButton();
    const [filter, setFilter] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [gridList, setGridList] = useState([]);
    const [nodeTypes, setNodeTypes] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [replacementList, setReplacementList] = useState([]);
    const [page, setPage] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [retryRow, setRetryRow] = useState(null);
    const [projectedParams, setProjectedParams] = useState(null);
    const [loadingSubText, setLoadingSubtext] = useState(null);

    const resetFilter=()=>{

        if (Object.keys(filterData).length>0) {
            setPage(null)
            if ( props.isSolshare === false && props.b2bOrgGuid ) {
                setProjectedParams({
                    grid_organization_guid: props.b2bOrgGuid,
                });
            } else {
                setProjectedParams(null);
            }
            setSearchText(null)
            setFilterData({
                pagination: "True"
            });
            props.history.push({
                search: undefined,
            });
            props.GET_REPLACEMENT_LIST({pagination: "True"});
        }
    };

    useEffect(()=>{
        props.GET_GRID_OWNERS_LIST();
        props.GET_MODELS_LIST();
        props.GET_GRIDS_WITHOUT_WITHOUT_PAGINATION();
        // [Very minor] Following request will get first page data without "page: 1" key-value pair
        // [Very minor] Yet still I have added it for better understanding
        props.GET_REPLACEMENT_LIST({...filterData,  pagination: "True", page: 1});
        props.GET_NODE_TYPE_REQUEST();
    },[]);

    useEffect(()=>{
        if ( props.isSolshare === false && props.b2bOrgGuid ) {
            setProjectedParams({
                ...(projectedParams),
                grid_organization_guid: props.b2bOrgGuid,
            })
        }
    },[props.b2bOrgGuid,props.isSolshare])

    useEffect(()=>{
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);

            if (Object.keys(temp)?.length >0) {
                /* Set the projected filter lists. Requires validation */
                if ( props.isSolshare === false && props.b2bOrgGuid ){
                    delete temp.grid_organization_guid;
                    Object.assign(temp, {grid_organization_guid: props.b2bOrgGuid })
                }
                setProjectedParams(temp);
            }
        }
        catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    const replacementListAndFilerFormLoadActivity = (newPage) => {
        if (filterData)
        {
            if (searchText) {
                props.GET_REPLACEMENT_LIST({...filterData, solbox_serial_number:searchText, pagination: "True", page: newPage})
            } else {
                props.GET_REPLACEMENT_LIST({...filterData, pagination: "True", page: newPage})
            }
        } else {
            if (searchText) {
                props.GET_GRID_LIST({page: newPage, name: searchText});
                props.GET_REPLACEMENT_LIST({ solbox_serial_number:searchText, pagination: "True", page: newPage})
            } else {
                props.GET_GRID_LIST({page: newPage});
                props.GET_REPLACEMENT_LIST({ pagination: "True", page: newPage})
            }
        }
        props.SET_REPLACEMENT_PHASES_TO_UNDEFINED()
    }

    useEffect(()=>{
        if (props.retrySuccess === requestCycle.success || props.reimbursementSuccessful || props.configUpdateSuccessful) {
            replacementListAndFilerFormLoadActivity()
        }


    }, [props.retrySuccess, props.reimbursementSuccessful, props.configUpdateSuccessful]);


    const transferBalance = (data) => {
        let payloadForBalanceTransfer = {
            'old_solbox_serial_number': data.old_solbox_serial_number,
            'new_solbox_serial_number': data.new_solbox_serial_number,
            'old_solbox_node_guid': data.node_guid,
            'user_guid': props.user_guid,
            'replacement_id': data.replacement_id,
            'remarks': data.remarks
        }
        props.SEND_REIMBURESEMENT_FOR_REPLACEMENT(payloadForBalanceTransfer)
    }

    const transferConfig = (data) => {
        let payloadForConfigTransfer = {
            'old_solbox_serial_number': data.old_solbox_serial_number,
            'new_solbox_serial_number': data.new_solbox_serial_number,
            'customer_guid': data.customer_guid,
            'grid_guid': data.grid_guid,
            'user_guid': props.user_guid,
            'replacement_id': data.replacement_id,
            'remarks': data.remarks
        }
        props.SEND_CONFIG_FOR_REPLACEMENT(payloadForConfigTransfer)
    }

    const retry = (rowData) => {
        setRetryRow(rowData);
        let payloadForRetry = {
            'old_solbox_serial_number': rowData.old_solbox_serial_number,
            'new_solbox_serial_number': rowData.new_solbox_serial_number,
            'customer_guid': rowData.customer_guid,
            'grid_guid': rowData.grid_guid,
            'replacement_id': rowData.replacement_id
        }

        // if (rowData.config_transfer_status === 'not_init' || (rowData.config_transfer_status === 'failed' && rowData.balance_transfer_status === 'not_init')) {
        //     transferConfig(rowData)
        // } else {
        //     if (rowData.config_transfer_status === 'success' && rowData.balance_transfer_status === 'not_init') {
        //         transferBalance(rowData)
        //     } else {
        //         props.REPLACEMENT_RETRY(payloadForRetry);
        //     }
        // }



        if (rowData.config_transfer_status === 'not_init') {
            transferConfig(rowData);

        }
        if (rowData.balance_transfer_status === 'not_init'){
            setTimeout(()=>{
                transferBalance(rowData);
            },3000)
        }

        if (rowData.balance_transfer_status==='failed' || rowData.config_transfer_status==='failed'){
            props.REPLACEMENT_RETRY(payloadForRetry);
        }
    }

    /*Mapping For Dropdown */
    useEffect(()=>{
        const organisations= props.gridOwnerList;
        const models= props.modelList;
        const grids= props.gridList;
        const nodeTypes= props.nodeTypeList;


        if (organisations && organisations.length>=0)
        {
            setOrgList(organisations.map((organisation)=>{
                return {
                    label: organisation.organization_name,
                    value: organisation.organization_guid,
                }
            }));
        }


        if (models && models.length>=0)
        {
            setModelList(models.map((model)=>{
                return {
                    value: model.model_guid,
                    label: model.model_alias,
                }
            }));
        }


        if (nodeTypes && nodeTypes.length>=0)
        {
            setNodeTypes(nodeTypes.reduce((filtered, nodeType)=>{
                if (nodeType.name!=='Wifi Tower'){
                    filtered.push({
                        ...nodeType,
                        label: nodeType.name,
                        value: nodeType.id,
                    })
                }
                return filtered;

            },[]))
        }

        if (grids && grids.length>=0)
        {
            setGridList(grids.map((grid)=>{
                return {
                    label: grid.name,
                    value: grid.grid_guid,
                }
            }))
        }

    }, [props.gridOwnerList, props.modelList, props.gridList, props.nodeTypeList])

    /*Map Replacement List*/
    useEffect(()=>{

        const replacements = props.replacementList;

        if (replacements && replacements.results && replacements.results.length >=0)
        {
            setReplacementList(
                replacements.results.map((replacement)=>{
                    return {
                        ...replacement,
                        replacement_id: replacement.replacement_id,
                        replacementDate: new DateTimeFormat(replacement.created_at).localDisplayTime ,
                        status: getStatus(replacement),
                        replaced_by: replacement.replaced_by,
                        old_solbox_serial_number: replacement.old_solbox_serial_number,
                        new_solbox_serial_number: replacement.new_solbox_serial_number,
                        retry: getStatusInString(replacement).retry
                    }
                })
            )
        }

    },[props.replacementList]);

    const onFilter=(data, changeURL=true)=>{
        const temp = processFilterParams(data);

        if (changeURL) {
            props.history.push({
                search: JsonToUriSerialize(temp),
            });
        }
        setFilterData(temp);

        if (Object.keys(temp)?.length>0 ){

            props.GET_REPLACEMENT_LIST({
                ...temp,
                pagination: "True",
            });
        }

    }

    const onChangePage=(event, newPage)=>{
        // Saving newPage which will be used at 'replacementListAndFilerFormLoadActivity' function later
        setPage(newPage)
        replacementListAndFilerFormLoadActivity(newPage)
    }

    const onSearch=(text)=>{
        if (text==='' && !searchText)
        {
            return;
        }
        setPage(null);
        if (filterData)
        {
            props.GET_REPLACEMENT_LIST({...filterData, solbox_serial_number:text, pagination: "True",})
        }else {
            props.GET_REPLACEMENT_LIST({solbox_serial_number:text, pagination: "True",})
        }
        setSearchText(text)
    }

    const initiateReplacementProcess = () => {
        if (filterData && searchText) {
            props.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION({...filterData, solbox_serial_number:searchText, pagination: "False"})
        } else if (filterData && !searchText) {
            props.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION({...filterData, pagination: "False"})
        } else if (!filterData && searchText) {
            props.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION({solbox_serial_number:searchText, pagination: "False"})
        } else {
            props.GET_REPLACEMENT_LIST_WITHOUT_PAGINATION({pagination: "False"})
        }
    }

    /*---Mapping data for exporting replacement list---*/
    useEffect(()=>{
        if (props.replacementListForExport)
        {
            exportToCsv(props.replacementListForExport)
        }

    },[props.replacementListForExport]);

    const exportToCsv=(listForExport)=>{
        const data = listForExport.map((replacement, index)=>{
            return {
                id: replacement.replacement_id,
                replacementDate: replacement.replacementDate || "N/A",
                customer_name: replacement.customer_name || "N/A",
                customer_phone: replacement.customer_phone || "N/A",
                status: getStatusInString(replacement).message,
                done_by: replacement.replaced_by || 'N/A',
                old_solbox: replacement.old_solbox_serial_number || "N/A" ,
                new_solbox: replacement.new_solbox_serial_number || "N/A",
                remarks: replacement.remarks || "N/A",
            }
        });

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: `` ,
            filename: `Replacement_history_${moment().format('Do_MMMM_YYYY_hh_mm_a')}` ,
            useTextFile: false,
            useBom: true,
            headers: ['Replacement ID', 'Date', 'Customer name', 'Customer phone', 'Status', 'Replacement done by', "Old SOLbox", "New SOLbox", "Remarks"],
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data.length>0?data:[{}]);
    }

    const filterController=()=>{
        setFilter(!filter);
    }

    const ToolBar = <>
        <Toolbar>
            <Toolbar.Title >
                <SolTypography.Title style={{fontWeight: '600'}}>
                    <SolTypography.Text>{props.replacementList?.count>0?props.replacementList.count:'No'} {parseInt(props.replacementList?.count)===1?'SOLbox Replaced':'SOLboxes Replaced'} </SolTypography.Text>
                </SolTypography.Title>
            </Toolbar.Title>
            <Toolbar.ToolbarContainer>
                <Toolbar.ToolbarContainer.Items>
                    <IconButton classes={classes} onClick={filterController}>
                        <FilterListIcon color="disabled" fontSize="large"/>
                    </IconButton>
                    <IconButton classes={classes} onClick={initiateReplacementProcess} disabled={props.filterTriggeredLoading || props.isLoading || props.retryLoading}>
                        {props.dataIsBeingPreparedForCsv? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>:<img src={require('../../../../utils/asset/download-Button.svg')} alt="Export csv"/>}
                    </IconButton>

                    <SolInput
                        id={'test'}
                        name={'search'}
                        onKeyPress={(event)=>{
                            if (event.key === 'Enter') {
                                onSearch(event.target.value);
                            }
                        }}
                        autoComplete={'off'}
                        onChange={(event)=>{
                            if (event.target.value === '') {
                                onSearch(event.target.value);
                            }
                        }}
                        placeholder={'Type SOLbox ID...'}
                    />
                </Toolbar.ToolbarContainer.Items>
            </Toolbar.ToolbarContainer>
        </Toolbar>
    </>

    return (
        <ContentWrapper showCardHeader={false} pageTitle={null} showBackButton={false} isLoading={props.isLoading}>
            {ToolBar}


            <Filter className={'filter-animation'} style={{
                display: filter?undefined:'none'
            }}>
                <h4 style={{paddingBottom: "1rem"}}>Filter</h4>

                {/*Pass the Dropdown Data as props to the filter form and catch it in the filter form. Then pass the data in the options props in the Select */}
                <FilterForm nodeTypes={nodeTypes} modelList={modelList} gridList={gridList} orgList={orgList}
                            resetFilter={resetFilter} gridOwners={[]} setListFilters={onFilter}
                            projectedParams={projectedParams}/>
            </Filter>

            {<ReplacementLegends/>}

            <DataTableContainer>
                {props.filterTriggeredLoading!==true?
                    <DataTable
                        columns={replacementListColumns}
                        data={replacementList}
                        detailPanel={rowData => {
                            return (
                                <>
                                    <DetailsPanel data={rowData}/>
                                </>
                            )
                        }}
                        actions={props.isSolshare? [
                            (rowData)=>({
                                icon: ({disabled})=> {
                                    if (props.retryLoading===true && rowData.replacement_id===retryRow.replacement_id) {
                                        return (<Spinner size={'lg'} variant={'warning'} animation={"grow"}/>)
                                    } else if (props.retryLoading===true && rowData.replacement_id!==retryRow.replacement_id) {
                                        // 'true' is directly used for 'disabled' attribute as I couldn't figure out how the 'disabled' value is updating due to time shortage: Reza, 21.05.2021
                                        return (<Button disabled={true} className={'btn btn-outline-warning'}  size={'sm'} >
                                            Retry
                                        </Button>)
                                    } else {
                                        return (<Button disabled={disabled} className={'btn btn-outline-warning'}  size={'sm'} >
                                            Retry
                                        </Button>)
                                    }
                                },
                                onClick: (event, rowData) => {
                                    retry(rowData);
                                },
                                hidden: !rowData.retry
                            })
                        ]:undefined}
                        count={props.replacementList?.count}
                        itemsPerPage={props.replacementList?.page_size}
                        asyncPagination={true}
                        onChangePage={onChangePage}
                        page={page}
                    />
                    : <LoadingSpinner/>}
            </DataTableContainer>



            <style jsx>{`
              .filter-animation{
                      animation-name: breath-in;
                      animation-duration: 40ms;
                    }
              @keyframes breath-in {
                0% { height: 0; opacity: 0;  }
                10% { height: 10px;  opacity: 0.10; }
                20% { height: 20px;  opacity: 0.20; }
                30% { height: 30px;  opacity: 0.30; }
                40% { height: 40px;  opacity: 0.40; }
                50% { height: 50px;  opacity: 0.50; }
                60% { height: 60px;  opacity: 0.60; }
                70% { height: 70px;  opacity: 0.70; }
                80% { height: 80px;  opacity: 0.80; }
                90% { height: 90px;  opacity: 0.90; }
                100% { height: auto;  opacity: 1.00; }
              }
              
              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}
              
             
        `}</style>
        </ContentWrapper>
    );
};

ReplacementList.propTypes = {

};


const mapStateToProps=(state)=>{
    return {
        filterDropdownLoading: state.replacementReducer.filterDropdownLoading,
        gridOwnerList: state.replacementReducer.gridOwnerList,
        isLoading: state.replacementReducer.isLoading,
        gridList: state.replacementReducer.gridList,
        modelList: state.replacementReducer.modelList,
        replacementList: state.replacementReducer.replacementList,
        replacementListForExport: state.replacementReducer.replacementListForExport,
        dataIsBeingPreparedForCsv: state.replacementReducer.dataIsBeingPreparedForCsv,
        nodeTypeList: state.replacementReducer.nodeTypeList,
        filterTriggeredLoading: state.replacementReducer.filterTriggeredLoading,
        retrySuccess: state.replacementReducer.retrySuccess,
        retryLoading: state.replacementReducer.retryLoading,
        reimbursementSuccessful: state.replacementReducer.reimbursementSuccessful,
        configUpdateSuccessful: state.replacementReducer.configUpdateSuccessful,
        user_guid: state.auth.user_guid
    }

}

export default connect(mapStateToProps, actions ) (withRoles(ReplacementList));
