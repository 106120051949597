import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {toast} from "react-toastify";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import Select from "react-select";
import {makeStyles} from "@material-ui/core/styles";
import 'react-dates/initialize';
import HORIZONTAL_ORIENTATION from 'react-dates';
import {isInclusivelyAfterDay} from 'react-dates';

import {DateRangePicker, SingleDatePicker, DayPickerRangeController, DateRangePickerInput,} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {
    DangerStatusRender,
    PendingRenderStatus,
    SuccessRenderStatus,
    NotInitiatedRender
} from "../../../../components/legendComponents/legendComponents";
import {
    isNullorUndefined,
    JsonToUriSerialize,
    processFilterParams,
    validateValue
} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});


// const defaultProps = {
//     // example props for the demo
//     autoFocusEndDate: false,
//     initialStartDate: null,
//     initialEndDate: null,
//     startDateOffset: undefined,
//     endDateOffset: undefined,
//     showInputs: false,
//     minDate: null,
//     maxDate: null,
//
//     // day presentation and interaction related props
//     renderCalendarDay: undefined,
//     renderDayContents: null,
//     minimumNights: 1,
//     isDayBlocked: () => false,
//     isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
//     isDayHighlighted: () => false,
//     enableOutsideDays: false,
//     daysViolatingMinNightsCanBeClicked: false,
//
//     // calendar presentation and interaction related props
//     orientation: HORIZONTAL_ORIENTATION,
//     verticalHeight: undefined,
//     withPortal: false,
//     initialVisibleMonth: null,
//     numberOfMonths: 2,
//     onOutsideClick() {},
//     keepOpenOnDateSelect: false,
//     renderCalendarInfo: null,
//     isRTL: false,
//     renderMonthText: null,
//     renderMonthElement: null,
//     renderKeyboardShortcutsButton: undefined,
//     renderKeyboardShortcutsPanel: undefined,
//
//     // navigation related props
//     navPrev: null,
//     navNext: null,
//     renderNavPrevButton: null,
//     renderNavNextButton: null,
//     onPrevMonthClick() {},
//     onNextMonthClick() {},
//
//     // internationalization
//     monthFormat: 'MMMM YYYY',
// };
//


 const FilterForm = ({
                               gridOwners,
                               setListFilters,
                               resetFilter,
                               orgList,
                               gridList,
                               modelList,
                               nodeTypes,
                               ...props
                           }) => {
    const START_DATE = 'start date';
    const {register, handleSubmit, errors, setError, clearErrors, control} = useForm();
    const [calendarView] = useState(['year', 'month']);
    const [monthYear, setMonthYear] = useState(null);
    const [division, setDivision] = useState(null);
    const [org, setOrg] = useState(null);
    const [model, setModel] = useState(null);
    const [grid, setGrid] = useState(null);
    const [status, setStatus] = useState(null);
    const [customerType, setCustomerType] = useState(null);
    const [filter, setFilter] = useState({});
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [initial1filterParam, setInitial1filterParam] = useState(null);
    const classes = useStylesForDatePicker();


    const statuses = [
        {
            label: <>
                <SuccessRenderStatus>{"Completed"}</SuccessRenderStatus>
            </>, value: "success"
        },
        {
            label: <>
                <NotInitiatedRender>{"Config transfer not initiated"}</NotInitiatedRender>
            </>, value: "config_transfer_not_init"
        },
        {
            label: <>
                <NotInitiatedRender>{"Balance transfer not initiated"}</NotInitiatedRender>
            </>, value: "balance_transfer_not_init"
        },
        {
            label: <>
                <PendingRenderStatus>{"Configuration transfer in progress"}</PendingRenderStatus>
            </>, value: "config_transfer_in_progress"
        },
        {
            label: <>
                <PendingRenderStatus>{"Balance transfer in progress"}</PendingRenderStatus>
            </>, value: "balance_transfer_in_progress"
        },
        {
            label: <>
                <DangerStatusRender>{"Configuration transfer failed"}</DangerStatusRender>
            </>, value: "config_transfer_failed"
        },
        {
            label: <>
                <DangerStatusRender>{"Balance transfer failed"}</DangerStatusRender>
            </>, value: "balance_transfer_failed"
        },
        // {label: "Failed", value: "failed"},
    ]


    const customerTypes = [
        {label: "Consumer", value: "Consumer"},
        {label: "Prosumer", value: "Prosumer"},
        {label: "Producer", value: "Producer"},
        {label: "Microutility", value: "Microutility"},
    ]

    // const startDateString = start && start.format('YYYY-MM-DD');
    // const endDateString = end && end.format('YYYY-MM-DD');

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    useEffect(() => {
        setDateRange(start && end ? `${new DateTimeFormat(start).localDisplayWithoutTime} - ${new DateTimeFormat(end).localDisplayWithoutTime}` : '');
    }, [start, end])


    const handleDateChange = ({startDate, endDate}) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }


    const onDatesChange = ({startDate, endDate}) => {

        // let doesNotMeetMinNights = false;
        // if (startDate && endDate) {
        //     const dayDiff = endDate.diff(startDate.clone().startOf('day').hour(12), 'days');
        //     doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
        //     console.log('asdasd')
        // }

        // const { daysViolatingMinNightsCanBeClicked, minimumNights } = props;
        // let doesNotMeetMinNights = false;
        // if (daysViolatingMinNightsCanBeClicked && startDate && endDate) {
        //     const dayDiff = endDate.diff(startDate.clone().startOf('day').hour(12), 'days');
        //     doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
        // }


        setStartDate(startDate);
        setEndDate(endDate);

    }

    const validateIfFuture=(keyword)=>{
        const date = moment(keyword, 'YYYY-MM-DD');
        if (date.isValid()){
            const todayDate = moment();
            if (todayDate.isAfter(date)) {
                return date;
            }else {
                return undefined
            }
        }else {

            return undefined;
        }
    }

    const validationKeyArray = [
        {
            parameter: 'solbox_model_guid',
            list: modelList,
        },
        {
            parameter: 'grid_organization_guid',
            list: orgList,
        },
        {
            parameter: 'status',
            list: statuses,
        },
        {
            parameter: 'grid_item_guid',
            list: gridList,
            isMulti : true
        },
        {
            parameter: 'node_type_id',
            list: nodeTypes,
        },
        {
            parameter: 'replacement_date_to',
            validationFunction: validateIfFuture
        },
        {
            parameter: 'replacement_date_from',
            validationFunction: validateIfFuture

        }
    ];

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(props.projectedParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {

            /* Looping through each of the value in the keys. (Essentially This loop will run for every keys in the projected params object) */

            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const currentIndex = validationKeyArray.findIndex((element) => {
                    return element.parameter === item;
                });

                if (currentIndex > -1 && !isNullorUndefined(validateValue(props.projectedParams[item], validationKeyArray[currentIndex] ))) {
                    Object.assign(validParameters, {[`${item}`]: validateValue(props.projectedParams[item], validationKeyArray[currentIndex] )})
                }
            })
        }

        setInitial1filterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)

        /*Now we validated the */


        /* Now if the valid Params object has at least one parameter we set the filter */

    }, [props.projectedParams, gridList, nodeTypes, modelList, orgList])

    useEffect(() => {
        if (!isNullorUndefined(initial1filterParam)) {

            const frontEndFilterPayload = {}
             if (initial1filterParam.solbox_model_guid) {
                 setModel(initial1filterParam.solbox_model_guid);
                 Object.assign(frontEndFilterPayload, {solbox_model_guid: initial1filterParam.solbox_model_guid.value,})
             }

            if (initial1filterParam.grid_organization_guid) {
                setOrg(initial1filterParam.grid_organization_guid);
                Object.assign(frontEndFilterPayload, {grid_organization_guid: initial1filterParam.grid_organization_guid.value,})
            }

            if (initial1filterParam.node_type_id) {
                setCustomerType(initial1filterParam.node_type_id);
                Object.assign(frontEndFilterPayload, {node_type_id: initial1filterParam.node_type_id.value})
            }

            if (initial1filterParam.status) {
                setStatus(initial1filterParam.status);
                Object.assign(frontEndFilterPayload, {status: initial1filterParam.status.value})
            }

            if (initial1filterParam.grid_item_guid) {
                setGrid(initial1filterParam.grid_item_guid);
                Object.assign(frontEndFilterPayload, {grid_item_guid: getCommaSeparatedGrids(initial1filterParam.grid_item_guid) || undefined})
            }


            if (initial1filterParam.replacement_date_from && initial1filterParam.replacement_date_to) {
                setStartDate(initial1filterParam.replacement_date_from);
                Object.assign(frontEndFilterPayload, {replacement_date_from: initial1filterParam.replacement_date_from.local().format('YYYY-MM-DD')});

                setEndDate(initial1filterParam.replacement_date_to);
                Object.assign(frontEndFilterPayload, {replacement_date_to: initial1filterParam.replacement_date_to.local().format('YYYY-MM-DD')})
            }

            setFilter(frontEndFilterPayload)
            setListFilters(frontEndFilterPayload, false);
            setInitial1filterParam(null);
        }
    }, [initial1filterParam])


    const resetForm = () => {
        if (Object.keys(filter).length > 0) {
            setModel(null);
            setCustomerType(null);
            setStatus(null);
            setGrid(null);
            setStartDate(null);
            setEndDate(null);
            resetFilter()
        }
    }

    const validateForm = (data) => {
        if (!start && !end && !org && !model && !grid && !customerType && !status) {
            setError("validationError", {
                type: "manual",
                message: "No filter has been selected."
            });
            toast.error("Please select at least one filter")
            return false
        } else {
            return true
        }
    }

    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days');
        return current.isAfter(today);
    }


    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start} // momentPropTypes.momentObj or null,
                    endDate={end} // momentPropTypes.momentObj or null,
                    // isOutsideRange={() => null}
                    numberOfMonths={2}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)} // PropTypes.func.isRequired,
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    );


    const getCommaSeparatedGrids = (selectedGrids) => {
        let temp = null;
        if (selectedGrids) {
            temp = JSON.stringify(selectedGrids.map((item) => {
                return item.value
            }));
        } else {
            return undefined;
        }

        return temp?.length >= 0 ? temp : undefined;
    }


    const submit = (data) => {
        if (validateForm(data) === true) {
            const payload = {
                solbox_model_guid: model?.value || undefined,
                grid_organization_guid: org?.value || undefined,
                status: status?.value || undefined,
                grid_item_guid: getCommaSeparatedGrids(grid) || undefined,
                node_type_id: customerType?.value || undefined,
                replacement_date_from: start?.local().format('YYYY-MM-DD') || null, /*2020-09-10*/
                replacement_date_to: end?.local().format('YYYY-MM-DD') || null, /*2020-09-10*/
            }
            setListFilters(payload);
        }
    }


    return <form onSubmit={handleSubmit(submit)}>
        <div className={'col-md-12 no-padding'}>
            {/*<div className="col-md-9 no-padding">*/}
            {/*    {errors.validationError && }*/}
            {/*</div>*/}
            <div className={'row'}>
                <div className={'col-md-3'}>
                    {/*<Select*/}
                    {/*    name={`org`}*/}
                    {/*    placeholder={`Replacement Date Range`}*/}
                    {/*    classNamePrefix="react-select-sol-style"*/}
                    {/*    isDisabled={false}*/}
                    {/*    isLoading={false}*/}
                    {/*    maxMenuHeight={200}*/}
                    {/*    isClearable={true}*/}
                    {/*    options={gridOwners}*/}
                    {/*    value={org}*/}
                    {/*    control={control}*/}
                    {/*    isSearchable={true}*/}
                    {/*    onChange={(selected) => {*/}
                    {/*        setOrg(selected)*/}
                    {/*        clearErrors()*/}
                    {/*        return selected*/}
                    {/*    }}*/}
                    {/*/>*/}


                    <OverlayTrigger rootClose trigger="click" placement="bottom-start" overlay={popover}>
                        <SolInput
                            name={'dateRangePickerInput'}
                            readOnly={true}
                            value={dateRange}
                            placeholder={`Replacement Date Range`}
                        />
                    </OverlayTrigger>


                    {/*<SingleDatePicker*/}
                    {/*    date={moment()} // momentPropTypes.momentObj or null*/}
                    {/*    onDateChange={date => (console.log('asdgweg'))} // PropTypes.func.isRequired*/}
                    {/*    focused={false} // PropTypes.bool*/}
                    {/*    onFocusChange={handleFocusChange} // PropTypes.func.isRequired*/}
                    {/*    id="your_unique_id" // PropTypes.string.isRequired,*/}
                    {/*/>*/}

                    {errors.org && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.org.message}</div>}
                </div>
                <div className={'col-md-3'}>
                    <Select
                        name={`status`}
                        placeholder={`Status`}
                        classNamePrefix="react-select-sol-style"
                        isDisabled={false}
                        isLoading={false}
                        maxMenuHeight={200}
                        value={status}
                        isClearable={true}
                        control={control}
                        options={statuses}
                        isSearchable={true}
                        onChange={(selected) => {
                            setStatus(selected)
                            clearErrors();
                            return selected
                        }}
                    />
                    {errors.division && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.division.message}</div>}
                </div>

                <div className={'col-md-2'}>

                </div>
            </div>

            <div className={'row'} style={{
                marginTop: '1.5rem',
            }}>
                <div className={'col-md-3'}>
                    <Select
                        name={`grids`}
                        placeholder={`Grid`}
                        classNamePrefix="react-select-sol-style"
                        isMulti={true}
                        isDisabled={false}
                        isLoading={false}
                        maxMenuHeight={200}
                        isClearable={true}
                        options={gridList}
                        value={grid}
                        control={control}
                        isSearchable={true}
                        onChange={(selected) => {
                            setGrid(selected)
                            clearErrors()
                            return selected
                        }}
                    />
                    {errors.org && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.org.message}</div>}
                </div>
                <div className={'col-md-3'}>
                    <Select
                        name={`org`}
                        placeholder={`Grid operator`}
                        classNamePrefix="react-select-sol-style"
                        isDisabled={!props.isSolshare}
                        isLoading={false}
                        maxMenuHeight={200}
                        isClearable={true}
                        options={orgList}
                        value={org}
                        control={control}
                        isSearchable={true}
                        onChange={(selected) => {
                            setOrg(selected);
                            clearErrors();
                            return selected
                        }}
                    />
                    {errors.org && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.org.message}</div>}
                </div>
                <div className={'col-md-3'}>
                    <Select
                        name={`customer_type`}
                        placeholder={`Customer Type`}
                        classNamePrefix="react-select-sol-style"
                        isDisabled={false}
                        isLoading={false}
                        maxMenuHeight={200}
                        isClearable={true}
                        options={nodeTypes}
                        value={customerType}
                        control={control}
                        isSearchable={true}
                        onChange={(selected) => {
                            setCustomerType(selected)
                            clearErrors()
                            return selected
                        }}
                    />
                    {errors.org && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.org.message}</div>}
                </div>
                <div className={'col-md-3'}>
                    <Select
                        name={`model`}
                        placeholder={`SOLbox Model`}
                        classNamePrefix="react-select-sol-style"
                        isDisabled={false}
                        isLoading={false}
                        maxMenuHeight={200}
                        isClearable={true}
                        options={modelList}
                        value={model}
                        control={control}
                        isSearchable={true}
                        onChange={(selected) => {
                            setModel(selected)
                            clearErrors()
                            return selected
                        }}
                    />
                    {errors.org && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.org.message}</div>}
                </div>
            </div>


            <div className={'col-md-3 no-padding'} style={{
                marginTop: '1.5rem',
            }}>


                <Button name={'filterSubmit'} className={'btn btn-outline-warning'} size={'sm'} id={'filterSubmit'}
                        type='submit'>
                    Apply Filter
                </Button>

                <Button name={'reset'} id={'reset'} size={'sm'}
                        className={'btn btn-outline-secondary reset-button-style'} type={'button'} dullComponent={true}
                        onClick={resetForm}>
                    Reset Filter
                </Button>
            </div>

        </div>

        <style jsx>{`
          .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active {
            border: 1px solid #F18D00;
            box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
            outline: none;
          }

          .custom-popover {
            min-width: fit-content;
            min-height: fit-content;
          }
        `}</style>
    </form>
}
export default withRoles(FilterForm)