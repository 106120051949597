import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";

const DetailsContainer = styled.div`
  padding: 2rem 2rem 2rem 3rem;
`;


const Text =(props)=>{
    return <div style={{marginBottom: '.65rem', fontWeight: props.bold? '600':undefined}} ><SolTypography.Text primary={props.primary} >{props.children}</SolTypography.Text></div>
}


const DetailsPanel = ({data,...props}) => {
    let buyPrice = 'N/A'
    let sellPrice = 'N/A'
    let priceUnit = ' Tk/Ah'
    if (data.old_solbox_firmware_version && data.old_solbox_config && Object.entries(data.old_solbox_config).length !== 0) {
        let onlyFirmware = parseFloat(data.old_solbox_firmware_version.split('_')[1])
        if (onlyFirmware >= 5.213) {
            buyPrice = data.old_solbox_config.u16BuyPrice_conf !== null? data.old_solbox_config.u16BuyPrice_conf / 100 + priceUnit : 'N/A'
            sellPrice = data.old_solbox_config.u16SellPrice_conf !== null? data.old_solbox_config.u16SellPrice_conf / 100 + priceUnit : 'N/A'
        } else {
            buyPrice = data.old_solbox_config.fBuyPrice_conf !== null? data.old_solbox_config.fBuyPrice_conf + priceUnit : 'N/A'
            sellPrice = data.old_solbox_config.fSellPrice_conf !== null? data.old_solbox_config.fSellPrice_conf + priceUnit : 'N/A'
        }
    } else if (!data.old_solbox_firmware_version && data.old_solbox_config && Object.entries(data.old_solbox_config).length !== 0) {
        // At backend, We are passing values of 'fBuyPrice_conf' and 'fSuyPrice_conf' as if no previously assigned values against these keys are not found
        // This can happen if we try to replace SOLboxs having different firmwares [lower and above/equal to 5.213]
        // Also, in ideal case, both of 'fBuyPrice_conf' and 'fSellPrice_conf' must not have value 0
        if (data.old_solbox_config.fBuyPrice_conf || data.old_solbox_config.fSellPrice_conf) {
            buyPrice = data.old_solbox_config.fBuyPrice_conf + priceUnit
            sellPrice = data.old_solbox_config.fSellPrice_conf + priceUnit
        } else {
            // Checking for null value is necessary
            buyPrice = data.old_solbox_config.u16BuyPrice_conf !== null? data.old_solbox_config.u16BuyPrice_conf / 100 + priceUnit : 'N/A'
            sellPrice = data.old_solbox_config.u16SellPrice_conf !== null? data.old_solbox_config.u16SellPrice_conf / 100 + priceUnit : 'N/A'
        }
    }
    let balance = data.old_solbox_balance !== null? data.old_solbox_balance + ' Tk' : 'N/A'
    let configType = 'N/A'
    let exchangeControl = 'N/A'
    let thresholdValue = 'N/A'
    if (data.old_solbox_config && Object.entries(data.old_solbox_config).length !== 0) {
        configType = data.old_solbox_config.configuration_type
        if (data.old_solbox_config.u8exchange_control_v2 !== null) {
            // Simple if check catching 0 as well hence checking for null explicitly
            exchangeControl = parseFloat(data.old_solbox_config.u8exchange_control_v2) === 1 ? 'On':'Off'
        }
        thresholdValue = data.old_solbox_config.fThreshold !== null?  data.old_solbox_config.fThreshold + ' mA' : 'N/A'
    }
    return (
        <DetailsContainer style={{fontSize:'13px'}}>
            <div className={'row'}>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>Customer</SolTypography.Text></div>
                    <Text  primary={true}>{data?.customer_name || "N/A"}</Text>
                    <Text>{`${data?.customer_phone || "N/A"}` || "N/A"}</Text>

                </div>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>Remarks</SolTypography.Text></div>
                    <Text >{data?.remarks || "N/A"}</Text>
                    <Text>{data?.customer_phone}</Text>
                </div>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>{data?.old_solbox_serial_number || "N/A"}</SolTypography.Text></div>

                    <Text bold={true} >Config Type: {data.config_found_at_old_solbox? configType: 'N/A'}</Text>
                    <Text bold={true} >Balance: {balance}</Text>
                    <Text bold={true}>Buy: {data.config_found_at_old_solbox? buyPrice: 'N/A'},
                        Sell: {data.config_found_at_old_solbox? sellPrice : 'N/A'}</Text>
                    <Text bold={true}>Exchange Control: {data.config_found_at_old_solbox? exchangeControl: 'N/A'} </Text>
                    <Text bold={true}>Threshold Current: {data.config_found_at_old_solbox? thresholdValue: 'N/A'}</Text>
                </div>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>{data?.new_solbox_serial_number || "N/A"}</SolTypography.Text></div>
                    <Text bold={true} >Config Type: {data.config_transfer_status ==='success'? configType : 'N/A'}</Text>
                    <Text bold={true} >Balance: {data.balance_transfer_status==='success'?  balance : 'N/A'}</Text>
                    <Text bold={true}>Buy: {data.config_transfer_status==='success'? buyPrice : 'N/A'},  Sell: {data.config_transfer_status==='success'? sellPrice : 'N/A'}</Text>
                    <Text bold={true}>Exchange Control: {data.config_transfer_status==='success'? exchangeControl : 'N/A'}</Text>
                    <Text bold={true}>Threshold Current: {data.config_transfer_status==='success'? thresholdValue : 'N/A'}</Text>
                </div>
            </div>

        </DetailsContainer>
    );
};

DetailsPanel.propTypes = {

};

export default DetailsPanel;
