import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Badge, Button, Form, Modal, Spinner} from "react-bootstrap";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import {incompatibleFirmwareVersion, determineBuyPriceAndSellPrice} from "../../utils/firmwareVersionChecking/firmwareVersionChecking";


const PurpleSwitch = withStyles({
    switchBase: {
        color: '#d6d6d6',
        '&$checked': {
            color: "#db8025",
        },
        '&$checked + $track': {
            backgroundColor: '#6b6b6b',
        },
    },
    checked: {},
    track: {},
})(Switch);

export const ConfigForm =({configurationData, hideModal, disableButton, submit, ...props}) => {
    const {handleSubmit, register, errors, control, setValue} = useForm();
    const [checked, setChecked] = useState(true);
    const [threshHold, setThreshHold] = useState('');
    const [buyPrice, setBuyPrice] = useState('');
    const [sellPrice, setSellPrice] = useState('');
    const [hvd, setHVD] = useState('14.5');
    const [hvr, setHVR] = useState('14');
    const [thresholdFieldErrorMessage, setThresholdFieldErrorMessage] = useState(false);
    const [buyPriceFieldErrorMessage, setBuyPriceFieldErrorMessage] = useState(false);
    const [sellPriceFieldErrorMessage, setSellPriceFieldErrorMessage] = useState(false);
    const [hvdFieldErrorMessage, setHVDFieldErrorMessage] = useState(false);
    const [hvrFieldErrorMessage, setHVRFieldErrorMessage] = useState(false);
    const [disableBuyPriceField, setDisableBuyPriceField] = useState(false);
    const [disableSellPriceField, setDisableSellPriceField] = useState(false);
    const [disableVoltagefields, setDisableVoltageFields] = useState(false);
    const modes= {
        1: {label: "Default", value: 'default'},
        2: {label: "Sell", value: 'sell'},
        3: {label: "Buy", value: 'buy'},
    }
    const [selectedValue, setSelectedValue] = useState(modes["1"]);
    const defaultErrorMessageForHVD = 'Please enter a number!'
    const [errorMessageForHVD, setErrorMessageForHVD] = useState(defaultErrorMessageForHVD);

    useEffect(()=>{
        if (configurationData?.configuration_parameters)
        {
            // Saving current configurations
            let data = configurationData.configuration_parameters
            let [buyPrice, sellPrice] = determineBuyPriceAndSellPrice(configurationData.fw_version, data)
            // Highest Voltage Reconnect
            setHVR(data.fVBulkStart_conf)
            // Highest Voltage Disconnect
            setHVD(data.fVBulkEnd_conf)
            if (data.u8ConfigVersionMajor) {
                setSelectedValue(modes[`${data.u8ConfigVersionMajor}`])
                if (data.u8ConfigVersionMajor === 3) {
                    // Buy config
                    setDisableSellPriceField(true)
                    setDisableVoltageFields(true)
                } else if (data.u8ConfigVersionMajor === 2) {
                    // Sell config
                    setDisableBuyPriceField(true)
                }
            }
            // Exchnage control
            if (data.u8exchange_control_v2 && parseInt(data.u8exchange_control_v2)>0) {
                setChecked(true)
            } else {
                setChecked(false)
            }
            // Threshold value
            if (data.fThreshold) {
                setThreshHold(data.fThreshold)
            } else {
                setThreshHold('')
            }
            // Buy price
            setBuyPrice(buyPrice)
            // Sell price
            setSellPrice(sellPrice)
        }
    },[configurationData])

    const handleTextInput = (event) => {
        if (event.target.name === "threshold_value") {
            if (isNaN(event.target.value)) {
                setThresholdFieldErrorMessage(true)
            } else {
                setThresholdFieldErrorMessage(false)
                setThreshHold(event.target.value);
            }
        }
        if (event.target.name === "buy_price") {
            if (isNaN(event.target.value)) {
                setBuyPriceFieldErrorMessage(true)
            } else {
                setBuyPriceFieldErrorMessage(false)
                setBuyPrice(event.target.value)
            }
        }
        if (event.target.name === "sell_price") {
            if (isNaN(event.target.value)) {
                setSellPriceFieldErrorMessage(true)
            } else {
                setSellPriceFieldErrorMessage(false)
                setSellPrice(event.target.value);
            }
        }
        if (event.target.name === "hvd") {
            if (isNaN(event.target.value)) {
                setErrorMessageForHVD(defaultErrorMessageForHVD)
                setHVDFieldErrorMessage(true)
            } else {
                setHVDFieldErrorMessage(false)
                setHVD(event.target.value);
            }
        }
        if (event.target.name === "hvr") {
            if (isNaN(event.target.value)) {
                setHVRFieldErrorMessage(true)
            } else {
                setHVRFieldErrorMessage(false)
                setHVR(event.target.value);
            }
        }
    };

    // Select menu handle change
    const handleChange=(selected)=>{
        if (selected.value === "buy") {
            // Buy config
            setDisableSellPriceField(true)
            setDisableVoltageFields(true)
            setValue('sell_price', '0', { shouldValidate: true })
            setValue('buy_price', '', { shouldValidate: true })
            setSellPrice('0')
            setBuyPrice('')
            setDisableBuyPriceField(false)
        } else if (selected.value === "sell") {
            // Sell config
            setDisableBuyPriceField(true)
            setValue('sell_price', '', { shouldValidate: true })
            setValue('buy_price', '0', { shouldValidate: true })
            setSellPrice('')
            setBuyPrice('0')
            setDisableSellPriceField(false)
            setDisableVoltageFields(false)
        } else {
            // Default config
            setSellPrice('')
            setBuyPrice('')
            setValue('sell_price', '', { shouldValidate: true })
            setValue('buy_price', '', { shouldValidate: true })
            setDisableBuyPriceField(false)
            setDisableSellPriceField(false)
            setDisableVoltageFields(false)
        }
        setSelectedValue(selected)
        return selected;
    }

    const generateSubmissionData = (data)=> {
        if (!(hvd > hvr)) {
            setHVDFieldErrorMessage(true)
            setErrorMessageForHVD('High Voltage Disconnect value must be greater than the High Voltage Reconnect value!')
        } else {
            setHVDFieldErrorMessage(false)
            setErrorMessageForHVD(defaultErrorMessageForHVD)

            const payload = {
                configuration_type: selectedValue.value,
                u8exchange_control_v2: incompatibleFirmwareVersion(configurationData.fw_version)?undefined:checked===true?1:0,
                fThreshold: threshHold,
                solbox_guid: configurationData?.solbox_guid || "",
                customer_guid: configurationData?.customer_guid || "",
                grid_guid: configurationData?.grid_guid || "",
                solbox_id: configurationData.serial_number,
                sell_price: parseFloat(sellPrice),
                buy_price: parseFloat(buyPrice),
                fVBulkStart_conf: parseFloat(hvr),
                fVBulkEnd_conf: parseFloat(hvd)
            }
            submit(payload);
        }
    }

    const formBody = () => {
        return <>
            <Form onSubmit={handleSubmit(generateSubmissionData)}>
                <Form.Group>
                    <div className={'row'}>
                        <div className={'col-md-4 div-element-center'}>
                            <Form.Label>{`Select Mode`}<span className={"text-danger"}>*</span></Form.Label>
                        </div>
                        <div className="col-md-8">
                            <Select
                                name={`mode`}
                                placeholder={`Select a Mode`}
                                isDisabled={false}
                                isLoading={false}
                                maxMenuHeight={200}
                                isClearable={true}
                                control={control}
                                defaultValue
                                value={selectedValue}
                                rules={{
                                    required: "Required",
                                }}
                                // onMenuScrollToBottom={(event)=>{
                                //     if (nextPage)
                                //     {
                                //         props.FETCH_MODEL_LIST(nextPage)
                                //     }
                                // }}
                                isSearchable={true}
                                options={[{label: "Buy", value: 'buy'},{label: "Sell", value: 'sell'},{label: "Default", value: 'default'},]}
                                onChange={(selected) => selected? handleChange(selected):null}
                            />
                            {errors.mode && <div className={"text-danger"}>
                                <i className={"flaticon-warning kt-font-brand"}/>{errors.mode.message}</div>}
                        </div>
                    </div>
                </Form.Group>

                {!incompatibleFirmwareVersion(configurationData?.fw_version || props.solboxDetails?.solbox_fw_version) ?<Form.Group>
                    <div className={'row'}>
                        <div className={'col-md-4 div-element-center'}>
                            <Form.Label>{`Threshold Value`}</Form.Label>
                        </div>
                        <div className="col-md-8">
                            <Form.Control
                                name={'threshold_value'}
                                placeholder="Enter threshold value"
                                ref={register({
                                    required: "Required"
                                })}
                                type={'text'}
                                onKeyUp={(event) => handleTextInput(event)}
                                defaultValue={threshHold}
                                autoComplete={'off'}
                            />
                        </div>
                    </div>
                    {errors.threshold_value? <div className={'row'}>
                        <div className={'col-md-4'}></div>
                        <div className={'col-md-8 text-danger'}>
                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.threshold_value.message}
                        </div>
                    </div>:null}
                    {thresholdFieldErrorMessage? (
                        <div className={`row`}>
                            <div className={'col-md-4'}></div>
                            <div className={'col-md-8'}>
                                <span className="d-block mb-3" style={{ color: "rgba(203, 21, 43, 1)" }}>
                                    {`Please enter a number!`}
                                </span>
                            </div>
                        </div>
                    ) : ''}
                </Form.Group>:null}

                <Form.Group>
                    <div className={'row'}>
                        <div className={'col-md-4 div-element-center'}>
                            <Form.Label>{`Buy Price (BDT)`}</Form.Label>
                        </div>
                        <div className="col-md-8">
                            <Form.Control
                                name={'buy_price'}
                                placeholder="Enter buy price"
                                ref={register({
                                    required: "Required"
                                })}
                                type={'text'}
                                autoComplete={'off'}
                                onKeyUp={(event) => handleTextInput(event)}
                                defaultValue={buyPrice}
                                disabled={disableBuyPriceField}
                            />
                        </div>
                    </div>
                    {errors.buy_price? <div className={'row'}>
                        <div className={'col-md-4'}></div>
                        <div className={'col-md-8 text-danger'}>
                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.buy_price.message}
                        </div>
                    </div>:null}
                    {buyPriceFieldErrorMessage? (
                        <div className={`row`}>
                            <div className={'col-md-4'}></div>
                            <div className={'col-md-8'}>
                                <span className="d-block mb-3" style={{ color: "rgba(203, 21, 43, 1)" }}>
                                    {`Please enter a number!`}
                                </span>
                            </div>
                        </div>
                    ) : ''}
                </Form.Group>

                <Form.Group>
                    <div className={'row'}>
                        <div className={'col-md-4 div-element-center'}>
                            <Form.Label>{`Sell Price (BDT)`}</Form.Label>
                        </div>
                        <div className="col-md-8">
                            <Form.Control
                                name={'sell_price'}
                                placeholder="Enter sell price"
                                ref={register({
                                    required: "Required"
                                })}
                                type={'text'}
                                onKeyUp={(event) => handleTextInput(event)}
                                defaultValue={sellPrice}
                                disabled={disableSellPriceField}
                                autoComplete={'off'}
                            />
                        </div>
                    </div>
                    {errors.sell_price? <div className={'row'}>
                        <div className={'col-md-4'}></div>
                        <div className={'col-md-8 text-danger'}>
                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.sell_price.message}
                        </div>
                    </div>:null}
                    {sellPriceFieldErrorMessage? (
                        <div className={`row`}>
                            <div className={'col-md-4'}></div>
                            <div className={'col-md-8'}>
                                <span className="d-block mb-3" style={{ color: "rgba(203, 21, 43, 1)" }}>
                                    {`Please enter a number!`}
                                </span>
                            </div>
                        </div>
                    ) : ''}
                </Form.Group>


                {!incompatibleFirmwareVersion(configurationData?.fw_version || props.solboxDetails?.solbox_fw_version)?<Form.Group>
                    <div className={'row'}>
                        <div className={'col-md-4 div-element-center'}>
                            <Form.Label>{`Exchange Control`}</Form.Label>
                        </div>
                        <div className="col-md-8">
                            <PurpleSwitch name={'exchange'} checked={checked} onChange={(event)=>{
                                setChecked(!checked)
                            }}/>
                            {errors.exchange && <div className={"text-danger"}>
                                <i className={"flaticon-warning kt-font-brand"}/>{errors.exchange.message}</div>}
                        </div>
                    </div>
                </Form.Group>:null}

                <Form.Group>
                        <div className={'row'}>
                            <div className={'col-md-4 div-element-center'}>
                                <Form.Label>{'High Voltage Disconnect'}</Form.Label>
                            </div>
                            <div className="col-md-8">
                                <Form.Control
                                    name={'hvd'}
                                    placeholder="Provide HVD value..."
                                    ref={register({
                                        required: "Required"
                                    })}
                                    type={'text'}
                                    autoComplete={'off'}
                                    onKeyUp={(event) => handleTextInput(event)}
                                    defaultValue={hvd}
                                    disabled={disableVoltagefields}
                                />
                            </div>
                        </div>
                        {errors.hvd? <div className={'row'}>
                            <div className={'col-md-4'}></div>
                            <div className={'col-md-8 text-danger'}>
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.hvd.message}
                            </div>
                        </div>:null}
                        {hvdFieldErrorMessage? (
                            <div className={`row`}>
                                <div className={'col-md-4'}></div>
                                <div className={'col-md-8'}>
                                <span className="d-block mb-3" style={{ color: "rgba(203, 21, 43, 1)" }}>
                                    {errorMessageForHVD}
                                </span>
                                </div>
                            </div>
                        ) : ''}
                    </Form.Group>

                    <Form.Group>
                        <div className={'row'}>
                            <div className={'col-md-4 div-element-center'}>
                                <Form.Label>{'High Voltage Reconnect'}</Form.Label>
                            </div>
                            <div className="col-md-8">
                                <Form.Control
                                    name={'hvr'}
                                    placeholder="Provide HVR value..."
                                    ref={register({
                                        required: "Required"
                                    })}
                                    type={'text'}
                                    autoComplete={'off'}
                                    onKeyUp={(event) => handleTextInput(event)}
                                    defaultValue={hvr}
                                    disabled={disableVoltagefields}
                                />
                            </div>
                        </div>
                        {errors.hvr? <div className={'row'}>
                                <div className={'col-md-4'}></div>
                                <div className={'col-md-8 text-danger'}>
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.hvr.message}
                               </div>
                        </div>:null}
                        {hvrFieldErrorMessage? (
                            <div className={`row`}>
                                <div className={'col-md-4'}></div>
                                <div className={'col-md-8'}>
                                <span className="d-block mb-3" style={{ color: "rgba(203, 21, 43, 1)" }}>
                                    {`Please enter a number!`}
                                </span>
                                </div>
                            </div>
                        ) : ''}
                    </Form.Group>

                {submit?<Modal.Footer>
                    <Button style={{
                        backgroundColor: '#6c757d',
                        border: 'none',
                    }} disabled={disableButton} onClick={hideModal}>
                        <i className="fa fa-window-close"/>
                        &nbsp;Cancel
                    </Button>
                    <Button variant="warning" type="submit" disabled={(disableButton || thresholdFieldErrorMessage ||
                        buyPriceFieldErrorMessage || sellPriceFieldErrorMessage || hvdFieldErrorMessage || hvrFieldErrorMessage)}>
                        Update
                        &nbsp;{disableButton ? <Spinner animation="border" variant="light"/> :
                            <i className="fa fa-paper-plane"/>}
                    </Button>
                </Modal.Footer>:null}
            </Form>
        </>
    }


    return <>
        {formBody()}
    </>
}
