import React, {Fragment, useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {connect} from "react-redux";
import {customerActions} from "../../customerActions";
import {Button, Tabs, Tab, Badge, Modal, Breadcrumb} from "react-bootstrap";
import styled from "styled-components";
import {CustomerProfileContainer, OnlineStatus, SolTypography} from "../components";
import {SolTypography as SolTypoGraphyGlobal} from '../../../../components/utilityComponents/SOlTypography'
import {CustomerType} from "../../../../components/utilityComponents/SOlTypography";
import Loader from "../../../../components/loader/Loader";
import NodeReplacementList from "./nodesReplacementModule/NodeReplacementList";
import CustomerProfileTransactions from "./CustomerProfileTransactions";
import LiveDataComponent from "../../../commonFeatureModules/views/LiveDataComponent";
import {commonActions} from '../../../commonFeatureModules/commonActions'
import {requestCycle} from "../../../grids/utils";
import {Link} from "react-router-dom";
import {
    displayBangladeshiMobileNo,
    getApplianceString,
    isNumber,
    getShsSetupString,
    isNullorUndefined, paisaToTaka, capitalizeFirstLetter
} from "../../../../utils/utilityFunctions";
import {incompatibleFirmwareVersion} from "../../../../utils/firmwareVersionChecking/firmwareVersionChecking";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";


const TabContainer = styled.div`
  padding: 2rem 2rem 0 0;
  color: #12100C !important;
`;

const customerTypes = {
    consumer: 'CO',
    microutility: 'MU',
    prosumer: 'PRO',
    producer: 'PRD',
}

const moods = {
    0: 'Auto',
    1: 'Sell',
    2: "Buy",
    3: 'Stop'
}

const CustomerProfile = props => {
    const {
        isLoading,
        detailsLoading,
        customerDetails,
        GET_TRANSACTION_HISTORY_LIST,
        muDetails,
        FETCH_CUSTOMER_DETAILS,
        MU_PROFILE_DETAILS,
        PUBLISH_TOPIC_FOR_SINGLE_SOLBOX,
        GET_LIVE_DATA_FOR_SINGLE_SOLBOX,
        SET_FLAGS_TO_UNDEFINED,
        GET_SOLBOX_PROFILE_DATA,
        GET_LEGACY_LIVE_DATA_COMMON,
        loading,
        topicPublished,
        liveDataLoadingMessage,
        liveDataFetched,
        liveData,
        solboxDetails,
        legacyData,
        refreshTime
    } = props;
    const [onlineStatus, setOnlineStatus] = useState(false);
    const [key, setKey] = useState('transactions');
    const subHeader = useSubheader();
    const [firstCall, setFirstCall] = useState(true);
    const [refreshTimerString, setRefreshTimerString] = useState('');
    const [dataReceivedTime, setDataReceivedTime] = useState(null);
    const [timer, setTimer] = useState(null);

    const toggleCustomerProfileTab = (key) => {
        setKey(key);
    };


    useEffect(()=>{
        // subHeader.setBreadCrumbComponent(<Breadcrumb>
        //     <Breadcrumb.Item href={'/grids/list'} target={'_blank'} >Grids</Breadcrumb.Item>
        //     <Breadcrumb.Item active>
        //         {getGridNameString(props.gridInfo)==='N/A'?'Current grid':getGridNameString(props.gridInfo)}
        //     </Breadcrumb.Item>
        // </Breadcrumb>)
        //
        //
        subHeader.setActionButtons(<>
            <div className={'d-flex align-items-right flex-wrap align-items-center'}>

                <>
                    <b style={{
                        textAlign: 'right',
                    }}>
                        <SolTypoGraphyGlobal.Text secondary> {dataReceivedTime? <>Last data received on<br/>{dataReceivedTime}</>: null }  </SolTypoGraphyGlobal.Text></b>&nbsp;&nbsp;&nbsp;
                </>
                    <Button variant="warning" size={'sm'} type="submit" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={() => checkForLatestData()} disabled={loading}>
                        <img src={require('../../../../utils/asset/refresh-live-data.svg')}/>&nbsp;&nbsp;Check
                        Latest Data
                    </Button>
            </div>

        </>)

        return ()=>{
            subHeader.setActionButtons(null);
            subHeader.setBreadCrumbComponent(null);
        }
    },[loading, dataReceivedTime]);

    useEffect(()=>{
        setRefreshTimerString('Refreshed just now');
        const interval=setInterval(timeChecker,10000);

        return ()=>clearInterval(interval);
    },[refreshTime])

    useEffect(()=>{
        if (liveData?.updated_at){
            setDataReceivedTime(new DateTimeFormat(liveData.updated_at).localDisplayTime)
        }
    },[liveData])

    useEffect(()=>{
        if (legacyData?.updated_at){
            setDataReceivedTime(new DateTimeFormat(legacyData.updated_at).localDisplayTime)
        }
    },[legacyData])

    useEffect(()=>{

        if (!timer)
        {
            setRefreshTimerString('Refreshed just now');
        }
        else if (Number.isInteger(timer) && timer<60)
        {
            setRefreshTimerString(`Refreshed ${timer} ${timer>1?'minutes':'minute'} ago`);
        }
        else if (Number.isInteger(timer) && timer>=60)
        {
            setRefreshTimerString(`Refreshed ${Math.floor(timer/60)} ${Math.floor(timer/60)>1?'hours':'hour'} and ${Math.floor(timer%60)} minutes ago`)
        }
        else {
            setRefreshTimerString(null)
        }

    },[timer])


    const timeChecker =()=>{
        const temp = moment().diff(refreshTime, 'minutes')
        if (temp!==timer){
            setTimer(temp)
        }
    }


    const checkForLatestData=()=>{
        // PUBLISH_TOPIC_FOR_SINGLE_SOLBOX({
        //     solbox_id: props.match.params.solbox_serial_number.toString().slice(4,8),
        // });
        if (!incompatibleFirmwareVersion(solboxDetails.solbox_fw_version)){
            PUBLISH_TOPIC_FOR_SINGLE_SOLBOX({
                solbox_id: props.match.params.solbox_serial_number.toString().slice(4,8),
            });

        } else {

            GET_LEGACY_LIVE_DATA_COMMON({
                solbox_guid_list: [props.match.params.solbox_guid],
            });
        }
    }

    /* Fetching the Customer or MU profile Data */
    useEffect(()=>{
        // alert(props.location.state?.customer_type);

        // solboxDetails

        GET_SOLBOX_PROFILE_DATA({
            solbox_guid: props.match.params.solbox_guid,
        });

        // const temp =[ 'Producer', 'Prosumer','Consumer'];

        return ()=> {
            SET_FLAGS_TO_UNDEFINED();
        }

    },[SET_FLAGS_TO_UNDEFINED, GET_SOLBOX_PROFILE_DATA])


    useEffect(()=>{
        subHeader.setTitle('Customer Profile');
    },[]);


    useEffect(()=>{
        if (legacyData && liveDataFetched === requestCycle.success && firstCall){
            // setTimeout(()=>{
            //     setFirstCall(false);
            // },200)
            setFirstCall(false);
        }
    }, [legacyData])


    useEffect(()=>{
        if (solboxDetails?.node_type !== 'Microutility')
        {
            subHeader.setTitle('Customer Profile');

            FETCH_CUSTOMER_DETAILS({
                customer_guid: props.match.params.customer_guid,
                solbox_guid: props.match.params.solbox_guid,
                solbox_serial_number: props.match.params.solbox_serial_number
            });
        }
        else if (solboxDetails?.node_type === 'Microutility') {
            subHeader.setTitle('MU Profile');

            MU_PROFILE_DETAILS({
                solbox_guid: props.match.params.solbox_guid
            })
        }
    },[solboxDetails])


    /*Starting to fetch the Live Data for current SOLbox*/
    useEffect(()=>{

        if (solboxDetails?.solbox_fw_version){
            if (!firstCall && !incompatibleFirmwareVersion(solboxDetails.solbox_fw_version)){
                PUBLISH_TOPIC_FOR_SINGLE_SOLBOX({
                    solbox_id: props.match.params.solbox_serial_number.toString().slice(4,8),
                });

            } else {

                GET_LEGACY_LIVE_DATA_COMMON({
                    solbox_guid_list: [props.match.params.solbox_guid],
                });
            }

        }

    },[PUBLISH_TOPIC_FOR_SINGLE_SOLBOX, SET_FLAGS_TO_UNDEFINED , solboxDetails]);

    /* Getting Live Data if publishing request is successful */
    useEffect(()=>{

        /*Checking if the publish request has been successful*/
        if (topicPublished === requestCycle.success)
        {
            setTimeout(()=>{
                GET_LIVE_DATA_FOR_SINGLE_SOLBOX({
                    solbox_id: props.match.params.solbox_serial_number.toString().slice(4,8),
                });
            },8000)
        }

    },[topicPublished,GET_LIVE_DATA_FOR_SINGLE_SOLBOX]);


    /*Setting Live Data and Online Status*/
    useEffect(()=>{
        if (liveDataFetched=== requestCycle.success && liveData)
        {
            setOnlineStatus(true)
        } else if (legacyData && legacyData.solbox_guid === props.match.params.solbox_guid && legacyData.solbox_status==='online')
        {
            setOnlineStatus(true);
        }
    },[liveDataFetched,liveData, legacyData])


    /*Going back to the previous Route*/
    const backFunction=()=>{
        // props.history.push('/customers/list');
        props.history.goBack();
    }


    const getExhangeControl=(data)=>{
        // {liveDataFetched===requestCycle.success && Object.keys(liveData).length>0?getExhangeControl():'No Data found'}
        if (!incompatibleFirmwareVersion(solboxDetails?.solbox_fw_version))
        {
            if (liveDataFetched===requestCycle.success){
                if (parseFloat(liveData.u8exchange_control_v2)===0)return <SolTypography.Text style={{color: '#CB152B'}}>Off</SolTypography.Text>
                else if(parseFloat(liveData.u8exchange_control_v2)===1)return <SolTypography.Text style={{color: '#0B6E4F'}}>On</SolTypography.Text>
                else return "No Data Found"
            }else
            {
                return "No Data Found"
            }
        }else{
            return "N/A"
        }
    }


    const getBalanceData=()=>{

        if (liveDataFetched===requestCycle.success && !incompatibleFirmwareVersion(solboxDetails?.solbox_fw_version))
        {
            if (liveDataFetched ===requestCycle.success)
            {
                return  liveDataFetched===requestCycle.success && Object.keys(liveData).length>0 && !isNaN(liveData?.i32Balance)? `${(liveData.i32Balance/100)} Tk` : "N/A";
            }
            else {
                return "No data found"
            }

        } else{
            return legacyData && Object.keys(legacyData).length>0 && isNumber(legacyData.last_known_balance)? `${legacyData.last_known_balance/100} Tk`: "No Data Found";
        }
    }


    const getTariffInfo=(data)=>{
        let tempString= '';

        if (liveDataFetched===requestCycle.success  && !incompatibleFirmwareVersion(solboxDetails?.solbox_fw_version))
        {
            if (liveDataFetched ===requestCycle.success)
            {
                tempString = !isNumber(liveData?.u16BuyPrice) && !isNumber(liveData?.u16SellPrice) ? 'No tariff info found':'';

                if (tempString) return tempString;

                tempString = tempString.concat(isNumber(liveData?.u16BuyPrice)? `Buy ${paisaToTaka(liveData?.u16BuyPrice)} Tk / Ah, `:'No buy tariff found, ');

                tempString =  tempString.concat(isNumber(liveData?.u16SellPrice)?`Sell ${paisaToTaka(liveData?.u16SellPrice)} Tk / Ah`:'No sell tariff found');
            }else {
                return "No data found"
            }

        }else{
            // if (firstCall)setFirstCall(false);
            tempString = !isNumber(legacyData?.current_buy_price) && !isNumber(legacyData?.current_sell_price) ? 'No tariff info found':'';

            if (tempString) return tempString;

            tempString = tempString.concat(isNumber(legacyData?.current_buy_price)? `Buy ${paisaToTaka(legacyData.current_buy_price)} Tk / Ah, `:'No buy tariff found, ');

            tempString =  tempString.concat(isNumber(legacyData?.current_sell_price)?`Sell ${paisaToTaka(legacyData.current_sell_price)} Tk / Ah`:'No sell tariff found');
        }

        return tempString;


    }
    const getThresholdValue=()=>{

        if (!incompatibleFirmwareVersion(solboxDetails?.solbox_fw_version))
        {
            if (liveDataFetched===requestCycle.success && Object.keys(liveData).length>0 && isNumber(liveData.fThreshold))
            {
                return `${liveData.fThreshold} mA`
            }else {
                return 'Data not found'
            }

        }else{
            return "N/A"
        }
    }


    const formatExchangeControl=(data)=>{
        if (typeof data=== 'string' && data==='on') return <><SolTypography.Text success >{capitalizeFirstLetter(data)}</SolTypography.Text> </>
        else if (typeof data=== 'string' && data==='off') return <><SolTypography.Text danger >{capitalizeFirstLetter(data)}</SolTypography.Text> </>
    }


    const renderCustomerProfile =()=>{
        return <>
            <div className={'col-md-6'}>
                <SolTypography>
                    <SolTypography.Title primary>{customerDetails.name || "N/A"} &nbsp;{customerDetails.node_type?<CustomerType>{customerTypes[customerDetails.node_type.toString().toLowerCase()]}</CustomerType>:null} </SolTypography.Title>
                    <SolTypography.SubTitle>{displayBangladeshiMobileNo(customerDetails.phone)}</SolTypography.SubTitle>
                    <SolTypography.SubTitle>{customerDetails?.occupation || "N/A"}, {customerDetails?.income_range?.split("-")[0] || "N/A"} ~ {customerDetails?.income_range?.split("-")[1] || "N/A"} Tk Per Month Income</SolTypography.SubTitle>
                    <SolTypography.SubTitle>{getApplianceString(customerDetails?.appliance_info)? `${getApplianceString(customerDetails?.appliance_info)} | Total Wattage: ${isNumber(customerDetails?.total_wattage)? `${customerDetails?.total_wattage}w`: "N/A"}`: `Total Wattage: ${isNumber(customerDetails?.total_wattage)? `${customerDetails?.total_wattage}w`: "N/A"}` }</SolTypography.SubTitle>
                    <SolTypography.SubTitle primary>
                        <Link to={`/grids/details/${customerDetails.grid_guid}`} target={'_blank'} style={{ color: "#F18D00",}}>
                            {/*{customerDetails.grid_name || 'N/A'}- { customerDetails?.address?.district || "N/A" }- { customerDetails?.address?.division || "N/A" }*/}
                            {getGridNameString(customerDetails)}
                        </Link>
                    </SolTypography.SubTitle>
                </SolTypography>
            </div>

            <div className={'col-md-6'}>
                <SolTypography>
                    <SolTypography.Title primary>&nbsp;</SolTypography.Title>
                    <SolTypography.Title >Currently using SOLbox <SolTypography.Text primary>
                       <Link to={`/solboxes/profile/${props.match.params.solbox_serial_number}/${props.match.params.solbox_guid}`}
                             target={'_blank'}
                             style={{ color: "#F18D00",}}>
                           &nbsp;{props.match.params.solbox_serial_number}
                       </Link>
                    </SolTypography.Text> </SolTypography.Title>
                    <SolTypography.SubTitle>Firmware: {customerDetails.solbox_fw_version || "N/A"}</SolTypography.SubTitle>
                    <SolTypography.SubTitle>{getOnlineStatusString()} &nbsp; <OnlineStatus online={onlineStatus}/>  </SolTypography.SubTitle>
                    <SolTypography.SubTitle>Balance: {getBalanceData()}</SolTypography.SubTitle>
                    <SolTypography.SubTitle>Tariff: {getTariffInfo(customerDetails)}</SolTypography.SubTitle>
                    <SolTypography.SubTitle>Exchange Control : {/*{getExhangeControl()}*/} {formatExchangeControl(customerDetails.exchange_control) || "No Data Found"} </SolTypography.SubTitle>
                    <SolTypography.SubTitle>Threshold Value: {isNumber(customerDetails.threshold_value)?customerDetails.threshold_value:"No Data Found"}</SolTypography.SubTitle>
                </SolTypography>
            </div>
        </>
    }



    const  getOnlineStatusString=()=> {


        if (liveDataFetched===requestCycle.success && !incompatibleFirmwareVersion(solboxDetails?.solbox_fw_version))
        {
            if (onlineStatus)
            {

                // alert('asdasd');
                return `Online at ${liveData && isNumber(liveData.u8UserMode)? moods[liveData.u8UserMode]:'N/A'} Mode`
            }
            else{
                return "Offline"
            }

        }else{
            if (onlineStatus)
            {

                // alert('fff');
                return `Online at ${ legacyData && legacyData.solbox_mode? legacyData.solbox_mode:'N/A'} Mode`
            }
            else{
                return "Offline"
            }
        }
    }


    const  getRouterInfo=()=> {

        if (liveDataFetched===requestCycle.success && !incompatibleFirmwareVersion(solboxDetails?.solbox_fw_version))
        {
            return liveData?.ssid;

        }else{
            return legacyData?.solbox_ssid;
        }
    }


    const getGridNameString=(data)=>{
        let temp = '';
        temp = data?.grid_name? temp.concat(`${data.grid_name}`):temp.concat(`N/A`);
        temp = data?.grid_address?.district? temp.concat(`-${data.grid_address.district}`):temp.concat(``);
        temp = data?.grid_address?.division? temp.concat(`-${data.grid_address.division}`):temp.concat(``);
        temp = data?.iot_alias? temp.concat(`-${data?.iot_alias}`):temp.concat(``);

        return temp
    }

    /*Microutility Profile*/
    const renderMUProfile=()=>{
        return <>
            <div className={'col-md-6'}>
                <SolTypography>
                    <SolTypography.Title primary> Microutility - {props.match.params.solbox_serial_number.toString().slice(4) ||  "N/A"} &nbsp;{muDetails.node_type?<CustomerType>{customerTypes[muDetails.node_type.toString().toLowerCase()]}</CustomerType>:null} </SolTypography.Title>
                    <SolTypography.SubTitle primary>
                        <Link to={`/grids/details/${muDetails?.grid_guid}`} target={'_blank'}
                              style={{ color: "#F18D00",}}>
                            {getGridNameString(muDetails)}
                        </Link>
                    </SolTypography.SubTitle>

                </SolTypography>
            </div>

            <div className={'col-md-6'}>
                <SolTypography>
                    <SolTypography.Title primary>&nbsp;</SolTypography.Title>
                    <SolTypography.Title >Currently using SOLbox <SolTypography.Text primary>
                       <Link to={`/solboxes/profile/${props.match.params.solbox_serial_number}/${props.match.params.solbox_guid}`}
                             target={'_blank'}
                             style={{ color: "#F18D00",}}>
                           &nbsp;{props.match.params.solbox_serial_number}
                       </Link>
                    </SolTypography.Text> </SolTypography.Title>
                    <SolTypography.SubTitle style={{
                        marginTop: '9px',
                    }}>Firmware:  {muDetails.solbox_fw_version || 'N/A'}</SolTypography.SubTitle>
                    <SolTypography.SubTitle> Model: {muDetails.solbox_model || 'N/A'} </SolTypography.SubTitle>
                    <SolTypography.SubTitle> {getOnlineStatusString()} &nbsp; <OnlineStatus online={onlineStatus}/>  </SolTypography.SubTitle>
                    <SolTypography.SubTitle style={{
                        marginTop: '9px',
                    }}>Nearby customer: <SolTypography.Text primary > <Link  style={{ color: "#F18D00",}}
                                                                             target={'_blank'}
                                                                             to={`/customers/profile/${props.match.params.customer_guid}/${props.match.params.solbox_guid}/${props.match.params.solbox_serial_number}/${solboxDetails?.node_type}`}
                    >
                        {muDetails.customer_shs_info.customer_name}
                    </Link> </SolTypography.Text> ({muDetails.customer_shs_info.customer_phone})  </SolTypography.SubTitle>
                    <SolTypography.SubTitle>Router: {getRouterInfo() || 'No Data Found'}</SolTypography.SubTitle>
                    <SolTypography.SubTitle>SHS Setup: {getShsSetupString(muDetails)}</SolTypography.SubTitle>
                </SolTypography>
            </div>
        </>
    }



    /*Logic to render the MU or Customer Profile*/
    const renderDetails=()=>{
        if (!customerDetails && !muDetails)
        {
            return <div className={'d-flex justify-content-center'}><h1><Badge variant="secondary">No Data Available</Badge></h1></div>
        }else if (customerDetails && solboxDetails && solboxDetails.node_type!=='Microutility') {
            return <>
                <div className={'col-md-12 no-padding mb-5'} >
                    <div className={'row'}>
                        {renderCustomerProfile()}
                    </div>
                </div>
            </>
        }else if(muDetails && solboxDetails && solboxDetails.node_type==='Microutility'){
            return <>
                <div className={'col-md-12 no-padding mb-5'} >
                    <div className={'row'}>
                        {renderMUProfile()}
                    </div>
                </div>
            </>
        }else {
            return <div className={'d-flex justify-content-center'}><h1><Badge variant="secondary">No Data Available</Badge></h1></div>
        }

    }


    /*Back Button for header*/
    const BackButton = <Button size={'sm'} variant={'secondary'} className={'btn-outline-warning'} onClick={backFunction}>
        <i className="fa fa-chevron-left"/> &nbsp;Go Back</Button>;


    return (
        <div>
            <ContentWrapper permissionDenied={false} showCardHeader={false} pageTitle={BackButton} showBackButton={null} statusCode={null} isLoading={isLoading }>

                <CustomerProfileContainer>
                    {detailsLoading || loading?<>
                        <Loader
                            text={ !solboxDetails? "Fetching Data..."  :solboxDetails.node_type!=='Microutility'?'Fetching Customer Data...':'Fetching Microutility Data...'}
                            size={loading?'sm':undefined}
                            loadingSubText={liveDataLoadingMessage}
                        /></>
                        :renderDetails()}

                </CustomerProfileContainer>


                <div className={'col-md-12 no-padding'}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => toggleCustomerProfileTab(k)}
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        {/*<Tab eventKey="trading" title="Trading" tabClassName={'sol-tab'}>*/}
                        {/*    <TabContainer>*/}
                        {/*        <h1>TBD</h1>*/}
                        {/*    </TabContainer>*/}
                        {/*</Tab>*/}
                        {/*<Tab eventKey="active_solbox" title="Active SOlbox" tabClassName={'sol-tab'}>*/}
                        {/*    <TabContainer>*/}
                        {/*        <h1>TBD</h1>*/}
                        {/*    </TabContainer>*/}
                        {/*</Tab>*/}
                        <Tab eventKey="transactions" title="Transactions"  tabClassName={'sol-tab'}>
                            <TabContainer>
                                <CustomerProfileTransactions solboxId={props.match.params.solbox_serial_number} />
                            </TabContainer>
                        </Tab>
                        <Tab eventKey="replacement" title="SOLbox Replacement" tabClassName={'sol-tab'}>
                            <TabContainer>
                                <NodeReplacementList solboxGuid={props.match.params.solbox_guid } />
                            </TabContainer>
                        </Tab>
                    </Tabs>
                </div>
            </ContentWrapper>
            {/*<style jsx>{`*/}
            {/*  .sol-tab.active{*/}
            {/*          color: #F18D00 !important;*/}
            {/*  }*/}
            {/*  .sol-tab:hover{*/}
            {/*    color: #12100C !important;*/}
            {/*  }*/}
            {/*  .sol-tab{*/}
            {/*    border-radius: 7px !important;*/}
            {/*  }*/}
            {/*`}</style>*/}
        </div>
    );
};

CustomerProfile.propTypes = {
};




export default  connect(({customerReducer,commonReducer})=>({...customerReducer, ...commonReducer}), {...customerActions,...commonActions})(CustomerProfile) ;
