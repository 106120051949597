export const customerActionTypes = {

    FETCH_GRID_LIST_REQUEST: 'FETCH_GRID_LIST_REQUEST',
    FETCH_GRID_LIST_SUCCESS: 'FETCH_GRID_LIST_SUCCESS',
    FETCH_GRID_LIST_FAILURE: 'FETCH_GRID_LIST_FAILURE',

    FETCH_ALL_CUSTOMER_REQUEST: 'FETCH_ALL_CUSTOMER_REQUEST',
    FETCH_ALL_CUSTOMER_SUCCESS: 'FETCH_ALL_CUSTOMER_SUCCESS',
    FETCH_ALL_CUSTOMER_FAILURE: 'FETCH_ALL_CUSTOMER_FAILURE',

    FETCH_FILTERED_CUSTOMER_REQUEST: 'FETCH_FILTERED_CUSTOMER_REQUEST',
    FETCH_FILTERED_CUSTOMER_SUCCESS: 'FETCH_FILTERED_CUSTOMER_SUCCESS',
    FETCH_FILTERED_CUSTOMER_FAILURE: 'FETCH_FILTERED_CUSTOMER_FAILURE',

    FETCH_SEARCHED_CUSTOMER_REQUEST: 'FETCH_SEARCHED_CUSTOMER_REQUEST',
    FETCH_SEARCHED_CUSTOMER_SUCCESS: 'FETCH_SEARCHED_CUSTOMER_SUCCESS',
    FETCH_SEARCHED_CUSTOMER_FAILURE: 'FETCH_SEARCHED_CUSTOMER_FAILURE',

    FETCH_CUSTOMER_DETAILS_REQUEST: 'FETCH_CUSTOMER_DETAILS_REQUEST',
    FETCH_CUSTOMER_DETAILS_SUCCESS: 'FETCH_CUSTOMER_DETAILS_SUCCESS',
    FETCH_CUSTOMER_DETAILS_FAILURE: 'FETCH_CUSTOMER_DETAILS_FAILURE',

    REPLACEMENT_RETRY_FROM_NODE_REQUEST: 'REPLACEMENT_RETRY_FROM_NODE_REQUEST',
    REPLACEMENT_RETRY_FROM_NODE_SUCCESS: 'REPLACEMENT_RETRY_FROM_NODE_SUCCESS',
    REPLACEMENT_RETRY_FROM_NODE_FAILURE: 'REPLACEMENT_RETRY_FROM_NODE_FAILURE',



    MU_PROFILE_DETAILS_REQUEST: 'MU_PROFILE_DETAILS_REQUEST',
    MU_PROFILE_DETAILS_SUCCESS: 'MU_PROFILE_DETAILS_SUCCESS',
    MU_PROFILE_DETAILS_FAILURE: 'MU_PROFILE_DETAILS_FAILURE',


    REPLACEMENT_LIST_FOR_SPECIFIC_NODE_REQUEST: 'REPLACEMENT_LIST_FOR_SPECIFIC_NODE_REQUEST',
    REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SEPARATE_LOADER_REQUEST: 'REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SEPARATE_LOADER_REQUEST',
    REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SUCCESS: 'REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SUCCESS',
    REPLACEMENT_LIST_FOR_SPECIFIC_NODE_FAILURE: 'REPLACEMENT_LIST_FOR_SPECIFIC_NODE_FAILURE',

    FETCH_TRANSACTION_TYPES_REQUEST: 'FETCH_TRANSACTION_TYPES_REQUEST',
    FETCH_TRANSACTION_TYPES_SUCCESS: 'FETCH_TRANSACTION_TYPES_SUCCESS',
    FETCH_TRANSACTION_TYPES_FAILURE: 'FETCH_TRANSACTION_TYPES_FAILURE',

    GET_TRANSACTION_HISTORY_LIST_REQUEST: 'GET_TRANSACTION_HISTORY_LIST_REQUEST',
    GET_TRANSACTION_HISTORY_LIST_SUCCESS: 'GET_TRANSACTION_HISTORY_LIST_SUCCESS',
    GET_TRANSACTION_HISTORY_LIST_FAILURE: 'GET_TRANSACTION_HISTORY_LIST_FAILURE'
};