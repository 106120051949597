export const replacementActionTypes = {

    /*Replacement additional Info*/
    FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_REQUEST: 'FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_REQUEST',
    FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_SUCCESS: 'FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_SUCCESS',
    FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_FAILURE: 'FETCH_REPLACEMENT_RELATED_ADDITIONAL_INFO_FAILURE',

    NODES_UPDATE_SOLBOX_CONFIGURATION_REQUEST: 'NODES_UPDATE_SOLBOX_CONFIGURATION_REQUEST',
    NODES_UPDATE_SOLBOX_CONFIGURATION_SUCCESS: 'NODES_UPDATE_SOLBOX_CONFIGURATION_SUCCESS',
    NODES_UPDATE_SOLBOX_CONFIGURATION_FAILURE: 'NODES_UPDATE_SOLBOX_CONFIGURATION_FAILURE',

    CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_REQUEST: 'CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_REQUEST',
    CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_SUCCESS: 'CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_SUCCESS',
    CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_FAILURE: 'CHECK_SERIAL_NUMBER_FOR_REPLACEMENT_FAILURE',

    REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_REQUEST: 'REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_REQUEST',
    REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_SUCCESS: 'REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_SUCCESS',
    REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_FAILURE: 'REPLACE_REQUEST_FOR_SOLBOX_FOR_REPLACEMENT_FAILURE',

    SEND_CONFIG_FOR_REPLACEMENT_REQUEST: 'SEND_CONFIG_FOR_REPLACEMENT_REQUEST',
    SEND_CONFIG_FOR_REPLACEMENT_SUCCESS: 'SEND_CONFIG_FOR_REPLACEMENT_SUCCESS',
    SEND_CONFIG_FOR_REPLACEMENT_FAILURE: 'SEND_CONFIG_FOR_REPLACEMENT_FAILURE',

    SEND_REIMBURESEMENT_FOR_REPLACEMENT_REQUEST: 'SEND_REIMBURESEMENT_FOR_REPLACEMENT_REQUEST',
    SEND_REIMBURESEMENT_FOR_REPLACEMENT_SUCCESS: 'SEND_REIMBURESEMENT_FOR_REPLACEMENT_SUCCESS',
    SEND_REIMBURESEMENT_FOR_REPLACEMENT_FAILURE: 'SEND_REIMBURESEMENT_FOR_REPLACEMENT_FAILURE',

    GET_REPLACEMENT_LIST_REQUEST: 'GET_REPLACEMENT_LIST_REQUEST',
    GET_REPLACEMENT_LIST_WITH_SEPARATE_LOADER_REQUEST: 'GET_REPLACEMENT_LIST_WITH_SEPARATE_LOADER_REQUEST',
    GET_REPLACEMENT_LIST_SUCCESS: 'GET_REPLACEMENT_LIST_SUCCESS',
    GET_REPLACEMENT_LIST_FAILURE: 'GET_REPLACEMENT_LIST_FAILURE',

    GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_REQUEST: 'GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_REQUEST',
    GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_SUCCESS: 'GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_SUCCESS',
    GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_FAILURE: 'GET_REPLACEMENT_LIST_WITHOUT_PAGINATION_FAILURE',

    GET_GRID_OWNERS_LIST_REQUEST: 'GET_GRID_OWNERS_LIST_REQUEST',
    GET_GRID_OWNERS_LIST_SUCCESS: 'GET_GRID_OWNERS_LIST_SUCCESS',
    GET_GRID_OWNERS_LIST_FAILURE: 'GET_GRID_OWNERS_LIST_FAILURE',

    GET_MODELS_LIST_REQUEST: 'GET_MODELS_LIST_REQUEST',
    GET_MODELS_LIST_SUCCESS: 'GET_MODELS_LIST_SUCCESS',
    GET_MODELS_LIST_FAILURE: 'GET_MODELS_LIST_FAILURE',

    GET_NODE_TYPE_REQUEST: 'GET_NODE_TYPE_REQUEST',
    GET_NODE_TYPE_SUCCESS: 'GET_NODE_TYPE_SUCCESS',
    GET_NODE_TYPE_FAILURE: 'GET_NODE_TYPE_FAILURE',

    REPLACEMENT_RETRY_REQUEST: 'REPLACEMENT_RETRY_REQUEST',
    REPLACEMENT_RETRY_SUCCESS: 'REPLACEMENT_RETRY_SUCCESS',
    REPLACEMENT_RETRY_FAILURE: 'REPLACEMENT_RETRY_FAILURE',

    GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_REQUEST: 'GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_REQUEST',
    GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_SUCCESS: 'GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_SUCCESS',
    GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_FAILURE: 'GET_GRIDS_WITHOUT_WITHOUT_PAGINATION_FAILURE',

    NODE_PROCESS_FAILED: 'NODE_PROCESS_FAILED',
    SET_REIMBURSEMENT_SUCCESSFUL_TO_UNDEFINED: 'SET_REIMBURSEMENT_SUCCESSFUL_TO_UNDEFINED',
    SET_REPLACEMENT_PHASES_TO_UNDEFINED: 'SET_REPLACEMENT_PHASES_TO_UNDEFINED',
    SET_CONFIRMATION_FLAG_TO_UNDEFINED: 'SET_CONFIRMATION_FLAG_TO_UNDEFINED',
    SET_BALANCE_DATA_FLAG_TO_UNDEFINED: 'SET_BALANCE_DATA_FLAG_TO_UNDEFINED',

    UPDATE_SOLBOX_BALANCE_REQUEST: 'UPDATE_SOLBOX_BALANCE_REQUEST',
    UPDATE_SOLBOX_BALANCE_SUCCESS: 'UPDATE_SOLBOX_BALANCE_SUCCESS',
    UPDATE_SOLBOX_BALANCE_FAILURE: 'UPDATE_SOLBOX_BALANCE_FAILURE'
}