import React, { useState, useEffect } from 'react';
import { tradingActions }   from "../tradingActions";
import { connect } from 'react-redux';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import SolSelect from "../../../components/SolSelect";
import SolDaterangePicker from "../../../components/SolDaterangePicker";
import moment from "moment";

const TradingInsights = (props) => {

    const {
        isLoading,
        gridOwnerList,
        gridsList,
        isGridListLoading,
        GET_GRID_OWNERS_LIST,
        GET_GRID_LIST
    } = props;

    const [gridOwner, setGridOwner] = useState("");
    const [grid, setGrid] = useState("");
    const [gridOwnersOptions, setGridOwnersOptions] = useState([]);
    const [gridListOptions, setGridListOptions] = useState([]);
    const [compareStartDate, setCompareStartDate] = useState(null);
    const [compareEndDate, setCompareEndDate] = useState(null);
    const [withStartDate, setWithStartDate] = useState(null);
    const [withEndDate, setWithEndDate] = useState(null);
    const [compareDateRange, setCompareDateRange] = useState("");
    const [withDateRange, setWithDateRange] = useState("");

    const handleChangeGridOwner = (gridOwner) => {
        setGridOwner(gridOwner);
    }

    const handleChangeGrid = (grid) => {
        setGrid(grid);
    }

    const handleCompareDateRange = (startDate, endDate) => {
        setCompareStartDate(startDate);
        setCompareEndDate(endDate);
    }

    useEffect(() => {
        if (compareStartDate !== undefined &&
            compareEndDate !== undefined &&
            compareStartDate !== null &&
            compareEndDate !== null
        ) {
            setCompareDateRange(
                            moment(compareStartDate._d).format('LL')
                            .concat(" - ", moment(compareEndDate._d).format('LL'))
                        );
        }
    }, [compareStartDate, compareEndDate]);

    const handleDateRangeValidation = (day) => {
        if (compareEndDate) {
          return day.startOf('day').isAfter(moment()) || day.startOf('day').isBefore(moment(compareEndDate).add(1, "days"));
        }
        return day.startOf('day').isAfter(moment());
    }

    const handleWithDateRange = (startDate, endDate) => {
        setWithStartDate(startDate);
        setWithEndDate(endDate);
    }

    useEffect(() => {
        if (withStartDate !== undefined &&
            withEndDate !== undefined &&
            withStartDate !== null &&
            withEndDate !== null
        ) {
            setWithDateRange(
                            moment(withStartDate._d).format('LL')
                            .concat(" - ", moment(withEndDate._d).format('LL'))
                        );
        }
    }, [withStartDate, withEndDate]);

    useEffect(() => {
        GET_GRID_OWNERS_LIST();
    }, []);

    useEffect(() => {
        if (gridOwnerList && gridOwnerList.length !== 0) {
            let gridOwnerArr = [];
            gridOwnerList.forEach((element, index) => {
                if (element.organization_guid && element.organization_name) {
                    gridOwnerArr.push({
                        value: element.organization_guid,
                        label: element.organization_name
                    });
                }
            });
            setGridOwnersOptions(gridOwnerArr);
        }
    }, [gridOwnerList]);

    console.log(gridOwnerList);

    useEffect(() => {
        if (gridOwner && gridOwner.value) {
            GET_GRID_LIST(gridOwner.value);
        }
    }, [gridOwner]);

    console.log(gridsList);

    useEffect(() => {
        if (gridsList) {
            let gridArr = [];
            gridsList.forEach((element, index) => {
                if (element.grid_guid && element.name) {
                    gridArr.push({
                        value: element.grid_guid,
                        label: element.name
                    });
                }
            });
            setGridListOptions(gridArr);
        }
    }, [gridsList]);

    const resetFilterForm = (event) => {
        setGridOwner("");
        setGrid("");
        setCompareStartDate(null);
        setCompareEndDate(null);
        setCompareDateRange("");
        setWithStartDate(null);
        setWithEndDate(null);
        setWithDateRange("");
    }

    return (
        <ContentWrapper
            showBackButton={false}
            permissionDenied={false}
            pageTitle={""}
            //statusCode={statusCode} 
            isLoading={false}
            showCardHeader={false}
        >
            <div className="row">
                <div className="col-12 mb-5">
                    <h3 className="list-bold-text">Trend Report of</h3>
                </div>
            </div>
            <div className="row">
                <div className={`col-sm-3 mt-5 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Grid Operator`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Grid Operator`}
                        value={gridOwner}
                        handleChangeSolSelect={handleChangeGridOwner}
                        options={gridOwnersOptions}
                        selectMulti={false}
                        loading={isLoading}
                        clearable={true}
                        noOptionsMessage={() => `No grid operator available`}
                    />
                </div>
                <div className={`col-sm-3 mt-5 mb-2`}>
                    <label className={`form-check-label normal-text mb-5`}>
                        {`Grid`}
                    </label>
                    <SolSelect
                        placeHolder={`Select Grid`}
                        value={grid}
                        handleChangeSolSelect={handleChangeGrid}
                        options={gridListOptions}
                        selectMulti={false}
                        loading={isGridListLoading}
                        clearable={true}
                        noOptionsMessage={() => `No grids available`}
                    />
                </div>
            </div>
            <div className="row">
                <div className={`col-sm-3 mt-5 mb-2`}>
                    <SolDaterangePicker
                        dateRangelabel={`Compare`}
                        //customLabel={`normal-text`}
                        startDate={compareStartDate}
                        endDate={compareEndDate}
                        dateRange={compareDateRange}
                        handleDateRange={handleCompareDateRange}
                    />
                </div>
                <div className={`col-sm-3 mt-5 mb-2`}>
                    <SolDaterangePicker
                        dateRangelabel={`With`}
                        //customLabel={`normal-text`}
                        startDate={withStartDate}
                        endDate={withEndDate}
                        dateRange={withDateRange}
                        handleDateRange={handleWithDateRange}
                        dateRangeValidation={handleDateRangeValidation}
                    />
                </div>
            </div>
            <div className={`row mb-5`}>
                <div className={`col-12 mt-1`}>
                    <button
                        type={`button`}
                        className={'btn btn-outline-warning btn-sm mb-5'}
                        onClick={(event) => alert("Report will be generated soon!")}
                    >
                        {`Generate Report`}
                    </button>
                    <button
                        type={`button`}
                        className={'btn btn-outline-secondary btn-sm ml-4 mb-5 list-filter-reset-button'}
                        onClick={(event) => resetFilterForm(event)}
                    >
                        {`Reset Filter`}
                    </button>
                </div>
            </div>
        </ContentWrapper>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.tradingReducer.isLoading,
        gridOwnerList: state.tradingReducer.gridOwnerList,
        gridsList: state.tradingReducer.gridsList,
        isGridListLoading: state.tradingReducer.isGridListLoading
    }
};

export default connect(mapStateToProps, tradingActions)(TradingInsights);