import React, {Fragment} from "react";
import { ExportToCsv } from 'export-to-csv';
import DateTimeFormat from "../../utils/dateFormat/DateTimeFormat";
import styled from "styled-components";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "../../components/utilityComponents/SOlTypography";
import {isNumber} from "../../utils/utilityFunctions";
import moment from "moment";

export const exportGridListToCsv=(data)=>{

    const getTariffString=(tariff)=>{
        if (tariff) {
            return `Buy at ৳ ${tariff.buy_price || 'N/A'}, Sell at ৳ ${tariff.sell_price || 'N/A'} `
        }
    }

    const getTotalNumber=(grid)=>{
        let total = 0;
        const tempArray = ['consumer', 'prosumer', 'producer', 'microutility'];
        tempArray.forEach((item)=>{
            if (grid && isNumber(grid[item])){
                total = total + parseFloat(grid[item]);
            }
        });
        return total;
    }


    const grids = data.map((grid, index)=>{
        return {
            name: grid.name || "N/A",
            address: `${grid.address.sub_district || 'N/A'} - ${grid.address.district || 'N/A'}`,
            consumer: grid.consumer || "0",
            prosumer: grid.prosumer || "0",
            microutility: grid.microutility || "0",
            producer: grid.producer || "0",
            total: getTotalNumber(grid) ,
            tariff: getTariffString(grid.current_tariff),

        }
    });

    let currentDate = moment().format("DD_MMM_YYYY")
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: `` ,
        filename: `Grid_list_` + currentDate,
        useTextFile: false,
        useBom: true,
        headers: ['Name', 'Address', 'Consumer', 'Prosumer', 'MU','Producer', 'Total', 'Tariff'],
    };

    const csvExporter = new ExportToCsv(options);


    csvExporter.generateCsv(grids.length>0?grids:[{}]);


}

export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`


export const LoadingSpinner=({loadingSubText, size})=>{
    return <Fragment>
        <div className="align-content-center mt-5"  align="center">
            <h2 className={`mb-5`}>
                <span>{`Fetching Data`}</span>
            </h2>
            <Spinner animation="border"  size={size || 'sm'} variant="primary" />
            <br/>
            <SolTypography.SubTitle primary> {loadingSubText} </SolTypography.SubTitle>

        </div>

    </Fragment>
}


export const requestCycle = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
}
