import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {divisionOptions} from "../../../utils/countryData/solDivision";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {ThemeProvider} from '@material-ui/styles';
import {toast} from "react-toastify"
import {isNullorUndefined, isNumber, validateValue} from "../../../utils/utilityFunctions";
import moment from "moment";
import {withRoles} from "../../../router/SecuredRoute";
import {DayPickerRangeController} from "react-dates";
import {SolInput} from "../../../components/SolStyledComponents/components";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});

export const Toolbar = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: .40rem .55rem .40rem .55rem;
  background-color: #ffffff;
  /* Global/01. Primary */
  border: ${props => !props.dullComponent ? '1px solid #F18D00' : '1px solid #8C8C9B'};
  box-sizing: border-box;
  border-radius: 4px;


`;

Toolbar.ToolbarContainer = styled.div`
  grid-column: 10/-1;
`;
Toolbar.Title = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1/9;
`
Toolbar.ToolbarContainer.Items = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
`;


export const Filter = styled.div`
  margin: 35px 0 0 0;
  padding: 0;
`;

const FormContainer = ({gridOwners, setListFilters, modelList, gridList, reloadGridList, gridOptionLoading,
                           gridOwnerOptionLoading, modelOptionLoading, defaultStartDate, defaultEndDate, resetFilter, ...props}) => {
    const {register, handleSubmit, errors, setError, clearErrors, control} = useForm();
    const [calendarView] = useState(['year', 'month']);
    const [grid, setGrid] = useState(null);
    const [model, setModel] = useState(null);
    const [status, setStatus] = useState(null);
    const [org, setOrg] = useState(null);
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [filter, setFilter] = useState({});
    const [initial1filterParam, setInitial1filterParam] = useState(null);
    const [filterApplied, setFilterApplied] = useState(false)

    const statusOptions = [
        {
            label: "Successful",
            value: "successful"
        },
        {
            label: "In progress",
            value: "inprogress"
        },
        {
            label: "Uninitiated",
            value: "uninitiated"
        },
        {
            label: "Failed",
            value: "failed"
        }
    ]

    const validationKeyArray = [
        {
            parameter: 'organization_guid',
            list: gridOwners
        },
        {
            parameter: 'grid_guid',
            list: gridList
        },
        {
            parameter: 'solbox_model',
            list: modelList
        },
        {
            parameter: 'status',
            list: statusOptions
        },
        {
            parameter: 'ota_date',
            validationFunction: (keyword) => keyword,
        }
    ];

    useEffect(() => {
        let keys = null;
        let validParameters = {};

        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(props.projectedParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {

            /* Looping through each of the value in the keys. (Essentially This loop will run for every keys in the projected params object) */

            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const currentIndex = validationKeyArray.findIndex((element) => {
                    return element.parameter === item;
                });

                if (currentIndex > -1 && !isNullorUndefined(validateValue(props.projectedParams[item], validationKeyArray[currentIndex]))) {
                    Object.assign(validParameters, {[`${item}`]: validateValue(props.projectedParams[item], validationKeyArray[currentIndex])})
                }
            })
        }

        setInitial1filterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)

        /*Now we validated the */


        /* Now if the valid Params object has at least one parameter we set the filter */

    }, [props.projectedParams]);

    useEffect(() => {
        if (!isNullorUndefined(initial1filterParam)) {

            const frontEndFilterPayload = {}

            // TODO: Need to check the impact of the 'frontEndFilterPayload'
            // TODO: Update the followings
            // TODO: Also need to verify the keys from the API
            if (initial1filterParam.organization_guid) {
                setOrg(initial1filterParam.organization_guid);
                Object.assign(frontEndFilterPayload, {organization_guid: initial1filterParam.organization_guid.value,})
            }

            if (initial1filterParam.grid) {
                setGrid(initial1filterParam.grid);
                Object.assign(frontEndFilterPayload, {grid_guid: initial1filterParam.grid.value})
            }

            if (initial1filterParam.model) {
                setModel(initial1filterParam.model);
                Object.assign(frontEndFilterPayload, {solbox_model: initial1filterParam.model.value})
            }

            if (initial1filterParam.month && initial1filterParam.year) {
                Object.assign(frontEndFilterPayload, {month: parseInt(initial1filterParam.month)});
                Object.assign(frontEndFilterPayload, {year: initial1filterParam.year})
            }

            setFilter(frontEndFilterPayload)
            setListFilters(frontEndFilterPayload);
            setInitial1filterParam(null);
        }
    }, [initial1filterParam])

    const validateForm = (data) => {
        if (!dateRange) {
            // setError("validationError", {
            //     type: "manual",
            //     message: "Dont Forget Your Username Should Be Cool!"
            // });
            toast.error("Please select at least one filter")
            return false
        } else {
            return true
        }
    }

    const submit = (data) => {
        if (validateForm(data) === true) {
            let startDate = dateRange.split("-")[0].trim()
            let endDate = dateRange.split("-")[1].trim()
            const payload = {
                from_date: moment(startDate).format("YYYY-MM-DD"),
                to_date: moment(endDate).format("YYYY-MM-DD")
            }
            setFilterApplied(true)
            setFilter({
                ...payload,
            })
            setListFilters({
                ...payload,
            });
        }
    }

    useEffect(() => {
        setFilterApplied(false)
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    useEffect(()=>{
        setDateRange(`${defaultStartDate} - ${defaultEndDate}`)
    },[])

    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days')
        return current.isAfter(today)
    }

    const onDatesChange = ({startDate, endDate}) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start} // momentPropTypes.momentObj or null,
                    endDate={end} // momentPropTypes.momentObj or null,
                    // isOutsideRange={() => null}
                    numberOfMonths={2}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)} // PropTypes.func.isRequired,
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    );


    const resetForm = () => {
        setDateRange(`${defaultStartDate} - ${defaultEndDate}`)
        setStartDate(moment(defaultStartDate))
        setEndDate(moment(defaultEndDate))
        if (filterApplied) {
            resetFilter(true)
        } else {
            resetFilter(false)
        }
        // if (Object.keys(filter).length > 0) {
        //     // setGrid(null)
        //     // setModel(null)
        //     // setOrg(null)
        // }
    }


    return <form onSubmit={handleSubmit(submit)}>
        <div className={'col-md-12 no-padding'}>
            {/*<div className={'row'}>*/}
            {/*    <div className={'col-md-4'}>*/}
            {/*        <Select*/}
            {/*            name={`org`}*/}
            {/*            placeholder={`Grid owner`}*/}
            {/*            classNamePrefix="react-select-sol-style"*/}
            {/*            isDisabled={!props.isSolshare}*/}
            {/*            isLoading={gridOwnerOptionLoading}*/}
            {/*            maxMenuHeight={200}*/}
            {/*            isClearable={true}*/}
            {/*            options={gridOwners}*/}
            {/*            value={org}*/}
            {/*            control={control}*/}
            {/*            isSearchable={true}*/}
            {/*            onChange={(selected) => {*/}
            {/*                setOrg(selected)*/}
            {/*                clearErrors()*/}
            {/*                setGrid(null)*/}
            {/*                reloadGridList(selected)*/}
            {/*                return selected*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className={'col-md-4'}>*/}
            {/*        <Select*/}
            {/*            name={`grid`}*/}
            {/*            placeholder={`Grid`}*/}
            {/*            classNamePrefix="react-select-sol-style"*/}
            {/*            isDisabled={false}*/}
            {/*            isLoading={gridOptionLoading}*/}
            {/*            maxMenuHeight={200}*/}
            {/*            value={grid}*/}
            {/*            isClearable={true}*/}
            {/*            control={control}*/}
            {/*            options={gridList}*/}
            {/*            isSearchable={true}*/}
            {/*            onChange={(selected) => {*/}
            {/*                setGrid(selected)*/}
            {/*                clearErrors()*/}
            {/*                return selected*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className={'col-md-4'}>*/}
            {/*        <Select*/}
            {/*            name={`model`}*/}
            {/*            placeholder={`SOLbox model`}*/}
            {/*            classNamePrefix="react-select-sol-style"*/}
            {/*            isDisabled={false}*/}
            {/*            isLoading={modelOptionLoading}*/}
            {/*            maxMenuHeight={200}*/}
            {/*            value={model}*/}
            {/*            isClearable={true}*/}
            {/*            control={control}*/}
            {/*            options={modelList}*/}
            {/*            isSearchable={true}*/}
            {/*            onChange={(selected) => {*/}
            {/*                setModel(selected)*/}
            {/*                clearErrors()*/}
            {/*                return selected*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'row'} style={{marginTop: '1.5rem'}}>
                {/*<div className={'col-md-4'}>*/}
                {/*    <Select*/}
                {/*        name={`status`}*/}
                {/*        placeholder={`OTA status`}*/}
                {/*        classNamePrefix="react-select-sol-style"*/}
                {/*        isDisabled={false}*/}
                {/*        isLoading={false}*/}
                {/*        maxMenuHeight={200}*/}
                {/*        value={status}*/}
                {/*        isClearable={true}*/}
                {/*        control={control}*/}
                {/*        options={statusOptions}*/}
                {/*        isSearchable={true}*/}
                {/*        onChange={(selected) => {*/}
                {/*            setStatus(selected)*/}
                {/*            clearErrors()*/}
                {/*            return selected*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className={'col-md-4'}>
                    <OverlayTrigger rootClose trigger="click" placement="bottom-start" overlay={popover}>
                        <SolInput
                            name={'dateRangePickerInput'}
                            readOnly={true}
                            value={dateRange}
                            placeholder={`OTA date range`}
                        />
                    </OverlayTrigger>
                </div>
            </div>

            <div className={'col-md-3 no-padding'} style={{
                marginTop: '1.5rem',
            }}>


                <Button name={'filterSubmit'} className={'btn btn-outline-warning'} size={'sm'} id={'filterSubmit'}
                        type='submit'>
                    Apply Filter
                </Button>

                <Button name={'reset'} id={'reset'} size={'sm'}
                        className={'btn btn-outline-secondary reset-button-style'} type={'button'} dullComponent={true}
                        onClick={resetForm}>
                    Reset Filter
                </Button>
            </div>

        </div>

        <style jsx>{`
          .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active {
            border: 1px solid #F18D00;
            box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
            outline: none;
          }
        `}</style>
    </form>
}



export default withRoles(FormContainer)

