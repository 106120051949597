import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../../../components/dataTable/DataTable";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {Badge, Button, OverlayTrigger, Popover, Spinner, Tab, Tabs} from "react-bootstrap";
import Loader from "../../../../components/loader/Loader";
import {TabContainer} from "../../../../components/utilityComponents/utilityStyledComponents";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {Filter} from "../components";
import {DataTableContainer, getLoadingSpinner, LoadingSpinner} from '../../utils';
import {withRoles} from "../../../../router/SecuredRoute";
import moment from "moment";
import {DayPickerRangeController} from "react-dates";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {Link} from "react-router-dom";
import {requestCycle} from "../../utils";

const OtaUpdateHistory = (props) => {

    const [otaUpdateList, setOtaUpdateList] = useState([]);
    const [solboxSerialNumber, setSolboxSerialNumber] = useState(props.match.params.solbox_serial_number);
    const [solboxId, setSolboxId] = useState(props.match.params.solbox_serial_number.substring(4, 8));
    const [solboxGuid, setSolboxGuid] = useState(props.match.params.solbox_guid);
    const [page, setPage] = useState(null);

    const [espFirmwareVersion, setEspFirmwareVersion] = useState(null)
    const [otaFirmwareVersion, setOtaFirmwareVersion] = useState(null)
    const [firmwareVersionUpdateAt, setFirmwareVersionUpdateAt] = useState(null)
    const [otaAvailability, setOtaAvailability] = useState(null)
    const [otaStatus, setOtaStatus] = useState(null)
    const [incompatible, setIncompatible] = useState(false)
    const [solboxStatus, setSolboxStatus] = useState("")

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const [key, setKey] = useState('currentFirmware');

    const {
        GET_OTA_UPDATE_LIST,
        GET_OTA_UPDATE_LIST_WITH_FILTER,
        COLLECT_SOLBOX_OTA_DETAILS,
        SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE
    } = props;

    const defaultStartDate = moment().subtract(1, 'days').format("YYYY-MM-DD")
    const defaultEndDate = moment().format("YYYY-MM-DD")

    const otaUpdateListColumns=[
        {
            field: 'otaDate',
            title: "OTA Date",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'previousFw',
            title: "Previous FW",
            sorting: false,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            disableClick: true
        },
        {
            field: 'currentFw',
            title: "Current FW",
            sorting: false,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            disableClick: true
        },
        {
            field: 'esp_status',
            title: "ESP Status",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'left',
            },
            headerStyle: {
                textAlign: 'left',
            },
            // disableClick: true,
            render: (rowData) => {
                let espOtaStatus = rowData.device_esp_ota_status
                // let awsOtaStatus = rowData.aws_ota_status
                if (['pending', 'initiated', 'inprogress'].includes(espOtaStatus)) {
                    return (
                        <div style={{'white-space': 'nowrap'}}>
                         <span className="text-info">
                           In progress
                          </span>
                        </div>
                    )

                } else if (espOtaStatus === 'failed') {
                    return (
                        <span className={"text-danger"}>
                            Failed
                        </span>
                    )
                } else if (espOtaStatus === 'succeeded') {
                    return (
                        <span className={"text-success"}>
                            Successful
                        </span>
                    )
                } else if (espOtaStatus === 'cancelled') {
                    return (
                        <>Cancelled</>
                    )
                } else {
                    return (
                        <>Queued</>
                    )
                }
            }
        },
        {
            field: 'espCompletionDate',
            title: "ESP completion time",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'stm_status',
            title: "STM Status",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'left',
            },
            headerStyle: {
                textAlign: 'left',
            },
            // disableClick: true,
            render: (rowData) => {
                let deviceStmOtaStatus = rowData.device_stm_ota_status
                // let awsStmOtaStatus = rowData.aws_stm_ota_status
                if (deviceStmOtaStatus === 'inprogress') {
                    return (
                      <span className="text-info">
                        In progress
                      </span>
                    )
                } else if (deviceStmOtaStatus === 'failed') {
                    return (
                        <span className={"text-danger"}>
                            Failed
                        </span>
                    )
                } else if (deviceStmOtaStatus === 'succeeded') {
                    return (
                        <span className={"text-success"}>
                            Successful
                        </span>
                    )
                } else if (deviceStmOtaStatus) {
                    return (
                        <span style={{'text-transform': 'capitalize'}}>{deviceStmOtaStatus}</span>
                    )
                } else {
                    return (
                        <>Queued</>
                    )
                }
            }
        },
        {
            field: 'stmCompletionDate',
            title: "STM completion time",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'updatedBy',
            title: "Updated by",
            sorting: false,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                textAlign: 'right'
            },
            disableClick: true
        }
    ]

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])

    useEffect(()=>{
        setDateRange(`${moment(defaultStartDate).format("DD MMM, YYYY")} - ${moment(defaultEndDate).format("DD MMM, YYYY")}`)
        COLLECT_SOLBOX_OTA_DETAILS({"solbox_id": solboxId})
    },[])

    useEffect(()=>{
        const otaUpdates = props.otaUpdates;
        if (otaUpdates && otaUpdates.results.length > 0) {
            setOtaUpdateList(otaUpdates.results.map((otaUpdate, index) => {
                return {
                    ...otaUpdate,
                    previousFw: otaUpdate.previous_firmware,
                    currentFw: otaUpdate.current_firmware,
                    otaDate: otaUpdate.update_date? moment(otaUpdate.update_date).format('DD MMM, YYYY hh:mm A'): "N/A",
                    esp_status: otaUpdate,
                    espCompletionDate: otaUpdate.esp_ota_completion_time? moment(otaUpdate.esp_ota_completion_time).format('DD MMM, YYYY hh:mm A'):"N/A",
                    stm_status: otaUpdate,
                    stmCompletionDate: otaUpdate.stm_ota_completion_time? moment(otaUpdate.stm_ota_completion_time).format('DD MMM, YYYY hh:mm A'):"N/A",
                    updatedBy: otaUpdate.updated_by_name
                }
            }))
        }
    },[props.otaUpdates]);

    useEffect(()=>{
        const otaDetails = props.otaDetails;
        if (otaDetails) {
            let solboxFW = otaDetails.solbox_fw_version.split("_")[1]
            if (parseInt(solboxFW.split(".")[0]) < 7) {
                setIncompatible(true)
            }
            // TODO: Following status should be generated based on live data if the SOLbox firmware is greater than 5
            // TODO: But as it arising confusion, we have hidden the status from UI
            setSolboxStatus(otaDetails.solbox_status === 'online'? true:false)
            setOtaFirmwareVersion(otaDetails.ota_firmware_version)
            setFirmwareVersionUpdateAt(otaDetails.fw_version_updated_at? moment(otaDetails.fw_version_updated_at).format("DD MMMM, YYYY"): 'N/A')
            setOtaAvailability(otaDetails.ota_available)
            if (otaDetails.last_ota_history) {
                if (otaDetails.ota_available) {
                    let deviceEspOtaStatus = otaDetails.last_ota_history.device_esp_ota_status
                    let deviceStmOtaStatus = otaDetails.last_ota_history.device_stm_ota_status
                    let status
                    if (deviceEspOtaStatus === 'succeeded' && deviceStmOtaStatus === 'succeeded') {
                        status = "Succeeded"
                    } else if (deviceEspOtaStatus === 'failed' || deviceStmOtaStatus === 'failed') {
                        status = "Failed"
                    } else if (deviceEspOtaStatus === 'cancelled' || deviceStmOtaStatus === 'cancelled') {
                        status = "Cancelled"
                    } else {
                        status = "In progress"
                    }
                    setOtaStatus(status)
                    setEspFirmwareVersion(otaDetails.last_ota_history.previous_firmware)
                    if (status === "In progress") {
                        setOtaAvailability(false)
                    }
                } else {
                    setEspFirmwareVersion(otaDetails.last_ota_history.current_firmware)
                }
            } else {
                setEspFirmwareVersion(solboxFW)
            }
        }
    },[props.otaDetails]);

    useEffect(()=>{
        if (props.otaUpdateStatus === requestCycle.success) {
            setOtaStatus("In progress")
            setOtaAvailability(false)
        }
    }, [props.otaUpdateStatus]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        // As there will always be some dates selected
        let [fromDate, toDate] = getDates(dateRange)
        let payload = {'pagination': 'True', 'from_date': fromDate, 'to_date': toDate, 'solbox_id': solboxId}
        GET_OTA_UPDATE_LIST_WITH_FILTER({...payload, page: newPage})
    }

    const getDates = (dateRange) => {
        let startDate = dateRange.split("-")[0].trim()
        let endDate = dateRange.split("-")[1].trim()

        return [moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")]
    }

    const handleTabSwitching = (tab) => {
        setKey(tab)
        if (tab==="otaUpdateHistory") {
            let fromDate, toDate = defaultStartDate, defaultEndDate
            if (dateRange) {
                [fromDate, toDate] = getDates(dateRange)
            }
            GET_OTA_UPDATE_LIST({'pagination': 'True', 'from_date': fromDate, 'to_date': toDate, 'solbox_id': solboxId})
        } else {
            COLLECT_SOLBOX_OTA_DETAILS({"solbox_id": solboxId})
        }
    }

    const sendOtaUpdate = () => {
        let payload = {
            "solboxes": [solboxId],
            "firmware_version": otaFirmwareVersion
        }
        SEND_OTA_UPDATE_FROM_CURRENT_FIRMWARE_PAGE(payload)
    }

    const applyFilter = () => {
        let [fromDate, toDate] = getDates(dateRange)
        GET_OTA_UPDATE_LIST({'pagination': 'True', 'from_date': fromDate, 'to_date': toDate, 'solbox_id': solboxId});
    }

    const resetFilter = () => {
        setOtaUpdateList([])
        setDateRange(`${moment(defaultStartDate).format("DD MMM, YYYY")} - ${moment(defaultEndDate).format("DD MMM, YYYY")}`)
        setStartDate(moment(defaultStartDate))
        setEndDate(moment(defaultEndDate))
        GET_OTA_UPDATE_LIST({'pagination': 'True', 'from_date': defaultStartDate, 'to_date': defaultEndDate, 'solbox_id': solboxId});
    }

    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days')
        return current.isAfter(today)
    }

    const onDatesChange = ({startDate, endDate}) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start} // momentPropTypes.momentObj or null,
                    endDate={end} // momentPropTypes.momentObj or null,
                    // isOutsideRange={() => null}
                    numberOfMonths={2}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)} // PropTypes.func.isRequired,
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    );


    return (
        <div>
            <div className={'col-md-12'}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => handleTabSwitching(k)}
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    <Tab eventKey="currentFirmware" title="Current FW" tabClassName={'sol-tab'}>
                        <TabContainer className={"pl-4"}>
                            {props.collectingOtaDetails? <LoadingSpinner loadingSubText={"Collecting details.."}/>:
                                <>
                                    <div className={"row"}>
                                        <label className={"col-md-2"} htmlFor={"esp-firmware-version"}>SOLbox</label>
                                        <div className={"col-md-10"} id={"esp-firmware-version"}>
                                            <Link
                                                target="_blank"
                                                to={`/solboxes/profile/${solboxSerialNumber}/${solboxGuid}`}
                                                style={{color: "#F18D00"}}
                                            >
                                                <span className="d-block sol-text solbox-profile-details">{solboxSerialNumber}</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <label className={"col-md-2"} htmlFor={"esp-firmware-version"}>ESP firmware
                                            version</label>
                                        <div className={"col-md-10"} id={"esp-firmware-version"}>
                                            <SolTypography.Text
                                                className={'text-small valueText'}>{espFirmwareVersion}</SolTypography.Text>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <label className={"col-md-2"} htmlFor={"esp-firmware-version"}>Firmware version
                                            updated at</label>
                                        <div className={"col-md-10"} id={"esp-firmware-version"}>
                                            <SolTypography.Text
                                                className={'text-small valueText'}>{firmwareVersionUpdateAt}</SolTypography.Text>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <label className={"col-md-2"} htmlFor={"esp-firmware-version"}>OTA
                                            availability</label>
                                        <div className={"col-md-10"} id={"esp-firmware-version"}>
                                            <SolTypography.Text
                                                className={'text-small valueText'}>
                                                {otaAvailability? <span className={"text-success"}>{'Yes'}</span>:
                                                    <span className={"text-danger"}>{'No'}</span>}
                                                {(otaStatus === 'Succeeded' && !otaAvailability)? <><br/><span className={'text-danger'}>SOLbox is running on latest FW</span></>: null}
                                                {(incompatible && !otaAvailability)? <><br/><span className={'text-danger'}>SOLbox hardware is incompatible with new FW</span></>:null}
                                                {(otaStatus === 'In progress' && !otaAvailability)? <><br/><span className={'text-danger'}>OTA in progress</span></>:null}
                                            </SolTypography.Text>
                                        </div>
                                    </div>
                                    {!otaAvailability? <div className={"row"}>
                                        <label className={"col-md-2"} htmlFor={"esp-firmware-version"}>OTA
                                            status</label>
                                        <div className={"col-md-10"} id={"esp-firmware-version"}>
                                            <SolTypography.Text className={'text-small valueText'}>
                                                {otaStatus === 'Succeeded'? <span className={'text-success'}>{otaStatus}</span>:null}
                                                {otaStatus === 'Failed'? <span className={'text-danger'}>{otaStatus}</span>:null}
                                                {(otaStatus !== 'Failed' && otaStatus !== 'Succeeded')? <>{otaStatus}</>:null}
                                            </SolTypography.Text>
                                        </div>
                                    </div>: null}
                                    <div className={"row"}>
                                        <div className={"col-md-2"}>
                                            <button
                                                className="btn btn-warning btn-sm mt-2"
                                                disabled={(!otaAvailability || props.otaUpdateProcessing)? true: false}
                                                onClick={(event) => {sendOtaUpdate()}}
                                                style={{width: '100%'}}
                                            >
                                                OTA update to the latest FW
                                            </button>
                                        </div>
                                    </div>
                                </>}
                        </TabContainer>
                    </Tab>
                    <Tab eventKey="otaUpdateHistory" title="OTA update history" tabClassName={'sol-tab'}>
                        <TabContainer className={"pl-4"}>
                            <div className={"row mb-1 ml-1"}>
                                <h3>SOLbox:</h3>&nbsp;<Link target="_blank" to={`/solboxes/profile/${solboxSerialNumber}/${solboxGuid}`} style={{color: "#F18D00"}}>
                                <span className="d-block sol-text solbox-profile-details"><h3>{solboxSerialNumber}</h3></span>
                                </Link>
                            </div>
                            <div style={{paddingBottom: "1rem"}}>
                                    <div className={'row'} style={{marginBottom: '1.5rem'}}>
                                        <div className={'col-md-4'}>
                                            <OverlayTrigger rootClose trigger="click" placement="bottom-start" overlay={popover}>
                                                <SolInput
                                                    name={'dateRangePickerInput'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={`OTA date range`}
                                                />
                                            </OverlayTrigger>
                                        </div>
                                    </div>

                                    <Button name={'filterSubmit'} className={'btn btn-outline-warning'} size={'sm'} id={'filterSubmit'}
                                            type='button' onClick={() => applyFilter()}>
                                        Apply Filter
                                    </Button>

                                    <Button name={'reset'} id={'reset'} size={'sm'}
                                            className={'btn btn-outline-secondary reset-button-style'} type={'button'} dullComponent={true}
                                            onClick={() => resetFilter()}>
                                        Reset Filter
                                    </Button>
                            </div>
                            <DataTableContainer>
                                {props.filterTriggeredLoading !== true ? <DataTable
                                    columns={otaUpdateListColumns}
                                    data={otaUpdateList}
                                    showToolbar={false}
                                    asyncPagination={true}
                                    isLoading={props.tableLoading}
                                    count={props.otaUpdates?.count}
                                    itemsPerPage={props.otaUpdates?.page_size}
                                    onChangePage={onChangePage}
                                    page={page}
                                /> : <LoadingSpinner loadingSubText={"Collecting OTA updates of " + solboxSerialNumber}/>}
                            </DataTableContainer>
                        </TabContainer>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

OtaUpdateHistory.propTypes = {
    solboxGuid: PropTypes.string.isRequired,
    solboxSerialNumber: PropTypes.string.isRequired,
};
const mapStateToProps=(state)=>{
    return {
        filterTriggeredLoading: state.otaUpdatesReducer.filterTriggeredLoading,
        otaUpdates: state.otaUpdatesReducer.otaUpdateList,
        collectingOtaDetails: state.otaUpdatesReducer.collectingOtaDetails,
        otaDetails: state.otaUpdatesReducer.otaDetails,
        otaUpdateProcessing: state.otaUpdatesReducer.otaUpdateProcessing,
        otaUpdateStatus: state.otaUpdatesReducer.otaUpdateStatus
    }
}

export default connect(mapStateToProps, actions)(withRoles(OtaUpdateHistory));
