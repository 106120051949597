import React from "react";
import {Badge} from "react-bootstrap";

export const gridListColumns=[

    {
        field: 'name',
        title: "Name",
        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0'
        },
        headerStyle: {
            paddingLeft: '0'
        },
        // disableClick: true,
    },
    {
        field: 'consumer',
        title: "Consumers",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        width: '40px',
        cellStyle: {
            textAlign: 'right',
        },
        headerStyle: {
            textAlign: 'center'
        },
        disableClick: true,
    },
    {
        field: 'prosumer',
        title: "Prosumer",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            textAlign: 'right'
        },
        width: '40px',
        headerStyle: {
            textAlign: 'center'
        },
        disableClick: true,
    },
    {
        field: 'microutility',
        title: "MU",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        width: '40px',
        cellStyle: {
            textAlign: 'right',
        },
        headerStyle: {
            textAlign: 'center',
        },
        disableClick: true,
    },
    {
        field: 'producer',
        title: "Producer",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        width: '40px',
        cellStyle: {
            textAlign: 'right',
        },
        headerStyle: {
            textAlign: 'center',
        },
        disableClick: true,
    },
    {
        field: 'total',
        title: "Total",
        sorting: false,
        emptyValue: ()=>{
            return "N/A"
        },
        width: '40px',
        cellStyle: {
            textAlign: 'right',
        },
        headerStyle: {
            textAlign: 'center',
        },
        disableClick: true,
    },
    {
        field: 'tariff',
        title: "Tariff",
        emptyValue: ()=>{
            return "N/A"
        },
        disableClick: true,
    },
]
