import React from 'react';

const FirebaseContext = React.createContext(null);


export const withFirebase = Component => props => {
    return (
        <FirebaseContext.Consumer>

            {/*{firebase=>{*/}
            {/*    return withAuthentication(<Component {...props} firebase={firebase} />, firebase)}}*/}
            {firebase=><Component {...props} firebase={firebase} />}
        </FirebaseContext.Consumer>)
};


export default FirebaseContext;

