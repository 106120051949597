import React, { useState, useEffect } from "react";
import { customerActions }   from "../customerActions";
import { connect } from 'react-redux';
import SolTextInput from "../../../components/soltextinput/SolTextInput";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import { ExportToCsv } from 'export-to-csv';
import { Spinner } from "react-bootstrap";

const CustomerListToolbar = (props) => {

    const {
        isLoading,
        customerParams,
        customerPhone,
        getCustomers,
        hideCustomerList,
        searchFlag,
        handleSearchFlag,
        customerListData,
        importCustomerData,
        handleChangeSearchKeyword,
        FETCH_SEARCHED_CUSTOMER
    } = props;

    const searchCustomer = (event) => {
        if (event.key === 'Enter' &&
            customerParams !== undefined &&
            customerPhone !== undefined &&
            customerPhone !== ""
        ) {
            FETCH_SEARCHED_CUSTOMER(customerPhone.trim(), customerParams);
            handleSearchFlag(event);
	    } else {
            if (event.key === 'Enter' && searchFlag === true && customerPhone === "") {
                FETCH_SEARCHED_CUSTOMER(customerPhone.trim(), {});
            }
        }
    }

    const [csvLoaderIcon, setCsvLoaderIcon] = useState(false);
    const [exportCsv, setExportCsv] = useState(false);

    const handleClickCsvLoaderIcon = (event) => {
        if (isLoading !== undefined && isLoading !== null && isLoading === true) {
            setCsvLoaderIcon(true);
            setExportCsv(true);
        } else {
            setExportCsv(true);
        }
    }

    useEffect(() => {
        if (isLoading !== undefined && isLoading !== null && isLoading === false) {
            setCsvLoaderIcon(false);
        }
    }, [isLoading]);

    useEffect(() => {
        if (importCustomerData !== undefined &&
            importCustomerData !== null &&
            importCustomerData.length !== 0 &&
            exportCsv !== undefined &&
            exportCsv !== null &&
            exportCsv === true
        ) {    
            const options = { 
                fieldSeparator: ',',
                filename: `customers_${moment().format("DD_MM_YYYY")}`,
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true, 
                showTitle: false,
                title: '',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(importCustomerData);
            setExportCsv(false);
        }
    }, [importCustomerData, exportCsv]);
    
    return (
        <React.Fragment>
            <div className={`row list-toolbar`}>
                <div className={'col-12 col-sm-7'}>
                    <h3 className={`list-bold-text`}>
                        {(customerListData !== undefined && customerListData.length !== 0) ? 
                            (customerListData.length < 2) ?
                                `${customerListData.length} Customer` 
                                :
                                `${customerListData.length} Customers`
                            : 
                            `Customer`
                        }
                        </h3>
                </div>
                {getCustomers === true &&
                    <div className={`col-12 col-sm-5`}>
                        <div className={`list-toolbar-actions-part float-right`}>
                            {/* <div className="form-group d-inline-block">
                                <SolTextInput 
                                    name={`phoneOfCustomer`}
                                    placeHolder="Type Customer Phone..."
                                    value={customerPhone}
                                    handleChange={handleChangeSearchKeyword}
                                    handleKeyDown={searchCustomer}
                                />
                            </div> */}
                            <div className={`list-download-icon d-inline-block ml-5`}>
                            {csvLoaderIcon ?
                                <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>
                                :
                                <img 
                                    className="inter-module-link"
                                    src={require('../../../utils/asset/download-Button.svg')} 
                                    alt="download icon"
                                    onClick={(event) => handleClickCsvLoaderIcon(event)}
                                />
                            }
                            </div>
                            <div className={`list-toolbar-actions list-filter-icon d-inline-block ml-5`}>
                                <FilterListIcon 
                                    color="disabled"   
                                    fontSize="large" 
                                    style={{"marginRight": "0.5rem"}} 
                                    onClick={(event) => hideCustomerList(event)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.customerReducer.isLoading
    }
};

export default connect(mapStateToProps, customerActions)(CustomerListToolbar);