import React, { useState, useEffect } from "react";
import { solboxActions } from "../../solboxActions";
import { connect } from "react-redux";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import moment from "moment";
import SolSelect from "../../../../components/SolSelect";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import SolDaterangePicker from "../../../../components/SolDaterangePicker";

const SolboxProfileTransactions = (props) => {

    const {
        isLoading,
        tableLoading,
        isTransactionTypesLoading,
        solboxId,
        transactionTypes,
        pageCount,
        pageSize,
        transactionWorth,
        transactionHistory,
        FETCH_TRANSACTION_TYPES,
        GET_TRANSACTION_HISTORY_LIST
    } = props;

    const handleSolboxClick = (event, data) => {
        props.history.push(`/solboxes/profile/${data.solbox}/${data.solbox_guid}`);
    }

    const transactionColumns = [
        {
            field: "id",
            title: "ID",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <span className="clipboard-style" onClick={() => navigator.clipboard.writeText(rowData.id)}>
                    {(rowData.id && rowData.id !== `N/A`) ?
                        rowData.id.substring(0, rowData.id.indexOf("-")).concat("...")
                        :
                        `N/A`
                    }
                </span>
            )
        },
        {
            field: "date",
            title: "Date",
            width: "240px",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <React.Fragment>
                    <span className="d-block">{rowData.date}</span>
                </React.Fragment>
            )
        },
        {
            field: "solbox",
            title: "SOLbox",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <React.Fragment>
                    {(rowData &&
                    rowData.solbox) ?
                    <span 
                        className="d-inline-block"
                        //onClick={(event, data) => handleSolboxClick(event, rowData)}
                    >
                        {rowData.solbox}
                    </span>
                    :
                    `N/A`
                    }
                </React.Fragment>
            )
        },
        {
            field: "amount",
            title: "Amount (Tk)",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <React.Fragment>
                    {(rowData && rowData.amount) ?
                    <React.Fragment>
                        <span className="d-block">
                        {rowData.amount}
                        </span>
                    </React.Fragment>
                    :
                    `N/A`}
                    {(rowData && 
                        rowData.sent_from &&
                        rowData.sent_from !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                        {`From ${rowData.sent_from}`}
                        </span>
                    </React.Fragment>
                    :
                    null}
                    {(rowData && 
                        rowData.initiated_by &&
                        rowData.initiated_by !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                            {`By ${rowData.initiated_by}`}
                        </span>
                    </React.Fragment>
                    :
                    null}
                    {(rowData && 
                        rowData.remarks &&
                        rowData.remarks !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                        {rowData.remarks}
                        </span>
                    </React.Fragment>
                    :
                    null}
                </React.Fragment>
            )
        },
        {
            field: "type",
            title: "Type",
            headerStyle: { color: "#8C8C9B" },
            render: (rowData) => (
                <React.Fragment>
                    {(rowData && rowData.type) ?
                    <React.Fragment>
                        <span className="d-block list-bold-text">
                            {rowData.type}
                        </span>
                    </React.Fragment>
                    :
                    `N/A`}
                    {(rowData && 
                        rowData.bkash_ref &&
                        rowData.bkash_ref !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                            {`REF: ${rowData.bkash_ref}`}
                        </span>
                    </React.Fragment>
                    :
                    null}
                    {(rowData && 
                        rowData.drop_datetime &&
                        rowData.drop_datetime !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                            {`Dropped on ${moment(rowData.drop_datetime).format("DD/MM/YYYY, h:mm:ss a")}`}
                        </span>
                    </React.Fragment>
                    :
                    null}
                    {(rowData && 
                        rowData.promotion_name &&
                        rowData.promotion_name !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                            {`Promotion Reference #${rowData.promotion_name}`}
                        </span>
                    </React.Fragment>
                    :
                    null}
                    {(rowData && 
                        rowData.old_solbox_serial_number &&
                        rowData.old_solbox_serial_number !== `N/A`
                    ) ?
                    <React.Fragment>
                        <span className="d-block normal-text">
                            {`OLD SOLbox: ${rowData.old_solbox_serial_number}`}
                        </span>
                    </React.Fragment>
                    :
                    null}
                </React.Fragment>
            )
        },
        {
            field: "status",
            title: "Status",
            headerStyle: { color: "#8C8C9B" },
            //cellStyle: { color: "rgba(203, 21, 43, 1)" },
            render: (rowData) => (
                <React.Fragment>
                    {(rowData.status === "failed") ?
                        <span className="transaction-status-failed">
                            {rowData.status}
                        </span>
                        :
                        (rowData.status === "processing") ?
                        <React.Fragment>
                            <span className="d-block mb-2">
                            {`In Progress`}
                            </span>
                            <span className="normal-text d-block">
                                {`Attempt #${rowData.retry_counter}`}
                            </span>
                        </React.Fragment>
                        :
                        <span className="success-text">
                            {`Successful`}
                        </span>
                    }
                </React.Fragment>
            )
        }
    ];

    const [transactionHistoryList, setTransactionHistoryList] = useState([]);

    useEffect(() => {
        if (transactionHistory &&
            transactionHistory.length !== 0
        ) {
            let transactionHistoryArr = [];
            transactionHistory.forEach((element, index) => {
                transactionHistoryArr.push({
                    "id": 
                    element.transaction_guid !== undefined &&
                    element.transaction_guid !== null
                        ? element.transaction_guid
                        : `N/A`,
                    "date":
                    element.updated_at !== undefined && element.updated_at !== null
                        ? moment(element.updated_at).format("lll")
                        : `N/A`,
                    "customer":
                    element.customer_name !== undefined &&
                    element.customer_name !== null  ?
                    element.customer_name
                    : `N/A`,
                    "grid_name":
                    element.grid_name !== undefined && element.grid_name !== null
                    ? element.grid_name
                    : `N/A`,
                    "solbox":
                    element.solbox_serial_number !== undefined &&
                    element.solbox_serial_number !== null ?
                    element.solbox_serial_number
                    : `N/A`,
                    "amount": 
                    element.amount !== undefined &&
                    element.amount !== null 
                        ? element.amount.toString(10).includes("-")
                        ? element.amount.toString(10)
                        : `+`
                            .concat("", element.amount.toString(10))
                        : `N/A`,
                    "sent_from":
                    element.sent_from !== undefined && element.sent_from !== null
                    ? element.sent_from
                    : `N/A`,
                    "initiated_by":
                    element.initiated_by !== undefined && element.initiated_by !== null
                    ? element.initiated_by 
                    : `N/A`,
                    "remarks":
                    element.remarks !== undefined && element.remarks !== null 
                    ? element.remarks
                    : `N/A`,
                    "type": 
                    element.txn_type !== undefined && element.txn_type !== null
                    ? element.txn_type
                    : `N/A`,
                    "bkash_ref":
                    element.bkash_ref !== undefined && element.bkash_ref !== null
                    ? element.bkash_ref 
                    : `N/A`,
                    "drop_datetime":
                    element.drop_datetime !== undefined && element.drop_datetime !== null
                    ? element.drop_datetime
                    : `N/A`,
                    "old_solbox_serial_number":
                    element.old_solbox_serial_number !== undefined && element.old_solbox_serial_number !== null
                    ? element.old_solbox_serial_number
                    : `N/A`,
                    "promotion_name":
                    element.promotion_name !== undefined && element.promotion_name !== null 
                    ? element.promotion_name 
                    : `N/A`,
                    "status":
                    element.status !== undefined && element.status !== null
                    ? element.status
                    : `N/A`,
                    "retry_counter":
                    element.retry_counter !== undefined && element.retry_counter !== null
                    ? element.retry_counter
                    : `N/A`,
                    "retry_allow":
                    element.retry_allow !== undefined && element.retry_allow !== null
                    ? element.retry_allow 
                    : `N/A`,
                    "show_log":
                    element.show_log !== undefined && element.show_log !== null && element.show_log.length !== 0
                    ? element.show_log
                    : `N/A`,
                    "customer_guid":
                    element.customer_guid !== undefined && element.customer_guid !== null
                    ? element.customer_guid 
                    : "",
                    "grid_guid":
                    element.grid_guid !== undefined && element.grid_guid !== null
                    ? element.grid_guid 
                    : "",
                    "solbox_guid":
                    element.solbox_guid !== undefined && element.solbox_guid !== null
                    ? element.solbox_guid 
                    : ""
                });
            });
            setTransactionHistoryList(transactionHistoryArr);
        } else {
            setTransactionHistoryList([]);
        }
    }, [transactionHistory]);

    useEffect(() => {
        if (transactionTypes !== undefined &&
            transactionTypes === null
        ) {
            FETCH_TRANSACTION_TYPES();
        }
    }, [transactionTypes]);

    const [transactionTypesOpt, setTransactionTypesOpt] = useState([]);

    useEffect(() => {
        if (transactionTypes &&
            transactionTypes.length !== 0
        ) {
            let transactionTypesArr = [];
            transactionTypes.forEach((element, index) => {
                transactionTypesArr.push({
                    value: element.guid, label: element.name
                });
            });
            setTransactionTypesOpt(transactionTypesArr);
        }
    }, [transactionTypes]);

    const [transactionType, setTransactionType] = useState("");

    const handleTransactionTypeSelect = (transactionType) => {
        setTransactionType(transactionType);
    }

    const [transactionStatus, setTransactionStatus] = useState("");

    const handleChangeTransactionStatus = (event) => {
        if (event.target.id === "received") {
            setTransactionStatus(event.target.value);
        } else {
            setTransactionStatus(event.target.value);
        }
    }

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const [dateRange, setDateRange] = useState("");

    useEffect(() => {
        if (startDate !== undefined &&
            endDate !== undefined &&
            startDate !== null &&
            endDate !== null
        ) {
            setDateRange(
                            moment(startDate._d).format('LL')
                            .concat(" - ", moment(endDate._d).format('LL'))
                        );
            if (transactionType !== undefined && 
                transactionType !== null && 
                transactionType !== "" &&
                transactionStatus !== undefined && 
                transactionStatus !== null && 
                transactionStatus !== ""
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "status": transactionStatus,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD")
                });
            } else {
                if (transactionType !== undefined && 
                    transactionType !== null && 
                    transactionType !== ""
                ) {
                    GET_TRANSACTION_HISTORY_LIST({
                        "pagination": "True",
                        "is_in_last_two_days_transactions": "False",
                        "solbox_serial_number": solboxId,
                        "txn_type": transactionType.value,
                        "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                        "end_date": moment(endDate._d).format("YYYY-MM-DD")
                    });
                } else if (transactionStatus !== undefined && 
                    transactionStatus !== null && 
                    transactionStatus !== ""
                ) {
                    GET_TRANSACTION_HISTORY_LIST({
                        "pagination": "True",
                        "is_in_last_two_days_transactions": "False",
                        "solbox_serial_number": solboxId,
                        "status": transactionStatus,
                        "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                        "end_date": moment(endDate._d).format("YYYY-MM-DD")
                    });
                } else {
                    GET_TRANSACTION_HISTORY_LIST({
                        "pagination": "True",
                        "is_in_last_two_days_transactions": "False",
                        "solbox_serial_number": solboxId,
                        "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                        "end_date": moment(endDate._d).format("YYYY-MM-DD")
                    });
                }
            }
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (transactionType !== undefined && transactionType !== null && transactionType !== "") {
            if (transactionStatus !== undefined && 
                transactionStatus !== null && 
                transactionStatus !== "" &&
                startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD"),
                    "status": transactionStatus
                });
            } else if (transactionStatus !== undefined && 
                transactionStatus !== null && 
                transactionStatus !== ""
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "status": transactionStatus
                });
            } else if (startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD")
                });
            } else {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value
                });
            }
        } else {
            if (transactionStatus !== undefined && 
                transactionStatus !== null && 
                transactionStatus !== "" &&
                startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD"),
                    "status": transactionStatus
                });
                
            } else if (transactionStatus !== undefined && 
                transactionStatus !== null && 
                transactionStatus !== ""
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "status": transactionStatus
                });
            } else if (startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD")
                });
            } else {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True", 
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId
                });
            }
        }
    }, [transactionType]);

    useEffect(() => {
        if (transactionStatus !== undefined && transactionStatus !== null && transactionStatus !== "") {
            if (transactionType !== undefined && 
                transactionType !== null && 
                transactionType !== "" &&
                startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD"),
                    "status": transactionStatus
                });
            } else if (transactionType !== undefined && 
                transactionType !== null && 
                transactionType !== ""
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "status": transactionStatus
                });
            } else if (startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD"),
                    "status": transactionStatus
                });
            } else {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "status": transactionStatus
                });
            }
        } else {
            if (transactionType !== undefined && 
                transactionType !== null && 
                transactionType !== "" &&
                startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD")
                });
            } else if (transactionType !== undefined && 
                transactionType !== null && 
                transactionType !== ""
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value
                });
            } else if (startDate !== undefined &&
                endDate !== undefined &&
                startDate !== null &&
                endDate !== null
            ) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD")
                });
            } else {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "solbox_serial_number": solboxId
                });
            }
        }
    }, [transactionStatus]);

    const [pageNumber, setpageNumber] = useState(null);

    const handleChangePage = (event, pageNumber) => {
        setpageNumber(pageNumber);
        if (pageNumber && solboxId) {
            if (startDate && endDate && transactionType && transactionStatus) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "page": pageNumber,
                    "solbox_serial_number": solboxId,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD"),
                    "txn_type": transactionType.value,
                    "status": transactionStatus
                });
            } else if (!startDate && !endDate && transactionType && transactionStatus) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "page": pageNumber,
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value,
                    "status": transactionStatus
                });
            } else if (!startDate && !endDate && !transactionType && transactionStatus) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "page": pageNumber,
                    "solbox_serial_number": solboxId,
                    "status": transactionStatus
                });
            } else if (!startDate && !endDate && transactionType && !transactionStatus) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "page": pageNumber,
                    "solbox_serial_number": solboxId,
                    "txn_type": transactionType.value
                });
            } else if (startDate && endDate && !transactionType && !transactionStatus) {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "page": pageNumber,
                    "solbox_serial_number": solboxId,
                    "start_date": moment(startDate._d).format("YYYY-MM-DD"),
                    "end_date": moment(endDate._d).format("YYYY-MM-DD")
                });
            } else {
                GET_TRANSACTION_HISTORY_LIST({
                    "pagination": "True",
                    "is_in_last_two_days_transactions": "False",
                    "page": pageNumber,
                    "solbox_serial_number": solboxId
                });
            }
        }
    }

    return (
        <React.Fragment>
            <ContentWrapper 
                permissionDenied={false} 
                pageTitle={""} 
                showBackButton={false}
                isLoading={false} 
                showCardHeader={false}
            >
                <h3 className={`list-bold-text mb-5`}>
                    {pageCount
                        ? pageCount > 1
                            ? `${pageCount} Transactions Worth ${transactionWorth} Tk`
                            : `${pageCount} Transaction Worth ${transactionWorth} Tk`
                        : `Transactions`}
                </h3>
                <div className={`row list-filter-control mt-5 mb-5`}>
                    <div className={`col-12 col-sm-3 mb-2`}>
                        <label className={`form-check-label normal-text mb-5`}>
                            {`Transaction Type`} 
                        </label>
                        <SolSelect
                            placeHolder={`Select Type`}
                            value={transactionType}
                            handleChangeSolSelect={handleTransactionTypeSelect}
                            options={transactionTypesOpt}
                            selectMulti={false}
                            loading={isTransactionTypesLoading}
                            clearable={true}
                            noOptionsMessage={() => `No type available`}
                        />
                    </div>
                    <div className={`col-12 col-sm-3`}>
                        <SolDaterangePicker
                            dateRangelabel={`Transaction Date`}
                            customLabel={`normal-text`}
                            startDate={startDate}
                            endDate={endDate}
                            dateRange={dateRange}
                            handleDateRange={handleDateRange}
                        />
                    </div>
                    <div className={`col-12 col-sm-3`}>
                        <label className={`form-check-label normal-text d-block mb-3`}>
                            {`Transaction Status`}
                        </label>
                        <div className="form-check form-check-inline mt-2">
                            <SolRadioInput
                                name="statusOfTransaction"
                                id="received"
                                value={`received`}
                                handleChange={(event) => handleChangeTransactionStatus(event)}
                                checked={(transactionStatus === "received") ? true : false}
                                htmlFor="received"
                                labelName="Received"
                            />
                        </div>
                        <div className="form-check form-check-inline mt-2 customer-status-inactive">
                            <SolRadioInput
                                name="statusOfTransaction"
                                id="failed"
                                value={`failed`}
                                handleChange={(event) => handleChangeTransactionStatus(event)}
                                checked={(transactionStatus === "failed") ? true : false}
                                htmlFor="failed"
                                labelName="Failed"
                            />
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={transactionColumns}
                    data={transactionHistoryList}
                    isLoading={tableLoading}
                    search={false}
                    count={pageCount}
                    asyncPagination={true}
                    itemsPerPage={pageSize}
                    onChangePage={handleChangePage}
                    page={pageNumber}
                />
            </ContentWrapper>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
      isLoading: state.solboxReducer.isLoading,
      tableLoading: state.solboxReducer.tableLoading,
      statusCode: state.solboxReducer.statusCode,
      isTransactionTypesLoading: state.solboxReducer.isTransactionTypesLoading,
      transactionTypes: state.solboxReducer.transactionTypes,
      pageCount: state.customerReducer.pageCount,
      pageSize: state.customerReducer.pageSize,
      transactionWorth: state.solboxReducer.transactionWorth,
      transactionHistory: state.solboxReducer.transactionHistory
    };
  };

export default connect(mapStateToProps, solboxActions)(SolboxProfileTransactions);