import { persistReducer } from "redux-persist";
import { } from 'redux-saga/effects'
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import * as routerHelpers from "../../../_metronic/_helpers/RouterHelpers";
import * as firebase from 'firebase/app';
import Firebase from "./firebase";
import {actionTypes} from './actionTypes';
import {actions} from './actions';
import Api from "./Api";
import { parsePhoneNumberFromString as parseMobile } from 'libphonenumber-js/mobile';

import { SET_ACCESS_TOKEN } from "redux-refresh-token";

var provider = new firebase.auth.GoogleAuthProvider();

/*Initial State object*/
const initialAuthState = {
  user: undefined,
  authToken: undefined,
  firebase: new Firebase(),
  confirmation: undefined,
  Token: undefined,
  isError: false,
  errorMsg: '',
  access_token: undefined,
  refresh_token: undefined,
  role: '',
  disabled: false,
  is_solshare: undefined,
  organization_guid: undefined,
};

/*Reducer for login page*/
export const reducer = persistReducer(
    { storage, key: "solshare-auth", whitelist: ["user", "authToken", "Token", "access_token", "refresh_token", "role", "user_guid", 'is_solshare', 'organization_guid' ] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.SEND_OTP: {
          return {...state, isError: false, errorMsg: ''}
        }
        case actionTypes.CONFIRM_OTP: {
          return {...state, isError: false, errorMsg: ''}
        }

        case actionTypes.SET_CONFIRMATION: {
          const { confirmation } = action.payload;
          return { ...state, confirmation: confirmation, loading: false };
        }

        case actionTypes.AUTH_ERROR: {
          const { msg } = action.payload;
          return { ...state, errorMsg: msg, isError: true };
        }

        case actionTypes.LOGOUT: {
          const { firebase } = state;
          firebase.doSignOut();
          routerHelpers.forgotLastLocation();
          storage.removeItem('persist:root')
          return initialAuthState;
        }

        case actionTypes.VERIFY_AUTH: {
          return {...state};
        }
        case actionTypes.REFRESH_AUTH_TOKEN: {
          return { ...state };
        }
        case actionTypes.SET_TOKEN: {
          const { user, access_token, refresh_token } = action.payload;
          return { ...state, user: user, access_token: access_token, refresh_token: refresh_token, loading: false };
        }
        case SET_ACCESS_TOKEN: {
          const {access_token} = action
          return { ...state , access_token: access_token};
        }
          // case actionTypes.CHECK_GOOGLE_USER: {
          //   return { ...state };
          // }
        case actionTypes.SET_ROLE:
          return {
            ...state,
            role: action.payload.role,
            user_guid: action.payload.user_guid,
            ...action.payload
          };
        case actionTypes.START_LOADING_STATE:
          return {
            ...state,
            loading: true,
            emailButtonLoading: true,
          }
        case actionTypes.DISABLE_LOADING:
          return {
            ...state,
            loading: false,
            confirmation: undefined,
            emailButtonLoading: undefined,
          }
          case actionTypes.DISABLE_LOADING_IN_OTP:
          return {
            ...state,
            loading: false,
            emailButtonLoading: false,
          }
        case actionTypes.START_EMAIL_LOADING_STATE:
          return {
            ...state,
            emailButtonLoading: true,
            confirmation: undefined,
          }
        case actionTypes.CLEAR_CONFIRMATION_OBJECT:
          return {
            ...state,
            emailButtonLoading: undefined,
            loading: undefined,
            confirmation: undefined,
            isError: false,
            errorMsg: '',
          }
        default:
          return state;
      }
    }
);


export function* saga() {

  // ======================= verfify phone number with backend ================
  yield takeLatest(actionTypes.VERIFY_PHONE, function* registerSaga(action) {
    // const phoneno = /\+8801[13-9]\d{8}$/;
    let phone_verfiy = undefined;
    var phone_number = action.payload.phone;

    if (parseMobile(phone_number).isValid()) {
      yield put(actions.START_LOADING_STATE())
      yield  Api.post("/users/checking/", {"username": phone_number})
          .then((response) => {
            phone_verfiy = response.data
          }, (error) => {
            console.log("phone number does not exist");
          });

      if(phone_verfiy === undefined){
        yield put(actions.DISABLE_LOADING())
        yield put(actions.AUTH_ERROR("Your phone number does not exist! Please contact Support"))
      }else{
        yield put(actions.SET_ROLE( phone_verfiy ));
        phone_verfiy=undefined;
        yield put(actions.SEND_OTP(action.payload.phone, action.payload.firebase, action.payload.recap ))
      }
    }else{
      yield put(actions.DISABLE_LOADING())
      yield put(actions.AUTH_ERROR("Phone number invalid! Please enter valid Phone number"))
    }
  });


  // ======================= login with google ================
  yield takeLatest(actionTypes.POPUP_SIGNIN, function* registerSaga(action) {

    let user = undefined;
    let verifyed_email = undefined
    yield put(actions.START_EMAIL_LOADING_STATE());
    yield action.payload.firebase.signInWithPopup(provider)
        .then(confirmationResult => {
          user = confirmationResult.user;

        })
        .catch(error => {
          console.log('error' + error);
        });

    if(user === undefined){
      yield put(actions.DISABLE_LOADING())
      yield put(actions.AUTH_ERROR("Google sign in failed! Please try again"))
    }else{
      let access_token=null;
      yield user.getIdToken(false).then((result)=>{
        access_token=result;
      });
      const refreshToken = user.refreshToken;
      const email = user.email;
      // check email address register to the backend
      yield  Api.post("/users/checking/", {"username":email})
          .then((response) => {
            verifyed_email = response.data;
          }, (error) => {
            console.log("Email address does not exist");
          });

      if(verifyed_email === undefined){
        yield put(actions.DISABLE_LOADING())
        yield put(actions.AUTH_ERROR("Your email address does not exist! Please contact Support"))
      }else{
        yield put(actions.DISABLE_LOADING())
        yield put(actions.SET_ROLE( verifyed_email));
        verifyed_email=undefined;
        yield put(actions.SET_TOKEN(user, access_token, refreshToken));
      }
    }
  });

  // =================== send otp to phone number ==================
  yield takeLatest(actionTypes.SEND_OTP, function* loginSaga(action) {
    var phone_number = action.payload.phone;
    let confirmation = undefined;
    yield action.payload.firebase.doSignInWithPhone(phone_number, action.payload.recap)
        .then(confirmationResult => {
          confirmation=  confirmationResult;
        })
        .catch(error => {
          console.log('error' + error);
        });
    if(confirmation === undefined){
      yield put(actions.DISABLE_LOADING())
      yield put(actions.AUTH_ERROR("OTP can't be send! Please enter valid phone number."))
    }else{
      yield put(actions.SET_CONFIRMATION(confirmation));
    }

  });

  // ========================== verfiy otp ================================
  yield takeLatest(actionTypes.CONFIRM_OTP, function* registerSaga(action) {
    yield put(actions.START_LOADING_STATE())
    let user = undefined
    yield  action.payload.confirmation.confirm(action.payload.code).then(function (result) {
      // User signed in successfully.
      user = result.user
    }).catch(function (error) {
      // User couldn't sign in (bad verification code?)
      console.log("Login failed");

    });

    if(user === undefined){
      yield put(actions.DISABLE_LOADING_IN_OTP())
      yield put(actions.AUTH_ERROR("Invalid OTP! Please enter valid OTP"))
    }else{
      let access_token=null;
      yield user.getIdToken(false).then((result)=>{
        access_token=result;
      });
      const refreshToken = user.refreshToken;
      yield put(actions.SET_TOKEN(user, access_token, refreshToken));
    }

  });

}
