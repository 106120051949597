import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
// import UsersRouter from "../features/users/router";
import {PermissionDenied} from "../modules/ErrorsExamples/PermissionDenied";
import {connect} from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";


/*Injecting roles with HOC*/
export const withRoles=(Component)=>{

    return connect(mapStateToProps)((props)=>{

        return <Component  {...props} b2bOrgGuid={props.organization_guid} isSolshare={props.is_solshare}/>
    })
}


const SecuredRoute = (props) => {

    const {relatedModule, externalUserPermitted= true, component: Component, path, is_solshare, organization_guid  } = props;

    return (

        <Route path={path} component={   props.is_solshare || externalUserPermitted? Component:PermissionDenied   } />  /**/
    );
};


SecuredRoute.defaultProps = {
    relatedModule: []
}


SecuredRoute.propTypes = {
    relatedModule: PropTypes.array,
    component: PropTypes.object.isRequired,
    is_solshare: PropTypes.bool.isRequired,
    externalUserPermitted: PropTypes.bool,
    path: PropTypes.string.isRequired,
    organization_guid: PropTypes.string,
};


const mapStateToProps = (state) => {
    return {
        ...state.auth,
    }
}

export default connect(mapStateToProps)(SecuredRoute);



