import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, LoadingSpinner} from '../../utils'
import {
    DangerStatusRender,
    PendingRenderStatus,
    SuccessRenderStatus
} from "../../../../components/legendComponents/legendComponents";
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import moment from "moment";
import {ExportToCsv} from "export-to-csv";
import {requestCycle} from "../../utils";
import {toast} from "react-toastify";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});


const OtaUpdateList = (props) => {
    const [showFilter, setShowFilter] = useState(true);
    const [otaUpdateList, setOtaUpdateList] = useState([]);
    const [page, setPage] = useState(null);
    const [gridOwnersList, setGridOwnersList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [gridList, setGridList] = useState([]);
    const [filterParameters, setFilterParameters] = useState(null);
    const subHeader = useSubheader();
    const [searchText, setSearchText] = useState(null);
    const [projectedParams, setProjectedParams] = useState(null);
    const [validatonProcessGoingOn, setValidationProcessGoingOn] = useState(false);
    const [retryProcessGoingOn, setRetryProcessGoingOn] = useState(false);
    const [verificationProcessGoingOn, setVerificationProcessGoingOn] = useState(false);
    const { b2bOrgGuid , isSolshare } = props;

    const defaultStartDate = moment().subtract(1, 'days').format("YYYY-MM-DD")
    const defaultEndDate = moment().format("YYYY-MM-DD")

    const {
        GET_OTA_UPDATE_LIST,
        GET_OTA_UPDATE_LIST_FOR_EXPORT,
        GET_OTA_UPDATE_LIST_WITH_FILTER,
        GET_GRID_OWNERS_LIST,
        GET_MODELS_LIST,
        GET_GRID_LIST,
        RETRY_OTA
    } = props;
    const classes = useStylesIconButton();

    const otaUpdateListColumns=[
        {
            field: 'solbox',
            title: "SOLbox",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
            render: (rowData) => {
                return (
                    <Link
                        target="_blank"
                        to={`/solboxes/profile/${rowData.solbox}/${rowData.solbox__item_guid}`}
                        style={{color: "#F18D00"}}
                    >
                                <span className="d-block sol-text solbox-profile-details">
                                {rowData.solbox}
                                </span>
                    </Link>
                )
            }
        },
        {
            field: 'previousFw',
            title: "Previous FW",
            sorting: false,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // textAlign: 'center'
                paddingLeft: '0'
            },
            headerStyle: {
                // textAlign: 'center'
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'currentFw',
            title: "Current FW",
            sorting: false,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // textAlign: 'center'
                paddingLeft: '0'
            },
            headerStyle: {
                // textAlign: 'center'
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'otaDate',
            title: "OTA Date",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // textAlign: 'center',
                paddingLeft: '0'
            },
            headerStyle: {
                // textAlign: 'center',
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'esp_status',
            title: "ESP Status",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // textAlign: 'left',
                paddingLeft: '0'
            },
            headerStyle: {
                // textAlign: 'left',
                paddingLeft: '0'
            },
            // disableClick: true,
            render: (rowData) => {
                let espOtaStatus = rowData.device_esp_ota_status
                let awsOtaStatus = rowData.aws_ota_status
                if (['succeeded', 'failed'].includes(awsOtaStatus) && espOtaStatus === 'pending') {
                    let disableRetryButton = false
                    if (rowData.retry_disabled_till) {
                        // As server is returning value for UTC, we have added 6 hours manually to be aligned with UTC + 06 timezone for Asia/Dhaka
                        // Personal note: I have tried this with moment timezone but got stuck in getting differences between current time and disabled time.
                        // As this thing is needed to be deployed quickly, I have done this manually - Noor Faizur Reza, January 19, 2022, 12:00 PM
                        let difference = moment().diff(moment(rowData.retry_disabled_till, "YYYY-MM-DD HH:mm:ss").add(6, 'hours'))
                        let duration = moment.duration(difference)
                        if (duration < 0) {
                            disableRetryButton = true
                        }
                    }
                    setValidationProcessGoingOn(true)
                    setRetryProcessGoingOn(false)
                    setVerificationProcessGoingOn(false)
                    return (
                        <div style={{'whiteSpace': 'nowrap'}}>
                         <span className="text-info">
                           In progress
                          </span>&nbsp;&nbsp;<button type={'button'} className={"btn btn-warning btn-sm"}
                                                     disabled={props.otaUpdateRetrying || disableRetryButton? true:false}
                                                     onClick={() => {resendOtaUpdate(rowData)}}>
                            {`Retry validation`}
                        </button>
                        </div>
                    )
                } else if (!['succeeded', 'failed'].includes(awsOtaStatus) && ['pending', 'initiated', 'inprogress'].includes(espOtaStatus)) {
                    return (
                        <div style={{'whiteSpace': 'nowrap'}}>
                         <span className="text-info">
                           In progress
                          </span>
                        </div>
                    )

                } else if (espOtaStatus === 'failed') {
                    return (
                        <span className={"text-danger"}>
                            Failed
                        </span>
                    )
                } else if (espOtaStatus === 'succeeded') {
                    return (
                        <span className={"text-success"}>
                            Successful
                        </span>
                    )
                } else if (espOtaStatus === 'cancelled') {
                    return (
                        <>Cancelled</>
                    )
                } else {
                    return (
                        <>Queued</>
                    )
                }
            }
        },
        {
            field: 'espCompletionDate',
            title: "ESP completion time",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'stm_status',
            title: "STM Status",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // textAlign: 'left',
                paddingLeft: '0'
            },
            headerStyle: {
                // textAlign: 'left',
                paddingLeft: '0'
            },
            // disableClick: true,
            render: (rowData) => {
                let deviceStmOtaStatus = rowData.device_stm_ota_status
                let awsStmOtaStatus = rowData.aws_stm_ota_status

                let disableRetryButton = false
                if (rowData.retry_disabled_till) {
                    // As server is returning value for UTC, we have added 6 hours manually to be aligned with UTC + 06 timezone for Asia/Dhaka
                    // Personal note: I have tried this with moment timezone but got stuck in getting differences between current time and disabled time.
                    // As this thing is needed to be deployed quickly, I have done this manually - Noor Faizur Reza, January 19, 2022, 12:00 PM
                    let difference = moment().diff(moment(rowData.retry_disabled_till, "YYYY-MM-DD HH:mm:ss").add(6, 'hours'))
                    let duration = moment.duration(difference)
                    if (duration < 0) {
                        disableRetryButton = true
                    }
                }

                if (awsStmOtaStatus !== 'succeeded' && deviceStmOtaStatus === 'initiated') {
                    setValidationProcessGoingOn(false)
                    setRetryProcessGoingOn(true)
                    setVerificationProcessGoingOn(false)
                    return (
                        <div style={{'whiteSpace': 'nowrap'}}>
                         <span className="text-info">
                           In progress
                          </span>&nbsp;&nbsp;<button type={'button'} className={"btn btn-warning btn-sm"}
                                                     disabled={props.otaUpdateRetrying || disableRetryButton? true:false}
                                                     onClick={() => {resendOtaUpdate(rowData)}}>
                            {`Retry`}
                        </button>
                        </div>
                    )
                } else if (awsStmOtaStatus === 'succeeded' && deviceStmOtaStatus === 'inprogress') {
                    setValidationProcessGoingOn(false)
                    setRetryProcessGoingOn(false)
                    setVerificationProcessGoingOn(true)
                    return (
                        <div style={{'whiteSpace': 'nowrap'}}>
                         <span className="text-info">
                           In progress
                          </span>&nbsp;&nbsp;<button type={'button'} className={"btn btn-warning btn-sm"}
                                                     disabled={props.otaUpdateRetrying || disableRetryButton? true:false}
                                                     onClick={() => {resendOtaUpdate(rowData)}}>
                            {`Verify`}
                        </button>
                        </div>
                    )
                } else if (deviceStmOtaStatus === 'failed') {
                    return (
                        <span className={"text-danger"}>
                            Failed
                        </span>
                    )
                } else if (deviceStmOtaStatus === 'succeeded') {
                    return (
                        <span className={"text-success"}>
                            Successful
                        </span>
                    )
                } else if (deviceStmOtaStatus) {
                    return (
                        <span style={{'textTransform': 'capitalize'}}>{deviceStmOtaStatus}</span>
                    )
                } else {
                    return (
                        <>Queued</>
                    )
                }
            }
        },
        {
            field: 'stmCompletionDate',
            title: "STM completion time",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            disableClick: true
        },
        {
            field: 'updatedBy',
            title: "Updated by",
            sorting: false,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right',
            },
            headerStyle: {
                textAlign: 'right',
            },
            disableClick: true
        }
    ]

    useEffect(() => {
        setFilterParameters({'from_date': defaultStartDate, 'to_date': defaultEndDate})
        GET_OTA_UPDATE_LIST({'from_date': defaultStartDate, 'to_date': defaultEndDate, 'pagination': 'True'})
        GET_GRID_OWNERS_LIST()
        GET_MODELS_LIST()
        GET_GRID_LIST({})
    }, []);

    useEffect(() => {
        const otaUpdates = props.otaUpdates
        if (otaUpdates?.results?.length > 0) {
            setOtaUpdateList(otaUpdates.results.map((otaUpdate, index) => {
                return {
                    ...otaUpdate,
                    solbox: otaUpdate.solbox__solbox_id ? "1100" + otaUpdate.solbox__solbox_id: 'N/A',
                    previousFw: otaUpdate.previous_firmware.split("_")[1],
                    currentFw: otaUpdate.current_firmware.split("_")[1],
                    otaDate: otaUpdate.update_date? moment(otaUpdate.update_date).format('DD MMM, YYYY hh:mm A'): "N/A",
                    esp_status: otaUpdate,
                    espCompletionDate: otaUpdate.esp_ota_completion_time? moment(otaUpdate.esp_ota_completion_time).format('DD MMM, YYYY hh:mm A'):"N/A",
                    stm_status: otaUpdate,
                    stmCompletionDate: otaUpdate.stm_ota_completion_time? moment(otaUpdate.stm_ota_completion_time).format('DD MMM, YYYY hh:mm A'):"N/A",
                    updatedBy: otaUpdate.updated_by_name
                }
            }))
        } else {
            setOtaUpdateList([])
        }
    }, [props.otaUpdates]);

    useEffect(() => {
        const ownerList = props.gridOwnerList;
        if (ownerList && ownerList.length > -1) {
            setGridOwnersList(ownerList.map((owner, index) => {
                return {
                    ...owner,
                    label: owner.organization_name,
                    value: owner.organization_guid,
                }
            }))
        }
    }, [props.gridOwnerList]);

    useEffect(() => {
        const modelList = props.modelList;
        if (modelList && modelList.length > 0) {
            setModelList(modelList.map((model, index) => {
                return {
                    ...model,
                    label: model.model_alias,
                    value: model.id,
                }
            }))
        }
    }, [props.modelList]);

    useEffect(() => {
        const gridList = props.gridList;
        if (gridList && gridList.length > 0) {
            setGridList(gridList.map((grid, index) => {
                return {
                    ...grid,
                    label: grid.name,
                    value: grid.grid_guid,
                }
            }))
        }
    }, [props.gridList]);

    useEffect(()=>{
        if ( props.isSolshare === false && props.b2bOrgGuid ){
            setProjectedParams({
                ...projectedParams,
                organization_guid: props.b2bOrgGuid,
            })
        }
    },[props.b2bOrgGuid, props.isSolshare])

    useEffect(() => {
        let temp = null;

        try {
            temp = parseParams(props.history?.location?.search);

            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                if ( props.isSolshare === false && props.b2bOrgGuid ){
                    delete temp.organization_guid;
                    Object.assign(temp, {organization_guid: props.b2bOrgGuid })
                }
                setProjectedParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    }, [props.history.location.search])

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterParameters) {
            if (searchText) {
                props.GET_OTA_UPDATE_LIST_WITH_FILTER({
                    page: newPage,
                    solbox_id: searchText,
                    pagination: 'True',
                    ...filterParameters
                });
            } else {
                props.GET_OTA_UPDATE_LIST_WITH_FILTER({...filterParameters, pagination: 'True', page: newPage})
            }
        } else {
            if (searchText) {
                props.GET_OTA_UPDATE_LIST_WITH_FILTER({pagination: 'True', page: newPage, solbox_id: searchText});
            } else {
                props.GET_OTA_UPDATE_LIST_WITH_FILTER({pagination: 'True', page: newPage});
            }
        }
    }

    const setListFilters = (filterData) => {
        if (Object.keys(filterData).length !== 0) {
            setPage(null)
            setFilterParameters(filterData);
            if (Object.keys(filterData)?.length > 0) {
                props.history.push({
                    search: JsonToUriSerialize(filterData),
                });
            }

            if (searchText) {
                props.GET_OTA_UPDATE_LIST_WITH_FILTER({...filterData, pagination: 'True', name: searchText});
            } else {
                props.GET_OTA_UPDATE_LIST_WITH_FILTER({...filterData, pagination: 'True'})
            }
        }
    }

    const resetFilter = (resetList) => {
        setFilterParameters(null)
        if ( props.isSolshare === false && props.b2bOrgGuid ) {
            setProjectedParams({
                organization_guid: props.b2bOrgGuid,
            })
        } else setProjectedParams(null);
        setSearchText('')
        props.history.push({
            search: undefined,
        });
        if (resetList) {
            GET_OTA_UPDATE_LIST_WITH_FILTER({'from_date': defaultStartDate, 'to_date': defaultEndDate, pagination: 'True'})
        }
    }

    const onSearch = (text) => {
        if ((text === '' && !searchText) || text.length != 8 || !/^\d+$/.test(text)) {
            return;
        }
        setPage(null)
        // Parsing the SOLbox ID
        let solboxId = text.substring(4, 8)
        if (filterParameters) {
            props.GET_OTA_UPDATE_LIST_WITH_FILTER({...filterParameters, solbox_id: solboxId, pagination: 'True'})
        } else {
            props.GET_OTA_UPDATE_LIST_WITH_FILTER({solbox_id: solboxId, pagination: 'True'})
        }
        setSearchText(text.substring(4, 8))
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const exportToCsv = () => {
        if (filterParameters) {
            if (searchText) {
                props.GET_OTA_UPDATE_LIST_FOR_EXPORT({
                    solbox_id: searchText,
                    pagination: 'False',
                    ...filterParameters
                });
            } else {
                props.GET_OTA_UPDATE_LIST_FOR_EXPORT({...filterParameters, pagination: 'False'})
            }
        } else {
            if (searchText) {
                props.GET_OTA_UPDATE_LIST_FOR_EXPORT({pagination: 'False', solbox_id: searchText});
            } else {
                props.GET_OTA_UPDATE_LIST_FOR_EXPORT({pagination: 'False'});
            }
        }
    }

    const triggerGridListLoading = (gridOwner) => {
        setGridList(null)
        GET_GRID_LIST({organization_guid: gridOwner.value})
    }

    const resendOtaUpdate = (data) => {
        let retryPayload = {
            "history_id": data.pk
        }
        RETRY_OTA(retryPayload)
    }

    useEffect(() => {
        if (props.otaUpdateRetryStatus === requestCycle.success){
            if (validatonProcessGoingOn) {
                toast.success('Validation started successfully!')
                setValidationProcessGoingOn(false)
            } else if (retryProcessGoingOn) {
                toast.success('OTA update reinitiated successfully!')
                setRetryProcessGoingOn(false)
            } else {
                // Verification process going on
                toast.success('Verification started successfully!')
                setVerificationProcessGoingOn(false)
            }
            if (filterParameters) {
                if (searchText) {
                    props.GET_OTA_UPDATE_LIST_WITH_FILTER({
                        page: page,
                        solbox_id: searchText,
                        pagination: 'True',
                        ...filterParameters
                    });
                } else {
                    props.GET_OTA_UPDATE_LIST_WITH_FILTER({...filterParameters, pagination: 'True', page: page})
                }
            } else {
                if (searchText) {
                    props.GET_OTA_UPDATE_LIST_WITH_FILTER({pagination: 'True', page: page, solbox_id: searchText});
                } else {
                    props.GET_OTA_UPDATE_LIST_WITH_FILTER({pagination: 'True', page: page});
                }
            }
        } else if (props.otaUpdateRetryStatus === requestCycle.failed) {
            if (validatonProcessGoingOn) {
                toast.error('Failed to start validation process!')
                setValidationProcessGoingOn(false)
            } else if (retryProcessGoingOn) {
                toast.error('Failed to reinitiate OTA update process!')
                setRetryProcessGoingOn(false)
            } else {
                // Verification process going on
                toast.error('Failed to start verification process!')
                setVerificationProcessGoingOn(false)
            }
        }
    }, [props.otaUpdateRetryStatus]);

    const ToolBar = <>
        <Toolbar>
            <Toolbar.Title>
                <b>
                    <h1>
                        OTA Updates
                    </h1>
                </b>
            </Toolbar.Title>
            <Toolbar.ToolbarContainer>
                <Toolbar.ToolbarContainer.Items>
                    <IconButton onClick={filterToggle} classes={classes}>
                        <FilterListIcon color="disabled" fontSize="large"/>
                    </IconButton>
                    <IconButton classes={classes} onClick={exportToCsv}
                                disabled={(props.isLoading || props.iconLoading)? true:false}>
                        {props.iconLoading === true ? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :
                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                 alt="Download ota history"/>}
                    </IconButton>
                    <SolInput
                        id={'test'}
                        name={'search'}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                onSearch(event.target.value);
                            }
                        }}
                        autoComplete={'off'}
                        placeholder={'Ex:11005123'}
                    />
                </Toolbar.ToolbarContainer.Items>
            </Toolbar.ToolbarContainer>
        </Toolbar>
    </>

    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"OTA updates"}
                            statusCode={props.statusCode}>
                {ToolBar}
                <hr/>
                <div className={'filter-animation'} style={{
                    display: showFilter ? undefined : 'none',
                }}>
                    <Filter>
                        <h4 style={{paddingBottom: "1rem"}}>Filter</h4>
                        <FormContainer resetFilter={resetFilter} gridOwners={gridOwnersList}
                                       modelList={modelList}
                                       gridList={gridList}
                                       reloadGridList={triggerGridListLoading}
                                       gridOptionLoading={props.isGridListLoading}
                                       gridOwnerOptionLoading={props.isGridOwnerListLoading}
                                       modelOptionLoading={props.isModelListLoading}
                                       defaultStartDate={moment(defaultStartDate).format("DD MMM, YYYY")}
                                       defaultEndDate={moment(defaultEndDate).format("DD MMM, YYYY")}
                                       setListFilters={setListFilters} projectedParams={projectedParams}/>
                    </Filter></div>
                <hr/>

                <DataTableContainer>
                    {props.filterTriggeredLoading !== true ? <DataTable
                        columns={otaUpdateListColumns}
                        data={otaUpdateList}
                        showToolbar={false}
                        asyncPagination={true}
                        isLoading={props.tableLoading}
                        count={props.otaUpdates?.count}
                        itemsPerPage={props.otaUpdates?.page_size}
                        onChangePage={onChangePage}
                        page={page}
                    /> : <LoadingSpinner loadingSubText={"Collecting OTA updates"}/>}
                </DataTableContainer>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

OtaUpdateList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        isLoading: state.otaUpdatesReducer.isLoading,
        tableLoading: state.otaUpdatesReducer.tableLoading,
        statusCode: state.otaUpdatesReducer.statusCode,
        otaUpdates: state.otaUpdatesReducer.otaUpdateList,
        gridOwnerList: state.otaUpdatesReducer.gridOwnerList,
        modelList: state.otaUpdatesReducer.modelList,
        gridList: state.otaUpdatesReducer.gridList,
        filterTriggeredLoading: state.otaUpdatesReducer.filterTriggeredLoading,
        iconLoading: state.otaUpdatesReducer.iconLoading,
        isGridOwnerListLoading: state.otaUpdatesReducer.isGridOwnerListLoading,
        isModelListLoading: state.otaUpdatesReducer.isModelListLoading,
        isGridListLoading: state.otaUpdatesReducer.isGridListLoading,
        otaUpdateRetrying: state.otaUpdatesReducer.otaUpdateRetrying,
        otaUpdateRetryStatus: state.otaUpdatesReducer.otaUpdateRetryStatus
    }
}

export default connect(mapStateToProps, actions)(withRoles(OtaUpdateList));
