import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {SolTypography} from "../../../../../components/utilityComponents/SOlTypography";
import {requestCycle} from "../../../../grids/utils";

const DetailsContainer = styled.div`
  padding: 2rem 2rem 2rem 3rem;
`;


const Text =(props)=>{
    return <div style={{marginBottom: '.65rem', fontWeight: props.bold? '600':undefined}} ><SolTypography.Text primary={props.primary} >{props.children}</SolTypography.Text></div>
}


const Details = ({data,...props}) => {
    return (
        <DetailsContainer style={{fontSize:'13px'}}>
            <div className={'row'}>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>Customer</SolTypography.Text></div>
                    <Text  primary={true}>{data?.customer_info?.customer_name || "N/A"}</Text>
                    <Text>{data?.customer_info?.customer_phone || "N/A"}</Text>

                </div>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>Remarks</SolTypography.Text></div>
                    <Text >{data?.remarks || "N/A"}</Text>
                </div>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>{data?.old_solbox_serial_number || "N/A"}</SolTypography.Text></div>
                    <Text bold={true} >Balance: { data?.old_solbox_balance || 0 }Tk</Text>
                    <Text bold={true}>Buy { `${data?.tariff_info?.buy_price || 0}`} Tk / Ah,  Sell { `${data?.tariff_info?.sell_price || 0}`} Tk / Ah</Text>
                    <Text bold={true}>Exchange Control { `${data?.exchange_control_logic || "N/A"}`} </Text>
                    <Text bold={true}>{`${data?.threshold || "0"}`} mA Threshold Current</Text>
                </div>
                <div className={'col-md-3'}>
                    <div style={{marginBottom: '1.3rem'}} ><SolTypography.Text primary={props.primary}>{data?.old_solbox_serial_number || "N/A"}</SolTypography.Text></div>
                    <Text bold={true} >Balance: {data?.balance_transfer_status===requestCycle.success?  `${data?.old_solbox_balance || 0}`: "N/A"  }Tk</Text>
                    <Text bold={true}>Buy {data?.config_transfer_status===requestCycle.success?  `${data?.tariff_info?.buy_price || 0}`: "N/A"  } Tk / Ah,  Sell {data?.config_transfer_status===requestCycle.success?  `${data?.tariff_info?.sell_price || 0}`: "N/A"  } Tk / Ah</Text>
                    <Text bold={true}>Exchange Control {data?.config_transfer_status===requestCycle.success?  `${data?.exchange_control_logic || "N/A"}`: "N/A"  } </Text>
                    <Text bold={true}>{data?.config_transfer_status===requestCycle.success?  `${data?.threshold || "0"}`: "N/A"  } mA Threshold Current</Text>
                </div>
            </div>

        </DetailsContainer>
    );
};

Details.propTypes = {

};

export default Details;