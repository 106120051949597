import React, {Fragment} from "react";
import { ExportToCsv } from 'export-to-csv';
import DateTimeFormat from "../../utils/dateFormat/DateTimeFormat";
import styled from "styled-components";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "../../components/utilityComponents/SOlTypography";
import {isNumber} from "../../utils/utilityFunctions";
import moment from "moment";

export const exportOtaUpdateListToCsv = (data) => {
    let currentDate = moment().format("DD_MMM_YYYY")
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: `` ,
        filename: `OTA_update_list_` + currentDate,
        useTextFile: false,
        useBom: true,
        headers: ['SOLbox', 'Previous FW', 'Current FW', 'OTA date', 'Status', 'Updated by']
    };
    const csvExporter = new ExportToCsv(options);
    if (data?.length > 0) {
        const otaUpdatesForCsvExport = data.map((otaUpdate, index)=>{
            return {
                solbox: otaUpdate.solbox_id ? "1100" + otaUpdate.solbox_id: 'N/A',
                previousFw: otaUpdate.previous_firmware? otaUpdate.previous_firmware: 'N/A',
                currentFw: otaUpdate.current_firmware? otaUpdate.current_firmware: 'N/A',
                otaDate: otaUpdate.update_date? moment(otaUpdate.update_date).format('DD MMM, YYYY'): 'N/A',
                status: otaUpdate.aws_ota_status? otaUpdate.aws_ota_status: 'N/A',
                updatedby: otaUpdate.updated_by_name? otaUpdate.updated_by_name: 'N/A'
            }
        });
        csvExporter.generateCsv(otaUpdatesForCsvExport);
    } else {
        csvExporter.generateCsv([{}]);
    }
}

export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`


export const LoadingSpinner=({loadingSubText, size})=>{
    return <Fragment>
        <div className="align-content-center mt-5"  align="center">
            <h2 className={`mb-5`}>
                <span>{`Fetching Data`}</span>
            </h2>
            <Spinner animation="border"  size={size || 'sm'} variant="primary" />
            <br/>
            <SolTypography.SubTitle primary> {loadingSubText} </SolTypography.SubTitle>

        </div>

    </Fragment>
}


export const requestCycle = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
}
