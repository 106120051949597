import { customerActionTypes } from "./customerActionTypes";
import { customerInitialState } from "./customerInitialState";
import {replacementActionTypes as actionTypes} from "../replacement/replacementActionTypes";
import {requestCycle} from "../grids/utils";
import {toast} from "react-toastify";
import { countBy } from "lodash";

export const customerReducer = (state = customerInitialState, action) => {

    switch (action.type) {

        case customerActionTypes.FETCH_GRID_LIST_REQUEST:
            return {
                ...state,
                isGridListLoading: true
            };

        case customerActionTypes.FETCH_GRID_LIST_SUCCESS:
            return {
                ...state,
                isGridListLoading: false,
                gridList: Array.isArray(action.payload) ? action.payload : []
            };

        case customerActionTypes.FETCH_GRID_LIST_FAILURE:
            return {
                ...state,
                isGridListLoading: false,
                statusCode: action.payload.status,
            };

        case customerActionTypes.FETCH_ALL_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
                tableLoading: true
            };

        case customerActionTypes.FETCH_ALL_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                customerList: Array.isArray(action.payload) ? action.payload : []
            };

        case customerActionTypes.FETCH_ALL_CUSTOMER_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status,
            };

        case customerActionTypes.FETCH_FILTERED_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: false,
                tableLoading: true
            };

        case customerActionTypes.FETCH_FILTERED_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                customerList: Array.isArray(action.payload) ? action.payload : []
            };

        case customerActionTypes.FETCH_FILTERED_CUSTOMER_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status,
            };

        case customerActionTypes.FETCH_SEARCHED_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: false,
                tableLoading: true
            };

        case customerActionTypes.FETCH_SEARCHED_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                customerList: Array.isArray(action.payload) ? action.payload : []
            };

        case customerActionTypes.FETCH_SEARCHED_CUSTOMER_FAILURE:
            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status,
            };
            
        case customerActionTypes.FETCH_CUSTOMER_DETAILS_REQUEST:
            return {
                ...state,
                detailsLoading: true,
            };

        case customerActionTypes.FETCH_CUSTOMER_DETAILS_SUCCESS:
            return {
                ...state,
                detailsLoading: false,
                customerDetails: action.payload,
            };

        case customerActionTypes.FETCH_CUSTOMER_DETAILS_FAILURE:
            return {
                ...state,
                detailsLoading: false,
                statusCode: action.payload.status!==404?action.payload.status:null,
            };
        case customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_REQUEST:
            return {
                ...state,
                tabLoading: true,
            };
        case customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SEPARATE_LOADER_REQUEST:
            return {
                ...state,
                filterTriggeredTabLoading: true,
            };

        case customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_SUCCESS:
            return {
                ...state,
                tabLoading: false,
                // nodeReplacementHistoryData: {
                //     "links": {
                //         "next": null,
                //         "previous": null
                //     },
                //     "count": 3,
                //     "page_size": 10,
                //     "object_count": 3,
                //     "results": [
                //         {
                //             "replacement_id": 22,
                //             "old_solbox_serial_number": "11004330",
                //             "new_solbox_serial_number": "22003000",
                //             "old_solbox_balance": "0.00",
                //             "remarks": null,
                //             "config_id": null,
                //             "transaction_guid": null,
                //             "config_transfer_status": "processing",
                //             "balance_transfer_status": "processing",
                //             "performed_by": "Sadman Shakib",
                //             "tariff_info": {
                //                 "buy_price": 12,
                //                 "sell_price": 9
                //             },
                //             "exchange_control_logic": "off",
                //             "threshold": 1231234,
                //             "customer_info": {
                //                 "customer_name": "MSI Shafik",
                //                 "customer_phone": "01740999768"
                //             },
                //             "created_at": "2020-11-26T18:47:43.169740"
                //         },
                //         {
                //             "replacement_id": 4330,
                //             "old_solbox_serial_number": "11004330",
                //             "new_solbox_serial_number": "22003000",
                //             "old_solbox_balance": "0.00",
                //             "remarks": null,
                //             "config_id": null,
                //             "transaction_guid": null,
                //             "config_transfer_status": "success",
                //             "balance_transfer_status": "success",
                //             "performed_by": "Sadman Shakib",
                //             "tariff_info": {
                //                 "buy_price": 12,
                //                 "sell_price": 9
                //             },
                //             "exchange_control_logic": "off",
                //             "threshold": 1231234,
                //             "customer_info": {
                //                 "customer_name": "MSI Shafik",
                //                 "customer_phone": "01740999768"
                //             },
                //             "created_at": "2020-11-26T18:58:25.704907"
                //         },
                //         {
                //             "replacement_id": 4330261905,
                //             "old_solbox_serial_number": "11004330",
                //             "new_solbox_serial_number": "11005000",
                //             "old_solbox_balance": "0.00",
                //             "remarks": null,
                //             "config_id": null,
                //             "transaction_guid": null,
                //             "config_transfer_status": "success",
                //             "balance_transfer_status": "success",
                //             "performed_by": "Sadman Shakib",
                //             "tariff_info": {
                //                 "buy_price": 12,
                //                 "sell_price": 9
                //             },
                //             "exchange_control_logic": "off",
                //             "threshold": 1231234,
                //             "customer_info": {
                //                 "customer_name": "MSI Shafik",
                //                 "customer_phone": "01740999768"
                //             },
                //             "created_at": "2020-11-26T19:05:41.504381"
                //         }
                //     ]
                // },
                nodeReplacementHistoryData: action.payload,
                filterTriggeredTabLoading: false,

            };

        case customerActionTypes.REPLACEMENT_LIST_FOR_SPECIFIC_NODE_FAILURE:
            return {
                ...state,
                tabLoading: false,
                filterTriggeredTabLoading: false,
                statusCode: action.payload.status,
            };

        case customerActionTypes.REPLACEMENT_RETRY_FROM_NODE_REQUEST:
            return {
                ...state,
                retrySuccess: undefined,
                retryLoading: true,
            };

        case customerActionTypes.REPLACEMENT_RETRY_FROM_NODE_SUCCESS:
            toast.success('Retry request successful')
            return {
                ...state,
                retrySuccess: requestCycle.success,
                retryLoading: false,
            };

        case customerActionTypes.REPLACEMENT_RETRY_FROM_NODE_FAILURE:

            toast.error('Retry request failed');

            return {
                ...state,
                retrySuccess: requestCycle.failed,
                retryLoading: false,
            };
        case customerActionTypes.MU_PROFILE_DETAILS_REQUEST:
            return {
                ...state,
                detailsLoading: true,
            };

        case customerActionTypes.MU_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                detailsLoading: false,
                // muDetails: {
                //     "grid_name": "Rish",
                //     "node_type": "Producer",
                //     "node_guid": "a136dc20-090f-4024-86e7-c1f417018d82",
                //     "buy_price": null,
                //     "sell_price": null,
                //     "solbox_model": "STM_MODEL",
                //     "solbox_fw_version": "5.214",
                //     "solbox_stage": "Operation",
                //     "exchange_control": "off",
                //     "threshold_value": 200,
                //     "customer_shs_info": {
                //         "customer_name": "Shafik",
                //         "customer_phone": "01703979804",
                //         "is_connected_to_charge_controller": true,
                //         "battery_info": {},
                //         "panel_info": {},
                //         "shs_installation_date": "2020-12-06"
                //     }
                // },
                muDetails: action.payload
            };

        case customerActionTypes.MU_PROFILE_DETAILS_FAILURE:
            return {
                ...state,
                detailsLoading: false,
                statusCode: action.payload.status!==404?action.payload.status:null,
            };

        case customerActionTypes.FETCH_TRANSACTION_TYPES_REQUEST:

        return {
            ...state,
            isTransactionTypesLoading: true
        };

        case customerActionTypes.FETCH_TRANSACTION_TYPES_SUCCESS:

        return {
            ...state,
            isTransactionTypesLoading: false,
            transactionTypes: Array.isArray(action.payload) ? action.payload : []
        };

        case customerActionTypes.FETCH_TRANSACTION_TYPES_FAILURE:

        return {
            ...state,
            isTransactionTypesLoading: false,
            statusCode: action.payload.status,
        };
        
        case customerActionTypes.GET_TRANSACTION_HISTORY_LIST_REQUEST:

            return { 
                ...state,
                isLoading: false,
                tableLoading: true,
                //transactionHistory: null
            };
    
        case customerActionTypes.GET_TRANSACTION_HISTORY_LIST_SUCCESS:

            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                pageCount: action.payload.count,
                pageSize: action.payload.page_size,
                transactionWorth: action.payload.total_worth,
                transactionHistory: Array.isArray(action.payload.results) ? action.payload.results : []
            };

        case customerActionTypes.GET_TRANSACTION_HISTORY_LIST_FAILURE:

            return {
                ...state,
                isLoading: false,
                tableLoading: false,
                statusCode: action.payload.status
            };

        default:
            return state;
    }
}