import React, {useEffect, useState} from "react";
import {Badge, Button, Form, InputGroup, Spinner, ButtonGroup, Modal} from "react-bootstrap";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {commonActions} from "../../commonActions";
import {connect} from "react-redux";
import {incompatibleFirmwareVersion, determineBuyPriceAndSellPrice} from "../../../../utils/firmwareVersionChecking/firmwareVersionChecking";
import PropTypes from 'prop-types';
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import LiveDataComponent from "../LiveDataComponent";
import {ConfigForm} from "../../../../components/configDetails/configForm";
import Loader from "../../../../components/loader/Loader";
import {requestCycle} from "../../../grids/utils";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {withRoles} from "../../../../router/SecuredRoute";


const ConfigData = ({solboxSerialNumber, solboxGuid, configurationId, ...props}) => {
    const [configData, setConfigData] = useState(undefined);
    const [modal, setModal] = useState(false);
    const additionalConfigurationData = null;
    const additionalSolboxInfo = null;
    const modes = {
        1: <ButtonGroup variant={"primary"} aria-label="outlined secondary button group">
            <Button disabled>Buy</Button>
            <Button disabled>Sell</Button>
            <Button variant={"secondary"} disabled>Default</Button>
        </ButtonGroup>,
        2: <ButtonGroup variant={"primary"} aria-label="outlined secondary button group">
            <Button disabled>Buy</Button>
            <Button variant={"secondary"} disabled>Sell</Button>
            <Button disabled>Default</Button>
        </ButtonGroup>,
        3: <ButtonGroup variant={"primary"} aria-label="outlined secondary button group">
            <Button variant={"secondary"} disabled>Buy</Button>
            <Button disabled>Sell</Button>
            <Button disabled>Default</Button>
        </ButtonGroup>,
        4: <Badge variant={'danger'}> N/A </Badge>
    }

    const {
        FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX,
        solBoxCurrentConfiguration,
        UPDATE_SOLBOX_CONFIGURATION,
        GET_SOLBOX_PROFILE_DATA,
        FETCH_CONFIG_DETAILS,
        configurationUpdated,

        SET_FLAGS_TO_UNDEFINED

    } = props;


    useEffect(() => {

        GET_SOLBOX_PROFILE_DATA({
            solbox_guid: solboxGuid,
        })
        if (configurationId && typeof configurationId === 'string') {
            FETCH_CONFIG_DETAILS({
                config_id: configurationId
            })
        } else {
            FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX({
                solbox_id: solboxSerialNumber,
            });
        }

        return ()=> {
            SET_FLAGS_TO_UNDEFINED()
        }


    }, [FETCH_CURRENT_CONFIGURATION_FOR_SOLBOX, GET_SOLBOX_PROFILE_DATA, FETCH_CONFIG_DETAILS]);


    useEffect(()=>{
        if (configurationUpdated===requestCycle.success)
        {
            setModal(false)
        }
    },[configurationUpdated])


    useEffect(()=>{
        if (configurationUpdated===requestCycle.success)
        {
            setModal(false)
        }
    },[])


    useEffect(() => {
        if (solBoxCurrentConfiguration?.configuration_parameters) {
            const data = solBoxCurrentConfiguration.configuration_parameters;

            let [buyPrice, sellPrice] = determineBuyPriceAndSellPrice(solBoxCurrentConfiguration.fw_version, data)
            let priceUnit = "Tk/Wh"

            setConfigData({
                // mode: data.u8ConfigVersionMajor? modes[`${data.u8ConfigVersionMajor}`]:modes["4"],
                mode: data.u8ConfigVersionMajor ? data.u8ConfigVersionMajor : 'n/a',
                exchangeControl: data.u8exchange_control_v2 === 0 || data.u8exchange_control_v2 > 0 ? parseInt(data.u8exchange_control_v2) : undefined,
                fThreshold: data.fThreshold ? data.fThreshold : '',
                buyPrice: buyPrice + " " + priceUnit,
                sellPrice: sellPrice + " " + priceUnit,
                hvr: data.fVBulkStart_conf,
                hvd: data.fVBulkEnd_conf
            })
        }
    }, [solBoxCurrentConfiguration])


    const getNoDataMessage = () => {
        return <>
            <div className={"d-flex justify-content-center"}><h1><Badge variant="secondary">No configuration has been
                set</Badge></h1></div>
            {props.reloadFunction ? <>
                <div style={{
                    marginTop: '10px',
                    paddingTop: '10px',
                }} className={"d-flex justify-content-center"}>
                    <Button variant="secondary" onClick={props.reloadFunction}>
                        {props.disableFlashButton ? <Spinner animation="border" variant="secondary"/> :
                            <i className="flaticon2-refresh-button"/>}
                        &nbsp;Reload
                    </Button>
                </div>
            </> : null}
            {props.isSolshare? <div className={'d-flex justify-content-center'} style={{
                paddingTop: "10px",
            }}>
                <Button variant={"primary"} onClick={() => setModal(true)}>Set Configuration&nbsp;&nbsp;<i
                    className="fa fa-angle-double-up"/></Button>
            </div>:null}
        </>
    }


    const onSubmit = (data) => {
        UPDATE_SOLBOX_CONFIGURATION({
            ...data,
            solbox_id: parseInt(solboxSerialNumber),
            solbox_guid: solboxGuid,
        });
    };

    const renderModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={false}
                hideModal={closeModal}
                modal={modal}
                title={<>Set Configuration for SOLbox <SolTypography.Text
                    primary>{solboxSerialNumber}</SolTypography.Text></>}>
                <ConfigForm submit={onSubmit}
                            hideModal={closeModal}
                            disableButton={props.disableButton}
                            configurationData={solBoxCurrentConfiguration}
                            solboxDetails={props.solboxDetails}/>
            </GenericModal>
        </>
    }

    const closeModal = () => {
        setModal(false);
    }

    const generalInfo = () => {
        return <>
            <div className={'formContainer'}>
                <div className={'general-info'}>

                    <div>
                        <div className={'displayRow'}>
                            <div className={'displayLabel'}>
                                <label>Serial Number</label>
                            </div>
                            <div className={'displayValue'}>
                                {/*<Form.Control disabled value={}/>*/}
                                <SolTypography.Text className={'text-small valueText'}>{solBoxCurrentConfiguration?.serial_number || "N/A"}</SolTypography.Text>
                            </div>
                        </div>
                        <div className={'displayRow'}>
                            <div className={'displayLabel'}>
                                <label>Grid</label>
                            </div>
                            <div className={'displayValue'}>
                                {/* <Form.Control disabled value={}/> */}
                                <SolTypography.Text className={'text-small valueText'}>{solBoxCurrentConfiguration?.grid_name || props.solboxDetails?.grid_name || "N/A"}</SolTypography.Text>
                            </div>
                        </div>
                        <div className={'displayRow'}>
                            <div>
                                <label className={'displayLabel'}>Customer</label>
                            </div>
                            <div className={'displayValue'}>
                                {/*<Form.Control disabled value={additionalSolboxInfo.customerInfo?.name}/>*/}
                                <SolTypography.Text className={'text-small valueText'}>{solBoxCurrentConfiguration?.customer_name || props.solboxDetails?.customer_info?.name || "N/A"}</SolTypography.Text>

                            </div>
                        </div>
                        <div className={'displayRow'}>
                            <div className={'displayLabel'}>
                                <label>Configuration Updated At</label>
                            </div>
                            <div className={'displayValue'}>
                                {/*<Form.Control disabled value={additionalSolboxInfo.created_at}/>*/}
                                <SolTypography.Text className={'text-small valueText'}>{new DateTimeFormat(solBoxCurrentConfiguration?.created_at).localDisplayTime || "N/A"}</SolTypography.Text>
                            </div>
                        </div>

                        <div className={'displayRow'}>
                            <div className={'displayLabel'}>
                                <label>Configuration Recieved By SOLbox Status: </label>
                            </div>
                            <div className={'displayValue'}>
                                {/*<Form.Control disabled value={additionalSolboxInfo.created_at}/>*/}
                                <SolTypography.Text className={'text-small valueText'}>{solBoxCurrentConfiguration?.received_by_solbox === true ?
                                    <Badge
                                        variant={'success'}>Yes</Badge> : solBoxCurrentConfiguration?.received_by_solbox === false ?
                                        <Badge variant={'danger'}>No</Badge> : "N/A"}</SolTypography.Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


    const body = () => {
        return <>

            {generalInfo()}

            <div className={'formContainer'}>
                <div className={'displayForm'}>
                    <div>
                        <div className={'displayRow'}>
                            <div className={'displayLabel'}>
                                <label>Mode</label>
                            </div>
                            <div className={'displayValue'}>
                                {/*{configData.mode}*/}
                                <div className={'group-button'}>
                                    {configData.mode !== 'n/a' ? <>
                                        {/*<button className={`group-button__group-button-style-left ${configData.mode === 3 ?'group-button__enabled':null}`}>Buy</button>*/}
                                        {/*<button className={`group-button__group-button-style-right ${configData.mode === 1 ?'group-button__enabled':null}`}>Default</button>*/}

                                        {configData.mode === 1 ? <button disabled
                                                                         className={`group-button__group-button-style-center group-button__enabled group-button__border-all group-button__adhoc-background-default`}>Default</button> : null}
                                        {configData.mode === 2 ? <button disabled
                                                                         className={`group-button__group-button-style-center group-button__enabled group-button__border-all group-button__adhoc-background-sell`}>Sell</button> : null}
                                        {configData.mode === 3 ? <button disabled
                                                                         className={`group-button__group-button-style-center group-button__enabled group-button__border-all group-button__adhoc-background-buy`}>Buy</button> : null}
                                    </> : <button disabled
                                                  className={`group-button__group-button-style-center group-button__enabled group-button__border-all`}
                                                  style={{
                                                      backgroundColor: "#e74c3c",
                                                  }}>Not Set</button>}
                                </div>
                            </div>
                        </div>

                        {!incompatibleFirmwareVersion(additionalSolboxInfo) ? <div className={'displayRow'}>
                            <div className={'displayLabel'}>
                                <label>Exchange Control</label>
                            </div>
                            <div className={'displayValue'}>
                                <div className={'group-button'}>

                                    {configData.exchangeControl > 0 || configData.exchangeControl === 0 ? <>
                                        {configData.exchangeControl > 0 ? <button disabled
                                                                                  className={`group-button__group-button-style-center group-button__enabled group-button__border-all`}>On</button> : null}
                                        {configData.exchangeControl === 0 ? <button disabled
                                                                                    className={`group-button__group-button-style-center group-button__enabled group-button__border-all`}
                                                                                    style={{
                                                                                        backgroundColor: '#7f8c8d'
                                                                                    }}>Off</button> : null}

                                    </> : <button disabled
                                                  className={`group-button__group-button-style-center group-button__enabled group-button__border-all`}
                                                  style={{
                                                      backgroundColor: "#e74c3c",
                                                  }}>Not Set</button>}

                                </div>

                            </div>
                        </div> : null}


                        <div className={'displayRow'}>
                            <div className={'displayLabel'}>
                                <label>Threshold Value</label>
                            </div>
                            <div className={'displayValue'}>
                                {/*<Form.Control disabled value={configData.fThreshold}/>*/}
                                <h5 className={'text-small valueText'}>{`${configData.fThreshold? configData.fThreshold.toString() + ' mA': 'N/A'}`}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'displayNonEditable'}>
                    <div className={'displayRow'}>
                        <div className={'displayLabel'}>
                            <label>Buy Price</label>
                        </div>
                        <div className={'displayValue push-to-right'}>
                            <h5 className={'text-small valueText'}>{configData.buyPrice}</h5>
                        </div>
                    </div>

                    <div className={'displayRow'}>
                        <div className={'displayLabel'}>
                            <label>Sell Price</label>
                        </div>
                        <div className={'displayValue push-to-right'}>
                            <h5 className={'text-small valueText'}>{configData.sellPrice}</h5>
                        </div>
                    </div>

                    <div className={'displayRow'}>
                        <div className={'displayLabel'}>
                            <label>High Voltage Reconnect</label>
                        </div>
                        <div className={'displayValue push-to-right'}>
                            <h5 className={'text-small valueText'}>{configData.hvr}</h5>
                        </div>
                    </div>
                    <div className={'displayRow'}>
                        <div className={'displayLabel'}>
                            <label>High Voltage Disconnect</label>
                        </div>
                        <div className={'displayValue push-to-right'}>
                            <h5 className={'text-small valueText'}>{configData.hvd}</h5>
                        </div>
                    </div>
                </div>
            </div>
            {!configurationId && props.isSolshare? <div className={'displayButton'}>
                <div className={'displayButton__container'}>
                    <Button variant={"primary"} onClick={() => setModal(true)}>Update&nbsp;&nbsp;<i
                        className="fa fa-angle-double-up"/></Button>
                </div>
            </div> : null}


        </>
    }


    return <>
        {props.isLoading?<Loader text={"Fetching Configuration"}/>:<>
            {props.configDatatabLoading !== true ? configData ? body() : getNoDataMessage() :
                <div className="align-content-center" align="center"><Spinner animation="border" variant="primary"/></div>}

            {renderModal()}
        </>}

        <style jsx>{`

          .formContainer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            flex-direction: row;
          }

          .displayRow {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(3, 1fr);
            padding-bottom: 20px;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
          }

          .displayLabel {
            grid-column: 1/3;
          }

          .displayValue {
            grid-column: 3/4;
          }

          .displayForm {
            width: 100%;
            border-right: .25em dimgrey solid;
            padding-right: 30px;
            //padding-bottom: 0;
            //margin-bottom: 0;
          }

          .displayNonEditable {
            padding-left: 55px;
            //padding-bottom: 0;
            //margin-bottom: 0;
          }


          .displayButton {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            &__container {
              grid-column: 2/-1;
              justify-content: right;
              text-align: right;
              padding-right: 0;
              //padding-top: 25px;
            }
          }


          .general-info {
            padding-bottom: 40px;
            width: 100%;
            padding-right: 30px;
          }


          .valueText {
            font-weight: 400 !important;
          }

          .push-to-right {
            text-align: right;
            padding-right: 10px;
          }  
          
          .group-button {
            display: flex;
            flex-direction: row;
          }

          .group-button__group-button-style-left {
            padding: .5em 1em;
            border: none;
            border-bottom-left-radius: 10%;
            border-top-left-radius: 10%;
            background-color: #ebebf5;
            color: #1b1b28;
            font-size: 0.95em;
            font-weight: 300;
          }

          .group-button__group-button-style-right {
            padding: .5em 1em;
            border-bottom-right-radius: 10%;
            border-top-right-radius: 10%;
            border: none;
            background-color: #ebebf5;
            color: #1b1b28;
            font-size: 0.95em;
            font-weight: 300;
          }

          .group-button__group-button-style-center {
            padding: .5em 1em;
            border: none;
            background-color: #ebebf5;
            color: #1b1b28;
            font-size: 0.95em;
            font-weight: 300;
          }

          .group-button__enabled {
            background-color: #1abc9c;
            color: #fff;
            font-weight: 400;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          }

          .group-button__border-all {
            border-radius: 8%;
          }

          .group-button__adhoc-background-default {
            background-color: #e67e22;
          }

          .group-button__adhoc-background-buy {
            background-color: #2980b9;
          }

          .group-button__adhoc-background-sell {
            background-color: #34495e;
          }
        `}</style>


    </>

}

ConfigData.propTypes = {
    solboxGuid: PropTypes.string.isRequired,
    solboxSerialNumber: PropTypes.string.isRequired,
    solBoxCurrentConfiguration: PropTypes.object,
}
const mapStateToProps = ({commonReducer}) => (commonReducer);

export default connect(mapStateToProps, commonActions)(withRoles(ConfigData))


