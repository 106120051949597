import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DataTableContainer, Filter, Toolbar} from "../../../../../components/compoundToolBar/compoundToolBar";
import {connect} from 'react-redux';
import {SolTypography} from "../../../../../components/utilityComponents/SOlTypography";
import DataTable from "../../../../../components/dataTable/DataTable";
import {dummyData} from "./nodeReplacementColumns";
import {nodesReplacementListColumns} from "./nodeReplacementColumns";
import {
    DangerStatusRender,
    PendingRenderStatus,
    SuccessRenderStatus
} from "../../../../../components/legendComponents/legendComponents";
import {Button, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {SolInput} from "../../../../../components/SolStyledComponents/components";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {DayPickerRangeController} from "react-dates";
import moment from "moment";
import {toast} from "react-toastify";
import DateTimeFormat from "../../../../../utils/dateFormat/DateTimeFormat";
import DetailsPanel from "../../../../replacement/view/replacementList/DetailsPanel";
import Details from "./Details";
import {customerActions as actions} from "../../../customerActions";
import Loader from "../../../../../components/loader/Loader";
import {getStatus, getStatusInString, ReplacementLegends} from "../../../../../utils/replacementutils/utilityFunctions";
import {LoadingSpinner, requestCycle} from "../../../../grids/utils";
import {replacementActions} from "../../../../replacement/replacementActions";


const replacementStatuses= {
    topUp: {
        success: <b><SuccessRenderStatus>Transfer Balance</SuccessRenderStatus></b>,
        processing: <b><PendingRenderStatus>Transfer Balance</PendingRenderStatus></b>,
        failed: <b><DangerStatusRender>Transfer Balance</DangerStatusRender></b>,
    },
    config: {
        success: <b><SuccessRenderStatus>Transfer Config</SuccessRenderStatus></b>,
        processing: <b><PendingRenderStatus>Transfer Config</PendingRenderStatus></b>,
        failed: <b><DangerStatusRender>Transfer Config</DangerStatusRender></b>,
    },

}
const NodeReplacementList = props => {

    const {register, errors, setError, clearErrors, handleSubmit} = useForm()
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [dateRange, setDateRange] = useState(null);
    const [historyList, setHistoryList] = useState([]);
    const [retryRow, setRetryRow] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [page, setPage] = useState(null);

    console.log(props.solboxGuid)
    useEffect(()=>{

        setFilterData({
            solbox_guid: props.solboxGuid,
        })
        props.REPLACEMENT_LIST_FOR_SPECIFIC_NODE({
            solbox_guid: props.solboxGuid,
        });

        return ()=>{
            console.log("Leaving Replacement history Tab")
        }
    },[]);


    useEffect(()=>{
        const data = props.nodeReplacementHistoryData;
        if (data && data.results && data.results.length>=0)
        {
            setHistoryList(data.results.map((replacement)=>{
                return {
                    ...replacement,
                    replacement_id: replacement.replacement_id,
                    replacementDate: new DateTimeFormat(replacement.created_at).localDisplayTime ,
                    status: getStatus(replacement),
                    replaced_by: replacement.replaced_by,
                    old_solbox_serial_number: replacement.old_solbox_serial_number,
                    new_solbox_serial_number: replacement.new_solbox_serial_number,
                    retry: getStatusInString(replacement).retry
                }
            }));
        }
    },[props.nodeReplacementHistoryData])

    useEffect(()=>{

        reload()
    },[props.retrySuccess, props.reimbursementSuccessful, props.configUpdateSuccessful]);

    const reload =()=>{
        if(props.retrySuccess===requestCycle.success || props.reimbursementSuccessful===true ||props.configUpdateSuccessful===true ){
            props.SET_REPLACEMENT_PHASES_TO_UNDEFINED();
            props.REPLACEMENT_LIST_FOR_SPECIFIC_NODE({...filterData, page: page})
        }
    }

    const validateForm=(data)=>{
        if (!start && !end )
        {
            setError("date", {
                type: "manual",
                message: "Enter valid date range"
            });
            toast.error("Please select dateRange")
            return false
        }else{
            return true
        }
    }


    const submit=()=>{
        if (validateForm()===true){
            setPage(null);
            const payload= {
                ...filterData,
                start_date: moment(start).toISOString(),
                end_date: moment(end).toISOString(),
            }
            setFilterData(payload)

            props.REPLACEMENT_LIST_FOR_SPECIFIC_NODE(payload)
        }
    }

    const disableFutureDt=(current)=>{
        const today = moment();
        return current.isBefore(today);
    }


    const onDatesChange =({startDate, endDate}) => {

        // let doesNotMeetMinNights = false;
        // if (startDate && endDate) {
        //     const dayDiff = endDate.diff(startDate.clone().startOf('day').hour(12), 'days');
        //     doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
        //     console.log('asdasd')
        // }

        // const { daysViolatingMinNightsCanBeClicked, minimumNights } = props;
        // let doesNotMeetMinNights = false;
        // if (daysViolatingMinNightsCanBeClicked && startDate && endDate) {
        //     const dayDiff = endDate.diff(startDate.clone().startOf('day').hour(12), 'days');
        //     doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
        // }



        setStart(startDate);
        setEnd(  endDate);

    }



    const handleFocusChange = (input) => {
        if (!input){
            setFocusedInput('startDate')
        }else{
            setFocusedInput(input)
        }
    }

    const onChangePage=(event, newPage)=>{
        setPage(newPage)
        props.REPLACEMENT_LIST_FOR_SPECIFIC_NODE({...filterData, page: newPage})
    }

    const transferConfig = (data) => {
        let payloadForConfigTransfer = {
            'old_solbox_serial_number': data.old_solbox_serial_number,
            'new_solbox_serial_number': data.new_solbox_serial_number,
            'customer_guid': data.customer_guid,
            'grid_guid': data.grid_guid,
            'user_guid': props.user_guid,
            'replacement_id': data.replacement_id,
            'remarks': data.remarks
        }
        props.SEND_CONFIG_FOR_REPLACEMENT(payloadForConfigTransfer)
    }

    const transferBalance = (data) => {
        let payloadForBalanceTransfer = {
            'old_solbox_serial_number': data.old_solbox_serial_number,
            'new_solbox_serial_number': data.new_solbox_serial_number,
            'old_solbox_node_guid': data.node_guid,
            'user_guid': props.user_guid,
            'replacement_id': data.replacement_id,
            'remarks': data.remarks
        }
        props.SEND_REIMBURESEMENT_FOR_REPLACEMENT(payloadForBalanceTransfer)
    }


    const retry = (rowData) => {
        props.SET_REPLACEMENT_PHASES_TO_UNDEFINED();
        setRetryRow(rowData);
        let payloadForRetry = {
            'old_solbox_serial_number': rowData.old_solbox_serial_number,
            'new_solbox_serial_number': rowData.new_solbox_serial_number,
            'customer_guid': rowData.customer_guid,
            'grid_guid': rowData.grid_guid,
            'replacement_id': rowData.replacement_id
        }


        if (rowData.config_transfer_status === 'not_init') {
            transferConfig(rowData);

        }
        if (rowData.balance_transfer_status === 'not_init'){
            setTimeout(()=>{
                transferBalance(rowData);
            },3000)
        }

        if (rowData.balance_transfer_status==='failed' || rowData.config_transfer_status==='failed'){
            props.REPLACEMENT_RETRY_FROM_NODE(payloadForRetry);
        }
    }

    const resetFilter=()=>{

        if (Object.keys(filterData).indexOf('start_date')>-1 || Object.keys(filterData).indexOf('end_date')>-1){
            setStart(null)
            setEnd(null)
            setDateRange(null)
            setFilterData({
                solbox_guid: props.solboxGuid,
            })
            props.REPLACEMENT_LIST_FOR_SPECIFIC_NODE({
                solbox_guid: props.solboxGuid,
            });
        }

    }


    const popover = (
        <Popover id="popover-basic"  bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'} >
                <DayPickerRangeController
                    {...props}
                    startDate={start} // momentPropTypes.momentObj or null,
                    endDate={end} // momentPropTypes.momentObj or null,
                    isOutsideRange={() => null}
                    numberOfMonths={2}
                    isValidDate={disableFutureDt}
                    onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)} // PropTypes.func.isRequired,
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    );

    const ToolBar = <>
        <Toolbar>
            <Toolbar.Title >
                <SolTypography.Title style={{fontWeight: '600'}}>
                    <SolTypography.Text>   {props.nodeReplacementHistoryData?.count>0?props.nodeReplacementHistoryData.count:'No'} SOLboxes Replaced  </SolTypography.Text>   {/*50% Avg Replacement/Month*/}
                </SolTypography.Title>
            </Toolbar.Title>
        </Toolbar>
    </>;

    useEffect(()=>{
        setDateRange(start && end ?`${new DateTimeFormat(start).localDisplayWithoutTime} - ${new DateTimeFormat(end).localDisplayWithoutTime}`:'');
    },[start, end])


    const renderBody=()=>{
        return <div>
            {ToolBar}
            <Filter>
                <form onSubmit={handleSubmit(submit)}>
                    <div className={'col-md-12 no-padding'}>
                        {/*<div className="col-md-9 no-padding">*/}
                        {/*    {errors.validationError && }*/}
                        {/*</div>*/}
                        <div className={'row'}>
                            <div className={'col-md-3'}>
                                {/*<Select*/}
                                {/*    name={`org`}*/}
                                {/*    placeholder={`Replacement Date Range`}*/}
                                {/*    classNamePrefix="react-select-sol-style"*/}
                                {/*    isDisabled={false}*/}
                                {/*    isLoading={false}*/}
                                {/*    maxMenuHeight={200}*/}
                                {/*    isClearable={true}*/}
                                {/*    options={gridOwners}*/}
                                {/*    value={org}*/}
                                {/*    control={control}*/}
                                {/*    isSearchable={true}*/}
                                {/*    onChange={(selected) => {*/}
                                {/*        setOrg(selected)*/}
                                {/*        clearErrors()*/}
                                {/*        return selected*/}
                                {/*    }}*/}
                                {/*/>*/}



                                <OverlayTrigger rootClose trigger="click" placement="bottom-start" overlay={popover}>
                                    <SolInput
                                        name={'date'}
                                        readOnly={true}
                                        value={dateRange}
                                        placeholder={`Replacement Date Range`}
                                        ref={register()}
                                        onChange={()=>{
                                            clearErrors();
                                        }}
                                    />
                                </OverlayTrigger>



                                {/*<SingleDatePicker*/}
                                {/*    date={moment()} // momentPropTypes.momentObj or null*/}
                                {/*    onDateChange={date => (console.log('asdgweg'))} // PropTypes.func.isRequired*/}
                                {/*    focused={false} // PropTypes.bool*/}
                                {/*    onFocusChange={handleFocusChange} // PropTypes.func.isRequired*/}
                                {/*    id="your_unique_id" // PropTypes.string.isRequired,*/}
                                {/*/>*/}

                                {errors.date && <div className={"text-danger"}>
                                    <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.date.message}</div>}
                            </div>

                        </div>




                        <div className={'col-md-3 no-padding'} style={{
                            marginTop: '1.5rem',
                        }}>


                            <Button name={'filterSubmit'} className={'btn btn-outline-warning'} size={'sm'}  id={'filterSubmit'} type='submit'>
                                Apply Filter
                            </Button>

                            <Button name={'reset'} id={'reset'}  size={'sm'}  className={'btn btn-outline-secondary reset-button-style'} type={'button'} dullComponent={true} onClick={resetFilter}>
                                Reset Filter
                            </Button>
                        </div>

                    </div>

                    <style jsx>{`
                  .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                        border: 1px solid #F18D00;
                        box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                        outline: none;  
                        }
                        
                    .custom-popover{
                      min-width: fit-content;
                      min-height: fit-content;
                    }
                `}</style>
                </form>
            </Filter>

            {<ReplacementLegends/>}
            {props.filterTriggeredTabLoading===true?<> <LoadingSpinner loadingSubText={'please wait'} /> </>:<DataTableContainer>
                <DataTable
                    columns={nodesReplacementListColumns}
                    data={historyList}
                    onChangePage={onChangePage}
                    asyncPagination={true}
                    itemsPerPage={props.nodeReplacementHistoryData?.page_size}
                    count={props.nodeReplacementHistoryData?.count}
                    detailPanel={rowData => {
                        return (
                            <>
                                <Details data={rowData}/>
                            </>
                        )
                    }}
                    actions={[
                        (rowData)=>({
                            icon: ({disabled})=> {
                                // console.log(disabled)
                                if ((props.retryLoading===true|| props.retryLoading2===true) && rowData.replacement_id===retryRow.replacement_id){
                                    return (<Spinner  size={'lg'} variant={'warning'} animation={"grow"}/>)
                                }else{

                                    return (<Button  disabled={disabled} className={'btn btn-outline-warning'}  size={'sm'} >
                                        Retry
                                    </Button>)
                                }

                            },
                            onClick: (event, rowData) => {
                                retry(rowData);
                            },
                            disabled: !rowData.retry
                        })
                    ]}
                />
            </DataTableContainer>}
        </div>
    }



    return (

            props.tabLoading ===true ? <Loader/>:renderBody()

    );
};

NodeReplacementList.propTypes = {

};
const mapStateToProps=(state)=>{
    return {
        tabLoading: state.customerReducer.tabLoading,
        nodeReplacementHistoryData : state.customerReducer.nodeReplacementHistoryData,
        filterTriggeredTabLoading : state.customerReducer.filterTriggeredTabLoading,
        retryLoading : state.customerReducer.retryLoading,
        retrySuccess : state.customerReducer.retrySuccess,

        reimbursementSuccessful: state.replacementReducer.reimbursementSuccessful,
        retryLoading2: state.replacementReducer.retryLoading,
        configUpdateSuccessful: state.replacementReducer.configUpdateSuccessful,

    }
}


export default connect(mapStateToProps, {...actions , ...replacementActions})(NodeReplacementList);

